/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RoleDetailsViewModel } from './roleDetailsViewModel';
import { DatasetDetailsViewModel } from './datasetDetailsViewModel';

export interface UserViewModel {
  roles?: Array<RoleDetailsViewModel> | null;
  datasets?: Array<DatasetDetailsViewModel> | null;
  id?: string;
  authUserId?: string | null;
  email?: string | null;
  lastName?: string | null;
  firstName?: string | null;
}
