<div class="dialog-form">
  <div class="dialog-form__close">
    <a mat-dialog-close>
      <mat-icon>close</mat-icon>
    </a>
  </div>
  <header class="uploads__header">
    <div class="upload-text">Upload File</div>
  </header>
  <main mat-dialog-content>
    <ng-container>
      <div class="uploads__box">
        <div class="uploads__center-text">
          <lib-upload-modal *ngIf="data" [data]="data" (uploadSuccess)="uploadAction()"></lib-upload-modal>
        </div>
      </div>
    </ng-container>
  </main>
</div>
