import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { ReminderDetailsViewModel } from 'data';
// import { note } from 'data';
export interface Reminder extends ReminderDetailsViewModel {
  id: string;
}

export interface RemindersState extends EntityState<Reminder> {
  // additional state property
  selectedReminderId: string | null;
  loading: boolean;
  loaded: boolean;
  error: any;
}

export const RemindersAdapter: EntityAdapter<Reminder> = createEntityAdapter<Reminder>({
  selectId: (post: Reminder) => post.id,
  sortComparer: false
});

export const RemindersInitialState: RemindersState = RemindersAdapter.getInitialState({
  selectedReminderId: '',
  loaded: false,
  loading: false,
  error: undefined
});
