import { Injectable } from '@angular/core';
import { ApiAutoCompleteService, FieldSelectOption } from 'components/shared';

import { Observable } from 'rxjs';
import { filter, map, shareReplay } from 'rxjs/operators';

import { EobWrapperService } from '../service-wrappers/eob-wrapper.service';

@Injectable({
  providedIn: 'root'
})
export class EobCodesLookupService extends ApiAutoCompleteService {
  constructor(private service: EobWrapperService) {
    super();
  }

  existingValues: string[] = [];

  mapRow(result) {
    return {
      value: result,
      label: `${result.code} - ${result.name}`,
      inactive: result.inactive
    };
  }

  search(value: string): Observable<FieldSelectOption[]> {
    return this.service
      .apiV1EobcodeAllPost({
        generalSearch: value,
        pageSize: 9999
      })
      .pipe(
        map((results) =>
          this.sortByCode(
            results.data
              .filter(
                (x) =>
                  x.code !== '1' &&
                  x.code !== '2' &&
                  x.code !== '3' &&
                  x.code !== 'PR1' &&
                  x.code !== 'PR2' &&
                  x.code !== 'PR3'
              )
              .map(this.mapRow).filter((x: any) => (!x.inactive || this.existingValues.includes(x.value)))
          )
        )
      );
  }

  searchEOBmaint(value: string): Observable<FieldSelectOption[]> {
    return this.service
      .apiV1EobcodeAllPost({
        generalSearch: value,
        pageSize: 9999
      })
      .pipe(map((results) => results.data.map(this.mapRow).filter((x: any) => (!x.inactive || this.existingValues.includes(x.value)))));
  }

  sortByCode(EobCode) {
    return EobCode.sort((a, b) =>
      (Number(a.value.code) ? a.value.code.padStart(4, '0') : a.value.code) <
      (Number(b.value.code) ? b.value.code.padStart(4, '0') : b.value.code)
        ? -1
        : 1
    );
  }

  load(id: string): Observable<FieldSelectOption> {
    if (id !== '') {
      return this.service.apiV1EobcodeIdGet(id).pipe(map(this.mapRow));
    }
  }
}
