import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent, DialogContent } from 'components/core';
import { ShowDialogComponent } from 'projects/components/src/lib/core/show-dialog/show-dialog.component';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DialogService {
  dialogContent: DialogContent = {};
  defaultModalContent: DialogContent = {
    header: 'Please Confirm',
    body: 'Are you sure you want to continue?',
    cancelButtonText: 'Cancel',
    OKButtonText: 'OK',
    cancelButtonVisible: true
  };

  constructor(private readonly dialog: MatDialog) {}

  showConfirm(dialogContent: DialogContent): Observable<boolean> {
    this.dialogContent = dialogContent === this.dialogContent ? this.defaultModalContent : dialogContent;
    const dialogRef = this.dialog.open(DialogComponent, { data: this.dialogContent });
    return dialogRef.afterClosed();
  }

  showConfirmDialog(dialogContent: DialogContent): Observable<boolean> {
    this.dialogContent = Object.assign(this.defaultModalContent, dialogContent);
    const dialogRef = this.dialog.open(ShowDialogComponent, { data: this.dialogContent });
    return dialogRef.afterClosed();
  }
}
