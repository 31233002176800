import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseWrapperService } from './base-wrapper.service';
import { ConfigurationService, FeatureConfigurationViewModel, UploadCustomizationViewModel } from 'data';
import { SessionStorageService } from '../session-storage.service';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationWrapperService extends BaseWrapperService {
  constructor(private service: ConfigurationService, protected sessionStorageService: SessionStorageService) {
    super();
  }

  apiV1ConfigurationFeatureFeaturenameGet(featurename: string): Observable<FeatureConfigurationViewModel> {
    return this.service.apiV1ConfigurationFeatureFeaturenameGet(featurename, this.getDataSetId());
  }

  apiV1ConfigurationUploadGet(): Observable<UploadCustomizationViewModel> {
    return this.service.apiV1ConfigurationUploadGet(this.getDataSetId());
  }
}
