/**
 * ImaginePay API
 * This API facilitates partner integration within the platform.
 *
 * The version of the OpenAPI document: 22_12_0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface ImaginePayApiModelsReversePaymentsRefundTransactionRequestWebModel {
  transactionReferenceNumber: string;
  user?: string;
  paymentType?: ImaginePayApiModelsReversePaymentsRefundTransactionRequestWebModel.PaymentTypeEnum;
  receiptEmail?: string;
  markAsWorked?: boolean;
  reverseAmount?: number;
}
export namespace ImaginePayApiModelsReversePaymentsRefundTransactionRequestWebModel {
  export type PaymentTypeEnum = 'Cash' | 'Check' | 'Credit';
  export const PaymentTypeEnum = {
    Cash: 'Cash' as PaymentTypeEnum,
    Check: 'Check' as PaymentTypeEnum,
    Credit: 'Credit' as PaymentTypeEnum
  };
}
