/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface InsuranceProceduresOnHoldViewModel {
  id?: string;
  accountNumber?: number;
  lastName?: string | null;
  firstName?: string | null;
  insuranceCarrierName?: string | null;
  insuranceCarrierNumber?: string | null;
  visit?: number;
  serviceDate?: string;
  createdDate?: string;
  visitAmount?: number;
  patientId?: string;
  visitId?: string;
  providerId?: string;
  locationId?: string;
  financialClassId?: string;
  insuranceCarrierId?: string;
  readonly insuranceId?: string;
  financialClassName?: string | null;
  providerFirstName?: string | null;
  providerLastName?: string | null;
  locationName?: string | null;
  reasonForHold?: string | null;
  proceduresOnHold?: number;
  readonly billingFlagged?: boolean;
  queueName?: string | null;
  readonly queue?: string | null;
  readonly resultDescription?: string | null;
}
