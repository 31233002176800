/**
 * ImaginePay API
 * This API facilitates partner integration within the platform.
 *
 * The version of the OpenAPI document: 22_12_0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface ImaginePayApiModelsTransactionLinkedTransactionRequest {
  refCode?: string;
  email?: string;
  amount: number;
  firstName: string;
  lastName: string;
  cardNumber?: string;
  cardExpirationYear?: number;
  cardExpirationMonth?: number;
  cardVerificationValue?: number;
  bankAccountNumber?: string;
  bankRoutingNumber?: string;
  bankAccountType?: ImaginePayApiModelsTransactionLinkedTransactionRequest.BankAccountTypeEnum;
  bankAuthorization?: ImaginePayApiModelsTransactionLinkedTransactionRequest.BankAuthorizationEnum;
  isBusinessAccount?: boolean;
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  state?: string;
  postalCode: string;
  createToken: boolean;
}
export namespace ImaginePayApiModelsTransactionLinkedTransactionRequest {
  export type BankAccountTypeEnum = 'Checking' | 'Saving';
  export const BankAccountTypeEnum = {
    Checking: 'Checking' as BankAccountTypeEnum,
    Saving: 'Saving' as BankAccountTypeEnum
  };
  export type BankAuthorizationEnum = 'WRITTEN_CHECK' | 'TELEPHONE' | 'PAYOR';
  export const BankAuthorizationEnum = {
    WrittenCheck: 'WRITTEN_CHECK' as BankAuthorizationEnum,
    Telephone: 'TELEPHONE' as BankAuthorizationEnum,
    Payor: 'PAYOR' as BankAuthorizationEnum
  };
}
