<div class="implementation-end__container">
  <div class="implementation-end__elements">
    <div>
      <img
        src="../../../../assets/peregrine-logo-white_black.svg"
        class="logo"
        alt="Imagine"
        width="316"
        height="200"
      />
    </div>
    <h3>Thank you for installing your new Peregrine software!</h3>
    <div class="implementation-end__message">
      <div class="implementation-end__inner-message">
        We greatly appreciate your participation in the completion of the set up process.
      </div>
    </div>
    <div class="implementation-end__message">
      <div class="implementation-end__inner-message">We hope that you will enjoy our software.</div>
    </div>
    <div class="implementation-end__message">
      <div class="implementation-end__inner-message">
        If you have any questions or concerns, please submit an
        <a href="https://it-assistant.com/" target="_blank">ITA ticket</a>.
      </div>
    </div>
    <div>
      <lib-button-loading color="primary" class="implementation-end__button" (clickEvent)="next()"
        >Finish
      </lib-button-loading>
    </div>
  </div>
</div>
