/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CodeViewModel } from './codeViewModel';

export interface FinancialClassDetailsViewModel {
  id?: string;
  datasetId?: string;
  code?: string | null;
  name?: string | null;
  description?: string | null;
  sendStatement?: boolean;
  statementCycle?: number;
  workersComp?: boolean;
  dlqDays?: number;
  paymentCodeId?: string | null;
  adjustmentCodeId?: string | null;
  adjustmentCode2Id?: string | null;
  interestCodeId?: string | null;
  holdBilling?: boolean;
  inactive?: boolean;
  writeoffDays?: number | null;
  writeoffMinimum?: number;
  collectionsPaymentDays?: number | null;
  collectionsStatementDays?: number | null;
  collectionsDelinquency?: number | null;
  automateCollections?: boolean;
  automateWriteoff?: boolean;
  createdBy?: string | null;
  createdDate?: string;
  updatedBy?: string | null;
  updatedDate?: string | null;
  isDeleted?: boolean;
  deletedBy?: string | null;
  deletedDate?: string | null;
  paymentCode?: CodeViewModel;
  adjustmentCode?: CodeViewModel;
  adjustmentCode2?: CodeViewModel;
  interestCode?: CodeViewModel;
}
