import { Component, Inject, Injector, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTable } from '@angular/material/table';
import { compare } from 'fast-json-patch';
import { map, take } from 'rxjs/operators';
import { FieldSelectOption } from '../../../../../../projects/components/src/public-api';
import { ProviderLocationViewModel } from '../../../../../../projects/data/src/public-api';
import { DialogService } from '../../../../core/services/dialog.service';
import { LocationsLookupService } from '../../../../core/services/lookup';
import { NotificationService } from '../../../../core/services/notification.service';
import { ProviderLocationsWrapperService } from '../../../../core/services/service-wrappers/providerLocations-wrapper.service';

@Component({
  selector: 'app-provider-locations-entry-dialog',
  templateUrl: './provider-locations-entry-dialog.component.html',
  styleUrls: ['./provider-locations-entry-dialog.component.scss']
})
export class ProviderLocationsEntryDialogComponent implements OnInit {
  @ViewChild(MatTable) table: MatTable<ProviderLocationViewModel>;
  @ViewChild('paginator') paginator: MatPaginator;
  entryForm: UntypedFormGroup;
  providerLocationEntryId;
  providerId;
  activeLocationArray;
  inactiveLocationArray: Array<FieldSelectOption>;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ProviderLocationsEntryDialogComponent>,
    private injector: Injector,
    public dialog: MatDialog,
    private fb: UntypedFormBuilder,
    public locationLookup: LocationsLookupService,
    private service: ProviderLocationsWrapperService,
    private notification: NotificationService
  ) {
    this.providerLocationEntryId = this.data?.providerLocationEntry;
    this.providerId = this.data?.providerId;
    this.activeLocationArray = this.data?.activeLocationArray;
  }

  ngOnInit(): void {
    this.entryForm = this.fb.group({
      providerId: new UntypedFormControl('', Validators.required),
      locationId: new UntypedFormControl('')
    });

    this.inactiveLocationArray = []; //initialize

    this.locationLookup.search('').subscribe((loc) => {
      loc.forEach((location) => {
        if (!this.activeLocationArray.map((a) => a.id).includes(location.value)) {
          this.inactiveLocationArray.push(location);
        }
      });
    });
  }

  cancel() {
    this.dialogRef.close(false);
  }
  save() {
    const formData = {
      providerId: this.providerId,
      locationId: this.entryForm.get('locationId').value
    };
      this.service
        .apiV1ProviderlocationAddLocationPost(formData)
        .pipe(
          map((add) => {
            return add;
          })
        )
        .pipe(take(1))
        .subscribe(
          (result) => {
            this.notification.success('Saved Provider Location(s)');
            this.dialogRef.close(true);
          },
          (err) => this.notification.error('Error Saving Provider Location(s)')
        );
    }
}
