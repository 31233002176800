import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthClientConfig, AuthConfig, AuthConfigService } from './auth.config';
import { AuthClientFactory, AuthClientService } from './auth.client';

@NgModule()
export class ImaginePayAuthModule {
  static forRoot(config?: AuthConfig): ModuleWithProviders<ImaginePayAuthModule> {
    return {
      ngModule: ImaginePayAuthModule,
      providers: [
        {
          provide: AuthConfigService,
          useValue: config
        },
        {
          provide: AuthClientService,
          useFactory: AuthClientFactory.createClient,
          deps: [AuthClientConfig]
        }
      ]
    };
  }
}
