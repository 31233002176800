const origin = window.location.origin;
const pathname = window.location.pathname;
export const localhostAppId = 'localhost';
export const devFDAppId = 'vantage.azurefd.net/dev-peregrine';
export const qaFDAppId = 'vantage.azurefd.net/qa-peregrine';

export const getAppId = () => {
  const originUrl = origin.toString();
  if (originUrl.indexOf('localhost') > -1) {
    return localhostAppId;
    // TEMP FOR DEBUGGING
    // return devFDAppId;
  } else if (originUrl.indexOf('vantage.azurefd.net') > -1) {
    if (pathname.toString().indexOf('dev') !== -1) {
      return devFDAppId;
    } else if (pathname.toString().indexOf('qa') !== -1) {
      return qaFDAppId;
    }
  } else {
    return origin;
  }
};

const isNonProd = (env) => {
  if (env === 'prod') {
    return false;
  } else {
    return true;
  }
};

export const isUsingFD = () => {
  return origin.toString().indexOf('azurefd.net') !== -1 ? true : false;
};

export const getRedirectUrl = () => {
  const env = getRuntimeEnv();

  if (isUsingFD() && isNonProd(env)) {
    return `${origin}/${env}-peregrine/`;
  } else if (isUsingFD()) {
    return `${origin}/peregrine/`;
  } else {
    return `${origin}/`;
  }
};

export const getRuntimeEnv = () => {
  let env = 'prod';

  if (
    pathname.toString().indexOf('dev-peregrine') !== -1 ||
    origin.toString().indexOf('vantage.azurefd.net') !== -1 ||
    origin.toString().indexOf('dev.internal') !== -1
  ) {
    env = 'dev';
  } else if (origin.toString().indexOf('qa.internal') !== -1 ||
    origin.toString().indexOf('demo.internal') !== -1
    ) {
    env = 'qa';
  } else if (origin.toString().indexOf('localhost:4200') !== -1) {
    env = 'local';
  }

  return env;
};

// Called in App Module
export const getBaseHref = () => {
  let baseHref = '/';
  const appId = getAppId();

  if (appId === devFDAppId) {
    baseHref = '/dev-peregrine/';
  } else if (appId === qaFDAppId) {
    baseHref = '/qa-peregrine/';
  }

  return baseHref;
};

// Used in environment setup
export const getBaseUrl = () => {
  //let baseUrl = '';
  // const env = getRuntimeEnv();
  const appId = getAppId();

  if (appId === localhostAppId) {
    return '';
  } else if (appId === devFDAppId) {
    return '/dev-peregrine-api';
  } else if (appId === qaFDAppId) {
    return '/qa-peregrine-api';
  }

  return origin.toString().replace('https://', 'https://api.');
};


export const getImaginePayConfig = () => {
  const env = getRuntimeEnv();
  switch (env) {
    case 'local':
      return {
        paymentUrl: 'https://dev.imaginepay.com/partner/MakePayment',
        baseUrl: 'https://devapi.imaginepay.com',
        authClientId: '0207202220033',
        authTokenUrl: 'https://devapi.imaginepay.com/session/token',
        authGrantType: 'password',
        authPassword: 'password',
        authUserName: 'PRGRN242312168C14',
        httpInterceptor: {
          allowedList: ['https://devapi.imaginepay.com/*','https://dev.imaginepay.com/*']
        }
      };
    case 'dev':
    case 'qa':
      return {
        paymentUrl: 'https://dev.imaginepay.com/partner/MakePayment',
        baseUrl: 'https://devapi.imaginepay.com',
        authClientId: '',
        authTokenUrl: 'https://devapi.imaginepay.com/session/token',
        authGrantType: 'password',
        authPassword: '',
        authUserName: '',
        httpInterceptor: {
          allowedList: ['https://devapi.imaginepay.com/*','https://dev.imaginepay.com/*']
        }
      };
    case 'prod':
    default:
      return {
        paymentUrl: 'https://pay.imaginepay.com/partner/MakePayment',
        baseUrl: 'https://api.imaginepay.com/',
        authClientId: '',
        authTokenUrl: 'https://api.imaginepay.com/session/token',
        authGrantType: 'password',
        authPassword: '',
        authUserName: '',
        httpInterceptor: {
          allowedList: ['https://api.imaginepay.com/*','https://pay.imaginepay.com/*']
        }
      };
  }
};
