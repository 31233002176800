/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BatchViewModel } from './batchViewModel';
import { RemitHoldingSimpleViewModel } from './remitHoldingSimpleViewModel';
import { RemitClaimHoldingViewModel } from './remitClaimHoldingViewModel';

export interface UnappliedRemittanceDetailsViewModel {
  id?: string;
  datasetId?: string;
  remitHoldingId?: string;
  remitClaimHoldingId?: string;
  batchId?: string;
  unappliedCause?: string | null;
  createdBy?: string | null;
  createdDate?: string;
  remitClaimHolding?: RemitClaimHoldingViewModel;
  remitHolding?: RemitHoldingSimpleViewModel;
  batch?: BatchViewModel;
}
