/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RemitRemarkProcedureHoldingViewModel } from './remitRemarkProcedureHoldingViewModel';
import { RemitEOBProcedureHoldingViewModel } from './remitEOBProcedureHoldingViewModel';

export interface RemitProcedureHoldingViewModel {
  id?: string;
  datasetId?: string;
  remitHoldingId?: string;
  remitClaimHoldingId?: string;
  procedureCode?: string | null;
  modifier1?: string | null;
  modifier2?: string | null;
  modifier3?: string | null;
  modifier4?: string | null;
  chargeAmount?: number;
  paymentAmount?: number;
  chargeQuantity?: number;
  allowedAmount?: number;
  chargeDOS?: string | null;
  procedureSequenceNumber?: string | null;
  procedureCode2?: string | null;
  createdBy?: string | null;
  createdDate?: string;
  remitEOBProcedureHoldings?: Array<RemitEOBProcedureHoldingViewModel> | null;
  remitRemarkProcedureHoldings?: Array<RemitRemarkProcedureHoldingViewModel> | null;
}
