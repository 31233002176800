import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseWrapperService } from './base-wrapper.service';
import { SearchRequestViewModel, SearchResultsViewModel, SearchService } from 'data';
import { SessionStorageService } from '../session-storage.service';

@Injectable({
  providedIn: 'root'
})
export class SearchWrapperService extends BaseWrapperService {
  constructor(private service: SearchService, protected sessionStorageService: SessionStorageService) {
    super();
  }

  apiV1SearchPost(searchRequestViewModel?: SearchRequestViewModel): Observable<SearchResultsViewModel> {
    return this.service.apiV1SearchPost(this.getDataSetId(), searchRequestViewModel);
  }
}
