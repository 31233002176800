/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ProcedureTransactionPaymentViewModel } from './procedureTransactionPaymentViewModel';

export interface AddPaymentViewModel {
  datasetId?: string;
  patientId?: string;
  paymentRequestId?: string | null;
  paymentReferenceNumber?: string | null;
  paymentType?: string | null;
  paymentCode?: string | null;
  totalPaymentAmount?: number;
  insuranceId?: string | null;
  paymentCodeId?: string | null;
  procedureTransactionPayments?: Array<ProcedureTransactionPaymentViewModel> | null;
}
