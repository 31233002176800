/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface ProcedureTransactionHoldingViewModel {
  id?: string;
  datasetId?: string;
  sendingFacilityId?: string;
  patientHoldingId?: string | null;
  patientId?: string | null;
  accountLinkId?: string | null;
  visitHoldingId?: string | null;
  procedureId?: string | null;
  procedureLinkId?: string | null;
  attendingProviderId?: string | null;
  attendingProviderLinkId?: string | null;
  referringProviderId?: string | null;
  referringProviderLinkId?: string | null;
  orderingProviderId?: string | null;
  orderingProviderLinkId?: string | null;
  primaryProviderId?: string | null;
  primaryProviderLinkId?: string | null;
  supervisingProviderId?: string | null;
  fromDate?: string | null;
  toDate?: string | null;
  placeOfServiceCodeId?: string | null;
  placeOfServiceCodeLinkId?: string | null;
  locationId?: string | null;
  locationLinkId?: string | null;
  modifier1Id?: string | null;
  modifier2Id?: string | null;
  modifier3Id?: string | null;
  modifier4Id?: string | null;
  procedureAmount?: number | null;
  quantity?: number;
  accessionNumber?: string | null;
  insuranceAuthNumber?: string | null;
  claimComment?: string | null;
}
