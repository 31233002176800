import { Component, EventEmitter, OnInit, Output } from '@angular/core';

// TODO: On Push
// TODO MARKED FOR DELETION
@Component({
  selector: 'lib-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  @Output() selectThemeOutput = new EventEmitter<string>();

  themes = [
    { name: 'Light', value: 'light-theme' },
    { name: 'Dark', value: 'dark-theme' }
  ];

  constructor() {}

  themeSelect(themeValue) {
    this.selectThemeOutput.emit(themeValue);
  }

  ngOnInit(): void { }

  trackByValue(index: number, item: any) {
    return item.value
  }
}
