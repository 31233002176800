<div class="visit-information">
  <lib-card [hideTitle]="true">
    <dl class="data-list">
      <dt>Primary Insurance</dt>
      <dd>
        {{ visit?.primaryInsurance?.insuranceCarrier?.carrierName || '--' }}
      </dd>
      <dt>Primary Insurance Auth#</dt>
      <dd>
        {{ visit?.primaryInsuranceAuthNumber || '--' }}
      </dd>
      <dt>Admit Date</dt>
      <dd>
        {{ visit?.hospitalAdmitDate || '--' | dateDisplay }}
      </dd>
      <dt>Secondary Insurance</dt>
      <dd>
        {{ visit?.secondaryInsurance?.insuranceCarrier?.carrierName || '--' }}
      </dd>
      <dt>Secondary Insurance Auth#</dt>
      <dd>
        {{ visit?.secondaryInsuranceAuthNumber || '--' }}
      </dd>
      <dt>Discharge Date</dt>
      <dd>
        {{ visit?.hospitalDischargeDate || '--' | dateDisplay }}
      </dd>
      <dt>Tertiary Insurance</dt>
      <dd>
        {{ visit?.tertiaryInsurance?.insuranceCarrier?.carrierName || '--' }}
      </dd>
      <dt>Tertiary Insurance Auth#</dt>
      <dd>
        {{ visit?.tertiaryInsuranceAuthNumber || '--' }}
      </dd>
      <dt>Transmission Code</dt>
      <dd>
        {{ visit?.pwkTransmissionCode || '--' }}
      </dd>
      <dt>Financial Class</dt>
      <dd>{{ visit?.financialClass?.name || '--' }}</dd>
      <dt>Accident Type</dt>
      <dd>
        {{ visit?.accidentType?.name || '--' }}
      </dd>
      <dt>Attachment Type</dt>
      <dd>
        {{ visit?.pwkReportTypeCode || '--' }}
      </dd>
      <dt>Responsible Party</dt>
      <dd>{{ visit?.holdStatus === 'Held' ? 'Insurance' : 'Patient' }}</dd>
      <dt>Accident Location</dt>
      <dd>
        {{ visit?.accidentStateCode || '--' }}
      </dd>
      <dt>Attachment Control Number</dt>
      <dd>
        {{ visit?.pwkAttachmentNumber || '--' }}
      </dd>
      <dt>Delinquency Code</dt>
      <dd>{{ visit?.delinquencyCode }}</dd>
      <dt>Current DOE</dt>
      <dd>
        {{ visit?.illnessInjuryDate || '--' | dateDisplay }}
      </dd>
    </dl>
  </lib-card>
</div>
