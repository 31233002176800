import { Injectable } from '@angular/core';
import { ApiAutoCompleteService, FieldSelectOption } from 'components/shared';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { RvuGpciWrapperService } from '../service-wrappers/rvu-gpci-wrapper.service';

@Injectable({
  providedIn: 'root'
})
export class RvuGpciLookupService extends ApiAutoCompleteService {
  constructor(private service: RvuGpciWrapperService) {
    super();
  }
  rvuGpciEmpty$ = this.service
    .apiV1RvugpciAllPost({
      generalSearch: '',
      pageSize: 999
    })
    .pipe(shareReplay(1));

  mapRow(result) {
    return {
      value: result.id,
      label: `${result.mac}-${result.stateCode}-${result.localityNumber}-${result.localityName}`
    };
  }

  search(value: string): Observable<FieldSelectOption[]> {
    if (value === '') {
      return this.rvuGpciEmpty$.pipe(map((results) => results.data.map(this.mapRow)));
    }

    return this.service
      .apiV1RvugpciAllPost({
        generalSearch: value,
        pageSize: 999
      })
      .pipe(map((results) => results.data.map(this.mapRow)));
  }

  load(id: string): Observable<FieldSelectOption> {
    return this.service.apiV1RvugpciIdGet(id).pipe(map(this.mapRow));
  }
}
