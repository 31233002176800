import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { getBaseUrl } from '@core/utilities/runtime.utils';
import { MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalBroadcastService, MsalGuard, MsalInterceptor, MsalService } from '@azure/msal-angular';
import { BrowserCacheLocation, InteractionType, LogLevel, PublicClientApplication } from '@azure/msal-browser';

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

const AUTH_CONFIG_URL = '/api/v1/authentication/configuration';
let baseUrl = getBaseUrl();
if (environment.production) {
  enableProdMode();
}

// using a service doesn't work at this part of the application lifecycle
// as injectable services are not available
fetch(baseUrl + AUTH_CONFIG_URL)
  .then(resp => resp.json())
  .then(env => {
    environment.msalConfig = env.msalConfig;
    environment.b2cPolicies = env.b2cPolicies;
    environment.apiConfig = env.apiConfig;
    environment.apiConfig.baseUrl = baseUrl;
    platformBrowserDynamic([
      {
        provide: MSAL_INSTANCE,
        useValue: new PublicClientApplication({
          auth: {
            clientId: env.msalConfig.auth.clientId,
            authority: env.b2cPolicies.authorities.signIn.authority,
            redirectUri: '/',
            postLogoutRedirectUri: '/login',
            knownAuthorities: [env.b2cPolicies.authorityDomain]
          },
          cache: {
            
            cacheLocation: BrowserCacheLocation.LocalStorage,
            storeAuthStateInCookie: isIE
          },
          system: {
            allowNativeBroker: false, // Disables WAM Broker
            loggerOptions: {
              loggerCallback(logLevel: LogLevel, message: string) {
                //console.log('MSAL Angular: ',  message);
              },
              logLevel: LogLevel.Trace,
              piiLoggingEnabled: false
            }
          }
        })
      },
      {
        provide: MSAL_GUARD_CONFIG,
        useValue:  {
          interactionType: InteractionType.Redirect,
          authRequest: {
            scopes: [...env.apiConfig.scopes],
          },
          loginFailedRoute: '/login'
        }
      },
      {
        provide: MSAL_INTERCEPTOR_CONFIG,
        useFactory: () => 
        {
          const protectedResourceMap = new Map<string, Array<string>>();
          protectedResourceMap.set(env.apiConfig.uri, env.apiConfig.scopes);

          return {
            interactionType: InteractionType.Redirect,
            protectedResourceMap
          }
        }
      }
    ]
  ) .bootstrapModule(AppModule).catch(err => console.error(err))
});    
