import { UntypedFormGroup } from "@angular/forms";

export class MailingAddressUtility {

  temp_mailingAddress1: string;
  temp_mailingAddress2: string;
  temp_mailingCity: string;
  temp_mailingStateCode: string;
  temp_mailingZip: string;
  temp_mailingCountryCode: string;

  subSameAsFunctionalityToSecondAddress(formGroup: UntypedFormGroup, same: boolean, addressTypePrefix: string = 'mailing') {

    if (same) {
      this.temp_mailingAddress1 = formGroup.get(addressTypePrefix + 'Address1').value;
      this.temp_mailingAddress2 = formGroup.get(addressTypePrefix + 'Address2').value;
      this.temp_mailingCity = formGroup.get(addressTypePrefix + 'City').value;
      this.temp_mailingStateCode = formGroup.get(addressTypePrefix + 'StateCode').value;
      this.temp_mailingZip = formGroup.get(addressTypePrefix + 'Zip').value;
      this.temp_mailingCountryCode = formGroup.get(addressTypePrefix + 'CountryCode').value;
      formGroup.get(addressTypePrefix + 'Address1').setValue(formGroup.get('address1').value);
      formGroup.get(addressTypePrefix + 'Address1').disable();
      formGroup.get(addressTypePrefix + 'Address2').setValue(formGroup.get('address2').value);
      formGroup.get(addressTypePrefix + 'Address2').disable();
      formGroup.get(addressTypePrefix + 'City').setValue(formGroup.get('city').value);
      formGroup.get(addressTypePrefix + 'City').disable();
      formGroup.get(addressTypePrefix + 'StateCode').setValue(formGroup.get('stateCode').value);
      formGroup.get(addressTypePrefix + 'StateCode').disable();
      formGroup.get(addressTypePrefix + 'Zip').setValue(formGroup.get('zip').value);
      formGroup.get(addressTypePrefix + 'Zip').disable();
      formGroup.get(addressTypePrefix + 'CountryCode').setValue(formGroup.get('countryCode').value);
      formGroup.get(addressTypePrefix + 'CountryCode').disable();
    }
    else {
      formGroup.get(addressTypePrefix + 'Address1').enable();
      formGroup.get(addressTypePrefix + 'Address1').setValue(this.temp_mailingAddress1);
      formGroup.get(addressTypePrefix + 'Address2').enable();
      formGroup.get(addressTypePrefix + 'Address2').setValue(this.temp_mailingAddress2);
      formGroup.get(addressTypePrefix + 'City').enable();
      formGroup.get(addressTypePrefix + 'City').setValue(this.temp_mailingCity);
      formGroup.get(addressTypePrefix + 'StateCode').enable();
      formGroup.get(addressTypePrefix + 'StateCode').setValue(this.temp_mailingStateCode);
      formGroup.get(addressTypePrefix + 'Zip').enable();
      formGroup.get(addressTypePrefix + 'Zip').setValue(this.temp_mailingZip);
      formGroup.get(addressTypePrefix + 'CountryCode').enable();
      formGroup.get(addressTypePrefix + 'CountryCode').setValue(this.temp_mailingCountryCode);
    }

  }

}
