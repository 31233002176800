import { NgModule, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UnauthorizedComponent } from './unauthorized.component';
import { UnauthorizedRoutingModule } from './unauthorized-routing.module';
import { MaterialModule } from 'components/shared/modules';

@NgModule({
  declarations: [UnauthorizedComponent],
  exports: [UnauthorizedComponent],
  imports: [CommonModule, UnauthorizedRoutingModule, MaterialModule]
})
export class UnauthorizedModule {
  
}
