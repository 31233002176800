import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nameInitialDisplay'
})
export class NameInitialDisplayPipe implements PipeTransform {
  transform(value: string) {
    if (value === '' || value === undefined || value === null) {
      return ''
    }
    return value.substring(0,1) + '.';
  }
}
