/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EligibilityAdditionalInfoResponseViewModel } from './eligibilityAdditionalInfoResponseViewModel';
import { EligibilityReferenceResponseViewModel } from './eligibilityReferenceResponseViewModel';
import { EligibilityServiceTypeResponseViewModel } from './eligibilityServiceTypeResponseViewModel';
import { EligbilityValidationResponseViewModel } from './eligbilityValidationResponseViewModel';
import { EligibilityRelatedResponseViewModel } from './eligibilityRelatedResponseViewModel';
import { EligibilityTimePeriodResponseViewModel } from './eligibilityTimePeriodResponseViewModel';
import { EligibilityDeliveryResponseViewModel } from './eligibilityDeliveryResponseViewModel';
import { EligibilityMessageResponseViewModel } from './eligibilityMessageResponseViewModel';

export interface EligibilityBenefitsResponseViewModel {
  id?: string;
  datasetId?: string;
  eligibilityBenefitInformationCode?: string | null;
  coverageLevelCode?: string | null;
  insuranceTypeCode?: string | null;
  planCoverageDescription?: string | null;
  timePeriodQualifierCode?: string | null;
  monetaryAmount?: number;
  percentage?: number;
  quantityQualifierCode?: string | null;
  quantity?: number;
  authorizationRequired?: string | null;
  inNetwork?: string | null;
  compositeMedicalProcedureIdentifier?: string | null;
  diagnosisCodePointer?: string | null;
  serviceTypes?: Array<EligibilityServiceTypeResponseViewModel> | null;
  serviceDelivery?: Array<EligibilityDeliveryResponseViewModel> | null;
  references?: Array<EligibilityReferenceResponseViewModel> | null;
  timePeriods?: Array<EligibilityTimePeriodResponseViewModel> | null;
  validations?: Array<EligbilityValidationResponseViewModel> | null;
  messages?: Array<EligibilityMessageResponseViewModel> | null;
  additionalInformation?: Array<EligibilityAdditionalInfoResponseViewModel> | null;
  relatedEntities?: Array<EligibilityRelatedResponseViewModel> | null;
}
