import { Component, Input, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { SteppedFormGroupDefinition } from '../models/stepped-form-group-definition';

@Component({
  selector: 'lib-stepped-form',
  templateUrl: './stepped-form.component.html',
  styleUrls: ['./stepped-form.component.scss']
})
export class SteppedFormComponent {
  @Input()
  disabled: boolean;

  @Input()
  formGroups: UntypedFormGroup;

  @Input()
  formGroupsDefinition: SteppedFormGroupDefinition[];

  @Input()
  firstFieldByStep: { [step: number]: string };

  @ViewChild('stepper') stepper: MatStepper;

  constructor() {}

  openStep(stepper: MatStepper, step: number): void {
    stepper.selectedIndex = step;
  }

  stepChanged(stepper: MatStepper): void {
    (document.getElementById(this.firstFieldByStep[stepper.selectedIndex]) as HTMLElement)?.focus();
  }

  restart() {
    this.openStep(this.stepper, 0);
  }

  get formGroupsControls(): UntypedFormGroup[] {
    if (!this.formGroups) {
      return [];
    }
    return Object.keys(this.formGroups.controls)
      .filter((ctrlKey) => this.formGroupsDefinition.find(({ key }) => key === ctrlKey))
      .map((ctrlKey) => this.formGroups.controls[ctrlKey]) as UntypedFormGroup[];
  }

  get isFormCompleted(): boolean {
    return this.formGroupsControls.every((formGroup) => formGroup.valid);
  }

  trackByIndex(index: number, item: any) {
    return index
  }
}
