import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogContent } from './dialog.content';

// TODO: ONPUSH
@Component({
  selector: 'lib-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit {
  defaultModalContent: DialogContent = {
    header: 'Please Confirm',
    body: 'Are you sure you want to continue?',
    cancelButtonText: 'Cancel',
    OKButtonText: 'OK',
    cancelButtonVisible: true
  };
  constructor(public dialogRef: MatDialogRef<DialogComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogContent) {}

  ngOnInit(): void {}

  /**
   * Closes dialog
   *
   * @memberof DialogComponent
   */
  ok() {
    this.dialogRef.close(true);
  }

  /**
   * Dismisses dialog
   *
   * @memberof DialogComponent
   */
  cancel() {
    this.dialogRef.close(false);
  }
}
