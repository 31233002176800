import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { ProviderLocationService } from '../../../../../projects/data/src/lib/api/providerLocation.service';
import { ProviderLocationDetailsViewModel } from '../../../../../projects/data/src/lib/model/providerLocationDetailsViewModel';
import { ProviderLocationDetailsViewModelPagedModelResponse } from '../../../../../projects/data/src/lib/model/providerLocationDetailsViewModelPagedModelResponse';
import { PagedModelRequest } from '../../../../../projects/data/src/public-api';
import { BaseWrapperService } from './base-wrapper.service';
import { SessionStorageService } from '../session-storage.service';

@Injectable({
  providedIn: 'root'
})
export class ProviderLocationsWrapperService extends BaseWrapperService {
  constructor(private service: ProviderLocationService, protected sessionStorageService: SessionStorageService) {
    super();
  }

  apiV1ProviderLocationAllPost(
    pagedModelRequest?: PagedModelRequest
  ): Observable<ProviderLocationDetailsViewModelPagedModelResponse> {
    return this.service.apiV1ProviderlocationAllPost(this.getDataSetId(), pagedModelRequest);
  }

  private cache: Observable<string>[] = [];
  apiV1ProviderLocationIdGetFromCache(id: string): Observable<ProviderLocationDetailsViewModel> {
    if (!this.cache[id]) {
      this.cache[id] = this.apiV1ProviderLocationIdGet(id).pipe(shareReplay());
    }
    return this.cache[id];
  }

  apiV1ProviderLocationIdGet(id: string): Observable<ProviderLocationDetailsViewModel> {
    return this.service.apiV1ProviderlocationIdGet(id, this.getDataSetId());
  }

  apiV1ProviderLocationDetailsIdGet(id: string): Observable<ProviderLocationDetailsViewModel> {
    return this.service.apiV1ProviderlocationDetailsIdGet(id, this.getDataSetId());
  }

  apiV1ProviderlocationAddLocationPost(requestBody): Observable<ProviderLocationDetailsViewModel> {
    return this.service.apiV1ProviderlocationAddLocationPost(this.getDataSetId(), requestBody);
  }

  apiV1ProviderLocationPatchIdPatch(id: string, operation): Observable<ProviderLocationDetailsViewModel> {
    return this.service.apiV1ProviderlocationPatchIdPatch(id, this.getDataSetId(), operation);
  }

  apiV1ProviderLocationIdDelete(id: string): Observable<ProviderLocationDetailsViewModel> {
    return this.service.apiV1ProviderlocationIdDelete(id, this.getDataSetId());
  }
}
