/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface EligibilityRelatedViewModel {
  id?: string;
  relatedLastOrgName?: string | null;
  relatedFirstName?: string | null;
  relatedMiddleName?: string | null;
  relatedIdentificationQualifierCode?: string | null;
  relatedIdentificationQualifierName?: string | null;
  relatedIdentificationCode?: string | null;
  relatedAddressLine1?: string | null;
  relatedAddressLine2?: string | null;
  relatedCity?: string | null;
  relatedState?: string | null;
  relatedPostalCode?: string | null;
  relatedProviderTypeCode?: string | null;
  relatedProviderTypeName?: string | null;
  relatedProviderIdentifierQualifierCode?: string | null;
  relatedProviderIdentifierQualifierName?: string | null;
  relatedProviderIdentifier?: string | null;
  relatedContactName?: string | null;
  relatedCommNumberQualifier?: string | null;
  relatedCommNumber?: string | null;
  relatedCommNumberQualifier2?: string | null;
  relatedCommNumber2?: string | null;
  relatedCommNumberQualifier3?: string | null;
  relatedCommNumber3?: string | null;
}
