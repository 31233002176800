/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UpdateInsuranceHoldingViewModel } from './updateInsuranceHoldingViewModel';
import { UpdatePatientHoldingViewModel } from './updatePatientHoldingViewModel';
import { UpdateGuarantorHoldingViewModel } from './updateGuarantorHoldingViewModel';

export interface UpdateDemoHoldingViewModel {
  patientHoldingId?: string;
  patientHolding?: UpdatePatientHoldingViewModel;
  guarantorHoldingId?: string;
  guarantorHolding?: UpdateGuarantorHoldingViewModel;
  primaryInsuranceHoldingId?: string;
  primaryInsuranceHolding?: UpdateInsuranceHoldingViewModel;
  secondaryInsuranceHoldingId?: string | null;
  secondaryInsuranceHolding?: UpdateInsuranceHoldingViewModel;
  tertiaryInsuranceHoldingId?: string | null;
  tertiaryInsuranceHolding?: UpdateInsuranceHoldingViewModel;
}
