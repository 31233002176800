import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';

// TODO: On Push
@Component({
  selector: 'lib-page-layout',
  templateUrl: './page-layout.component.html',
  styleUrls: ['./page-layout.component.scss']
})
export class PageLayoutComponent implements OnInit {
  constructor() {}
  @ViewChild('sideBarRight') sideBarRight: MatSidenav;

  ngOnInit(): void {}

  /**
   * Opens Right Nav
   *
   * @memberof PageLayoutComponent
   */
  openSideNavRight() {
    this.sideBarRight.open();
  }

  /**
   * Closes Right Nav
   *
   * @memberof PageLayoutComponent
   */
  closeSideNavRight() {
    this.sideBarRight.close();
  }

  /**
   * Toggles Right nav
   *
   * @memberof PageLayoutComponent
   */
  toggleSideNavRight() {
    this.sideBarRight.toggle();
  }
}
