/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface EligibilityDeliveryViewModel {
  id?: string;
  quantityQualifierCode?: string | null;
  quantityQualifierName?: string | null;
  quantity?: number;
  measurementUnitCode?: string | null;
  measurementUnitName?: string | null;
  timePeriodQualifierCode?: string | null;
  timePeriodQualifierName?: string | null;
  numberOfPeriod?: number;
}
