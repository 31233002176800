import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

export interface DataSet {
  id: string;
  customerId: string;
  name: string;
  taxIdNumber: string;
  address1: string;
  address2: string;
  city: string;
  stateCode: string;
  zip: string;
  countryCode: string;
  acronym: string;
  inactive?: boolean;
}

export interface DataSetState extends EntityState<DataSet> {
  // additional state property
  selectedDataSetId: string | null;
}

export const DataSetAdapter: EntityAdapter<DataSet> = createEntityAdapter<DataSet>({
  selectId: (post: DataSet) => post.id,
  sortComparer: false
});

export const DataSetInitialState: DataSetState = DataSetAdapter.getInitialState({
  selectedDataSetId: ''
});
