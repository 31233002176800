<h2 mat-dialog-title>{{ data.header }}</h2>
<mat-dialog-content class="mat-typography">
  <p>
    {{ data.body }}
  </p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button *ngIf="data.cancelButtonVisible || data.cancelButtonText" mat-button (click)="cancel()">
    {{ data.cancelButtonText }}
  </button>
  <button mat-flat-button *ngIf="data.OKButtonText !== ''" color="primary" (click)="ok()">
    {{ data.OKButtonText }}
  </button>
</mat-dialog-actions>
