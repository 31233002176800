import { Component, Input, Output, EventEmitter, OnInit, ChangeDetectionStrategy } from '@angular/core';

export interface DetailFields {
  name: string;
  value: string | number;
  missing?: boolean;
  updated?: boolean;
  invalid?: boolean;
  previous?: boolean;
}

@Component({
  selector: 'lib-detail-card',
  templateUrl: './detail-card.component.html',
  styleUrls: ['./detail-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DetailCardComponent implements OnInit {
  /**
   * ID of Element Emitted
   *
   * @type {string}
   * @memberof DetailCardComponent
   */
  @Input() id: string;

  /**
   * Text Title for card
   *
   * @type {string}
   * @memberof DetailCardComponent
   */
  @Input() title: string;

  /**
   * Display Fields
   *
   * @type {DetailFields[]}
   * @memberof DetailCardComponent
   */
  @Input() fields: DetailFields[] = [];

  /**
   * Displays pencil edit icon
   *
   * @memberof DetailCardComponent
   */
  @Input() showEdit = true;

  /**
   * Displays add icon
   *
   * @memberof DetailCardComponent
   */
   @Input() showAdd = false;

  /**
   * Emits ID of selected item
   *
   * @type {EventEmitter<string>}
   * @memberof DetailCardComponent
   */
  @Output() openEdit: EventEmitter<string> = new EventEmitter<string>();

  /**
   * Emits ID of selected item for add
   *
   * @type {EventEmitter<string>}
   * @memberof DetailCardComponent
   */
   @Output() openAdd: EventEmitter<string> = new EventEmitter<string>();

  /**
   * Chips at the top of the component
   *
   * @type {any[]}
   * @memberof DetailCardComponent
   */
  public chips: any[];

  ngOnInit() {
    const seen = new Set();
    this.chips = this.fields
      .filter(({ missing, updated, invalid }) => missing || updated || invalid)
      .map(({ missing, updated, invalid }) => ({
        type: updated ? 'success' : 'error',
        text: invalid ? 'Invalid Data' : missing ? 'Missing Fields' : 'Updated'
      }))
      .filter((el) => {
        const duplicate = seen.has(el.text);
        seen.add(el.text);
        return !duplicate;
      });
  }

  trackByValue(index: number, item: any) {
    return item.value
  }
}
