import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { OrderByPipe } from 'components';
import { take } from 'rxjs/operators';
import { NotificationService } from 'src/app/core/services/notification.service';
import { ReminderWrapperService } from 'src/app/core/services/service-wrappers/reminder-wrapper.service';
import { updateReminders } from 'src/app/core/state/reminders/reminders.actions';

@Component({
  selector: 'app-reminders-list',
  templateUrl: './reminders-list.component.html',
  styleUrls: ['./reminders-list.component.scss']
})
export class RemindersListComponent implements OnInit {
  privReminders;
  orderPipe: OrderByPipe = new OrderByPipe();

  @Input()
  get reminders(): any {
    return this.privReminders;
  }
  set reminders(e) {
    this.privReminders = e;
    this.applyFilter();
  }

  @Input()
  isLoading: boolean;

  @Input()
  isLoaded: boolean;

  @Input()
  error: any;

  displayReminders: any;
  filterValue = '';
  overflowMenuOptions = [{ id: 'delete', label: 'Dismiss' }];
  constructor(
    private store: Store<any>,
    private reminderService: ReminderWrapperService,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {}
  setFilter(e) {
    this.filterValue = e.value;
    this.applyFilter();
  }

  applyFilter() {
    this.displayReminders = this.orderPipe.transform(this.reminders, 'scheduledDate');
  }
  overflowMenuClicked(id, reminder) {
    if (id === 'delete') {
      this.reminderService
        .apiV1ReminderIdDelete(reminder.id)
        .pipe(take(1))
        .subscribe(
          (x) => {
            this.store.dispatch(updateReminders());
          },
          (err) => this.notificationService.error('Error dismissing reminder')
        );
    } else {
      this.store.dispatch(updateReminders());
    }
  }
  trackById(index: number, item: any) {
    return item.id
  }
}
