<ng-container [ngSwitch]="filterConfig?.filterType">
  <!-- Future Feature -->
  <ng-container *ngSwitchCase="filterType.date">
    <mat-form-field class="date" appearance="outline" [formGroup]="formGroup">
      <mat-date-range-input class="input" [formGroup]="formGroup" [rangePicker]="picker">
        <input matStartDate [formControlName]="filterControlStartName" (focus)="picker.open()" />
        <input matEndDate [formControlName]="filterControlEndName" (focus)="picker.open()" />
      </mat-date-range-input>
      <mat-datepicker-toggle class="pick" matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>
  </ng-container>
  <ng-container *ngSwitchCase="filterType.number">
    <div class="dual-input-filter">
      <mat-form-field appearance="outline" [formGroup]="formGroup">
        <input matInput type="number" [formControlName]="filterControlStartName" />
      </mat-form-field>
      <span> -</span>
      <mat-form-field appearance="outline" [formGroup]="formGroup">
        <input matInput type="number" [formControlName]="filterControlEndName" />
      </mat-form-field>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="filterType.autocomplete">
    <lib-form-field
      [parent]="formGroup"
      class=""
      type="autocomplete"
      [name]="controlName"
      label=""
      [apiService]="filterConfig.autocompleteAPI"
    ></lib-form-field>
  </ng-container>
  <ng-container *ngSwitchCase="filterType.dropdown">
    <mat-form-field appearance="outline" [formGroup]="formGroup">
      <mat-select [formControlName]="controlName" [multiple]="multiple">
        <mat-option></mat-option>
        <mat-option
          *ngFor="let option of filterConfig.dropDownFilterOptions; trackBy: trackByValue"
          [value]="option.value"
        >
          {{ option.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </ng-container>
  <ng-container *ngSwitchDefault>
    <mat-form-field appearance="outline" [formGroup]="formGroup">
      <input matInput [formControlName]="controlName" />
    </mat-form-field>
  </ng-container>
</ng-container>
