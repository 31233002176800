import { Injector, LOCALE_ID } from '@angular/core';
import { Validators } from '@angular/forms';
import { ColumnSizes, FilterType, FieldSelectOption } from 'components';
import { DateDisplayPipe } from 'projects/components/src/lib/shared/pipes/date-display/date-display.pipe';
import { Report } from '../../models/report';
import { LocationsLookupService, PhysiciansLookupService } from '../../../../core/services/lookup';
import { TransactionByTypeDateService } from './transactions-by-type-date.service';

export const TransactionByTypeDate: Report = {
  id: 'transactions-by-type-date',
  label: 'Transactions by Type and Date Report',
  textCriteria: {'Start Date': '', 'End Date': '', 'Locations': '', 'Providers': '', 'Include Charges': false, 'Include Adjustments': false, 'Include Payments': false},
  cleanFilterParams: {
    'locationIds': '', 'providerIds': ''
  },
  getReportTitle: () => {
    return TransactionByTypeDate.label + ' ' + TransactionByTypeDate.textCriteria['Start Date'] + ' - ' + TransactionByTypeDate.textCriteria['End Date']
  },
  getSearchCriteria: (injector: Injector) => [
    {
      title: '',
      controls: [
        {
          label: 'Date Type',
          name: 'reportDateType',
          type: 'select',
          class: 'form-span-6',
          options: [
            {
              label: 'Service Date',
              value: 'S'
            },
            {
              label: 'Posting Date',
              value: 'P'
            }
          ],
          validators: Validators.required,
          selectionChanged: (event) => {
            TransactionByTypeDate.textCriteria['Report Date Type'] = event.source.triggerValue;
          }
        },
        {
          label: 'Date Range',
          name: 'dateRange',
          type: 'dateRange',
          class: 'form-span-6',
          daterangeStartChanged: (event) => {
            TransactionByTypeDate.textCriteria['Start Date'] = event.value.toLocaleDateString();
          },
          daterangeEndChanged: (event) => {
            TransactionByTypeDate.textCriteria['End Date'] = event.value.toLocaleDateString();
          }
        },
        {
          label: '',
          name: 'dateRangeStart',
          type: 'noInput',
          class: 'display-none',
          validators: Validators.required
        },
        {
          label: '',
          name: 'dateRangeEnd',
          type: 'noInput',
          class: 'display-none',
          validators: Validators.required
        },
        {
          matLabel: 'Locations',
          name: 'locationIds',
          apiService: injector.get(LocationsLookupService),
          type: 'multiSelect',
          class: 'form-span-6 multi-input',
          placeholder: 'Locations',
          selectOptions: (event) => {
            let listValue = []
            let listText = []
            event.forEach((x: FieldSelectOption) => {

              listValue.push(x.value)
              listText.push(x.label)
            })
            TransactionByTypeDate.textCriteria['Locations'] = listText.join(',');
            TransactionByTypeDate.cleanFilterParams['locationIds'] = listValue
          }
        },
        {
          matLabel: 'Providers',
          name: 'providerIds',
          apiService: injector.get(PhysiciansLookupService),
          type: 'multiSelect',
          class: 'form-span-6 multi-input',
          placeholder: 'Providers',
          selectOptions: (event) => {
            let listValue = []
            let listText = []
            event.forEach((x: FieldSelectOption) => {
              listValue.push(x.value)
              listText.push(x.label)
            })
            TransactionByTypeDate.textCriteria['Providers'] = listText.join(',');
            TransactionByTypeDate.cleanFilterParams['providerIds'] = listValue

          }
        },
        {
          label: 'Include Charges',
          name: 'includeCharges',
          type: 'checkbox',
          class: 'checkbox form-span-12 short-checkbox',
          checkedChanged: (event) => {
            TransactionByTypeDate.textCriteria['Include Charges'] = event.checked;
          }
        },
        {
          label: 'Include Adjustments',
          name: 'includeAdjustments',
          type: 'checkbox',
          class: 'checkbox form-span-12 short-checkbox',
          checkedChanged: (event) => {
            TransactionByTypeDate.textCriteria['Include Adjustments'] = event.checked;
          }
        },
        {
          label: 'Include Payments',
          name: 'includePayments',
          type: 'checkbox',
          class: 'checkbox form-span-12 short-checkbox',
          checkedChanged: (event) => {
            TransactionByTypeDate.textCriteria['Include Payments'] = event.checked;
          }
        }
      ]
    }
  ],
  getGridConfiguration: (injector: Injector) => {
    const locale = injector.get(LOCALE_ID);
    const moneyFormat = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });
    return {
      service: injector.get<any>(TransactionByTypeDateService),
      columnDefinitions: [
        {
          id: 'Account',
          displayKey: 'Account',
          headerText: 'Account',
          displayOrder: 0,
          columnSize: ColumnSizes.normal,
          sortable: true,
          filterable: true
        },
        {
          id: 'Posting Date',
          displayKey: 'Posting Date',
          headerText: 'Posting Date',
          displayOrder: 1,
          columnSize: ColumnSizes.normal,
          sortable: true,
          filterable: true
        },
        {
          id: 'Transaction Date',
          displayKey: 'Transaction Date',
          headerText: 'Transaction Date',
          displayOrder: 2,
          columnSize: ColumnSizes.normal,
          sortable: true,
          filterable: true
        },
        {
          id: 'Location',
          displayKey: 'Location',
          headerText: 'Location',
          displayOrder: 4,
          columnSize: ColumnSizes.wide,
          sortable: true,
          filterable: true
        },
        {
          id: 'Attending',
          displayKey: 'Attending',
          headerText: 'Attending',
          displayOrder: 5,
          columnSize: ColumnSizes.wide,
          sortable: true,
          filterable: true,
        },
        {
          id: 'Primary',
          displayKey: 'Primary',
          headerText: 'Primary',
          displayOrder: 6,
          columnSize: ColumnSizes.wide,
          sortable: true,
          filterable: true,
        },
        {
          id: 'Ordering',
          displayKey: 'Ordering',
          headerText: 'Ordering',
          displayOrder: 7,
          columnSize: ColumnSizes.wide,
          sortable: true,
          filterable: true,
        },
        {
          id: 'Code',
          displayKey: 'Code',
          headerText: 'Code',
          displayOrder: 8,
          columnSize: ColumnSizes.narrow,
          sortable: true,
          filterable: true
        },
        {
          id: 'Description',
          displayKey: 'Description',
          headerText: 'Description',
          displayOrder: 9,
          columnSize: ColumnSizes.wide,
          sortable: true,
          filterable: true,
        },
        {
          id: 'Amount',
          displayKey: 'Amount',
          headerText: 'Amount',
          displayOrder: 10,
          columnSize: ColumnSizes.normal,
          sortable: true,
          filterable: true,
          formatter: moneyFormat.format
        },
        {
          id: 'Patient Name',
          displayKey: 'Patient Name',
          headerText: 'Patient Name',
          displayOrder: 11,
          columnSize: ColumnSizes.wide,
          sortable: true,
          filterable: true
        },
        {
          id: 'Check Number',
          displayKey: 'Check Number',
          headerText: 'Check Number',
          displayOrder: 12,
          columnSize: ColumnSizes.normal,
          sortable: true,
          filterable: true
        }
      ]
    };
  }
};

