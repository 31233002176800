/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface ImplementationDetailViewModel {
  id?: string;
  implementationId?: string;
  implementationStepId?: string;
  implementationStepCode?: string | null;
  implementationStepName?: string | null;
  order?: number;
  status?: string | null;
  createdBy?: string | null;
  createdDate?: string;
  updatedBy?: string | null;
  updatedDate?: string | null;
}
