import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationService } from '@core/services/notification.service';
import { UserMaintenanceSecurityWrapperService } from '@core/services/service-wrappers/user-maintenance-security-wrapper.service';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Component({
  selector: 'app-assign-roles-dialog',
  templateUrl: './assign-roles-dialog.component.html',
  styleUrls: ['./assign-roles-dialog.component.scss']
})
export class AssignRolesDialogComponent implements OnInit {
  formGroup = new UntypedFormGroup({
    roles: new UntypedFormControl(),
  });
  saving = false;
  loading = false;
  assignRolesOptions = [];
  selectedRoleIds = [];
  selectedRoles = [];
  userId = '';
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private userMaintenanceSecurityService: UserMaintenanceSecurityWrapperService,
    private notificationService: NotificationService,
    public dialogRef: MatDialogRef<AssignRolesDialogComponent>
  ) {
    this.userId = data?.data.id;
    this.selectedRoleIds = data?.data?.userRoles?.map(item => item.id);
  }

  ngOnInit(): void {
    this.getRoleList();
  }

  getRoleList(): void {
    this.loading = true;
    this.userMaintenanceSecurityService
      .apiV1RolePost({
        pageSize: 100
      }).subscribe((response) => {
        this.loading = false;
        this.assignRolesOptions = response.data;
        if(this.selectedRoleIds?.length) {
          this.selectedRoles = this.assignRolesOptions.filter(item => this.selectedRoleIds.includes(item.id))
        }
      })
  }

  save() {
    this.saving = true;
    const formData = {
      userId: this.userId,
      roleIds: this.selectedRoles.map(x => x.id)
    }
    let saveObservable: Observable<any>;
    let notification;
    notification = 'Roles Assigned';
    saveObservable = this.userMaintenanceSecurityService.apiV1UserRolesPost(formData).pipe(map((x: any) => x));

    saveObservable
      .pipe(take(1))
      .subscribe(
        (response) => {
          this.dialogRef.close(true);
          this.notificationService.success(notification);
        },
        (err) => this.notificationService.error('Saving User')
      )
      .add(() => {
        this.saving = false;
      });
  }

  trackById(index: number, item: any) {
    return item.id;
  }
}
