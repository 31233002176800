/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EligibilityReferenceViewModel } from './eligibilityReferenceViewModel';
import { EligibilityValidationViewModel } from './eligibilityValidationViewModel';

export interface EligibilityReceiverViewModel {
  id?: string;
  providerEntityIdentifierCode?: string | null;
  providerEntityIdentifierName?: string | null;
  providerLastOrgName?: string | null;
  providerFirstName?: string | null;
  providerMiddleName?: string | null;
  providerIdentificationQualifierCode?: string | null;
  providerIdentificationQualifierName?: string | null;
  providerIdentificationCode?: string | null;
  providerSpecialtyCode?: string | null;
  references?: Array<EligibilityReferenceViewModel> | null;
  validations?: Array<EligibilityValidationViewModel> | null;
}
