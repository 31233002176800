import { Injector, LOCALE_ID } from '@angular/core';
import { Validators } from '@angular/forms';
import { ColumnSizes, FilterType, FieldSelectOption } from 'components';
import { DateDisplayPipe } from 'projects/components/src/lib/shared/pipes/date-display/date-display.pipe';
import { Report } from '../../models/report';
import { LocationsLookupService, FinancialClassesLookupService } from '../../../../core/services/lookup';
import { FinancialClassActivityReportService } from './financial-class-activity-report.service';

export const FinancialClassActivityReport: Report = {
  id: 'financial-class-activity-report',
  label: 'Financial Class Activity Report',
  textCriteria: { 'Start Date': '', 'End Date': '', 'Financial Classes': '', 'Locations': '', 'Report Format': ''},
  cleanFilterParams: {
    'financialClassIds': '', 'locationIds': ''
  },
  getReportTitle: () => {
    return FinancialClassActivityReport.label + ' ' + FinancialClassActivityReport.textCriteria['Start Date'] + ' - ' + FinancialClassActivityReport.textCriteria['End Date'];
  },
  getSearchCriteria: (injector: Injector) => [
    {
      title: '',
      controls: [
        {
          label: 'Date Range',
          name: 'dateRange',
          type: 'dateRange',
          class: 'form-span-6',
          daterangeStartChanged: (event) => {
            FinancialClassActivityReport.textCriteria['Start Date'] = event.value.toLocaleDateString();
          },
          daterangeEndChanged: (event) => {
            FinancialClassActivityReport.textCriteria['End Date'] = event.value.toLocaleDateString();
          }
        },
        {
          label: '',
          name: 'dateRangeStart',
          type: 'noInput',
          class: 'display-none',
          validators: Validators.required
        },
        {
          label: '',
          name: 'dateRangeEnd',
          type: 'noInput',
          class: 'display-none',
          validators: Validators.required
        },
        {
          matLabel: 'Financial Class',
          name: 'financialClassIds',
          apiService: injector.get(FinancialClassesLookupService),
          class: 'form-span-6 multi-input',
          type: 'multiSelect',
          placeholder: 'Financial Class',
          selectOptions: (event) => {
            let listValue = []
            let listText = []
            event.forEach((x: FieldSelectOption) => {
              listValue.push(x.value)
              listText.push(x.label)
            })
            FinancialClassActivityReport.textCriteria['Financial Classes'] = listText.join(',');
            FinancialClassActivityReport.cleanFilterParams['financialClassIds'] = listValue
          }
        },
        {
          matLabel: 'Location',
          name: 'locationIds',
          apiService: injector.get(LocationsLookupService),
          type: 'multiSelect',
          class: 'form-span-6 multi-input',
          placeholder: 'Location',
          selectOptions: (event) => {
            let listValue = []
            let listText = []
            event.forEach((x: FieldSelectOption) => {

              listValue.push(x.value)
              listText.push(x.label)
            })
            FinancialClassActivityReport.textCriteria['Locations'] = listText.join(',');
            FinancialClassActivityReport.cleanFilterParams['locationIds'] = listValue
          }
        },
        {
          label: 'Report Format',
          name: 'reportFormat',
          class: 'form-span-6',
          type: 'select',
          options: [
            {
              label: 'by location of service within practice',
              value: '1'
            },
            {
              label: 'by practice',
              value: '2'
            }

          ],
          validators: Validators.required,
          selectionChanged: (event) => {
            FinancialClassActivityReport.textCriteria['Report Format'] = event.source.triggerValue;
          }
        }
      ]
    }
  ],
  getGridConfiguration: (injector: Injector) => {
    const locale = injector.get(LOCALE_ID);
    const moneyFormat = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });
    return {
      service: injector.get<any>(FinancialClassActivityReportService),
      columnDefinitions: [
        {
          id: 'Location Code',
          displayKey: 'Location Code',
          headerText: 'Location Code',
          displayOrder: 0,
          columnSize: ColumnSizes.normal,
          sortable: true,
          filterable: true
        },        {
          id: 'Location',
          displayKey: 'Location',
          headerText: 'Location',
          displayOrder:  1,
          columnSize: ColumnSizes.normal,
          sortable: true,
          filterable: true
        },
        {
          id: 'FC Code',
          displayKey: 'FC Code',
          headerText: 'FC Code',
          displayOrder: 2,
          columnSize: ColumnSizes.normal,
          sortable: true,
          filterable: true
        },
        {
          id: 'Financial Class',
          displayKey: 'Financial Class',
          headerText: 'Financial Class',
          displayOrder: 3,
          columnSize: ColumnSizes.normal,
          sortable: true,
          filterable: true
        },
        {
          id: 'Beginning AR',
          displayKey: 'Beginning AR',
          headerText: 'Beginning AR',
          displayOrder: 4,
          columnSize: ColumnSizes.normal,
          sortable: true,
          filterable: true,
          formatter: moneyFormat.format
        },
        {
          id: 'Volume',
          displayKey: 'Volume',
          headerText: 'Volume',
          displayOrder: 5,
          columnSize: ColumnSizes.narrow,
          sortable: true,
          filterable: true,
          formatter: (x) => {
            if (!isNaN(x)) {
              return parseFloat(x).toFixed(0);
            }
            else {
              return x;
            }
          }
        },
        {
          id: 'Amount',
          displayKey: 'Amount',
          headerText: 'Amount',
          displayOrder: 6,
          columnSize: ColumnSizes.narrow,
          sortable: true,
          filterable: true,
          formatter: moneyFormat.format
        },
        {
          id: 'Adj Volume',
          displayKey: 'Adj Volume',
          headerText: 'Adj Volume',
          displayOrder: 7,
          columnSize: ColumnSizes.narrow,
          sortable: true,
          filterable: true,
          formatter: (x) => {
            if (!isNaN(x)) {
              return parseFloat(x).toFixed(0);
            }
            else {
              return x;
            }
          }
        },
        {
          id: 'Adj Amount',
          displayKey: 'Adj Amount',
          headerText: 'Adj Amount',
          displayOrder: 8,
          columnSize: ColumnSizes.narrow,
          sortable: true,
          filterable: true,
          formatter: moneyFormat.format
        },
        {
          id: 'Pay Volume',
          displayKey: 'Pay Volume',
          headerText: 'Pay Volume',
          displayOrder: 9,
          columnSize: ColumnSizes.narrow,
          sortable: true,
          filterable: true,
          formatter: (x) => {
            if (!isNaN(x)) {
              return parseFloat(x).toFixed(0);
            }
            else {
              return x;
            }
          }
        },
        {
          id: 'Pay Amount',
          displayKey: 'Pay Amount',
          headerText: 'Pay Amount',
          displayOrder: 10,
          columnSize: ColumnSizes.narrow,
          sortable: true,
          filterable: true,
          formatter: moneyFormat.format
        },
        {
          id: 'Net Amount',
          displayKey: 'Net Amount',
          headerText: 'Net Amount',
          displayOrder: 11,
          columnSize: ColumnSizes.narrow,
          sortable: true,
          filterable: true,
          formatter: moneyFormat.format
        },
        {
          id: 'Ending Amount',
          displayKey: 'Ending Amount',
          headerText: 'Ending Amount',
          displayOrder: 12,
          columnSize: ColumnSizes.narrow,
          sortable: true,
          filterable: true,
          formatter: moneyFormat.format
        }
      ]
    };
  }
};
