/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { JobViewModel } from './jobViewModel';
import { DatasetViewModel } from './datasetViewModel';

export interface ExecutedJobDetailsViewModel {
  id?: string;
  datasetId?: string;
  jobId?: string | null;
  runTimeSeconds?: number;
  exitStatus?: string | null;
  executed?: string;
  executedBy?: string | null;
  resultSetSize?: number;
  resultSetJson?: string | null;
  note?: string | null;
  parameterJson?: string | null;
  reportId?: string | null;
  textParameterJson?: string | null;
  dataset?: DatasetViewModel;
  job?: JobViewModel;
}
