/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface ARTransactionViewModel {
  id?: string;
  datasetId?: string;
  visitId?: string;
  patientId?: string;
  paymentCodeId?: string;
  adjustmentCodeId?: string;
  adjustmentCode2Id?: string;
  postingDate?: string | null;
  transactionDate?: string | null;
  allowed?: number;
  paid?: number;
  adjusted?: number;
  adjusted2?: number;
  copay?: number;
  coinsurance?: number;
  deductible?: number;
  checkNumber?: string | null;
  internalControlNumber?: string | null;
  enteredBy?: string | null;
  postedBy?: string | null;
  insuranceId?: string;
}
