import { Injectable } from '@angular/core';
import { FieldSelectOption } from 'components/shared';
import {
  delinquencyCodes,
  statesUs,
  countries,
  relationOptions,
  sexOptions,
  emergencyContactOptions,
  insuranceOrder,
  scheduledJobStatus,
  holdStatuses,

} from './static-catalogues';
import { pwkReportTypeCodes } from './static-catalogues/pwk-report-type-codes';

@Injectable({
  providedIn: 'root'
})
export class FormCataloguesService {
  public delinquencyCodes = delinquencyCodes;
  public stateOptions = statesUs;
  public countryOptions = countries;
  public sexOptions = sexOptions;
  public relationOptions = relationOptions;
  public emergencyContactOptions = emergencyContactOptions;
  public insuranceOrder = insuranceOrder;
  public scheduledJobStatus = scheduledJobStatus;
  public holdStatuses = holdStatuses;
  public pwkReportTypes = pwkReportTypeCodes

  constructor() {}

  getCatalogue(name: any): (FieldSelectOption | string)[] {
    return this[name] || [];
  }
}
