import { CdkPortal } from '@angular/cdk/portal';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { HeaderService } from '../header.service';

// TODO: On Push
@Component({
  selector: 'lib-header-content',
  templateUrl: './header-content.component.html',
  styleUrls: ['./header-content.component.scss']
})
export class HeaderContentComponent implements OnInit, OnDestroy {
  @ViewChild(CdkPortal, { static: true }) headerPortal: CdkPortal;
  constructor(public header: HeaderService) {}

  ngOnInit(): void {
    this.header.headerOutlet.next(this.headerPortal);
  }
  ngOnDestroy() {
    this.header.headerOutlet.next(null);
  }
}
