import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  BillingHistoryViewModel,
  BillingService,
  PagedModelRequest,
  RebillOptionsViewModel,
  RemoveBillingViewModel
} from '../../../../../projects/data/src/public-api';
import { BaseWrapperService } from './base-wrapper.service';
import { SessionStorageService } from '../session-storage.service';

@Injectable({
  providedIn: 'root'
})
export class BillingWrapperService extends BaseWrapperService {
  constructor(private billingService: BillingService, protected sessionStorageService: SessionStorageService) {
    super();
  }

  apiV1BillingIdGet(id: string): Observable<Array<BillingHistoryViewModel>> {
    return this.billingService.apiV1BillingIdGet(id, this.getDataSetId());
  }

  apiV1BillingRemovePost(formdata: RemoveBillingViewModel): Observable<any> {
    return this.billingService.apiV1BillingRemovePost(this.getDataSetId(), formdata);
  }

  apiV1BillingBillingFileHistoryPost(pagedModelRequest?: PagedModelRequest): Observable<any> {
    return this.billingService.apiV1BillingBillingFileHistoryPost(this.getDataSetId(), pagedModelRequest);
  }

  apiV1BillingUndoIdPost(id: string): Observable<any> {
    return this.billingService.apiV1BillingUndoIdPost(id, this.getDataSetId());
  }

  apiV1BillingRebillPost(billOptions: RebillOptionsViewModel): Observable<any> {
    return this.billingService.apiV1BillingRebillPost(this.getDataSetId(), billOptions);
  }

  apiV1BillingRebillCountPost(billOptions: RebillOptionsViewModel): Observable<any> {
    return this.billingService.apiV1BillingRebillCountPost(this.getDataSetId(),  billOptions);
  }
  apiV1BillingGeneratePaperClaimsPost(): Observable<any> {
    return this.billingService.apiV1BillingGeneratePaperClaimsPost(this.getDataSetId());
  }
  apiV1BillingPrintIdPost(id: string): Observable<any> {
    return this.billingService.apiV1BillingPrintIdPost(id, this.getDataSetId());
  }
}
