import { createFeatureSelector, createSelector } from '@ngrx/store';

import { DataSetState, DataSetAdapter } from './data-set.state';

export const FEATURE_NAME = 'dataSets';

export const getUserClientState = createFeatureSelector<DataSetState>(FEATURE_NAME);

export const { selectIds, selectEntities, selectAll, selectTotal } = DataSetAdapter.getSelectors(getUserClientState);

export const getSelectedDataSetId = createSelector(getUserClientState, (state) => state?.selectedDataSetId);
