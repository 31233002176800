import { CurrencyPipe } from '@angular/common';
import { Directive, HostListener, Self, OnDestroy, AfterViewInit, Input } from '@angular/core';
import { NgControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BaseFormatterDirective } from './base-formatter.directive';

@Directive({
  selector: '[libMoneyFormatter]',
  providers: [CurrencyPipe]
})
export class MoneyFormatterDirective extends BaseFormatterDirective implements OnDestroy, AfterViewInit {
  @Input() decimalCount: number;

  constructor(@Self() private ngControl: NgControl, private currencyPipe: CurrencyPipe) {
    super(ngControl);
  }
  ngAfterViewInit() {
    this.setValue(this.formatInput(this.ngControl.value));
    this.ngControl.control.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(this.updateValue.bind(this));
  }
  @HostListener('focus') onFocus() {
    this.baseOnFocus();
  }
  @HostListener('blur') onBlur() {
    this.baseOnBlur();
  }

  ngOnDestroy() {
    this.onDestroy();
  }
  formatInput(v) {
    return this.currencyPipe.transform(v, 'USD', '');
  }
}
