import { Injectable } from '@angular/core';
import { PlaceOfServiceCrosswalkService } from 'projects/data/src/lib/api/placeOfServiceCrosswalk.service';
import { UpdatePlaceOfServiceCrosswalkViewModel } from 'projects/data/src/lib/model/updatePlaceOfServiceCrosswalkViewModel';
import { BaseWrapperService } from './base-wrapper.service';
import { SessionStorageService } from '../session-storage.service';

@Injectable({
  providedIn: 'root'
})
export class PlaceOfServiceCrosswalkWrapperService extends BaseWrapperService {
  constructor(private service: PlaceOfServiceCrosswalkService, protected sessionStorageService: SessionStorageService) {
    super();
  }

  apiV1PlaceOfServiceCrosswalkInsuranceCarrierInsurancecarrieridGet(insuranceCarrierId) {
    return this.service.apiV1PlaceOfServiceCrosswalkInsuranceCarrierInsurancecarrieridGet(
      insuranceCarrierId,
      this.getDataSetId()
    );
  }

  apiV1PlaceOfServiceCrosswalkUpdateInsurancecarrieridPost(
    insuranceCarrierId,
    crosswalks: UpdatePlaceOfServiceCrosswalkViewModel[]
  ) {
    return this.service.apiV1PlaceOfServiceCrosswalkUpdateInsurancecarrieridPost(
      insuranceCarrierId,
      this.getDataSetId(),
      crosswalks
    );
  }
}
