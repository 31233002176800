<header class="sub-page-layout__header insuranceCarriers__header">
  <div class="insuranceCarriers__header-text h2 h2--semi-bold">Insurance Carriers</div>
  <mat-slide-toggle
    class="insuranceCarriers__active-toggle"
    [checked]="activeFilter === 'active'"
    color="primary"
    (change)="showInactiveChanges($event)"
  >
    <div *ngIf="activeFilter !== 'inactive'">Active</div>
    <div *ngIf="activeFilter === 'inactive'">Inactive</div>
  </mat-slide-toggle>
  <button mat-flat-button color="primary" class="insuranceCarriers__add-item" (click)="uploadCodes()" *ngIf="!viewOnly">
    <mat-icon>file_upload</mat-icon> Upload Insurance Carriers
  </button>
  <button
    mat-flat-button
    color="primary"
    class="insuranceCarriers__add-item"
    (click)="addInsuranceCarrier()"
    *ngIf="!viewOnly"
  >
    <mat-icon>add_circle_outline</mat-icon> Add Insurance Carrier
  </button>
</header>
<div *ngIf="dataSource && gridConfig" class="lib-grid__grid-container">
  <lib-grid [dataSource]="dataSource" [gridConfiguration]="gridConfig" skipLoadOnInit="true" loadAfterInit="true">
    <div class="">
      <h4>No Insurance Carriers Found.</h4>
    </div>
  </lib-grid>
</div>
<footer *ngIf="isImplementation && itemCount > 0" class="insuranceCarriers__footer">
  <lib-button-loading
    color="primary"
    class="insuranceCarriers__button"
    [invalid]="itemCount === 0"
    (clickEvent)="next()"
    >Next
  </lib-button-loading>
</footer>
