import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AutoBreadcrumbComponent } from './auto-breadcrumb.component';
import { BreadcrumbsModule } from 'components';

@NgModule({
  declarations: [AutoBreadcrumbComponent],
  exports: [AutoBreadcrumbComponent],
  imports: [CommonModule, BreadcrumbsModule]
})
export class AutoBreadcrumbModule {}
