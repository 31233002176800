import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService } from '@core/services/local-storage.service';
import { SearchWrapperService } from '@core/services/service-wrappers/search-wrapper.service';
import { FormGroupDefinition } from 'components';
import { SearchRequestViewModel } from 'data';
import { first, map } from 'rxjs/operators';

@Component({
  selector: 'app-global-search',
  templateUrl: './global-search.component.html',
  styleUrls: ['./global-search.component.scss']
})
export class GlobalSearchComponent implements OnInit {
  formGroup: UntypedFormGroup;
  searchSelection = 'contains';
  subfields = ['ssn','mrn','altid','employer','guarantor','guarantor_address','guarantor_phone','policy_number','notes','first_last_name','icn','check_number','procedure_results','batch_check_number'];
  @Output() cancel: EventEmitter<void> = new EventEmitter<void>();
  
  constructor(private searchService: SearchWrapperService,
    public route: ActivatedRoute,
    private router: Router,
    private localStorageService: LocalStorageService
    ) {
    this.formGroup = new UntypedFormGroup({
      searchType: new UntypedFormControl(''),
      searchTerms: new UntypedFormControl('', Validators.required),
      patient: new UntypedFormControl(''),
      ssn: new UntypedFormControl(''),
      mrn: new UntypedFormControl(''),
      altid: new UntypedFormControl(''),
      employer: new UntypedFormControl(''),
      guarantor: new UntypedFormControl(''),
      guarantor_phone: new UntypedFormControl(''),
      guarantor_address: new UntypedFormControl(''),
      policy_number: new UntypedFormControl(''),
      notes: new UntypedFormControl(''),
      first_last_name: new UntypedFormControl(''),
      visits: new UntypedFormControl(''),
      icn: new UntypedFormControl(''),
      check_number: new UntypedFormControl(''),
      procedure_results: new UntypedFormControl(''),
      dateStart: new UntypedFormControl(''),
      dateEnd: new UntypedFormControl(''),
      batches: new UntypedFormControl(''),
      batch_check_number: new UntypedFormControl(''),
      codes: new UntypedFormControl(''),
      other: new UntypedFormControl('')
    });
   }

  
  ngOnInit(): void {}

  clear() {
    this.formGroup.get('searchTerms').setValue(null)
  }

  clearFilters() {  
    this.formGroup.reset();
    this.formGroup.get('searchType').setValue('contains');
  }

  subfieldToggle(field, parent) {
    var isChecked = this.formGroup.get(field).value;
    if (!isChecked) {
      this.formGroup.get(parent).setValue(true);
    }
  }

  patientToggle() {
    var isChecked = this.formGroup.get('patient').value;
    if (isChecked === false) {
      this.formGroup.get('ssn').setValue(isChecked);
      this.formGroup.get('mrn').setValue(isChecked);
      this.formGroup.get('altid').setValue(isChecked);
      this.formGroup.get('employer').setValue(isChecked);
      this.formGroup.get('guarantor').setValue(isChecked);
      this.formGroup.get('guarantor_phone').setValue(isChecked);
      this.formGroup.get('guarantor_address').setValue(isChecked);
      this.formGroup.get('policy_number').setValue(isChecked);
      this.formGroup.get('notes').setValue(isChecked);
      this.formGroup.get('first_last_name').setValue(isChecked);
    }
  }
  
  visitToggle() {
    var isChecked = this.formGroup.get('visits').value; 
    if (isChecked === false) {
      this.formGroup.get('icn').setValue(isChecked);
      this.formGroup.get('check_number').setValue(isChecked);
      this.formGroup.get('procedure_results').setValue(isChecked);
    }
  }

  batchToggle() {
    var isChecked = this.formGroup.get('batches').value;
    if (isChecked === false) {
      this.formGroup.get('batch_check_number').setValue(isChecked);
    }
  }

  closeWindow() {
    this.cancel.emit();
  }

  search() {
    const fieldselections = [];
    
    this.subfields.forEach(item => {
      var fieldValue = this.formGroup.get(item).value;
      if (fieldValue === true) {
        fieldselections.push(item);
      }
    });

    const formdata: SearchRequestViewModel = {
      term: this.formGroup.get('searchTerms').value,
      fields: fieldselections,
      pageNum: 1,
      pageSize: 9999,
      searchPatients: this.formGroup.get('patient').value === true ? true : false,
      searchVisits: this.formGroup.get('visits').value === true ? true : false,
      searchBatches: this.formGroup.get('batches').value === true ? true : false,
      searchCodes: this.formGroup.get('codes').value === true ? true : false,
      searchOther: this.formGroup.get('other').value === true ? true : false,
      startsWith: this.searchSelection === 'contains' ? false : true,
    }
    if (this.formGroup.get('dateStart').value) {
      formdata.startDate = this.formGroup.get('dateStart').value;
    }
    if (this.formGroup.get('dateEnd').value) {
      formdata.endDate = this.formGroup.get('dateEnd').value;
    }

    var initialPage = formdata.searchPatients === true ? 'patient-results' : 
                      formdata.searchVisits === true ? 'visit-results' : 
                      formdata.searchBatches === true ? 'batch-results' : 
                      formdata.searchCodes === true ? 'code-results' : 
                      'other-results'  

    this.localStorageService.setItem('global-search', formdata);
    this.cancel.emit();
    this.router
          .navigateByUrl('/', { skipLocationChange: true })
          .then(() => this.router.navigate(['/', 'global-search', initialPage ]));
  }
}
