import { Validators } from '@angular/forms';
import { Report } from '../../models/report';
import { Injector, LOCALE_ID } from '@angular/core';
import { ColumnDefinition, ColumnSizes } from 'components';
import { ServiceAnalysisReportService } from './service-analysis.service';
import { DateDisplayPipe } from 'projects/components/src/lib/shared/pipes/date-display/date-display.pipe';
import { ReportDownloadFormat } from '../../models/report-download-format';

const moneyFormat = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });
const dateDisplayPipe: DateDisplayPipe = new DateDisplayPipe('en-us');
        
export const ServiceAnalysisReport: Report = {
    id: 'service-analysis',
    label: 'Service Analysis Report',
    textCriteria: { 'Start Date': '', 'End Date': '', 'Format' : '', 'Patient Detail': '', 'Service Detail': ''},
    getReportTitle: () => {
        return ServiceAnalysisReport.label + ' ' + ServiceAnalysisReport.textCriteria['Start Date'] + ' - ' 
            + ServiceAnalysisReport.textCriteria['End Date'];
    },
    serverPdf: true,
    hasDetail: true,
    // 3 available grid definitions
    gridColumnDefinitions: [
      [
        {
          id: 'Attending Provider',
          displayKey: 'attending_provider',
          headerText: 'Attending Provider',
          displayOrder: 0,
          columnSize: ColumnSizes.extraWide,
          sortable: true,
          filterable: true
        },
        {
          id: 'Patient Name',
          displayKey: 'patient_name',
          headerText: 'Patient Name',
          displayOrder: 1,
          columnSize: ColumnSizes.wide,
          sortable: true,
          filterable: true
        },
        {
          id: 'Service Date',
          displayKey: 'service_date',
          headerText: 'Service Date',
          displayOrder: 2,
          columnSize: ColumnSizes.normal,
          sortable: true,
          filterable: true,
          formatter: (x) => { let d = dateDisplayPipe.transform(x); return d == "--" ? "" : d;  },
        },
        {
          id: 'Diagnosis',
          displayKey: 'diagnosis_code',
          headerText: 'Diagnosis',
          displayOrder: 4,
          columnSize: ColumnSizes.narrow,
          sortable: true,
          filterable: true
        },
        {
          id: 'Proc Mod',
          displayKey: 'procedure_modifiers',
          headerText: 'Procedure / Modifiers',
          displayOrder: 5,
          columnSize: ColumnSizes.wide,
          sortable: true,
          filterable: true
        },
        {
          id: 'Units',
          displayKey: 'quantity',
          headerText: 'Units',
          displayOrder: 6,
          columnSize: ColumnSizes.narrow,
          sortable: true,
          filterable: true,
          
        },
        {
          id: 'Charges',
          displayKey: 'procedure_amount',
          headerText: 'Charges',
          displayOrder: 7,
          columnSize: ColumnSizes.normal,
          sortable: true,
          filterable: true,
          formatter: moneyFormat.format
        },
        {
          id: 'Insurance Payments',
          displayKey: 'insurance_payment_amount',
          headerText: 'Insurance Payments',
          displayOrder: 8,
          columnSize: ColumnSizes.normal,
          sortable: true,
          filterable: true,
          formatter: moneyFormat.format
        },
        {
          id: 'Patient Payments',
          displayKey: 'patient_payment_amount',
          headerText: 'Patient Payments',
          displayOrder: 9,
          columnSize: ColumnSizes.normal,
          sortable: true,
          filterable: true,
          formatter: moneyFormat.format
        },
        {
          id: 'Adjustments',
          displayKey: 'adjustment_amount',
          headerText: 'Adjustments',
          displayOrder: 10,
          columnSize: ColumnSizes.normal,
          sortable: true,
          filterable: true,
          formatter: moneyFormat.format
        },
        {
          id: 'Insurance Balance',
          displayKey: 'insurance_balance',
          headerText: 'Insurance Balance',
          displayOrder: 11,
          columnSize: ColumnSizes.normal,
          sortable: true,
          filterable: true,
          formatter: moneyFormat.format
        },
        {
          id: 'Patient Balance',
          displayKey: 'patient_balance',
          headerText: 'Patient Balance',
          displayOrder: 12,
          columnSize: ColumnSizes.normal,
          sortable: true,
          filterable: true,
          formatter: moneyFormat.format
        },
          {
        id: 'Visits',
        displayKey: 'visit_count',
        headerText: 'Visits',
        displayOrder: 13,
        columnSize: ColumnSizes.narrow,
        sortable: true,
        filterable: true
        }           
      ],
      [
        {
          id: 'Service Month',
          displayKey: 'service_month',
          headerText: 'Service Month',
          displayOrder: 0,
          columnSize: ColumnSizes.wide,
          sortable: true,
          filterable: true
        },
        {
          id: 'Patient Name',
          displayKey: 'patient_name',
          headerText: 'Patient Name',
          displayOrder: 1,
          columnSize: ColumnSizes.wide,
          sortable: true,
          filterable: true
        },
        {
          id: 'Service Date',
          displayKey: 'service_date',
          headerText: 'Service Date',
          displayOrder: 2,
          columnSize: ColumnSizes.normal,
          sortable: true,
          filterable: true,
          formatter: (x) => { let d = dateDisplayPipe.transform(x); return d == "--" ? "" : d;  },
        },
        {
          id: 'Diagnosis',
          displayKey: 'diagnosis_code',
          headerText: 'Diagnosis',
          displayOrder: 4,
          columnSize: ColumnSizes.narrow,
          sortable: true,
          filterable: true
        },
        {
          id: 'Proc Mod',
          displayKey: 'procedure_modifiers',
          headerText: 'Procedure / Modifiers',
          displayOrder: 5,
          columnSize: ColumnSizes.wide,
          sortable: true,
          filterable: true
        },
        {
          id: 'Units',
          displayKey: 'quantity',
          headerText: 'Units',
          displayOrder: 6,
          columnSize: ColumnSizes.narrow,
          sortable: true,
          filterable: true,
          
        },
        {
          id: 'Charges',
          displayKey: 'procedure_amount',
          headerText: 'Charges',
          displayOrder: 7,
          columnSize: ColumnSizes.normal,
          sortable: true,
          filterable: true,
          formatter: moneyFormat.format
        },
        {
          id: 'Insurance Payments',
          displayKey: 'insurance_payment_amount',
          headerText: 'Insurance Payments',
          displayOrder: 8,
          columnSize: ColumnSizes.normal,
          sortable: true,
          filterable: true,
          formatter: moneyFormat.format
        },
        {
          id: 'Patient Payments',
          displayKey: 'patient_payment_amount',
          headerText: 'Patient Payments',
          displayOrder: 9,
          columnSize: ColumnSizes.normal,
          sortable: true,
          filterable: true,
          formatter: moneyFormat.format
        },
        {
          id: 'Adjustments',
          displayKey: 'adjustment_amount',
          headerText: 'Adjustments',
          displayOrder: 10,
          columnSize: ColumnSizes.normal,
          sortable: true,
          filterable: true,
          formatter: moneyFormat.format
        },
        {
          id: 'Insurance Balance',
          displayKey: 'insurance_balance',
          headerText: 'Insurance Balance',
          displayOrder: 11,
          columnSize: ColumnSizes.normal,
          sortable: true,
          filterable: true,
          formatter: moneyFormat.format
        },
        {
          id: 'Patient Balance',
          displayKey: 'patient_balance',
          headerText: 'Patient Balance',
          displayOrder: 12,
          columnSize: ColumnSizes.normal,
          sortable: true,
          filterable: true,
          formatter: moneyFormat.format
        },
          {
        id: 'Visits',
        displayKey: 'visit_count',
        headerText: 'Visits',
        displayOrder: 13,
        columnSize: ColumnSizes.narrow,
        sortable: true,
        filterable: true
        }           
      ],
      [
        {
          id: 'Location',
          displayKey: 'location',
          headerText: 'Location',
          displayOrder: 0,
          columnSize: ColumnSizes.extraWide,
          sortable: true,
          filterable: true
        },
        {
          id: 'Patient Name',
          displayKey: 'patient_name',
          headerText: 'Patient Name',
          displayOrder: 1,
          columnSize: ColumnSizes.wide,
          sortable: true,
          filterable: true
        },
        {
          id: 'Service Date',
          displayKey: 'service_date',
          headerText: 'Service Date',
          displayOrder: 2,
          columnSize: ColumnSizes.normal,
          sortable: true,
          filterable: true,
          formatter: (x) => { let d = dateDisplayPipe.transform(x); return d == "--" ? "" : d;  },
        },
        {
          id: 'Diagnosis',
          displayKey: 'diagnosis_code',
          headerText: 'Diagnosis',
          displayOrder: 4,
          columnSize: ColumnSizes.narrow,
          sortable: true,
          filterable: true
        },
        {
          id: 'Proc Mod',
          displayKey: 'procedure_modifiers',
          headerText: 'Procedure / Modifiers',
          displayOrder: 5,
          columnSize: ColumnSizes.wide,
          sortable: true,
          filterable: true
        },
        {
          id: 'Units',
          displayKey: 'quantity',
          headerText: 'Units',
          displayOrder: 6,
          columnSize: ColumnSizes.narrow,
          sortable: true,
          filterable: true,
          
        },
        {
          id: 'Charges',
          displayKey: 'procedure_amount',
          headerText: 'Charges',
          displayOrder: 7,
          columnSize: ColumnSizes.normal,
          sortable: true,
          filterable: true,
          formatter: moneyFormat.format
        },
        {
          id: 'Insurance Payments',
          displayKey: 'insurance_payment_amount',
          headerText: 'Insurance Payments',
          displayOrder: 8,
          columnSize: ColumnSizes.normal,
          sortable: true,
          filterable: true,
          formatter: moneyFormat.format
        },
        {
          id: 'Patient Payments',
          displayKey: 'patient_payment_amount',
          headerText: 'Patient Payments',
          displayOrder: 9,
          columnSize: ColumnSizes.normal,
          sortable: true,
          filterable: true,
          formatter: moneyFormat.format
        },
        {
          id: 'Adjustments',
          displayKey: 'adjustment_amount',
          headerText: 'Adjustments',
          displayOrder: 10,
          columnSize: ColumnSizes.normal,
          sortable: true,
          filterable: true,
          formatter: moneyFormat.format
        },
        {
          id: 'Insurance Balance',
          displayKey: 'insurance_balance',
          headerText: 'Insurance Balance',
          displayOrder: 11,
          columnSize: ColumnSizes.normal,
          sortable: true,
          filterable: true,
          formatter: moneyFormat.format
        },
        {
          id: 'Patient Balance',
          displayKey: 'patient_balance',
          headerText: 'Patient Balance',
          displayOrder: 12,
          columnSize: ColumnSizes.normal,
          sortable: true,
          filterable: true,
          formatter: moneyFormat.format
        },
        {
          id: 'Visits',
          displayKey: 'visit_count',
          headerText: 'Visits',
          displayOrder: 13,
          columnSize: ColumnSizes.narrow,
          sortable: true,
          filterable: true
        }           
      ]
    ],
    getSearchCriteria: (injector: Injector) => [
        {
          title: 'Where',
          controls: [
            {
              label: 'Service Date Range',
              name: 'dateRange',
              type: 'dateRange',
              class: 'form-span-12',
              daterangeStartChanged: (event) => {
                ServiceAnalysisReport.textCriteria['Start Date'] = event.value.toLocaleDateString();
              },
              daterangeEndChanged: (event) => {
                ServiceAnalysisReport.textCriteria['End Date'] = event.value.toLocaleDateString();
              }
            },
            {
              label: '',
              name: 'dateRangeStart',
              type: 'noInput',
              class: 'display-none',
              validators: Validators.required
            },
            {
              label: '',
              name: 'dateRangeEnd',
              type: 'noInput',
              class: 'display-none',
              validators: Validators.required
            },
            {
              label: 'Report Format',
              name: 'format',
              type: 'select',
              options: [
                {
                  label: 'Rendering Provider',
                  value: 'P'
                },
                {
                  label: 'Service Month and Year',
                  value: 'S'
                }
                ,
                {
                  label: 'Location',
                  value: 'L'
                }
              ],
              class: 'form-span-12',
              validators: Validators.required,
              selectionChanged: (event) => {
                ServiceAnalysisReport.textCriteria['Format'] = event.source.triggerValue;
                // based on report format selected, change grid column definition and report download format
                if (event.source.value === 'P') {
                  selectedColumnDefinitions = ServiceAnalysisReport.gridColumnDefinitions[0];
                  ServiceAnalysisReport.reportDownloadFormat = ServiceAnalysisReport.reportDownloadFormats[0];
                }
                else if (event.source.value === 'S') {
                  selectedColumnDefinitions = ServiceAnalysisReport.gridColumnDefinitions[1];
                  ServiceAnalysisReport.reportDownloadFormat = ServiceAnalysisReport.reportDownloadFormats[1];
                }
                else if (event.source.value === 'L') {
                  selectedColumnDefinitions = ServiceAnalysisReport.gridColumnDefinitions[2];
                  ServiceAnalysisReport.reportDownloadFormat = ServiceAnalysisReport.reportDownloadFormats[2];
                }
              }
            },
            {
              label: 'Include Patient Detail',
              name: 'patientDetail',
              type: 'checkbox',
              initial: true,
              class: 'form-span-7 short-checkbox',
              checkedChanged: (event) => {
                ServiceAnalysisReport.formGroup.controls['serviceDetail'].setValue(event.source.checked);
                ServiceAnalysisReport.textCriteria['Patient Detail'] = ServiceAnalysisReport.textCriteria['Service Detail'] = event.source.checked;
                ServiceAnalysisReport.hasDetail = ServiceAnalysisReport.serverPdf = event.source.checked;
              }
            },
            {
              label: 'Include Service Detail',
              name: 'serviceDetail',
              type: 'checkbox',
              initial: true,
              class: 'form-span-7 short-checkbox',
              checkedChanged: (event) => {
                ServiceAnalysisReport.textCriteria['Service Detail'] = event.source.checked;
                if (event.source.checked)  {
                  ServiceAnalysisReport.textCriteria['Patient Detail'] = true;
                  ServiceAnalysisReport.formGroup.controls['patientDetail'].setValue(true);
                  ServiceAnalysisReport.hasDetail = true;
                }
              }
            }
          ]
        }
      ],
    getGridConfiguration: (injector: Injector) => {
        const locale = injector.get(LOCALE_ID);
        return {
          service: injector.get<any>(ServiceAnalysisReportService),
          columnDefinitions: 
            selectedColumnDefinitions.filter(d => 
              ServiceAnalysisReport.formGroup.controls['patientDetail'].value && ServiceAnalysisReport.formGroup.controls['serviceDetail'].value ||
                  d.id != 'Service Date' && d.id != 'Diagnosis' && d.id != 'Proc Mod'
              ).filter(dd => ServiceAnalysisReport.formGroup.controls['patientDetail'].value || dd.id != 'Patient Name')
        }
    },
    // 3 available report formats
    reportDownloadFormats : [
      {
        headers: [
            {header: "Date", alignment: "L"}, {header: "Diagnosis", alignment: "L"}, {header: "Procedure/Modifiers", alignment: "L"},
            {header: "Units", alignment: "R"}, {header: "Charges", alignment: "R"}, {header: "Insurance Payments", alignment: "R"},
            {header: "Patient Payments", alignment: "R"}, {header: "Adjustments", alignment: "R"}, {header: "Insurance Balance", alignment: "R"},
            {header: "Patient Balance", alignment: "R"}, {header: "Visits", alignment: "R"}
        ],
        records: [
              {
                level: 4,
                bold: true,
                spaceBefore: 10,
                spaceAfter: 5,
                pageBreakBefore : true,
                columns: [ 
                    { name: "attending_provider", span: 3}, {name: "quantity" }, {name : "procedure_amount"}, {name : "insurance_payment_amount"},
                    {name : "patient_payment_amount"}, {name : "adjustment_amount"}, {name : "insurance_balance"}, {name : "patient_balance"}, {name : "visit_count"}
                ]
            },
            {
                level: 3,
                bold: true,
                spaceBefore: 10,
                spaceAfter: 5,
                pageBreakBefore : true,
                columns: [ 
                    { name: "attending_provider", span: 3}, {name: "quantity" }, {name : "procedure_amount"}, {name : "insurance_payment_amount"},
                    {name : "patient_payment_amount"}, {name : "adjustment_amount"}, {name : "insurance_balance"}, {name : "patient_balance"}, {name : "visit_count"}
                ]
            },
            {
                level: 2,
                spaceBefore: 5,
                columns: [ 
                    { name: "patient_name", span: 3}, {name: "quantity" }, {name : "procedure_amount"}, {name : "insurance_payment_amount"},
                    {name : "patient_payment_amount"}, {name : "adjustment_amount"}, {name : "insurance_balance"}, {name : "patient_balance"}, 
                    {name : "visit_count"}
                ]
            },
            {
                level: 1,
                borderBottom: 1,
                columns: [ 
                    { name: "service_date", paddingLeft: 6}, {name: "diagnosis_code" }, {name: "procedure_modifiers" }, {name: "quantity" }, {name : "procedure_amount"}, 
                    {name : "insurance_payment_amount"}, {name : "patient_payment_amount"}, {name : "adjustment_amount"}, {name : "insurance_balance"}, {name : "patient_balance"}, {name : "visit_count"}
                ]
            }
        ]
    },
    {
      headers: [
          {header: "Service Month", alignment: "L"},  {header: "Diag", alignment: "L"}, {header: "Proc/Mod", alignment: "L"},
          {header: "Units", alignment: "R"}, {header: "Charges", alignment: "R"}, 
          {header: "Ins Disb", alignment: "R"}, {header: "Pat Disb", alignment: "R"}, {header: "Adjs", alignment: "R"}, 
          {header: "Ins Bal", alignment: "R"}, {header: "Pat Bal", alignment: "R"}, {header: "Visits", alignment: "R"}
      ],
      records: [
          {
              level: 3,
              bold: true,
              spaceBefore: 10,
              spaceAfter: 5,
              pageBreakBefore : true,
              columns: [ 
                  { name: "service_month", span: 3}, {name: "quantity" }, {name : "procedure_amount"}, {name : "insurance_payment_amount"},
                  {name : "patient_payment_amount"}, {name : "adjustment_amount"}, {name : "insurance_balance"}, {name : "patient_balance"}, 
                  {name : "visit_count"}
              ]
          },
          {
              level: 2,
              spaceBefore: 5,
              columns: [ 
                  { name: "patient_name", span: 3}, {name: "quantity" }, {name : "procedure_amount"}, {name : "insurance_payment_amount"},
                  {name : "patient_payment_amount"}, {name : "adjustment_amount"}, {name : "insurance_balance"}, {name : "patient_balance"}, {name : "visit_count"}
              ]
          },
          {
              level: 1,
              borderBottom: 1,
              columns: [ 
                  { name: "service_date", paddingLeft: 6}, {name: "diagnosis_code" }, {name: "procedure_modifiers" }, {name: "quantity" }, {name : "procedure_amount"}, 
                  {name : "insurance_payment_amount"}, {name : "patient_payment_amount"}, {name : "adjustment_amount"}, {name : "insurance_balance"}, {name : "patient_balance"}, {name : "visit_count"}
              ]
          }
      ]
    },
    {
      headers: [
          {header: "Location", alignment: "L"},  {header: "Diag", alignment: "L"}, {header: "Proc/Mod", alignment: "L"},
          {header: "Units", alignment: "R"}, {header: "Charges", alignment: "R"}, 
          {header: "Ins Disb", alignment: "R"}, {header: "Pat Disb", alignment: "R"}, {header: "Adjs", alignment: "R"}, 
          {header: "Ins Bal", alignment: "R"}, {header: "Pat Bal", alignment: "R"}, {header: "Visits", alignment: "R"}
      ],
      records: [
          {
              level: 3,
              bold: true,
              spaceBefore: 10,
              spaceAfter: 5,
              pageBreakBefore : true,
              columns: [ 
                  {name: "location", span: 3}, {name: "quantity" }, {name : "procedure_amount"}, {name : "insurance_payment_amount"},
                  {name : "patient_payment_amount"}, {name : "adjustment_amount"}, {name : "insurance_balance"}, {name : "patient_balance"}, 
                  {name : "visit_count"}
              ]
          },
          {
              level: 2,
              spaceBefore: 5,
              columns: [ 
                  {name: "patient_name", span: 3}, {name: "quantity" }, {name : "procedure_amount"}, {name : "insurance_payment_amount"},
                  {name : "patient_payment_amount"}, {name : "adjustment_amount"}, {name : "insurance_balance"}, {name : "patient_balance"}, 
                  {name : "visit_count"}
              ]
          },
          {
              level: 1,
              borderBottom: 1,
              columns: [ 
                  {name: "service_date", paddingLeft: 6}, {name: "diagnosis_code" }, {name: "procedure_modifiers" }, {name: "quantity" }, {name : "procedure_amount"}, 
                  {name : "insurance_payment_amount"}, {name : "patient_payment_amount"}, {name : "adjustment_amount"}, {name : "insurance_balance"}, 
                  {name : "patient_balance"}, {name : "visit_count"}
              ]
          }
      ]
    }
    ]
}
// set default grid column definition
let selectedColumnDefinitions: ColumnDefinition[] = ServiceAnalysisReport.gridColumnDefinitions[0];
// set default report download format
ServiceAnalysisReport.reportDownloadFormat = ServiceAnalysisReport.reportDownloadFormats[0];
                  