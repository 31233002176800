import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthorizedComponent } from './core/layout/authorized/authorized.component';
import { ImplementationGuard } from './features/implementation/guards/implementation.guard';
import { MsalGuard } from '@azure/msal-angular';
import { ClientGuard } from './features/security/client.guard';

const routes: Routes = [
  {
    path: '',
    component: AuthorizedComponent,
    canActivate: [MsalGuard, ClientGuard],
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
      },
      {
        canActivate: [ImplementationGuard, ClientGuard],
        path: 'dashboard',
        loadChildren: () => import('./features/dashboard/dashboard.module').then((module) => module.DashboardModule)
      },
      {
        canActivate: [ImplementationGuard, ClientGuard],
        path: 'queues',
        loadChildren: () => import('./features/queues/queues.module').then((module) => module.QueuesModule)
      },
      {
        canActivate: [ImplementationGuard, ClientGuard],
        path: 'reports',
        loadChildren: () => import('./features/reports/reports.module').then((module) => module.ReportsModule)
      },
      {
        canActivate: [ImplementationGuard, ClientGuard],
        path: 'documents',
        loadChildren: () => import('./features/documents/documents.module').then((module) => module.DocumentsModule)
      },
      {
        canActivate: [ImplementationGuard, ClientGuard],
        path: 'accounts',
        loadChildren: () => import('./features/accounts/accounts.module').then((module) => module.AccountsModule)
      },
      {
        canActivate: [ImplementationGuard, ClientGuard],
        path: 'documents',
        loadChildren: () => import('./features/documents/documents.module').then((module) => module.DocumentsModule)
      },
      {
        canActivate: [ImplementationGuard, ClientGuard],
        path: 'files',
        loadChildren: () => import('./features/files/files.module').then((module) => module.FilesModule)
      },
      {
        canActivate: [ImplementationGuard, ClientGuard],
        path: 'transactions',
        loadChildren: () =>
          import('./features/transactions/transactions.module').then((module) => module.TransactionsModule)
      },
      {
        canActivate: [ImplementationGuard, ClientGuard],
        path: 'settings',
        loadChildren: () => import('./features/settings/settings.module').then((module) => module.SettingsModule)
      },
      {
        canActivate: [ImplementationGuard, ClientGuard],
        path: 'help',
        loadChildren: () => import('./features/help/help.module').then((module) => module.HelpModule)
      },
      {
        canActivate: [ClientGuard],
        path: 'implementation',
        loadChildren: () => import('./features/implementation/implementation.module').then((module) => module.ImplementationModule)
      },
      {
        canActivate: [ImplementationGuard, ClientGuard],
        path: 'global-search',
        loadChildren: () => import('./features/global-search-results/global-search-results.module').then((module) => module.GlobalSearchResultsModule)
      },
      {
        canActivate: [ImplementationGuard, ClientGuard],
        path: 'user-configurations',
        loadChildren: () => import('./features/user-configurations/user-configurations.module').then((module) => module.UserConfigurationsModule)
      },
    ]
  },
  { path: 'error', loadChildren: () => import('./features/error/error.module').then((module) => module.ErrorModule) },
  {
    path: 'login',
    loadChildren: () =>
      import('./features/security/login/login.module').then((module) => module.LoginModule)
  },
  {
    path: 'logout',
    loadChildren: () =>
      import('./features/security/logout/logout.module').then((module) => module.LogoutModule)
  },
  {
    path: 'unauthorized',
    loadChildren: () =>
      import('./features/security/unauthorized/unauthorized.module').then((module) => module.UnauthorizedModule)
  },
  { path: '**', redirectTo: '', pathMatch: 'full' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    // useHash: true,
    // enableTracing: true,
    scrollOffset: [0, 100]
})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
