/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface PlaceOfServiceCrosswalkViewModel {
  id?: string;
  datasetId?: string;
  insuranceCarrierId?: string | null;
  originalPlaceOfServiceCodeId?: string | null;
  originalPlaceOfServiceCode?: string | null;
  originalPlaceOfServiceName?: string | null;
  replacementPlaceOfServiceCodeId?: string | null;
  replacementPlaceOfServiceCode?: string | null;
  replacementPlaceOfServiceName?: string | null;
}
