import { Action, createReducer, on } from '@ngrx/store';

import * as RemindersActions from './reminders.actions';
import { RemindersState, RemindersInitialState, RemindersAdapter } from './reminders.state';

const reducer = createReducer(
  RemindersInitialState,
  on(RemindersActions.loadReminders, (state, action) => {
    return { ...state, selectedNoteId: '', entities: null, loaded: false, loading: true };
  }),
  on(RemindersActions.updateReminders, (state, action) => {
    return { ...state, loaded: false, loading: true };
  }),
  on(RemindersActions.loadRemindersSuccess, (state, action) => {
    return RemindersAdapter.setAll(action.reminders, { ...state, loaded: true, loading: false });
  }),
  on(RemindersActions.loadRemindersFailure, (state, action) => {
    return { ...state, account: null, loaded: false, loading: false, error: action.error };
  }),
  on(RemindersActions.unloadReminders, (state, action) => {
    return RemindersInitialState;
  })
);

export function accountNotesReducer(state: RemindersState | undefined, action: Action): RemindersState {
  return reducer(state, action);
}
