import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  BodyClasses,
  ColumnDefinition,
  ColumnDisplayType,
  ColumnSizes,
  GridComponent,
  GridConfiguration,
  OrderByPipe,
  DialogContent
} from 'components';
import { Observable } from 'rxjs';
import { DialogService } from 'src/app/core/services/dialog.service';
import { EClaimsDialogService } from './e-claims-dialog.service';
import { AddAnsiDialogComponent } from './add-ansi-dialog/add-ansi-dialog.component';

@Component({
  selector: 'app-e-claims-dialog',
  templateUrl: './e-claims-dialog.component.html',
  styleUrls: ['./e-claims-dialog.component.scss']
})
export class EClaimsDialogComponent implements OnInit {
  @ViewChild(GridComponent) grid: GridComponent;
  orderPipe: OrderByPipe = new OrderByPipe();
  saving = false;
  private columnDefinitions: ColumnDefinition[] = [
    {
      id: 'loopName',
      displayKey: 'loopName',
      headerText: 'Loop/Segment Name',
      displayOrder: 0,
      columnSize: ColumnSizes.extraWide,
      sortable: true
    },
    {
      id: 'value',
      displayKey: 'value',
      headerText: 'Value',
      displayOrder: 1,
      columnSize: ColumnSizes.extraWide,
      sortable: true
    },
    {
      id: 'select',
      displayKey: 'select',
      displayType: ColumnDisplayType.chipList,
      displayOrder: 2,
      headerText: '',
      columnSize: ColumnSizes.extraNarrow
    },
    {
      id: 'actions',
      displayKey: 'actions',
      displayType: ColumnDisplayType.actionList,
      columnSize: ColumnSizes.extraNarrow,
      bodyClasses: [BodyClasses.alignRight],
      displayOrder: 99,
      headerText: '',
      overflowMenuOptions: [
        {
          id: 'delete',
          label: 'Delete'
        }
      ],
      //overflowMenuClicked: (value, data) => {
      //  // eslint-disable-next-line
     
      //}
    }
  ];
  gridConfig: GridConfiguration = {
    columnDefinitions: this.columnDefinitions,
    displayColumns: this.orderPipe.transform(this.columnDefinitions, 'displayOrder').map((x) => x.id)
  };

  dataSource: EClaimsDialogService;
  constructor(
    private eClaimService: EClaimsDialogService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<EClaimsDialogComponent>
  ) {
    this.dataSource = eClaimService;
  }

  add() {
    this.openAddAnsiModal(null);
  }

  openAddAnsiModal(insuranceCarrierId: string) {
    const dialog = this.dialog.open(AddAnsiDialogComponent, {
      disableClose: true,
      autoFocus: false,
      data: { insuranceCarrierId }
    });
    dialog.afterClosed().subscribe((result) => {
      if (result) {
        this.grid.loadData();
      }
    });
  }

  save() {
    this.saving = true;
    alert('Saved!');
    this.dialogRef.close(true);
  }

  ngOnInit(): void {}
}
