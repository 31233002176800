import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { CustomVisitDetailsViewModel } from '../visit-details.component';

@Component({
  selector: 'lib-visit-information',
  templateUrl: './visit-information.component.html',
  styleUrls: ['./visit-information.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VisitInformationComponent implements OnInit {
  @Input()
  visit: CustomVisitDetailsViewModel;
  constructor() {}

  ngOnInit(): void {}
}
