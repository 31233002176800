import { Pipe, PipeTransform } from '@angular/core';
import { ProviderDetailsViewModel } from 'data';

@Pipe({
  name: 'physicianDisplay'
})
export class PhysicianDisplayPipe implements PipeTransform {
  transform(physician: ProviderDetailsViewModel, showNumber: boolean = true, emptyValue = '--'): unknown {
    if (!physician) {
      return emptyValue;
    }

    const doctorNumber = showNumber ? `${physician.providerNumber} ` : '';

    return `${doctorNumber}${physician.firstName} ${physician.lastName}`;
  }
}
