import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  BodyClasses,
  ColumnDefinition,
  ColumnDisplayType,
  ColumnSizes,
  FilterType,
  GridComponent,
  GridConfiguration,
  OrderByPipe,
  DialogContent
} from 'components';
import { map, take } from 'rxjs/operators';
import { DialogService } from 'src/app/core/services/dialog.service';
import { ProceduresDialogComponent } from './procedures-dialog/procedures-dialog.component';
import { ProceduresService } from './procedures.service';
import { ProcedureWrapperService } from 'src/app/core/services/service-wrappers/procedure-wrapper.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { DateDisplayPipe } from 'projects/components/src/lib/shared/pipes/date-display/date-display.pipe';
import { ActivatedRoute, Router } from '@angular/router';
import { ImplementationWrapperService } from '@core/services/service-wrappers/implementation-wrapper.service';
import { Store } from '@ngrx/store';
import { ImplementationState } from '../../implementation/state/implementation.state';
import { Observable, Subscription } from 'rxjs';
import { selectImplementation } from '../../implementation/state/implementation.selectors';
import * as ImplementationActions from '../../implementation/state/implementation.actions';
import { ImplementationDetailViewModel } from 'projects/data/src/lib/model/implementationDetailViewModel';
import { ImplementationViewModel } from 'projects/data/src/lib/model/implementationViewModel';
import { UploadCodesComponent } from '../upload-codes/upload-codes.component';
import { SessionStorageService } from '@core/services/session-storage.service';
import { UserDetailsViewModel } from 'data';

@Component({
  selector: 'app-procedures',
  templateUrl: './procedures.component.html',
  styleUrls: ['./procedures.component.scss'],
  providers: [ProceduresService]
})
export class ProceduresComponent implements OnInit, OnDestroy {
  @ViewChild(GridComponent) grid: GridComponent;
  orderPipe: OrderByPipe = new OrderByPipe();
  activeFilter: string = 'active';
  dateDisplayPipe: DateDisplayPipe = new DateDisplayPipe('en-us');
  private dateFormat = new Intl.DateTimeFormat('en-us');
  private moneyFormat = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });
  userInfo: UserDetailsViewModel;
  viewOnly = false;
  viewOnlyOptions = [{ id: 'view', label: 'View' }];
  overflowMenuOptions = [
    {
      id: 'edit',
      label: 'Edit'
    }
  ];
  private columnDefinitions: ColumnDefinition[] = [
    {
      id: 'actions',
      displayKey: 'actions',
      displayType: ColumnDisplayType.actionList,
      columnSize: ColumnSizes.tiny,
      bodyClasses: [BodyClasses.alignRight],
      displayOrder: 0,
      headerText: '',
      overflowMenuOptions: this.overflowMenuOptions,
      overflowMenuClicked: (value, data) => {
        if (value === 'delete') {
          this.deleteEntry(data);
        } else if (value === 'edit') {
          this.openProcedureModal(data.id);
        } else if (value === 'view') {
          this.openProcedureModal(data.id, true);
        }
      }
    },
    {
      id: 'procedureCode',
      displayKey: 'code',
      headerText: 'Code',
      displayOrder: 1,
      columnSize: ColumnSizes.extraNarrow,
      filterable: true,
      sortable: true,
      sticky: true
    },
    {
      id: 'description',
      displayKey: 'description',
      headerText: 'Description',
      columnSize: ColumnSizes.extraWide,
      filterable: true,
      sortable: true,
      displayOrder: 2
    },
    {
      id: 'procedureComponentType',
      displayKey: 'procedureComponentType',
      headerText: 'Professional, Technical, Global',
      displayOrder: 3,
      bodyClasses: [BodyClasses.alignLeft],
      filterable: true,
      sortable: true,
      columnSize: ColumnSizes.normal
    },
    {
      id: 'fee',
      displayKey: 'fee',
      headerText: 'Fee',
      displayOrder: 4,
      bodyClasses: [BodyClasses.alignLeft],
      filterable: true,
      sortable: true,
      formatter: this.moneyFormat.format,
      filterConfig: {
        filterType: FilterType.number
      },
      columnSize: ColumnSizes.extraNarrow
    },
    {
      id: 'effectiveFrom',
      displayKey: 'effectiveFrom',
      headerText: 'Effective From',
      displayOrder: 5,
      filterable: true,
      formatter: (x) => { return this.dateDisplayPipe.transform(x) },
      filterConfig: {

        filterType: FilterType.date
      },
      sortable: true,
      columnSize: ColumnSizes.midwidth
    },
    {
      id: 'effectiveTo',
      displayKey: 'effectiveTo',
      headerText: 'Effective To',
      displayOrder: 6,
      filterable: true,
      formatter: (x) => { return this.dateDisplayPipe.transform(x) },
      filterConfig: {
        filterType: FilterType.date
      },
      sortable: true,
      columnSize: ColumnSizes.midwidth
    }
  ];
  gridConfig: GridConfiguration = {
    columnDefinitions: this.columnDefinitions,
    displayColumns: this.orderPipe.transform(this.columnDefinitions, 'displayOrder').map((x) => x.id)
  };

  dataSource: ProceduresService;
  constructor(
    private procedureService: ProceduresService,
    public dialog: MatDialog,
    private deleteService: ProcedureWrapperService,
    private dialogService: DialogService,
    private notificationService: NotificationService,
    private activatedRoute: ActivatedRoute,
    private implementationService: ImplementationWrapperService,
    private router: Router,
    private store: Store<ImplementationState>,
    private sessionStorageService: SessionStorageService
  ) {
    this.dataSource = procedureService;
    this.dataSource.activeFilter = 'active';
    this.userInfo = this.sessionStorageService.getItem("user-info");
    this.viewOnly = this.userInfo?.userPermissions.find(x => x.permissionType == "SystemMaintenance")?.accessType !== "Update";
    if (this.viewOnly) {
      this.columnDefinitions[0].overflowMenuOptions = this.viewOnlyOptions;
    }
  }

  addProcedure() {
    this.openProcedureModal(null);
  }

  openProcedureModal(procedureId: string, viewOnly = false) {
    const dialog = this.dialog.open(ProceduresDialogComponent, {
      disableClose: true,
      autoFocus: false,
      data: { procedureId, viewOnly }
    });

    dialog.afterClosed().subscribe((result) => {
      if (result) {
        this.grid.loadData();
      }
    });
  }

  deleteEntry(data) {
    const modalContent: DialogContent = {
      header: 'Delete Procedure Code',
      body: `Are you sure you want to delete "${data.description}"? This cannot be undone.`,
      cancelButtonText: 'Cancel',
      OKButtonText: 'Delete'
    };
    this.dialogService.showConfirm(modalContent).subscribe((result) => {
      if (result) {
        this.deleteService
          .apiV1ProcedureIdDelete(data.id)
          .pipe(
            map((x: any) => x),
            take(1)
          )
          .subscribe((deleteResult) => {
            this.grid.loadData();
            this.notificationService.notifyNow('Procedure Code Deleted');
          });
      }
    });
  }

  uploadCodes() {
    const dialog = this.dialog.open(UploadCodesComponent, {
      disableClose: true,
      autoFocus: false,
      height: '600px',
      width:'600px',
      data: {code: 'procedure'}
    });

    dialog.afterClosed().subscribe((result) => {
        this.grid.loadData();
    });
  }

  public isImplementation: boolean = false;
  public gonext: boolean = false;
  public itemCount: number = 0;
  private activatedRouteSub: Subscription;
  private itemCountSub: Subscription;
  private implementation$: Observable<ImplementationViewModel> = this.store.select(selectImplementation);;
  private implementationSub: Subscription;
  private implementation : ImplementationViewModel = null;
  private implementationDetail: ImplementationDetailViewModel = null;
  private nextImplementationDetail: ImplementationDetailViewModel = null;
  private STEP_CODE = 'procedures'

  implementationSubscribe() {
    this.activatedRouteSub = this.activatedRoute.data?.subscribe(data => {
      this.isImplementation = data.implementation;

      if (this.isImplementation) {
        this.itemCountSub = this.procedureService.itemCountSubject?.subscribe((c) => this.itemCount = c);
        this.implementationSub =
          this.implementation$?.subscribe((i) => {
            this.implementation = i;
            this.implementationDetail = this.implementation.details?.find(d => d.implementationStepCode == this.STEP_CODE);
            this.nextImplementationDetail = this.implementation.details?.find(d => d.order == this.implementationDetail.order + 1);
        });
      }
    });
    this.store.dispatch(ImplementationActions.updateImplementationStep({ stepCode: this.STEP_CODE}));
  }

  implementationUnsubscribe() {
    if (this.isImplementation) {
      this.activatedRouteSub.unsubscribe();
      this.itemCountSub.unsubscribe();
      this.implementationSub.unsubscribe();
    }
  }

  next(): void {
    if (this.implementationDetail.status == "InProgress") {
      this.gonext = true;
      this.implementationService
      .apiV1ImplementationCompleteStepCodePost(this.STEP_CODE)
      .subscribe(
        () => {
          this.gonext = false;
          this.router.navigateByUrl('/implementation/' + this.nextImplementationDetail.implementationStepCode);
        },
        () => this.gonext = false
      );
    }
    else {
      this.router.navigateByUrl('/implementation/' + this.nextImplementationDetail.implementationStepCode);
    }
  }

  ngOnInit(): void {
    this.implementationSubscribe();
  }
  ngOnDestroy(): void {
    this.implementationUnsubscribe();
  }
  showInactiveChanges(event) {
    this.activeFilter = event.checked ? 'active' : 'inactive';
    this.dataSource.activeFilter = this.activeFilter;
    this.grid.loadData();
  }
}
