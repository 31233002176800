import { Pipe, PipeTransform } from '@angular/core';
import { ColumnDefinition } from '../models';
import { BaseColumnClass } from './base-column-class';

@Pipe({
  name: 'headerColumnClass'
})
export class HeaderColumnClassPipe extends BaseColumnClass implements PipeTransform {
  transform(columnDefinition: ColumnDefinition): string[] {
    let headerClasses = ['lib-header-cell'];

    if (columnDefinition.headerClasses) {
      headerClasses = headerClasses.concat(columnDefinition.bodyClasses);
    }

    return this.baseTransform(headerClasses, columnDefinition);
  }
}
