<header class="scheduled-jobs__header sub-page-layout__header">
  <div class="scheduled-jobs__header-text h2 h2--semi-bold">Scheduled Jobs</div>
  <mat-slide-toggle
    class="scheduled-jobs__disabled-toggle"
    [checked]="showDisabled"
    color="primary"
    (change)="showDisabledChanged($event)"
  >
    Show Disabled Jobs
  </mat-slide-toggle>
  <button mat-flat-button color="primary" class="scheduled-jobs__add-item" (click)="addJob()">
    <mat-icon>add_circle_outline</mat-icon> Add Job
  </button>
  <button
    mat-flat-button
    color="primary"
    class="scheduled-jobs__add-item"
    (click)="openHistory()"
    *ngIf="implementation === null || implementation.status === 'Complete'"
  >
    View History
  </button>
</header>
<div *ngIf="dataSource && gridConfig; else loading" class="lib-grid__grid-container">
  <lib-grid [dataSource]="dataSource" [gridConfiguration]="gridConfig">
    <div class="">
      <h4>No Scheduled Jobs Found.</h4>
    </div>
  </lib-grid>
</div>

<footer *ngIf="isImplementation && length > 0" class="scheduled-jobs__footer">
  <lib-button-loading color="primary" class="scheduled-jobs__button" [invalid]="length === 0" (clickEvent)="next()"
    >Next
  </lib-button-loading>
</footer>

<ng-template #loading>
  <div class="loading">
    <mat-spinner [diameter]="150"></mat-spinner>
  </div>
</ng-template>
