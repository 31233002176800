/**
 * ImaginePay API
 * This API facilitates partner integration within the platform.
 *
 * The version of the OpenAPI document: 22_12_0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface ImaginePayApiModelsBalanceBillingEnrollmentStatusResponse {
  token?: string;
  billingIdentifier?: string;
  billingPatientId?: number;
  processedTransactionId?: number;
  processedAmount?: number;
  status?: ImaginePayApiModelsBalanceBillingEnrollmentStatusResponse.StatusEnum;
}
export namespace ImaginePayApiModelsBalanceBillingEnrollmentStatusResponse {
  export type StatusEnum = 'ACTIVE' | 'INACTIVE';
  export const StatusEnum = {
    Active: 'ACTIVE' as StatusEnum,
    Inactive: 'INACTIVE' as StatusEnum
  };
}
