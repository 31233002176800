import { Pipe, PipeTransform } from '@angular/core';
import { GuarantorDetailsViewModel } from 'data';

@Pipe({
  name: 'addressFormatterSingleLine'
})
export class AddressFormatterSingleLinePipe implements PipeTransform {
  transform(person: GuarantorDetailsViewModel): string {
    return `${person.address1 || ''} ${person.address2 || ''}${person.city ? ', ' + person.city : ''}${
      person.stateCode ? ', ' + person.stateCode : ''
    }${person.countryCode ? ', ' + person.countryCode : ''}${person.zip ? ', ' + person.zip : ''}`;
  }
}
