/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ProceduresAndPaymentsViewModel } from './proceduresAndPaymentsViewModel';
import { QueueSimpleViewModel } from './queueSimpleViewModel';
import { TotalDaysAmountViewModel } from './totalDaysAmountViewModel';
import { FirstAttemptViewModel } from './firstAttemptViewModel';
import { ChargeFlowViewModel } from './chargeFlowViewModel';
import { ErrorsAndDenialsViewModel } from './errorsAndDenialsViewModel';

export interface DashboardViewModel {
  totalAROutstanding?: number;
  errorsAndDenials?: ErrorsAndDenialsViewModel;
  firstAttemptBilled?: FirstAttemptViewModel;
  queuesData?: Array<QueueSimpleViewModel> | null;
  proceduresAndPayments?: Array<ProceduresAndPaymentsViewModel> | null;
  agedARBuckets?: Array<TotalDaysAmountViewModel> | null;
  chargeFlow?: Array<ChargeFlowViewModel> | null;
  lastUpdated?: string;
}
