import { Action, createReducer, on } from '@ngrx/store';

import { initialUserState, UserState } from './user.state';
import { loadUser, loadUserFailure, loadUserSuccess, updateUser } from './user.actions';

const reducer = createReducer(
  initialUserState,
  on(loadUser, (state) => ({ ...state, loading: true, error: undefined })),
  on(loadUserSuccess, (state, { user }) => ({ loading: false, ...user })),
  on(loadUserFailure, (state, { error }) => ({ loading: false, error })),
  on(loadUser, (state) => ({ ...state, loading: true, error: undefined }))
);

export function userReducer(state: UserState | undefined, action: Action): UserState {
  return reducer(state, action);
}
