/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DemographicUpdatesFieldViewModel } from './demographicUpdatesFieldViewModel';
import { InsuranceDetailsViewModel } from './insuranceDetailsViewModel';

export interface InsuranceDetailsViewModelDemographicUpdatesFieldViewModelListValueTuple {
  item1?: InsuranceDetailsViewModel;
  item2?: Array<DemographicUpdatesFieldViewModel> | null;
}
