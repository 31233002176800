import {
  Component,
  ChangeDetectionStrategy,
  ContentChildren,
  forwardRef,
  OnInit,
  OnDestroy,
  OnChanges,
  AfterViewInit,
  QueryList,
  SimpleChanges,
  Input,
  ChangeDetectorRef,
  EventEmitter,
  Output
} from '@angular/core';
import { Subscription, Observable, merge } from 'rxjs';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { startWith, take } from 'rxjs/operators';
import { FileUploadQueueService } from './file-upload-queue.service';
import { FileUploadComponent } from '../file-upload/file-upload.component';
import { ImportResultWrapperService } from '@core/services/service-wrappers/import-result-wrapper.service';
import { DialogContent } from '../../../dialog';
import { DialogService } from '@core/services/dialog.service';

@Component({
  selector: 'lib-file-upload-queue',
  templateUrl: './file-upload-queue.component.html',
  styleUrls: ['./file-upload-queue.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [FileUploadQueueService]
})
export class FileUploadQueueComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  @ContentChildren(forwardRef(() => FileUploadComponent))
  public fileUploads: QueryList<FileUploadComponent>;
  get fileUploadRemoveEvents(): Observable<FileUploadComponent> {
    return merge(...this.fileUploads.map((fileUpload) => fileUpload.removeEvent));
  }
  private fileRemoveSubscription: Subscription | null;
  private changeSubscription: Subscription;
  public disabled = false;
  public saving = false;
  public files: Array<any> = [];
  @Input() httpUrl: string;
  @Input() httpRequestHeaders: HttpHeaders | { [header: string]: string | string[] } = new HttpHeaders();

  @Input() httpRequestParams: HttpParams | { [param: string]: string | string[] } = new HttpParams();
  @Input() fileAlias = 'file';
  @Input() uploadAllColor = 'primary';
  @Input() uploadAllLabel = 'Import';
  @Input() checkUploadFile: boolean;
  @Input() maxFiles: number;
  @Input() allowedExtensions: string[];
  @Output() isDisabled: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private fileUploadQueueService: FileUploadQueueService, 
    private changeDetectorRef: ChangeDetectorRef,
    private importService: ImportResultWrapperService,
    private dialogService: DialogService,
    ) {}

  ngOnInit() {
    this.fileUploadQueueService.initialize({
      httpUrl: this.httpUrl,
      httpRequestHeaders: this.httpRequestHeaders,
      httpRequestParams: this.httpRequestParams,
      fileAlias: this.fileAlias,
      maxFiles: this.maxFiles,
      allowedExtensions: this.allowedExtensions,
      checkUploadFile: this.checkUploadFile
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.fileUploadQueueService.initialize({
      httpUrl: changes.httpUrl ? changes.httpUrl.currentValue : undefined,
      httpRequestHeaders: changes.httpRequestHeaders ? changes.httpRequestHeaders.currentValue : undefined,
      httpRequestParams: changes.httpRequestParams ? changes.httpRequestParams.currentValue : undefined,
      fileAlias: changes.fileAlias ? changes.fileAlias.currentValue : undefined,
      maxFiles: changes.maxFiles ? changes.maxFiles.currentValue : undefined,
      allowedExtensions: changes.allowedExtensions ? changes.allowedExtensions.currentValue : undefined,
      checkUploadFile: changes.checkUploadFile ? changes.checkUploadFile.currentValue : undefined
    });
  }

  ngAfterViewInit() {
    this.changeSubscription = this.fileUploads.changes.pipe(startWith(null as string)).subscribe(() => {
      if (this.fileRemoveSubscription) {
        this.fileRemoveSubscription.unsubscribe();
      }
      this._listenToFileRemoved();
    });
  }

  private _listenToFileRemoved(): void {
    this.fileRemoveSubscription = this.fileUploadRemoveEvents.subscribe((event: FileUploadComponent) => {
      this.files.splice(event.id, 1);
      this.changeDetectorRef.markForCheck();
    });
  }

  add(file: any) {
    if (!this.disabled) {
      if (!this.checkUploadFile)
      {
        this.files.push(file);
        this.changeDetectorRef.markForCheck();
      }
      else {
        this.importService.apiV1UploadCheckPost(file.name)
        .pipe(take(1))
          .subscribe((response) => {
            if (response === null) {
              this.files.push(file);
              this.changeDetectorRef.markForCheck();
            }
            else {
              var noquotes = response.replaceAll("\"", "");
              const modalContent: DialogContent = {
                header: 'WARNING - Duplicate File Found',
                body: noquotes,
                cancelButtonText: 'Cancel',
                OKButtonText: 'Continue'
              };
              this.dialogService.showConfirm(modalContent).subscribe((result) => {
                if (result) {
                  this.files.push(file);
                  this.changeDetectorRef.markForCheck();
                }
              });
            }
          });
      }
      
    }
  }

  currentUpload = -1;
  fileUploadArray: FileUploadComponent[];

  public uploadAll() {
    this.disabled = true;
    this.saving = true;
    this.isDisabled.emit(this.disabled);
    this.fileUploadArray = this.fileUploads.toArray();

    this.uploadNext();
  }

  private uploadNext() {
    if (++this.currentUpload < this.fileUploads.length) {
      this.fileUploadArray[this.currentUpload].uploadCompleteEvent.subscribe(() => this.uploadNext());
      this.fileUploadArray[this.currentUpload].upload();
    }
  }

  public removeAll() {
    this.files.splice(0, this.files.length);
    this.changeDetectorRef.markForCheck();
  }

  ngOnDestroy() {
    if (this.changeSubscription) {
      this.changeSubscription.unsubscribe();
    }
    if (this.fileRemoveSubscription) {
      this.fileRemoveSubscription.unsubscribe();
    }

    if (this.files) {
      this.removeAll();
    }
  }
}
