import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataSetSelectorComponent } from './data-set-selector.component';
import { MatMenuModule } from '@angular/material/menu';
@NgModule({
  declarations: [DataSetSelectorComponent],
  exports: [DataSetSelectorComponent],
  imports: [CommonModule, MatMenuModule]
})
export class DataSetSelectorModule {}
