export function phone(value: string): string {
  let newValue = value;
  if (value) {
    const clean = value.replace(/\D/g, '');
    if (clean.length <= 10) {
      const x = clean.match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      newValue = !x[2] ? x[1] : `(${x[1]}) ${x[2]}${x[3] ? `-${x[3]}` : ''}`;
    } else if (clean.length === 11) {
      const x = clean.match(/(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,4})/);
      newValue = `+${x[1]}-${x[2]}-${x[3]}-${x[4]}`;
    } else {
      const x = clean.match(/(\d{0,2})(\d{0,2})(\d{0,4})(\d{0,4})/);
      newValue = `+${x[1]} (${x[2]}) ${x[3]}-${x[4]}`;
    }
  }
  return newValue;
}

export function zipCode(value: string): string {

  // auto-format zip code fields
  let newValue = value;

  if (value) {
    const clean = value.replace(/[-]/g, '');
    if (clean.length <= 5) {
      newValue = clean
    } else {
      newValue = clean.substring(0, 5) + '-' + clean.substring(5, clean.length);
    }
  }
  return newValue;
}

export function birthDate(value: string): string {
  let newValue = value;
  if (value) {
    if (value.length === 8) {
      var regex = new RegExp("^[0-9]{8}$")
      if (regex.test(value)) {
        newValue = value.substring(0, 2) + '/' + value.substring(2, 4) + '/' + value.substring(4)
      }

    }
    else if (value.length === 6) {
      var regex = new RegExp("^[0-9]{6}$")
      if (regex.test(value)) {
        newValue = "0" + value.substring(0, 1) + '/' + "0" + value.substring(1, 2) + '/' + value.substring(2)
      }
    }
    else if (value.length === 7) {
      var regex = new RegExp("^[0-9]{7}$")
      if (regex.test(value)) {
        newValue = "0" + value.substring(0, 1) + '/' + value.substring(1, 3) + '/' + value.substring(3)
      }
    }
  }
 
  return newValue
}
