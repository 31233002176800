/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface EligibilityHistoryViewModel {
  patientId?: string;
  insuranceId?: string | null;
  insuranceCarrierId?: string | null;
  originalResponseDate?: string;
  createdDate?: string;
  readonly responseDate?: string;
  benefitCode?: string | null;
  readonly responseType?: string | null;
  insuranceCarrierName?: string | null;
  policyNumber?: string | null;
  relationCode?: string | null;
  readonly relationship?: string | null;
  subscriberLast?: string | null;
  subscriberFirst?: string | null;
  followupActionCode?: string | null;
  readonly followupAction?: string | null;
  rejectReasonCode?: string | null;
  readonly rejectReason?: string | null;
}
