import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'lib-status',
  template: `
    <ng-container [ngSwitch]="status">
      <span *ngSwitchCase="'Accepted'" class="status__approved"> <mat-icon> check_circle </mat-icon> Approved</span>
      <span *ngSwitchCase="'Denied'" class="status__denied"> <mat-icon> error </mat-icon> Denied</span>
      <span *ngSwitchCase="'Unknown'" class="status__unknown"> <mat-icon> help </mat-icon> Unknown</span>
      <span *ngSwitchCase="'Pending'" class="status__pending">
        <mat-icon> radio_button_unchecked </mat-icon> Pending</span
      >
      <span *ngSwitchCase="'' || null">--</span>
      <span *ngSwitchDefault class="status__other"> {{ status }}</span>
    </ng-container>
  `,
  styleUrls: ['./status.component.scss']
})
export class StatusComponent implements OnInit {
  @Input()
  status: 'Unknown' | 'Accepted' | 'Denied' | 'Pending';
  constructor() {}

  ngOnInit(): void {}
}
