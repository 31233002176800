import { Injector, LOCALE_ID } from '@angular/core';
import { Validators } from '@angular/forms';
import {
  LocationsLookupService,
  PhysiciansLookupService,
  InsuranceCarriersLookupService,
  FinancialClassesLookupService
} from '../../../../core/services/lookup';
import { ColumnSizes, FieldSelectOption, FilterType } from 'components';
import { Report } from '../../models/report';
import { DateDisplayPipe } from 'projects/components/src/lib/shared/pipes/date-display/date-display.pipe';
import { RvuByProviderService } from './rvu-by-provider.service';

export const RvuByProvider: Report = {
  id: 'rvu-by-provider',
  label: 'RVU By Provider',
  textCriteria: {
    'Date Type': '',
    'Start Date': '',
    'End Date': '',
    'Financial Classes': '',
    'Insurance Carriers': '',
    'Provider Type': '',
    Providers: '',
    Locations: '',
    'Include Procedure Details': ''
  },
  cleanFilterParams: {
    insuranceCarrierIds: '',
    financialClassIds: '',
    locationIds: '',
    attendingProviderIds: ''
  },
  getReportTitle: () => {
    return RvuByProvider.label;
  },
  getSearchCriteria: (injector: Injector) => [
    {
      controls: [
        {
          label: 'Date Range Type',
          name: 'dateRangeType',
          type: 'select',
          class: 'form-span-6',
          validators: Validators.required,
          initial: 'S',
          options: [
            {
              label: 'Posting Date',
              value: 'P'
            },
            {
              label: 'Service Date',
              value: 'S'
            }
          ],
          selectionChanged: (event) => {
            RvuByProvider.textCriteria['Date Type'] = event.source.triggerValue;
          }
        },
        {
          label: 'Date Range',
          name: 'dateRange',
          type: 'dateRange',
          class: 'form-span-6',
          daterangeStartChanged: (event) => {
            RvuByProvider.textCriteria['Start Date'] = event.value.toLocaleDateString();
          },
          daterangeEndChanged: (event) => {
            RvuByProvider.textCriteria['End Date'] = event.value.toLocaleDateString();
          }
        },
        {
          label: '',
          name: 'dateRangeStart',
          type: 'noInput',
          class: 'display-none',
          validators: Validators.required
        },
        {
          label: '',
          name: 'dateRangeEnd',
          type: 'noInput',
          class: 'display-none',
          validators: Validators.required
        },
        {
          matLabel: 'Insurance Carrier',
          name: 'insuranceCarrierIds',
          apiService: injector.get(InsuranceCarriersLookupService),
          class: 'form-span-6 multi-input',
          type: 'multiSelect',
          placeholder: 'Insurance Carriers',
          selectOptions: (event: []) => {
            let listValue = [];
            let listText = [];
            event.forEach((x: FieldSelectOption) => {
              listValue.push(x?.value);
              listText.push(x.label);
            });
            RvuByProvider.textCriteria['Insurance Carriers'] = listText.join(',');
            RvuByProvider.cleanFilterParams['insuranceCarrierIds'] = listValue;
          }
        },
        {
          matLabel: 'Financial Class',
          name: 'financialClassIds',
          apiService: injector.get(FinancialClassesLookupService),
          class: 'form-span-6 multi-input',
          type: 'multiSelect',
          placeholder: 'Financial Class',
          selectOptions: (event) => {
            let listValue = [];
            let listText = [];
            event.forEach((x: FieldSelectOption) => {
              listValue.push(x.value);
              listText.push(x.label);
            });
            RvuByProvider.textCriteria['Financial Classes'] = listText.join(',');
            RvuByProvider.cleanFilterParams['financialClassIds'] = listValue;
          }
        },

        {
          label: 'Provider Type',
          name: 'providerType',
          type: 'select',
          class: 'form-span-6',
          validators: Validators.required,
          initial: 'A',
          options: [
            {
              label: 'Attending',
              value: 'A'
            },
            {
              label: 'Referring',
              value: 'R'
            },
            {
              label: 'Ordering',
              value: 'O'
            },
            {
              label: 'Primary',
              value: 'P'
            }
          ],
          selectionChanged: (event) => {
            RvuByProvider.textCriteria['Provider Type'] = event.source.triggerValue;
          }
        },
        {
          matLabel: 'Provider',
          name: 'providerIds',
          apiService: injector.get(PhysiciansLookupService),
          type: 'multiSelect',
          class: 'form-span-6 multi-input',
          placeholder: 'Provider',
          selectOptions: (event) => {
            let listValue = [];
            let listText = [];
            event.forEach((x: FieldSelectOption) => {
              listValue.push(x.value);
              listText.push(x.label);
            });
            RvuByProvider.textCriteria['Providers'] = listText.join(',');
            RvuByProvider.cleanFilterParams['providerIds'] = listValue;
          }
        },
        {
          matLabel: 'Location',
          name: 'locationIds',
          apiService: injector.get(LocationsLookupService),
          type: 'multiSelect',
          class: 'form-span-6 multi-input',
          placeholder: 'Location',
          selectOptions: (event) => {
            let listValue = [];
            let listText = [];
            event.forEach((x: FieldSelectOption) => {
              listValue.push(x.value);
              listText.push(x.label);
            });
            RvuByProvider.textCriteria['Locations'] = listText.join(',');
            RvuByProvider.cleanFilterParams['locationIds'] = listValue;
          }
        },
        {
          label: 'Include Procedure Details',
          name: 'includeProcedureDetail',
          type: 'checkbox',
          class: 'checkbox',
          checkedChanged: (event) => {
            RvuByProvider.textCriteria['Include Procedure Details'] = event.checked;
          }
        },
        {
          label: 'Exclude Refunds',
          name: 'excludeRefunds',
          type: 'checkbox',
          class: 'form-span-12 checkbox',
          initial: false,
          toggleChanged: (event) => {
            RvuByProvider.textCriteria['Exclude Refunds'] = event.checked === true ? 'Yes' : 'No'
          }
        },
      ]
    }
  ],
  getGridConfiguration: (injector: Injector) => {
    const locale = injector.get(LOCALE_ID);
    const datePipe: DateDisplayPipe = new DateDisplayPipe(locale);
    const moneyFormat = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });
    const percentFormat = new Intl.NumberFormat('en-US', {
      style: 'percent',
      minimumFractionDigits: 1,
      maximumFractionDigits: 2
    });
    const decimalFormat = new Intl.NumberFormat('en-US', {
      style: 'decimal',
      minimumFractionDigits: 1,
      maximumFractionDigits: 4
    });
    return {
      service: injector.get<any>(RvuByProviderService),
      columnDefinitions: [
        {
          id: 'location',
          displayKey: 'location',
          headerText: 'Location',
          displayOrder: 0,
          columnSize: ColumnSizes.narrow,
          sortable: true,
          filterable: true
        },
        {
          id: 'provider',
          displayKey: 'provider',
          headerText: 'Provider',
          displayOrder: 1,
          columnSize: ColumnSizes.narrow,
          sortable: true,
          filterable: true
        },
        {
          id: 'reportingType',
          displayKey: 'reportingType',
          headerText: 'Reporting Type',
          displayOrder: 2,
          columnSize: ColumnSizes.normal,
          sortable: true,
          filterable: true
        },
        {
          id: 'procedure',
          displayKey: 'procedure',
          headerText: 'Procedure',
          displayOrder: 3,
          columnSize: ColumnSizes.normal,
          sortable: true,
          filterable: true
        },
        {
          id: 'count',
          displayKey: 'count',
          headerText: 'Count',
          displayOrder: 4,
          columnSize: ColumnSizes.narrow,
          sortable: true,
          filterable: true
        },
        {
          id: 'totalRvu',
          displayKey: 'totalRvu',
          headerText: 'Total Rvu',
          displayOrder: 5,
          columnSize: ColumnSizes.narrow,
          sortable: true,
          filterable: true,
          formatter: decimalFormat.format
        },
        {
          id: 'AvgRvuPerProc',
          displayKey: 'AvgRvuPerProc',
          headerText: 'Avg RVU Per Proc',
          displayOrder: 6,
          columnSize: ColumnSizes.narrow,
          sortable: true,
          filterable: true,
          formatter: decimalFormat.format
        },
        {
          id: 'AvgChargedPerRvu',
          displayKey: 'AvgChargedPerRvu',
          headerText: 'Avg Charged Per RVU',
          displayOrder: 7,
          columnSize: ColumnSizes.narrow,
          sortable: true,
          filterable: true,
          formatter: moneyFormat.format
        },
        {
          id: 'AvgPaidPerRvu',
          displayKey: 'AvgPaidPerRvu',
          headerText: 'Avg Paid Per RVU',
          displayOrder: 8,
          columnSize: ColumnSizes.narrow,
          sortable: true,
          filterable: true,
          formatter: moneyFormat.format
        },
        {
          id: 'PaidPercentage',
          displayKey: 'PaidPercentage',
          headerText: 'Paid Percentage',
          displayOrder: 9,
          columnSize: ColumnSizes.narrow,
          sortable: true,
          filterable: true,
          formatter: percentFormat.format
        },
        {
          id: 'totalWork',
          displayKey: 'totalWork',
          headerText: 'Total Work',
          displayOrder: 10,
          columnSize: ColumnSizes.narrow,
          sortable: true,
          filterable: true,
          formatter: decimalFormat.format
        },
      ]
    };
  }
};
