import { DatePipe } from '@angular/common';
import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateDisplay'
})
export class DateDisplayPipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) private locale: string) {}
  datePipe = new DatePipe(this.locale);

  transform(value: string, emptyValue = '--'): string {
    if (value === null) return emptyValue;
    const date = new Date(value);
    const minDate = new Date(1900, 0, 1);
    return date > minDate ? this.datePipe.transform(date, 'MM/dd/yyyy') : emptyValue;
  }
}
