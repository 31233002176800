export const accountStatuses = [
  { name: 'Visits Pending Eligibility', value: 'VisitsPendingEligibility' },
  { name: 'Eligibility Work', value: 'eligibilityWork' },
  { name: 'CCI', value: 'cci' },
  { name: 'Insurance Visits on Hold', value: 'insuranceProceduresOnHold' },
  { name: 'Denied Procedures', value: 'deniedProcedures' },
  { name: 'Errored Procedures', value: 'erroredProcedures' },
  { name: 'No Response Visits', value: 'noResponseVisits' },
  { name: 'Pending Insurance Procedures', value: 'pendingInsuranceProcedures' },
  { name: 'Unapplied Insurance Procedures', value: 'unappliedInsuranceProcedures' },
  { name: 'Pending Patient Procedures', value: 'pendingPatientProcedures' },
  { name: 'Refunds', value: 'refunds' },
  { name: 'Collections', value: 'collections' }
];
