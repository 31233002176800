import { Injector, LOCALE_ID } from '@angular/core';
import { Validators } from '@angular/forms';
import { ColumnSizes, FieldSelectOption, FilterType } from 'components';
import { DateDisplayPipe } from 'projects/components/src/lib/shared/pipes/date-display/date-display.pipe';
import { Report } from '../../models/report';
import { DailyPostingSummaryService } from './daily-posting-summary.service';
import { LocationsLookupService } from '@core/services/lookup';

export const DailyPostingSummary: Report = {
  id: 'daily-posting-summary',
  label: 'Daily Posting Summary',
  textCriteria: { 'Date Range Type': '', 'Start Date': '', 'End Date': ''},
  cleanFilterParams: { 'locationIds': '' },
  getReportTitle: () => {
    return DailyPostingSummary.label + ' ' + DailyPostingSummary.textCriteria['Start Date'] + ' - ' + DailyPostingSummary.textCriteria['End Date']
      + '(' + DailyPostingSummary.textCriteria['Date Range Type'] + ')';
  },
  getSearchCriteria: (injector: Injector) => [
    {
      title: '',
      controls: [
        {
          label: 'Range Type',
          name: 'dateRangeType',
          type: 'select',
          class: 'form-span-4',
          options: [
            {
              label: 'Service Date',
              value: 'S'
            },
            {
              label: 'Posting Date',
              value: 'P'
            }
          ],
          validators: Validators.required,
          selectionChanged: (event) => {
            DailyPostingSummary.textCriteria['Date Range Type'] = event.source.triggerValue;
          }
        },
        {
          label: 'Date Range',
          name: 'dateRange',
          type: 'dateRange',
          class: 'form-span-8',
          daterangeStartChanged: (event) => {
            DailyPostingSummary.textCriteria['Start Date'] = event.value.toLocaleDateString();
          },
          daterangeEndChanged: (event) => {
            DailyPostingSummary.textCriteria['End Date'] = event.value.toLocaleDateString();
          }  
        },
        {
          label: '',
          name: 'dateRangeStart',
          type: 'noInput',
          class: 'display-none',
          validators: Validators.required
        },
        {
          label: '',
          name: 'dateRangeEnd',
          type: 'noInput',
          class: 'display-none',
          validators: Validators.required
        },
        {
          matLabel: 'Location',
          name: 'locationIds',
          apiService: injector.get(LocationsLookupService),
          type: 'multiSelect',
          class: 'form-span-12 multi-input',
          placeholder: 'Location',
          selectOptions: (event) => {
            let listValue = []
            let listText = []
            event.forEach((x: FieldSelectOption) => {
              listValue.push(x.value)
              listText.push(x.label)
            })
            DailyPostingSummary.textCriteria['Locations'] = listText.join(',');
            DailyPostingSummary.cleanFilterParams['locationIds'] = listValue
          }
        }
      ]
    }
  ],
  getGridConfiguration: (injector: Injector) => {
    const locale = injector.get(LOCALE_ID);
    const datePipe: DateDisplayPipe = new DateDisplayPipe(locale);
    const moneyFormat = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });
    return {
      service: injector.get<any>(DailyPostingSummaryService),
      columnDefinitions: [
        {
          id: 'Location',
          displayKey: 'Location',
          headerText: 'Location',
          sortable: true,
          filterable: true,
          displayOrder: 0
        },
        {
          id: 'Posting Service Date',
          displayKey: 'Posting Service Date',
          headerText: 'Posting Service Date',
          displayOrder: 1,
          columnSize: ColumnSizes.normal,
          dynamic: true,
        },
        {
          id: 'Beginning Balance',
          displayKey: 'Beginning Balance',
          headerText: 'Beginning Balance',
          bodyClasses: [],
          sortable: true,
          filterable: true,
          formatter: moneyFormat.format,
          displayOrder: 2
        },
        {
          id: 'Procedures Posted',
          displayKey: 'Procedures Posted',
          headerText: 'Procedures Posted',
          bodyClasses: [],
          sortable: true,
          filterable: true,
          formatter: moneyFormat.format,
          displayOrder: 3
        },
        {
          id: 'Payments Posted',
          displayKey: 'Payments Posted',
          headerText: 'Payments Posted',
          formatter: moneyFormat.format,
          bodyClasses: [],
          sortable: true,
          filterable: true,
          displayOrder: 4
        },
        {
          id: 'Refunds Posted',
          displayKey: 'Refunds Posted',
          headerText: 'Refunds Posted',
          formatter: moneyFormat.format,
          bodyClasses: [],
          sortable: true,
          filterable: true,
          displayOrder: 5
        },
        {
          id: 'Adjustments Posted',
          displayKey: 'Adjustments Posted',
          headerText: 'Adjustments Posted',
          bodyClasses: [],
          sortable: true,
          filterable: true,
          formatter: moneyFormat.format,
          displayOrder: 6
        },
        {
          id: 'Ending Balance',
          displayKey: 'Ending Balance',
          headerText: 'Ending Balance',
          bodyClasses: [],
          sortable: true,
          filterable: true,
          formatter: moneyFormat.format,
          displayOrder: 7
        }
      ]
    };
  }
};
