<div class="main-container">

  <mat-sidenav-container hasBackdrop="false">
    <mat-sidenav mode="side" opened>
      <ng-content select="[sideBar]"></ng-content>
    </mat-sidenav>
    <mat-sidenav-content>
      <ng-content></ng-content>
      <lib-footer></lib-footer>
    </mat-sidenav-content>
    <mat-sidenav position="end" mode="over" #sideBarRight>
      <ng-content select="[sideBarRight]"></ng-content>
    </mat-sidenav>
  </mat-sidenav-container>
</div>
