/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface AddScheduledJobViewModel {
  jobId?: string;
  datasetId?: string;
  description?: string | null;
  status?: string | null;
  frequencyType?: string | null;
  nextExecution?: string | null;
  lastExecuted?: string | null;
  frequencyInterval?: number | null;
  executionHour?: number | null;
  executionMinute?: number | null;
  executionMeridiem?: string | null;
  executionTimezone?: string | null;
  weekDays?: Array<string> | null;
  monthDays?: Array<number> | null;
  months?: Array<string> | null;
  scheduledForId?: string;
  isSysAdminJob?: boolean | null;
}
