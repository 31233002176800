import { AppState } from '../../../core/core.state';
import { ImplementationViewModel } from 'data';

export interface ImplementationState {
  implementation: ImplementationViewModel;
  currentStepCode: string;
  loading: boolean;
  loaded: boolean;
}

export const initialImplementationState: ImplementationState = {
  implementation: null,
  currentStepCode: '',
  loaded: false,
  loading: false
};

export interface State extends AppState {
  implementation: ImplementationState;
}