/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RemitEOBClaimHoldingViewModel } from './remitEOBClaimHoldingViewModel';
import { RemitRemarkClaimHoldingViewModel } from './remitRemarkClaimHoldingViewModel';
import { RemitClaimHoldingViewModel } from './remitClaimHoldingViewModel';

export interface RemitHoldingDetailViewModel {
  id?: string;
  datasetId?: string;
  remitDownloadedFileId?: string;
  checkAmount?: number;
  payMethod?: string | null;
  checkDate?: string | null;
  checkNumber?: string | null;
  payorName?: string | null;
  payorId?: string | null;
  payorAddress?: string | null;
  payorCityStateZip?: string | null;
  businessType?: string | null;
  businessName?: string | null;
  businessContact?: string | null;
  technicalType?: string | null;
  technicalName?: string | null;
  technicalContact?: string | null;
  webType?: string | null;
  webURL?: string | null;
  payeeName?: string | null;
  nationPayorId?: string | null;
  payeeAddress?: string | null;
  payeeCityStateZip?: string | null;
  payorIdRef?: string | null;
  createdBy?: string | null;
  createdDate?: string;
  remitClaimHoldings?: Array<RemitClaimHoldingViewModel> | null;
  remitEOBClaimHoldings?: Array<RemitEOBClaimHoldingViewModel> | null;
  remitRemarkClaimHoldings?: Array<RemitRemarkClaimHoldingViewModel> | null;
}
