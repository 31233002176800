import { Injectable, NgZone } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { ToastComponent } from 'components/core';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  constructor(private readonly snackBar: MatSnackBar, private readonly zone: NgZone) {}

  /**
   * Dispatches default toast notification
   *
   * @param {string} message
   * @memberof NotificationService
   */
  default(message: string) {
    this.show(message, {
      duration: 2000,
      panelClass: 'default-notification-overlay'
    });
  }

  /**
   * Dispatches info toast notification
   *
   * @param {string} message
   * @memberof NotificationService
   */
  info(message: string) {
    this.show(message, {
      duration: 2000,
      panelClass: 'info-notification-overlay'
    });
  }

  /**
   * Dispatches success toast notification
   *
   * @param {string} message
   * @memberof NotificationService
   */
  success(message: string) {
    this.show(
      message,
      {
        duration: 2000,
        panelClass: 'success-notification-overlay'
      },
      'check_circle'
    );
  }

  /**
   * Dispatches warn toast notification
   *
   * @param {string} message
   * @memberof NotificationService
   */
  warn(message: string) {
    this.show(message, {
      duration: 2500,
      panelClass: 'warning-notification-overlay'
    });
  }

  /**
   * Dispatches error toast notification
   *
   * @param {string} message
   * @memberof NotificationService
   */
  error(message: string) {
    this.show(
      message,
      {
        duration: 3000,
        panelClass: 'error-notification-overlay'
      },
      'warning'
    );
  }

  /**
   * Dispatches toast notification based on passed in parameters
   *
   * @private
   * @param {string} message
   * @param {MatSnackBarConfig} configuration
   * @memberof NotificationService
   */
  private show(message: string, configuration: MatSnackBarConfig, icon: string = '') {
    // Need to open snackBar from Angular zone to prevent issues with its position per
    // https://stackoverflow.com/questions/50101912/snackbar-position-wrong-when-use-errorhandler-in-angular-5-and-material
    this.zone.run(() =>
      this.snackBar.openFromComponent(ToastComponent, {
        ...configuration,
        data: { message, icon },
        horizontalPosition: 'end',
        verticalPosition: 'bottom'
      })
    );
  }

  public async notifyNow(notification) {
    await this.success(notification)
  }

  public async errorNow(notification) {
    await this.error(notification)
  }
}
