import { Injectable } from '@angular/core';
import {
  AddInsuranceCarrierClaimValueViewModel,
  InsuranceCarrierClaimValueDetailsViewModel,
  InsuranceCarrierClaimValueDetailsViewModelPagedModelResponse,
  InsuranceCarrierClaimValueService
} from 'data';
import { Observable } from 'rxjs';
import { BaseWrapperService } from './base-wrapper.service';
import { SessionStorageService } from '../session-storage.service';

@Injectable({
  providedIn: 'root'
})
export class InsuranceCarrierClaimValueWrapperService extends BaseWrapperService {
  constructor(
    private service: InsuranceCarrierClaimValueService,
    protected sessionStorageService: SessionStorageService
  ) {
    super();
  }

  apiV1InsuranceCarrierClaimValueAllPost(
    requestBody
  ): Observable<InsuranceCarrierClaimValueDetailsViewModelPagedModelResponse> {
    return this.service.apiV1InsuranceCarrierClaimValueAllPost(this.getDataSetId(), requestBody);
  }

  apiV1InsuranceCarrierClaimValueIdGet(id: string): Observable<InsuranceCarrierClaimValueDetailsViewModel> {
    return this.service.apiV1InsuranceCarrierClaimValueIdGet(id, this.getDataSetId());
  }

  apiV1InsuranceCarrierClaimValueAddPost(requestBody): Observable<AddInsuranceCarrierClaimValueViewModel> {
    return this.service.apiV1InsuranceCarrierClaimValueAddPost(this.getDataSetId(), requestBody);
  }

  apiV1InsuranceCarrierClaimValuePatchIdPatch(
    id: string,
    operation
  ): Observable<InsuranceCarrierClaimValueDetailsViewModel> {
    return this.service.apiV1InsuranceCarrierClaimValuePatchIdPatch(id, this.getDataSetId(), operation);
  }

  apiV1InsuranceCarrierClaimValueIdDelete(id: string): Observable<InsuranceCarrierClaimValueDetailsViewModel> {
    return this.service.apiV1InsuranceCarrierClaimValueIdDelete(id, this.getDataSetId());
  }

  apiV1InsuranceCarrierClaimValueUpdateAll(insurancecarrierid: string, requestBody): Observable<Array<string>> {
    return this.service.apiV1InsuranceCarrierClaimValueUpdateAllInsurancecarrieridPost(
      insurancecarrierid,
      this.getDataSetId(),
      requestBody
    );
  }

  apiV1InsuranceCarrierClaimValueEnsureClaimValuePost(
    ruleCardId: string,
    insurancecarrierid: string
  ): Observable<Boolean> {
    return this.service.apiV1InsuranceCarrierClaimValueEnsureClaimValuePost(
      this.getDataSetId(),
      ruleCardId,
      insurancecarrierid
    );
  }

  apiV1InsuranceCarrierClaimValueSearchInsurancecarrieridPost(
    insurancecarrierid: string,
    requestBody
  ): Observable<InsuranceCarrierClaimValueDetailsViewModelPagedModelResponse> {
    return this.service.apiV1InsuranceCarrierClaimValueSearchInsurancecarrieridPost(
      insurancecarrierid,
      this.getDataSetId(),
      requestBody
    );
  }
}
