import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SubPageLayoutComponent } from './sub-page-layout.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [SubPageLayoutComponent],
  exports: [SubPageLayoutComponent],
  imports: [CommonModule, MatSidenavModule, MatIconModule, MatButtonModule]
})
export class SubPageLayoutModule {}
