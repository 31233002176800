import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Observable, Subscription, take } from 'rxjs';
import { ScheduledJobsDialogComponent } from './scheduled-jobs-dialog/scheduled-jobs-dialog.component';
import { ImplementationWrapperService } from '@core/services/service-wrappers/implementation-wrapper.service';
import { Store } from '@ngrx/store';
import { ImplementationState } from '../../implementation/state/implementation.state';
import { selectImplementation } from '../../implementation/state/implementation.selectors';
import * as ImplementationActions from '../../implementation/state/implementation.actions';
import { ImplementationViewModel } from 'projects/data/src/lib/model/implementationViewModel';
import { ImplementationDetailViewModel } from 'projects/data/src/lib/model/implementationDetailViewModel';
import { BodyClasses, ColumnDefinition, ColumnDisplayType, ColumnSizes, FilterType, GridComponent, GridConfiguration, OrderByPipe } from 'components';
import { ScheduledJobsService } from './scheduled-jobs.service';
import { AppState } from '@core/core.state';
import { BillingWrapperService } from '@core/services/service-wrappers/billing-wrapper.service';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-scheduled-jobs-history',
  templateUrl: './scheduled-jobs.component.html',
  styleUrls: ['./scheduled-jobs.component.scss'],
  providers: [ScheduledJobsService]
})
export class ScheduledJobsComponent implements OnInit {
  @ViewChild(GridComponent) grid: GridComponent;
  public subs = new Subscription();
  user;
  orderPipe: OrderByPipe = new OrderByPipe();
  dataSource: ScheduledJobsService;
  private dateFormat = new Intl.DateTimeFormat('en-us');
  showDisabled = false;
  public length = 0;
  saving = false;
  private columnDefinitions: ColumnDefinition[] = [
    {
      id: 'actions',
      displayKey: 'actions',
      headerText: '',
      displayOrder: 0,
      displayType: ColumnDisplayType.actionList,
      columnSize: ColumnSizes.tiny,
      bodyClasses: [BodyClasses.alignRight],
      overflowMenuOptions: [
        {
          id: 'edit',
          label: 'Edit'
        }
      ],
      overflowMenuClicked: (value, data) => {
        if (value === 'edit') {
          this.editJob(data.id);
        }
      }
    },
    {
      id: 'job',
      displayKey: 'jobName',
      headerText: 'Job',
      displayOrder: 1,
      columnSize: ColumnSizes.wide,
      filterable: true,
      sortable: true
    },
    {
      id: 'status',
      displayKey: 'status',
      headerText: 'Status',
      displayOrder: 2,
      columnSize: ColumnSizes.narrow,
      sortable: true,
      filterable: true
    },
    {
      id: 'nextExecution',
      displayKey: 'nextExecution',
      headerText: 'Next Execution',
      displayOrder: 3,
      columnSize: ColumnSizes.date,
      sortable: true,
      filterable: true,
      formatter: (x) => {
        const dt: Date = new Date(x);
        return dt.toLocaleDateString() + ' ' + dt.toLocaleTimeString();
      },
      filterConfig: {
        filterType: FilterType.date,
        filterKey: 'nextExecution'
      }
    },
    {
      id: 'frequencyType',
      displayKey: 'frequencyType',
      headerText: 'Frequency',
      displayOrder: 4,
      columnSize: ColumnSizes.narrow,
      sortable: true,
      filterable: true
    },
    {
      id: 'scheduledBy',
      displayKey: 'scheduledForName',
      headerText: 'Scheduled By',
      displayOrder: 5,
      columnSize: ColumnSizes.midwidth,
      sortable: true,
      filterable: true
    },
    {
      id: 'description',
      displayKey: 'description',
      headerText: 'Description',
      displayOrder: 6,
      columnSize: ColumnSizes.normal,
      sortable: true,
      filterable: true
    }
  ];
  gridConfig: GridConfiguration = {
    columnDefinitions: this.columnDefinitions,
    displayColumns: this.orderPipe.transform(this.columnDefinitions, 'displayOrder').map((x) => x.id)
  };

  constructor(
    private router:Router,
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private implementationService: ImplementationWrapperService,
    private store: Store<ImplementationState>,
    private appstore: Store<AppState>,
    private scheduledJobsService: ScheduledJobsService,
    private billingService: BillingWrapperService
  ) {
    this.appstore.subscribe(s => this.user = s.user);
    this.dataSource = scheduledJobsService;
  }

  ngOnInit(): void {
    this.dataSource.itemCount$.subscribe(x => this.length = x);
    this.implementationSubscribe();
  }

  ngOnDestroy() {
    //this.subs.unsubscribe();
    this.implementationUnsubscribe();
  }
  addJob() {
    this.openJobModal(null);
  }

  editJob(jobId: string) {
    this.openJobModal(jobId);
  }

  openJobModal(jobId: string) {
    const dialog = this.dialog.open(ScheduledJobsDialogComponent, {
      disableClose: true,
      autoFocus: false,
      data: { scheduledJobId: jobId, user: this.user }
    });

    dialog.afterClosed().subscribe((result) => {
      if (result) {
        this.dataSource.refresh();
      }
    });
  }

  rowClicked(event, element) {
    if (event === 'edit') {
      this.editJob(element.id);
    }
  }

  openHistory() {
    this.router.navigate(['/', 'settings', 'scheduled-jobs', 'scheduled-jobs-history']);
  }

  public isImplementation: boolean = false;
  public gonext: boolean = false;
  public itemCount: number = 0;
  private activatedRouteSub: Subscription;
  private implementation$: Observable<ImplementationViewModel> = this.store.select(selectImplementation);;
  private implementationSub: Subscription;
  protected implementation : ImplementationViewModel = null;
  private implementationDetail: ImplementationDetailViewModel = null;
  private nextImplementationDetail: ImplementationDetailViewModel = null;
  private STEP_CODE = 'scheduled-jobs'

  implementationSubscribe() {
    this.activatedRouteSub = this.activatedRoute.data?.subscribe(data => {
      this.isImplementation = data.implementation;

      if (this.isImplementation) {
        this.implementationSub =
          this.implementation$.subscribe((i) => {
            this.implementation = i;
            this.implementationDetail = this.implementation.details.find(d => d.implementationStepCode == this.STEP_CODE);
            this.nextImplementationDetail = this.implementation.details.find(d => d.order == this.implementationDetail.order + 1);
        });
      }
    });
    this.store.dispatch(ImplementationActions.updateImplementationStep({ stepCode: this.STEP_CODE}));
  }

  implementationUnsubscribe() {
    if (this.isImplementation) {
      this.activatedRouteSub.unsubscribe();
      this.implementationSub.unsubscribe();
    }
  }

  showDisabledChanged(event) {
    this.showDisabled = event.checked;
    this.dataSource.showDisabled = this.showDisabled;
    this.dataSource.refresh();
  }

  next(): void {
    if (this.implementationDetail.status == "InProgress") {
      this.gonext = true;
      this.implementationService
      .apiV1ImplementationCompleteStepCodePost(this.STEP_CODE)
      .subscribe(
        () => {
          this.gonext = false;
          this.router.navigateByUrl('/implementation/' + this.nextImplementationDetail.implementationStepCode);
        },
        () => this.gonext = false
      );
    }
    else {
      this.router.navigateByUrl('/implementation/' + this.nextImplementationDetail.implementationStepCode);
    }
  }
}

