/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ProcedureResultViewModel } from './procedureResultViewModel';
import { PatientViewModel } from './patientViewModel';
import { VisitHoldingViewModel } from './visitHoldingViewModel';
import { ChargeCentralProcedureDetailsViewModel } from './chargeCentralProcedureDetailsViewModel';
import { ProcedureResultHoldingViewModel } from './procedureResultHoldingViewModel';

export interface ChargeCentralDetailsViewModel {
  patient?: PatientViewModel;
  visitHolding?: VisitHoldingViewModel;
  procedureResultHoldings?: Array<ProcedureResultHoldingViewModel> | null;
  procedureResults?: Array<ProcedureResultViewModel> | null;
  procedures?: Array<ChargeCentralProcedureDetailsViewModel> | null;
  referringProviderRequired?: boolean;
}
