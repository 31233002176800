// Copied from from data project

import { ComparisonType } from './comparison-type';

export interface  PagedModel {
  sortOrder?: ListSortDirection;
  sortColumn?: string | null;
  generalSearch?: string | null;
  propertySearches?: Array<PropertySearch> | null;
  numericRanges?: Array<PropertyNumericRange> | null;
  dateRanges?: Array<PropertyDateRange> | null;
  page?: number;
  pageSize?: number;
  startDate?: string;
  endDate?: string;
  itemCount?: number;
}
export interface PropertyNumericRange {
  propertyName?: string | null;
  rangeStart?: number;
  rangeEnd?: number;
}

export interface PropertySearch {
  propertyName?: string | null;
  comparisonType?: ComparisonType;
  value?: string | null;
}

export type ListSortDirection = 0 | 1;

export const ListSortDirection = {
  NUMBER_0: 0 as ListSortDirection,
  NUMBER_1: 1 as ListSortDirection
};

export interface PropertyDateRange {
  propertyName?: string | null;
  rangeEnd?: string;
  rangeStart?: string;
}
