import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { UserWrapperService } from '../../services/service-wrappers/user-wrapper.service';
import { UserState } from './user.state';
import { loadUser, updateUser, loadUserSuccess, loadUserFailure } from './user.actions';
import { selectDataSet } from '../data-set/data-set.actions';

@Injectable()
export class UserEffects {
  constructor(private actions$: Actions, private store: Store<UserState>, private service: UserWrapperService) {}

  initializeUser = createEffect(() =>
    this.actions$.pipe(
      ofType(selectDataSet),
      map(({ dataSetId }) => {
        return loadUser();
      })
    )
  );

  loadUser = createEffect(() =>
    this.actions$.pipe(
      ofType(loadUser),
      switchMap(({}) => {
        return this.service.apiV1UserGet().pipe(
          map((response) => {
            return loadUserSuccess({ user: response });
          }),
          catchError((error) => of(loadUserFailure({ error })))
        );
      })
    )
  );
}
