import { ConfigurableFocusTrapFactory } from '@angular/cdk/a11y';
import { InjectionToken } from '@angular/core';
import { environment } from '@environment/environment';
import { AuthClientConfig } from './auth.config';

export class AuthClientFactory {
  static createClient(configFactory: AuthClientConfig): AuthClient {
    const config = configFactory.get();

    if (!config) {
      throw new Error(
        'Configuration must be specified either through AuthModule.forRoot or through AuthClientConfig.set'
      );
    }

    const { authTokenUrl, authClientId, authUserName, authPassword, authGrantType } = config;

    return new AuthClient({
      fetchUrl: authTokenUrl,
      clientId: authClientId,
      userName: authUserName,
      password: authPassword,
      grantType: authGrantType
    });
  }
}

export const AuthClientService = new InjectionToken<AuthClient>('imagine-auth.client');

export interface AuthClientOptions {
  fetchUrl: string;
  clientId: string;
  userName: string;
  password: string;
  grantType: string;
}

export class AuthClient {
  readonly TOKEN = 'IMAGINE_PAY_TOKEN';
  readonly TOKEN_EXP = 'IMAGINE_PAY_TOKEN_EXP';

  clientOptions: AuthClientOptions;
  constructor(_obj: AuthClientOptions) {
    this.clientOptions = _obj;
  }

  getTokenSilently(): Promise<any> {
    if (!this.isTokenExpired()) {
      return Promise.resolve(this.getTokenFromState());
    }
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/x-www-form-urlencoded');

    const urlencoded = new URLSearchParams();
    urlencoded.append('username', environment.imaginePayConfig.authUserName);
    urlencoded.append('password', environment.imaginePayConfig.authPassword);
    urlencoded.append('client_id', environment.imaginePayConfig.authClientId);
    urlencoded.append('grant_type', this.clientOptions.grantType);

    const requestOptions: RequestInit = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    };

    return (
      fetch(this.clientOptions.fetchUrl, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          this.setTokenState(result.access_token, result.expires_in);
          return result.access_token;
        })
        // eslint-disable-next-line
        .catch((error) => console.error('error', error))
    );
  }

  isTokenExpired() {
    const expireDate = new Date(window.sessionStorage.getItem(this.TOKEN_EXP));
    if (expireDate instanceof Date && !isNaN(expireDate.valueOf())) {
      return new Date() > expireDate;
    }
    return true;
  }

  getTokenFromState() {
    const sessionStr = window.sessionStorage.getItem(this.TOKEN);
    return sessionStr;
  }
  setTokenState(token: string, expiresIn: number) {
    window.sessionStorage.setItem(this.TOKEN, token);

    const expireDate = new Date();
    expireDate.setSeconds(expireDate.getSeconds() + expiresIn);

    window.sessionStorage.setItem(this.TOKEN_EXP, expireDate.toString());
  }
}
