/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RuleCardAlterationViewModel } from './ruleCardAlterationViewModel';
import { RuleCardViewModel } from './ruleCardViewModel';

export interface RuleCardUpdateDetailsViewModel {
  id?: string;
  ruleCardId?: string;
  ordinal?: number;
  ruleCardAlterationId?: string | null;
  columnName?: string | null;
  value?: string | null;
  ruleCard?: RuleCardViewModel;
  ruleCardAlteration?: RuleCardAlterationViewModel;
}
