/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ARTransactionVisitUpdateModel } from './aRTransactionVisitUpdateModel';

export interface ARTransactionPostedPatientViewModel {
  visits?: Array<ARTransactionVisitUpdateModel> | null;
  batchId?: string;
  adjustmentQuantity?: number;
  adjustmentAmount?: number;
  paymentQuantity?: number;
  paymentAmount?: number;
  batchTotal?: number;
  batchExpected?: number | null;
  batchRemaining?: number | null;
  patientId?: string;
  insuranceId?: string | null;
  patientLastName?: string | null;
  patientFirstName?: string | null;
  accountNumber?: number;
  insuranceCarrierName?: string | null;
  totalBalance?: number;
}
