import { formatPercent } from '@angular/common';
import { Injector, LOCALE_ID } from '@angular/core';
import { Validators } from '@angular/forms';
import { BodyClasses, ColumnSizes, CustomValidators, FieldSelectOption, FilterType } from 'components';
import { DateDisplayPipe } from 'projects/components/src/lib/shared/pipes/date-display/date-display.pipe';
import { Report } from '../../models/report';
import { SelectedProceduresService } from './selected-procedures.service';
import {
    PhysiciansLookupService,
    InsuranceCarriersLookupService,
    FinancialClassesLookupService,
    AttendingPhysiciansLookupService,
    LocationsLookupService,
    ProcedureCodesLookupService,
    DiagnosisCodesLookupService,
    PlacesOfServiceCodesLookupService
} from 'src/app/core/services/lookup';

export const SelectedProcedures: Report = {
    id: 'selected-procedures',
    label: 'Selected Procedures Report',
    subText: 'If filters are not selected, then all data is included.',
    searchFormClass: 'selected-procedures-report',
    textCriteria: {
        'Insurance Carriers': '', 'Financial Classes': '', 'Locations': '', 'Attending Providers': ''
        , 'Procedure Codes': '', 'Diagnosis Codes': '', 'Start Date': '', 'End Date': ''
        , 'Start Post Date': '', 'End Post Date': '', 'Start Billed Date': '', 'End Billed Date': ''
        , 'Balance Min': 0, 'Balance Max': 99999, 'Referring Providers': '', 'Place Of Service Codes': ''
    },
    cleanFilterParams: {
        'insuranceCarrierIds': '', 'financialClassIds': '', 'locationIds': '', 'attendingProviderIds': ''
        , 'procedureCodeIds': '', 'diagnosisCodeIds': '', 'referringProviderIds': '' , 'placeOfServiceCodeIds': ''
    },
    getReportTitle: () => {
        return SelectedProcedures.label + ' ' + SelectedProcedures.textCriteria['Start Date'] + ' - ' + SelectedProcedures.textCriteria['End Date'];
    },
    getSearchCriteria: (injector: Injector) => [
        {
            title: '',
            controls: [
                {
                    matLabel: 'Financial Classes',
                    name: 'financialClassIds',
                    apiService: injector.get(FinancialClassesLookupService),
                    class: 'form-span-6 multi-input',
                    type: 'multiSelect',
                    placeholder: 'Financial Class',
                    selectOptions: (event) => {
                        let listValue = []
                        let listText = []
                        event.forEach((x: FieldSelectOption) => {
                            listValue.push(x.value)
                            listText.push(x.label)
                        })
                        SelectedProcedures.textCriteria['Financial Classes'] = listText.join(',');
                        SelectedProcedures.cleanFilterParams['financialClassIds'] = listValue
                    }
                },
                {
                    matLabel: 'Locations',
                    name: 'locationIds',
                    apiService: injector.get(LocationsLookupService),
                    type: 'multiSelect',
                    class: 'form-span-6 multi-input',
                    placeholder: 'Location',
                    selectOptions: (event) => {
                        let listValue = []
                        let listText = []
                        event.forEach((x: FieldSelectOption) => {

                            listValue.push(x.value)
                            listText.push(x.label)
                        })
                        SelectedProcedures.textCriteria['Locations'] = listText.join(',');
                        SelectedProcedures.cleanFilterParams['locationIds'] = listValue
                    }
                },
                {
                    matLabel: 'Insurance Carriers',
                    name: 'insuranceCarrierIds',
                    apiService: injector.get(InsuranceCarriersLookupService),
                    class: 'form-span-6 multi-input',
                    type: 'multiSelect',
                    placeholder: 'Insurance Carriers',
                    selectOptions: (event: []) => {
                        let listValue = []
                        let listText = []
                        event.forEach((x: FieldSelectOption) => {
                            listValue.push(x?.value)
                            listText.push(x.label)
                        })
                        SelectedProcedures.textCriteria['Insurance Carriers'] = listText.join(',');
                        SelectedProcedures.cleanFilterParams['insuranceCarrierIds'] = listValue
                    },
                },
                {
                    matLabel: 'Attending Providers',
                    name: 'attendingProviderIds',
                    apiService: injector.get(AttendingPhysiciansLookupService),
                    type: 'multiSelect',
                    class: 'form-span-6 multi-input',
                    placeholder: 'Attending Provider',
                    selectOptions: (event) => {
                        let listValue = []
                        let listText = []
                        event.forEach((x: FieldSelectOption) => {
                            listValue.push(x.value)
                            listText.push(x.label)
                        })
                        SelectedProcedures.textCriteria['Attending Providers'] = listText.join(',');
                        SelectedProcedures.cleanFilterParams['attendingProviderIds'] = listValue

                    }
                },
                {
                    matLabel: 'Referring Providers',
                    name: 'referringProviderIds',
                    apiService: injector.get(PhysiciansLookupService),
                    type: 'multiSelect',
                    class: 'form-span-6 multi-input',
                    placeholder: 'Referring Provider',
                    selectOptions: (event) => {
                        let listValue = []
                        let listText = []
                        event.forEach((x: FieldSelectOption) => {
                            listValue.push(x.value)
                            listText.push(x.label)
                        })
                        SelectedProcedures.textCriteria['Referring Providers'] = listText.join(',');
                        SelectedProcedures.cleanFilterParams['referringProviderIds'] = listValue
                    }
                },
                {
                    matLabel: 'Procedures',
                    name: 'procedureCodeIds',
                    placeholder: 'Procedure Code',
                    apiService: injector.get(ProcedureCodesLookupService),
                    class: 'form-span-6 multi-input',
                    type: 'multiSelect',
                    selectOptions: (event) => {
                        let listValue = []
                        let listText = []
                        event.forEach((x: FieldSelectOption) => {
                            listValue.push(x.value)
                            listText.push(x.label)
                        })
                        SelectedProcedures.textCriteria['Procedure Codes'] = listText.join(',');
                        SelectedProcedures.cleanFilterParams['procedureCodeIds'] = listValue

                    }
                },
                {
                    matLabel: 'Place of Service Codes',
                    name: 'placeOfServiceCodeIds',
                    placeholder: 'Place of Service Codes',
                    apiService: injector.get(PlacesOfServiceCodesLookupService),
                    class: 'form-span-6 multi-input',
                    type: 'multiSelect',
                    selectOptions: (event) => {
                        let listValue = []
                        let listText = []
                        event.forEach((x: FieldSelectOption) => {
                            listValue.push(x.value)
                            listText.push(x.label)
                        })
                        SelectedProcedures.textCriteria['Place of Service Codes'] = listText.join(',');
                        SelectedProcedures.cleanFilterParams['placeOfServiceCodeIds'] = listValue

                    }
                },
                {
                    label: 'Hold Status',
                    name: 'holdStatus',
                    type: 'select',
                    multiple: true,
                    options: [
                        { label: 'Any', value: 'B' },
                        { label: 'Insurance', value: 'H' },
                        { label: 'Guarantor', value: 'R' }
                    ],
                    class: 'form-span-3',
                    selectionChanged: (event) => {
                        SelectedProcedures.textCriteria['Hold Status'] = event.source.triggerValue
                    }
                },
                {
                    label: 'Coverages',
                    name: 'coverages',
                    type: 'select',
                    multiple: true,
                    options: [
                        {
                            label: 'All Insurances',
                            value: '1'
                        },
                        {
                            label: 'Primary',
                            value: '2'
                        },
                        {
                            label: 'Secondary',
                            value: '3'
                        },
                        {
                            label: 'Tertiary',
                            value: '4'
                        },
                        {
                            label: 'Secondary and Tertiary',
                            value: '5'
                        }
                    ],
                    class: 'form-span-3',
                    selectionChanged: (event) => {
                        SelectedProcedures.textCriteria['Coverages'] = event.source.triggerValue
                    }
                },
                {
                    label: 'Procedure Service Date Range',
                    name: 'serviceDateRange',
                    type: 'dateRange',
                    class: 'form-span-6',
                    daterangeStartChanged: (event) => {
                        SelectedProcedures.textCriteria['Start Date'] = event.value.toLocaleDateString();
                    },
                    daterangeEndChanged: (event) => {
                        SelectedProcedures.textCriteria['End Date'] = event.value.toLocaleDateString();
                    }
                },
                {
                    label: '',
                    name: 'serviceDateRangeStart',
                    type: 'noInput',
                    class: 'display-none',
                },
                {
                    label: '',
                    name: 'serviceDateRangeEnd',
                    type: 'noInput',
                    class: 'display-none',
                },
                {
                    label: 'Procedure Posted Date Range',
                    name: 'postDateRange',
                    type: 'dateRange',
                    class: 'form-span-6',
                    daterangeStartChanged: (event) => {
                        SelectedProcedures.textCriteria['Start Post Date'] = event.value.toLocaleDateString();
                    },
                    daterangeEndChanged: (event) => {
                        SelectedProcedures.textCriteria['End Post Date'] = event.value.toLocaleDateString();
                    }
                },
                {
                    label: '',
                    name: 'postDateRangeStart',
                    type: 'noInput',
                    class: 'display-none',
                },
                {
                    label: '',
                    name: 'postDateRangeEnd',
                    type: 'noInput',
                    class: 'display-none',
                },
                {
                    label: 'Billed Date Range',
                    name: 'billedDateRange',
                    type: 'dateRange',
                    class: 'form-span-6',
                    daterangeStartChanged: (event) => {
                        SelectedProcedures.textCriteria['Start Billed Date'] = event.value.toLocaleDateString();
                    },
                    daterangeEndChanged: (event) => {
                        SelectedProcedures.textCriteria['End Billed Date'] = event.value.toLocaleDateString();
                    }
                },
                {
                    label: '',
                    name: 'billedDateRangeStart',
                    type: 'noInput',
                    class: 'display-none'
                },
                {
                    label: '',
                    name: 'billedDateRangeEnd',
                    type: 'noInput',
                    class: 'display-none'
                },
                {
                    label: 'Procedure Balance Minimum',
                    name: 'minBalance',
                    class: 'form-span-3',
                    type: 'number',
                    selectionChanged: (event) => {
                        SelectedProcedures.textCriteria['Balance Min'] = event;
                    },
                    initial: 0

                },
                {
                    label: 'Procedure Balance Maximum',
                    name: 'maxBalance',
                    class: 'form-span-3',
                    type: 'number',
                    initial: 99999,
                    selectionChanged: (event) => {
                        SelectedProcedures.textCriteria['Balance Max'] = event;
                    }
                }
            ]
        }
    ],
    getGridConfiguration: (injector: Injector) => {
        const locale = injector.get(LOCALE_ID);
        const datePipe: DateDisplayPipe = new DateDisplayPipe(locale);
        const percentFormat = new Intl.NumberFormat('en-US', { style: "percent", minimumFractionDigits: 1, maximumFractionDigits: 2 });
        const moneyFormat = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });
        return {
            service: injector.get<any>(SelectedProceduresService),
            columnDefinitions: [
                {
                    id: 'AccountNumber',
                    displayKey: 'AccountNumber',
                    headerText: 'Account',
                    displayOrder: 0,
                    columnSize: ColumnSizes.narrow,
                    filterable: true,
                    sortable: true
                },
                {
                    id: 'PatientName',
                    displayKey: 'PatientName',
                    headerText: 'Patient',
                    displayOrder: 1,
                    columnSize: ColumnSizes.normal,
                    filterable: true,
                    sortable: true
                },
                {
                    id: 'VisitNumber',
                    displayKey: 'VisitNumber',
                    headerText: 'Visit',
                    displayOrder: 2,
                    columnSize: ColumnSizes.extraNarrow,
                    filterable: true,
                    sortable: true
                },
                {
                    id: 'PrimaryInsuranceCarrier',
                    displayKey: 'PrimaryInsuranceCarrier',
                    headerText: 'Primary',
                    displayOrder: 3,
                    columnSize: ColumnSizes.normal,
                    filterable: true,
                    sortable: true
                },
                {
                    id: 'SecondaryInsuranceCarrier',
                    displayKey: 'SecondaryInsuranceCarrier',
                    headerText: 'Secondary',
                    displayOrder: 4,
                    columnSize: ColumnSizes.normal,
                    filterable: true,
                    sortable: true
                },
                {
                    id: 'TertiaryInsuranceCarrier',
                    displayKey: 'TertiaryInsuranceCarrier',
                    headerText: 'Tertiary',
                    displayOrder: 5,
                    columnSize: ColumnSizes.normal,
                    filterable: true,
                    sortable: true
                },
                {
                    id: 'Procedure',
                    displayKey: 'Procedure',
                    headerText: 'Procedure',
                    displayOrder: 6,
                    columnSize: ColumnSizes.normal,
                    filterable: true,
                    sortable: true
                },
                {
                    id: 'ProcedureAmount',
                    displayKey: 'ProcedureAmount',
                    headerText: 'Procedure Amount',
                    displayOrder: 7,
                    columnSize: ColumnSizes.narrow,
                    filterable: true,
                    sortable: true,
                    bodyClasses: [BodyClasses.alignRight],
                    formatter: moneyFormat.format
                },
                {
                    id: 'ProcedureBalance',
                    displayKey: 'ProcedureBalance',
                    headerText: 'Procedure Balance',
                    displayOrder: 8,
                    columnSize: ColumnSizes.narrow,
                    filterable: true,
                    sortable: true,
                    bodyClasses: [BodyClasses.alignRight],
                    formatter: moneyFormat.format
                },
                {
                    id: 'Location',
                    displayKey: 'Location',
                    headerText: 'Location',
                    displayOrder: 9,
                    columnSize: ColumnSizes.normal,
                    filterable: true,
                    sortable: true
                },
                {
                    id: 'AttendingProvider',
                    displayKey: 'AttendingProvider',
                    headerText: 'Attending',
                    displayOrder: 10,
                    columnSize: ColumnSizes.normal,
                    filterable: true,
                    sortable: true
                },
                {
                    id: 'ReferringProvider',
                    displayKey: 'ReferringProvider',
                    headerText: 'Referring',
                    displayOrder: 11,
                    columnSize: ColumnSizes.normal,
                    filterable: true,
                    sortable: true
                },
                {
                    id: 'FinancialClass',
                    displayKey: 'FinancialClass',
                    headerText: 'Financial Class',
                    displayOrder: 12,
                    columnSize: ColumnSizes.normal,
                    filterable: true,
                    sortable: true
                },
                {
                    id: 'ServiceDate',
                    displayKey: 'ServiceDate',
                    headerText: 'Service Date',
                    displayOrder: 13,
                    columnSize: ColumnSizes.date,
                    filterable: true,
                    sortable: true,
                    filterConfig: {
                        filterType: FilterType.date,
                        filterKey: 'serviceDate'
                    },
                    formatter: (x) => {
                        return datePipe.transform(x)
                    }
                },
                {
                    id: 'PostingDate',
                    displayKey: 'PostingDate',
                    headerText: 'Posting Date',
                    displayOrder: 14,
                    columnSize: ColumnSizes.date,
                    filterable: true,
                    sortable: true,
                    filterConfig: {
                        filterType: FilterType.date,
                        filterKey: 'PostingDate'
                    },
                    formatter: (x) => {
                        return datePipe.transform(x)
                    }
                },
                {
                    id: 'LastBilledDate',
                    displayKey: 'LastBilledDate',
                    headerText: 'Billed Date',
                    displayOrder: 15,
                    columnSize: ColumnSizes.date,
                    filterable: true,
                    sortable: true,
                    filterConfig: {
                        filterType: FilterType.date,
                        filterKey: 'LastBilledDate'
                    },
                    formatter: (x) => {
                        return datePipe.transform(x)
                    }
                },
                {
                    id: 'ResponsibleParty',
                    displayKey: 'ResponsibleParty',
                    headerText: 'Responsible Party',
                    displayOrder: 16,
                    columnSize: ColumnSizes.normal,
                    filterable: true,
                    sortable: true
                },
                {
                    id: 'PlaceOfServiceCode',
                    displayKey: 'PlaceOfServiceCode',
                    headerText: 'POS',
                    displayOrder: 17,
                    columnSize: ColumnSizes.normal,
                    filterable: true,
                    sortable: true
                },
            ]
        };
    }
};
