/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HoldingIssue } from './holdingIssue';

export interface ClaimProviderViewModel {
  id?: string | null;
  lastName?: string | null;
  middleName?: string | null;
  firstName?: string | null;
  title?: string | null;
  npi?: string | null;
  taxonomy?: string | null;
  isNonPerson?: boolean;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  stateCode?: string | null;
  zip?: string | null;
  issues?: Array<HoldingIssue> | null;
}
