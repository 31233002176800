/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ConversionUploadFileViewModel } from './conversionUploadFileViewModel';

export interface ConversionUploadViewModel {
  method?: string | null;
  location?: string | null;
  folder?: string | null;
  status?: string | null;
  headers?: boolean;
  truncate?: boolean;
  files?: Array<ConversionUploadFileViewModel> | null;
}
