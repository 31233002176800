/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RemitDownloadedFileSimpleViewModel } from './remitDownloadedFileSimpleViewModel';

export interface RemitHoldingSimpleViewModel {
  id?: string;
  datasetId?: string;
  remitDownloadedFileId?: string;
  checkAmount?: number;
  payMethod?: string | null;
  checkDate?: string | null;
  checkNumber?: string | null;
  payorName?: string | null;
  payorId?: string | null;
  remitDownloadedFile?: RemitDownloadedFileSimpleViewModel;
}
