/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EligibilityReferenceViewModel } from './eligibilityReferenceViewModel';
import { EligibilityTimePeriodViewModel } from './eligibilityTimePeriodViewModel';
import { EligibilityValidationViewModel } from './eligibilityValidationViewModel';
import { EligibilityBenefitsViewModel } from './eligibilityBenefitsViewModel';

export interface EligibilityDependentViewModel {
  id?: string;
  dependentLastOrgName?: string | null;
  dependentFirstName?: string | null;
  dependentMiddleName?: string | null;
  dependentAddressLine1?: string | null;
  dependentAddressLine2?: string | null;
  dependentCity?: string | null;
  dependentState?: string | null;
  dependentPostalCode?: string | null;
  dependentDateOfBirth?: string;
  dependentGenderCode?: string | null;
  dependentInsuredResponseCode?: string | null;
  dependentInsuredRelationshipCode?: string | null;
  dependentInsuredRelationshipName?: string | null;
  dependentInsuredMaintenanceTypeCode?: string | null;
  dependentInsuredMaintenanceReasonCode?: string | null;
  validations?: Array<EligibilityValidationViewModel> | null;
  eligibilityInformation?: Array<EligibilityBenefitsViewModel> | null;
  timePeriods?: Array<EligibilityTimePeriodViewModel> | null;
  references?: Array<EligibilityReferenceViewModel> | null;
}
