import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { RemindersState } from './reminders.state';
import * as RemindersActions from './reminders.actions';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { PagedModelRequest } from 'data';
import { of } from 'rxjs';
import { ReminderWrapperService } from '../../services/service-wrappers/reminder-wrapper.service';
import { loadUserSuccess } from '../user/user.actions';
// import {}
import { selectUserId } from '../user/user.selectors';

@Injectable()
export class RemindersEffects {
  constructor(private actions$: Actions, private store: Store<any>, private service: ReminderWrapperService) {}

  initializeReminders = createEffect(() =>
    this.actions$.pipe(
      ofType(loadUserSuccess),
      map(({}) => {
        return RemindersActions.loadReminders();
      })
    )
  );

  loadReminders = createEffect(() =>
    this.actions$.pipe(
      ofType(RemindersActions.loadReminders, RemindersActions.updateReminders),
      withLatestFrom(this.store.pipe(select(selectUserId))),
      switchMap(([action, userId]) => {
        const request: PagedModelRequest = {
          page: 1,
          pageSize: 100,
          propertySearches: [
            {
              propertyName: 'assignedUserId',
              comparisonType: 0,
              value: userId
            }
          ]
        };
        return this.service.apiV1ReminderAllDetailPost(request).pipe(
          map((reminderResponse) => {
            return {
              data: reminderResponse.data.map((d) => ({
                ...d,
                scheduledDate: new Date(d.scheduledDate)
              }))
            };
          }),
          map((reminderResponse: any) => {
            return RemindersActions.loadRemindersSuccess({ reminders: reminderResponse.data });
          }),
          catchError((error) => of(RemindersActions.loadRemindersFailure({ error })))
        );
      })
    )
  );
}
