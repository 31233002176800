/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface EligibilityDeliveryResponseViewModel {
  id?: string;
  datasetId?: string;
  eligibilityBenefitsResponseId?: string;
  quantityQualifierCode?: string | null;
  quantity?: string | null;
  measurementUnitCode?: string | null;
  sampleSelectionMod?: string | null;
  timePeriodQualifierCode?: string | null;
  numberOfPeriod?: string | null;
  deliveryFrequencyCode?: string | null;
  deliveryPatternTimeCode?: string | null;
}
