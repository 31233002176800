/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PatientDetailsViewModelDemographicUpdatesFieldViewModelListValueTuple } from './patientDetailsViewModelDemographicUpdatesFieldViewModelListValueTuple';
import { InsuranceDetailsViewModelDemographicUpdatesFieldViewModelListValueTuple } from './insuranceDetailsViewModelDemographicUpdatesFieldViewModelListValueTuple';
import { GuarantorDetailsViewModelDemographicUpdatesFieldViewModelListValueTuple } from './guarantorDetailsViewModelDemographicUpdatesFieldViewModelListValueTuple';
import { InsuranceHoldingDetailsViewModelDemographicUpdatesFieldViewModelListValueTuple } from './insuranceHoldingDetailsViewModelDemographicUpdatesFieldViewModelListValueTuple';

export interface DemographicUpdatesDetailViewModel {
  patient?: PatientDetailsViewModelDemographicUpdatesFieldViewModelListValueTuple;
  guarantor?: GuarantorDetailsViewModelDemographicUpdatesFieldViewModelListValueTuple;
  insurances?: Array<InsuranceDetailsViewModelDemographicUpdatesFieldViewModelListValueTuple> | null;
  insuranceHoldings?: Array<InsuranceHoldingDetailsViewModelDemographicUpdatesFieldViewModelListValueTuple> | null;
}
