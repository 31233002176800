/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { FinancialClassViewModel } from './financialClassViewModel';
import { CodeViewModel } from './codeViewModel';
import { InsuranceHoldingViewModel } from './insuranceHoldingViewModel';

export interface VisitHoldingViewModel {
  id?: string;
  datasetId?: string;
  sendingFacilityId?: string;
  patientId?: string | null;
  patientHoldingId?: string | null;
  accidentTypeId?: string | null;
  primaryInsuranceHoldingId?: string | null;
  secondaryInsuranceHoldingId?: string | null;
  tertiaryInsuranceHoldingId?: string | null;
  primaryInsuranceId?: string | null;
  secondaryInsuranceId?: string | null;
  tertiaryInsuranceId?: string | null;
  accidentStateCode?: string | null;
  referenceNumber?: number;
  financialClassId?: string | null;
  financialClassLinkId?: string | null;
  hospitalAdmitDate?: string | null;
  hospitalDischargeDate?: string | null;
  illnessInjuryDate?: string | null;
  patientStatus?: string | null;
  createdBy?: string | null;
  createdDate?: string;
  updatedBy?: string | null;
  updatedDate?: string | null;
  financialClass?: FinancialClassViewModel;
  accidentType?: CodeViewModel;
  primaryInsuranceAuthNumber?: string | null;
  secondaryInsuranceAuthNumber?: string | null;
  tertiaryInsuranceAuthNumber?: string | null;
  primaryInsuranceHolding?: InsuranceHoldingViewModel;
  secondaryInsuranceHolding?: InsuranceHoldingViewModel;
  tertiaryInsuranceHolding?: InsuranceHoldingViewModel;
}
