import { Injector, LOCALE_ID } from '@angular/core';
import { Validators } from '@angular/forms';
import { BodyClasses, ColumnSizes, CustomValidators, FieldSelectOption, FilterType } from 'components';
import { FinancialClassesLookupService, InsuranceCarriersLookupService } from 'src/app/core/services/lookup';
import { Report } from '../../models/report';
import { GroupBillingService } from './group-billing.service';
const CHECKBOX_CLASSES = 'reverse-check-box form-span-12 mb-2';

function insLookup(injector: Injector) : InsuranceCarriersLookupService {
  var lookup: InsuranceCarriersLookupService = injector.get(InsuranceCarriersLookupService);
  lookup.setCarrierTypeFilter("GroupBilling");
  return lookup;
}

export const GroupBilling: Report = {
  id: 'group-billing-report',
  label: 'Group Billing Report',
  textCriteria: { 'Start Date': '', 'End Date': '', 'Insurance Carriers': '', 'Financial Classes': ''},
  cleanFilterParams: {
    'insuranceCarrierIds': '', 'financialClassIds': ''
  },
  getReportTitle: () => {
    return GroupBilling.label + ' ' + GroupBilling.textCriteria['Start Date'] + ' - ' + GroupBilling.textCriteria['End Date']
  },
  getSearchCriteria: (injector: Injector) => [
    {
      title: 'None selected = all selected',
      controls: [
        {
          label: 'Date Range',
          name: 'dateRange',
          type: 'dateRange',
          class: 'form-span-12',
          daterangeStartChanged: (event) => {
            GroupBilling.textCriteria['Start Date'] = event.value.toLocaleDateString();
          },
          daterangeEndChanged: (event) => {
            GroupBilling.textCriteria['End Date'] = event.value.toLocaleDateString();
          }
        },
        {
          label: '',
          name: 'dateRangeStart',
          type: 'noInput',
          class: 'display-none',
          validators: Validators.required
        },
        {
          label: '',
          name: 'dateRangeEnd',
          type: 'noInput',
          class: 'display-none',
          validators: Validators.required
        },
        {
          matLabel: 'Insurance Carriers',
          name: 'insuranceCarrierIds',
          apiService: insLookup(injector),
          class: 'form-span-12 multi-input',
          type: 'multiSelect',
          placeholder: 'Insurance Carriers',
          selectOptions: (event: []) => {
            let listValue = []
            let listText = []
            event.forEach((x: FieldSelectOption) => {
              listValue.push(x?.value)
              listText.push(x.label)
            })
            GroupBilling.textCriteria['Insurance Carriers'] = listText.join(',');
            GroupBilling.cleanFilterParams['insuranceCarrierIds'] = listValue
          },
        },
        {
          matLabel: 'Financial Class',
          name: 'financialClassIds',
          apiService: injector.get(FinancialClassesLookupService),
          class: 'form-span-12 multi-input',
          type: 'multiSelect',
          placeholder: 'Financial Class',
          selectOptions: (event) => {
            let listValue = []
            let listText = []
            event.forEach((x: FieldSelectOption) => {
              listValue.push(x.value)
              listText.push(x.label)
            })
            GroupBilling.textCriteria['Financial Classes'] = listText.join(',');
            GroupBilling.cleanFilterParams['financialClassIds'] = listValue
          }
        },

      ]
    }
  ],
  getGridConfiguration: (injector: Injector) => {
    const moneyFormat = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });
    return {
      service: injector.get<any>(GroupBillingService),
      columnDefinitions: [
        {
          id: 'Group Code',
          displayKey: 'Group Code',
          headerText: 'Group Code',
          displayOrder: 0,
          columnSize: ColumnSizes.normal,
          filterable: true,
          sortable: true
        },
        {
          id: 'Mail To Name',
          displayKey: 'Mail To Name',
          headerText: 'Mail To Name',
          displayOrder: 1,
          columnSize: ColumnSizes.extraWide,
          filterable: true,
          sortable: true
        },
        {
          id: 'Mail To Address1',
          displayKey: 'Mail To Address1',
          headerText: 'Mail To Address1',
          displayOrder: 2,
          columnSize: ColumnSizes.normal,
          filterable: true,
          sortable: true
        },
        {
          id: 'Mail To Address 2',
          displayKey: 'Mail To Address2',
          headerText: 'Mail To Address 2',
          displayOrder: 3,
          columnSize: ColumnSizes.normal,
          filterable: true,
          sortable: true
        },
        {
          id: 'Mail To City',
          displayKey: 'Mail To City',
          headerText: 'Mail To City',
          displayOrder: 4,
          columnSize: ColumnSizes.normal,
          filterable: true,
          sortable: true
        },
        {
          id: 'Mail To State',
          displayKey: 'Mail To State',
          headerText: 'Mail To State',
          displayOrder: 5,
          columnSize: ColumnSizes.extraNarrow,
          filterable: true,
          sortable: true
        },
        {
          id: 'Mail To Zip',
          displayKey: 'Mail To Zip',
          headerText: 'Mail To Zip',
          displayOrder: 6,
          columnSize: ColumnSizes.narrow,
          filterable: true,
          sortable: true
        },
        {
          id: 'Account Number',
          displayKey: 'Account Number',
          headerText: 'Account Number',
          displayOrder: 7,
          columnSize: ColumnSizes.normal,
          filterable: true,
          sortable: true
        },
        {
          id: 'Patient Name',
          displayKey: 'Patient Name',
          headerText: 'Patient Name',
          displayOrder: 8,
          columnSize: ColumnSizes.normal,
          filterable: true,
          sortable: true
        },
        {
          id: 'Birth Date',
          displayKey: 'Birth Date',
          headerText: 'Birth Date',
          displayOrder: 9,
          columnSize: ColumnSizes.normal,
          filterable: true,
          sortable: true
        },
        {
            id: 'Service Date',
            displayKey: 'Service Date',
            headerText: 'Service Date',
            displayOrder: 10,
            columnSize: ColumnSizes.normal,
            filterable: true,
            sortable: true
        },
        {
            id: 'Type',
            displayKey: 'Type',
            headerText: 'Type',
            displayOrder: 11,
            columnSize: ColumnSizes.extraNarrow,
            filterable: true,
            sortable: true
        },
        {
            id: 'Procedure Code',
            displayKey: 'Procedure Code',
            headerText: 'Procedure Code',
            displayOrder: 12,
            columnSize: ColumnSizes.narrow,
            filterable: true,
            sortable: true
        },
        {
            id: 'Description',
            displayKey: 'Description',
            headerText: 'Description',
            displayOrder: 13,
            columnSize: ColumnSizes.wide,
            filterable: true,
            sortable: true
        },
        {
            id: 'Amount',
            displayKey: 'Amount',
            headerText: 'Amount',
            displayOrder: 14,
            columnSize: ColumnSizes.narrow,
            filterable: true,
            sortable: true,
            formatter: moneyFormat.format
        },
        {
            id: 'Amount Paid',
            displayKey: 'Amount Paid',
            headerText: 'Amount Paid',
            displayOrder: 15,
            columnSize: ColumnSizes.narrow,
            filterable: true,
            sortable: true,
            formatter: moneyFormat.format
        },
        {
            id: 'Diagnosis Code',
            displayKey: 'Diagnosis Code',
            headerText: 'Diagnosis Code',
            displayOrder: 16,
            columnSize: ColumnSizes.narrow,
            filterable: true,
            sortable: true
        },
        {
            id: 'Attending Provider',
            displayKey: 'Attending Provider',
            headerText: 'Attending Provider',
            displayOrder: 17,
            columnSize: ColumnSizes.normal,
            filterable: true,
            sortable: true
        },
        {
            id: 'Attending Provider NPI',
            displayKey: 'Attending Provider NPI',
            headerText: 'Attending Provider NPI',
            displayOrder: 18,
            columnSize: ColumnSizes.narrow,
            filterable: true,
            sortable: true
        },
        {
            id: 'Remit To Name',
            displayKey: 'Remit To Name',
            headerText: 'Remit To Name',
            displayOrder: 19,
            columnSize: ColumnSizes.normal,
            filterable: true,
            sortable: true
        },
        {
            id: 'Remit Address #1',
            displayKey: 'Remit Address #1',
            headerText: 'Remit Address 1',
            displayOrder: 20,
            columnSize: ColumnSizes.normal,
            filterable: true,
            sortable: true
        },
        {
            id: 'Remit Address #2',
            displayKey: 'Remit Address #2',
            headerText: 'Remit Address 2',
            displayOrder: 21,
            columnSize: ColumnSizes.normal,
            filterable: true,
            sortable: true
        },
        {
            id: 'Remit City',
            displayKey: 'Remit City',
            headerText: 'Remit City',
            displayOrder: 22,
            columnSize: ColumnSizes.normal,
            filterable: true,
            sortable: true
        },
        {
            id: 'Remit State',
            displayKey: 'Remit State',
            headerText: 'Remit State',
            displayOrder: 23,
            columnSize: ColumnSizes.narrow,
            filterable: true,
            sortable: true
        },
        {
            id: 'Remit Zip',
            displayKey: 'Remit Zip',
            headerText: 'Remit Zip',
            displayOrder: 24,
            columnSize: ColumnSizes.narrow,
            filterable: true,
            sortable: true
        },
        {
            id: 'Phone',
            displayKey: 'Phone',
            headerText: 'Phone',
            displayOrder: 25,
            columnSize: ColumnSizes.normal,
            filterable: true,
            sortable: true
        },
        {
            id: 'Remit Email',
            displayKey: 'Remit Email',
            headerText: 'Remit Email',
            displayOrder: 26,
            columnSize: ColumnSizes.normal,
            filterable: true,
            sortable: true
        },
        {
            id: 'Visit Number',
            displayKey: 'Visit Number',
            headerText: 'Visit Number',
            displayOrder: 27,
            columnSize: ColumnSizes.extraNarrow,
            filterable: true,
            sortable: true
        },
        {
          id: 'Statement Total Balance',
          displayKey: 'Statement Total Balance',
          headerText: 'Statement Total Balance',
          displayOrder: 28,
          columnSize: ColumnSizes.narrow,
          filterable: true,
          sortable: true,
          formatter: moneyFormat.format
      },
      {
        id: 'Mail To Email',
        displayKey: 'Mail To Email',
        headerText: 'Mail To Email',
        displayOrder: 29,
        columnSize: ColumnSizes.normal,
        filterable: true,
        sortable: true
      },
      ]
    };
  }
};
