<div class='lib-form-container'>
  <header class='lib-form-container__form-header'>
    <h3>{{title}}</h3>
    <article class='lib-form-container__main-buttons'>
      <ng-content select='[top-buttons]'></ng-content>
    </article>
  </header>
  <ng-content></ng-content>
  <footer class='lib-form-container__form-footer'>
    <article class='lib-form-container__main-buttons'>
      <ng-content select='[bottom-buttons]'></ng-content>
    </article>
  </footer>
</div>
