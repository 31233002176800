/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface VisitSearchViewModel {
  visitId?: string;
  patientId?: string;
  patientName?: string | null;
  accountNumber?: number;
  readonly accountNumberDisplay?: string | null;
  serviceDate?: string | null;
  visitNumber?: number;
  holdStatus?: string | null;
  procedureAmount?: number;
  paymentAmount?: number;
  adjustmentAmount?: number;
  visitBalance?: number;
  carrierName?: string | null;
  insuranceCarrierId?: string;
  financialClassId?: string;
  insuranceId?: string;
  financialClassCode?: string | null;
  financialClassName?: string | null;
  order?: string | null;
  currentBatch?: string | null;
  otherBatches?: string | null;
}
