import { Injector, LOCALE_ID } from '@angular/core';
import { Validators } from '@angular/forms';
import { ColumnSizes, FieldSelectOption, FilterType } from 'components';
import { DateDisplayPipe } from 'projects/components/src/lib/shared/pipes/date-display/date-display.pipe';
import { Report } from '../../models/report';
import { DiagnosisCodesLookupService, ProcedureCodesLookupService } from '@core/services/lookup';
import { VisitDetailReportService } from './visit-detail-report.service';

export const VisitDetailReport: Report = {
  id: 'visit-detail',
  label: 'Visit Detail Report',
  textCriteria: { 'Start Date': '', 'End Date': '', 'Procedure Codes': '', 'Diagnosis Codes': ''},
  cleanFilterParams: { 'procedureIds': '', 'diagnosisCodeIds': '' },
  getReportTitle: () => {
    return VisitDetailReport.label + ' ' + VisitDetailReport.textCriteria['Start Date'] + ' - ' + VisitDetailReport.textCriteria['End Date'];
  },
  getSearchCriteria: (injector: Injector) => [
    {
      title: '',
      controls: [
        {
          label: 'Service Date Range',
          name: 'dateRange',
          type: 'dateRange',
          class: 'form-span-12',
          daterangeStartChanged: (event) => {
            VisitDetailReport.textCriteria['Start Date'] = event.value.toLocaleDateString();
          },
          daterangeEndChanged: (event) => {
            VisitDetailReport.textCriteria['End Date'] = event.value.toLocaleDateString();
          }  
        },
        {
          label: '',
          name: 'dateRangeStart',
          type: 'noInput',
          class: 'display-none',
          validators: Validators.required
        },
        {
          label: '',
          name: 'dateRangeEnd',
          type: 'noInput',
          class: 'display-none',
          validators: Validators.required
        },
        {
          label: 'Procedure Codes',
          name: 'procedureIds',
          apiService: injector.get(ProcedureCodesLookupService),
          class: 'form-span-12 multi-input',
          type: 'multiSelect',
          selectOptions: (event) => {
            let listValue = []
            let listText = []
            event.forEach((x: FieldSelectOption) => {
              listValue.push(x?.value)
              listText.push(x.label)
            })
            VisitDetailReport.textCriteria['Procedure Codes'] = listText.join(',');
            VisitDetailReport.cleanFilterParams['procedureIds'] = listValue
          }
        },
        {
          label: 'Diagnosis Codes',
          name: 'diagnosisCodeIds',
          apiService: injector.get(DiagnosisCodesLookupService),
          excludeSelectAllOption: true,
          class: 'form-span-12 multi-input',
          type: 'multiSelect',
          selectOptions: (event) => {
            let listValue = []
            let listText = []
            event.forEach((x: FieldSelectOption) => {
              listValue.push(x?.value)
              listText.push(x.label)
            })
            VisitDetailReport.textCriteria['Diagnosis Codes'] = listText.join(',')
            VisitDetailReport.cleanFilterParams['diagnosisCodeIds'] = listValue
          }
        }
      ]
    }
  ],
  getGridConfiguration: (injector: Injector) => {
    const locale = injector.get(LOCALE_ID);
    const datePipe: DateDisplayPipe = new DateDisplayPipe(locale);
    const moneyFormat = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });
    return {
      service: injector.get<any>(VisitDetailReportService),
      columnDefinitions: [
        {
          id: 'account_number',
          displayKey: 'account_number',
          headerText: 'Account Number',
          sortable: true,
          filterable: true,
          displayOrder: 0,
          columnSize: ColumnSizes.normal,
        },
        {
          id: 'last_first_name',
          displayKey: 'last_first_name',
          headerText: 'Patient Name',
          sortable: true,
          displayOrder: 1,
          columnSize: ColumnSizes.wide,
          filterable: true,
        },
        {
          id: 'primary_insurance_carrier',
          displayKey: 'primary_insurance_carrier',
          headerText: 'Primary Insurance',
          sortable: true,
          displayOrder: 2,
          columnSize: ColumnSizes.extraWide,
          filterable: true,
        },
        {
          id: 'secondary_insurance_carrier',
          displayKey: 'secondary_insurance_carrier',
          headerText: 'Secondary Insurance',
          sortable: true,
          displayOrder: 3,
          columnSize: ColumnSizes.extraWide,
          filterable: true,
        },
        {
          id: ' tertiary_insurance_carrier',
          displayKey: 'tertiary_insurance_carrier',
          headerText: 'Tertiary Insurance',
          sortable: true,
          displayOrder: 4,
          columnSize: ColumnSizes.wide,
          filterable: true,
        },
        {
          id: ' birth_date',
          displayKey: 'birth_date',
          headerText: 'Birth Date',
          sortable: true,
          displayOrder: 5,
          columnSize: ColumnSizes.normal,
          filterable: true,
        },
        {
          id: 'sex',
          displayKey: 'sex',
          headerText: 'Gender',
          sortable: true,
          displayOrder: 6,
          columnSize: ColumnSizes.narrow,
          filterable: true,
        },
        {
          id: 'from_date',
          displayKey: 'from_date',
          headerText: 'Service Date',
          sortable: true,
          displayOrder: 7,
          columnSize: ColumnSizes.normal,
          filterable: true,
        },
        {
          id: 'reference_number',
          displayKey: 'reference_number',
          headerText: 'Visit #',
          displayOrder: 8,
          columnSize: ColumnSizes.narrow,
          filterable: true,
        },
        {
          id: 'procedure_code',
          displayKey: 'procedure_code',
          headerText: 'Procedure Code',
          sortable: true,
          displayOrder: 9,
          columnSize: ColumnSizes.normal,
          filterable: true,
        },
        {
          id: 'diagnosis_codes',
          displayKey: 'diagnosis_codes',
          headerText: 'Diagnosis Codes',
          sortable: true,
          displayOrder: 10,
          columnSize: ColumnSizes.extraWide,
          filterable: true,
        }
      ]
    };
  }
};
