import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthorizedComponent } from './authorized.component';
import { SidebarModule } from 'components/core/sidebar';
import { CoreModule as CoreComponentModule } from 'components/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RemindersModule } from '../../components/reminders/reminders.module';
import { HttpClientModule } from '@angular/common/http';
import { ImplementationModule } from 'src/app/features/implementation/implementation.module';
import { GlobalSearchModule } from '@core/components/global-search/global-search.module';
@NgModule({
  declarations: [AuthorizedComponent],
  imports: [
    CommonModule,
    SidebarModule,
    CoreComponentModule,
    RouterModule,
    MatIconModule,
    MatButtonModule,
    RemindersModule,
    HttpClientModule,
    ImplementationModule,
    GlobalSearchModule
  ]
})
export class AuthorizedModule {}
