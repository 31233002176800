/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { StatusSegmentModel } from './statusSegmentModel';

export interface ClaimStatusProcedureViewModel {
  id?: string;
  datasetId?: string;
  claimStatusPatientResponseId?: string;
  procedureTransactionId?: string | null;
  procedureCode?: string | null;
  modifier1?: string | null;
  modifier2?: string | null;
  modifier3?: string | null;
  modifier4?: string | null;
  chargeAmount?: number;
  quantity?: number;
  entityIdentifier3Code?: string | null;
  lineItemControlNumber?: string | null;
  serviceDate?: string | null;
  isCleared?: boolean;
  statusSegmentModel?: StatusSegmentModel;
}
