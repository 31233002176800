import { Component, Inject, Injector, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroupDefinition, GridComponent } from 'components';
import { compare } from 'fast-json-patch';
import { Observable, of } from 'rxjs';
import { first, map, take } from 'rxjs/operators';
import { NotificationService } from 'src/app/core/services/notification.service';
import { InsuranceWrapperService } from 'src/app/core/services/service-wrappers/insurance-wrapper.service';

@Component({
  selector: 'app-add-ansi-dialog',
  templateUrl: './add-ansi-dialog.component.html',
  styleUrls: ['./add-ansi-dialog.component.scss']
})
export class AddAnsiDialogComponent implements OnInit {
  @ViewChild(GridComponent) grid: GridComponent;
  formGroup = new UntypedFormGroup({});
  formInitialized = false;
  saving = false;
  insuranceCarrierId;

  formDefinitions: FormGroupDefinition[] = [
    {
      hideTitle: true,
      controls: [
        {
          label: '',
          type: 'empty',
          class: 'form-span-1'
        },
        {
          label: 'Loop/Segment Name',
          name: 'loopSegmentName',
          type: 'select',
          options: ['Loop/Segment 1', 'Loop/Segment 2', 'Loop/Segment 3'],
          class: 'form-span-5'
        },
        {
          label: 'Value',
          name: 'value',
          type: 'select',
          options: ['Value 1', 'Value 2', 'Value 3'],
          class: 'form-span-5'
        }
      ]
    }
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private injector: Injector,
    private notificationService: NotificationService,
    public dialogRef: MatDialogRef<AddAnsiDialogComponent>,
    private dialog: MatDialog
  ) {
    this.insuranceCarrierId = data?.insuranceCarrierId;
  }

  ngOnInit(): void {
    this.setupForm();
  }

  setupForm() {
    this.formDefinitions.forEach((sc) => {
      sc.controls.forEach((control) => {
        if (control.type !== 'empty' && control.type !== 'label') {
          this.formGroup.addControl(control.name, new UntypedFormControl(control.initial ?? '', control.validators));
        }
      });
    });
    this.formInitialized = true;
  }

  save() {
    if (this.formGroup.valid) {
      this.saving = true;
    }

    const formData = {
      loopSegmentName: this.formGroup.get('loopSegmentName').value,
      value: this.formGroup.get('value').value
    };

    let saveObservable: Observable<any>;
    let notification;
    notification = 'Loop/Segment Name Added';

    saveObservable
      .pipe(take(1))
      .subscribe(
        (response) => {
          this.dialogRef.close(true);
          this.notificationService.success(notification);
        },
        (err) => this.notificationService.error('Saving Loop/Segment Name')
      )
      .add(() => {
        this.saving = false;
      });
  }
}
