/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ARTransactionHoldingViewModel } from './aRTransactionHoldingViewModel';

export interface BatchDetailsViewModel {
  id?: string;
  batchType?: string | null;
  batchNumber?: number;
  checkNumber?: string | null;
  payorName?: string | null;
  expectedAmount?: number | null;
  batchStatus?: string | null;
  postingDate?: string | null;
  defaultPaymentTypeId?: string | null;
  createdDate?: string;
  arTransactionHoldings?: Array<ARTransactionHoldingViewModel> | null;
}
