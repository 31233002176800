/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EligibilityReferenceResponseViewModel } from './eligibilityReferenceResponseViewModel';
import { EligbilityValidationResponseViewModel } from './eligbilityValidationResponseViewModel';
import { EligibilitySubscriberResponseViewModel } from './eligibilitySubscriberResponseViewModel';

export interface EligibilityReceiverResponseViewModel {
  id?: string;
  datasetId?: string;
  eligibilityResponseFileId?: string;
  eligibilitySourceResponseId?: string;
  providerEntityIdentifierCode?: string | null;
  providerEntityTypeQualifier?: string | null;
  providerLastOrgName?: string | null;
  providerFirstName?: string | null;
  providerMiddleName?: string | null;
  providerIdentificationQualifierCode?: string | null;
  providerIdentificationCode?: string | null;
  providerTypeCode?: string | null;
  providerSpecialtyCode?: string | null;
  eligibilitySubscriberResponses?: Array<EligibilitySubscriberResponseViewModel> | null;
  references?: Array<EligibilityReferenceResponseViewModel> | null;
  validations?: Array<EligbilityValidationResponseViewModel> | null;
}
