/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface ClaimStatusHistoryViewModel {
  patientId?: string;
  visitNumber?: number;
  statusDate?: string | null;
  insuranceCarrier?: string | null;
  procedureCode?: string | null;
  categoryCode?: string | null;
  categoryDescription?: string | null;
  statusCode?: string | null;
  statusDescription?: string | null;
  freeFormText?: string | null;
  categoryCode2?: string | null;
  categoryDescription2?: string | null;
  statusCode2?: string | null;
  statusDescription2?: string | null;
  categoryCode3?: string | null;
  categoryDescription3?: string | null;
  statusCode3?: string | null;
  statusDescription3?: string | null;
}
