import { ReportGroup } from '../../models/report-group';
import { ARAgedTrialBalance } from './a-r-aged-trial-balance';
import { ARAgedTrialBalanceService } from './a-r-aged-trial-balance.service';
import { BusinessManagementSummaryReport } from './business-management-summary-report';
import { BusinessManagementSummaryReportService } from './business-management-summary-report.service';
import { CollectionRatioReport } from './collection-ratio-report';
import { CollectionRatioReportService } from './collection-ratio-report.service';
import { PatientMix } from './patient-mix';
import { PatientMixService } from './patient-mix.service';
import { ZeroBalance } from './zero-balance';
import { ZeroBalanceService } from './zero-balance.service';
import { DailyActivitySummary } from './daily-activity-summary';
import { DailyActivitySummaryService } from './daily-activity-summary.service';

export const FinancialAnalysisReportGroup: ReportGroup = {
  id: 'financial-analysis',
  label: 'Financial Analysis',
  reports: [
    ARAgedTrialBalance,
    BusinessManagementSummaryReport,
    CollectionRatioReport,
    DailyActivitySummary,
    PatientMix,
    ZeroBalance
  ]
};

export const FinancialAnalysisServices = [
  ARAgedTrialBalanceService,
  BusinessManagementSummaryReportService,
  CollectionRatioReportService,
  DailyActivitySummaryService,
  PatientMixService,
  ZeroBalanceService
];
