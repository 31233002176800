/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SendingFacilityViewModel } from './sendingFacilityViewModel';
import { LocationDetailsViewModel } from './locationDetailsViewModel';
import { PlaceOfServiceCodeLinkViewModel } from './placeOfServiceCodeLinkViewModel';
import { ProcedureViewModel } from './procedureViewModel';
import { PatientHoldingViewModel } from './patientHoldingViewModel';
import { CodeViewModel } from './codeViewModel';
import { HoldingIssue } from './holdingIssue';
import { FinancialClassLinkViewModel } from './financialClassLinkViewModel';
import { VisitHoldingViewModel } from './visitHoldingViewModel';
import { ProcedureResultHoldingViewModel } from './procedureResultHoldingViewModel';
import { LocationLinkViewModel } from './locationLinkViewModel';
import { FinancialClassViewModel } from './financialClassViewModel';
import { NdcUnit } from './ndcUnit';
import { PatientViewModel } from './patientViewModel';
import { ProviderLinkViewModel } from './providerLinkViewModel';
import { ProviderViewModel } from './providerViewModel';
import { DiagnosisCodeLinkViewModel } from './diagnosisCodeLinkViewModel';
import { ProcedureLinkViewModel } from './procedureLinkViewModel';
import { ProcedureDiagnosisCodeHoldingViewModel } from './procedureDiagnosisCodeHoldingViewModel';

export interface ProcedureTransactionHoldingDetailsViewModel {
  id?: string;
  datasetId?: string;
  sendingFacilityId?: string;
  patientHoldingId?: string | null;
  patientId?: string | null;
  visitId?: string | null;
  visitHoldingId?: string | null;
  procedureId?: string | null;
  procedureLinkId?: string | null;
  attendingProviderId?: string | null;
  attendingProviderLinkId?: string | null;
  referringProviderId?: string | null;
  referringProviderLinkId?: string | null;
  orderingProviderId?: string | null;
  orderingProviderLinkId?: string | null;
  primaryProviderId?: string | null;
  primaryProviderLinkId?: string | null;
  supervisingProviderId?: string | null;
  fromDate?: string | null;
  toDate?: string | null;
  placeOfServiceCodeId?: string | null;
  placeOfServiceCodeLinkId?: string | null;
  locationId?: string | null;
  locationLinkId?: string | null;
  modifier1Id?: string | null;
  modifier2Id?: string | null;
  modifier3Id?: string | null;
  modifier4Id?: string | null;
  procedureAmount?: number | null;
  quantity?: number;
  insuranceAuthNumber?: string | null;
  accessionNumber?: string | null;
  nationalDrugCodeId?: string | null;
  drugUnit?: NdcUnit;
  drugQuantity?: number | null;
  drugAmount?: number | null;
  drugCode?: string | null;
  prescriptionNumber?: string | null;
  holdPatientBilling?: boolean | null;
  holdInsuranceBilling?: boolean | null;
  nocProcedureDescription?: string | null;
  isDeleted?: boolean;
  deletedBy?: string | null;
  deletedDate?: string | null;
  createdBy?: string | null;
  createdDate?: string;
  updatedBy?: string | null;
  updatedDate?: string | null;
  claimComment?: string | null;
  financialClassId?: string | null;
  financialClassLinkId?: string | null;
  referringProviderRequired?: boolean;
  hasDemographicUpdates?: boolean;
  issues?: Array<HoldingIssue> | null;
  patientHolding?: PatientHoldingViewModel;
  patient?: PatientViewModel;
  sendingFacility?: SendingFacilityViewModel;
  visitHolding?: VisitHoldingViewModel;
  procedure?: ProcedureViewModel;
  procedureLink?: ProcedureLinkViewModel;
  attendingProvider?: ProviderViewModel;
  attendingProviderLink?: ProviderLinkViewModel;
  referringProvider?: ProviderViewModel;
  referringProviderLink?: ProviderLinkViewModel;
  orderingProvider?: ProviderViewModel;
  orderingProviderLink?: ProviderLinkViewModel;
  primaryProvider?: ProviderViewModel;
  supervisingProvider?: ProviderViewModel;
  primaryProviderLink?: ProviderLinkViewModel;
  location?: LocationDetailsViewModel;
  locationLink?: LocationLinkViewModel;
  placeOfServiceLink?: PlaceOfServiceCodeLinkViewModel;
  diagnosesLinks?: Array<DiagnosisCodeLinkViewModel> | null;
  modifier1?: CodeViewModel;
  modifier2?: CodeViewModel;
  modifier3?: CodeViewModel;
  modifier4?: CodeViewModel;
  procedureResultHolding?: ProcedureResultHoldingViewModel;
  diagnoses?: Array<ProcedureDiagnosisCodeHoldingViewModel> | null;
  placeOfServiceCode?: CodeViewModel;
  financialClass?: FinancialClassViewModel;
  financialClassLink?: FinancialClassLinkViewModel;
}
