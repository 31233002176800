import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { ColumnDefinition } from 'components';
import { of } from 'rxjs';
import { catchError, filter, map, take } from 'rxjs/operators';
import { BaseReportGridSource } from '../base-report-grid-source';
import { ReportWrapperService } from '../../../../core/services/service-wrappers/report-wrapper.service';
import { DateDisplayPipe } from 'projects/components/src/lib/shared/pipes/date-display/date-display.pipe';
import { saveAs } from 'file-saver';
import { getSelectedDataSetId } from 'src/app/core/state/data-set/data-set.selectors';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root'
})
export class ARAgedTrialBalanceService extends BaseReportGridSource<any> {
  dataSetId$ = this.baseStore.select(getSelectedDataSetId).pipe(filter((cId) => !!cId));
  dataSetId;
  request: any;
  constructor(
    private reportService: ReportWrapperService,
    @Inject(LOCALE_ID) private locale: string,
    private baseStore: Store<any>
  ) {
    super();
    this.dataSetId$.subscribe((dataSetIdFromStore) => {
      this.dataSetId = dataSetIdFromStore;
    });
  }
  datePipe: DateDisplayPipe = new DateDisplayPipe(this.locale);
  initialize(query: any, columnDefinitions: ColumnDefinition[]) {
    this.gridColumns = columnDefinitions;
    const queryTotal = Object.keys(query).length;

    if (queryTotal === 1) {
      this.request = {
        slug: 'aging-trial-balance',
        include_0: true,
        include_31: true,
        include_61: true,
        include_91: true,
        include_121: true,
        include_150_over: true,
        dataSetId: this.dataSetId,
        textParameters: this.textParameters
      };
    } else {
      this.request = {
        slug: 'aging-trial-balance',
        reportFormat: query.reportFormat,
        include_0: query.include_0 === true,
        include_31: query.include_31 === true,
        include_61: query.include_61 === true,
        include_91: query.include_91 === true,
        include_121: query.include_121 === true,
        include_150_over: query.include_150_over === true,
        attendingProviderIds: query.attendingProviderIds?.join(',') ?? '',
        responsibleInsuranceCarrierIds: query.responsibleInsuranceCarrierIds?.join(',') ?? '',
        primaryInsuranceCarrierIds: query.primaryInsuranceCarrierIds?.join(',') ?? '',
        financialClassIds: query.financialClassIds?.join(',') ?? '',
        locationIds: query.locationIds?.join(',') ?? '',
        responsibilityTypes: query.responsibilityTypes?.join(',') ?? "",
        showDetails: query.showDetails ?? false,
        asOfDate: this.datePipe.transform(query.asOfDate),
        dataSetId: this.dataSetId,
        excludeRefunds: query.excludeRefunds ?? false,
        textParameters: this.textParameters,
      };
    }

    this.loadInitialData(
      this.reportService.apiV1ReportPost(this.request).pipe(
        map((x) => {
          this.textParameters['User'] = x.processedBy;
          this.datasetName = x.datasetName;
          this.reportExecutionId = x.reportExecutionId;
          return x.data.map((data) => {
            return { ...data };
          });
        }),
        catchError(() => of([]))
      )
    );
  }
}
