import { Injectable } from '@angular/core';
import { ApiAutoCompleteService, FieldSelectOption } from 'components/shared';

import { Observable } from 'rxjs';
import { map, shareReplay, filter, take } from 'rxjs/operators';

import { PhysiciansWrapperService } from '../service-wrappers/physicians-wrapper.service';

@Injectable({
  providedIn: 'root'
})
export class AttendingPhysiciansLookupService extends ApiAutoCompleteService {

  attendingPhysician$ = this.service.apiV1PhysicianAllPost({ generalSearch: '', pageSize: 99999, propertySearches: [
            {
              propertyName: 'isBillable',
              value: 'true'
            }
          ] }).pipe(shareReplay(1));
  constructor(private service: PhysiciansWrapperService) {
    super();
    this.dynamicSearch = true;
  }

  existingValues: string []= [];

  mapRow(result) {
    return {
      value: result.id,
      label: `${result.providerNumber}-${result.firstName || ''} ${result.middleName || ''} ${result.lastName || ''}, ${result.address1 || ''}, ${result.city || ''} ${result.stateCode || ''} ${result.zip || ''} `,
      inactive: result.inactive,
      shortLabel: result.providerNumber
    };
  }

  search(value: string): Observable<FieldSelectOption[]> {
    if (value === '') {
      return this.attendingPhysician$.pipe(take(1),
        map((results) => this.sortByName(results.data)),
        map((results) => results.map(this.mapRow).filter((x: any) => !x.inactive || this.existingValues.includes(x.value)))
      );
    }
    return this.service.apiV1PhysicianAllPost({ 
      generalSearch: value, 
      pageSize: 100, 
      propertySearches: [
      {
        propertyName: 'isBillable',
        value: 'true'
      }]}).pipe(
      take(1),
      map((results) => this.sortByName(results.data)),
      map((results) => results.map(this.mapRow).filter((x: any) => !x.inactive || this.existingValues.includes(x.value)))
    );
  }

  sortByName(prov) {
    return prov.sort((a, b) => (a.lastName + ' ' + a.firstName < b.lastName + ' ' + b.firstName ? -1 : 1));
  }

  private cache: Observable<string>[] = [];
  load(id: string): Observable<FieldSelectOption> {
    if (!this.cache[id]) {
      this.cache[id] = this.service.apiV1PhysicianIdGet(id).pipe(map(this.mapRow)).pipe(shareReplay());
    }

    return this.cache[id];
  }

  loadByIds(ids: string[]): Observable<FieldSelectOption[]> {
    return this.service.loadByIds(ids).pipe(map((results) => results.map(this.mapRow)));
  }
}
