/**
 * ImaginePay API
 * This API facilitates partner integration within the platform.
 *
 * The version of the OpenAPI document: 22_12_0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent,
  HttpParameterCodec,
  HttpContext
} from '@angular/common/http';
import { CustomHttpParameterCodec } from '../encoder';
import { Observable } from 'rxjs';

// @ts-ignore
import { ImaginePayApiModelsMessageResponse } from '../model/imaginePayApiModelsMessageResponse';
// @ts-ignore
import { ImaginePayApiModelsReceiptReceiptRequestForm } from '../model/imaginePayApiModelsReceiptReceiptRequestForm';
// @ts-ignore
import { ImaginePayApiModelsReceiptTransactionReceiptResponse } from '../model/imaginePayApiModelsReceiptTransactionReceiptResponse';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable({
  providedIn: 'root'
})
export class ReceiptService {
  protected basePath = 'https://devapi.imaginepay.com';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  public encoder: HttpParameterCodec;

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }

  // @ts-ignore
  private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
    if (typeof value === 'object' && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }

  private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
    if (value == null) {
      return httpParams;
    }

    if (typeof value === 'object') {
      if (Array.isArray(value)) {
        (value as any[]).forEach((elem) => (httpParams = this.addToHttpParamsRecursive(httpParams, elem, key)));
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(key, (value as Date).toISOString().substr(0, 10));
        } else {
          throw Error('key may not be null if value is Date');
        }
      } else {
        Object.keys(value).forEach(
          (k) => (httpParams = this.addToHttpParamsRecursive(httpParams, value[k], key != null ? `${key}.${k}` : k))
        );
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error('key may not be null if value is not object or array');
    }
    return httpParams;
  }

  /**
   * Get a link to a transaction receipt
   * @param authorization access token
   * @param receiptRequestForm A request for a receipt
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public receiptGrantReceiptAccess(
    authorization: string,
    receiptRequestForm: ImaginePayApiModelsReceiptReceiptRequestForm,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json' | 'text/json' | 'application/xml' | 'text/xml';
      context?: HttpContext;
    }
  ): Observable<ImaginePayApiModelsReceiptTransactionReceiptResponse>;
  public receiptGrantReceiptAccess(
    authorization: string,
    receiptRequestForm: ImaginePayApiModelsReceiptReceiptRequestForm,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json' | 'text/json' | 'application/xml' | 'text/xml';
      context?: HttpContext;
    }
  ): Observable<HttpResponse<ImaginePayApiModelsReceiptTransactionReceiptResponse>>;
  public receiptGrantReceiptAccess(
    authorization: string,
    receiptRequestForm: ImaginePayApiModelsReceiptReceiptRequestForm,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json' | 'text/json' | 'application/xml' | 'text/xml';
      context?: HttpContext;
    }
  ): Observable<HttpEvent<ImaginePayApiModelsReceiptTransactionReceiptResponse>>;
  public receiptGrantReceiptAccess(
    authorization: string,
    receiptRequestForm: ImaginePayApiModelsReceiptReceiptRequestForm,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: 'application/json' | 'text/json' | 'application/xml' | 'text/xml';
      context?: HttpContext;
    }
  ): Observable<any> {
    if (authorization === null || authorization === undefined) {
      throw new Error('Required parameter authorization was null or undefined when calling receiptGrantReceiptAccess.');
    }
    if (receiptRequestForm === null || receiptRequestForm === undefined) {
      throw new Error(
        'Required parameter receiptRequestForm was null or undefined when calling receiptGrantReceiptAccess.'
      );
    }

    let localVarHeaders = this.defaultHeaders;
    if (authorization !== undefined && authorization !== null) {
      localVarHeaders = localVarHeaders.set('Authorization', String(authorization));
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json', 'text/json', 'application/xml', 'text/xml'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json',
      'text/json',
      'application/xml',
      'text/xml',
      'application/x-www-form-urlencoded'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    return this.httpClient.post<ImaginePayApiModelsReceiptTransactionReceiptResponse>(
      `${this.configuration.basePath}/receipt/grantaccess`,
      receiptRequestForm,
      {
        context: localVarHttpContext,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }
}
