/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface HL7ConfigurationViewModel {
  id?: string;
  datasetId?: string;
  name?: string | null;
  listenAtIp?: string | null;
  listenAtPort?: number;
  inTesting?: boolean;
  useLocal?: boolean;
  sendContinuousTest?: boolean;
  status?: string | null;
}
