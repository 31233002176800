/**
 * ImaginePay API
 * This API facilitates partner integration within the platform.
 *
 * The version of the OpenAPI document: 22_12_0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ImaginePayApiModelsAuditHistoryTransactionPaymentHistory } from './imaginePayApiModelsAuditHistoryTransactionPaymentHistory';

export interface ImaginePayApiModelsAuditHistoryTransactionHistory {
  amount?: number;
  transactionReferenceNumber?: string;
  autoAdjustedPayment?: boolean;
  payments?: Array<ImaginePayApiModelsAuditHistoryTransactionPaymentHistory>;
  receiptEmail?: string;
  referrerCode?: string;
  dateCreated?: string;
}
