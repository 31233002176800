/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Hl7FieldConfigurationViewModel } from './hl7FieldConfigurationViewModel';

export interface Hl7FileConfigurationViewModel {
  datasetId?: string | null;
  sendingFacilityId?: string | null;
  hl7FileConfigurationId?: string | null;
  sendingFacilityName?: string | null;
  hasDemos?: boolean;
  hasCharges?: boolean;
  fileContents?: string | null;
  fileType?: string | null;
  createdBy?: string | null;
  createdDate?: string;
  updatedDate?: string | null;
  externalAccountNumber?: Hl7FieldConfigurationViewModel;
  patientLastName?: Hl7FieldConfigurationViewModel;
  patientFirstName?: Hl7FieldConfigurationViewModel;
  patientMiddleName?: Hl7FieldConfigurationViewModel;
  patientSuffix?: Hl7FieldConfigurationViewModel;
  patientBirthDate?: Hl7FieldConfigurationViewModel;
  patientSex?: Hl7FieldConfigurationViewModel;
  patientSSN?: Hl7FieldConfigurationViewModel;
  medicalRecordNumber?: Hl7FieldConfigurationViewModel;
  patientDeathDate?: Hl7FieldConfigurationViewModel;
  patientMaritalStatus?: Hl7FieldConfigurationViewModel;
  patientAddress1?: Hl7FieldConfigurationViewModel;
  patientAddress2?: Hl7FieldConfigurationViewModel;
  patientCity?: Hl7FieldConfigurationViewModel;
  patientState?: Hl7FieldConfigurationViewModel;
  patientZip?: Hl7FieldConfigurationViewModel;
  patientCountry?: Hl7FieldConfigurationViewModel;
  patientHomePhone?: Hl7FieldConfigurationViewModel;
  patientWorkPhone?: Hl7FieldConfigurationViewModel;
  guarantorLastName?: Hl7FieldConfigurationViewModel;
  guarantorFirstName?: Hl7FieldConfigurationViewModel;
  guarantorMiddleName?: Hl7FieldConfigurationViewModel;
  guarantorSuffix?: Hl7FieldConfigurationViewModel;
  guarantorBirthDate?: Hl7FieldConfigurationViewModel;
  guarantorSex?: Hl7FieldConfigurationViewModel;
  guarantorSSN?: Hl7FieldConfigurationViewModel;
  guarantorHomePhone?: Hl7FieldConfigurationViewModel;
  guarantorWorkPhone?: Hl7FieldConfigurationViewModel;
  guarantorAddress1?: Hl7FieldConfigurationViewModel;
  guarantorAddress2?: Hl7FieldConfigurationViewModel;
  guarantorCity?: Hl7FieldConfigurationViewModel;
  guarantorStateCode?: Hl7FieldConfigurationViewModel;
  guarantorZip?: Hl7FieldConfigurationViewModel;
  guarantorCountryCode?: Hl7FieldConfigurationViewModel;
  guarantorEmployerName?: Hl7FieldConfigurationViewModel;
  guarantorEmployerAddress1?: Hl7FieldConfigurationViewModel;
  guarantorEmployerAddress2?: Hl7FieldConfigurationViewModel;
  guarantorEmployerCity?: Hl7FieldConfigurationViewModel;
  guarantorEmployerStateCode?: Hl7FieldConfigurationViewModel;
  guarantorEmployerZip?: Hl7FieldConfigurationViewModel;
  guarantorEmployerCountryCode?: Hl7FieldConfigurationViewModel;
  guarantorEmployerPhone?: Hl7FieldConfigurationViewModel;
  insuranceCompanyCode?: Hl7FieldConfigurationViewModel;
  insuranceCompanyName?: Hl7FieldConfigurationViewModel;
  policyNumber?: Hl7FieldConfigurationViewModel;
  groupNumber?: Hl7FieldConfigurationViewModel;
  planName?: Hl7FieldConfigurationViewModel;
  insuredRelation?: Hl7FieldConfigurationViewModel;
  insuredLastName?: Hl7FieldConfigurationViewModel;
  insuredFirstName?: Hl7FieldConfigurationViewModel;
  insuredMiddleName?: Hl7FieldConfigurationViewModel;
  insuredSuffix?: Hl7FieldConfigurationViewModel;
  insuredBirthDate?: Hl7FieldConfigurationViewModel;
  insuredSex?: Hl7FieldConfigurationViewModel;
  insuredAddress1?: Hl7FieldConfigurationViewModel;
  insuredAddress2?: Hl7FieldConfigurationViewModel;
  insuredCity?: Hl7FieldConfigurationViewModel;
  insuredStateCode?: Hl7FieldConfigurationViewModel;
  insuredZip?: Hl7FieldConfigurationViewModel;
  insuredCountryCode?: Hl7FieldConfigurationViewModel;
  policyEffectiveFrom?: Hl7FieldConfigurationViewModel;
  policyEffectiveTo?: Hl7FieldConfigurationViewModel;
  accidentStateCode?: Hl7FieldConfigurationViewModel;
  financialClass?: Hl7FieldConfigurationViewModel;
  hospitalAdmitDate?: Hl7FieldConfigurationViewModel;
  hospitalDischargeDate?: Hl7FieldConfigurationViewModel;
  illnessInjuryDate?: Hl7FieldConfigurationViewModel;
  procedure?: Hl7FieldConfigurationViewModel;
  fromDate?: Hl7FieldConfigurationViewModel;
  toDate?: Hl7FieldConfigurationViewModel;
  modifier1?: Hl7FieldConfigurationViewModel;
  modifier2?: Hl7FieldConfigurationViewModel;
  modifier3?: Hl7FieldConfigurationViewModel;
  modifier4?: Hl7FieldConfigurationViewModel;
  attendingProviderCode?: Hl7FieldConfigurationViewModel;
  attendingProviderFirstName?: Hl7FieldConfigurationViewModel;
  attendingProviderLastName?: Hl7FieldConfigurationViewModel;
  referringProviderCode?: Hl7FieldConfigurationViewModel;
  referringProviderFirstName?: Hl7FieldConfigurationViewModel;
  referringProviderLastName?: Hl7FieldConfigurationViewModel;
  location?: Hl7FieldConfigurationViewModel;
  placeOfServiceCode?: Hl7FieldConfigurationViewModel;
  quantity?: Hl7FieldConfigurationViewModel;
  diagnosisCode1?: Hl7FieldConfigurationViewModel;
  diagnosisCode2?: Hl7FieldConfigurationViewModel;
  diagnosisCode3?: Hl7FieldConfigurationViewModel;
  diagnosisCode4?: Hl7FieldConfigurationViewModel;
  diagnosisCode5?: Hl7FieldConfigurationViewModel;
  diagnosisCode6?: Hl7FieldConfigurationViewModel;
  diagnosisCode7?: Hl7FieldConfigurationViewModel;
  diagnosisCode8?: Hl7FieldConfigurationViewModel;
  diagnosisCode9?: Hl7FieldConfigurationViewModel;
  diagnosisCode10?: Hl7FieldConfigurationViewModel;
  diagnosisCode11?: Hl7FieldConfigurationViewModel;
  diagnosisCode12?: Hl7FieldConfigurationViewModel;
}
