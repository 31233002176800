<div class="reminder-form">
  <form [formGroup]="formGroup">
    <lib-form-field
      class="form-fields"
      [parent]="formGroup"
      label="Patient Search"
      name="patientId"
      type="autocomplete"
      [apiService]="patientLookupService"
      autoCompleteSkipInitial="true"
    ></lib-form-field>
    <lib-form-field
      class="form-fields"
      #userField
      [parent]="formGroup"
      label="Assigned To"
      name="assignedUserId"
      type="autocomplete"
      [apiService]="userLookupService"
    ></lib-form-field>

    <lib-form-field
      class="form-fields"
      [parent]="formGroup"
      label="Scheduled Date"
      name="scheduledDate"
      type="date"
    ></lib-form-field>
    <mat-form-field class="reminder-form__message-input" appearance="outline">
      <mat-label>Note</mat-label>
      <textarea matInput formControlName="message" placeholder="" id="note-field"></textarea>
    </mat-form-field>

    <div class="reminder-form__actions">
      <button mat-stroked-button type="button" (click)="cancel()" id="cancelButton">Cancel</button>
      <lib-button-loading
        color="primary"
        [loading]="saving"
        [invalid]="formGroup.invalid"
        (click)="save()"
        id="saveButton"
        >Create
      </lib-button-loading>
    </div>
  </form>
</div>
