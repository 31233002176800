import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LocationsComponent } from './locations.component';
import { LocationsClassRoutingModule } from './locations-routing.module';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { GridModule, SharedModule } from 'components';
import { LocationsDialogModule } from './locations-dialog/locations-dialog.module';
import { MatSlideToggle } from '@angular/material/slide-toggle';

@NgModule({
  declarations: [LocationsComponent],
  imports: [
    CommonModule,
    LocationsClassRoutingModule,
    MatButtonModule,
    MatIconModule,
    GridModule,
    SharedModule,
    LocationsDialogModule,
    MatSlideToggle
  ]
})
export class LocationsModule {}
