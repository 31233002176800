import { Validator, ValidatorFn, Validators } from '@angular/forms';
import { ColumnDefinition } from './column-definition';

/**
 * Object to be passed into our grid to configure rows and columns
 *
 * @export
 * @interface GridConfiguration
 */
export interface GridConfiguration {
  // Array of column configurations
  columnDefinitions: ColumnDefinition[];

  // Column Ids for being displayed. Keeping this separate from
  // columnDefinitions allows us to dynamically add or remove columns on the UI side
  displayColumns: string[];

  // To add the elevation class or not
  elevation?: boolean;

  //
  rowClickEvent?: (value: any) => void;

  rowExpandable?: boolean;
}
