/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PagedModelRequest } from './pagedModelRequest';
import { RuleCardTypeAlterationViewModel } from './ruleCardTypeAlterationViewModel';
import { PagedListMetaData } from './pagedListMetaData';

export interface RuleCardTypeAlterationViewModelPagedModelResponse {
  pagedRequest?: PagedModelRequest;
  data?: Array<RuleCardTypeAlterationViewModel> | null;
  metaData?: PagedListMetaData;
}
