/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserConfigurationViewModel } from './userConfigurationViewModel';
import { PermissionSimpleViewModel } from './permissionSimpleViewModel';
import { DatasetSimpleViewModel } from './datasetSimpleViewModel';
import { RoleViewModel } from './roleViewModel';

export interface UserDetailsViewModel {
  id?: string;
  authId?: string | null;
  email?: string | null;
  lastName?: string | null;
  firstName?: string | null;
  fullName?: string | null;
  userDatasets?: Array<DatasetSimpleViewModel> | null;
  userRoles?: Array<RoleViewModel> | null;
  userPermissions?: Array<PermissionSimpleViewModel> | null;
  userConfigurations?: Array<UserConfigurationViewModel> | null;
  allowedClients?: Array<string> | null;
}
