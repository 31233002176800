import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { SessionStorageService } from '@core/services/session-storage.service';
import { PaymentWrapperService } from '@core/services/service-wrappers/payment-wrapper.service';
import { environment } from '@environment/environment';
import { first } from 'rxjs/operators';
import { DataSet } from './data-set.interface';
import { Router } from '@angular/router';

const SESSION_STORAGE_IMAGINEPAY_ACTIVE = 'imaginepay-active';

@Component({
  selector: 'lib-data-set-selector',
  templateUrl: './data-set-selector.component.html',
  styleUrls: ['./data-set-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  /*
   * Most elements will be outside of component in modal
   * Turning off ViewEncapsulation to better handle styles
   */
  encapsulation: ViewEncapsulation.None
})
export class DataSetSelectorComponent {
  @Input() selectedDataSet: DataSet;
  @Input() dataSets: DataSet[];

  @Output() optionClicked: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    private service: PaymentWrapperService,
    private sessionStorageService: SessionStorageService,
    private router: Router
  ) {}

  /**
   * Selects what 'client' to view
   *
   * @param {*} data
   * @memberof DataSetSelectorComponent
   */
  dataSetSelected(data) {
    if (data.id !== this.selectedDataSet.id) {
      this.sessionStorageService.setItem('selected-data-set-id', data.id);
      this.optionClicked.emit(data.id);
      var ipActive = false;
      this.service.apiV1PaymentConfigPost()
      .pipe(first())
      .subscribe(
        result => {
          ipActive = result.imaginePayDataset != null ? true : false;
          this.sessionStorageService.setItem(SESSION_STORAGE_IMAGINEPAY_ACTIVE, ipActive);
          this.sessionStorageService.removeItem('IMAGINE_PAY_TOKEN');
          this.sessionStorageService.removeItem('IMAGINE_PAY_TOKEN_EXP');
          environment.imaginePayConfig.authUserName = result.imaginePayDataset;
          environment.imaginePayConfig.authClientId = result.clientId;
          environment.imaginePayConfig.authPassword = result.password;
          if (this.router.url.endsWith('/dashboard')) {
            window.location.reload();
          }
          else {
            window.location.href = "/dashboard";
          }
        },
        err => {
          this.sessionStorageService.setItem(SESSION_STORAGE_IMAGINEPAY_ACTIVE, ipActive);
          if (this.router.url.endsWith('/dashboard')) {
            window.location.reload();
          }
          else {
            window.location.href = "/dashboard";
          }
        }
      );
    }
  }

  trackById(index: number, item: any) {
    return item.id
  }
}
