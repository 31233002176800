import { createSelector, createFeatureSelector } from '@ngrx/store';
import { ImplementationState } from './implementation.state';

export const FEATURE_NAME = 'implementation';
export const selectImplementationState = createFeatureSelector<ImplementationState>(FEATURE_NAME);

export const selectImplementation = createSelector(selectImplementationState, (state: ImplementationState) => state?.implementation);
export const selectImplementationDetails = createSelector(selectImplementationState, (state: ImplementationState) => state?.implementation?.details);
export const selectImpelementationStatus = createSelector(selectImplementationState, (state: ImplementationState) => state?.implementation?.status);
export const selectImplementationCurrentStep = createSelector(selectImplementationState, (state: ImplementationState) => state?.currentStepCode);
