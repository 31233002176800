/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ReportParameter } from './reportParameter';

export interface ReportViewModel {
  id?: string;
  slug?: string | null;
  name?: string | null;
  parameters?: Array<ReportParameter> | null;
}
