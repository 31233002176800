/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RuleCardAlterationViewModel } from './ruleCardAlterationViewModel';
import { RuleCardSubtypeViewModel } from './ruleCardSubtypeViewModel';
import { RuleCardTypeViewModel } from './ruleCardTypeViewModel';

export interface RuleCardTypeAlterationDetailsViewModel {
  id?: string;
  rowId?: number;
  ruleCardTypeId?: string;
  ruleCardSubtypeId?: string;
  ruleCardAlterationId?: string;
  ruleCardResultId?: string | null;
  ruleCardType?: RuleCardTypeViewModel;
  ruleCardSubtype?: RuleCardSubtypeViewModel;
  ruleCardAlteration?: RuleCardAlterationViewModel;
}
