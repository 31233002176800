/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EobHistoryViewModel } from './eobHistoryViewModel';

export interface BatchReportDetailsViewModel {
  arTransactionHoldingId?: string;
  arTransactionDetailHoldingId?: string;
  allowedAmount?: number;
  paymentAmount?: number;
  adjustmentAmount?: number;
  adjustment2Amount?: number;
  deductibleAmount?: number;
  copayAmount?: number;
  coinsuranceAmount?: number;
  procedureAmount?: number;
  fromDate?: string;
  procedureCode?: string | null;
  procedureName?: string | null;
  balance?: number;
  eobCodes?: Array<EobHistoryViewModel> | null;
}
