/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface PatientLedgerRequestModel {
  patientId?: string;
  notes?: boolean;
  providerSummary?: boolean;
  eobRemarks?: boolean;
  visitSummary?: boolean;
  insuranceInfo?: boolean;
  heldVisits?: boolean;
  visitStart?: number;
  visitEnd?: number;
  procedurePostStart?: string | null;
  procedurePostEnd?: string | null;
  procedureServiceStart?: string | null;
  procedureServiceEnd?: string | null;
  paymentPostStart?: string | null;
  paymentPostEnd?: string | null;
  readonly activityFilter?: boolean;
}
