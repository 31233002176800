import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { WindowToken } from '../injection-tokens/window-token';

export const NO_INTERNET_CONNECTION = 'No Internet Connection';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {
  constructor(@Inject(WindowToken) private window: Window) {}

  getClientErrorMessage(error: Error): string {
    return error.message ? error.message : error.toString();
  }

  getServerErrorMessage(error: HttpErrorResponse): string {
    return this.window.navigator.onLine ? error.message : NO_INTERNET_CONNECTION;
  }
}
