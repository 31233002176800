/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface HL7FileConfigurationSummaryModel {
  datasetId?: string | null;
  sendingFacilityId?: string | null;
  hl7FileConfigurationId?: string | null;
  sendingFacilityName?: string | null;
  createdBy?: string | null;
  hasDemos?: boolean;
  hasCharges?: boolean;
  fileType?: string | null;
  createdDate?: string;
  updatedDate?: string | null;
}
