<div class="scheduled-jobs__close">
  <a mat-dialog-close>
    <mat-icon>close</mat-icon>
  </a>
</div>
<header mat-dialog-title class="scheduled-jobs__title">
  <h2>Schedule Job</h2>
  <h5>Select criteria for job</h5>
</header>

<ng-container [formGroup]="formGroup">
  <main mat-dialog-content class="scheduled-jobs__form">
    <lib-form-field
      class="scheduled-jobs__form-control-med"
      [parent]="formGroup"
      label="Job"
      name="jobId"
      #jobName
      type="autocomplete"
      [apiService]="jobLookupService"
    >
    </lib-form-field>
    <lib-form-field
      class="scheduled-jobs__form-control-narrow"
      [parent]="formGroup"
      label="Status"
      name="status"
      type="select"
      [options]="scheduledJobStatuses"
    >
    </lib-form-field>
    <lib-form-field
      class="scheduled-jobs__form-control-med"
      [parent]="formGroup"
      name="nextExecution"
      label="Date field"
      type="date"
      [min]="minDate"
    >
    </lib-form-field>
    <lib-frequency-input
      class="scheduled-jobs__form-control-narrow"
      [frequencyInput]="frequencyInputObj"
      (frequencyChanged)="frequencyChanged($event)"
    >
    </lib-frequency-input>
    <input type="hidden" formControlName="executionHour" />
    <input type="hidden" formControlName="executionMinute" />
    <input type="hidden" formControlName="executionMeridiem" />
    <input type="hidden" formControlName="frequencyType" />
    <input type="hidden" formControlName="frequencyInterval" />
    <input type="hidden" formControlName="weekDays" />
    <input type="hidden" formControlName="monthDays" />
    <input type="hidden" formControlName="executionTimeZone" />
    <lib-form-field
      class="scheduled-jobs__form-control-narrow"
      [parent]="formGroup"
      label="User"
      name="scheduledForId"
      type="select"
      [apiService]="userLookupService"
    >
    </lib-form-field>
    <lib-form-field
      class="scheduled-jobs__form-control-wide"
      [parent]="formGroup"
      label="Description"
      name="description"
      type="text"
    >
    </lib-form-field>
  </main>
  <div mat-dialog-actions class="scheduled-jobs__actions">
    <button class="cancel-button" mat-stroked-button mat-dialog-close>Cancel</button>
    <lib-button-loading
      color="primary"
      class="save-button"
      [loading]="saving"
      [invalid]="formGroup.invalid"
      (clickEvent)="save()"
      >Schedule
    </lib-button-loading>
  </div>
</ng-container>
