import { Pipe, PipeTransform } from '@angular/core';
import { FieldSelectOption } from '../../form';

@Pipe({
  name: 'locationDisplay'
})
export class LocationDisplayPipe implements PipeTransform {
  transform(locationId: string, locations: FieldSelectOption[], emptyValue = '--'): unknown {
    return locations?.find((location) => location.value === locationId)?.label || emptyValue;
  }
}
