/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RuleCardTypeViewModel } from './ruleCardTypeViewModel';
import { RuleCardExpressionViewModel } from './ruleCardExpressionViewModel';

export interface RuleCardSubtypeDetailsViewModel {
  id?: string;
  rowId?: number;
  ruleCardTypeId?: string;
  name?: string | null;
  code?: string | null;
  ruleCardType?: RuleCardTypeViewModel;
  ruleCardExpressions?: Array<RuleCardExpressionViewModel> | null;
}
