import { Injector, LOCALE_ID } from '@angular/core';
import { Validators } from '@angular/forms';
import { ColumnSizes, FieldSelectOption, FilterType } from 'components';
import { DateDisplayPipe } from 'projects/components/src/lib/shared/pipes/date-display/date-display.pipe';
import { Report } from '../../models/report';
import { UserLookupService } from '../../../../core/services/lookup/user-lookup.service';
import { BatchSummaryService } from './batch-summary.service';

export const BatchSummary: Report = {
  id: 'batch-summary',
  label: 'Batch Summary',
  textCriteria: { 'Start Date': '', 'End Date': '', 'Users': '' },
  cleanFilterParams: {
    'Users': ''
  },

  getReportTitle: () => {
    return BatchSummary.label + ' ' + BatchSummary.textCriteria['Start Date'] + ' - ' + BatchSummary.textCriteria['End Date'];
  },
  getSearchCriteria: (injector: Injector) => [
    {
      stackedTitle: true,
      title: 'No users selected = all users selected',
      controls: [
        {
          label: 'Date Range',
          name: 'dateRange',
          type: 'dateRange',
          class: 'form-span-6',
          daterangeStartChanged: (event) => {
            BatchSummary.textCriteria['Start Date'] = event.value.toLocaleDateString();
          },
          daterangeEndChanged: (event) => {
            BatchSummary.textCriteria['End Date'] = event.value.toLocaleDateString();
          }
        },
        {
          label: '',
          name: 'dateRangeStart',
          type: 'noInput',
          class: 'display-none',
          validators: Validators.required
        },
        {
          label: '',
          name: 'dateRangeEnd',
          type: 'noInput',
          class: 'display-none',
          validators: Validators.required
        },
        {
          matLabel: 'Users',
          name: 'userIds',
          apiService: injector.get(UserLookupService),
          class: 'form-span-6 multi-input',
          type: 'multiSelect',
          placeholder: 'Users',
          selectOptions: (event: []) => {
            let listValue = []
            let listText = []
            event.forEach((x: FieldSelectOption) => {
              listValue.push(x?.value)
              listText.push(x.label)
            })
            BatchSummary.textCriteria['Users'] = listText.join(',');
            BatchSummary.cleanFilterParams['userIds'] = listValue
          },
        },
        {
          label: 'Exclude Refunds',
          name: 'excludeRefunds',
          class: 'form-span-6',
          type: 'toggle',
          initial: false,
          toggleChanged: (event) => {
            BatchSummary.textCriteria['Exclude Refunds'] = event.checked === true ? 'Yes' : 'No'
          }
        },
      ]
    }
  ],
  getGridConfiguration: (injector: Injector) => {
    const locale = injector.get(LOCALE_ID);
    const moneyFormat = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });
    return {
      service: injector.get<any>(BatchSummaryService),
      columnDefinitions: [
        {
          id: 'Batch',
          displayKey: 'Batch',
          headerText: 'Batch',
          displayOrder: 0,
          columnSize: ColumnSizes.narrow,
          sortable: true,
          filterable: true
        },
        {
          id: 'Posting Date',
          displayKey: 'Posting Date',
          headerText: 'Posting Date',
          displayOrder: 1,
          columnSize: ColumnSizes.normal,
          sortable: true,
          filterable: true
        },
        {
          id: 'User ID',
          displayKey: 'User ID',
          headerText: 'User Name',
          displayOrder: 2,
          columnSize: ColumnSizes.normal,
          sortable: true,
          filterable: true
        },
        {
          id: 'Payment Quantity',
          displayKey: 'Payment Quantity',
          headerText: 'Payment Quantity',
          displayOrder: 5,
          columnSize: ColumnSizes.narrow,
          sortable: true,
          filterable: true
        },
        {
          id: 'Payments',
          displayKey: 'Payments',
          headerText: 'Payments',
          displayOrder: 6,
          columnSize: ColumnSizes.narrow,
          sortable: true,
          filterable: true,
          formatter: moneyFormat.format
        },
        {
          id: 'Adjustment Quantity',
          displayKey: 'Adjustment Quantity',
          headerText: 'Adjustment Quantity',
          displayOrder: 7,
          columnSize: ColumnSizes.narrow,
          sortable: true,
          filterable: true
        },
        {
          id: 'Adjustments',
          displayKey: 'Adjustments',
          headerText: 'Adjustments',
          displayOrder: 8,
          columnSize: ColumnSizes.narrow,
          sortable: true,
          filterable: true,
          formatter: moneyFormat.format
        },
        {
          id: 'Total',
          displayKey: 'Total',
          headerText: 'Total',
          displayOrder: 9,
          columnSize: ColumnSizes.narrow,
          sortable: true,
          filterable: true,
          formatter: moneyFormat.format
        }
      ]
    };
  }
};

