import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'lib-eob-history',
  templateUrl: './eob-history.component.html',
  styleUrls: ['./eob-history.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EobHistoryComponent implements OnInit {
  @Input()
  visit: any;


  constructor() {}

  ngOnInit(): void { }

  trackById(index: number, item: any) {
    return item.id
  }
}
