import { Action, createReducer, on } from '@ngrx/store';

import * as ImplementationActions from './implementation.actions';
import { ImplementationState, initialImplementationState } from './implementation.state';

const reducer = createReducer(
    initialImplementationState,
  on(ImplementationActions.loadImplementationDetails, (state, action) => {
    return { ...state, implementation: null, loaded: false, loading: true };
  }),
  on(ImplementationActions.loadImplementationSuccess, (state, action) => {
    return { ...state, implementation: action.implementation, loaded: true, loading: false };
  }),
  on(ImplementationActions.loadImplementationFailure, (state, action) => {
    return { ...state, account: null, loaded: false, loading: false };
  }),
  on(ImplementationActions.updateImplementationStep, (state, action) => {
    return { ...state, currentStepCode: action.stepCode };
  })
);

export function implementationReducer(state: ImplementationState | undefined, action: Action): ImplementationState {
  return reducer(state, action);
}
