/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RuleCardSubtypeViewModel } from './ruleCardSubtypeViewModel';
import { RuleCardExpressionViewModel } from './ruleCardExpressionViewModel';
import { RuleCardViewModel } from './ruleCardViewModel';

export interface RuleCardTypeDetailsViewModel {
  id?: string;
  rowId?: number;
  name?: string | null;
  isDeleted?: boolean;
  ruleCardSubtypes?: Array<RuleCardSubtypeViewModel> | null;
  ruleCardExpressions?: Array<RuleCardExpressionViewModel> | null;
  ruleCards?: Array<RuleCardViewModel> | null;
}
