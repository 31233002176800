import { FilterType } from './filter-type';
import { ComparisonType } from './comparison-type';
import { FilterDropDownOption } from './filter-type-dropdown';

export interface FilterConfiguration {
  filterKey?: string;
  filterType: FilterType;
  comparisonType?: ComparisonType;
  dropDownFilterOptions?: FilterDropDownOption[];
  autocompleteAPI?: any;
  initial?: any;
}
