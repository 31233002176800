/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export type ListSortDirection = 0 | 1;

export const ListSortDirection = {
  NUMBER_0: 0 as ListSortDirection,
  NUMBER_1: 1 as ListSortDirection
};
