/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EligibilityBenefitsResponseViewModel } from './eligibilityBenefitsResponseViewModel';
import { EligibilityReferenceResponseViewModel } from './eligibilityReferenceResponseViewModel';
import { EligbilityValidationResponseViewModel } from './eligbilityValidationResponseViewModel';
import { EligibilityReceiverResponseViewModel } from './eligibilityReceiverResponseViewModel';
import { EligibilityTransactionTraceResponseViewModel } from './eligibilityTransactionTraceResponseViewModel';
import { EligibilityTimePeriodResponseViewModel } from './eligibilityTimePeriodResponseViewModel';
import { EligibilityDependentResponseViewModel } from './eligibilityDependentResponseViewModel';

export interface EligibilitySubscriberResponseViewModel {
  id?: string;
  datasetId?: string;
  eligibilityResponseFileId?: string;
  eligibilitySourceResponseId?: string;
  eligibilityReceiverResponseId?: string;
  subscriberPrefix?: string | null;
  subscriberLastOrgName?: string | null;
  subscriberFirstName?: string | null;
  subscriberMiddleName?: string | null;
  subscriberSuffix?: string | null;
  subscriberIdentificationQualifierCode?: string | null;
  subscriberIdentificationCode?: string | null;
  subscriberAddressLine1?: string | null;
  subscriberAddressLine2?: string | null;
  subscriberCity?: string | null;
  subscriberState?: string | null;
  subscriberPostalCode?: string | null;
  subscriberDateOfBirth?: string | null;
  subscriberGenderCode?: string | null;
  otherProviderTypeCode?: string | null;
  otherProviderIdentifierQualifierCode?: string | null;
  otherProviderIdentifier?: string | null;
  militaryInformationStatusCode?: string | null;
  militaryEmploymentStatusCode?: string | null;
  militaryServiceAffiliationCode?: string | null;
  militaryDescription?: string | null;
  militaryDateTimePeriodQualifier?: string | null;
  militaryDateTimeStart?: string;
  militaryDateTimeEnd?: string;
  insuredResponseCode?: string | null;
  insuredRelationshipCode?: string | null;
  insuredMaintenanceTypeCode?: string | null;
  insuredMaintenanceReasonCode?: string | null;
  patientAccountNumber?: string | null;
  eligibilityRequestDate?: string;
  sequenceNumber?: string | null;
  insuranceId?: string | null;
  validations?: Array<EligbilityValidationResponseViewModel> | null;
  eligibilityInformation?: Array<EligibilityBenefitsResponseViewModel> | null;
  timePeriods?: Array<EligibilityTimePeriodResponseViewModel> | null;
  traceResponses?: Array<EligibilityTransactionTraceResponseViewModel> | null;
  references?: Array<EligibilityReferenceResponseViewModel> | null;
  eligibilityDependentResponse?: EligibilityDependentResponseViewModel;
  eligibilityReceiverResponse?: EligibilityReceiverResponseViewModel;
}
