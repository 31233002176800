import { createFeatureSelector, createSelector } from '@ngrx/store';

import { AppState } from '../../core.state';
import { AuthState } from './auth.state';

export const selectAuthState = createFeatureSelector<AuthState>('authData');
export const selectAuth = createSelector(selectAuthState, (state: AuthState) => state);
export const selectIsAuthenticated = createSelector(selectAuthState, (state: AuthState) => state.authenticated);
export const selectUsersName = createSelector(
  selectAuthState,
  (state: AuthState) => `${state.firstName || ''} ${state.lastName || ''}`
);
export const selectUsersPicture = createSelector(selectAuthState, (state: AuthState) => state.picture);
