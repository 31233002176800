<lib-page-layout #pageLayout class="page-layout">
  <ng-container sideBar>
    <lib-sidebar
      [mainNavConfig]="mainNavConfig"
      [userPicture]="userPicture | async"
      [selectedDataSet]="selectedDataSet$ | async"
      [availableDataSets]="dataSets$ | async"
      (logout)="logOut()"
      (passwordUpdate)="updatePassword()"
      (dataSetSelected)="newClientSelected($event)"
      [implementationComplete]="implementationViewModel === null || implementationViewModel?.status === 'Complete'"
    >
    </lib-sidebar>
  </ng-container>
  <lib-header
    (document)="documentClicked()"
    (openNotifications)="openSideBarRight('Reminders')"
    (openSearch)="openSideBarRight('Global Search')"
    [notificationCount]="notificationCount$ | async"
    [notificationsActive]="implementationViewModel === null || implementationViewModel?.status === 'Complete'"
  ></lib-header>
  <main class="page-layout__main">
    <div [@routeAnimations]="o.isActivated ? o.activatedRoute : ''" class="page-layout__main-content">
      <router-outlet #o="outlet"></router-outlet>
    </div>
  </main>

  <ng-container sideBarRight>
    <div class="page-layout__reminders">
      <div class="page-layout__reminders-header">
        <div class="h5 h5--semi-bold">{{ sidenavType }}</div>
        <button mat-icon-button (click)="closeSideBarRight()" style="float: right">
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <app-reminders class="page-layout__reminder-body" *ngIf="sidenavType === 'Reminders'"></app-reminders>
      <app-global-search
        class="page-layout__reminder-body"
        *ngIf="sidenavType === 'Global Search'"
        (cancel)="closeSideBarRight()"
      ></app-global-search>
    </div>
  </ng-container>
</lib-page-layout>
