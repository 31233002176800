import { Injectable } from '@angular/core';
import {
  FeeScheduleDetailsViewModel,
  FeeScheduleEntryService,
  FeeScheduleEntryViewModel,
  FeeScheduleService,
  FeeScheduleViewModel,
  FeeScheduleViewModelPagedModelResponse,
  PagedModelRequest,
  PopulateFeeScheduleViewModel
} from 'data';
import { Observable } from 'rxjs';
import { BaseWrapperService } from './base-wrapper.service';
import { SessionStorageService } from '../session-storage.service';

@Injectable({
  providedIn: 'root'
})
export class FeeScheduleWrapperService extends BaseWrapperService {
  constructor(
    private feeService: FeeScheduleService,
    private feeEntryService: FeeScheduleEntryService,
    protected sessionStorageService: SessionStorageService
  ) {
    super();
  }

  apiV1FeeScheduleAllPost(pagedModelRequest?: PagedModelRequest): Observable<FeeScheduleViewModelPagedModelResponse> {
    return this.feeService.apiV1FeeScheduleAllPost(this.getDataSetId(), pagedModelRequest);
  }

  apiV1FeeScheduleAllDetailPost(
    pagedModelRequest?: PagedModelRequest
  ): Observable<FeeScheduleViewModelPagedModelResponse> {
    return this.feeService.apiV1FeeScheduleAllDetailPost(this.getDataSetId(), pagedModelRequest);
  }

  apiV1FeeScheduleIdGet(id: string): Observable<FeeScheduleViewModel> {
    return this.feeService.apiV1FeeScheduleIdGet(id, this.getDataSetId());
  }
  apiV1FeeScheduleDetailsIdGet(id: string): Observable<FeeScheduleViewModel> {
    return this.feeService.apiV1FeeScheduleDetailsIdGet(id, this.getDataSetId());
  }

  apiV1FeeScheduleAddPost(requestBody): Observable<FeeScheduleViewModel> {
    return this.feeService.apiV1FeeScheduleAddPost(this.getDataSetId(), requestBody);
  }

  apiV1FeeSchedulePatchIdPatch(id: string, operation): Observable<FeeScheduleDetailsViewModel> {
    return this.feeService.apiV1FeeSchedulePatchIdPatch(id, this.getDataSetId(), operation);
  }

  apiV1FeeScheduleIdDelete(id: string): Observable<FeeScheduleViewModel> {
    return this.feeService.apiV1FeeScheduleIdDelete(id, this.getDataSetId());
  }

  apiV1FeeScheduleEntryAddPost(requestBody): Observable<FeeScheduleViewModel> {
    return this.feeEntryService.apiV1FeeScheduleEntryAddPost(this.getDataSetId(), requestBody);
  }

  apiV1FeeScheduleEntryPatchIdPatch(id: string, operation): Observable<FeeScheduleEntryViewModel> {
    return this.feeEntryService.apiV1FeeScheduleEntryPatchIdPatch(id, this.getDataSetId(), operation);
  }
  apiV1FeeScheduleEntryDetailsIdGet(id: string): Observable<FeeScheduleViewModel> {
    return this.feeEntryService.apiV1FeeScheduleEntryDetailsIdGet(id, this.getDataSetId());
  }

  apiV1FeeSchedulePopulatePost(populateFeeSchedule: PopulateFeeScheduleViewModel): Observable<FeeScheduleViewModel> {
    return this.feeService.apiV1FeeSchedulePopulatePost(this.getDataSetId(), populateFeeSchedule);
  }

  apiV1FeeScheduleEntryIdDelete(id: string): Observable<FeeScheduleViewModel> {
    return this.feeEntryService.apiV1FeeScheduleEntryIdDelete(id, this.getDataSetId());
  }
}
