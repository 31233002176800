/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export type NdcUnit = 0 | 1 | 2 | 3 | 4;

export const NdcUnit = {
  NUMBER_0: 0 as NdcUnit,
  NUMBER_1: 1 as NdcUnit,
  NUMBER_2: 2 as NdcUnit,
  NUMBER_3: 3 as NdcUnit,
  NUMBER_4: 4 as NdcUnit
};
