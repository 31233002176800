import { Inject, Injectable } from '@angular/core';
import { WindowToken } from '../injection-tokens/window-token';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  constructor(@Inject(WindowToken) private window: Window) {}

  /**
   * Sets local storage
   *
   * @param {string} key
   * @param {*} value
   * @memberof LocalStorageService
   */
  setItem(key: string, value: any) {
    this.window.localStorage.setItem(key, JSON.stringify(value));
  }

  /**
   * Retrieves item from local storage
   *
   * @param {string} key
   * @returns
   * @memberof LocalStorageService
   */
  getItem(key: string) {
    return JSON.parse(localStorage.getItem(key));
  }

  /**
   * Removes item from local storage
   *
   * @param {string} key
   * @memberof LocalStorageService
   */
  removeItem(key: string) {
    this.window.localStorage.removeItem(key);
  }

  /** Tests that localStorage exists, can be written to, and read from. */
  testLocalStorage() {
    const testValue = 'testValue';
    const testKey = 'testKey';
    let retrievedValue: string;
    const errorMessage = 'localStorage did not return expected value';

    this.setItem(testKey, testValue);
    retrievedValue = this.getItem(testKey);
    this.removeItem(testKey);

    if (retrievedValue !== testValue) {
      throw new Error(errorMessage);
    }
  }
}
