/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface OtherSearchResultViewModel {
  id?: string;
  datasetId?: string;
  entityType?: string | null;
  code?: string | null;
  name?: string | null;
  number?: string | null;
  address1?: string | null;
  city?: string | null;
  codeMatch?: boolean;
  nameMatch?: boolean;
  numberMatch?: boolean;
  addressMatch?: boolean;
  readonly score?: number;
}
