/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Token request view model containing client id and secret.  Properties duplicated to support  slightly different property name standards
 */
export interface TokenRequestViewModel {
  clientId?: string | null;
  client_id?: string | null;
  clientSecret?: string | null;
  client_secret?: string | null;
}
