import { createReducer, on, Action } from '@ngrx/store';
import { loadDataSetsSuccess, selectDataSet } from './data-set.actions';
import { DataSetInitialState, DataSetAdapter, DataSetState } from './data-set.state';

const reducer = createReducer(
  DataSetInitialState,
  on(selectDataSet, (state, { dataSetId }) => ({
    ...state,
    selectedDataSetId: dataSetId
  })),
  on(loadDataSetsSuccess, (state, { dataSets }) => {
    const activeDataSets = [];
    dataSets.forEach((set) => {
      if (!set.inactive) {
        activeDataSets.push(set);
      }
    })
    return DataSetAdapter.upsertMany(activeDataSets, { ...state });
  })
);

export function DataSetReducer(state: DataSetState | undefined, action: Action): DataSetState {
  return reducer(state, action);
}
