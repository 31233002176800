import { NgControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export class BaseFormatterDirective {
  public destroy$ = new Subject();

  decimalCount: number;

  constructor(private baseNgControl: NgControl) {}

  updateValue(value) {
    const inputVal = value !== undefined && value !== null ? value : '';
    this.setValue(this.validateDecimalValue(this.unFormatValue(inputVal)));
  }

  baseOnFocus() {
    this.setValue(this.unFormatValue(this.baseNgControl.value));
  }

  baseOnBlur() {
    const value = this.baseNgControl.value || '';
    if (!!value) {
      this.setValue(this.formatInput(value));
    }
  }

  formatInput(v) {}

  unFormatValue(v) {
    return v?.toString()?.replace(/,/g, '')?.replace(/\$/g, '');
  }

  validateDecimalValue(v) {
    if (Number.isNaN(Number(v))) {
      const strippedValue = v.slice(0, v.length - 1);
      return Number.isNaN(Number(strippedValue)) ? '' : strippedValue;
    }
    return v;
  }

  setValue(v) {
    this.baseNgControl.control.setValue(v, { emitEvent: false });
  }

  onDestroy() {
    this.setValue(this.unFormatValue(this.baseNgControl.value));
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
