<!-- <h2 mat-dialog-title>{{data.header}}</h2> -->
<div class="dialog-form__close">
  <a mat-dialog-close>
    <mat-icon>close</mat-icon>
  </a>
</div>
<mat-dialog-content class="mat-typography show-dialog-content">
  <p>
    {{ data.body }}
  </p>
  <p>
    {{ data.subBody }}
  </p>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button
    mat-stroked-button
    mat-dialog-close
    *ngIf="data.cancelButtonText"
    class="mat-button--large"
    (click)="cancel()"
  >
    {{ data.cancelButtonText }}
  </button>
  <lib-button-loading
    color="primary"
    [btnClass]="'mat-button--large mat-button--wide'"
    [loading]="saving"
    (clickEvent)="ok()"
    >{{ data.OKButtonText }}
  </lib-button-loading>
</mat-dialog-actions>
