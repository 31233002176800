import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderBy'
})
export class OrderByPipe implements PipeTransform {
  /**
   * Orders array of object by property. Can be used in component or template.
   *
   * @param {*} array
   * @param {string} field
   * @return {*}  {any[]}
   * @memberof OrderByPipe
   */
  transform(array: any, field: string, isAsc: boolean = true): any[] {
    if (Array.isArray(array)) {
      array.sort((a, b) => {
        return this.compare(a[field], b[field], isAsc);
      });
    }
    return array;
  }
  compare(a: number | string | Date, b: number | string | Date, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
}
