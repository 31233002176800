<div class="dialog-form report-form">
  <div class="dialog-form__close">
    <a mat-dialog-close>
      <mat-icon>close</mat-icon>
    </a>
  </div>
  <header mat-dialog-title class="dialog-form__title">
    <h2>EOB Code</h2>
  </header>
  <main mat-dialog-content>
    <div class="dialog-form__container">
      <ng-container *ngIf="formInitialized">
        <lib-dynamic-form [dynamicFormGroup]="formGroup" [formGroupDefinitions]="formDefinitions"> </lib-dynamic-form>
      </ng-container>
    </div>
  </main>
  <div *ngIf="duplicateCode" class="mat-error" id="duplicateCode">
    {{ duplicateResponse }}
  </div>
  <div mat-dialog-actions class="buttons">
    <button mat-stroked-button mat-dialog-close *ngIf="viewOnly">Close</button>
    <button class="cancel-button" mat-stroked-button mat-dialog-close *ngIf="!viewOnly">Cancel</button>
    <lib-button-loading
      *ngIf="!viewOnly"
      color="primary"
      class="save-button"
      [loading]="saving"
      [invalid]="formGroup.invalid || duplicateCode"
      (clickEvent)="save()"
    >
      Save
    </lib-button-loading>
  </div>
</div>
