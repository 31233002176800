/**
 * ImaginePay API
 * This API facilitates partner integration within the platform.
 *
 * The version of the OpenAPI document: 22_12_0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface ImaginePayApiModelsPaymentPlanTruncatePaymentPlan {
  truncateAmount: number;
  readonly reason?: string;
  readonly username: string;
}
