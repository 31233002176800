import { Component, Inject, Injector, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PlacesOfServiceCodesLookupService } from '@core/services/lookup';
import { PlaceOfServiceCrosswalkWrapperService } from '@core/services/service-wrappers/place-of-service-crosswalk-wrapper-service';
import { FormGroupDefinition, GridComponent } from 'components';
import { PlaceOfServiceCrosswalkViewModel } from 'projects/data/src/lib/model/placeOfServiceCrosswalkViewModel';
import { take } from 'rxjs/operators';
import { NotificationService } from 'src/app/core/services/notification.service';
import { UpdatePlaceOfServiceCrosswalkViewModel } from 'data';

@Component({
  selector: 'app-place-of-service-dialog',
  templateUrl: './place-of-service-dialog.component.html',
  styleUrls: ['./place-of-service-dialog.component.scss']
})
export class PlaceOfServiceDialogComponent implements OnInit {
  @ViewChild(GridComponent) grid: GridComponent;
  formGroup = new UntypedFormGroup({});
  formDefinition: FormGroupDefinition = { controls: [], title: ''};
  formDefinitions: FormGroupDefinition[] = [];
  formInitialized = false;
  saving = false;
  insuranceCarrierId;
  posDict = {};
  viewOnly = false;
  crosswalks: PlaceOfServiceCrosswalkViewModel[];
    constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private injector: Injector,
    private notificationService: NotificationService,
    public dialogRef: MatDialogRef<PlaceOfServiceDialogComponent>,
    private service: PlaceOfServiceCrosswalkWrapperService,
    private lookupService: PlacesOfServiceCodesLookupService
  ) {
    this.insuranceCarrierId = data?.insuranceCarrierId;
    this.viewOnly = data?.viewOnly;
  }

  ngOnInit(): void {
    this.setupForm();
  }

  setupForm() {
    this.service.apiV1PlaceOfServiceCrosswalkInsuranceCarrierInsurancecarrieridGet(this.insuranceCarrierId).subscribe((pos) => {
      pos.forEach((p) => 
      {
        //place of service dictionary guid => code
        this.posDict[p.originalPlaceOfServiceCodeId] = p.originalPlaceOfServiceCode;
        // add a control definition for a label with the original place of service
        this.formDefinition.controls.push({type: 'label', class: 'form-span-6', label: p.originalPlaceOfServiceCode + ' - ' + p.originalPlaceOfServiceName});
        // add a control definition for an autocomplete with the current crosswalk value (if any)
        this.formDefinition.controls.push({
            name: 'placeOfServiceCode' + p.originalPlaceOfServiceCode,
            initial: p.replacementPlaceOfServiceCodeId,
            apiService: this.lookupService,
            class: 'form-span-6',
            type: 'select',
            emptyOption: ' '
          })     
        //add the control to the underlying form group
        this.formGroup.addControl('placeOfServiceCode' + p.originalPlaceOfServiceCode, new UntypedFormControl(p.replacementPlaceOfServiceCodeId ?? ''));
      }
    );
    this.formDefinitions.push(this.formDefinition);
    this.formInitialized = true;
    });
  }

  save() {
    if (this.formGroup.valid) {
      this.saving = true;
      const formData = this.formGroup.value;
      
      let crosswalks: UpdatePlaceOfServiceCrosswalkViewModel[] = [];
      const vals = Object.values(formData);
      Object.keys(formData).forEach((key, i) => {
        const val = vals[i].toString();
        crosswalks.push({
            originalPlaceOfServiceCode: key.replace('placeOfServiceCode', ''), 
            replacementPlaceOfServiceCode: val === '' ? '' : this.posDict[val] 
        });
      });      
      
      this.service.apiV1PlaceOfServiceCrosswalkUpdateInsurancecarrieridPost(this.insuranceCarrierId, crosswalks)
      .pipe(take(1))
      .subscribe(() => {
        this.dialogRef.close(true);
        this.notificationService.success('Place of Service Crosswalks updated');
        this.saving = false;
      },
      (err) => {
        this.notificationService.error('Error updating Place of Service Crosswalks')
        this.dialogRef.close(true);
        this.saving = false;
      });
    }
  }
}
