/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface BatchViewModel {
  id?: string;
  datasetId?: string;
  batchType?: string | null;
  batchNumber?: number;
  checkNumber?: string | null;
  expectedAmount?: number | null;
  payorName?: string | null;
  batchStatus?: string | null;
  postingDate?: string | null;
  createdBy?: string | null;
  date?: string;
  transactionCount?: number;
  totalPaymentAmount?: number;
  totalAdjustmentAmount?: number;
  totalAdjustment2Amount?: number;
  totalDeductibleAmount?: number;
  totalCopayAmount?: number;
  totalCoinsuranceAmount?: number;
  paymentCount?: number;
  adjustmentCount?: number;
  defaultPaymentTypeId?: string | null;
  paymentType?: string | null;
  hasUnapplied?: boolean;
  batchNumberChar?: string | null;
}
