/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EligibilityBenefitsResponseViewModel } from './eligibilityBenefitsResponseViewModel';
import { EligibilityReferenceResponseViewModel } from './eligibilityReferenceResponseViewModel';
import { EligbilityValidationResponseViewModel } from './eligbilityValidationResponseViewModel';
import { EligibilityTransactionTraceResponseViewModel } from './eligibilityTransactionTraceResponseViewModel';
import { EligibilityTimePeriodResponseViewModel } from './eligibilityTimePeriodResponseViewModel';

export interface EligibilityDependentResponseViewModel {
  id?: string;
  datasetId?: string;
  eligibilityResponseFileId?: string;
  eligibilitySubscriberResponseId?: string;
  dependentPrefix?: string | null;
  dependentLastOrgName?: string | null;
  dependentFirstName?: string | null;
  dependentMiddleName?: string | null;
  dependentSuffix?: string | null;
  dependentAddressLine1?: string | null;
  dependentAddressLine2?: string | null;
  dependentCity?: string | null;
  dependentState?: string | null;
  dependentPostalCode?: string | null;
  dependentDateOfBirth?: string;
  dependentGenderCode?: string | null;
  otherProviderTypeCode?: string | null;
  otherProviderIdentifierQualifierCode?: string | null;
  otherProviderIdentifier?: string | null;
  militaryInformationStatusCode?: string | null;
  militaryEmploymentStatusCode?: string | null;
  militaryServiceAffiliationCode?: string | null;
  militaryDescription?: string | null;
  militaryDateTimePeriodQualifier?: string | null;
  militaryDateTimeStart?: string;
  militaryDateTimeEnd?: string;
  insuredResponseCode?: string | null;
  insuredRelationshipCode?: string | null;
  insuredMaintenanceTypeCode?: string | null;
  insuredMaintenanceReasonCode?: string | null;
  patientAccountNumber?: string | null;
  eligibilityRequestDate?: string;
  sequenceNumber?: string | null;
  insuranceId?: string | null;
  eligibilityInformation?: Array<EligibilityBenefitsResponseViewModel> | null;
  timePeriods?: Array<EligibilityTimePeriodResponseViewModel> | null;
  traceResponses?: Array<EligibilityTransactionTraceResponseViewModel> | null;
  references?: Array<EligibilityReferenceResponseViewModel> | null;
  validations?: Array<EligbilityValidationResponseViewModel> | null;
}
