/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { InsuranceCarrierSimpleViewModel } from './insuranceCarrierSimpleViewModel';

export interface InsuranceSimpleViewModel {
  id?: string;
  policyNumber?: string | null;
  insuredRelation?: string | null;
  insuredLastName?: string | null;
  insuredFirstName?: string | null;
  groupNumber?: string | null;
  policyEffectiveFrom?: string | null;
  policyEffectiveTo?: string | null;
  assignment?: string | null;
  insuranceCarrier?: InsuranceCarrierSimpleViewModel;
}
