<lib-card>
  <ng-container name="title">Procedure Totals</ng-container>
  <section class="procedure-totals">
    <div class="procedure-totals__legend">
      <div></div>
      <div class="procedure-totals__currency">Amount</div>
      <div class="procedure-totals__currency">Paid</div>
      <div class="procedure-totals__currency">Adjusted 1</div>
      <div class="procedure-totals__currency">Adjusted 2</div>
      <div class="procedure-totals__currency">Balance</div>
    </div>
    <div class="procedure-totals__totals">
      <div>Totals</div>
      <div class="procedure-totals__currency">{{ visit?.procedureAmount | currency }}</div>
      <div class="procedure-totals__currency">{{ visit?.paid | currency }}</div>
      <div class="procedure-totals__currency">{{ visit?.adjusted | currency }}</div>
      <div class="procedure-totals__currency">{{ visit?.adjusted2 | currency }}</div>
      <div class="procedure-totals__currency">{{ visit?.balance | currency }}</div>
    </div>
    <div
      class="procedure-totals__procedure"
      *ngFor="let procedure of visit?.procedureTransactions; let i = index; trackBy: trackById"
    >
      <div>Procedure {{ i + 1 }}</div>
      <div class="procedure-totals__currency">{{ procedure?.procedureAmount | currency }}</div>
      <div class="procedure-totals__currency">{{ procedure?.totalPaid | currency }}</div>
      <div class="procedure-totals__currency">
        {{ procedure?.totalAdjustedAmount | currency }}
      </div>
      <div class="procedure-totals__currency">
        {{ procedure?.totalAdjusted2Amount | currency }}
      </div>
      <div class="procedure-totals__currency">{{ procedure?.balance | currency }}</div>
    </div>
  </section>
</lib-card>
