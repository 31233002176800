/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent,
  HttpParameterCodec,
  HttpContext
} from '@angular/common/http';
import { CustomHttpParameterCodec } from '../encoder';
import { Observable } from 'rxjs';

// @ts-ignore
import { ARTransactionDetailsViewModel } from '../model/aRTransactionDetailsViewModel';
// @ts-ignore
import { ARTransactionDetailsViewModelPagedModelResponse } from '../model/aRTransactionDetailsViewModelPagedModelResponse';
// @ts-ignore
import { ARTransactionHoldingVisitSelectionModel } from '../model/aRTransactionHoldingVisitSelectionModel';
// @ts-ignore
import { ARTransactionPostedPatientViewModel } from '../model/aRTransactionPostedPatientViewModel';
// @ts-ignore
import { ARTransactionViewModel } from '../model/aRTransactionViewModel';
// @ts-ignore
import { ARTransactionViewModelPagedModelResponse } from '../model/aRTransactionViewModelPagedModelResponse';
// @ts-ignore
import { ARTransactionVisitUpdateModel } from '../model/aRTransactionVisitUpdateModel';
// @ts-ignore
import { AddARTransactionViewModel } from '../model/addARTransactionViewModel';
// @ts-ignore
import { NotFoundResult } from '../model/notFoundResult';
// @ts-ignore
import { Operation } from '../model/operation';
// @ts-ignore
import { PagedModelRequest } from '../model/pagedModelRequest';
// @ts-ignore
import { ProblemDetails } from '../model/problemDetails';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable({
  providedIn: 'root'
})
export class ARTransactionService {
  protected basePath = 'http://localhost';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  public encoder: HttpParameterCodec;

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }

  // @ts-ignore
  private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
    if (typeof value === 'object' && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }

  private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
    if (value == null) {
      return httpParams;
    }

    if (typeof value === 'object') {
      if (Array.isArray(value)) {
        (value as any[]).forEach((elem) => (httpParams = this.addToHttpParamsRecursive(httpParams, elem, key)));
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(key, (value as Date).toISOString().substr(0, 10));
        } else {
          throw Error('key may not be null if value is Date');
        }
      } else {
        Object.keys(value).forEach(
          (k) => (httpParams = this.addToHttpParamsRecursive(httpParams, value[k], key != null ? `${key}.${k}` : k))
        );
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error('key may not be null if value is not object or array');
    }
    return httpParams;
  }

  /**
   * Adds a new entity. (Auth required: Transactions: Update)
   * Adds a new entity
   * @param datasetId
   * @param addARTransactionViewModel
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV1ArtransactionAddPost(
    datasetId: string,
    addARTransactionViewModel?: AddARTransactionViewModel,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<ARTransactionDetailsViewModel>;
  public apiV1ArtransactionAddPost(
    datasetId: string,
    addARTransactionViewModel?: AddARTransactionViewModel,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpResponse<ARTransactionDetailsViewModel>>;
  public apiV1ArtransactionAddPost(
    datasetId: string,
    addARTransactionViewModel?: AddARTransactionViewModel,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpEvent<ARTransactionDetailsViewModel>>;
  public apiV1ArtransactionAddPost(
    datasetId: string,
    addARTransactionViewModel?: AddARTransactionViewModel,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<any> {
    if (datasetId === null || datasetId === undefined) {
      throw new Error('Required parameter datasetId was null or undefined when calling apiV1ArtransactionAddPost.');
    }

    let localVarHeaders = this.defaultHeaders;
    if (datasetId !== undefined && datasetId !== null) {
      localVarHeaders = localVarHeaders.set('DatasetId', String(datasetId));
    }

    let localVarCredential: string | undefined;
    // authentication (Bearer) required
    localVarCredential = this.configuration.lookupCredential('Bearer');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    return this.httpClient.post<ARTransactionDetailsViewModel>(
      `${this.configuration.basePath}/api/v1/artransaction/add`,
      addARTransactionViewModel,
      {
        context: localVarHttpContext,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Gets all entity records with detail. (Auth required: Transactions: View)
   * Gets all detailed entities with applied paging and filtering
   * @param datasetId
   * @param pagedModelRequest
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV1ArtransactionAllDetailPost(
    datasetId: string,
    pagedModelRequest?: PagedModelRequest,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<ARTransactionDetailsViewModelPagedModelResponse>;
  public apiV1ArtransactionAllDetailPost(
    datasetId: string,
    pagedModelRequest?: PagedModelRequest,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpResponse<ARTransactionDetailsViewModelPagedModelResponse>>;
  public apiV1ArtransactionAllDetailPost(
    datasetId: string,
    pagedModelRequest?: PagedModelRequest,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpEvent<ARTransactionDetailsViewModelPagedModelResponse>>;
  public apiV1ArtransactionAllDetailPost(
    datasetId: string,
    pagedModelRequest?: PagedModelRequest,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<any> {
    if (datasetId === null || datasetId === undefined) {
      throw new Error(
        'Required parameter datasetId was null or undefined when calling apiV1ArtransactionAllDetailPost.'
      );
    }

    let localVarHeaders = this.defaultHeaders;
    if (datasetId !== undefined && datasetId !== null) {
      localVarHeaders = localVarHeaders.set('DatasetId', String(datasetId));
    }

    let localVarCredential: string | undefined;
    // authentication (Bearer) required
    localVarCredential = this.configuration.lookupCredential('Bearer');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    return this.httpClient.post<ARTransactionDetailsViewModelPagedModelResponse>(
      `${this.configuration.basePath}/api/v1/artransaction/all-detail`,
      pagedModelRequest,
      {
        context: localVarHttpContext,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Gets all entity records. (Auth required: Transactions: View)
   * Gets all entities with applied paging and filtering
   * @param datasetId
   * @param pagedModelRequest
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV1ArtransactionAllPost(
    datasetId: string,
    pagedModelRequest?: PagedModelRequest,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<ARTransactionViewModelPagedModelResponse>;
  public apiV1ArtransactionAllPost(
    datasetId: string,
    pagedModelRequest?: PagedModelRequest,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpResponse<ARTransactionViewModelPagedModelResponse>>;
  public apiV1ArtransactionAllPost(
    datasetId: string,
    pagedModelRequest?: PagedModelRequest,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpEvent<ARTransactionViewModelPagedModelResponse>>;
  public apiV1ArtransactionAllPost(
    datasetId: string,
    pagedModelRequest?: PagedModelRequest,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<any> {
    if (datasetId === null || datasetId === undefined) {
      throw new Error('Required parameter datasetId was null or undefined when calling apiV1ArtransactionAllPost.');
    }

    let localVarHeaders = this.defaultHeaders;
    if (datasetId !== undefined && datasetId !== null) {
      localVarHeaders = localVarHeaders.set('DatasetId', String(datasetId));
    }

    let localVarCredential: string | undefined;
    // authentication (Bearer) required
    localVarCredential = this.configuration.lookupCredential('Bearer');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    return this.httpClient.post<ARTransactionViewModelPagedModelResponse>(
      `${this.configuration.basePath}/api/v1/artransaction/all`,
      pagedModelRequest,
      {
        context: localVarHttpContext,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Gets a specific entity by id with included related details. (Auth required: Transactions: View)
   * Example id: 12345678-ABCD-ABCD-ABCD-123456789012
   * @param id
   * @param datasetId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV1ArtransactionDetailsIdGet(
    id: string,
    datasetId: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<ARTransactionDetailsViewModel>;
  public apiV1ArtransactionDetailsIdGet(
    id: string,
    datasetId: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpResponse<ARTransactionDetailsViewModel>>;
  public apiV1ArtransactionDetailsIdGet(
    id: string,
    datasetId: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpEvent<ARTransactionDetailsViewModel>>;
  public apiV1ArtransactionDetailsIdGet(
    id: string,
    datasetId: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling apiV1ArtransactionDetailsIdGet.');
    }
    if (datasetId === null || datasetId === undefined) {
      throw new Error(
        'Required parameter datasetId was null or undefined when calling apiV1ArtransactionDetailsIdGet.'
      );
    }

    let localVarHeaders = this.defaultHeaders;
    if (datasetId !== undefined && datasetId !== null) {
      localVarHeaders = localVarHeaders.set('DatasetId', String(datasetId));
    }

    let localVarCredential: string | undefined;
    // authentication (Bearer) required
    localVarCredential = this.configuration.lookupCredential('Bearer');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    return this.httpClient.get<ARTransactionDetailsViewModel>(
      `${this.configuration.basePath}/api/v1/artransaction/details/${encodeURIComponent(String(id))}`,
      {
        context: localVarHttpContext,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Deletes a record. (Auth required: Transactions: Update)
   * Marks a record as deleted. (soft delete)
   * @param id
   * @param datasetId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV1ArtransactionIdDelete(
    id: string,
    datasetId: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<any>;
  public apiV1ArtransactionIdDelete(
    id: string,
    datasetId: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpResponse<any>>;
  public apiV1ArtransactionIdDelete(
    id: string,
    datasetId: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpEvent<any>>;
  public apiV1ArtransactionIdDelete(
    id: string,
    datasetId: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling apiV1ArtransactionIdDelete.');
    }
    if (datasetId === null || datasetId === undefined) {
      throw new Error('Required parameter datasetId was null or undefined when calling apiV1ArtransactionIdDelete.');
    }

    let localVarHeaders = this.defaultHeaders;
    if (datasetId !== undefined && datasetId !== null) {
      localVarHeaders = localVarHeaders.set('DatasetId', String(datasetId));
    }

    let localVarCredential: string | undefined;
    // authentication (Bearer) required
    localVarCredential = this.configuration.lookupCredential('Bearer');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    return this.httpClient.delete<any>(
      `${this.configuration.basePath}/api/v1/artransaction/${encodeURIComponent(String(id))}`,
      {
        context: localVarHttpContext,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Gets a specific entity by id. (Auth required: Transactions: View)
   * Example id: 12345678-ABCD-ABCD-ABCD-123456789012
   * @param id
   * @param datasetId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV1ArtransactionIdGet(
    id: string,
    datasetId: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<ARTransactionViewModel>;
  public apiV1ArtransactionIdGet(
    id: string,
    datasetId: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpResponse<ARTransactionViewModel>>;
  public apiV1ArtransactionIdGet(
    id: string,
    datasetId: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpEvent<ARTransactionViewModel>>;
  public apiV1ArtransactionIdGet(
    id: string,
    datasetId: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling apiV1ArtransactionIdGet.');
    }
    if (datasetId === null || datasetId === undefined) {
      throw new Error('Required parameter datasetId was null or undefined when calling apiV1ArtransactionIdGet.');
    }

    let localVarHeaders = this.defaultHeaders;
    if (datasetId !== undefined && datasetId !== null) {
      localVarHeaders = localVarHeaders.set('DatasetId', String(datasetId));
    }

    let localVarCredential: string | undefined;
    // authentication (Bearer) required
    localVarCredential = this.configuration.lookupCredential('Bearer');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    return this.httpClient.get<ARTransactionViewModel>(
      `${this.configuration.basePath}/api/v1/artransaction/${encodeURIComponent(String(id))}`,
      {
        context: localVarHttpContext,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Patches a record. (Auth required: Transactions: Update)
   * Updates a record using the PATCH method.
   * @param id
   * @param datasetId
   * @param operation
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV1ArtransactionPatchIdPatch(
    id: string,
    datasetId: string,
    operation?: Array<Operation>,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<ARTransactionDetailsViewModel>;
  public apiV1ArtransactionPatchIdPatch(
    id: string,
    datasetId: string,
    operation?: Array<Operation>,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpResponse<ARTransactionDetailsViewModel>>;
  public apiV1ArtransactionPatchIdPatch(
    id: string,
    datasetId: string,
    operation?: Array<Operation>,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpEvent<ARTransactionDetailsViewModel>>;
  public apiV1ArtransactionPatchIdPatch(
    id: string,
    datasetId: string,
    operation?: Array<Operation>,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling apiV1ArtransactionPatchIdPatch.');
    }
    if (datasetId === null || datasetId === undefined) {
      throw new Error(
        'Required parameter datasetId was null or undefined when calling apiV1ArtransactionPatchIdPatch.'
      );
    }

    let localVarHeaders = this.defaultHeaders;
    if (datasetId !== undefined && datasetId !== null) {
      localVarHeaders = localVarHeaders.set('DatasetId', String(datasetId));
    }

    let localVarCredential: string | undefined;
    // authentication (Bearer) required
    localVarCredential = this.configuration.lookupCredential('Bearer');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json-patch+json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    return this.httpClient.patch<ARTransactionDetailsViewModel>(
      `${this.configuration.basePath}/api/v1/artransaction/patch/${encodeURIComponent(String(id))}`,
      operation,
      {
        context: localVarHttpContext,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Gets a specific transaction with included details. (Auth required: Transactions: View)
   * Gets a specific transaction with included details
   * @param datasetId
   * @param aRTransactionHoldingVisitSelectionModel
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV1ArtransactionTransactionDetailPost(
    datasetId: string,
    aRTransactionHoldingVisitSelectionModel?: ARTransactionHoldingVisitSelectionModel,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<ARTransactionPostedPatientViewModel>;
  public apiV1ArtransactionTransactionDetailPost(
    datasetId: string,
    aRTransactionHoldingVisitSelectionModel?: ARTransactionHoldingVisitSelectionModel,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpResponse<ARTransactionPostedPatientViewModel>>;
  public apiV1ArtransactionTransactionDetailPost(
    datasetId: string,
    aRTransactionHoldingVisitSelectionModel?: ARTransactionHoldingVisitSelectionModel,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpEvent<ARTransactionPostedPatientViewModel>>;
  public apiV1ArtransactionTransactionDetailPost(
    datasetId: string,
    aRTransactionHoldingVisitSelectionModel?: ARTransactionHoldingVisitSelectionModel,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<any> {
    if (datasetId === null || datasetId === undefined) {
      throw new Error(
        'Required parameter datasetId was null or undefined when calling apiV1ArtransactionTransactionDetailPost.'
      );
    }

    let localVarHeaders = this.defaultHeaders;
    if (datasetId !== undefined && datasetId !== null) {
      localVarHeaders = localVarHeaders.set('DatasetId', String(datasetId));
    }

    let localVarCredential: string | undefined;
    // authentication (Bearer) required
    localVarCredential = this.configuration.lookupCredential('Bearer');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    return this.httpClient.post<ARTransactionPostedPatientViewModel>(
      `${this.configuration.basePath}/api/v1/artransaction/transaction-detail`,
      aRTransactionHoldingVisitSelectionModel,
      {
        context: localVarHttpContext,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Unapplies a transaction by id. (Auth required: Transactions: View)
   * Unapplies a transaction by id.
   * @param id
   * @param datasetId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV1ArtransactionUnapplyIdPost(
    id: string,
    datasetId: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<any>;
  public apiV1ArtransactionUnapplyIdPost(
    id: string,
    datasetId: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpResponse<any>>;
  public apiV1ArtransactionUnapplyIdPost(
    id: string,
    datasetId: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpEvent<any>>;
  public apiV1ArtransactionUnapplyIdPost(
    id: string,
    datasetId: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling apiV1ArtransactionUnapplyIdPost.');
    }
    if (datasetId === null || datasetId === undefined) {
      throw new Error(
        'Required parameter datasetId was null or undefined when calling apiV1ArtransactionUnapplyIdPost.'
      );
    }

    let localVarHeaders = this.defaultHeaders;
    if (datasetId !== undefined && datasetId !== null) {
      localVarHeaders = localVarHeaders.set('DatasetId', String(datasetId));
    }

    let localVarCredential: string | undefined;
    // authentication (Bearer) required
    localVarCredential = this.configuration.lookupCredential('Bearer');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    return this.httpClient.post<any>(
      `${this.configuration.basePath}/api/v1/artransaction/unapply/${encodeURIComponent(String(id))}`,
      null,
      {
        context: localVarHttpContext,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Update insurance for an existing transaction. (Auth required: Transactions: Update)
   * Update insurance for an existing transaction.
   * @param datasetId
   * @param id
   * @param insuranceId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV1ArtransactionUpdateInsurancePost(
    datasetId: string,
    id?: string,
    insuranceId?: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<any>;
  public apiV1ArtransactionUpdateInsurancePost(
    datasetId: string,
    id?: string,
    insuranceId?: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpResponse<any>>;
  public apiV1ArtransactionUpdateInsurancePost(
    datasetId: string,
    id?: string,
    insuranceId?: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpEvent<any>>;
  public apiV1ArtransactionUpdateInsurancePost(
    datasetId: string,
    id?: string,
    insuranceId?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<any> {
    if (datasetId === null || datasetId === undefined) {
      throw new Error(
        'Required parameter datasetId was null or undefined when calling apiV1ArtransactionUpdateInsurancePost.'
      );
    }

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (id !== undefined && id !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>id, 'id');
    }
    if (insuranceId !== undefined && insuranceId !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>insuranceId, 'insuranceId');
    }

    let localVarHeaders = this.defaultHeaders;
    if (datasetId !== undefined && datasetId !== null) {
      localVarHeaders = localVarHeaders.set('DatasetId', String(datasetId));
    }

    let localVarCredential: string | undefined;
    // authentication (Bearer) required
    localVarCredential = this.configuration.lookupCredential('Bearer');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    return this.httpClient.post<any>(`${this.configuration.basePath}/api/v1/artransaction/update-insurance`, null, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * Update existing transaction, or add new one. (Auth requred: Transactions: Update)
   * Update existing transaction, or add new one
   * @param datasetId
   * @param aRTransactionVisitUpdateModel
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiV1ArtransactionUpdatePost(
    datasetId: string,
    aRTransactionVisitUpdateModel?: ARTransactionVisitUpdateModel,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<any>;
  public apiV1ArtransactionUpdatePost(
    datasetId: string,
    aRTransactionVisitUpdateModel?: ARTransactionVisitUpdateModel,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpResponse<any>>;
  public apiV1ArtransactionUpdatePost(
    datasetId: string,
    aRTransactionVisitUpdateModel?: ARTransactionVisitUpdateModel,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpEvent<any>>;
  public apiV1ArtransactionUpdatePost(
    datasetId: string,
    aRTransactionVisitUpdateModel?: ARTransactionVisitUpdateModel,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<any> {
    if (datasetId === null || datasetId === undefined) {
      throw new Error('Required parameter datasetId was null or undefined when calling apiV1ArtransactionUpdatePost.');
    }

    let localVarHeaders = this.defaultHeaders;
    if (datasetId !== undefined && datasetId !== null) {
      localVarHeaders = localVarHeaders.set('DatasetId', String(datasetId));
    }

    let localVarCredential: string | undefined;
    // authentication (Bearer) required
    localVarCredential = this.configuration.lookupCredential('Bearer');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    return this.httpClient.post<any>(
      `${this.configuration.basePath}/api/v1/artransaction/update`,
      aRTransactionVisitUpdateModel,
      {
        context: localVarHttpContext,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }
}
