/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ImplementationDetailViewModel } from './implementationDetailViewModel';

export interface ImplementationViewModel {
  id?: string;
  datasetId?: string | null;
  status?: string | null;
  details?: Array<ImplementationDetailViewModel> | null;
  createdBy?: string | null;
  createdDate?: string;
  updatedBy?: string | null;
  updatedDate?: string | null;
}
