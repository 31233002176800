/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface StatementDetailViewModel {
  datasetName?: string | null;
  datasetAddress1?: string | null;
  datasetAddress2?: string | null;
  datasetCity?: string | null;
  datasetState?: string | null;
  datasetZip?: string | null;
  doctorFirstName?: string | null;
  doctorLastName?: string | null;
  doctorMiddleName?: string | null;
  locationName?: string | null;
  patientAccountNumber?: string | null;
  patientFirstName?: string | null;
  patientMiddleName?: string | null;
  patientLastName?: string | null;
  guarantorFirstName?: string | null;
  guarantorMiddleName?: string | null;
  guarantorLastName?: string | null;
  guarantorAddress1?: string | null;
  guarantorAddress2?: string | null;
  guarantorCity?: string | null;
  guarantorState?: string | null;
  guarantorZip?: string | null;
  primaryInsuranceNumber?: string | null;
  primaryInsuranceName?: string | null;
  secondaryInsuranceNumber?: string | null;
  secondaryInsuranceName?: string | null;
  tertiaryInsuranceNumber?: string | null;
  tertiaryInsuranceName?: string | null;
  budgetAmount?: string | null;
  maxBillStage?: number;
  statementTotalBalance?: number;
  insurancePendingBalance?: string | null;
  visitNumber?: number;
  serviceDate?: string | null;
  locationCode?: string | null;
  procedureCode?: string | null;
  procedureDescription?: string | null;
  procedureAmount?: number;
  paymentPostDate?: string | null;
  paymentInsuranceNumber?: string | null;
  paymentAmount?: number;
  adjustmentAmount?: number;
  procedureBalance?: number;
  insuranceCarrierName?: string | null;
  arTransactionId?: string | null;
  procedureTransactionId?: string | null;
  insBilled?: string | null;
  holdStatus?: string | null;
  isPayment?: string | null;
  denialMessage?: string | null;
  denialNote?: string | null;
  responsibleEntity?: string | null;
  recordType?: string | null;
  patientDob?: string | null;
  respFirstName?: string | null;
  respLastName?: string | null;
  respMiddleName?: string | null;
  patientAddress1?: string | null;
  patientAddress2?: string | null;
  patientCity?: string | null;
  patientState?: string | null;
  patientZip?: string | null;
  collectorFirstName?: string | null;
  collectorLastName?: string | null;
  lastStatement?: string | null;
  statement?: string | null;
  flag?: string | null;
  releaseDate?: string | null;
  statementId?: string | null;
  submissionDate?: string | null;
  guarantorZip2?: string | null;
  patientZip2?: string | null;
  patientPhoneNumber?: string | null;
  patientCountry?: string | null;
  guarantorCountry?: string | null;
  currentBalance?: string | null;
  mobilePhone?: string | null;
  email?: string | null;
  optOutTextMessage?: string | null;
  optOutEmail?: string | null;
  referringDoctorFirstName?: string | null;
  referringDoctorLastName?: string | null;
  referringDoctorMiddleName?: string | null;
  datasetId?: string;
  patientId?: string;
  visitId?: string;
  statementFileId?: string;
  generatedOnDemand?: boolean;
  holdDelinquencyCode?: boolean;
}
