/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RuleCardExpressionOperatorViewModel } from './ruleCardExpressionOperatorViewModel';
import { PagedModelRequest } from './pagedModelRequest';
import { PagedListMetaData } from './pagedListMetaData';

export interface RuleCardExpressionOperatorViewModelPagedModelResponse {
  pagedRequest?: PagedModelRequest;
  data?: Array<RuleCardExpressionOperatorViewModel> | null;
  metaData?: PagedListMetaData;
}
