/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface AddLocationViewModel {
  datasetId?: string;
  code?: string | null;
  name?: string | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  stateCode?: string | null;
  zip?: string | null;
  countryCode?: string | null;
  phone?: string | null;
  fax?: string | null;
  website?: string | null;
  locationType?: string | null;
  taxId?: string | null;
  npi?: string | null;
  clia?: string | null;
  mammo?: string | null;
  sameMailing?: boolean;
  mailingAddress1?: string | null;
  mailingAddress2?: string | null;
  mailingCity?: string | null;
  mailingStateCode?: string | null;
  mailingZip?: string | null;
  mailingCountryCode?: string | null;
  placeOfServiceCodeIdDefault?: string | null;
  rvuGpciId?: string | null;
}
