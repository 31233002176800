<div class="global-search">
  <div class="global-search__body">
    <form class="search-data" [formGroup]="formGroup">
      <mat-radio-group class="radio-group" id="search-type" formControlName="searchType" [(ngModel)]="searchSelection">
        <mat-radio-button value="contains" id="containsButton">Contains</mat-radio-button>
        <mat-radio-button value="startsWith" id="startsWithButton">Starts With</mat-radio-button>
      </mat-radio-group>
      <mat-form-field appearance="outline" floatLabel="never" class="search-terms">
        <input type="text" matInput id="searchTerms" formControlName="searchTerms" />
        <button mat-button class="clear" (click)="clear()" id="clearSearchTerms">
          <mat-icon class="icon">highlight_off</mat-icon>
        </button>
      </mat-form-field>
      <div class="narrow-results-label">Filter Results by Date</div>
      <mat-form-field appearance="outline" class="date-range">
        <mat-label>Date Range</mat-label>
        <mat-date-range-input [rangePicker]="batchpicker" id="batchpicker">
          <input
            matStartDate
            matInput
            formControlName="dateStart"
            placeholder="Start date"
            class="start-date"
            id="dateStart"
            title="Patients: Birth Date &#10;Visits: Service Date &#10;Batches: Batch Date, Posting Date"
          />
          <input matEndDate matInput formControlName="dateEnd" placeholder="End date" id="dateEnd" />
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="batchpicker"></mat-datepicker-toggle>
        <mat-date-range-picker #batchpicker></mat-date-range-picker>
      </mat-form-field>

      <h3>Search For</h3>
      <mat-checkbox id="patient" formControlName="patient" (change)="patientToggle()">Patient</mat-checkbox>
      <div class="additional-filters">
        <div class="limit-results-label">Limit Results To:</div>
        <div>
          <mat-checkbox
            class="sub-filter"
            id="first_last_name"
            formControlName="first_last_name"
            (click)="subfieldToggle('first_last_name', 'patient')"
            >Patient Name</mat-checkbox
          >
        </div>
        <div>
          <mat-checkbox
            class="sub-filter-left"
            id="ssn"
            formControlName="ssn"
            (click)="subfieldToggle('ssn', 'patient')"
            >SSN</mat-checkbox
          >
          <mat-checkbox class="sub-filter" id="mrn" formControlName="mrn" (click)="subfieldToggle('mrn', 'patient')"
            >MRN</mat-checkbox
          >
        </div>
        <div>
          <mat-checkbox
            class="sub-filter-left"
            id="altid"
            formControlName="altid"
            (click)="subfieldToggle('altid', 'patient')"
            >Account#</mat-checkbox
          >
          <mat-checkbox
            class="sub-filter"
            id="employer"
            formControlName="employer"
            (click)="subfieldToggle('employer', 'patient')"
            >Employer</mat-checkbox
          >
        </div>
        <div>
          <mat-checkbox
            class="sub-filter-left"
            id="policy_number"
            formControlName="policy_number"
            (click)="subfieldToggle('policy_number', 'patient')"
            >Policy#</mat-checkbox
          >
          <mat-checkbox
            class="sub-filter"
            id="notes"
            formControlName="notes"
            (click)="subfieldToggle('notes', 'patient')"
            >Notes</mat-checkbox
          >
        </div>
        <div>
          <mat-checkbox
            class="sub-filter"
            id="guarantor"
            formControlName="guarantor"
            (click)="subfieldToggle('guarantor', 'patient')"
            >Guarantor Name</mat-checkbox
          >
        </div>
        <div>
          <mat-checkbox
            class="sub-filter"
            id="guarantor_phone"
            formControlName="guarantor_phone"
            (click)="subfieldToggle('guarantor_phone', 'patient')"
            >Guarantor Phone #</mat-checkbox
          >
        </div>
        <div>
          <mat-checkbox
            class="sub-filter"
            id="guarantorAddress"
            formControlName="guarantor_address"
            (click)="subfieldToggle('guarantor_address', 'patient')"
            >Guarantor Address</mat-checkbox
          >
        </div>
      </div>
      <mat-checkbox id="visits" formControlName="visits" (change)="visitToggle()">Visits</mat-checkbox>
      <div class="additional-filters">
        <div class="limit-results-label">Limit Results To:</div>
        <div>
          <mat-checkbox class="sub-filter-left" id="icn" formControlName="icn" (click)="subfieldToggle('icn', 'visits')"
            >ICN</mat-checkbox
          >
          <mat-checkbox
            class="sub-filter"
            id="check_number"
            formControlName="check_number"
            (click)="subfieldToggle('check_number', 'visits')"
            >Check#</mat-checkbox
          >
        </div>
        <mat-checkbox
          class="sub-filter"
          id="procedure_results"
          formControlName="procedure_results"
          (click)="subfieldToggle('procedure_results', 'visits')"
          >Procedure Results</mat-checkbox
        >
      </div>
      <mat-checkbox id="batches" formControlName="batches" (change)="batchToggle()">Batches</mat-checkbox>
      <div class="additional-filters">
        <div class="limit-results-label">Limit Results To:</div>
        <mat-checkbox
          class="sub-filter"
          id="batch_check_number"
          formControlName="batch_check_number"
          (click)="subfieldToggle('batch_check_number', 'batches')"
          >Check#</mat-checkbox
        >
      </div>
      <div>
        <mat-checkbox id="codes" formControlName="codes">Codes</mat-checkbox>
      </div>
      <div>
        <mat-checkbox id="other" formControlName="other" title="Insurance Carriers, Locations, Providers"
          >Other</mat-checkbox
        >
      </div>
      <div>
        <button mat-button class="clear-filters" color="primary" id="clearFiltersButton" (click)="clearFilters()">
          Clear all filters
        </button>
      </div>
    </form>
    <div class="footer">
      <button mat-stroked-button id="cancelButton" (click)="closeWindow()">Cancel</button>
      <button mat-flat-button color="primary" id="searchButton" (click)="search()" [disabled]="formGroup?.invalid">
        Search
      </button>
    </div>
  </div>
</div>
