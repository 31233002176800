import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  HL7ConfigurationViewModel,
  HL7ListenerService,
  HL7UploadViewModel,
  HL7UploadViewModelPagedModelResponse,
  UploadService
} from '../../../../../projects/data/src/public-api';
import { BaseWrapperService } from './base-wrapper.service';
import { PagedModelRequest } from 'data';
import { SessionStorageService } from '../session-storage.service';

@Injectable({
  providedIn: 'root'
})
export class ImportResultWrapperService extends BaseWrapperService {
  constructor(
    private uploadService: UploadService,
    private hl7ListenerService: HL7ListenerService,
    protected sessionStorageService: SessionStorageService
  ) {
    super();
  }

  apiV1Hl7ListenerStatusPost(): Observable<Array<HL7ConfigurationViewModel>> {
    return this.hl7ListenerService.apiV1Hl7ListenerStatusPost(this.getDataSetId(), 'body', true);
  }

  apiV1UploadHistoryPost(pagedModelRequest?: PagedModelRequest): Observable<HL7UploadViewModelPagedModelResponse> {
    return this.uploadService.apiV1UploadHistoryPost(this.getDataSetId(), pagedModelRequest);
  }

  apiV1UploadDownloadIdPost(id: string): Observable<Blob> {
    return this.uploadService.apiV1UploadDownloadIdPost(id, this.getDataSetId());
  }

  apiV1Hl7ListenerPatchIdPatch(id: string, operation): Observable<HL7ConfigurationViewModel> {
    return this.hl7ListenerService.apiV1Hl7ListenerPatchIdPatch(id, this.getDataSetId(), operation);
  }

  apiV1Hl7ListenerAddPost(requestBody): Observable<HL7ConfigurationViewModel> {
    return this.hl7ListenerService.apiV1Hl7ListenerAddPost(this.getDataSetId(), requestBody);
  }

  apiV1Hl7ListenerStatusIdPost(id: string): Observable<HL7ConfigurationViewModel> {
    return this.hl7ListenerService.apiV1Hl7ListenerStatusIdPost(id, this.getDataSetId());
  }

  apiV1Hl7ListenerTurnOffIdPost(id: string): Observable<any> {
    return this.hl7ListenerService.apiV1Hl7ListenerTurnOffIdPost(id, this.getDataSetId());
  }

  apiV1Hl7ListenerTurnOnIdPost(id: string): Observable<any> {
    return this.hl7ListenerService.apiV1Hl7ListenerTurnOnIdPost(id, this.getDataSetId());
  }

  apiV1UploadPost(file?: Blob): Observable<any> {
    return this.uploadService.apiV1UploadPost(this.getDataSetId(), file, 'events', true);
  }

  apiV1UploadCheckPost(filename?: string): Observable<any> {
    return this.uploadService.apiV1UploadCheckPost(this.getDataSetId(), filename);
  }

  apiV1UploadSummaryIdPost(id?: string): Observable<any> {
    return this.uploadService.apiV1UploadSummaryIdPost(id, this.getDataSetId());
  }
}
