/**
 * ImaginePay API
 * This API facilitates partner integration within the platform.
 *
 * The version of the OpenAPI document: 22_12_0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ImaginePayApiModelsPaymentRequestsVoidPaymentsStatus } from './imaginePayApiModelsPaymentRequestsVoidPaymentsStatus';

export interface ImaginePayApiModelsPaymentRequestsVoidPaymentsResult {
  paymentRequestId: number;
  totalAmountVoided?: number;
  actionPerformed?: boolean;
  message?: string;
  voidPaymentsStatus?: Array<ImaginePayApiModelsPaymentRequestsVoidPaymentsStatus>;
}
