/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface ZeroBalanceRequestViewModel {
  financialClassIds?: string | null;
  monthsAtZero?: number;
  procedureServiceRangeStart?: string;
  procedureServiceRangeEnd?: string;
  paymentPostedRangeStart?: string;
  paymentPostedRangeEnd?: string;
  statementRangeStart?: string;
  statementRangeEnd?: string;
  onlyDemos?: boolean;
}
