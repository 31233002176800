<div class="dialog-form report-form modal">
  <div class="dialog-form__close">
    <a mat-dialog-close>
      <mat-icon>close</mat-icon>
    </a>
  </div>
  <header mat-dialog-title class="dialog-form__title">
    <h2>Insurance Carrier</h2>
  </header>
  <div *ngIf="dataSource && gridConfig" class="lib-grid__grid-container">
    <lib-grid [dataSource]="dataSource" [gridConfiguration]="gridConfig" class="container">
      <div class="">
        <h4>No Ansi Information Found.</h4>
      </div>
    </lib-grid>
  </div>
  <button mat-button color="primary" class="newLoop" (click)="add()">
    <mat-icon>add_circle_outline</mat-icon> Add
  </button>

  <div mat-dialog-actions class="dialog-form__actions">
    <button class="cancel-button" mat-stroked-button mat-dialog-close>Cancel</button>
    <lib-button-loading color="primary" class="save-button" [loading]="saving" (clickEvent)="save()"
      >Save</lib-button-loading
    >
  </div>
</div>
