/**
 * ImaginePay API
 * This API facilitates partner integration within the platform.
 *
 * The version of the OpenAPI document: 22_12_0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface ImaginePayPartnerIntegrationTypesPaymentRequestTransactionDetails {
  referenceNumber: string;
  user: string;
  amount: number;
  dateSubmitted: string;
  receiptEmail?: string;
  paySource: string;
  creditAmount?: number;
  checkAmount?: number;
  cashAmount?: number;
  onlinePaymentRequestID: number;
  onlineProviderID: string;
  onlineAccountNumber: string;
  onlineTransactionID: string;
  isScheduled: boolean;
  scheduleID?: number;
  scheduleAmount?: number;
  scheduleNumberOfPayments?: number;
  scheduledPaymentMethod?: string;
  schedulePaymentMethodDetails?: string;
  scheduleNextPayment?: string;
  settlementDate: string;
  autoAdjustedPayment: boolean;
}
