import { Injector, LOCALE_ID } from '@angular/core';
import { Validators } from '@angular/forms';
import {
    LocationsLookupService,
    PhysiciansLookupService,
    FinancialClassesLookupService
} from '../../../../core/services/lookup';
import { ColumnSizes, FieldSelectOption, FilterType } from 'components';
import { Report } from '../../models/report';
import { DateDisplayPipe } from 'projects/components/src/lib/shared/pipes/date-display/date-display.pipe';
import { ProcedureAnalysisByProviderService } from './procedure-analysis-by-provider.service';

export const ProcedureAnalysisByProvider: Report = {
    id: 'procedure-analysis-by-provider',
    label: 'Procedure Analysis by Provider',
    textCriteria: { 'Date Type': '', 'Start Date': '', 'End Date': '', 'Financial Classes': '', 'Provider Type': '', 'Providers': '', 'Locations': '' },
    cleanFilterParams: {
        'financialClassIds': '', 'locationIds': '', 'providerIds': ''
    },
    getReportTitle: () => {
        return ProcedureAnalysisByProvider.label
    },
    getSearchCriteria: (injector: Injector) => [
        {
            controls: [
                {
                    label: 'Date Range Type',
                    name: 'dateRangeType',
                    type: 'select',
                    class: 'form-span-6',
                    validators: Validators.required,
                    initial: 'S',
                    options: [
                        {
                            label: 'Posting Date',
                            value: 'P'
                        },
                        {
                            label: 'Service Date',
                            value: 'S'
                        }
                    ],
                    selectionChanged: (event) => {
                        ProcedureAnalysisByProvider.textCriteria['Date Type'] = event.source.triggerValue;
                    }
                },
                {
                    label: 'Date Range',
                    name: 'dateRange',
                    type: 'dateRange',
                    class: 'form-span-6',
                    daterangeStartChanged: (event) => {
                        ProcedureAnalysisByProvider.textCriteria['Start Date'] = event.value.toLocaleDateString();
                    },
                    daterangeEndChanged: (event) => {
                        ProcedureAnalysisByProvider.textCriteria['End Date'] = event.value.toLocaleDateString();
                    }
                },
                {
                    label: '',
                    name: 'dateRangeStart',
                    type: 'noInput',
                    class: 'display-none',
                    validators: Validators.required,
                },
                {
                    label: '',
                    name: 'dateRangeEnd',
                    type: 'noInput',
                    class: 'display-none',
                    validators: Validators.required,
                },
                {
                    matLabel: 'Location',
                    name: 'locationIds',
                    apiService: injector.get(LocationsLookupService),
                    type: 'multiSelect',
                    class: 'form-span-6 multi-input',
                    placeholder: 'Location',
                    selectOptions: (event) => {
                        let listValue = []
                        let listText = []
                        event.forEach((x: FieldSelectOption) => {

                            listValue.push(x.value)
                            listText.push(x.label)
                        })
                        ProcedureAnalysisByProvider.textCriteria['Locations'] = listText.join(',');
                        ProcedureAnalysisByProvider.cleanFilterParams['locationIds'] = listValue
                    }
                },
                {
                    matLabel: 'Financial Class',
                    name: 'financialClassIds',
                    apiService: injector.get(FinancialClassesLookupService),
                    class: 'form-span-6 multi-input',
                    type: 'multiSelect',
                    placeholder: 'Financial Class',
                    selectOptions: (event) => {
                        let listValue = []
                        let listText = []
                        event.forEach((x: FieldSelectOption) => {
                            listValue.push(x.value)
                            listText.push(x.label)
                        })
                        ProcedureAnalysisByProvider.textCriteria['Financial Classes'] = listText.join(',');
                        ProcedureAnalysisByProvider.cleanFilterParams['financialClassIds'] = listValue
                    }
                },

                {
                    matLabel: 'Provider',
                    name: 'attendingProviderIds',
                    apiService: injector.get(PhysiciansLookupService),
                    type: 'multiSelect',
                    class: 'form-span-6 multi-input',
                    placeholder: 'Provider',
                    selectOptions: (event) => {
                        let listValue = []
                        let listText = []
                        event.forEach((x: FieldSelectOption) => {
                            listValue.push(x.value)
                            listText.push(x.label)
                        })
                        ProcedureAnalysisByProvider.textCriteria['Providers'] = listText.join(',');
                        ProcedureAnalysisByProvider.cleanFilterParams['attendingProviderIds'] = listValue

                    }
                },
                {
                    label: 'Provider Type',
                    name: 'providerType',
                    type: 'select',
                    class: 'form-span-6',
                    validators: Validators.required,
                    initial: 'A',
                    options: [
                        {
                            label: 'Attending',
                            value: 'A'
                        },
                        {
                            label: 'Referring',
                            value: 'R'
                        },
                        {
                            label: 'Ordering',
                            value: 'O'
                        },
                        {
                            label: 'Primary',
                            value: 'P'
                        }
                    ],
                    selectionChanged: (event) => {
                        ProcedureAnalysisByProvider.textCriteria['Provider Type'] = event.source.triggerValue;
                    }
                },
                {
                    label: 'Exclude Refunds',
                    name: 'excludeRefunds',
                    type: 'checkbox',
                    class: 'form-span-12 checkbox',
                    initial: false,
                    toggleChanged: (event) => {
                        ProcedureAnalysisByProvider.textCriteria['Exclude Refunds'] = event.checked === true ? 'Yes' : 'No'
                    }
                },
            ]
        }
    ],
    getGridConfiguration: (injector: Injector) => {
        const locale = injector.get(LOCALE_ID);
        const datePipe: DateDisplayPipe = new DateDisplayPipe(locale);
        const moneyFormat = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });
        const decimalFormat = new Intl.NumberFormat('en-US', { style: "decimal", minimumFractionDigits: 1, maximumFractionDigits: 4 });
        return {
            service: injector.get<any>(ProcedureAnalysisByProviderService),
            columnDefinitions: [
                {
                    id: 'provider',
                    displayKey: 'provider',
                    headerText: 'Provider',
                    displayOrder: 0,
                    columnSize: ColumnSizes.normal,
                    sortable: true,
                    filterable: true,
                },
                {
                    id: 'location',
                    displayKey: 'location',
                    headerText: 'Location',
                    displayOrder: 1,
                    columnSize: ColumnSizes.normal,
                    sortable: true,
                    filterable: true,
                },
                {
                    id: 'procedure',
                    displayKey: 'procedure',
                    headerText: 'Procedure',
                    displayOrder: 3,
                    columnSize: ColumnSizes.normal,
                    sortable: true,
                    filterable: true
                },
                {
                    id: 'Volume',
                    displayKey: 'Volume',
                    headerText: 'Volume',
                    displayOrder: 4,
                    columnSize: ColumnSizes.extraNarrow,
                    sortable: true,
                    filterable: true,
                },
                {
                    id: 'Procedure Amount',
                    displayKey: 'Procedure Amount',
                    headerText: 'Procedure Amount',
                    displayOrder: 5,
                    columnSize: ColumnSizes.narrow,
                    sortable: true,
                    filterable: true,
                    formatter: moneyFormat.format
                },
                {
                    id: 'Payment Amount',
                    displayKey: 'Payment Amount',
                    headerText: 'Payment Amount',
                    displayOrder: 6,
                    columnSize: ColumnSizes.narrow,
                    sortable: true,
                    filterable: true,
                    formatter: moneyFormat.format
                },
                {
                    id: 'Adjustment Amount',
                    displayKey: 'Adjustment Amount',
                    headerText: 'Adjustment Amount',
                    displayOrder: 7,
                    columnSize: ColumnSizes.narrow,
                    sortable: true,
                    filterable: true,
                    formatter: moneyFormat.format
                },
                {
                    id: 'RVU Units',
                    displayKey: 'RVU Units',
                    headerText: 'RVU Units',
                    displayOrder: 8,
                    columnSize: ColumnSizes.narrow,
                    sortable: true,
                    filterable: true,
                    formatter: decimalFormat.format
                }
            ]
        };
    }
};
