import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GridComponent } from './grid.component';
import { MaterialModule } from '../modules';
import { PipesModule } from '../pipes';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { GridFilterComponent } from './components/grid-filter/grid-filter.component';
import { FilterColumnsPipe, HeaderColumnClassPipe, BodyColumnClassPipe } from './pipes';
import { OverflowMenuModule } from '../overflow-menu/overflow-menu.module';
import { FormModule } from '../form/form.module';
import { ReportGridComponent } from './report-grid/report-grid.component';


@NgModule({
  declarations: [GridComponent, GridFilterComponent, FilterColumnsPipe, HeaderColumnClassPipe, BodyColumnClassPipe, ReportGridComponent],
  exports: [GridComponent, ReportGridComponent],
  imports: [
    CommonModule,
    MaterialModule,
    PipesModule,
    ReactiveFormsModule,
    RouterModule,
    OverflowMenuModule,
    FormModule
  ]
})
export class GridModule {}
