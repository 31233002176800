/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { FinancialClassSimpleViewModel } from './financialClassSimpleViewModel';
import { PatientSimpleViewModel } from './patientSimpleViewModel';
import { InsuranceSimpleViewModel } from './insuranceSimpleViewModel';
import { ARTransactionSimpleViewModel } from './aRTransactionSimpleViewModel';
import { CodeSimpleViewModel } from './codeSimpleViewModel';
import { QueueItemData } from './queueItemData';
import { ProcedureTransactionSimpleViewModel } from './procedureTransactionSimpleViewModel';

export interface VisitQueueDetailsViewModel {
  id?: string;
  datasetId?: string;
  referenceNumber?: number | null;
  holdStatus?: string | null;
  delinquencyCode?: number;
  hospitalAdmitDate?: string | null;
  hospitalDischargeDate?: string | null;
  illnessInjuryDate?: string | null;
  accidentStateCode?: string | null;
  procedureAmount?: number;
  adjusted?: number;
  balance?: number;
  paid?: number;
  procedureCount?: number;
  paymentCount?: number;
  adjustmentCount?: number;
  hasActivePaymentPlan?: boolean;
  patient?: PatientSimpleViewModel;
  accidentType?: CodeSimpleViewModel;
  financialClass?: FinancialClassSimpleViewModel;
  primaryInsurance?: InsuranceSimpleViewModel;
  secondaryInsurance?: InsuranceSimpleViewModel;
  tertiaryInsurance?: InsuranceSimpleViewModel;
  procedureTransactions?: Array<ProcedureTransactionSimpleViewModel> | null;
  arTransactions?: Array<ARTransactionSimpleViewModel> | null;
  issues?: Array<QueueItemData> | null;
}
