<header class="lib-header">
  <ng-template *ngIf="header.headerOutlet | async as headerOutlet" [cdkPortalOutlet]="headerOutlet"> </ng-template>

  <button mat-icon-button (click)="openSearch.emit()">
    <mat-icon>search</mat-icon>
  </button>

  <button mat-icon-button (click)="openNotifications.emit()" *ngIf="notificationsActive">
    <mat-icon
      [matBadge]="notificationCount"
      [matBadgeHidden]="notificationCount && notificationCount === 0"
      matBadgeColor="warn"
      >notifications_none</mat-icon
    >
  </button>

  <button mat-icon-button (click)="document.emit()">
    <mat-icon>help_outline</mat-icon>
  </button>
</header>
