/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HoldStatus } from './holdStatus';

export interface Visit {
  id?: string;
  rowId?: number;
  datasetId?: string;
  patientId: string;
  patientRowId?: number;
  accidentTypeId?: string | null;
  primaryInsuranceId?: string | null;
  secondaryInsuranceId?: string | null;
  tertiaryInsuranceId?: string | null;
  followUpInsuranceId?: string | null;
  accidentStateCode?: string | null;
  referenceNumber?: number;
  delinquencyCode?: number;
  inCollectionModule?: boolean;
  isScheduledWriteoff?: boolean;
  holdStatus?: HoldStatus;
  financialClassId?: string;
  hospitalAdmitDate?: string | null;
  hospitalDischargeDate?: string | null;
  illnessInjuryDate?: string | null;
  patientStatus?: string | null;
  isMerged?: boolean;
  isBilled?: boolean;
  assignDate?: string | null;
  followUpDate?: string | null;
  followUpNote?: string | null;
  isFollowUpActive?: boolean;
  assignedUserId?: string | null;
  pwkReportTypeCode?: string | null;
  pwkTransmissionCode?: string | null;
  pwkAttachmentNumber?: string | null;
  createdBy?: string | null;
  createdDate?: string;
  updatedBy?: string | null;
  updatedDate?: string | null;
  isDeleted?: boolean;
  deletedBy?: string | null;
  deletedDate?: string | null;
  primaryInsuranceAuthNumber?: string | null;
  secondaryInsuranceAuthNumber?: string | null;
  tertiaryInsuranceAuthNumber?: string | null;
  pendingCommit?: boolean;
  hasActivePaymentPlan?: boolean | null;
}
