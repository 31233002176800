import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FormIssue, SteppedFormGroupDefinition } from 'components';
import { BaseFormComponent } from '../../../shared/components/base-form.component';

@Component({
  selector: 'lib-collapsible-form-group',
  templateUrl: './collapsible-form-group.component.html'
})
export class CollapsibleFormGroupComponent extends BaseFormComponent implements OnInit {
  @Input()
  disabled: boolean;

  @Input()
  formGroups: UntypedFormGroup;

  @Input()
  formIssues: FormIssue[];

  @Input()
  formGroupsDefinition: SteppedFormGroupDefinition[];

  @Input()
  firstFieldByStep: { [step: number]: string };

  constructor() {
    super();
  }

  ngOnInit(): void {}

  focusFirstInput() {
    (document.getElementById(this.firstFieldByStep[0]) as HTMLElement)?.focus();
  }

  trackByIndex(index: number, item: any) {
    return index
  }
}
