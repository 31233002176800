/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EligibilitySourceResponseViewModel } from './eligibilitySourceResponseViewModel';

export interface EligibilityResponseFileViewModel {
  id?: string;
  datasetId?: string;
  fileName?: string | null;
  interchangeSenderIdQualifierCode?: string | null;
  interchangeSenderId?: string | null;
  interchangeReceiverIdQualifierCode?: string | null;
  interchangeReceiverId?: string | null;
  interchangeControlNumber?: string | null;
  responseDateTime?: string;
  senderCode?: string | null;
  receiverCode?: string | null;
  groupControlNumber?: string | null;
  fileContents?: string | null;
  eligibilitySourceResponses?: Array<EligibilitySourceResponseViewModel> | null;
}
