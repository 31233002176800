import { Directive, HostListener, Self, OnDestroy, AfterViewInit, Input } from '@angular/core';
import { NgControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BaseFormatterDirective } from './base-formatter.directive';

@Directive({
  selector: '[libDecimalFormatter]'
})
export class DecimalFormatterDirective extends BaseFormatterDirective implements OnDestroy, AfterViewInit {
  public decimalFormatter: Intl.NumberFormat;
  @Input() decimalCount: number;

  constructor(@Self() private ngControl: NgControl) {
    super(ngControl);
  }
  @HostListener('focus') onFocus() {
    this.baseOnFocus();
  }
  @HostListener('blur') onBlur() {
    this.baseOnBlur();
  }
  ngAfterViewInit() {
    let decimalCount = 2;
    if (!Number.isNaN(Number(this.decimalCount))) {
      decimalCount = this.decimalCount;
    }

    this.decimalFormatter = new Intl.NumberFormat('en-US', { maximumFractionDigits: decimalCount });
    this.setValue(this.formatInput(this.ngControl.value));
    this.ngControl.control.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(this.updateValue.bind(this));
  }

  ngOnDestroy() {
    this.onDestroy();
  }

  formatInput(v) {
    return this.decimalFormatter.format(v);
  }
}
