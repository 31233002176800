import { Directive, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'input[fileUploadInputFor], div[fileUploadInputFor]'
})
export class FileUploadInputForDirective {
  private queue: any = null;
  private element: HTMLElement;

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('fileUploadInputFor') set fileUploadQueue(value: any) {
    if (value) {
      this.queue = value;
    }
  }
  @Output() public fileSelected: EventEmitter<File[]> = new EventEmitter<File[]>();

  constructor(private elem: ElementRef) {
    this.element = this.elem.nativeElement;
  }

  @HostListener('change')
  public onChange(): any {
    const files = this.checkFileLimits(this.elem.nativeElement.files);
    this.fileSelected.emit(files);

    for (let i = 0; i < files.length; i++) {
      this.queue.add(files[i]);
    }
    this.elem.nativeElement.value = '';
  }

  @HostListener('drop', ['$event'])
  public onDrop(event: any): any {
    const files = this.checkFileLimits(event.dataTransfer.files);
    this.fileSelected.emit(files);

    for (let i = 0; i < files.length; i++) {
      this.queue.add(files[i]);
    }
    event.preventDefault();
    event.stopPropagation();
    this.elem.nativeElement.value = '';
  }

  @HostListener('dragover', ['$event'])
  public onDropOver(event: any): any {
    event.preventDefault();
  }

  checkFileLimits(files) {
    const maxFiles = this.queue.maxFiles || 0;
    const availableToAdd = maxFiles - this.queue.files.length;
    const allowedExtensions = this.queue.allowedExtensions;
    let filesArray: File[] = files ? Array.from(files) : [];
    if (allowedExtensions && allowedExtensions.length > 0) {
      filesArray = filesArray.filter((file) => {
        const segments = file.name.split('.');
        const extension = segments[segments.length - 1].toLowerCase();
        return allowedExtensions.find((ext) => ext.toLowerCase() === extension);
      });
    }
    if (maxFiles && availableToAdd < filesArray.length) {
      filesArray = filesArray.slice(0, availableToAdd);
    }
    return filesArray;
  }
}
