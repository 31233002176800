import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { FrequencyInputDialogComponent } from './frequency-input-dialog/frequency-input-dialog.component';

export interface FrequencyInput {
  nextExecution: Date;
  executionHour: number;
  executionMinute: number;
  executionMeridiem: string;
  frequencyType: string;
  frequencyInterval: string;
  weekDays: string;
  monthDays: string;
  executionTimeZone: string;
}

@Component({
  selector: 'lib-frequency-input',
  templateUrl: './frequency-input.component.html',
  styleUrls: ['./frequency-input.component.scss']
})
export class FrequencyInputComponent implements OnInit, OnChanges {
  formGroup = new UntypedFormGroup({
    frequencyDisplay: new UntypedFormControl(null)
  });
  @Input()
  frequencyInput: FrequencyInput;

  @Output() frequencyChanged: EventEmitter<any> = new EventEmitter<any>();

  constructor(public dialog: MatDialog) {}
  ngOnInit(): void {}

  showMe() {
    const dialog = this.dialog.open(FrequencyInputDialogComponent, {
      disableClose: true,
      panelClass: 'frequency-input',
      autoFocus: false,
      data: this.frequencyInput
    });

    dialog.afterClosed().subscribe((result) => {
      if (result) {
        this.formGroup.get('frequencyDisplay').patchValue(result.frequencyType);
        this.frequencyInput = result;
        this.frequencyChanged.emit(this.frequencyInput);
      }
    });
  }

  ngOnChanges(): void {
    let frequency = this.frequencyInput;
    if (frequency !== undefined) {
      this.formGroup.get('frequencyDisplay').patchValue(frequency.frequencyType);
    }
    this.frequencyChanged.emit();
  }
}
