/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface DemographicUpdatesFieldViewModel {
  id?: string;
  datasetId?: string;
  patientId?: string;
  guarantorId?: string | null;
  insuranceId?: string | null;
  insuranceHoldingId?: string | null;
  type?: string | null;
  field?: string | null;
  value?: string | null;
  source?: string | null;
  readonly updateDescription?: string | null;
  status?: string | null;
  originalValue?: string | null;
  createdDate?: string | null;
}
