import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ImplementationWrapperService } from '@core/services/service-wrappers/implementation-wrapper.service';
import { selectImplementation, selectImplementationDetails, selectImplementationCurrentStep } from './state/implementation.selectors';
import { Subscription } from 'rxjs';
import { MatIconModule } from '@angular/material/icon';
import { DialogService } from '@core/services/dialog.service';
import { DialogContent } from 'components';
import { ImplementationState } from './state/implementation.state';
import { selectDataSet } from '../../core/state/data-set/data-set.actions';
import { DataSetState } from '@core/state/data-set/data-set.state';
import { AppState } from '@core/core.state';
import { ImplementationViewModel } from 'projects/data/src/lib/model/implementationViewModel';
import { UserDetailsViewModel } from 'data';
import { SessionStorageService } from '@core/services/session-storage.service';


@Component({
  selector: 'app-implementation',
  templateUrl: './implementation.component.html',
  styleUrls: ['./implementation.component.scss']
})
export class ImplementationComponent implements OnInit, OnDestroy {
  userInfo: UserDetailsViewModel;

  constructor(private service: ImplementationWrapperService,
    private router: Router,
    private store: Store<ImplementationState>,
    private appStore: Store<AppState>,
    private sessionStorageService: SessionStorageService) {
    this.userInfo = this.sessionStorageService.getItem("user-info");
  }

  private subscriptions: Subscription[] = [];
  public implementationDetails$ = this.store.select(selectImplementationDetails);
  public implementation$ = this.store.select(selectImplementation);
  public implementationCurrentStep$ = this.store.select(selectImplementationCurrentStep);
  private implementationCurrentStep = '';
  private initialNav = true;
  private progressPercentage = 0;
  private implementationComplete = false;
  ngOnInit(): void {
    this.subscriptions.push(
      this.implementation$.subscribe((i) => {
        this.progressPercentage = this.getProgress(i);
        if (i) {
          if (this.initialNav) {
            const implStep = this.getImplementationStatusAndStep(i);
            this.appStore.dispatch(selectDataSet({ dataSetId: i.datasetId }));
            this.router.navigateByUrl('/implementation/' + implStep);
            this.initialNav = false;
          }
          this.implementationComplete = (i.details.find(d => d.status !== 'Complete') === undefined);
        }
      }),
      this.implementationCurrentStep$.subscribe((s) => this.implementationCurrentStep = s)
    );
  }

  getProgress(i: ImplementationViewModel) {
    let progress = 0.0;
    let totalSteps = 0;
    let completeSteps = 0;
    i?.details?.forEach(d => {
      totalSteps++;
      if (d.status === 'Complete') completeSteps++;
    });

    progress = ((completeSteps / totalSteps) * 100);
    return progress;
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  getImplementationStatusAndStep(implementation: ImplementationViewModel): string {
    if ((implementation.status ?? '') === 'NotStarted') {
      return 'start';
    }
    else {
      const detailLen = implementation.details?.length ?? 0;
      if (detailLen === 0) return 'start';
      else {
        for (let idx = detailLen - 1; idx >= 0; idx--) {
          if ((implementation.details[idx].status ?? '') === 'InProgress' || idx == 0) {
            return implementation.details[idx].implementationStepCode;
          }
        }
      }
    }
  }

  trackById(index: number, item: any) {
    return item.id;
  }

  adminRedirect(stepCode) {
    if (this.userInfo?.email?.toLowerCase().includes("@imagineteam.com")) {
      this.router.navigateByUrl('/implementation/' + stepCode);
    }
  }
}
