<div class="strength">
  <ul class="strengthBar mt-2">
    <li class="point" [style.background-color]="bar0"></li>
    <li class="point" [style.background-color]="bar1"></li>
    <li class="point" [style.background-color]="bar2"></li>
    <li class="point" [style.background-color]="bar3"></li>
    <li class="point" [style.background-color]="bar4"></li>
    <li class="point" [style.background-color]="bar5"></li>
  </ul>
  <p class="text-center mb-0" [style.color]="messageColor" *ngIf="passwordToCheck?.length">{{ message }}</p>
</div>
