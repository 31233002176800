import { Injector, LOCALE_ID } from '@angular/core';
import { Validators } from '@angular/forms';
import { ColumnSizes, FieldSelectOption, FilterType } from 'components';
import { DateDisplayPipe } from 'projects/components/src/lib/shared/pipes/date-display/date-display.pipe';
import { Report } from '../../models/report';
import {
  FinancialClassesLookupService,
  InsuranceCarriersLookupService,
} from '../../../../core/services/lookup';
import { UserLookupService } from '../../../../core/services/lookup/user-lookup.service';
import { NewPatientReportService } from './new-patient-report.service';

export const NewPatientReport: Report = {
  id: 'new-patient-report',
  label: 'New Patient Listing Report',
  textCriteria: {
    'Start Date': '',
    'End Date': '',
    'Created By': '',
    'Financial Class': '',
    'Primary Carrier': ''
  },
  cleanFilterParams: {
    'userIds': '', 'financialClassIds': '', 'insuranceCarrierIds': ''
  },
  getReportTitle: () => {
    return (
      NewPatientReport.label +
      ' ' +
      NewPatientReport.textCriteria['Start Date'] +
      ' - ' +
      NewPatientReport.textCriteria['End Date'] +
      ' (' +
      NewPatientReport.textCriteria['Created By'] +
      ')'
    );
  },
  getSearchCriteria: (injector: Injector) => [
    {
      title: '',
      controls: [
        {
          label: 'Date Range',
          name: 'dateRange',
          type: 'dateRange',
          class: 'form-span-12',
          daterangeStartChanged: (event) => {
            NewPatientReport.textCriteria['Start Date'] = event.value.toLocaleDateString();
          },
          daterangeEndChanged: (event) => {
            NewPatientReport.textCriteria['End Date'] = event.value.toLocaleDateString();
          }
        },
        {
          label: '',
          name: 'dateRangeStart',
          type: 'noInput',
          class: 'display-none',
          validators: Validators.required
        },
        {
          label: '',
          name: 'dateRangeEnd',
          type: 'noInput',
          class: 'display-none',
          validators: Validators.required
        },
        {
          matLabel: 'Users',
          name: 'userIds',
          apiService: injector.get(UserLookupService),
          class: 'form-span-12 multi-input',
          type: 'multiSelect',
          placeholder: 'Users',
          selectOptions: (event: []) => {
            let listValue = []
            let listText = []
            event.forEach((x: FieldSelectOption) => {
              listValue.push(x?.value)
              listText.push(x.label)
            })
            NewPatientReport.textCriteria['Users'] = listText.join(',');
            NewPatientReport.cleanFilterParams['userIds'] = listValue
          },
          includeSystemOption: true,
        },
        {
          matLabel: 'Financial Class',
          name: 'financialClassIds',
          apiService: injector.get(FinancialClassesLookupService),
          class: 'form-span-12 multi-input',
          type: 'multiSelect',
          placeholder: 'Financial Class',
          selectOptions: (event) => {
            let listValue = []
            let listText = []
            event.forEach((x: FieldSelectOption) => {
              listValue.push(x.value)
              listText.push(x.label)
            })
            NewPatientReport.textCriteria['Financial Classes'] = listText.join(',');
            NewPatientReport.cleanFilterParams['financialClassIds'] = listValue
          }
        },
        {
          matLabel: 'Insurance Carrier',
          name: 'insuranceCarrierIds',
          apiService: injector.get(InsuranceCarriersLookupService),
          class: 'form-span-12 multi-input',
          type: 'multiSelect',
          placeholder: 'Insurance Carriers',
          selectOptions: (event: []) => {
            let listValue = []
            let listText = []
            event.forEach((x: FieldSelectOption) => {
              listValue.push(x?.value)
              listText.push(x.label)
            })
            NewPatientReport.textCriteria['Insurance Carriers'] = listText.join(',');
            NewPatientReport.cleanFilterParams['insuranceCarrierIds'] = listValue
          },
        },
      ]
    }
  ],
  getGridConfiguration: (injector: Injector) => {
    return {
      service: injector.get<any>(NewPatientReportService),
      columnDefinitions: [
        {
          id: 'Account Number',
          displayKey: 'Account Number',
          headerText: 'Account Number',
          displayOrder: 0,
          columnSize: ColumnSizes.normal,
          sortable: true,
          filterable: true
        },
        {
          id: 'Date Created',
          displayKey: 'Date Created',
          headerText: 'Date Created',
          displayOrder: 1,
          columnSize: ColumnSizes.midwidth,
          sortable: true
        },
        {
          id: 'Created By User',
          displayKey: 'Created By User',
          headerText: 'Created By User',
          displayOrder: 2,
          columnSize: ColumnSizes.wide,
          sortable: true,
          filterable: true
        },
        {
          id: 'Patient Name',
          displayKey: 'Patient Name',
          headerText: 'Patient Name',
          displayOrder: 3,
          columnSize: ColumnSizes.wide,
          sortable: true,
          filterable: true
        },
        {
          id: 'Date of Birth',
          displayKey: 'Date of Birth',
          headerText: 'Date of Birth',
          displayOrder: 4,
          columnSize: ColumnSizes.normal,
          sortable: true,
          filterable: true
        },
        {
          id: 'Gender',
          displayKey: 'Gender',
          headerText: 'Gender',
          displayOrder: 5,
          columnSize: ColumnSizes.narrow,
          sortable: true,
          filterable: true
        },
        {
          id: 'Address',
          displayKey: 'Address',
          headerText: 'Address',
          displayOrder: 6,
          columnSize: ColumnSizes.wide,
          sortable: true,
          filterable: true
        },
        {
          id: 'City',
          displayKey: 'City',
          headerText: 'City',
          displayOrder: 7,
          columnSize: ColumnSizes.midwidth,
          sortable: true,
          filterable: true
        },
        {
          id: 'State',
          displayKey: 'State',
          headerText: 'State',
          displayOrder: 8,
          columnSize: ColumnSizes.narrow,
          sortable: true,
          filterable: true
        },
        {
          id: 'Zip',
          displayKey: 'Zip',
          headerText: 'Zip',
          displayOrder: 9,
          columnSize: ColumnSizes.extraNarrow,
          sortable: true,
          filterable: true
        },
        {
          id: 'Location',
          displayKey: 'Location',
          headerText: 'Location',
          displayOrder: 10,
          columnSize: ColumnSizes.wide,
          sortable: true,
          filterable: true
        },
        {
          id: 'Patient Phone Number',
          displayKey: 'Patient Phone Number',
          headerText: 'Patient Phone Number',
          displayOrder: 11,
          columnSize: ColumnSizes.normal,
          sortable: true,
          filterable: true
        },
        {
          id: 'Patient Financial Class',
          displayKey: 'Patient Financial Class',
          headerText: 'Patient Financial Class',
          displayOrder: 12,
          columnSize: ColumnSizes.wide,
          sortable: true,
          filterable: true
        },
        {
          id: 'Primary Insurance Carrier',
          displayKey: 'Primary Insurance Carrier',
          headerText: 'Primary Insurance Carrier',
          displayOrder: 13,
          columnSize: ColumnSizes.wide,
          sortable: true,
          filterable: true
        },
        {
          id: 'Primary Insurance Policy #',
          displayKey: 'Primary Insurance Policy #',
          headerText: 'Primary Insurance Policy #',
          displayOrder: 14,
          columnSize: ColumnSizes.normal,
          sortable: true,
          filterable: true
        },
        {
          id: 'Secondary Insurance Carrier',
          displayKey: 'Secondary Insurance Carrier',
          headerText: 'Secondary Insurance Carrier',
          displayOrder: 15,
          columnSize: ColumnSizes.wide,
          sortable: true,
          filterable: true
        },
        {
          id: 'Secondary Insurance Policy #',
          displayKey: 'Secondary Insurance Policy #',
          headerText: 'Secondary Insurance Policy #',
          displayOrder: 16,
          columnSize: ColumnSizes.normal,
          sortable: true,
          filterable: true
        },
        {
          id: 'Tertiary Insurance Carrier',
          displayKey: 'Tertiary Insurance Carrier',
          headerText: 'Tertiary Insurance Carrier',
          displayOrder: 17,
          columnSize: ColumnSizes.wide,
          sortable: true,
          filterable: true
        },
        {
          id: 'Tertiary Insurance Policy #',
          displayKey: 'Tertiary Insurance Policy #',
          headerText: 'Tertiary Insurance Policy #',
          displayOrder: 18,
          columnSize: ColumnSizes.normal,
          sortable: true,
          filterable: true
        }
      ]
    }
  }
};
