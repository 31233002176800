import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../modules/material.module';
import { OverflowMenuComponent } from './overflow-menu.component';

@NgModule({
  declarations: [OverflowMenuComponent],
  exports: [OverflowMenuComponent],
  imports: [CommonModule, MaterialModule]
})
export class OverflowMenuModule {}
