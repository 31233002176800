/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Patient view model information returned from a basic patient search
 */
export interface PatientSimpleIntegrationViewModel {
  datasetId?: string;
  accountNumber?: string | null;
  firstName?: string | null;
  middleName?: string | null;
  lastName?: string | null;
  firstLastName?: string | null;
  ssn?: string | null;
  medicalRecordNumber?: string | null;
  gender?: string | null;
  birthDate?: string;
}
