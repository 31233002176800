import { Injector, LOCALE_ID } from '@angular/core';
import { ColumnSizes, FieldSelectOption, FilterType } from 'components';
import { DateDisplayPipe } from 'projects/components/src/lib/shared/pipes/date-display/date-display.pipe';
import { Report } from '../../models/report';
import { UserLookupService } from '../../../../core/services/lookup/user-lookup.service';
import { ChargeGroupService } from './charge-group.service';
import { Validators } from '@angular/forms';
import { DiagnosisCodesLookupService, FinancialClassesLookupService, InsuranceCarriersLookupService, ProcedureCodesLookupService } from '../../../../core/services/lookup';

export const ChargeGroup: Report = {
  id: 'charge-group',
  label: 'Charge Group',
  textCriteria: {
    'Charge Group Min': '', 'Charge Group Max': '', 'Start Date': '', 'End Date': '', 'Users': '', 'Quantity': '', 'Include / Exclude Procedures': '', 'Include / Exclude Financial Class': '',
    'Include / Exclude Insurance Carrier': '', 'Include / Exclude Diagnosis Codes': '', 'Greater Than Or Less': '', 'Procedure Codes': '', 'Diagnosis Codes': '', 'Financial Classes': '', 'Insurance Carriers': ''
  },
  cleanFilterParams: {
    'insuranceCarrierIds': '', 'financialClassIds': '', 'procedureIds': '', 'diagnosisIds': '', 'userIds': ''
  },
  getReportTitle: () => {
    return ChargeGroup.label + ' ' + ChargeGroup.textCriteria['Start Date'] + ' - ' + ChargeGroup.textCriteria['End Date'];
  },
  getSearchCriteria: (injector: Injector) => [
    {
      controls: [
        {
          label: 'Exclude:',
          name: 'includeExcludeProcedures',
          class: 'form-span-2 toggle',
          type: 'toggle',
          checkedText: 'Include:',
          initial: true,
          toggleChanged: (event) => {
            ChargeGroup.textCriteria['Include / Exclude Procedures'] = event.checked === true ? 'I' : 'E'
          }
        },
        {
          label: 'Procedure Codes',
          name: 'procedureIds',
          apiService: injector.get(ProcedureCodesLookupService),
          class: 'form-span-10 multi-input',
          type: 'multiSelect',
          selectOptions: (event) => {
            let listValue = []
            let listText = []
            event.forEach((x: FieldSelectOption) => {
              listValue.push(x?.value)
              listText.push(x.label)
            })
            ChargeGroup.textCriteria['Procedure Codes'] = listText.join(',');
            ChargeGroup.cleanFilterParams['procedureIds'] = listValue
          }
        },
        {
          label: 'Exclude:',
          name: 'includeExcludeDiagnosis',
          class: 'form-span-2 toggle',
          type: 'toggle',
          checkedText: 'Include:',
          initial: true,
          toggleChanged: (event) => {
            ChargeGroup.textCriteria['Include / Exclude Diagnosis Codes'] = event.checked === true ? 'I' : 'E'
          }
        },
        {
          label: 'Diagnosis Codes',
          name: 'diagnosisIds',
          apiService: injector.get(DiagnosisCodesLookupService),
          excludeSelectAllOption: true,
          class: 'form-span-10 multi-input',
          type: 'multiSelect',
          selectOptions: (event) => {
            let listValue = []
            let listText = []
            event.forEach((x: FieldSelectOption) => {
              listValue.push(x?.value)
              listText.push(x.label)
            })
            ChargeGroup.textCriteria['Diagnosis Codes'] = listText.join(',')
            ChargeGroup.cleanFilterParams['diagnosisIds'] = listValue
          }
        },
        {
          label: 'Exclude:',
          name: 'includeExcludeFinancialClass',
          class: 'form-span-2 toggle',
          type: 'toggle',
          checkedText: 'Include:',
          initial: true,
          placeholder: 'Include / Exclude Financial Class',
          toggleChanged: (event) => {
            ChargeGroup.textCriteria['Include / Exclude Financial Class'] = event.checked === true ? 'I' : 'E'
          }
        },
        {
          label: 'Financial Class',
          name: 'financialClassIds',
          apiService: injector.get(FinancialClassesLookupService),
          class: 'form-span-10 multi-input',
          type: 'multiSelect',
          selectOptions: (event) => {
            let listValue = []
            let listText = []
            event.forEach((x: FieldSelectOption) => {
              listValue.push(x?.value)
              listText.push(x.label)
            })
            ChargeGroup.textCriteria['Financial Classes'] = listText.join(',')
            ChargeGroup.cleanFilterParams['financialClassIds'] = listValue
          }
        },
        {
          label: 'Exclude:',
          name: 'includeExcludeInsuranceCarrier',
          class: 'form-span-2 toggle',
          type: 'toggle',
          checkedText: 'Include:',
          initial: true,
          selectionChanged: (event) => {
            ChargeGroup.textCriteria['Include / Exclude Insurance Carrier'] = event.checked === true ? 'I' : 'E'
          }
        },
        {
          label: 'Insurance Carrier',
          name: 'insuranceCarrierIds',
          apiService: injector.get(InsuranceCarriersLookupService),
          class: 'form-span-10 multi-input',
          type: 'multiSelect',
          selectOptions: (event) => {
            let listValue = []
            let listText = []
            event.forEach((x: FieldSelectOption) => {
              listValue.push(x?.value)
              listText.push(x.label)
            })
            ChargeGroup.textCriteria['Insurance Carriers'] = listText.join(',')
            ChargeGroup.cleanFilterParams['insuranceCarrierIds'] = listValue
          }
        },
        {
          label: 'Posted Date Range',
          name: 'dateRange',
          type: 'dateRange',
          class: 'form-span-6',
          daterangeStartChanged: (event) => {
            ChargeGroup.textCriteria['Start Date'] = event.value.toLocaleDateString();
          },
          daterangeEndChanged: (event) => {
            ChargeGroup.textCriteria['End Date'] = event.value.toLocaleDateString();
          }
        },
        {
          label: '',
          name: 'dateRangeStart',
          type: 'noInput',
          class: 'display-none'
        },
        {
          label: '',
          name: 'dateRangeEnd',
          type: 'noInput',
          class: 'display-none'
        },
        {
          label: 'Charge Group Min',
          name: 'chargeGroupMin',
          class: 'form-span-3',
          type: 'number',
          min: 0,
          selectionChanged: (event) => {
            ChargeGroup.textCriteria['Charge Group Min'] = event;
          }
        },
        {
          label: 'Charge Group Max',
          name: 'chargeGroupMax',
          class: 'form-span-3',
          type: 'number',
          min: 0,
          selectionChanged: (event) => {
            ChargeGroup.textCriteria['Charge Group Max'] = event;
          }
        },
        {
          label: 'Quantity:',
          class: 'form-span-1',
          type: 'label'
        },
        {
          label: '>,<,or =',
          name: 'greaterThanOrLessThan',
          class: 'form-span-2',
          type: 'select',

          options: [
            {
              label: '',
              value: null
            },
            {
              label: '>',
              value: 'G'
            },
            {
              label: '<',
              value: 'L'
            },
            {
              label: '=',
              value: 'E'
            }
          ],
          initial: '',
          selectionChanged: (event) => {
            ChargeGroup.textCriteria['Greater Than Or Less'] = event.source.triggerValue;
          }
        },
        {
          label: 'Quantity',
          name: 'quantity',
          class: 'form-span-3',
          type: 'number',
          selectionChanged: (event) => {
            ChargeGroup.textCriteria['Quantity'] = event;
          }
        },
        {
          label: 'Users',
          name: 'userIds',
          apiService: injector.get(UserLookupService),
          type: 'multiSelect',
          class: 'form-span-6 multi-input',
          selectOptions: (event) => {
            let listValue: any[] = []
            let listText = []
            event.forEach((x) => {
              listValue.push(x.value)
              listText.push(x.label)
            })
            ChargeGroup.textCriteria['Users'] = listText.join(',')
            ChargeGroup.cleanFilterParams['userIds'] = listValue
          }
        },
      ]
    }
  ],
  getGridConfiguration: (injector: Injector) => {
    const locale = injector.get(LOCALE_ID);
    const moneyFormat = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });
    const datePipe: DateDisplayPipe = new DateDisplayPipe(locale);
    return {
      service: injector.get<any>(ChargeGroupService),
      columnDefinitions: [
        {
          id: 'Account',
          displayKey: 'Account',
          headerText: 'Account',
          displayOrder: 0,
          columnSize: ColumnSizes.normal,
          sortable: true,
          filterable: true
        },
        {
          id: 'Patient Name',
          displayKey: 'Patient Name',
          headerText: 'Patient Name',
          displayOrder: 1,
          columnSize: ColumnSizes.normal,
          sortable: true,
          filterable: true
        },
        {
          id: 'Service Date',
          displayKey: 'Service Date',
          headerText: 'Service Date',
          displayOrder: 2,
          columnSize: ColumnSizes.normal,
          sortable: true,
          filterable: true
        },
        {
          id: 'Procedure Code',
          displayKey: 'Procedure Code',
          headerText: 'Procedure Code',
          displayOrder: 5,
          columnSize: ColumnSizes.wide,
          sortable: true,
          filterable: true
        },
        {
          id: 'Quantity',
          displayKey: 'Quantity',
          headerText: 'Quantity',
          displayOrder: 6,
          columnSize: ColumnSizes.narrow,
          sortable: true,
          filterable: true,

        },
        {
          id: 'Modifiers',
          displayKey: 'Modifiers',
          headerText: 'Modifiers',
          displayOrder: 7,
          columnSize: ColumnSizes.narrow,
          sortable: true,
          filterable: true
        },
        {
          id: 'Procedure Amount',
          displayKey: 'Procedure Amount',
          headerText: 'Procedure Amount',
          displayOrder: 8,
          columnSize: ColumnSizes.narrow,
          sortable: true,
          filterable: true,
          formatter: moneyFormat.format
        },
        {
          id: 'Location',
          displayKey: 'Location',
          headerText: 'Location',
          displayOrder: 9,
          columnSize: ColumnSizes.wide,
          sortable: true,
          filterable: true
        },
        {
          id: 'Place of Service',
          displayKey: 'Place of Service',
          headerText: 'Place of Service',
          displayOrder: 10,
          columnSize: ColumnSizes.narrow,
          sortable: true,
          filterable: true
        },
        {
          id: 'Attending Provider',
          displayKey: 'Attending Provider',
          headerText: 'Attending Provider',
          displayOrder: 11,
          columnSize: ColumnSizes.normal,
          sortable: true,
          filterable: true
        },
        {
          id: 'Diagnosis',
          displayKey: 'Diagnosis',
          headerText: 'Diagnosis',
          displayOrder: 12,
          columnSize: ColumnSizes.narrow,
          sortable: true,
          filterable: true
        },
        {
          id: 'Insurance Carrier',
          displayKey: 'Insurance Carrier',
          headerText: 'Insurance Carrier',
          displayOrder: 13,
          columnSize: ColumnSizes.normal,
          sortable: true,
          filterable: true
        },
        {
          id: 'Financial Class',
          displayKey: 'Financial Class',
          headerText: 'Financial Class',
          displayOrder: 14,
          columnSize: ColumnSizes.normal,
          sortable: true,
          filterable: true
        },
        {
          id: 'Charge Group',
          displayKey: 'Charge Group',
          headerText: 'Charge Group',
          displayOrder: 15,
          columnSize: ColumnSizes.narrow,
          sortable: true,
          filterable: true,

        },
        {
          id: 'User',
          displayKey: 'User',
          headerText: 'User',
          displayOrder: 16,
          columnSize: ColumnSizes.normal,
          sortable: true,
          filterable: true
        },
        {
          id: 'Hash Value',
          displayKey: 'Hash Value',
          headerText: 'Hash Value',
          displayOrder: 17,
          columnSize: ColumnSizes.narrow,
          sortable: true,
          filterable: true,

        }
      ]
    };
  }
};
