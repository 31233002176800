<mat-vertical-stepper linear="false" #stepper (animationDone)="stepChanged(stepper)">
  <mat-step
    *ngFor="let formGroup of formGroupsControls; let i = index; trackBy: trackByIndex"
    editable="true"
    errorMessage="Missing information"
    [stepControl]="formGroup"
    [completed]="isFormCompleted"
  >
    <ng-template matStepLabel>
      <div
        class="lib-stepped-form__stepper-title"
        role="button"
        tabindex="0"
        (keyup.enter)="openStep(stepper, i)"
        (keyup.space)="openStep(stepper, i)"
      >
        {{ formGroupsDefinition[i].title }}
      </div>
    </ng-template>
    <form *ngIf="formGroup" [formGroup]="formGroup">
      <lib-form-group
        *ngFor="let fieldGroup of formGroupsDefinition[i].groups; trackBy: trackByIndex"
        [title]="fieldGroup.title"
        [stackedTitle]="true"
        [hide]="fieldGroup.hide"
        [disabled]="disabled"
        [formGroup]="formGroup"
        [formGroupDefinition]="fieldGroup.controls"
      ></lib-form-group>
    </form>
  </mat-step>
</mat-vertical-stepper>
