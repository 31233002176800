/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ProcedureResultDetailsViewModel } from './procedureResultDetailsViewModel';

export interface ProcedureResultViewModel {
  id?: string;
  datasetId?: string;
  procedureTransactionId?: string | null;
  patientId?: string | null;
  visitId?: string | null;
  procedureResultDetails?: Array<ProcedureResultDetailsViewModel> | null;
}
