import { Injectable } from '@angular/core';
import {
  EOBCodeService,
  EOBCodeViewModel,
  EOBCodeViewModelPagedModelResponse,
  EobHistoryViewModel,
  EobPaymentViewModel,
  EobRequestModel,
  PagedModelRequest
} from 'data';
import { Observable } from 'rxjs';
import { BaseWrapperService } from './base-wrapper.service';
import { SessionStorageService } from '../session-storage.service';

@Injectable({
  providedIn: 'root'
})
export class EobWrapperService extends BaseWrapperService {
  constructor(private service: EOBCodeService, protected sessionStorageService: SessionStorageService) {
    super();
  }

  apiV1EobcodeAllPost(pagedModelRequest?: PagedModelRequest): Observable<EOBCodeViewModelPagedModelResponse> {
    return this.service.apiV1EobcodeAllPost(this.getDataSetId(), pagedModelRequest);
  }

  apiV1EobcodeIdGet(id: string): Observable<EOBCodeViewModel> {
    return this.service.apiV1EobcodeIdGet(id, this.getDataSetId());
  }

  apiV1EobCodeAddPost(requestBody): Observable<EOBCodeViewModel> {
    return this.service.apiV1EobcodeAddPost(this.getDataSetId(), requestBody);
  }

  apiV1EobCodePatchIdPath(id: string, operation): Observable<EOBCodeViewModel> {
    return this.service.apiV1EobcodePatchIdPatch(id, this.getDataSetId(), operation);
  }

  apiV1EobCodeIdDelete(id: string): Observable<EOBCodeViewModel> {
    return this.service.apiV1EobcodeIdDelete(id, this.getDataSetId());
  }

  apiV1EobcodeHistoryIdGet(id: string): Observable<Array<EobHistoryViewModel>> {
    return this.service.apiV1EobcodeHistoryIdGet(id, this.getDataSetId());
  }

  apiV1EobcodeAllPaymentPost(request?: EobRequestModel): Observable<Array<EobPaymentViewModel>> {
    return this.service.apiV1EobcodeAllPaymentPost(this.getDataSetId(), request);
  }
}
