/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface PatientSearchResultViewModel {
  datasetId?: string;
  patientId?: string;
  patientFirstName?: string | null;
  patientMiddleName?: string | null;
  patientLastName?: string | null;
  readonly patientName?: string | null;
  birthDateMatch?: boolean;
  birthDate?: string;
  nameMatch?: boolean;
  addressMatch?: boolean;
  patientAddress1?: string | null;
  patientCity?: string | null;
  patientState?: string | null;
  readonly patientAddress?: string | null;
  ssnMatch?: boolean;
  mrnMatch?: boolean;
  mrn?: string | null;
  accountNumberMatch?: boolean;
  accountNumber?: string | null;
  employerMatch?: boolean;
  patientEmployerName?: string | null;
  guarantorNameMatch?: boolean;
  guarantorEmployerMatch?: boolean;
  guarantorPhoneMatch?: boolean;
  guarantorAddressMatch?: boolean;
  guarantorFirstName?: string | null;
  guarantorMiddleName?: string | null;
  guarantorLastName?: string | null;
  readonly guarantorName?: string | null;
  guarantorEmployerName?: string | null;
  guarantorAddress1?: string | null;
  guarantorAddress2?: string | null;
  readonly guarantorAddress?: string | null;
  guarantorCity?: string | null;
  guarantorState?: string | null;
  guarantorHomePhone?: string | null;
  guarantorMobilePhone?: string | null;
  guarantorWorkPhone?: string | null;
  readonly guarantorPhone?: string | null;
  primaryInsuranceMatch?: boolean;
  secondaryInsuranceMatch?: boolean;
  tertiaryInsuranceMatch?: boolean;
  readonly insuranceMatchType?: string | null;
  policyNumber?: string | null;
  carrierName?: string | null;
  readonly insurance?: string | null;
  notesMatch?: boolean;
  notesMessage?: string | null;
  diagnosisMatch?: boolean;
  diagnosisCode?: string | null;
  diagnosisName?: string | null;
  readonly diagnosis?: string | null;
  readonly score?: number;
  nameScore?: number;
  nameLength?: number;
}
