/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ARTransactionDetailEOBViewModel } from './aRTransactionDetailEOBViewModel';
import { ARTransactionDetailRemarkViewModel } from './aRTransactionDetailRemarkViewModel';

export interface ARTransactionDetailSimpleViewModel {
  id?: string;
  allowedAmount?: number;
  paymentAmount?: number;
  adjustmentAmount?: number;
  adjustment2Amount?: number;
  deductibleAmount?: number;
  copayAmount?: number;
  coinsuranceAmount?: number;
  interestAdjustmentAmount?: number;
  insurance?: string | null;
  icn?: string | null;
  checkNumber?: string | null;
  paymentType?: string | null;
  paymentCodeCode?: string | null;
  paymentCodeName?: string | null;
  adjustmentCode?: string | null;
  adjustmentCodeName?: string | null;
  adjustmentCode2?: string | null;
  batchType?: string | null;
  statusAccepted?: string | null;
  batchNumber?: string | null;
  batchId?: string | null;
  createdDate?: string | null;
  postedDate?: string | null;
  arTransactionDetailEOBCodes?: Array<ARTransactionDetailEOBViewModel> | null;
  arTransactionDetailRemarkCodes?: Array<ARTransactionDetailRemarkViewModel> | null;
}
