import { AfterViewInit, ChangeDetectorRef, Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { CustomValidators, DialogContent, FormGroupDefinition } from 'components';
import { Observable, Subscription } from 'rxjs';
import { first, map, take } from 'rxjs/operators';
import { pwkReportTypeCodes } from '@core/services/static-catalogues/pwk-report-type-codes';
import { compare } from 'fast-json-patch';
import { Store } from '@ngrx/store';
import { NotificationService } from '@core/services/notification.service';
import { CountryCodesLookupService, StateCodesLookupService } from '@core/services/lookup';
import { DatasetWrapperService } from '@core/services/service-wrappers/dataset-wrapper.service';
import { selectImplementation, selectImplementationDetails } from '../state/implementation.selectors';
import { ImplementationWrapperService } from '@core/services/service-wrappers/implementation-wrapper.service';
import * as ImplementationActions from '../state/implementation.actions';
import { Router } from '@angular/router';
import { ImplementationState } from '../state/implementation.state';
import { DatasetService } from 'data';
import { ImplementationDetailViewModel } from 'projects/data/src/lib/model/implementationDetailViewModel';
import { ImplementationViewModel } from 'projects/data/src/lib/model/implementationViewModel';

@Component({
  selector: 'app-practice-information',
  templateUrl: './practice-information.component.html',
  styleUrls: ['./practice-information.component.scss']
})
export class PracticeInformationComponent implements OnInit, AfterViewInit, OnDestroy {
  formGroup = new UntypedFormGroup({});
  formInitialized = false;
  selectedDatasetId = null;
  isDeletedResponse: boolean;
  isDeletedFlag: boolean = false;
  saveObservable: Observable<any>;
  dataSource: any;
  saving = false;
  gonext = false;
  storeObject: any;
  currentDataSetId: string;

  formDefinitions: FormGroupDefinition[];
  getFormDefinitions(): FormGroupDefinition[] {
    return [
      {
        hideTitle: true,
        controls: [
          {
            label: 'Practice Name',
            name: 'name',
            type: 'text',
            class: 'form-span-6',
            validators: Validators.required
          },
          {
            label: 'Acronym',
            name: 'acronym',
            type: 'text',
            class: 'form-span-3',
            validators: Validators.required
          },
          {
            label: 'Phone Number',
            name: 'phoneNumber',
            type: 'phone',
            class: 'form-span-3'
          },
          {
            label: 'NPI',
            name: 'npi',
            type: 'text',
            class: 'form-span-3',
            validators: [Validators.required, Validators.maxLength(10), Validators.minLength(10)]
          },
          {
            label: 'Tax ID',
            name: 'taxIdNumber',
            type: 'text',
            class: 'form-span-3'
          },
          {
            label: 'Taxonomy',
            name: 'taxonomy',
            type: 'text',
            class: 'form-span-3',
            validators: [
              Validators.required,
              CustomValidators.taxonomyLengthValidator,
              CustomValidators.taxonomyPatternValidator
            ]
          },
          {
            label: 'State License Number',
            name: 'stateLicenseNumber',
            type: 'text',
            class: 'form-span-3'
          },
          {
            label: 'Email',
            name: 'contactEmailAddress',
            type: 'email',
            class: 'form-span-6'
          },
          {
            label: 'Website URL',
            name: 'websiteUrl',
            type: 'text',
            class: 'form-span-6'
          },
          {
            label: 'Maximum Delinquency Code',
            name: 'maxDelinquencyCode',
            type: 'number',
            class: 'form-span-6',
            validators: [Validators.required, Validators.min(0)]
          },
          {
            label: 'Active',
            name: 'active',
            type: 'checkbox',
            class: 'form-span-3',
            initial: true
          }
        ]
      },
      {
        hideTitle: true,
        controls: [
          {
            label: 'Billing Provider',
            name: 'billingProvider',
            type: 'label',
            class: 'form-span-12'
          },
          {
            label: 'Entity Type',
            name: 'entityType',
            type: 'select',
            options: ['Non-Person', 'Person'],
            class: 'form-span-3',
            validators: Validators.required
          },
          {
            label: 'Billing Provider NPI',
            name: 'billingProviderNPI',
            type: 'text',
            class: 'form-span-3',
            validators: [Validators.required, Validators.maxLength(10), Validators.minLength(10)]
          },
          {
            type: 'label',
            class: 'form-span-6'
          },
          {
            label: 'Billing Org or Last Name',
            name: 'billingProviderLastOrgName',
            type: 'text',
            class: 'form-span-3',
            validators: [Validators.required]
          },
          {
            label: 'Billing Provider First Name',
            name: 'billingProviderFirstName',
            type: 'text',
            class: 'form-span-3',
            validators: [Validators.required]
          },
          {
            label: 'Billing Provider Middle Name',
            name: 'billingProviderMiddleName',
            type: 'text',
            class: 'form-span-3'
          },
          {
            label: 'Billing Provider Suffix',
            name: 'billingProviderSuffix',
            type: 'text',
            class: 'form-span-3'
          }
        ]
      },
      {
        hideTitle: true,
        controls: [
          {
            label: 'Claim Attachment Defaults',
            name: 'claimAttachments',
            type: 'label',
            class: 'form-span-12'
          },
          {
            label: 'Attachment Type',
            name: 'defaultPwkReportTypeCode',
            type: 'select',
            options: pwkReportTypeCodes,
            class: 'form-span-6'
          },
          {
            label: 'Transmission Code',
            name: 'defaultPwkTransmissionCode',
            type: 'select',
            options: [
              { label: 'Available on Request at Provider Site', value: 'AA' },
              { label: 'By Mail', value: 'BM' },
              { label: 'Electronically Only', value: 'EL' },
              { label: 'Email', value: 'EM' },
              { label: 'File Transfer', value: 'FT' },
              { label: 'By Fax', value: 'FX' }
            ],
            class: 'form-span-6'
          }
        ]
      },
      {
        hideTitle: true,
        controls: [
          {
            label: 'Address',
            name: 'address',
            type: 'label',
            class: 'form-span-12'
          },
          {
            label: 'Address Line 1',
            name: 'address1',
            type: 'text',
            class: 'form-span-6',
            validators: [Validators.required, CustomValidators.providerAddressValidator]
          },
          {
            label: 'Address Line 2',
            name: 'address2',
            type: 'text',
            class: 'form-span-6'
          },
          {
            label: 'City',
            name: 'city',
            type: 'text',
            class: 'form-span-3',
            validators: Validators.required
          },
          {
            label: 'State',
            name: 'stateCode',
            type: 'select',
            apiService: this.stateCodesLookupService,
            class: 'form-span-3'
          },
          {
            label: 'Zip',
            name: 'zip',
            type: 'zipCode',
            class: 'form-span-3',
            validators: [Validators.required, CustomValidators.extendedZipCodePatternValidator]
          },
          {
            label: 'Country',
            name: 'countryCode',
            type: 'select',
            apiService: this.countryService,
            class: 'form-span-3',
            initial: 'US'
          }
        ]
      },
      {
        hideTitle: true,
        controls: [
          {
            label: 'Mailing Address',
            name: 'mailingAddress',
            type: 'label',
            class: 'form-span-12'
          },
          {
            label: 'Mailing Name',
            name: 'mailingName',
            type: 'text',
            class: 'form-span-12'
          },
          {
            label: 'Address Line 1',
            name: 'mailingAddress1',
            type: 'text',
            class: 'form-span-6',
            validators: [CustomValidators.providerAddressValidator]
          },
          {
            label: 'Address Line 2',
            name: 'mailingAddress2',
            type: 'text',
            class: 'form-span-6'
          },
          {
            label: 'City',
            name: 'mailingCity',
            type: 'text',
            class: 'form-span-3'
          },
          {
            label: 'State',
            name: 'mailingStateCode',
            type: 'select',
            apiService: this.stateCodesLookupService,
            class: 'form-span-3'
          },
          {
            label: 'Zip',
            name: 'mailingZip',
            type: 'zipCode',
            class: 'form-span-3',
            validators: [CustomValidators.extendedZipCodePatternValidator]
          },
          {
            label: 'Country',
            name: 'mailingCountryCode',
            type: 'select',
            apiService: this.countryService,
            class: 'form-span-3',
          }
        ]
      }
    ];
  }

  constructor(
    private notificationService: NotificationService,
    private service: DatasetWrapperService,
    private stateCodesLookupService: StateCodesLookupService,
    private datasetService: DatasetService,
    private cd: ChangeDetectorRef,
    private store: Store<ImplementationState>,
    private implementationService: ImplementationWrapperService,
    private countryService: CountryCodesLookupService,
    private router: Router
  ) {
    this.formDefinitions = this.getFormDefinitions();
    this.formInitialized = true;
  }

  private subscriptions: Subscription[] = [];
  public implementationDetails$ = this.store.select(selectImplementationDetails);
  public implementation$ = this.store.select(selectImplementation);
  private implementation : ImplementationViewModel = null;
  private implementationDetail: ImplementationDetailViewModel = null;
  private nextImplementationDetail: ImplementationDetailViewModel = null;
  private STEP_CODE = 'practice-information';
  protected saved = false;

  ngOnInit(): void {
    this.setupForm();
    this.subscriptions.push(
      this.implementation$.subscribe((i) => {
        this.implementation = i;
        this.implementationDetail = this.implementation?.details.find(d => d.implementationStepCode == this.STEP_CODE);
        this.nextImplementationDetail = this.implementation?.details.find(d => d.order == this.implementationDetail.order + 1);
        if (i && i.datasetId) {
          this.selectedDatasetId = i.datasetId;
          this.loadDataset();
        }
      })
    );
    this.store.dispatch(ImplementationActions.updateImplementationStep({ stepCode: this.STEP_CODE }));
    this.billingProviderValidator();
  }

  ngAfterViewInit(): void {
    this.billingProviderValidator();
    this.cd.detectChanges();
  }
  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  setupForm() {
    this.formDefinitions.forEach((sc) => {
      sc.controls.forEach((control) => {
        if (control.type !== 'empty' && control.type !== 'label') {
          this.formGroup.addControl(control.name, new UntypedFormControl(control.initial ?? '', control.validators));
        }
      });
    });
    this.formInitialized = true;
  }

  loadDataset() {
    if (this.selectedDatasetId && this.selectedDatasetId !== '') {
      this.datasetService
        .apiV1DatasetDetailsIdGet(this.selectedDatasetId)
        .pipe(first())
        .subscribe((result) => {
          this.formGroup.patchValue(result);
          this.formGroup.patchValue({ entityType: result.personEntity === true ? 'Person' : 'Non-Person' });
          this.formGroup.patchValue({ active: !result.inactive });
          if (this.formGroup.valid) this.saved = true;
        });
    }
    if (this.selectedDatasetId === this.currentDataSetId) {
      this.formGroup.get('active').disable();
    }
  }

  save() {
    if (this.formGroup.valid) {
      this.saving = true;
      const formData = {
        name: this.formGroup.get('name').value,
        acronym: this.formGroup.get('acronym').value,
        address1: this.formGroup.get('address1').value,
        address2: this.formGroup.get('address2').value,
        city: this.formGroup.get('city').value,
        countryCode: this.formGroup.get('countryCode').value,
        stateCode: this.formGroup.get('stateCode').value,
        zip: this.formGroup.get('zip').value,
        inactive: false,
        phoneNumber: this.formGroup.get('phoneNumber').value,
        contactEmailAddress: this.formGroup.get('contactEmailAddress').value,
        stateLicenseNumber: this.formGroup.get('stateLicenseNumber').value,
        mailingAddress1: this.formGroup.get('mailingAddress1').value,
        mailingAddress2: this.formGroup.get('mailingAddress2').value,
        mailingCity: this.formGroup.get('mailingCity').value,
        mailingStateCode: this.formGroup.get('mailingStateCode').value,
        mailingCountryCode: this.formGroup.get('mailingCountryCode').value,
        mailingZip: this.formGroup.get('mailingZip').value,
        taxIdNumber: this.formGroup.get('taxIdNumber').value,
        npi: this.formGroup.get('npi').value,
        taxonomy: this.formGroup.get('taxonomy').value,
        personEntity: this.formGroup.get('entityType').value === 'Non-Person' ? false : true,
        billingProviderLastOrgName: this.formGroup.get('billingProviderLastOrgName').value,
        billingProviderFirstName: this.formGroup.get('billingProviderFirstName').value,
        billingProviderMiddleName: this.formGroup.get('billingProviderMiddleName').value,
        billingProviderSuffix: this.formGroup.get('billingProviderSuffix').value,
        billingProviderNPI: this.formGroup.get('billingProviderNPI').value,
        maxDelinquencyCode: this.formGroup.get('maxDelinquencyCode').value,
        defaultPwkReportTypeCode: this.formGroup.get('defaultPwkReportTypeCode').value,
        defaultPwkTransmissionCode: this.formGroup.get('defaultPwkTransmissionCode').value
      };

      this.service.apiV1DatasetPatchIdPatch(this.selectedDatasetId, compare({}, formData))
        .subscribe(
          (response) => {
              this.notificationService.success('Practice Information Updated');
              this.saving = false;
              this.saved = true;
            },
          (err) => { this.notificationService.error('Error Updating Practice Information'); this.saving = false; }
        );
    }
  }

  next() {
    if (this.implementationDetail.status == "InProgress") {
      this.gonext = true;
      this.implementationService
      .apiV1ImplementationCompleteStepCodePost(this.STEP_CODE)
      .subscribe(
        () => {
          this.gonext = false;
          this.router.navigateByUrl('/implementation/' + this.nextImplementationDetail.implementationStepCode);
        },
        () => this.gonext = false
      );
    }
    else {
      this.router.navigateByUrl('/implementation/' + this.nextImplementationDetail.implementationStepCode);
    }
  }

  billingProviderValidator() {
    let input = this.formGroup.get('entityType');
    let lastName = this.formGroup.get('billingProviderLastOrgName');
    let firstName = this.formGroup.get('billingProviderFirstName');

    if (input.value === 'Person') {
      lastName.setValidators([Validators.required]);
      lastName.updateValueAndValidity();
      firstName.setValidators([Validators.required]);
      firstName.updateValueAndValidity();
      if (this.formGroup.invalid) {
        this.saving = false;
      }
    }
    input?.valueChanges?.subscribe((providerType) => {
      if (providerType === 'Person') {
        lastName.setValidators([Validators.required]);
        lastName.updateValueAndValidity();
        firstName.setValidators([Validators.required]);
        firstName.updateValueAndValidity();
        if (this.formGroup.invalid) {
          this.saving = false;
        }
      } else {
        lastName.setValidators([]);
        lastName.updateValueAndValidity();
        firstName.setValidators([]);
        firstName.updateValueAndValidity();
        if (this.formGroup.invalid) {
          this.saving = false;
        }
      }
    });
  }
}
