/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface UpdateGuarantorHoldingViewModel {
  id?: string;
  datasetId?: string;
  sendingFacilityId?: string;
  lastName?: string | null;
  firstName?: string | null;
  middleName?: string | null;
  suffix?: string | null;
  birthDate?: string | null;
  sex?: string | null;
  ssn?: string | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  stateCode?: string | null;
  zip?: string | null;
  countryCode?: string | null;
  homePhone?: string | null;
  workPhone?: string | null;
  mobilePhone?: string | null;
  email?: string | null;
  useAlternateMailingAddress?: boolean;
  alternateName?: string | null;
  alternateAddress1?: string | null;
  alternateAddress2?: string | null;
  alternateCity?: string | null;
  alternateStateCode?: string | null;
  alternateZip?: string | null;
  alternateCountryCode?: string | null;
  employerName?: string | null;
  employerAddress1?: string | null;
  employerAddress2?: string | null;
  employerCity?: string | null;
  employerStateCode?: string | null;
  employerZip?: string | null;
  employerCountryCode?: string | null;
  employerPhone?: string | null;
  employerCode?: string | null;
  emergencyContactFirstName?: string | null;
  emergencyContactLastName?: string | null;
  emergencyContactRelation?: string | null;
  emergencyContactPhone?: string | null;
  driverLicenseNumber?: string | null;
  overrideStatementMessage?: string | null;
  optOutTextMessage?: boolean;
  optOutEmail?: boolean;
  isDeleted?: boolean;
}
