import { Injector, LOCALE_ID } from '@angular/core';
import { Validators } from '@angular/forms';
import { ReportWrapperService } from '../../../../core/services/service-wrappers/report-wrapper.service';
import { BodyClasses, ColumnDefinition, ColumnSizes, FieldSelectOption, FilterType } from 'components';
import { DateDisplayPipe } from 'projects/components/src/lib/shared/pipes/date-display/date-display.pipe';
import { Report } from '../../models/report';
import { UserLookupService } from '@core/services/lookup/user-lookup.service';
import { UserProductivityReportService } from './user-productivity-report.service.';

const percentFormat = new Intl.NumberFormat('en-US', { style: "percent", minimumFractionDigits: 2, maximumFractionDigits: 2});
export const UserProductivityReport: Report = {
  id: 'user-productivity',
  label: 'User Productivity Report',
  textCriteria: {
    'Start Date': '', 'End Date': '', 'Users': '', 'Group By Dataset': '', 'Current Dataset Only': ''
  },
  cleanFilterParams: {
    'userIds': ''
  },
  getReportTitle: () => {
    return UserProductivityReport.label + ' ' + UserProductivityReport.textCriteria['Start Date'] + ' - ' + UserProductivityReport.textCriteria['End Date'];
  },
  getSearchCriteria: (injector: Injector) => [
    {
      title: '',
      controls: [
        {
          label: 'Activity Date Range',
          name: 'dateRange',
          type: 'dateRange',
          class: 'form-span-12',
          daterangeStartChanged: (event) => {
            UserProductivityReport.textCriteria['Start Date'] = event.value.toLocaleDateString();
          },
          daterangeEndChanged: (event) => {
            UserProductivityReport.textCriteria['End Date'] = event.value.toLocaleDateString();
          }
        },
        {
          label: '',
          name: 'dateRangeStart',
          type: 'noInput',
          class: 'display-none',
          validators: Validators.required
        },
        {
          label: '',
          name: 'dateRangeEnd',
          type: 'noInput',
          class: 'display-none',
          validators: Validators.required
        },
        {
          matLabel: 'Users',
          name: 'userIds',
          apiService: injector.get(UserLookupService),
          class: 'form-span-12 multi-input',
          type: 'multiSelect',
          placeholder: 'Users',
          selectOptions: (event: []) => {
            let listValue = []
            let listText = []
            event.forEach((x: FieldSelectOption) => {
              listValue.push(x?.value)
              listText.push(x.label)
            })
            UserProductivityReport.textCriteria['Users'] = listText.join(',');
            UserProductivityReport.cleanFilterParams['userIds'] = listValue
          },
        },
        {
          label: 'Split Report By Dataset',
          name: 'groupByDataset',
          class: 'form-span-12 short-checkbox',
          type: 'checkbox',
          initial: true,
          checkedChanged: (event) => {
            UserProductivityReport.textCriteria['Group By Dataset'] = event.checked === true ? 'Yes' :'No';
            if (event.checked)  {
              UserProductivityReport.formGroup.controls['currentDatasetOnly'].setValue(false);
              UserProductivityReport.textCriteria['Current Dataset Only'] = 'No';
            }
            selectedColumnDefinitions = event.checked ? UserProductivityReport.gridColumnDefinitions[1] : UserProductivityReport.gridColumnDefinitions[0];
         }
        },
        {
          label: 'Include Current Dataset Only',
          name: 'currentDatasetOnly',
          class: 'form-span-12 short-checkbox',
          type: 'checkbox',
          initial: false,
          checkedChanged: (event) => {
            UserProductivityReport.textCriteria['Current Dataset Only'] = event.checked === false ? 'Yes' :'No';
            selectedColumnDefinitions = event.checked ? UserProductivityReport.gridColumnDefinitions[0] : UserProductivityReport.gridColumnDefinitions[1];
            if (event.checked)  {
              UserProductivityReport.formGroup.controls['groupByDataset'].setValue(false);
              UserProductivityReport.textCriteria['Group By Dataset'] = 'No';
            }
         }
        }
      ]
    }
  ],
  gridColumnDefinitions: [
    [
      {
        id: 'user_name',
        displayKey: 'user_name',
        headerText: 'User Name',
        displayOrder: 0,
        columnSize: ColumnSizes.normal,
        sortable: true,
        filterable: true
      },
      {
        id: 'patient',
        displayKey: 'patient',
        headerText: 'Patients',
        displayOrder: 1,
        columnSize: ColumnSizes.narrow,
        sortable: true,
        filterable: true
      },
      {
        id: 'patient_pct',
        displayKey: 'patient_pct',
        headerText: 'Patient %',
        displayOrder: 2,
        columnSize: ColumnSizes.narrow,
        sortable: true,
        filterable: true,
        formatter: percentFormat.format
      },
      {
        id: 'charge',
        displayKey: 'charge',
        headerText: 'Charges',
        displayOrder: 3,
        columnSize: ColumnSizes.narrow,
        sortable: true,
        filterable: true
      },
      {
        id: 'charge_pct',
        displayKey: 'charge_pct',
        headerText: 'Charge %',
        displayOrder: 4,
        columnSize: ColumnSizes.narrow,
        sortable: true,
        filterable: true,
        formatter: percentFormat.format
      },
      {
        id: 'payment',
        displayKey: 'payment',
        headerText: 'Payments',
        displayOrder: 5,
        columnSize: ColumnSizes.narrow,
        sortable: true,
        filterable: true
      },
      {
        id: 'payment_pct',
        displayKey: 'payment_pct',
        headerText: 'Payment %',
        displayOrder: 6,
        columnSize: ColumnSizes.narrow,
        sortable: true,
        filterable: true,
        formatter: percentFormat.format
      },
      {
        id: 'adjustment',
        displayKey: 'adjustment',
        headerText: 'Adjustments',
        displayOrder: 7,
        columnSize: ColumnSizes.narrow,
        sortable: true,
        filterable: true
      },
      {
        id: 'adjustment_pct',
        displayKey: 'adjustment_pct',
        headerText: 'Adjustment %',
        displayOrder: 8,
        columnSize: ColumnSizes.narrow,
        sortable: true,
        filterable: true,
        formatter: percentFormat.format
      },
      {
        id: 'notes',
        displayKey: 'notes',
        headerText: 'Notes',
        displayOrder: 9,
        columnSize: ColumnSizes.narrow,
        sortable: true,
        filterable: true
      },
      {
        id: 'notes_pct',
        displayKey: 'notes_pct',
        headerText: 'Notes %',
        displayOrder: 10,
        columnSize: ColumnSizes.narrow,
        sortable: true,
        filterable: true,
        formatter: percentFormat.format
      }
    ],
    [
      {
        id: 'dataset_name',
        displayKey: 'dataset_name',
        headerText: 'Dataset Name',
        displayOrder: 0,
        columnSize: ColumnSizes.wide,
        sortable: true,
        filterable: true
      },
      {
        id: 'user_name',
        displayKey: 'user_name',
        headerText: 'User Name',
        displayOrder: 1,
        columnSize: ColumnSizes.normal,
        sortable: true,
        filterable: true
      },
      {
        id: 'patient',
        displayKey: 'patient',
        headerText: 'Patients',
        displayOrder: 2,
        columnSize: ColumnSizes.narrow,
        sortable: true,
        filterable: true
      },
      {
        id: 'patient_pct',
        displayKey: 'patient_pct',
        headerText: 'Patient %',
        displayOrder: 3,
        columnSize: ColumnSizes.narrow,
        sortable: true,
        filterable: true,
        formatter: percentFormat.format
      },
      {
        id: 'charge',
        displayKey: 'charge',
        headerText: 'Charges',
        displayOrder: 4,
        columnSize: ColumnSizes.narrow,
        sortable: true,
        filterable: true
      },
      {
        id: 'charge_pct',
        displayKey: 'charge_pct',
        headerText: 'Charge %',
        displayOrder: 5,
        columnSize: ColumnSizes.narrow,
        sortable: true,
        filterable: true,
        formatter: percentFormat.format
      },
      {
        id: 'payment',
        displayKey: 'payment',
        headerText: 'Payments',
        displayOrder: 6,
        columnSize: ColumnSizes.narrow,
        sortable: true,
        filterable: true
      },
      {
        id: 'payment_pct',
        displayKey: 'payment_pct',
        headerText: 'Payment %',
        displayOrder: 7,
        columnSize: ColumnSizes.narrow,
        sortable: true,
        filterable: true,
        formatter: percentFormat.format
      },
      {
        id: 'adjustment',
        displayKey: 'adjustment',
        headerText: 'Adjustments',
        displayOrder: 8,
        columnSize: ColumnSizes.narrow,
        sortable: true,
        filterable: true
      },
      {
        id: 'adjustment_pct',
        displayKey: 'adjustment_pct',
        headerText: 'Adjustment %',
        displayOrder: 9,
        columnSize: ColumnSizes.narrow,
        sortable: true,
        filterable: true,
        formatter: percentFormat.format
      },
      {
        id: 'notes',
        displayKey: 'notes',
        headerText: 'Notes',
        displayOrder: 10,
        columnSize: ColumnSizes.narrow,
        sortable: true,
        filterable: true
      },
      {
        id: 'notes_pct',
        displayKey: 'notes_pct',
        headerText: 'Notes %',
        displayOrder: 11,
        columnSize: ColumnSizes.narrow,
        sortable: true,
        filterable: true,
        formatter: percentFormat.format
      }
    ]
  ],
  getGridConfiguration: (injector: Injector) => {
    const locale = injector.get(LOCALE_ID);
    const datePipe: DateDisplayPipe = new DateDisplayPipe(locale);
    const moneyFormat = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });
    return {
      service: injector.get<any>(UserProductivityReportService),
      columnDefinitions: selectedColumnDefinitions
    };
  }
}
let selectedColumnDefinitions: ColumnDefinition[] = UserProductivityReport.gridColumnDefinitions[0];
