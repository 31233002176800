import { Injectable, OnDestroy } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { SteppedFormGroupDefinition } from 'components';
import { Subscription } from 'rxjs';

@Injectable()
export class BaseFormComponent implements OnDestroy {
  public formGroups: UntypedFormGroup;
  public formGroupsDefinition: SteppedFormGroupDefinition[];
  protected subscriptions: Subscription[] = [];

  getGroup(section: string): UntypedFormGroup {
    return this.formGroups.controls[section] as UntypedFormGroup;
  }
  get formGroupsControls(): UntypedFormGroup[] {
    if (this.formGroups?.controls) {
      return Object.keys(this.formGroups?.controls)
        .filter((ctrlKey) => {
          const found = this.formGroupsDefinition.find(({ key }) => key === ctrlKey);
          return found && !found.hide;
        })
        .map((ctrlKey) => this.formGroups.controls[ctrlKey]) as UntypedFormGroup[];
    }
  }

  get isFormCompleted(): boolean {
    return this.formGroupsControls?.every((formGroup) => formGroup.valid);
  }

  getControl(section: string, control: string): UntypedFormControl {
    return (this.formGroups.controls[section] as UntypedFormGroup).controls[control] as UntypedFormControl;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
