<article class="procedure-activity" *ngIf="transactions?.length > 0">
  <header class="procedure-activity__header h5 h5--bold">Procedure Activity</header>
  <div class="procedure-activity__table">
    <header class="procedure-activity__table-header">
      <div>Received</div>
      <div>Insurance</div>
      <div>ICN</div>
      <div>Check #</div>
      <div class="procedure-activity__currency">Allowed</div>
      <div class="procedure-activity__currency">Paid</div>
      <div class="procedure-activity__currency">Adjusted 1</div>
      <div class="procedure-activity__currency">Adjusted 2</div>
      <div class="procedure-activity__currency">Deductible</div>
      <div class="procedure-activity__currency">Co-Ins.</div>
      <div class="procedure-activity__currency">Copay</div>
    </header>
    <div class="procedure-activity__table-row" *ngFor="let transaction of transactions; trackBy: trackById">
      <div>
        {{ transaction?.arTransaction.transactionDate | dateDisplay }}
      </div>
      <div>{{ transaction?.arTransaction.insurance?.insuranceCarrier?.carrierName || 'Self Pay' }}</div>
      <div>{{ transaction?.arTransaction?.internalControlNumber }}</div>
      <div>{{ transaction?.arTransaction?.checkNumber }}</div>
      <div class="procedure-activity__currency">{{ transaction.allowedAmount | currency }}</div>
      <div class="procedure-activity__currency">{{ transaction.paymentAmount | currency }}</div>
      <div class="procedure-activity__currency">{{ transaction.adjustmentAmount | currency }}</div>
      <div class="procedure-activity__currency">{{ transaction.adjustment2Amount ?? 0 | currency }}</div>
      <div class="procedure-activity__currency">{{ transaction.deductibleAmount | currency }}</div>
      <div class="procedure-activity__currency">{{ transaction.coinsuranceAmount | currency }}</div>
      <div class="procedure-activity__currency">{{ transaction.copayAmount | currency }}</div>
    </div>
  </div>
</article>
