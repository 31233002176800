/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface EcsReceiverConfigurationViewModel {
  id?: string | null;
  datasetId?: string | null;
  interchangeId?: string | null;
  environment?: string | null;
  transactionSetCode?: string | null;
  versionIdentifierCode?: string | null;
  receiverName?: string | null;
  receiverCode?: string | null;
  ftpSiteUrl?: string | null;
  downloadPath?: string | null;
  uploadPath?: string | null;
  fileExtension?: string | null;
}
