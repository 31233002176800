/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface PendingBatchTransactionViewModel {
  insuranceId?: string | null;
  insuranceCode?: string | null;
  arTransactionHoldingId?: string | null;
  batchId?: string | null;
  batchNumber?: number;
  allowedAmount?: number;
  paymentAmount?: number;
  adjustmentAmount?: number;
  adjustment2Amount?: number;
  deductibleAmount?: number;
  copayAmount?: number;
  coinsuranceAmount?: number;
  checkNumber?: string | null;
  internalControlNumber?: string | null;
}
