import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

// TODO: Marked for deletion
@Component({
  selector: 'lib-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchBarComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
