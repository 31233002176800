export enum BodyClasses {
  alignRight = 'align-right',
  alignLeft = 'align-left',
  alignCenter = 'align-center'
}

export enum ColumnSizes {
  ultraWide = 'grid-col-ultra-wide',
  superWide = 'grid-col-super-wide',
  extraWide = 'grid-col-x-wide',
  wide = 'grid-col-wide',
  normal = 'grid-col-normal',
  date = 'grid-col-date-width',
  midwidth = 'grid-col-midwidth',
  narrow = 'grid-col-narrow',
  extraNarrow = 'grid-col-x-narrow',
  fixedColumn = 'grid-col-xs-fixed',
  fixedTiny = 'grid-col-fixed-tiny',
  tiny = 'grid-col-tiny'
}
