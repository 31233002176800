<section class="lib-detail-layout">
  <header class="lib-detail-layout__header">
    <ng-container *ngTemplateOutlet='detailHeader'></ng-container>
  </header>
  <section class="lib-detail-layout__breadcrumbs">
    <ng-container *ngTemplateOutlet='detailBreadcrumbs'></ng-container>
  </section>
  <div class="lib-detail-layout__body">
    <section class="lib-detail-layout__menu">
      <ng-container *ngTemplateOutlet='detailMenu'></ng-container>
    </section>
    <section class="lib-detail-layout__content">
      <ng-container *ngTemplateOutlet='detailContent'></ng-container>
    </section>
  </div>
</section>
