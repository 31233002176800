import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { getRedirectUrl } from '@core/utilities/runtime.utils';

const origin = window.location.origin;

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {
  apiKey = '';
  dbConnection = '';
  defaultConnection = 'Username-Password-Authentication';
  constructor() {}

  initialize(handler: HttpBackend) {

    if (!origin.includes('localhost')) {
      const httpClient = new HttpClient(handler);
      return httpClient
        .post(
          environment.configEndpoint,
          { id: origin.replace('https://', '')},
          {
            headers: {
              'x-functions-key': environment.configEndpointFunctionKey
            }
          }
        )
        .pipe(
          map((response: any) => {
          })
        );
    }

    this.apiKey = environment.apiConfig.apiKey;
    return of(void 0);
  }
}
