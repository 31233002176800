import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HeaderService } from './header.service';

@Component({
  selector: 'lib-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnInit {
  @Input() notificationCount;
  @Input() notificationsActive = true;
  @Output() openNotifications: EventEmitter<string> = new EventEmitter<string>();
  @Output() openSearch: EventEmitter<string> = new EventEmitter<string>();
  @Output() document: EventEmitter<void> = new EventEmitter<void>();
  constructor(public header: HeaderService) {}

  ngOnInit(): void {}
}
