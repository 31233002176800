/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EligibilityValidationViewModel } from './eligibilityValidationViewModel';

export interface EligibilitySourceViewModel {
  id?: string;
  payorType?: string | null;
  payorEntityIdentifierCode?: string | null;
  payorEntityIdentifierName?: string | null;
  payorLastOrgName?: string | null;
  payorFirstName?: string | null;
  payorMiddleName?: string | null;
  payorIdentificationQualifierCode?: string | null;
  payorIdentificationQualifierName?: string | null;
  payorIdentificationCode?: string | null;
  payorContactName?: string | null;
  payorCommNumberQualifierCode?: string | null;
  payorCommNumberQualifierName?: string | null;
  payorCommNumber?: string | null;
  payorCommNumberQualifier2Code?: string | null;
  payorCommNumberQualifier2Name?: string | null;
  payorCommNumber2?: string | null;
  payorCommNumberQualifier3Code?: string | null;
  payorCommNumberQualifier3Name?: string | null;
  payorCommNumber3?: string | null;
  validations?: Array<EligibilityValidationViewModel> | null;
}
