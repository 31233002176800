<h3 class="center">Copy Fees from Existing Schedule</h3>
<lib-form-group [formGroup]="populateForm" class="populate" stackedTitle="true" hideTitle="true">
  <lib-form-field
    [parent]="populateForm"
    label="Fee Schedule"
    name="feeScheduleId"
    type="select"
    [array]="array"
    class="form-span-12"
  >
  </lib-form-field>
  <lib-form-field
    [parent]="populateForm"
    label="% of Fee Amounts"
    name="percent"
    type="number"
    [attr.min]="0"
    [attr.max]="100"
    class="form-span-6"
  >
  </lib-form-field>
  <lib-form-field
    *ngIf="isEdit"
    [parent]="populateForm"
    label="Update Existing Fees"
    name="updateFee"
    type="checkbox"
    class="form-span-3"
  >
  </lib-form-field>
  <lib-form-field [parent]="populateForm" label="Add New Fees" name="addNew" type="checkbox" class="form-span-3">
  </lib-form-field>
</lib-form-group>
<div class="button-div">
  <button class="cancel" (click)="cancel()" mat-stroked-button>Cancel</button>

  <button class="save" mat-flat-button color="primary" (click)="save()" [disabled]="populateForm.invalid">Save</button>
</div>
