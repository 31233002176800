<lib-card>
  <ng-container name="title">EOB History</ng-container>
  <section class="eob-history">
    <div class="eob__legend">
      <div>Procedure</div>
      <div>Service Date</div>
      <div>Insurance Carrier</div>
      <div>Type</div>
      <div>Description</div>
      <div>EOB Amount</div>
      <div>Response Date</div>
    </div>
    <div class="eob__code" *ngFor="let code of visit?.eobCodes; let i = index">
      <div>{{ code?.procedureCode }}</div>
      <div>{{ code?.serviceDate | date }}</div>
      <div>{{ code?.insuranceCarrier === ' ' || null ? 'Self Pay' : code?.insuranceCarrier }}</div>
      <div>{{ code?.type }}</div>
      <div>{{ code?.description }}</div>
      <div>{{ code?.amount | currency }}</div>
      <div>{{ code?.statusDate | date }}</div>
    </div>
  </section>
</lib-card>
