import { Pipe, PipeTransform } from '@angular/core';
import { ProcedureTransactionDetailsViewModel } from 'data';

@Pipe({
  name: 'procedureDescription'
})
export class ProcedureDescriptionPipe implements PipeTransform {
  transform(value: ProcedureTransactionDetailsViewModel): string {
    const displayValue = [];
    var modifiers = "";
    if (value?.modifier1?.code) {
      modifiers += value?.modifier1?.code;
      if (value?.modifier2?.code) {
        modifiers += ':' + value?.modifier2?.code;
        if (value?.modifier3?.code) {
          modifiers += ':' + value?.modifier3?.code;
          if (value?.modifier4?.code) {
            modifiers += ':' + value?.modifier4?.code;
          }
        }
      }
    }
    if (value?.procedure?.code) {
      if (modifiers !== "") {
        displayValue.push(value?.procedure?.code + "/" + modifiers);
      } else {
        displayValue.push(value?.procedure?.code);
      }
    }
    
    if (value?.procedure?.description) {
      displayValue.push(value?.procedure?.description);
    }

    return displayValue.join(', ');
  }
}
