import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LocationsDialogComponent } from './locations-dialog.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { FormModule, SharedModule } from 'components';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';

@NgModule({
  declarations: [LocationsDialogComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    FormModule,
    SharedModule,
    MatTableModule,
    MatPaginatorModule
  ]
})
export class LocationsDialogModule {}
