/**
 * ImaginePay API
 * This API facilitates partner integration within the platform.
 *
 * The version of the OpenAPI document: 22_12_0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface ImaginePayApiModelsPaymentRequest {
  clientID: string;
  password: string;
  dataset: string;
  requestID: string;
  remoteValue?: string;
  maxAmount?: number;
  minAmount?: number;
  expectedAmount?: number;
  amount?: number;
  balance: number;
  onlineAccountNumber: string;
  onlineProviderID?: string;
  onlineTransactionID: string;
  patientDateOfBirth: string;
  patientFirstname: string;
  patientLastname: string;
  note?: string;
  serviceDescription?: string;
  transactionDate: string;
  receiptEmail?: string;
  billingAddress?: string;
  billingAddressLine2?: string;
  billingAddressCity?: string;
  billingAddressState?: string;
  billingAddressZipcode?: string;
  billingIdentifier?: string;
  priorBalance?: number;
  billingPatientID?: number;
  eligibleForBalanceBilling?: boolean;
  balanceBillingAmount?: number;
  holdPaymentExport?: boolean;
  phoneNumber?: string;
  contactMethod?: string;
  propensityToPayGrade?: string;
  discountPercentage?: number;
  discountMaxAmount?: number;
  defaultBillingResponsiblePartyName?: string;
}
