import { Injectable } from '@angular/core';
import { ApiAutoCompleteService, FieldSelectOption } from 'components/shared';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CodeWrapperService } from '../service-wrappers/code-wrapper.service';

@Injectable({
  providedIn: 'root'
})
export class ReportTypesLookupService extends ApiAutoCompleteService {
  constructor(private service: CodeWrapperService) {
    super();
  }

  mapRow(result) {
    return {
      value: result.id,
      label: result.name.toUpperCase() + ' (' + result.code.toUpperCase() + ')'
    };
  }

  search(value: string): Observable<FieldSelectOption[]> {
    return this.service
      .apiV1CodeCodetypePost('ReportingType', {
        propertySearches: [
          {
            propertyName: 'inactive',
            comparisonType: 0,
            value: 'no'
          }
        ],
        page: 1,
        pageSize: 99999
      })
      .pipe(
        map((results) => this.sortByName(results.data)),
        map((results) => results.map(this.mapRow))
      );
  }

  sortByName(rpttype) {
    return rpttype.sort((a, b) => (a.name < b.name ? -1 : 1));
  }
}
