/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SendingFacilityViewModel } from './sendingFacilityViewModel';

export interface ProcedureResultDetailsHoldingViewModel {
  id?: string;
  datasetId?: string;
  sendingFacilityId?: string | null;
  procedureResultsHoldingId?: string | null;
  sequence?: number;
  data?: string | null;
  observationType?: string | null;
  isDeleted?: boolean;
  deletedBy?: string | null;
  deletedDate?: string | null;
  createdBy?: string | null;
  createdDate?: string;
  updatedBy?: string | null;
  updatedDate?: string | null;
  sendingFacility?: SendingFacilityViewModel;
}
