import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { ReplaySubject, BehaviorSubject, Subscription } from 'rxjs';
import { HttpHeaders, HttpParams, HttpClient, HttpEventType, HttpErrorResponse } from '@angular/common/http';
import { FileUploadQueueService } from '../file-upload-queue/file-upload-queue.service';
import { IUploadProgress } from '../file-upload.type';
import { NotificationService } from '../../../../../../../../src/app/core/services/notification.service';

@Component({
  selector: 'lib-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileUploadComponent implements OnInit, OnDestroy {
  private uploadProgressSubject = new ReplaySubject<IUploadProgress>();
  public uploadProgress$ = this.uploadProgressSubject.asObservable();
  private uploadInProgressSubject = new BehaviorSubject<boolean>(false);

  public subs = new Subscription();

  @Input() httpUrl: string;

  @Input() httpRequestHeaders: HttpHeaders | { [header: string]: string | string[] };

  @Input() httpRequestParams: HttpParams | { [param: string]: string | string[] };

  @Input() fileAlias: string;

  private _file: any;
  private _id: number;

  @Input() get file(): any {
    return this._file;
  }
  set file(file: any) {
    this._file = file;
  }
  @Input() set id(id: number) {
    this._id = id;
  }
  get id(): number {
    return this._id;
  }
  @Input() fileUploadAriaLabel = 'File Upload';
  @Input() cancelAriaLabel = 'Cancel File Upload';
  @Output() removeEvent = new EventEmitter<FileUploadComponent>();
  @Output() uploadEvent = new EventEmitter();
  @Output() uploadCompleteEvent = new EventEmitter();

  private fileUploadSubscription: any;
  public success: boolean;
  hasError = false;
  errorMessage = "";

  constructor(private httpClient: HttpClient, private fileUploadQueueService: FileUploadQueueService,
    private notificationService: NotificationService,) {
    const queueInput = this.fileUploadQueueService.getInputValue();
    if (queueInput) {
      this.httpUrl = this.httpUrl || queueInput.httpUrl;
      this.httpRequestHeaders = this.httpRequestHeaders || queueInput.httpRequestHeaders;
      this.httpRequestParams = this.httpRequestParams || queueInput.httpRequestParams;
      this.fileAlias = this.fileAlias || queueInput.fileAlias;
    }
  }

  ngOnInit() {
    this.uploadProgressSubject.next({
      progressPercentage: 0,
      loaded: 0,
      total: this._file.size
    });
  }

  public appendFile(file): void {
    this.formData.append(this.fileAlias, file, file.name);
  }

  private formData = new FormData();

  public upload(): void {
    this.uploadInProgressSubject.next(true);
    this.formData.append(this.fileAlias, this._file, this._file.name);
    this.subs.add(
      this.httpClient
        .post(
          this.httpUrl,
          this.formData,
          //http options
          {
            headers: this.httpRequestHeaders,
            observe: 'events',
            params: this.httpRequestParams,
            reportProgress: true,
            responseType: 'json'
          }
        )
        .subscribe(
          //next
          (event: any) => {
            if (event.type === HttpEventType.UploadProgress) {
              this.uploadProgressSubject.next({
                progressPercentage: Math.floor((event.loaded * 100) / event.total),
                loaded: event.loaded,
                total: event.total
              });
            }
            this.uploadEvent.emit({ file: this._file, event });
          },
          //error
          (error: any) => {
            this.hasError = true;
            this.errorMessage = `Error uploading file: ${error.status} ${error.statusText}`
            if (this.fileUploadSubscription) {
              this.fileUploadSubscription.unsubscribe();
            }

            this.uploadInProgressSubject.next(false);
            this.uploadEvent.emit({ file: this._file, event: error });

            if (error instanceof HttpErrorResponse) {
              if (error.error instanceof ErrorEvent) {
                console.error('Error Event');
              } else {
                this.notificationService.errorNow(this.errorMessage);
              }
            }
          },
          //complete
          () => {
            this.uploadInProgressSubject.next(false);
            this.uploadCompleteEvent.emit({file: this._file});
            this.notificationService.notifyNow('File successfully uploaded');
          }
        )
    );
  }

  public remove(): void {
    this.subs.unsubscribe();
    this.removeEvent.emit(this);
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
