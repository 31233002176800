<header class="sub-page-layout__header remarkCodes__header">
  <div class="remarkCodes__header-text h2 h2--semi-bold">Remark Codes</div>
  <mat-slide-toggle
    class="remarkCodes__active-toggle"
    [checked]="activeFilter === 'active'"
    color="primary"
    (change)="showInactiveChanges($event)"
  >
    <div *ngIf="activeFilter !== 'inactive'">Active</div>
    <div *ngIf="activeFilter === 'inactive'">Inactive</div>
  </mat-slide-toggle>
  <button mat-flat-button color="primary" class="remarkCodes__add-item" (click)="uploadCodes()" *ngIf="!viewOnly">
    <mat-icon>file_upload</mat-icon> Upload Remark Codes
  </button>
  <button mat-flat-button color="primary" class="remarkCodes__add-item" (click)="addRemarkCode()" *ngIf="!viewOnly">
    <mat-icon>add_circle_outline</mat-icon> Add Remark Code
  </button>
</header>
<div *ngIf="dataSource && gridConfig" class="lib-grid__grid-container">
  <lib-grid [dataSource]="dataSource" [gridConfiguration]="gridConfig" skipLoadOnInit="true" loadAfterInit="true">
    <div class="">
      <h4>No Remark Codes Found.</h4>
    </div>
  </lib-grid>
</div>
<footer *ngIf="isImplementation && itemCount > 0" class="remarkCodes__footer">
  <lib-button-loading color="primary" class="remarkCodes__button" [invalid]="itemCount === 0" (clickEvent)="next()"
    >Next
  </lib-button-loading>
</footer>
