/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RolePermissionViewModel } from './rolePermissionViewModel';

export interface RoleDetailsViewModel {
  rolePermissions?: Array<RolePermissionViewModel> | null;
  id?: string | null;
  roleName?: string | null;
  description?: string | null;
}
