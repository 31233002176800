import { Injectable, Inject } from '@angular/core';
import { LocalStorageService } from './local-storage.service';

import { environment } from '../../../environments/environment';
import { WindowToken } from '../injection-tokens/window-token';

const APP_PREFIX = environment.stateConfig.storagePrefix;

@Injectable({
  providedIn: 'root'
})
export class StateLocalStorageService extends LocalStorageService {
  constructor(@Inject(WindowToken) private window2: Window) {
    super(window2);
  }

  /**
   * Retrieves and returns local storage
   *
   * @static
   * @returns Object
   * @memberof LocalStorageService
   */
  static loadInitialState() {
    return Object.keys(localStorage).reduce((state: any, storageKey) => {
      if (storageKey.includes(APP_PREFIX)) {
        const stateKeys = storageKey
          .replace(APP_PREFIX, '')
          .toLowerCase()
          .split('.')
          .map((key) =>
            key
              .split('-')
              .map((token, index) => (index === 0 ? token : token.charAt(0).toUpperCase() + token.slice(1)))
              .join('')
          );
        let currentStateRef = state;
        stateKeys.forEach((key, index) => {
          if (index === stateKeys.length - 1) {
            currentStateRef[key] = JSON.parse(localStorage.getItem(storageKey));
            return;
          }
          currentStateRef[key] = currentStateRef[key] || {};
          currentStateRef = currentStateRef[key];
        });
      }
      return state;
    }, {});
  }

  /**
   * Stores state value in local storage with app prefix
   *
   * @param {string} key
   * @param {*} value
   * @memberof StateLocalStorageService
   */
  setItem(key: string, value: any) {
    super.setItem(`${APP_PREFIX}${key}`, value);
  }

  /**
   * Gets state value out of local storage
   *
   * @param {string} key
   * @returns
   * @memberof StateLocalStorageService
   */
  getItem(key: string) {
    return super.getItem(`${APP_PREFIX}${key}`);
  }

  /**
   * Removes state value from storage
   *
   * @param {string} key
   * @memberof StateLocalStorageService
   */
  removeItem(key: string) {
    super.removeItem(`${APP_PREFIX}${key}`);
  }
}
