import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-version',
  template: `{{ version }}`
})
export class VersionComponent implements OnInit {
  version = environment.versions.app;
  constructor() {}

  ngOnInit(): void {}
}
