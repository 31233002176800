import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { PagedModel } from './models';

export class BaseGridSource<T> implements DataSource<T> {
  dataSourceSubject = new BehaviorSubject<T[]>([]);

  loadingSubject = new BehaviorSubject<boolean>(false);

  public loading$ = this.loadingSubject.asObservable();
  public dataSource$ = this.dataSourceSubject.asObservable();

  itemCountSubject = new BehaviorSubject<number>(0);
  public itemCount$ = this.itemCountSubject.asObservable();
  
  reset(loadingStatus = false) {
    this.dataSourceSubject = new BehaviorSubject<T[]>([]);
    this.loadingSubject = new BehaviorSubject<boolean>(loadingStatus);
  }

  savedPageModel: PagedModel = null;

  /**
   * Event to trigger loading event.
   * This function is supposed to be overwritten with parent implementation
   *
   * @memberof BaseGridSource
   */
  load(pagedModel: PagedModel) {
    throw new Error('Method not implemented.');
  }

  refresh() {
    if (this.savedPageModel != null) this.load(this.savedPageModel);
  }
  

  /**
   * Used by Angular Material Table to subscribe to data source
   *
   * @param {CollectionViewer} collectionViewer
   * @return {*}  {(Observable<T[] | readonly)}
   * @memberof BaseGridSource
   */
  connect(collectionViewer: CollectionViewer): Observable<T[] | readonly T[]> {
    return this.dataSourceSubject.asObservable();
  }

  /**
   * Used by Angular Material Table to subscribe to data source
   *
   * @param {CollectionViewer} collectionViewer
   * @memberof BaseGridSource
   */
  disconnect(collectionViewer: CollectionViewer): void {
    this.dataSourceSubject.complete();
    this.loadingSubject.complete();
  }
}
