export const accountsSearch = [
  { name: 'Relevant', value: '' },
  { name: 'Guarantor Only', value: 'guarantor' },
  { name: 'Patient Only', value: 'patient' }
];
export const accountsSort = [
  { name: 'Recently searched', value: 'searched' },
  { name: 'Recently viewed', value: 'viewed' },
  { name: 'Recent activity', value: 'activity' }
];
