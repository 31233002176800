import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { routerReducer, RouterReducerState } from '@ngrx/router-store';

import { authReducer } from './state/auth-state/auth.reducer';
import { SettingsState } from './state/settings/settings.state';
import { settingsReducer } from './state/settings/settings.reducer';
import { SettingsEffects } from './state/settings/settings.effects';
import { initStateFromLocalStorage } from './state/meta-reducers/init-state-from-local-storage.reducer';
import { RouterStateUrl } from './state/router/router.state';
import { AuthState } from './state/auth-state/auth.state';

import { DataSetReducer } from './state/data-set/data-set.reducer';
import { DataSetState } from './state/data-set/data-set.state';
import { DataSetEffects } from './state/data-set/data-set.effects';

import { UserState } from './state/user/user.state';
import { userReducer } from './state/user/user.reducers';
import { UserEffects } from './state/user/user.effects';

import { RemindersState } from './state/reminders/reminders.state';
import { accountNotesReducer } from './state/reminders/reminders.reducers';
import { RemindersEffects } from './state/reminders/reminders.effects';

export const coreReducers: ActionReducerMap<AppState> = {
  authData: authReducer,
  settings: settingsReducer,
  router: routerReducer,
  dataSets: DataSetReducer,
  user: userReducer,
  reminders: accountNotesReducer
};

export const coreEffects = [SettingsEffects, DataSetEffects, UserEffects, RemindersEffects];

export const metaReducers: MetaReducer<AppState>[] = [initStateFromLocalStorage];

export interface AppState {
  authData: AuthState;
  settings: SettingsState;
  router: RouterReducerState<RouterStateUrl>;
  dataSets: DataSetState;
  user: UserState;
  reminders: RemindersState;
}
