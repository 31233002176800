import { Injector, LOCALE_ID } from '@angular/core';
import { Validators } from '@angular/forms';
import { ColumnSizes, FieldSelectOption, FilterType } from 'components';
import { DateDisplayPipe } from 'projects/components/src/lib/shared/pipes/date-display/date-display.pipe';
import { Report } from '../../models/report';
import {
  LocationsLookupService,
  AttendingPhysiciansLookupService,
  PhysiciansLookupService
} from '../../../../core/services/lookup';
import { PaymentAnalysisReportService } from './payment-analysis-report.service';

const dateFormat = new Intl.DateTimeFormat('en-us');

export const PaymentAnalysisReport: Report = {
  id: 'payment-analysis-report',
  label: 'Payment Analysis Report',
  textCriteria: {
    'Date Range Type': '',
    'Start Date': '',
    'End Date': '',
    'Payment Method': '',
    'Payment Source': '',
    'PaymentRangeLow': '',
    'PaymentRangeHigh': '',
    'Locations': '',
    'Providers': ''
  },
  cleanFilterParams: {
    'locationIds': '', 'providerIds': ''
  },
  getReportTitle: () => {
    return (
      PaymentAnalysisReport.label +
      ' ' +
      PaymentAnalysisReport.textCriteria['Start Date'] +
      ' - ' +
      PaymentAnalysisReport.textCriteria['End Date'] +
      ' (' +
      PaymentAnalysisReport.textCriteria['Date Range Type'] +
      ')'
    );
  },
  getSearchCriteria: (injector: Injector) => [
    {
      title: '',
      controls: [
        {
          label: 'Range Type',
          name: 'dateRangeType',
          type: 'select',
          class: 'form-span-6 ',
          options: [
            'Service Date',
            'Posting Date'
          ],
          validators: Validators.required,
          selectionChanged: (event) => {
            PaymentAnalysisReport.textCriteria['Date Range Type'] = event.source.triggerValue;
          }
        },
        {
          label: 'Date Range',
          name: 'dateRange',
          type: 'dateRange',
          class: 'form-span-6',
          daterangeStartChanged: (event) => {
            PaymentAnalysisReport.textCriteria['Start Date'] = event.value.toLocaleDateString();
          },
          daterangeEndChanged: (event) => {
            PaymentAnalysisReport.textCriteria['End Date'] = event.value.toLocaleDateString();
          }
        },
        {
          label: '',
          name: 'dateRangeStart',
          type: 'noInput',
          class: 'display-none',
          validators: Validators.required
        },
        {
          label: '',
          name: 'dateRangeEnd',
          type: 'noInput',
          class: 'display-none',
          validators: Validators.required
        },
        {
          label: 'Payment Method',
          name: 'paymentMethod',
          class: 'form-span-6',
          type: 'select',
          multiple: true,
          options: [
            {
              value: 'Manual Transaction Entry',
              label: 'Manual Transaction Entry'
            },
            {
              value: 'Secure Payment',
              label: 'ImaginePay (manual)'
            },
            {
              value: 'ImaginePay Import',
              label: 'ImaginePay (file import)'
            },
            {
              value: 'Remittance',
              label: 'Remittance'
            }
          ],
          selectionChanged: (event) => {
            PaymentAnalysisReport.textCriteria['Payment Method'] = event.source.triggerValue;
          },
        },
        {
          label: 'Payment Source',
          name: 'paymentSource',
          class: 'form-span-6',
          type: 'select',
          options: ['Insurance', 'Patient'],
          selectionChanged: (event) => {
            PaymentAnalysisReport.textCriteria['Payment Source'] = event.source.triggerValue;
          }
        },
        {
          label: 'Payment Range Low End',
          name: 'paymentRangeLow',
          type: 'money',
          class: 'form-span-3'
        },
        {
          label: 'Payment Range High End',
          name: 'paymentRangeHigh',
          type: 'money',
          class: 'form-span-3'
        },
        {
          matLabel: 'Location',
          name: 'locationIds',
          apiService: injector.get(LocationsLookupService),
          type: 'multiSelect',
          class: 'form-span-6 multi-input',
          placeholder: 'Location',
          selectOptions: (event) => {
            let listValue = []
            let listText = []
            event.forEach((x: FieldSelectOption) => {

              listValue.push(x.value)
              listText.push(x.label)
            })
            PaymentAnalysisReport.textCriteria['Location'] = listText.join(',');
            PaymentAnalysisReport.cleanFilterParams['locationIds'] = listValue
          }
        },
        {
          type: 'empty',
          class: 'form-span-3'
        },
        {
          matLabel: 'Provider',
          name: 'providerIds',
          apiService: injector.get(PhysiciansLookupService),
          type: 'multiSelect',
          class: 'form-span-6 multi-input',
          placeholder: 'Provider',
          selectOptions: (event) => {
            let listValue = []
            let listText = []
            event.forEach((x: FieldSelectOption) => {
              listValue.push(x.value)
              listText.push(x.label)
            })
            PaymentAnalysisReport.textCriteria['Provider'] = listText.join(',');
            PaymentAnalysisReport.cleanFilterParams['providerIds'] = listValue

          }
        },
      ]
    }
  ],
  getGridConfiguration: (injector: Injector) => {
    const locale = injector.get(LOCALE_ID);
    const moneyFormat = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });
    return {
      service: injector.get<any>(PaymentAnalysisReportService),
      columnDefinitions: [
        {
          id: 'Account Number',
          displayKey: 'Account Number',
          headerText: 'Account Number',
          displayOrder: 0,
          columnSize: ColumnSizes.normal,
          sortable: true,
          filterable: true
        },
        {
          id: 'Full Name',
          displayKey: 'Full Name',
          headerText: 'Full Name',
          displayOrder: 1,
          columnSize: ColumnSizes.normal,
          sortable: true,
          filterable: true
        },
        {
          id: 'Payment Amount',
          displayKey: 'Payment Amount',
          headerText: 'Payment Amount',
          displayOrder: 2,
          columnSize: ColumnSizes.narrow,
          sortable: true,
          filterable: true,
          formatter: moneyFormat.format
        },
        {
          id: 'Payment Method',
          displayKey: 'Payment Method',
          headerText: 'Payment Method',
          displayOrder: 4,
          columnSize: ColumnSizes.normal,
          sortable: true,
          filterable: true
        },
        {
          id: 'Payment Source',
          displayKey: 'Payment Source',
          headerText: 'Payment Source',
          displayOrder: 3,
          columnSize: ColumnSizes.normal,
          sortable: true,
          filterable: true
        },
        {
          id: 'Batch Number',
          displayKey: 'Batch Number',
          headerText: 'Batch Number',
          displayOrder: 3,
          columnSize: ColumnSizes.extraNarrow,
          sortable: true,
          filterable: true
        },
        {
          id: 'Payment Date',
          displayKey: 'Payment Date',
          headerText: 'Payment Date',
          displayOrder: 5,
          columnSize: ColumnSizes.narrow,
          sortable: true,
          filterable: true
        },
        {
          id: 'Service Date',
          displayKey: 'Service Date',
          headerText: 'Service Date',
          displayOrder: 3,
          columnSize: ColumnSizes.normal,
          sortable: true,
          filterable: true
        },
        {
          id: 'Provider',
          displayKey: 'Provider',
          headerText: 'Provider',
          displayOrder: 3,
          columnSize: ColumnSizes.normal,
          sortable: true,
          filterable: true
        },
        {
          id: 'Location',
          displayKey: 'Location',
          headerText: 'Location',
          displayOrder: 3,
          columnSize: ColumnSizes.normal,
          sortable: true,
          filterable: true
        }
      ]
    }
  }
};
