/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PatientDemographicsIntegrationViewModel } from './patientDemographicsIntegrationViewModel';
import { PatientServiceDatesIntegrationViewModel } from './patientServiceDatesIntegrationViewModel';
import { PatientInsuranceIntegrationViewModel } from './patientInsuranceIntegrationViewModel';
import { GuarantorDemographicsIntegrationViewModel } from './guarantorDemographicsIntegrationViewModel';

/**
 * Patient Details view model containing Patient and Gaurantor demographics,  Insurances, Dates of Service, and Balances
 */
export interface PatientDetailsIntegrationViewModel {
  datasetId?: string;
  accountNumber?: number;
  patient?: PatientDemographicsIntegrationViewModel;
  guarantor?: GuarantorDemographicsIntegrationViewModel;
  insurances?: Array<PatientInsuranceIntegrationViewModel> | null;
  datesOfService?: Array<PatientServiceDatesIntegrationViewModel> | null;
  patientBalance?: number;
  insuranceBalance?: number;
}
