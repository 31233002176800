/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ClaimProcedureNDCViewModel } from './claimProcedureNDCViewModel';
import { ClaimPaymentViewModel } from './claimPaymentViewModel';
import { ClaimProviderViewModel } from './claimProviderViewModel';
import { HoldingIssue } from './holdingIssue';
import { ClaimLocationViewModel } from './claimLocationViewModel';

export interface ClaimProcedureViewModel {
  procedureCode?: string | null;
  nocProcedureDescription?: string | null;
  modifier1?: string | null;
  modifier2?: string | null;
  modifier3?: string | null;
  modifier4?: string | null;
  drugCode?: string | null;
  drugQuantity?: number;
  drugUnit?: string | null;
  drugAmount?: number;
  prescriptionNumber?: string | null;
  procedureAmount?: number;
  quantity?: number;
  insuranceAuthNumber?: string | null;
  serviceFromDate?: string;
  serviceToDate?: string;
  placeOfService?: string | null;
  sequence?: number;
  claimComment?: string | null;
  sendClia?: boolean;
  sendMammo?: boolean;
  sendOrdering?: boolean;
  box17AQualifier?: string | null;
  box17AValue?: string | null;
  ndcDetails?: ClaimProcedureNDCViewModel;
  attendingProviderDetails?: ClaimProviderViewModel;
  referringProviderDetails?: ClaimProviderViewModel;
  orderingProviderDetails?: ClaimProviderViewModel;
  supervisingProviderDetails?: ClaimProviderViewModel;
  locationDetails?: ClaimLocationViewModel;
  diagnoses?: Array<string> | null;
  diagnosesPointers?: Array<string> | null;
  payments?: Array<ClaimPaymentViewModel> | null;
  insuranceId?: string;
  issues?: Array<HoldingIssue> | null;
}
