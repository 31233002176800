import { Injectable } from '@angular/core';
import { AddRuleCardCriteriaViewModel, Operation, PagedModelRequest, RuleCardCriteriaService } from 'data';
import { Observable } from 'rxjs';
import { BaseWrapperService } from './base-wrapper.service';
import { SessionStorageService } from '../session-storage.service';

@Injectable({
  providedIn: 'root'
})
export class RuleCardCriteriaWrapperService extends BaseWrapperService {
  constructor(
    private ruleCardCriteriaService: RuleCardCriteriaService,
    protected sessionStorageService: SessionStorageService
  ) {
    super();
  }

  // Rule Card Criteria
  apiV1RulecardcriteriaAllPost(pagedModelRequest?: PagedModelRequest): Observable<any> {
    return this.ruleCardCriteriaService.apiV1RulecardcriteriaAllPost(this.getDataSetId(), pagedModelRequest);
  }

  // Rule Card Criteria Details
  apiV1RulecardcriteriaAllDetailPost(pagedModelRequest?: PagedModelRequest): Observable<any> {
    return this.ruleCardCriteriaService.apiV1RulecardcriteriaAllDetailPost(this.getDataSetId(), pagedModelRequest);
  }

  apiV1RulecardcriteriaGetcriteriadataPost(pagedModelRequest?: PagedModelRequest): Observable<any> {
    return this.ruleCardCriteriaService.apiV1RulecardcriteriaGetcriteriadataPost(
      this.getDataSetId(),
      pagedModelRequest
    );
  }

  apiV1RulecardcriteriaGetdropdownvaluesPost(pagedModelRequest?: PagedModelRequest): Observable<any> {
    return this.ruleCardCriteriaService.apiV1RulecardcriteriaGetdropdownvaluesPost(
      this.getDataSetId(),
      pagedModelRequest
    );
  }

  apiV1RulecardcriteriaAddPost(addRuleCardCriteriaViewModel?: AddRuleCardCriteriaViewModel): Observable<any> {
    return this.ruleCardCriteriaService.apiV1RulecardcriteriaAddPost(this.getDataSetId(), addRuleCardCriteriaViewModel);
  }

  apiV1RulecardcriteriaDetailsIdGet(ruleCardCriteriaId: string): Observable<any> {
    return this.ruleCardCriteriaService.apiV1RulecardcriteriaDetailsIdGet(ruleCardCriteriaId, this.getDataSetId());
  }

  apiV1RulecardcriteriaPatchIdPatch(userId?: string, operation?: Array<Operation>): Observable<any> {
    return this.ruleCardCriteriaService.apiV1RulecardcriteriaPatchIdPatch(this.getDataSetId(), userId, operation);
  }

  apiV1RulecardcriteriaIdDelete(userId?: string): Observable<any> {
    return this.ruleCardCriteriaService.apiV1RulecardcriteriaIdDelete(this.getDataSetId(), userId);
  }
}
