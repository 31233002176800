import { Pipe, PipeTransform } from '@angular/core';
import { ProviderDetailsViewModel } from 'data';

@Pipe({
  name: 'trueFalseDisplay'
})
export class trueFalseDisplayPipe implements PipeTransform {
  transform(value) {
    if (value === true || value === '1' || value === 1) {
      return 'Yes';
    }
    return 'No';
  }
}
