/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { StatusSegmentModel } from './statusSegmentModel';
import { ClaimStatusPatientViewModel } from './claimStatusPatientViewModel';

export interface ClaimStatusProviderViewModel {
  id?: string;
  datasetId?: string;
  claimStatusFileId?: string;
  providerId?: string | null;
  providerName?: string | null;
  providerTraceIdentifier?: string | null;
  providerIdentifierType?: string | null;
  providerIdentifier?: string | null;
  totalAcceptedQuantity?: number;
  totalRejectedQuantity?: number;
  totalAcceptedAmount?: number;
  totalRejectedAmount?: number;
  isCleared?: boolean;
  hlSegment?: string | null;
  statusSegmentModel?: StatusSegmentModel;
  patientViewModels?: Array<ClaimStatusPatientViewModel> | null;
}
