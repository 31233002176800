import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BreadcrumbsModule } from './breadcrumbs';
import { DetailLayoutModule } from './detail-layout';
import { DialogModule } from './dialog';
import { HeaderModule } from './header';
import { OverflowMenuModule } from '../shared/overflow-menu';
import { PageLayoutModule } from './page-layout';
import { SearchBarModule } from './search-bar';
import { ToastModule } from './toast';
import { ShowDialogModule } from './show-dialog';

@NgModule({
  declarations: [],
  exports: [
    BreadcrumbsModule,
    DetailLayoutModule,
    DialogModule,
    HeaderModule,
    OverflowMenuModule,
    PageLayoutModule,
    SearchBarModule,
    ToastModule,
    ShowDialogModule
  ],
  imports: [CommonModule]
})
export class CoreModule {}
