import { BaseGridSource, ColumnDefinition, PagedModel, StaticCollectionUtilities } from 'components';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { catchError, filter, map, take } from 'rxjs/operators';
import { GridService } from 'projects/components/src/lib/shared/grid/grid.service';
import { ReportGrid } from '../models/report-grid';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

/**
 *
 * Report Grid Sources behave differently than normal grid resources.
 *
 * Normal Grid Sources reload data on paging or sorting for performance reasons.
 *
 * Report Grid Sources load in all data during 'initialize'
 * and the 'load' function is used for sorting, filtering and paging the result set.
 *
 * @export
 * @class BaseReportGridSource
 * @extends {BaseGridSource<T>}
 * @template T
 */
export class BaseReportGridSource<T> extends BaseGridSource<T> {
  gridColumns: ColumnDefinition[];
  resultColumns: any;
  baseDataSourceSubject = new BehaviorSubject<T[]>([]);
  filteredDataSourceSubject = new BehaviorSubject<T[]>([]);
  initializedSubject = new BehaviorSubject<boolean>(false);
  parametersSubject = new BehaviorSubject<T>(null);

  parameters;
  textParameters;
  executedJob;
  now = new Date();
  pagedModel;
  reportExecutionId;
  datasetName;
  
  public initialized$ = this.initializedSubject.asObservable();
  public baseDataSource$ = this.baseDataSourceSubject.asObservable();
  public filteredDataSource$ = this.filteredDataSourceSubject.asObservable();
  public loading$ = this.loadingSubject.asObservable();
  public parameters$ = this.parametersSubject.asObservable();

  private gridService = new GridService();
  public allData;

  initialize(query: any, columnDefinitions: ColumnDefinition[]):any {
    throw new Error('Method not implemented.');
  }

  loadInitialData(requestObs: Observable<any>) {
    this.reset(true);
    this.loadingSubject.next(true);
    this.initializedSubject.next(false);

    requestObs.subscribe((data) => {
      this.pagedModel.itemCount = data.length
      this.itemCountSubject.next(data.length);
      this.allData = data;
      this.nameDynamicColumns(data);
      const pagedData = this.pageData(data, this.pagedModel);
      this.baseDataSourceSubject.next(pagedData);
      this.loadingSubject.next(false);
      this.initializedSubject.next(true);
    })
  }

  getFilterSortData(pagedModel: PagedModel) {
    const filteredData = this.filterSortData(this.allData, pagedModel);
    const pagedData = this.pageData(filteredData, pagedModel);
    this.itemCountSubject.next(filteredData.length);
    this.baseDataSourceSubject.next(pagedData);
  }
  filterSortData(dataSource, pagedModel: PagedModel) {
    // Clone Data source to ensure original data source does not get mutated
    let clonedData = [...dataSource];
    clonedData = StaticCollectionUtilities.filter(clonedData, pagedModel);
    clonedData = StaticCollectionUtilities.sortData(clonedData, pagedModel);
    return clonedData;
  }

  getPagedData(pagedModel) {
    this.baseDataSourceSubject.next(this.pageData(this.allData, pagedModel));
  }
  pageData(dataSource, pagedModel: PagedModel):any[] {
    // Clone Data source to ensure original data source does not get mutated
    let clonedData = [...dataSource];
    clonedData = StaticCollectionUtilities.pageData(clonedData, pagedModel);
    return clonedData;
  }
  
  load(pagedModel: PagedModel) {
    combineLatest([this.initialized$, this.dataSource$])
      .pipe(
        filter(([initialized, baseDataSource]) => initialized === true),
        map(([initialized, baseDataSource]) => {
          return this.filterSortData(baseDataSource, pagedModel);
        }),
        map((filteredData) => {
          this.itemCountSubject.next(filteredData.length);
          this.filteredDataSourceSubject.next(filteredData);
          return this.pageData(filteredData, pagedModel);
        }),
        catchError((error) => {
          return of([]);
        }),
        take(1)
      )
      .subscribe((filteredData) => {
        this.nameDynamicColumns(filteredData);
        this.baseDataSourceSubject.next(filteredData);
      });
  }

  dynCols: string[] = [];
  nameDynamicColumns(filteredData) {
    let i = 0;
    this.gridColumns.filter(gc => gc.dynamic).forEach((d) => {
      if (filteredData.length > 0) {
        const key = Object.keys(filteredData[0])[d.displayOrder];
        d.displayKey = d.headerText = key;
        this.dynCols.push(key);
      }
      else {
          d.displayKey = d.headerText = this.dynCols[i];
      }
      i++;
    });
  }

  reportTitle; 
  downloadPDF(reportSlug: string, gridConfig: ReportGrid, print: boolean = false, reportTitle: string) {
    this.reportTitle = reportTitle;
    let w = this.gridColumns.length;
    let h = this.gridColumns.length / 17 * 11;
    if (w < 17) { w = 17; h = 11; }
    
    let doc = new jsPDF({ orientation: "l", unit: "in", format: [w, h] });
    doc['title'] = this.reportTitle;

    const headers = gridConfig.columnDefinitions?.map((cd) => cd.headerText);
    const body = this.allData?.map((dataItem) => {
      return gridConfig.columnDefinitions?.map((cd) => 
        !!cd.formatter ? cd.formatter(dataItem[cd.displayKey]) : dataItem[cd.displayKey]
      );
    });

    const cover_x = 1.0
    let cover_y: number = 1.0;

    let startDate= '';
    let endDate= '';
    let output: string[] = [];

    doc['created'] = `${this.executedJob?.executed ??  this.now.toLocaleDateString() + ' ' + this.now.toLocaleTimeString() }`;

    let totalPagesExp = '{total_pages_count_string}';

    autoTable(doc, {
      head: [headers],
      body: [...body],
      margin: {top: 1, bottom: 1, left: 1, right: 1 },
      theme: 'plain',
      
      didDrawPage: function(data) {
        const w = data.doc.getPageWidth();
        const h = data.doc.getPageHeight();
        data.doc.setFontSize(16);
        data.doc.text(data.doc['title'], 1.0, 0.75);
        data.doc.setFontSize(10);
        data.doc.text(data.doc['created'], 1.0, h - 0.50);
        data.doc.text('Page ' + data.pageNumber.toString() + ' of ' + totalPagesExp, w - 2.5, h - 0.50);  // somehow implement 'Page x of y' logic
      }
      
    });

    if (typeof doc.putTotalPages === 'function') { 
      doc.putTotalPages(totalPagesExp) 
    } 

    if (print) doc.autoPrint();
    doc.save(`${reportSlug}.pdf`);
  }
}
