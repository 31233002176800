import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header.component';
import { HeaderContentComponent } from './header-content/header-content.component';
import { PortalModule } from '@angular/cdk/portal';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatBadgeModule } from '@angular/material/badge';
import { MatMenuModule } from '@angular/material/menu';
@NgModule({
  declarations: [HeaderComponent, HeaderContentComponent],
  exports: [HeaderComponent, HeaderContentComponent],
  imports: [CommonModule, PortalModule, MatIconModule, MatButtonModule, MatBadgeModule, MatMenuModule]
})
export class HeaderModule {}
