<button [matMenuTriggerFor]="menu" #menuTrigger="matMenuTrigger" class="data-set__menu-button">
  <span> {{ selectedDataSet?.acronym }}: {{ selectedDataSet?.name }} </span>
  <span class="material-icons" *ngIf="!menuTrigger.menuOpen"> unfold_more </span>
  <span class="material-icons" *ngIf="menuTrigger.menuOpen"> unfold_less </span>
</button>
<mat-menu #menu="matMenu" class="data-set__menu-panel">
  <ng-container *ngFor="let dataSet of dataSets; trackBy: trackById">
    <button
      [ngClass]="{ 'data-set__menu-panel-button': true, 'data-set__active': dataSet.id === selectedDataSet?.id }"
      class="testing"
      mat-menu-item
      (click)="dataSetSelected(dataSet)"
    >
      {{ dataSet.acronym }}: {{ dataSet.name }}&nbsp;
      <em>
        {{ dataSet.description }}
      </em>
      <span class="material-icons" *ngIf="dataSet.id === selectedDataSet?.id"> check </span>
    </button>
  </ng-container>
</mat-menu>
