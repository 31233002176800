import { createAction, props } from '@ngrx/store';
import { Reminder } from './reminders.state';

const base = '[Reminders] -';

export const loadReminders = createAction(`${base} Load Reminders`);
export const loadRemindersSuccess = createAction(`${base} Load Success`, props<{ reminders: Reminder[] }>());
export const loadRemindersFailure = createAction(`${base} Load Failure`, props<{ error: any }>());

export const updateReminders = createAction(`${base} Update Reminders`);

export const unloadReminders = createAction(`${base} Unload Reminders`);
