import { Injectable } from '@angular/core';
import {
  InsuranceCarrierDetailsViewModel,
  InsuranceCarrierDetailsViewModelPagedModelResponse,
  InsuranceCarrierService,
  InsuranceCarrierViewModel,
  InsuranceViewModel,
  PagedModelRequest
} from 'data';
import { Observable } from 'rxjs';
import { BaseWrapperService } from './base-wrapper.service';
import { SessionStorageService } from '../session-storage.service';

@Injectable({
  providedIn: 'root'
})
export class InsuranceCarrierWrapperService extends BaseWrapperService {
  constructor(private service: InsuranceCarrierService, protected sessionStorageService: SessionStorageService) {
    super();
  }

  apiV1InsuranceCarrierAllPost(
    pagedModelRequest?: PagedModelRequest
  ): Observable<InsuranceCarrierDetailsViewModelPagedModelResponse> {
    return this.service.apiV1InsuranceCarrierAllPost(this.getDataSetId(), pagedModelRequest);
  }

  apiV1InsuranceCarrierAllDetailPost(
    pagedModelRequest?: PagedModelRequest
  ): Observable<InsuranceCarrierDetailsViewModelPagedModelResponse> {
    return this.service.apiV1InsuranceCarrierAllDetailPost(this.getDataSetId(), pagedModelRequest);
  }

  apiV1InsuranceCarrierIdGet(id: string): Observable<InsuranceCarrierDetailsViewModel> {
    return this.service.apiV1InsuranceCarrierIdGet(id, this.getDataSetId());
  }

  apiV1InsuranceCarrierAddPost(requestBody): Observable<InsuranceViewModel> {
    return this.service.apiV1InsuranceCarrierAddPost(this.getDataSetId(), requestBody);
  }

  apiV1InsuranceCarrierPatchIdPatch(id: string, operation): Observable<InsuranceViewModel> {
    return this.service.apiV1InsuranceCarrierPatchIdPatch(id, this.getDataSetId(), operation);
  }

  apiV1InsuranceCarrierIdDelete(id: string): Observable<InsuranceViewModel> {
    return this.service.apiV1InsuranceCarrierIdDelete(id, this.getDataSetId());
  }

  apiV1InsuranceCarrierValidateCarrierCodePost(carrierCode: string): Observable<InsuranceCarrierViewModel> {
    return this.service.apiV1InsuranceCarrierValidateCarrierCodePost(this.getDataSetId(), carrierCode);
  }

  loadByIds(ids: string[]): Observable<any> {
    return this.service.apiV1InsuranceCarrierIdsPost(this.getDataSetId(), ids);
  }
}
