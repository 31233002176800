import { Inject, Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
// import { AuthState } from './auth.state';
import { Observable, from, of, iif, throwError } from 'rxjs';

import { switchMap, first, concatMap, pluck, catchError, tap } from 'rxjs/operators';
import {
  ApiRouteDefinition,
  AuthClientConfig,
  HttpInterceptorConfig,
  isHttpInterceptorRouteConfig
} from './auth.config';
import { AuthClient, AuthClientService } from './auth.client';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private configFactory: AuthClientConfig,
    @Inject(AuthClientService) private authClient: AuthClient //   private authState: AuthState
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const config = this.configFactory.get();
    if (!config.httpInterceptor?.allowedList) {
      return next.handle(req);
    }

    return this.findMatchingRoute(req, config.httpInterceptor).pipe(
      concatMap((route) =>
        iif(
          // Check if a route was matched
          () => route !== null,
          // If we have a matching route, call getTokenSilently and attach the token to the
          // outgoing request
          of(route).pipe(
            pluck('tokenOptions'),
            concatMap<any, Observable<string>>((options) => {
              return this.getAccessTokenSilently().pipe(
                catchError((err) => {
                  if (this.allowAnonymous(route, err)) {
                    return of('');
                  }

                  //    this.authState.setError(err);
                  return throwError(err);
                })
              );
            }),
            switchMap((token: string) => {
              // Clone the request and attach the bearer token
              const clone = token
                ? req.clone({
                    headers: req.headers.set('Authorization', `Bearer ${token}`)
                  })
                : req;

              return next.handle(clone);
            })
          ),
          // If the URI being called was not found in our httpInterceptor config, simply
          // pass the request through without attaching a token
          next.handle(req)
        )
      )
    );
  }

  private getAccessTokenSilently(): Observable<any> {
    return of(this.authClient).pipe(
      concatMap((client) => client.getTokenSilently()),
      // tap((token) => this.authState.setAccessToken(token)),
      catchError((error) => {
        //   this.authState.refresh();
        return throwError(error);
      })
    );
  }
  private stripQueryFrom(uri: string): string {
    if (uri.indexOf('?') > -1) {
      uri = uri.substr(0, uri.indexOf('?'));
    }

    if (uri.indexOf('#') > -1) {
      uri = uri.substr(0, uri.indexOf('#'));
    }

    return uri;
  }

  private canAttachToken(route: ApiRouteDefinition, request: HttpRequest<any>): boolean {
    const testPrimitive = (value: string | undefined): boolean => {
      if (!value) {
        return false;
      }

      const requestPath = this.stripQueryFrom(request.url);

      if (value === requestPath) {
        return true;
      }

      // If the URL ends with an asterisk, match using startsWith.
      return value.indexOf('*') === value.length - 1 && request.url.startsWith(value.substr(0, value.length - 1));
    };

    if (isHttpInterceptorRouteConfig(route)) {
      if (route.httpMethod && route.httpMethod !== request.method) {
        return false;
      }

      /* istanbul ignore if */
      if (!route.uri && !route.uriMatcher) {
        console.warn('Either a uri or uriMatcher is required when configuring the HTTP interceptor.');
      }

      return route.uriMatcher ? route.uriMatcher(request.url) : testPrimitive(route.uri);
    }

    return testPrimitive(route);
  }

  private findMatchingRoute(
    request: HttpRequest<any>,
    config: HttpInterceptorConfig
  ): Observable<ApiRouteDefinition | null> {
    return from(config.allowedList).pipe(first((route) => this.canAttachToken(route, request), null));
  }

  private allowAnonymous(route: ApiRouteDefinition | null, err: any): boolean {
    return (
      !!route &&
      isHttpInterceptorRouteConfig(route) &&
      !!route.allowAnonymous &&
      ['login_required', 'consent_required'].includes(err.error)
    );
  }
}
