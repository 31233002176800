/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface AddPatientViewModel {
  datasetId?: string;
  guarantorId?: string | null;
  accountNumber?: number;
  lastName?: string | null;
  middleName?: string | null;
  firstName?: string | null;
  suffix?: string | null;
  birthDate?: string;
  sex?: string | null;
  ssn?: string | null;
  medicalRecordNumber?: string | null;
  defaultAdmissionDate?: string | null;
  defaultDischargeDate?: string | null;
  deathDate?: string | null;
  defaultInjuryDate?: string | null;
  defaultInjuryEmploymentCode?: string | null;
  defaultAccidentType?: string | null;
  defaultAutoAccidentState?: string | null;
  defaultLocationId?: string | null;
  defaultAttendingProviderId?: string | null;
  defaultReferringProviderId?: string | null;
  defaultAuthNumber?: string | null;
  defaultHoldInsuranceBilling?: boolean | null;
  defaultHoldPatientBilling?: boolean | null;
  printFirstReport?: boolean;
  militaryBranch?: string | null;
  militaryDutyStatus?: string | null;
  maritalStatus?: string | null;
  employmentStudentStatus?: string | null;
  primaryDiagnosisCodeId?: string | null;
  secondaryDiagnosisCodeId?: string | null;
  tertiaryDiagnosisCodeId?: string | null;
  quarternaryDiagnosisCodeId?: string | null;
  primaryInsuranceId?: string | null;
  secondaryInsuranceId?: string | null;
  tertiaryInsuranceId?: string | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  stateCode?: string | null;
  zip?: string | null;
  countryCode?: string | null;
  homePhone?: string | null;
  mobilePhone?: string | null;
  email?: string | null;
  useAlternateMailingAddress?: boolean;
  alternateName?: string | null;
  alternateAddress1?: string | null;
  alternateAddress2?: string | null;
  alternateCity?: string | null;
  alternateStateCode?: string | null;
  alternateZip?: string | null;
  alternateCountryCode?: string | null;
  employerName?: string | null;
  employerAddress1?: string | null;
  employerAddress2?: string | null;
  employerCity?: string | null;
  employerStateCode?: string | null;
  employerZip?: string | null;
  employerCountryCode?: string | null;
  employerPhone?: string | null;
  bannerMessage?: string | null;
  defaultPlaceOfServiceId?: string | null;
  letterId?: string | null;
  isDeleted?: boolean;
  deletedBy?: string | null;
  deletedDate?: string | null;
  createdBy?: string | null;
  createdDate?: string;
  updatedBy?: string | null;
  updatedDate?: string | null;
}
