import { ReportGroup } from '../../models/report-group';
import { GroupBilling } from './group-billing';
import { GroupBillingService } from './group-billing.service';
import { HeldClaimsRegister } from './held-claims-register';
import { HeldClaimsRegisterService } from './held-claims-register.service';
import { InsuranceTracking } from './insurance-tracking';
import { InsuranceTrackingService } from './insurance-tracking.service';
import { ReimbursementAnalysis } from './reimbursement-analysis';
import { ReimbursementAnalysisService } from './reimbursement-analysis.service';

export const InsuranceReportGroup: ReportGroup = {
  id: 'insurance-reports',
  label: 'Insurance Reports',
  reports: [HeldClaimsRegister, GroupBilling, InsuranceTracking, ReimbursementAnalysis]
};

export const InsuranceReportsServices = [HeldClaimsRegisterService, GroupBillingService, InsuranceTrackingService, ReimbursementAnalysisService];
