import { Injectable } from '@angular/core';
import { ApiAutoCompleteService, FieldSelectOption } from 'components/shared';

import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import { CodeWrapperService } from '../service-wrappers/code-wrapper.service';
import { RvuWrapperService } from '../service-wrappers/rvu-wrapper.service';

@Injectable({
  providedIn: 'root'
})
export class RvuTypeLookupService extends ApiAutoCompleteService {
  constructor(private service: RvuWrapperService) {
    super();
  }
  rvuTypeEmpty$ = this.service
    .apiV1RvucodeAllPost({
      generalSearch: ''
    })
    .pipe(shareReplay(1));

  mapRow(result) {
    return {
      value: result.id,
      label: result.name
    };
  }

  search(value: string): Observable<FieldSelectOption[]> {
    if (value === '') {
      return this.rvuTypeEmpty$.pipe(map((results) => results.data.map(this.mapRow)));
    }

    return this.service
      .apiV1RvucodeAllPost({
        generalSearch: value
      })
      .pipe(map((results) => results.data.map(this.mapRow)));
  }
}
