import { AppState } from '../../core.state';

export interface AuthState {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  picture: string;
  authenticated: boolean;
  clientId: string;
}

export const initialState: AuthState = {
  id: '',
  email: '',
  firstName: '',
  lastName: '',
  picture: '',
  authenticated: false,
  clientId: ''
};

export interface State extends AppState {
  authData: AuthState;
}
