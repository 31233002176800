import { Injector, LOCALE_ID } from '@angular/core';
import { Validators } from '@angular/forms';
import { ReportWrapperService } from '../../../../core/services/service-wrappers/report-wrapper.service';
import { BodyClasses, ColumnSizes, FieldSelectOption, FilterType } from 'components';
import { DateDisplayPipe } from 'projects/components/src/lib/shared/pipes/date-display/date-display.pipe';
import { Report } from '../../models/report';
import { Store } from '@ngrx/store';
import { ChargesWithAccessionNumbersService } from './charges-with-accession-numbers.service';
import {
  ProcedureCodesLookupService
} from 'src/app/core/services/lookup';

export const ChargesWithAccessionNumbers: Report = {
  id: 'charges-with-accession-numbers',
  label: 'Procedures With Accession Numbers',
  textCriteria: {
    'Range Type': 'S', 'Start Date': '', 'End Date': '', 'Include / Exclude Procedures': 'I', 'Procedure Code': '',
    'Exclude Reversed Procedures': false
  },
  cleanFilterParams: {
    'procedureCodeIds': ''
  },
  getReportTitle: () => {
    return ChargesWithAccessionNumbers.label + ' ' + ChargesWithAccessionNumbers.textCriteria['Start Date'] + ' - ' + ChargesWithAccessionNumbers.textCriteria['End Date']
      + ' (' + ChargesWithAccessionNumbers.textCriteria['Date Range Type'] + ')';
  },
  getSearchCriteria: (injector: Injector) => [
    {
      title: '',
      controls: [
        {
          label: 'Range Type',
          name: 'dateRangeType',
          type: 'select',
          class: 'form-span-3',
          options: [
            {
              label: 'Service Date',
              value: 'S'
            },
            {
              label: 'Posting Date',
              value: 'P'
            }
          ],
          initial: 'S',
          validators: Validators.required,
          selectionChanged: (event) => {
            ChargesWithAccessionNumbers.textCriteria['Date Range Type'] = event.source.triggerValue;
          }
        },
        {
          label: 'Date Range',
          name: 'dateRange',
          type: 'dateRange',
          class: 'form-span-5',
          daterangeStartChanged: (event) => {
            ChargesWithAccessionNumbers.textCriteria['Start Date'] = event.value.toLocaleDateString();
          },
          daterangeEndChanged: (event) => {
            ChargesWithAccessionNumbers.textCriteria['End Date'] = event.value.toLocaleDateString();
          }
        },
        {
          label: '',
          name: 'dateRangeStart',
          type: 'noInput',
          class: 'display-none',
          validators: Validators.required
        },
        {
          label: '',
          name: 'dateRangeEnd',
          type: 'noInput',
          class: 'display-none',
          validators: Validators.required
        },
        {
          label: 'Exclude Reversed Procedures',
          name: 'excludeReversals',
          type: 'checkbox',
          class: 'form-span-4 checkbox',
          checkedChanged: (event) => {
            ChargesWithAccessionNumbers.textCriteria['Exclude Reversed Procedures'] = event.source.triggerValue;
          }
        },
        {
          label: 'Include / Exclude Procedures',
          name: 'includeExcludeProcedures',
          class: 'form-span-3',
          type: 'select',

          options: [
            {
              label: 'Include',
              value: 'I'
            },
            {
              label: 'Exclude',
              value: 'E'
            }
          ],
          initial: 'I',
          validators: Validators.required,
          selectionChanged: (event) => {
            ChargesWithAccessionNumbers.textCriteria['Include / Exclude Procedures'] = event.source.triggerValue;
          }
        },
        {
          matLabel: 'Procedure Code',
          name: 'procedureCodeIds',
          placeholder: 'Procedure Code',
          apiService: injector.get(ProcedureCodesLookupService),
          class: 'form-span-9 multi-input',
          type: 'multiSelect',
          selectOptions: (event) => {
            let listValue = []
            let listText = []
            event.forEach((x: FieldSelectOption) => {
              listValue.push(x.value)
              listText.push(x.label)
            })
            ChargesWithAccessionNumbers.textCriteria['Procedure Codes'] = listText.join(',');
            ChargesWithAccessionNumbers.cleanFilterParams['procedureCodeIds'] = listValue

          }
        },



      ]
    }
  ],
  getGridConfiguration: (injector: Injector) => {
    const locale = injector.get(LOCALE_ID);
    const datePipe: DateDisplayPipe = new DateDisplayPipe(locale);
    const moneyFormat = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });
    return {
      service: injector.get<any>(ChargesWithAccessionNumbersService),
      columnDefinitions: [
        {
          id: 'Account Number',
          displayKey: 'Account Number',
          headerText: 'Account Number',
          displayOrder: 0,
          columnSize: ColumnSizes.narrow,
          sortable: true,
          filterable: true
        },
        {
          id: 'Last Name',
          displayKey: 'Last Name',
          headerText: 'Last Name',
          displayOrder: 1,
          columnSize: ColumnSizes.narrow,
          sortable: true,
          filterable: true
        },
        {
          id: 'First Name',
          displayKey: 'First Name',
          headerText: 'First Name',
          displayOrder: 2,
          columnSize: ColumnSizes.extraNarrow,
          sortable: true,
          filterable: true
        },
        {
          id: 'Location Name',
          displayKey: 'Location Name',
          headerText: 'Location Name',
          displayOrder: 3,
          columnSize: ColumnSizes.extraWide,
          sortable: true,
          filterable: true
        },
        {
          id: 'Service Date',
          displayKey: 'Service Date',
          headerText: 'Service Date',
          displayOrder: 4,
          columnSize: ColumnSizes.extraNarrow,
          filterable: true,
          sortable: true,
          filterConfig: {
            filterType: FilterType.date,
            filterKey: 'Service Date'
          },
          formatter: (x) => {
            return datePipe.transform(x)
          }
        },
        {
          id: 'Posting Date',
          displayKey: 'Posting Date',
          headerText: 'Posting Date',
          displayOrder: 4,
          columnSize: ColumnSizes.narrow,
          filterable: true,
          sortable: true,
          filterConfig: {
            filterType: FilterType.date,
            filterKey: 'Posting Date'
          },
          formatter: (x) => {
            return datePipe.transform(x)
          }
        },
        {
          id: 'Procedure Code',
          displayKey: 'Procedure Code',
          headerText: 'Procedure Code',
          displayOrder: 5,
          columnSize: ColumnSizes.extraNarrow,
          sortable: true,
          filterable: true
        },
        {
          id: 'Description',
          displayKey: 'Description',
          headerText: 'Description',
          displayOrder: 6,
          columnSize: ColumnSizes.extraWide,
          sortable: true,
          filterable: true,
        },
        {
          id: 'Accession Number',
          displayKey: 'Accession Number',
          headerText: 'Accession Number',
          displayOrder: 7,
          columnSize: ColumnSizes.extraNarrow,
          sortable: true,
          filterable: true,
        }
      ]
    };
  }
};

