import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  BodyClasses,
  ColumnDefinition,
  ColumnDisplayType,
  ColumnSizes,
  GridComponent,
  GridConfiguration,
  OrderByPipe,
  DialogContent,
  FilterType
} from 'components';
import { map, take } from 'rxjs/operators';
import { DialogService } from 'src/app/core/services/dialog.service';
import { FinancialClassDialogComponent } from './financial-class-dialog/financial-class-dialog.component';
import { FinancialClassService } from './financial-class.service';
import { FinancialClassWrapperService } from 'src/app/core/services/service-wrappers/financial-class-wrapper.service';
import { compare } from 'fast-json-patch/module/duplex';
import { ActivatedRoute, Router } from '@angular/router';
import { ImplementationWrapperService } from '@core/services/service-wrappers/implementation-wrapper.service';
import { Store } from '@ngrx/store';
import { ImplementationState } from '../../implementation/state/implementation.state';
import { Observable, Subscription } from 'rxjs';
import { selectImplementation } from '../../implementation/state/implementation.selectors';
import * as ImplementationActions from '../../implementation/state/implementation.actions';
import { ImplementationViewModel } from 'projects/data/src/lib/model/implementationViewModel';
import { ImplementationDetailViewModel } from 'projects/data/src/lib/model/implementationDetailViewModel';
import { UploadCodesComponent } from '../upload-codes/upload-codes.component';
import { SessionStorageService } from '@core/services/session-storage.service';
import { UserDetailsViewModel } from 'data';


@Component({
  selector: 'app-financial-class',
  templateUrl: './financial-class.component.html',
  styleUrls: ['./financial-class.component.scss'],
  providers: [FinancialClassService]
})
export class FinancialClassComponent implements OnInit, OnDestroy {
  @ViewChild(GridComponent) grid: GridComponent;
  orderPipe: OrderByPipe = new OrderByPipe();
  activeFilter: string = 'active';
  userInfo: UserDetailsViewModel;
  viewOnly = false;
  viewOnlyOptions = [{ id: 'view', label: 'View' }];
  overflowMenuOptions = [
    {
      id: 'edit',
      label: 'Edit'
    },
    {
      id: 'activate',
      label: 'Activate',
      field: 'inactive',
      condition: true
    },
    {
      id: 'inactivate',
      label: 'Inactivate',
      field: 'inactive',
      condition: false
    }
  ];
  private columnDefinitions: ColumnDefinition[] = [
    {
      id: 'actions',
      displayKey: 'actions',
      displayType: ColumnDisplayType.actionList,
      columnSize: ColumnSizes.fixedTiny,
      bodyClasses: [BodyClasses.alignRight],
      headerText: '',
      displayOrder: 0,
      overflowMenuOptions: this.overflowMenuOptions,
      overflowMenuClicked: (value, data) => {
        if (value === 'edit') {
          this.openFinancialClassModal(data.id);
        } else if (value === 'view') {
          this.openFinancialClassModal(data.id, true);
        } else if (value === 'inactivate') {
          this.setInactive(data);
        } else if (value === 'activate') {
          this.setActive(data);
        }
      }
    },
    {
      id: 'number',
      displayKey: 'code',
      headerText: 'Code',
      displayOrder: 1,
      columnSize: ColumnSizes.fixedColumn,
      filterable: true,
      sortable: true,
      sticky: true
    },
    {
      id: 'description',
      displayKey: 'name',
      headerText: 'Description',
      displayOrder: 2,
      columnSize: ColumnSizes.extraWide,
      sortable: true,
      filterable: true
    }, 
    {
      id: 'statementMinimum',
      displayKey: 'statementMinimum',
      headerText: 'Statement Min. Balance',
      displayOrder: 3,
      columnSize: ColumnSizes.narrow,
      sortable: true,
      filterable: true,
      filterConfig: {
        filterKey: 'statementMinimum',
        filterType: FilterType.number,
        comparisonType: 5
      }
    },
    {
      id: 'billHold',
      displayKey: 'holdBilling',
      headerText: 'Billing Held',
      displayOrder: 4,
      columnSize: ColumnSizes.narrow,
      sortable: true,
      filterable: true,
      formatter: (billHold, financialClass) => {
        if (financialClass.holdBilling === true) {
          return 'Yes';
        } else {
          return 'No';
        }
      },
      filterConfig: {
        filterKey: 'holdBilling',
        filterType: FilterType.boolean,
        comparisonType: 0
      }
    }
  ];
  gridConfig: GridConfiguration = {
    columnDefinitions: this.columnDefinitions,
    displayColumns: this.orderPipe.transform(this.columnDefinitions, 'displayOrder').map((x) => x.id)
  };

  dataSource: FinancialClassService;
  constructor(
    private financialClassService: FinancialClassService,
    public dialog: MatDialog,
    private service: FinancialClassWrapperService,
    private dialogService: DialogService,
    private activatedRoute: ActivatedRoute,
    private implementationService: ImplementationWrapperService,
    private router: Router,
    private store: Store<ImplementationState>,
    private sessionStorageService: SessionStorageService
  ) {
    this.userInfo = this.sessionStorageService.getItem("user-info");
    this.viewOnly = this.userInfo?.userPermissions.find(x => x.permissionType == "SystemMaintenance")?.accessType !== "Update";
    if (this.viewOnly) {
      this.columnDefinitions[0].overflowMenuOptions = this.viewOnlyOptions;
    }
    this.dataSource = financialClassService;
    this.dataSource.activeFilter = 'active';
  }

  addFinancialClass() {
    this.openFinancialClassModal(null);
  }

  openFinancialClassModal(financialClassId: string, viewOnly = false) {
    const dialog = this.dialog.open(FinancialClassDialogComponent, {
      disableClose: true,
      autoFocus: false,
      data: { financialClassId, viewOnly }
    });

    dialog.afterClosed().subscribe((result) => {
      if (result) {
        this.grid.loadData();
      }
    });
  }

  setInactive(data) {
    const modalContent: DialogContent = {
      header: 'Inactivate Financial Class',
      body: `Are you sure you want to Inactivate "${data.name}"?`,
      cancelButtonText: 'Cancel',
      OKButtonText: 'Inactivate'
    };
    this.dialogService.showConfirm(modalContent).subscribe((result) => {
      if (result) {
        if (data.inactive) {
          alert(`${data.name} is already Inactive`);
          this.grid.loadData();
        } else {
          const formData = {
            inactive: true
          }
          this.service
            .apiV1FinancialClassPatchIdPatch(data.id, compare({}, formData))
            .pipe(
              map((x: any) => x),
              take(1)
            )
            .subscribe((x) => {
              this.grid.loadData();
            });
        }
      }
    });
  }

  setActive(data) {
    const modalContent: DialogContent = {
      header: 'Activate Financial Class',
      body: `Are you sure you want to activate "${data.name}"?`,
      cancelButtonText: 'Cancel',
      OKButtonText: 'Activate'
    };
    this.dialogService.showConfirm(modalContent).subscribe((result) => {
      if (result) {
        if (!data.inactive) {
          alert(`${data.name} is already Active`);
          this.grid.loadData();
        } else {
          const formData = {
            inactive: false
          }
          this.service
            .apiV1FinancialClassPatchIdPatch(data.id, compare({}, formData))
            .pipe(
              map((x: any) => x),
              take(1)
            )
            .subscribe((x) => {
              this.grid.loadData();
            });
        }
      }
    });
  }

  uploadCodes() {
    const dialog = this.dialog.open(UploadCodesComponent, {
      disableClose: true,
      autoFocus: false,
      height: '600px',
      width:'600px',
      data: {code: 'financial-class'}
    });

    dialog.afterClosed().subscribe((result) => {
        this.grid.loadData();
    });
  }
  
  public isImplementation: boolean = false;
  public gonext: boolean = false;
  public itemCount: number = 0;
  private activatedRouteSub: Subscription;
  private itemCountSub: Subscription;
  private implementation$: Observable<ImplementationViewModel> = this.store.select(selectImplementation);;
  private implementationSub: Subscription;
  private implementation: ImplementationViewModel = null;
  private implementationDetail: ImplementationDetailViewModel = null;
  private nextImplementationDetail: ImplementationDetailViewModel = null;
  private STEP_CODE = 'financial-classes'

  implementationSubscribe() {
    this.activatedRouteSub = this.activatedRoute.data.subscribe(data => {
      this.isImplementation = data.implementation;

      if (this.isImplementation) {
        this.itemCountSub = this.financialClassService.itemCountSubject.subscribe((c) => this.itemCount = c);
        this.implementationSub =
          this.implementation$.subscribe((i) => {
            this.implementation = i;
            this.implementationDetail = this.implementation.details.find(d => d.implementationStepCode == this.STEP_CODE);
            this.nextImplementationDetail = this.implementation.details.find(d => d.order == this.implementationDetail.order + 1);
          });
      }
    });
    this.store.dispatch(ImplementationActions.updateImplementationStep({ stepCode: this.STEP_CODE }));
  }

  implementationUnsubscribe() {
    if (this.isImplementation) {
      this.activatedRouteSub.unsubscribe();
      this.itemCountSub.unsubscribe();
      this.implementationSub.unsubscribe();
    }
  }

  next(): void {
    if (this.implementationDetail.status == "InProgress") {
      this.gonext = true;
      this.implementationService
        .apiV1ImplementationCompleteStepCodePost(this.STEP_CODE)
        .subscribe(
          () => {
            this.gonext = false;
            this.router.navigateByUrl('/implementation/' + this.nextImplementationDetail.implementationStepCode);
          },
          () => this.gonext = false
        );
    }
    else {
      this.router.navigateByUrl('/implementation/' + this.nextImplementationDetail.implementationStepCode);
    }
  }

  ngOnInit(): void {
    this.implementationSubscribe();
  }
  ngOnDestroy(): void {
    this.implementationUnsubscribe();
  }
  showInactiveChanges(event) {
    this.activeFilter = event.checked ? 'active' : 'inactive';
    this.dataSource.activeFilter = this.activeFilter;
    this.grid.loadData();
  }
}
