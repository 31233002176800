import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SteppedFormGroupDefinition } from 'components';
import { BaseFormComponent } from '../../components/base-form.component';

@Component({
  selector: 'lib-collapsible-form',
  templateUrl: './collapsible-form.component.html',
  styleUrls: ['./collapsible-form.component.scss']
})
export class CollapsibleFormComponent implements OnInit {
  @Input()
  title: string;

  @Input()
  step: string;

  @Input()
  isExpanded = true;

  constructor() {}

  ngOnInit(): void {}
}
