/**
 * ImaginePay API
 * This API facilitates partner integration within the platform.
 *
 * The version of the OpenAPI document: 22_12_0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ImaginePayApiModelsAuditHistoryTSYSResponseLogHistory } from './imaginePayApiModelsAuditHistoryTSYSResponseLogHistory';

export interface ImaginePayApiModelsAuditHistoryTransactionPaymentHistory {
  onlineAccountNumber?: string;
  onlinePaymentRequestID?: number;
  onlineTransactionID?: string;
  transactionPaymentID?: number;
  amount?: number;
  paymentType?: string;
  status?: ImaginePayApiModelsAuditHistoryTransactionPaymentHistory.StatusEnum;
  gateway?: string;
  isPriorBalance?: boolean;
  isVerified?: boolean;
  gatewayResponses?: Array<ImaginePayApiModelsAuditHistoryTSYSResponseLogHistory>;
}
export namespace ImaginePayApiModelsAuditHistoryTransactionPaymentHistory {
  export type StatusEnum = 'PROCESSED' | 'VOIDED' | 'FAILED';
  export const StatusEnum = {
    Processed: 'PROCESSED' as StatusEnum,
    Voided: 'VOIDED' as StatusEnum,
    Failed: 'FAILED' as StatusEnum
  };
}
