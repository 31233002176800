<div class="dialog-form report-form">
  <div class="dialog-form__close">
    <a mat-dialog-close>
      <mat-icon>close</mat-icon>
    </a>
  </div>
  <header mat-dialog-title class="dialog-form__title">
    <h2>Location</h2>
  </header>
  <main mat-dialog-content>
    <div class="dialog-form__container">
      <ng-container *ngIf="formInitialized">
        <lib-dynamic-form [dynamicFormGroup]="formGroup" [formGroupDefinitions]="formDefinitions"> </lib-dynamic-form>
      </ng-container>
    </div>
    <h3>Location Providers</h3>
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
      <!--- Note that these columns can be defined in any order.
    The actual rendered columns are set as a property on the row definition" -->
      <!-- Position Column -->
      <ng-container matColumnDef="title">
        <th mat-header-cell *matHeaderCellDef>Title</th>
        <td mat-cell *matCellDef="let element">{{ element.title }}</td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="lastName">
        <th mat-header-cell *matHeaderCellDef>Last Name</th>
        <td mat-cell *matCellDef="let element">{{ element.lastName }}</td>
      </ng-container>

      <!-- Weight Column -->
      <ng-container matColumnDef="firstName">
        <th mat-header-cell *matHeaderCellDef>First Name</th>
        <td mat-cell *matCellDef="let element">{{ element.firstName }}</td>
      </ng-container>

      <!-- Symbol Column -->
      <ng-container matColumnDef="npi">
        <th mat-header-cell *matHeaderCellDef>NPI</th>
        <td mat-cell *matCellDef="let element">{{ element.npi }}</td>
      </ng-container>
      <ng-container matColumnDef="providerNumber">
        <th mat-header-cell *matHeaderCellDef>Provider Number</th>
        <td mat-cell *matCellDef="let element">{{ element.providerNumber }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <mat-paginator #paginator [pageSize]="25" [pageSizeOptions]="[25, 50, 100, 250, 500]"> </mat-paginator>
  </main>
  <div mat-dialog-actions class="buttons">
    <button mat-stroked-button mat-dialog-close *ngIf="viewOnly">Close</button>
    <button class="cancel-button" mat-stroked-button mat-dialog-close *ngIf="!viewOnly">Cancel</button>
    <lib-button-loading
      color="primary"
      class="save-button"
      [loading]="saving"
      [invalid]="formGroup.invalid"
      (clickEvent)="save()"
      *ngIf="!viewOnly"
      >Save
    </lib-button-loading>
  </div>
</div>
