<div class="lib-dynamic-form">
  <header class="lib-dynamic-form__form-header">
    <h3>{{ title }}</h3>
  </header>
  <form *ngIf="dynamicFormGroup" [formGroup]="dynamicFormGroup">
    <lib-form-group
      *ngFor="let fieldGroup of formGroupDefinitions; trackBy: trackByIndex"
      [title]="fieldGroup.title"
      [stackedTitle]="fieldGroup?.stackedTitle || false"
      [hideTitle]="fieldGroup?.hideTitle || false"
      [hide]="fieldGroup.hide"
      [disabled]="disabled"
      [formGroup]="dynamicFormGroup"
      [formGroupDefinition]="fieldGroup.controls"
    ></lib-form-group>
  </form>
</div>
