import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BaseWrapperService } from "./base-wrapper.service";
import { AddDatasetViewModel, DatasetDetailsViewModel, DatasetDetailsViewModelPagedModelResponse, PagedModelRequest } from "data";
import { DatasetService } from "data";
import { SessionStorageService } from "../session-storage.service";

@Injectable({
  providedIn: 'root'
})
export class DatasetWrapperService extends BaseWrapperService {
  constructor(private service: DatasetService, protected sessionStorageService: SessionStorageService) {
    super();
  }

  apiV1DatasetAddPost(dataset: AddDatasetViewModel): Observable<AddDatasetViewModel> {
    return this.service.apiV1DatasetAddPost(dataset)
  }

  apiV1DatasetDuplicatePost(datasetId: string, dataset: AddDatasetViewModel): Observable<DatasetDetailsViewModel> {
    return this.service.apiV1DatasetDuplicatePost(datasetId, dataset)
  }

  apiV1DatasetAllDetailPost(requestBody: PagedModelRequest): Observable<DatasetDetailsViewModelPagedModelResponse> {
    return this.service.apiV1DatasetAllDetailPost(requestBody)
  }
  apiV1DatasetAllPost(requestBody: PagedModelRequest): Observable<DatasetDetailsViewModelPagedModelResponse> {
    return this.service.apiV1DatasetAllPost(requestBody)
  }
  apiV1DatasetDetailsIdGet(id:string): Observable<DatasetDetailsViewModel> {
    return this.service.apiV1DatasetDetailsIdGet(id)
  }
  apiV1DatasetPatchIdPatch(id: string, operation): Observable<DatasetDetailsViewModel> {
    return this.service.apiV1DatasetPatchIdPatch(id, operation)
  }
  apiV1DatasetAllUserPost(requestBody: PagedModelRequest): Observable<DatasetDetailsViewModelPagedModelResponse> {
    return this.service.apiV1DatasetAllUserPost(requestBody)
  }
}
