import { Injectable } from '@angular/core';
import { ReimbursementAnalysisReportRequest, ReportDownloadRequest, ReportRequest, ReportService } from 'data';
import { BaseWrapperService } from './base-wrapper.service';
import { SessionStorageService } from '../session-storage.service';

@Injectable({
  providedIn: 'root'
})
export class ReportWrapperService extends BaseWrapperService {
  constructor(private service: ReportService, protected sessionStorageService: SessionStorageService) {
    super();
  }

  apiV1ReportPost(reportRequest?: any) {
    return this.service.apiV1ReportPost(this.getDataSetId(), reportRequest);
  }
  apiV1ReportDownloadPost(reportRequest?: any) {
    return this.service.apiV1ReportDownloadPost(this.getDataSetId(), reportRequest);
  }
  apiV1ReportDownloadExecutedIdPost(id: string) {
    return this.service.apiV1ReportDownloadExecutedIdPost(id, this.getDataSetId());
  }

  apiV1ReportDownloadReimbursementIdPost(id: string) {
    return this.service.apiV1ReportDownloadReimbursementIdPost(id, this.getDataSetId(), 'response');
  }
  apiV1ReportDownloadReimbursementIdPostText(id: string) {
    return this.service.apiV1ReportDownloadReimbursementIdPost(id, this.getDataSetId(), 'body');
  }

  apiV1ReportDownloadExecutedPost(reportDownloadRequest: ReportDownloadRequest) {
    return this.service.apiV1ReportDownloadExecutedPost(this.getDataSetId(), reportDownloadRequest, 'response');
  }

  apiV1ReportReimbursementAnalysisPostFile(reimbursementAnalysisRequest: ReimbursementAnalysisReportRequest) {
    return this.service.apiV1ReportReimbursementAnalysisPost(
      this.getDataSetId(),
      reimbursementAnalysisRequest,
      'response'
    );
  }

  apiV1ReportReimbursementAnalysisPostText(reimbursementAnalysisRequest: ReimbursementAnalysisReportRequest) {
    return this.service.apiV1ReportReimbursementAnalysisPost(this.getDataSetId(), reimbursementAnalysisRequest, 'body');
  }
}
