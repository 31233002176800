import { Injectable } from '@angular/core';
import { ApiAutoCompleteService, FieldSelectOption } from 'components/shared';
import { registerUpdateLifecycle } from 'echarts';

import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import { LocationsWrapperService } from '../service-wrappers/locations-wrapper.service';

@Injectable({
  providedIn: 'root'
})
export class LocationsLookupService extends ApiAutoCompleteService {
  location$ = this.service.apiV1LocationAllPost({ generalSearch: '', pageSize: 99999}).pipe(shareReplay(1));
  constructor(private service: LocationsWrapperService) {
    super();
    this.dynamicSearch = true;
  }

  existingValues: string []= [];

  mapRow(result) {
    return {
      value: result.id,
      label: `${result.code}- ${result.name}`,
      shortLabel: result.code,
      inactive: result.inactive
    };
  }

  search(value: string): Observable<FieldSelectOption[]> {
    if (value === '') {
      return this.location$.pipe(
        map((results) => results.data.map(this.mapRow).filter((x: any) => !x.inactive || this.existingValues.includes(x.value)))
      );
    }
    return this.service.apiV1LocationAllPost({
      generalSearch: value, pageSize: 100, propertySearchOr: true,
      propertySearches: [
        { propertyName: 'code', value: value, comparisonType: 1 }, { propertyName: 'name', value: value, comparisonType: 1 },
      ] }).pipe(
      map((results) => results.data.map(this.mapRow).filter((x: any) => !x.inactive || this.existingValues.includes(x.value)))
    );
  }

  private cache: Observable<string>[] = [];
  load(id: string): Observable<FieldSelectOption> {
    if (!this.cache[id]) {
      this.cache[id] = this.service.apiV1LocationIdGet(id).pipe(map(this.mapRow)).pipe(shareReplay());
    }

    return this.cache[id];
  }

  loadByIds(ids: string[]): Observable<FieldSelectOption[]> {
    return this.service.loadByIds(ids).pipe(map((results) => results.map(this.mapRow)));
  }
}
