import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FormGroupDefinition } from '../models';

@Component({
  selector: 'lib-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss']
})
export class DynamicFormComponent implements OnInit {
  @Input() class = '';
  @Input() title: string;
  @Input() disabled: boolean;
  @Input() dynamicFormGroup?: UntypedFormGroup;
  @Input() formGroupDefinitions: FormGroupDefinition[];

  constructor() {}

  ngOnInit(): void { }

  trackByIndex(index: number, item: any) {
    return index
  }
}
