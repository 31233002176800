<header class="sub-page-layout__header modifiers__header">
  <div class="modifiers__header-text h2 h2--semi-bold">Modifiers</div>
  <mat-slide-toggle
    class="modifiers__active-toggle"
    [checked]="activeFilter === 'active'"
    color="primary"
    (change)="showInactiveChanges($event)"
  >
    <div *ngIf="activeFilter !== 'inactive'">Active</div>
    <div *ngIf="activeFilter === 'inactive'">Inactive</div>
  </mat-slide-toggle>
  <button mat-flat-button color="primary" class="modifiers__add-item" (click)="uploadCodes()" *ngIf="!viewOnly">
    <mat-icon>file_upload</mat-icon> Upload Modifiers
  </button>
  <button mat-flat-button color="primary" class="modifiers__add-item" (click)="addModifiers()" *ngIf="!viewOnly">
    <mat-icon>add_circle_outline</mat-icon> Add Modifier
  </button>
</header>
<div *ngIf="dataSource && gridConfig" class="lib-grid__grid-container">
  <lib-grid [dataSource]="dataSource" [gridConfiguration]="gridConfig" skipLoadOnInit="true" loadAfterInit="true">
    <div class="">
      <h4>No Modifiers Found.</h4>
    </div>
  </lib-grid>
</div>
<footer *ngIf="isImplementation && itemCount > 0" class="modifiers__footer">
  <lib-button-loading color="primary" class="modifiers__button" [invalid]="itemCount === 0" (clickEvent)="next()"
    >Next
  </lib-button-loading>
</footer>
