import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { ColumnDefinition } from 'components';
import { of } from 'rxjs';
import { catchError, filter, map, take } from 'rxjs/operators';
import { BaseReportGridSource } from '../base-report-grid-source';
import { ReportWrapperService } from '../../../../core/services/service-wrappers/report-wrapper.service';
import { DateDisplayPipe } from 'projects/components/src/lib/shared/pipes/date-display/date-display.pipe';
import { saveAs } from 'file-saver';
import { Store } from '@ngrx/store';
import { getSelectedDataSetId } from 'src/app/core/state/data-set/data-set.selectors';

@Injectable({
  providedIn: 'root'
})
export class CollectionRatioReportService extends BaseReportGridSource<any> {
  dataSetId$ = this.baseStore.select(getSelectedDataSetId).pipe(filter((cId) => !!cId));
  dataSetId;

  constructor(private reportService: ReportWrapperService, @Inject(LOCALE_ID) private locale: string, private baseStore: Store<any>) {
    super();
    this.dataSetId$.subscribe((dataSetIdFromStore) => {
      this.dataSetId = dataSetIdFromStore;
    });
  }
  datePipe: DateDisplayPipe = new DateDisplayPipe(this.locale);
  initialize(query: any, columnDefinitions: ColumnDefinition[]) {
    this.gridColumns = columnDefinitions;
    const request = {
      slug: 'collection-ratio',
      datasetId: this.dataSetId,
      dateRangeType: query.dateRangeType,
      dateRangeStart: query.dateRangeStart,
      dateRangeEnd: query.dateRangeEnd,
      insuranceLevel: query.insuranceLevel,
      financialClassIds: query.financialClassIds,
      procedureIds: query.procedureIds,
      textParameters: this.textParameters
    };
    this.loadInitialData(
      this.reportService.apiV1ReportPost(request).pipe(
        map((x) => {
          this.textParameters['User'] = x.processedBy;
          this.datasetName = x.datasetName;
          this.reportExecutionId = x.reportExecutionId;
          return x.data.map((data) => {
            return { ...data, 'Posting Date': new Date(data['Posting Date']) };
          });
        }),
        catchError(() => of([]))
      )
    );
  }
}
