/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EligbilityValidationResponseViewModel } from './eligbilityValidationResponseViewModel';
import { EligibilityReceiverResponseViewModel } from './eligibilityReceiverResponseViewModel';

export interface EligibilitySourceResponseViewModel {
  id?: string;
  datasetId?: string;
  eligibilityResponseFileId?: string;
  payorEntityIdentifierCode?: string | null;
  payorEntityType?: string | null;
  payorLastOrgName?: string | null;
  payorFirstName?: string | null;
  payorMiddleName?: string | null;
  payorIdentificationQualifierCode?: string | null;
  payorIdentificationCode?: string | null;
  payorContactName?: string | null;
  payorCommNumberQualifier?: string | null;
  payorCommNumber?: string | null;
  payorCommNumberQualifier2?: string | null;
  payorCommNumber2?: string | null;
  payorCommNumberQualifier3?: string | null;
  payorCommNumber3?: string | null;
  eligibilityReceiverResponses?: Array<EligibilityReceiverResponseViewModel> | null;
  sourceValidations?: Array<EligbilityValidationResponseViewModel> | null;
}
