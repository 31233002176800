/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EligibilityDeliveryViewModel } from './eligibilityDeliveryViewModel';
import { EligibilityRelatedViewModel } from './eligibilityRelatedViewModel';
import { EligibilityReferenceViewModel } from './eligibilityReferenceViewModel';
import { EligibilityTimePeriodViewModel } from './eligibilityTimePeriodViewModel';
import { EligibilityServiceTypeViewModel } from './eligibilityServiceTypeViewModel';
import { EligibilityValidationViewModel } from './eligibilityValidationViewModel';

export interface EligibilityBenefitsViewModel {
  id?: string;
  eligibilityBenefitInformationCode?: string | null;
  eligibilityBenefitInformationName?: string | null;
  coverageLevelCode?: string | null;
  coverageLevelName?: string | null;
  insuranceTypeCode?: string | null;
  insuranceTypeName?: string | null;
  planCoverageDescription?: string | null;
  timePeriodQualifierCode?: string | null;
  timePeriodQualifierName?: string | null;
  monetaryAmount?: number;
  percentage?: number;
  quantityQualifierCode?: string | null;
  quantityQualifierName?: string | null;
  quantity?: number;
  authorizationRequired?: string | null;
  inNetwork?: string | null;
  compositeMedicalProcedureIdentifier?: string | null;
  diagnosisCodePointer?: string | null;
  serviceTypes?: Array<EligibilityServiceTypeViewModel> | null;
  serviceDelivery?: Array<EligibilityDeliveryViewModel> | null;
  timePeriods?: Array<EligibilityTimePeriodViewModel> | null;
  references?: Array<EligibilityReferenceViewModel> | null;
  validations?: Array<EligibilityValidationViewModel> | null;
  relatedEntities?: Array<EligibilityRelatedViewModel> | null;
  messages?: Array<string> | null;
}
