/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface PagedListMetaData {
  readonly pageCount?: number;
  readonly totalItemCount?: number;
  readonly pageNumber?: number;
  readonly pageSize?: number;
  readonly hasPreviousPage?: boolean;
  readonly hasNextPage?: boolean;
  readonly isFirstPage?: boolean;
  readonly isLastPage?: boolean;
  readonly firstItemOnPage?: number;
  readonly lastItemOnPage?: number;
}
