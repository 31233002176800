/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ProcedureDiagnosisCode } from './procedureDiagnosisCode';

export interface ProcedureTransactionViewModel {
  id?: string;
  datasetId?: string;
  visitId?: string;
  procedureId?: string;
  nocProcedureDescription?: string | null;
  nationalDrugCodeId?: string | null;
  attendingProviderId?: string | null;
  referringProviderId?: string | null;
  orderingProviderId?: string | null;
  primaryProviderId?: string | null;
  supervisingProviderId?: string | null;
  serviceFromDate?: string;
  serviceToDate?: string;
  postingDate?: string;
  placeOfServiceCodeId?: string;
  locationId?: string;
  modifier1Id?: string | null;
  modifier2Id?: string | null;
  modifier3Id?: string | null;
  modifier4Id?: string | null;
  procedureAmount?: number;
  accessionNumber?: string | null;
  drugUnit?: string | null;
  holdPatientBilling?: boolean | null;
  holdInsuranceBilling?: boolean | null;
  isReversed?: boolean;
  drugQuantity?: number | null;
  drugAmount?: number | null;
  prescriptionNumber?: string | null;
  drugCode?: string | null;
  quantity?: number;
  insuranceAuthNumber?: string | null;
  createdBy?: string | null;
  enteredBy?: string | null;
  postedBy?: string | null;
  claimComment?: string | null;
  chargeGroup?: number | null;
  diagnoses?: Array<ProcedureDiagnosisCode> | null;
}
