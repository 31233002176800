import { Component, Inject, OnInit, Optional } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectRemindersLoading, selectAll as selectAllReminders } from '../../state/reminders/reminders.selectors';
import { updateReminders } from '../../state/reminders/reminders.actions';
import { getSelectedDataSetId } from '../../state/data-set/data-set.selectors';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
type ReminderView = 'list' | 'form';

@Component({
  selector: 'app-reminders',
  templateUrl: './reminders.component.html',
  styleUrls: ['./reminders.component.scss']
})
export class RemindersComponent implements OnInit {
  disableCreateNote = false;
  reminderView: ReminderView = 'list';
  patientId;
  isDialog = false;

  constructor(
    private store: Store<any>,
    @Optional() public dialogRef: MatDialogRef<RemindersComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {}
  areRemindersLoading = this.store.select(selectRemindersLoading);
  existingReminders = this.store.select(selectAllReminders);

  clientId$ = this.store.select(getSelectedDataSetId);
  ngOnInit(): void {
    if (this.data) {
      this.patientId = this.data?.patientId;
      this.isDialog = true;
      this.showForm();
    }
  }
  showForm() {
    this.reminderView = 'form';
    this.disableCreateNote = true;
  }
  hideForm(event) {
    if (event === true) {
      this.store.dispatch(updateReminders());
    }

    this.reminderView = 'list';
    this.disableCreateNote = false;
  }

  close() {
    this.dialogRef.close(false);
  }
}
