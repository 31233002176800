<div class="spinner-container" *ngIf="dataSource?.loading$ | async">
  <mat-spinner></mat-spinner>
</div>
<div class="grid-wrapper">
  <table
    mat-table
    [class.grid]="true"
    [class.has-filter]="hasFilters"
    [class.has-row-click]="this.gridConfiguration?.rowClickEvent"
    [dataSource]="dataSource"
    matSort
  >
    <ng-container
      *ngFor="let column of gridConfiguration?.columnDefinitions; trackBy: trackById"
      [matColumnDef]="column.id"
      [sticky]="column?.sticky"
    >
      <th
        mat-header-cell
        *matHeaderCellDef
        [ngClass]="column | headerColumnClass"
        [mat-sort-header]="column.displayKey"
        [disabled]="!column.sortable"
      >
        <span class="label"> {{ column.headerText }}</span>
      </th>

      <td
        mat-cell
        *matCellDef="let data; let i = index"
        [ngClass]="column | bodyColumnClass"
        (click)="cellClick($event, column.id, data)"
        [id]="camelCaseRemoveSpaces(column.id) + i"
      >
        <ng-container [ngSwitch]="column.displayType">
          <ng-container *ngSwitchCase="columnTypes.routerLink">
            <a [routerLink]="data[column.routerLink]">
              <ng-container *ngTemplateOutlet="cellContent"></ng-container>
            </a>
          </ng-container>
          <ng-container *ngSwitchCase="columnTypes.chipList">
            <div class="grid-chip-list">
              <span
                *ngFor="let chip of data[column.displayKey]; trackBy: trackByIndex"
                class="grid-chip grid-chip-{{ chip.type }}"
                >{{ chip.text }}</span
              >
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="columnTypes.actionList">
            <lib-overflow-menu
              [options]="column.overflowMenuOptions"
              [data]="data"
              (optionClicked)="column.overflowMenuClicked($event, data)"
              *ngIf="column.overflowMenuOptions && column.overflowMenuOptions?.length > 0"
            ></lib-overflow-menu>
          </ng-container>
          <ng-container *ngSwitchCase="columnTypes.statusList">
            <div class="cell-status-list">
              <div
                *ngFor="
                  let item of data[column.displayKey];
                  index as i;
                  even as even;
                  last as last;
                  trackBy: trackByIndex
                "
              >
                <span *ngIf="even">{{ item }}</span>
                <span *ngIf="even && !last">{{ data[column.displayKey][i + 1] }}</span>
              </div>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="columnTypes.selectBox">
            <mat-checkbox (change)="toggleCheckbox(data)" [checked]="selection.isSelected(data)"></mat-checkbox>
          </ng-container>
          <ng-container *ngSwitchDefault>
            <ng-container *ngTemplateOutlet="cellContent"></ng-container>
          </ng-container>
        </ng-container>

        <ng-template #cellContent>
          <span [ngClass]="{ bold: column.bold_condition ? column.bold_condition(data) : false }">
            {{ column.formatter ? column.formatter(data[column.displayKey], data) : data[column.displayKey] }}
            <div *ngIf="column.status_icon" class="cell-status-icon">
              <mat-icon
                *ngIf="column.show_icon_condition ? column.show_icon_condition(data) : false"
                class="{{ column.icon_class(data) }}"
                matTooltip="{{ column.icon_tooltip(data) }}"
              >
                {{ column.status_icon(data) }}
              </mat-icon>
            </div>
          </span>

          <div *ngIf="column.cellActionButton" class="cell-action-button">
            <button
              mat-stroked-button
              (click)="cellActionButtonClick($event, column.displayKey, data)"
              matTooltip="{{ column.cellActionButton?.tooltip }}"
            >
              <span class="material-icons" *ngIf="column.cellActionButton?.icon">
                {{ column.cellActionButton?.icon }}
              </span>
              {{ column.cellActionButton?.text }}
            </button>
          </div>
        </ng-template>
      </td>
    </ng-container>

    <ng-container
      [matColumnDef]="column.id + '-filter'"
      *ngFor="let column of gridConfiguration?.columnDefinitions; trackBy: trackById"
      [sticky]="column?.sticky"
    >
      <th mat-header-cell *matHeaderCellDef [ngClass]="column.columnSize">
        <lib-grid-filter
          *ngIf="column.filterable"
          [formGroup]="filterFormGroup"
          [controlName]="column.displayKey"
          [filterConfig]="column?.filterConfig"
          class="lib-grid-filter"
          [id]="column.displayKey + '_filter'"
        >
        </lib-grid-filter>
        <mat-checkbox
          *ngIf="column.displayType === 6"
          (change)="$event ? masterToggle() : null"
          [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()"
          id="selectAll-checkbox"
        >
        </mat-checkbox>
        <lib-overflow-menu
          *ngIf="column.displayType === 3 && column.selectAllMenuOptions && column.selectAllMenuOptions.length > 0"
          [options]="column.selectAllMenuOptions"
          [data]="data"
          (optionClicked)="column.selectAllMenuClicked($event, selection)"
        ></lib-overflow-menu>
      </th>
    </ng-container>

    <tr
      mat-header-row
      *matHeaderRowDef="gridConfiguration?.displayColumns; sticky: false"
      class="lib-grid__header-row"
    ></tr>
    <ng-container *ngIf="hasFilters">
      <tr
        mat-header-row
        *matHeaderRowDef="gridConfiguration?.displayColumns | filterColumns; sticky: false"
        class="lib-grid__filter-row"
      ></tr>
    </ng-container>

    <tr mat-row *matRowDef="let row; columns: gridConfiguration?.displayColumns" (click)="rowClick($event, row)"></tr>
  </table>
  <div class="empty-results" *ngIf="(dataSource?.itemCount$ | async) === 0 && (dataSource?.loading$ | async) === false">
    <ng-content></ng-content>
  </div>
</div>
<mat-paginator
  [length]="dataSource?.itemCount$ | async"
  [pageSize]="gridService.pageSize"
  [pageIndex]="gridService.pageIndex"
  [pageSizeOptions]="pageSizeOptions"
  [showFirstLastButtons]="true"
  id="paginator"
>
</mat-paginator>
