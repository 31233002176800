/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface NationalDrugCodeViewModel {
  id?: string;
  datasetId?: string;
  procedureId?: string;
  ndcCode?: string | null;
  ndcQuantity?: number;
  ndcDrugName?: string | null;
  ndcAmount?: number;
  ndcUnit?: string | null;
  ndcQualifier?: string | null;
  inactive?: boolean;
  createdBy?: string | null;
  createdDate?: string;
}
