/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { LocationLinkViewModel } from './locationLinkViewModel';
import { SendingFacilityViewModel } from './sendingFacilityViewModel';
import { PlaceOfServiceCodeLinkViewModel } from './placeOfServiceCodeLinkViewModel';
import { ProviderLinkViewModel } from './providerLinkViewModel';
import { DiagnosisCodeLinkViewModel } from './diagnosisCodeLinkViewModel';
import { ProcedureLinkViewModel } from './procedureLinkViewModel';

export interface SendingFacilityDataViewModel {
  sendingFacility?: SendingFacilityViewModel;
  procedure?: ProcedureLinkViewModel;
  diagnoses?: Array<DiagnosisCodeLinkViewModel> | null;
  attendingProvider?: ProviderLinkViewModel;
  referringProvider?: ProviderLinkViewModel;
  primaryProvider?: ProviderLinkViewModel;
  location?: LocationLinkViewModel;
  placeOfServiceCode?: PlaceOfServiceCodeLinkViewModel;
  insuranceCarrierName?: string | null;
}
