import { Pipe, PipeTransform } from '@angular/core';
import { map } from 'rxjs/operators';
import { ModifiersLookupService } from '../../../../../../../src/app/core/services/lookup';

@Pipe({
  name: 'modifierLabel'
})
export class ModifierLabelPipe implements PipeTransform {
  lookup: ModifiersLookupService
  label:string
  transform(value: string) {
    if (value !== null && value !== undefined && value !== "") {
   
      this.lookup.load(value).pipe(map((label) => {
        return label

      })).subscribe((result) => {
        return result.label
      })
         
      //   return label.label

       
      //}
      //else {
      //  return value
      //}

    }
  }
   
  }


