import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { MsalService } from "@azure/msal-angular";
import { UserWrapperService } from "@core/services/service-wrappers/user-wrapper.service";
import { SessionStorageService } from "@core/services/session-storage.service";
import { Observable, catchError, map, of } from "rxjs";
import { getBaseUrl } from '@core/utilities/runtime.utils';
import { AuthenticationWrapperService } from "@core/services/service-wrappers/authentication-wrapper.service";

@Injectable({
    providedIn: 'root'
})
export class SecurityService {
    claims: any;
    GET_AUTH_CLIENTS = '/api/v1/authentication/authorized-clients';

    constructor(private authService: MsalService, private router: Router, private userService: UserWrapperService,
        private sessionStorageService: SessionStorageService, private authenticationWrapperService: AuthenticationWrapperService) 
    {
    }

     canActivate(
       next: ActivatedRouteSnapshot,
       state: RouterStateSnapshot): Observable<boolean | UrlTree>
     {
         this.claims = this.authService.instance.getActiveAccount()?.idTokenClaims as Record<string, any>;

        if (this.claims != null) {
            return this.authenticationWrapperService.apiV1AuthenticationAuthorizedClientsIdGet(this.claims.sub)
                .pipe(map (a => {
                    let clientId = this.sessionStorageService.getItem('peregrine-client-id');
                    if (clientId == null || a.authorizedClients.findIndex(c => c === clientId) >= 0) return true;
                    return this.router.parseUrl('/unauthorized')
                }),
                catchError(err => { return of(this.router.parseUrl('/unauthorized'))}))
                
        }
     }
}
