import { Component, Inject, Injector, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { compare } from 'fast-json-patch';
import { first, map, take } from 'rxjs/operators';
import { CustomValidators, DialogContent, FieldSelectOption } from '../../../../../../../projects/components/src/public-api';
import { FeeScheduleEntryViewModel, PopulateFeeScheduleViewModel } from '../../../../../../../projects/data/src/public-api';
import { DialogService } from '../../../../../core/services/dialog.service';
import { FeeScheduleLookupService } from '../../../../../core/services/lookup/fee-schedule-lookup.service';
import { NotificationService } from '../../../../../core/services/notification.service';
import { FeeScheduleWrapperService } from '../../../../../core/services/service-wrappers/fee-schedule-wrapper.service';
import { FeeSchedulesEntryDialogComponent } from '../fee-schedules-entry-dialog/fee-schedules-entry-dialog.component';

@Component({
  selector: 'app-populate-fee-schedule-dialog',
  templateUrl: './populate-fee-schedule-dialog.component.html',
  styleUrls: ['./populate-fee-schedule-dialog.component.scss'],
  providers: [UntypedFormBuilder]
})
export class PopulateFeeScheduleDialogComponent implements OnInit {
  populateForm: UntypedFormGroup;
  feeScheduleId
  isEdit: boolean = false;
  feeEntries: FeeScheduleEntryViewModel[] = [];
  fee;
  saving: boolean = false;
  updateEntries: FeeScheduleEntryViewModel[] = [];
  createEntries: FeeScheduleEntryViewModel[] = [];
  entriesToSave: any[] = [];
  entries: FeeScheduleEntryViewModel[] = [];
  array = []
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<PopulateFeeScheduleDialogComponent>,
    private injector: Injector,
    public dialog: MatDialog,
    private dialogService: DialogService,
    private fb: UntypedFormBuilder,
    public lookup: FeeScheduleLookupService,
    private service: FeeScheduleWrapperService,
    private notification: NotificationService
  ) { this.feeScheduleId = data?.feeScheduleId; }

  ngOnInit(): void {
    this.service.apiV1FeeScheduleAllPost({ pageSize: 9999 }).subscribe((result) => {
      let entries = result.data.filter((entry) => {
        return entry.id !== this.feeScheduleId
      })
      entries.forEach((entry) => {
        let option: FieldSelectOption;
        option = {
          label: entry.name,
          value: entry.id
        }
        this.array.push(option)
      })
    })

    this.populateForm = this.fb.group({
      feeScheduleId: new UntypedFormControl('', Validators.required),
      percent: new UntypedFormControl('', [Validators.required, CustomValidators.percentageValidator]),
      updateFee: new UntypedFormControl(''),
      addNew: new UntypedFormControl('')
    })
    this.populateForm.get('feeScheduleId').valueChanges.subscribe((result) => {
      if (result) {
        this.populateForm.get('percent').setValue(100);
      }
    })

    if (this.feeScheduleId !== "" && this.feeScheduleId) {
      this.service
        .apiV1FeeScheduleDetailsIdGet(this.feeScheduleId)
        .pipe(first())
        .subscribe((result) => {
          this.feeEntries = result.feeScheduleEntries
          if (result.feeScheduleEntries.length > 0) {
            this.isEdit = true;
          }
        })
    }
  }
  save() {
    this.saving = true
    let isUpdate = this.populateForm.get('updateFee').value === null || this.populateForm.get('updateFee').value === "" ? false : this.populateForm.get('updateFee').value
    let isNew = this.populateForm.get('addNew').value === "" || this.populateForm.get('addNew').value === null ? false : this.populateForm.get('addNew').value
    if (isUpdate === false && isNew == false) {
      this.notification.error('At least one checkbox must be checked to contiue')
      return;
    }
 
    const modalContent: DialogContent = {
      header: '',
      body: `Are you sure you want to copy the fee schedule entries of this fee schedule?`,
      cancelButtonText: 'Cancel',
      OKButtonText: 'Confirm'
    };
    this.dialogService.showConfirmDialog(modalContent).subscribe((result) => {
      if (result) {
        let formData: PopulateFeeScheduleViewModel = {
          toFeeScheduleId: this.feeScheduleId,
          fromFeeScheduleId: this.populateForm.get('feeScheduleId').value,
          percentage: Number(this.populateForm.get('percent').value),
          updateFee: isUpdate,
          addNew: isNew
        }
        this.service.apiV1FeeSchedulePopulatePost(formData).subscribe(() => {
          this.dialogRef.close(true);
        },
          (err) => {
            this.notification.error('Error Populating Fee Schedule')
          }
        )
      }
    })

   

  }
  cancel() {
    this.dialogRef.close( false );
  }

}
