import { Injectable } from '@angular/core';
import { ApiAutoCompleteService, FieldSelectOption } from 'components/shared';

import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { JobWrapperService } from '../service-wrappers/job-wrapper.service';
import { Store } from '@ngrx/store';
import { AppState } from '@core/core.state';

@Injectable({
  providedIn: 'root'
})
export class JobLookupService extends ApiAutoCompleteService {
  user;
  constructor(private service: JobWrapperService, private store: Store<AppState>) {
    super();
    this.store.subscribe(s => this.user = s.user);
  }

  mapRow(result) {
    return {
      value: result.id,
      label: result.name
    };
  }

  search(value: string): Observable<FieldSelectOption[]> {
    return this.service
      .apiV1JobAllPost({
        generalSearch: value,
        page: 1,
        pageSize: 100
      })
      .pipe(map((results) => results.data.filter(j =>(!j.isSysAdminJob || this.user?.email.indexOf('imagineteam.com') >= 0) && j.name != "Execute Report").map(this.mapRow)));
  }

  load(id: string): Observable<FieldSelectOption> {
    return this.service.apiV1JobIdGet(id).pipe(map(this.mapRow));
  }
}
