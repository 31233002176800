import { Pipe, PipeTransform } from '@angular/core';
import { ProcedureTransactionDetailsViewModel } from 'data';

@Pipe({
  name: 'diagnosisDescription'
})
export class DiagnosisDescriptionPipe implements PipeTransform {
  transform(value: ProcedureTransactionDetailsViewModel, diagnoses): unknown {
    const codes = [...new Set(value.diagnoses.map((x) => x.diagnosisCode.code))];
    return (
      codes.join(', ' || ''));
  }
}
