<ul mat-dialog-title [class]="fullClass">
  <ng-container *ngFor="let segment of path; let i = index">
    <li *ngIf="i > 0" class="lib-breadcrumbs--accent">
      <mat-icon>arrow_forward_ios</mat-icon>
    </li>
    <li
      *ngIf="segment.title"
      [ngClass]="{ 'lib-breadcrumbs--clickable': true, 'lib-breadcrumbs--accent': i === path.length - 1 }"
      (click)="segmentClicked.emit({ id: segment.id })"
      id="breadcrumb{{ segment.id }}"
    >
      {{ segment.title }}
    </li>
    <li *ngIf="segment.options" class="lib-breadcrumbs--accent lib-breadcrumbs__section-select">
      <button
        mat-button
        [matMenuTriggerFor]="sectionSelectMenu"
        id="breadcrumb{{ segment.currentOptionLabel || segment.options[0].label }}"
      >
        {{ segment.currentOptionLabel || segment.options[0].label }}
        <mat-icon>arrow_drop_down</mat-icon>
      </button>
      <mat-menu #sectionSelectMenu="matMenu">
        <button
          mat-menu-item
          *ngFor="let option of segment.options; let i = index; trackBy: trackByValue"
          (click)="segmentClicked.emit({ id: segment.id, option: option.value })"
          id="option{{ i }}"
        >
          <span>{{ option.label }}</span>
        </button>
      </mat-menu>
    </li>
  </ng-container>
</ul>
