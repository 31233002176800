import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppState } from '@core/core.state';
import { NotificationService } from '@core/services/notification.service';
import { UserMaintenanceSecurityWrapperService } from '@core/services/service-wrappers/user-maintenance-security-wrapper.service';
import { Store } from '@ngrx/store';
import { DataSet } from 'components/core/sidebar/data-set-selector';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { selectAll as selectClients } from '../../../../../core/state/data-set/data-set.selectors';

@Component({
  selector: 'app-assign-datasets-dialog',
  templateUrl: './assign-datasets-dialog.component.html',
  styleUrls: ['./assign-datasets-dialog.component.scss']
})
export class AssignDatasetsDialogComponent implements OnInit {
  selectedDatasetIds = [];
  saving = false;
  dataSets$: Observable<DataSet[]> = this.store.select(selectClients).pipe(
    filter((clients) => !!clients),
    map((clients) => {
      return clients.map((client) => ({
        id: client.id,
        name: client.name,
        description: '',
        acronym: client.acronym
      }));
    })
  );
  userId = '';

  constructor(
    public store: Store<AppState>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private userMaintenanceSecurityService: UserMaintenanceSecurityWrapperService,
    private notificationService: NotificationService,
    public dialogRef: MatDialogRef<AssignDatasetsDialogComponent>
  ) {
    this.userId = data?.data.id;
    this.selectedDatasetIds = data?.data?.userDatasets?.map((dataset) => dataset.id);
   }

  ngOnInit(): void {

  }

  updateSelection(dataset, event, length) {
    if(event.checked) {
      this.selectedDatasetIds.push(dataset.id);
    } else {
    for(var i = 0; i < length; i++) {
      if(this.selectedDatasetIds[i] == dataset.id) {
        this.selectedDatasetIds.splice(i,1);
     }
   }
 }
}
  

  save() {
    this.saving = true;
    const formData = {
      userId: this.userId,
      datasetIds: this.selectedDatasetIds
    }
    let saveObservable: Observable<any>;
    let notification;
    notification = 'Dataset(s) Assigned.';
    saveObservable = this.userMaintenanceSecurityService.apiV1UserDatasetsPost(formData).pipe(map((x: any) => x));

    saveObservable
      .pipe(take(1))
      .subscribe(
        (response) => {
          this.dialogRef.close(true);
          this.notificationService.success(notification);
        },
        (err) => this.notificationService.error('Error Assigning Dataset(s).')
      )
      .add(() => {
        this.saving = false;
      });
  }

  trackById(index: number, item: any) {
    return item.id;
  }
}
