import { Injectable } from '@angular/core';
import { ApiAutoCompleteService, FieldSelectOption } from 'components/shared';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { CodeWrapperService } from '../service-wrappers/code-wrapper.service';

@Injectable({
  providedIn: 'root'
})
export class StateCodesLookupService extends ApiAutoCompleteService {
  stateCodes$ = this.service
    .apiV1CodeCodetypePost('StateCode', {
      generalSearch: '',
      pageSize: 500
    })
    .pipe(shareReplay(1));

  constructor(private service: CodeWrapperService) {
    super();
  }

  mapRow(result) {
    return {
      value: result.code,
      label: result.name
    };
  }

  search(value: string): Observable<FieldSelectOption[]> {
    return this.stateCodes$.pipe(
      map((results) => results.data.map(this.mapRow)),
      map((results) => this.sortCategories(results)),
      shareReplay(1)
    );
  }

  reverseSearch(label: string): FieldSelectOption {
    let state: FieldSelectOption;
    this.stateCodes$
      .pipe(
        map((results) => {
          return results.data.map(this.mapRow);
        })
      )
      .subscribe((states) => {
        state = states.find((state) => state.value === label || state.label === label);
      });
    return state;
  }
}
