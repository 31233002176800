import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DetailCardComponent } from './detail-card.component';
import { MaterialModule } from '../modules/material.module';
import { CardModule } from '../card';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [DetailCardComponent],
  exports: [DetailCardComponent],
  imports: [CommonModule, MatIconModule, MatButtonModule, CardModule]
})
export class DetailCardModule {}
