<ng-container *ngFor="let formGroup of formGroupsControls; let i = index; trackBy: trackByIndex">
  <lib-collapsible-form
    [title]="formGroupsDefinition[i].title"
    [step]="i + 1"
    [isExpanded]="!formGroupsDefinition[i]?.hide"
  >
    <form *ngIf="formGroup" [formGroup]="formGroup">
      <lib-form-group
        *ngFor="let fieldGroup of formGroupsDefinition[i].groups; trackBy: trackByIndex"
        [title]="fieldGroup.title"
        [stackedTitle]="true"
        [hide]="fieldGroup.hide"
        [disabled]="disabled"
        [formGroup]="formGroup"
        [formGroupDefinition]="fieldGroup.controls"
        [formIssues]="formIssues"
      ></lib-form-group>
    </form>
  </lib-collapsible-form>
</ng-container>
