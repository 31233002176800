import { ReportGroup } from '../../models/report-group';
import { ProcedureAnalysisByProvider } from './procedure-analysis-by-provider';
import { ProcedureAnalysisByProviderService } from './procedure-analysis-by-provider.service';
import { RvuByProvider } from './rvu-by-provider';
import { RvuByProviderService } from './rvu-by-provider.service';


export const RvuReportGroup: ReportGroup = {
  id: 'rvu-reports',
  label: 'RVU Reports',
  reports: [ProcedureAnalysisByProvider, RvuByProvider]
};

export const RvuReportsServices = [RvuByProviderService, ProcedureAnalysisByProviderService];
