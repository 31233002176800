import { CdkPortal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {
  headerOutlet = new ReplaySubject<CdkPortal>(1);
}
