import { Injector, LOCALE_ID } from '@angular/core';
import { Validators } from '@angular/forms';
import { ReportWrapperService } from '../../../../core/services/service-wrappers/report-wrapper.service';
import { BodyClasses, ColumnSizes, FieldSelectOption, FilterType } from 'components';
import { DateDisplayPipe } from 'projects/components/src/lib/shared/pipes/date-display/date-display.pipe';
import { Report } from '../../models/report';
import { Store } from '@ngrx/store';
import { UnbilledChargesService } from './unbilled-charges.service';
import {
  PhysiciansLookupService,
  InsuranceCarriersLookupService,
  FinancialClassesLookupService,
  ProcedureCodesLookupService,
  AttendingPhysiciansLookupService
} from 'src/app/core/services/lookup';

export const UnbilledCharges: Report = {
  id: 'unbilled-procedures',
  label: 'Unbilled Charges Report',
  textCriteria: {'Start Date': '', 'End Date': '', 'Attending Providers': '', 'Insurance Carriers': '', 'Financial Classes': '',
    'Report Format': '', 'Date Range Type': '', 'Procedure Code': '', 'Include / Exclude Procedures': 'I', 'Exclude Zero Balance': ''
  },
  cleanFilterParams: {
    'attendingProviderIds': '', 'insuranceCarrierIds': '', 'financialClassIds': '', 'procedureCodeIds': ''
  },
  getReportTitle: () => {
    return UnbilledCharges.label + ' ' + UnbilledCharges.textCriteria['Start Date'] + ' - ' + UnbilledCharges.textCriteria['End Date']
      + ' (' + UnbilledCharges.textCriteria['Date Range Type'] + ')';
  },
  getSearchCriteria: (injector: Injector) => [
    {
      title: '',
      controls: [
        {
          label: 'Date Range Type',
          name: 'dateRangeType',
          class: 'form-span-6',
          type: 'select',
          options: [
            {
              label: 'Posting',
              value: 'P'
            },
            {
              label: 'Service',
              value: 'S'
            }
          ],
          validators: Validators.required,
          selectionChanged: (event) => {
            UnbilledCharges.textCriteria['Date Range Type'] = event.source.triggerValue;
          }
        },
        {
          label: 'Date Range',
          name: 'dateRange',
          type: 'dateRange',
          class: 'form-span-6',
          daterangeStartChanged: (event) => {
            UnbilledCharges.textCriteria['Start Date'] = event.value.toLocaleDateString();
          },
          daterangeEndChanged: (event) => {
            UnbilledCharges.textCriteria['End Date'] = event.value.toLocaleDateString();
          }
        },
        {
          label: '',
          name: 'dateRangeStart',
          type: 'noInput',
          class: 'display-none',
          validators: Validators.required
        },
        {
          label: '',
          name: 'dateRangeEnd',
          type: 'noInput',
          class: 'display-none',
          validators: Validators.required
        },
        {
          matLabel: 'Attending Provider',
          name: 'attendingProviderIds',
          apiService: injector.get(AttendingPhysiciansLookupService),
          type: 'multiSelect',
          class: 'form-span-6 multi-input',
          placeholder: 'Attending Provider',
          selectOptions: (event) => {
            let listValue = []
            let listText = []
            event.forEach((x: FieldSelectOption) => {
              listValue.push(x.value)
              listText.push(x.label)
            })
            UnbilledCharges.textCriteria['Attending Providers'] = listText.join(',');
            UnbilledCharges.cleanFilterParams['attendingProviderIds'] = listValue

          }
        },
        {
          matLabel: 'Insurance Carrier',
          name: 'insuranceCarrierIds',
          apiService: injector.get(InsuranceCarriersLookupService),
          class: 'form-span-6 multi-input',
          type: 'multiSelect',
          placeholder: 'Insurance Carriers',
          selectOptions: (event: []) => {
            let listValue = []
            let listText = []
            event.forEach((x: FieldSelectOption) => {
              listValue.push(x?.value)
              listText.push(x.label)
            })
            UnbilledCharges.textCriteria['Insurance Carriers'] = listText.join(',');
            UnbilledCharges.cleanFilterParams['insuranceCarrierIds'] = listValue
          },
        },
        {
          matLabel: 'Financial Class',
          name: 'financialClassIds',
          apiService: injector.get(FinancialClassesLookupService),
          class: 'form-span-6 multi-input',
          type: 'multiSelect',
          placeholder: 'Financial Class',
          selectOptions: (event) => {
            let listValue = []
            let listText = []
            event.forEach((x: FieldSelectOption) => {
              listValue.push(x.value)
              listText.push(x.label)
            })
            UnbilledCharges.textCriteria['Financial Classes'] = listText.join(',');
            UnbilledCharges.cleanFilterParams['financialClassIds'] = listValue
          }
        },
        {
          label: 'Report Format',
          name: 'reportFormat',
          class: 'form-span-6',
          type: 'select',
          options: [
            {
              label: 'By attending provider',
              value: '1'
            },
            {
              label: 'By financial class',
              value: '2'
            },
            {
              label: 'By insurance company',
              value: '3'
            }
          ],
          validators: Validators.required,
          selectionChanged: (event) => {
            UnbilledCharges.textCriteria['Report Format'] = event.source.triggerValue;
          }
        },
        {
          label: 'Include / Exclude Procedures',
          name: 'includeExcludeProcedures',
          class: 'form-span-6',
          type: 'select',

          options: [
            {
              label: 'Include',
              value: 'I'
            },
            {
              label: 'Exclude',
              value: 'E'
            }
          ],
          initial: 'I',
          validators: Validators.required,
          selectionChanged: (event) => {
            UnbilledCharges.textCriteria['Include / Exclude Procedures'] = event.source.triggerValue;
          }
        },
        {
          matLabel: 'Procedure Code',
          name: 'procedureCodeIds',
          placeholder: 'Procedure Code',
          apiService: injector.get(ProcedureCodesLookupService),
          class: 'form-span-6 multi-input',
          type: 'multiSelect',
          selectOptions: (event) => {
            let listValue = []
            let listText = []
            event.forEach((x: FieldSelectOption) => {
              listValue.push(x.value)
              listText.push(x.label)
            })
            UnbilledCharges.textCriteria['Procedure Codes'] = listText.join(',');
            UnbilledCharges.cleanFilterParams['procedureCodeIds'] = listValue

          }
        },
        {
          label: 'Exclude Zero Balance Claims',
          name: 'excludeZeroBalanceClaims',
          class: 'form-span-6',
          type: 'select',
          options: [
            {
              label: 'Yes',
              value: 'Y'
            },
            {
              label: 'No',
              value: 'N'
            }
          ],
          validators: Validators.required,
          selectionChanged: (event) => {
            UnbilledCharges.textCriteria['Exclude Zero Balance'] = event.source.triggerValue;
          }
        }

      ]
    }
  ],
  getGridConfiguration: (injector: Injector) => {
    const locale = injector.get(LOCALE_ID);
    const datePipe: DateDisplayPipe = new DateDisplayPipe(locale);
    const moneyFormat = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });
    return {
      service: injector.get<any>(UnbilledChargesService),
      columnDefinitions: [
        {
          id: 'Dynamic 0',
          displayKey: 'Dynamic 0',
          headerText: 'Dynamic 0',
          displayOrder: 0,
          dynamic: true,
          columnSize: ColumnSizes.normal,
          filterable: true,
          sortable: true
        },
        {
          id: 'Dynamic 1',
          displayKey: 'Dynamic 1',
          headerText: 'Dynamic 1',
          displayOrder: 1,
          dynamic: true,
          columnSize: ColumnSizes.normal,
          filterable: true,
          sortable: true
        },
        {
          id: 'Dynamic 2',
          displayKey: 'Dynamic 2',
          headerText: 'Dynamic 2',
          displayOrder: 2,
          dynamic: true,
          columnSize: ColumnSizes.normal,
          filterable: true,
          sortable: true
        },
        {
          id: 'Dynamic 3',
          displayKey: 'Dynamic 3',
          headerText: 'Dynamic 3',
          displayOrder: 3,
          dynamic: true,
          columnSize: ColumnSizes.normal,
          filterable: true,
          sortable: true
        },
        {
          id: 'Dynamic 4',
          displayKey: 'Dynamic 4',
          headerText: 'Dynamic 4',
          displayOrder: 4,
          dynamic: true,
          columnSize: ColumnSizes.normal,
          filterable: true,
          sortable: true
        },
        {
          id: 'Dynamic 5',
          displayKey: 'Dynamic 5',
          headerText: 'Dynamic 5',
          displayOrder: 5,
          dynamic: true,
          columnSize: ColumnSizes.extraNarrow,
          bodyClasses: [BodyClasses.alignRight],
          filterable: true,
          sortable: true
        },
        {
          id: 'Dynamic 6',
          displayKey: 'Dynamic 6',
          headerText: 'Dynamic 6',
          displayOrder: 6,
          dynamic: true,
          columnSize: ColumnSizes.narrow,
          filterable: true,
          sortable: true
        },
        {
          id: 'Dynamic 7',
          displayKey: 'Dynamic 7',
          headerText: 'Dynamic 7',
          displayOrder: 7,
          dynamic: true,
          columnSize: ColumnSizes.narrow,
          filterable: true,
          sortable: true
        },
        {
          id: 'Dynamic 8',
          displayKey: 'Dynamic 8',
          headerText: 'Dynamic 8',
          displayOrder: 8,
          dynamic: true,
          columnSize: ColumnSizes.normal,
          filterable: true,
          sortable: true
        },
        {
          id: 'Dynamic 9',
          displayKey: 'Dynamic 9',
          headerText: 'Dynamic 8',
          displayOrder: 9,
          dynamic: true,
          columnSize: ColumnSizes.extraNarrow,
          filterable: true,
          sortable: true,
          bodyClasses: [BodyClasses.alignRight],
          formatter: moneyFormat.format
        },
        {
          id: 'Dynamic 10',
          displayKey: 'Dynamic 10',
          headerText: 'Dynamic 10',
          displayOrder: 10,
          dynamic: true,
          columnSize: ColumnSizes.extraNarrow,
          filterable: true,
          sortable: true,
          bodyClasses: [BodyClasses.alignRight],
          formatter: moneyFormat.format
        }
      ]
    };
  }
};
