import { Component, Input, Output, EventEmitter } from '@angular/core';

export interface BreadcrumbsPath {
  id: string;
  param?:string,
  title?: string | number;
  options?: {
    value: string | number;
    label: string;
  }[];
  currentOptionLabel?: string;
}

export interface SegmentClickEvent {
  id: string;
  title: string;
  option?: string | number;
}

// TODO: ONPUSH
@Component({
  selector: 'lib-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent {
  @Input() display: 'slim' | 'regular' = 'slim';
  @Input() path: BreadcrumbsPath[];
  @Output() segmentClicked: EventEmitter<SegmentClickEvent> = new EventEmitter<SegmentClickEvent>();

  get fullClass(): string {
    return `lib-breadcrumbs ${this.display}`;
  }

  trackByValue(index: number, item: any) {
    return item.value
  }
}
