/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RuleCardSubtypeViewModel } from './ruleCardSubtypeViewModel';
import { RuleCardTypeViewModel } from './ruleCardTypeViewModel';
import { RuleCardResultViewModel } from './ruleCardResultViewModel';

export interface RuleCardTypeResultDetailsViewModel {
  id?: string;
  rowId?: number;
  ruleCardTypeId?: string;
  ruleCardSubtypeId?: string;
  ruleCardResultId?: string;
  ruleCardType?: RuleCardTypeViewModel;
  ruleCardSubtype?: RuleCardSubtypeViewModel;
  ruleCardResult?: RuleCardResultViewModel;
}
