import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ageDisplay'
})
export class AgeDisplayPipe implements PipeTransform {
  transform(value: string | Date) {
    if (!(value instanceof Date)) {
      value = new Date(value);
    }
    return Math.abs(new Date(Date.now() - new Date(value).getTime()).getUTCFullYear() - 1970);
  }
}
