/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { FinancialClassSimpleViewModel } from './financialClassSimpleViewModel';
import { CodeSimpleViewModel } from './codeSimpleViewModel';
import { InsuranceDetailsViewModel } from './insuranceDetailsViewModel';
import { ARTransactionProcedureViewModel } from './aRTransactionProcedureViewModel';

export interface ARTransactionVisitViewModel {
  procedures?: Array<ARTransactionProcedureViewModel> | null;
  datasetId?: string | null;
  visitId?: string | null;
  patientId?: string | null;
  insuranceId?: string | null;
  batchId?: string | null;
  visitNumber?: number;
  holdStatus?: string | null;
  totalCharges?: number;
  previousPayments?: number;
  previousAdjustments?: number;
  visitBalance?: number;
  lastStatementDate?: string | null;
  arTransactionHoldingId?: string | null;
  arTransactionId?: string | null;
  allowedAmount?: number;
  paymentAmount?: number;
  adjustmentAmount?: number;
  adjustment2Amount?: number;
  totalAdjustment?: number;
  totalEob?: number;
  deductibleAmount?: number;
  coinsuranceAmount?: number;
  copayAmount?: number;
  denied?: string | null;
  icn?: string | null;
  transactionDate?: string | null;
  releaseClaim?: boolean;
  checkNumber?: string | null;
  paymentCodeId?: string | null;
  adjustmentCodeId?: string | null;
  adjustmentCode2Id?: string | null;
  paymentTypeId?: string | null;
  patientPayment?: boolean;
  unappliedId?: string | null;
  isImaginePay?: boolean;
  primaryInsurance?: InsuranceDetailsViewModel;
  secondaryInsurance?: InsuranceDetailsViewModel;
  tertiaryInsurance?: InsuranceDetailsViewModel;
  paymentCode?: CodeSimpleViewModel;
  adjustmentCode?: CodeSimpleViewModel;
  adjustmentCode2?: CodeSimpleViewModel;
  paymentType?: CodeSimpleViewModel;
  financialClass?: FinancialClassSimpleViewModel;
}
