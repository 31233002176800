import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrderByPipe } from './orderBy/order-by.pipe';
import { AddressFormatterPipe } from './address-formatter/address-formatter.pipe';
import { AgeDisplayPipe } from './age-display/age-display.pipe';
import { AddressFormatterSingleLinePipe } from './address-formatter-single-line/address-formatter-single-line.pipe';
import { ProcedureDescriptionPipe } from './procedure-description/procedure-description.pipe';
import { DiagnosisDescriptionPipe } from './diagnosis-description/diagnosis-description.pipe';
import { PhysicianDisplayPipe } from './physician-display/physician-display.pipe';
import { DateDisplayPipe } from './date-display/date-display.pipe';
import { LocationDisplayPipe } from './location-display/location-display.pipe';
import { ModifierLabelPipe } from './modifier-label/modifier-label.pipe';
import { NameInitialDisplayPipe } from './name-initial-display/name-initial-display.pipe';
import { CurrencyDisplayPipe } from './currency-display/currency-display.pipe';
import { trueFalseDisplayPipe } from './true-false-display/true-false-display.pipe';

const pipes = [
  OrderByPipe,
  AddressFormatterPipe,
  AgeDisplayPipe,
  NameInitialDisplayPipe,
  AddressFormatterSingleLinePipe,
  ProcedureDescriptionPipe,
  DiagnosisDescriptionPipe,
  PhysicianDisplayPipe,
  LocationDisplayPipe,
  DateDisplayPipe,
  ModifierLabelPipe,
  CurrencyDisplayPipe,
  trueFalseDisplayPipe
];

@NgModule({
  declarations: [...pipes],
  exports: [...pipes],
  imports: [CommonModule]
})
export class PipesModule {}
