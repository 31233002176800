/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { WriteOffConfigurationTier } from './writeOffConfigurationTier';

export interface WriteOffConfigurationSettingsViewModel {
  writeOffAdjustmentCode?: string | null;
  minimumDelinquencyCode?: number;
  daysToWriteOffBalance?: number;
  excludeHeldVisits?: boolean;
  excludeCreditBalance?: boolean;
  useAccountBalance?: boolean;
  writeOffConfigurationTiers?: Array<WriteOffConfigurationTier> | null;
}
