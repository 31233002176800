import { Injectable } from '@angular/core';
import {
  AddPatientViewModel,
  MergePatientViewModel,
  Operation,
  PagedModelRequest,
  PatientDetailsViewModel,
  PatientDetailsViewModelPagedModelResponse,
  PatientLedgerRequestModel,
  PatientNumberViewModel,
  PatientReceiptRequestModel,
  PatientSearchViewModelPagedModelResponse,
  PatientService,
  PatientViewModel,
  PatientViewModelPagedModelResponse,
  ZeroBalanceRequestViewModel
} from 'data';
import { Observable } from 'rxjs';
import { BaseWrapperService } from './base-wrapper.service';
import { SessionStorageService } from '../session-storage.service';

@Injectable({
  providedIn: 'root'
})
export class PatientWrapperService extends BaseWrapperService {
  constructor(private service: PatientService, protected sessionStorageService: SessionStorageService) {
    super();
  }

  apiV1PatientPatchIdPatch(id: string, operation?: Array<Operation>): Observable<any> {
    return this.service.apiV1PatientPatchIdPatch(id, this.getDataSetId(), operation);
  }

  apiV1PatientAddPost(addPatientViewModel?: AddPatientViewModel): Observable<any> {
    return this.service.apiV1PatientAddPost(this.getDataSetId(), addPatientViewModel);
  }

  apiV1PatientAllPost(pagedModelRequest?: PagedModelRequest): Observable<PatientViewModelPagedModelResponse> {
    return this.service.apiV1PatientAllPost(this.getDataSetId(), pagedModelRequest);
  }

  apiV1PatientAllDetailPost(
    pagedModelRequest?: PagedModelRequest
  ): Observable<PatientDetailsViewModelPagedModelResponse> {
    return this.service.apiV1PatientAllDetailPost(this.getDataSetId(), pagedModelRequest);
  }

  apiV1PatientIdGet(id: string): Observable<PatientViewModel> {
    return this.service.apiV1PatientIdGet(id, this.getDataSetId());
  }

  apiV1PatientDetailsIdGet(id: string): Observable<PatientDetailsViewModel> {
    return this.service.apiV1PatientDetailsIdGet(id, this.getDataSetId());
  }

  apiV1PatientSelectionDetailsIdGet(id: string): Observable<PatientDetailsViewModel> {
    return this.service.apiV1PatientSelectionDetailsIdGet(id, this.getDataSetId());
  }

  apiV1PatientMergePatientPost(patientMerge: MergePatientViewModel): Observable<PatientDetailsViewModel> {
    return this.service.apiV1PatientMergePatientPost(this.getDataSetId(), patientMerge);
  }

  apiV1PatientPurgeAccounts(request: ZeroBalanceRequestViewModel): Observable<PatientDetailsViewModel[]> {
    return this.service.apiV1PatientPurgeAccountsPost(this.getDataSetId(), request);
  }

  apiV1PatientPatientNumberPost(): Observable<PatientNumberViewModel> {
    return this.service.apiV1PatientPatientNumberPost(this.getDataSetId());
  }

  apiV1PatientSearchPost(pagedModelRequest?: PagedModelRequest): Observable<PatientSearchViewModelPagedModelResponse> {
    return this.service.apiV1PatientSearchPost(this.getDataSetId(), pagedModelRequest);
  }

  apiV1PatientIdVisitsPost(id: string, pagedModelRequest?: PagedModelRequest): Observable<any> {
    return this.service.apiV1PatientIdVisitsPost(id, this.getDataSetId(), pagedModelRequest);
  }

  apiV1PatientIdProceduresPost(id: string, pagedModelRequest?: PagedModelRequest): Observable<any> {
    return this.service.apiV1PatientIdProceduresPost(id, this.getDataSetId(), pagedModelRequest);
  }

  apiV1PatientIdUnappliedPost(id: string, pagedModelRequest?: PagedModelRequest): Observable<any> {
    return this.service.apiV1PatientIdUnappliedPost(id, this.getDataSetId(), pagedModelRequest);
  }

  apiV1PatientPatientNumberPatientnumberGet(accountNumber: number): Observable<PatientDetailsViewModel> {
    return this.service.apiV1PatientPatientNumberPatientnumberGet(accountNumber, this.getDataSetId());
  }

  apiV1PatientValidateAccountNumberPost(accountNumber: number): Observable<any> {
    return this.service.apiV1PatientValidateAccountNumberPost(this.getDataSetId(), accountNumber);
  }

  apiV1PatientUpdateBannerIdPost(id: string, requestBody): Observable<any> {
    return this.service.apiV1PatientUpdateBannerIdPost(id, this.getDataSetId(), requestBody);
  }

  apiV1PatientReceiptPdfPost(request: PatientReceiptRequestModel): Observable<Blob> {
    return this.service.apiV1PatientReceiptPdfPost(this.getDataSetId(), request);
  }

  apiV1PatientLedgerPdfPost(request: PatientLedgerRequestModel): Observable<Blob> {
    return this.service.apiV1PatientLedgerPdfPost(this.getDataSetId(), request);
  }

  loadByIds(): Observable<any> {
    return this.service.apiV1PatientIdsPost(this.getDataSetId(), this.getPatientIds());
  }
}
