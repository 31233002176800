import { Injectable } from '@angular/core';
import { BaseGridSource, ComparisonType, PagedModel } from 'components';
import { of } from 'rxjs';
import { catchError, finalize, map, take } from 'rxjs/operators';
import { CodeWrapperService } from 'src/app/core/services/service-wrappers/code-wrapper.service';

@Injectable()
export class AdjustmentCodesService extends BaseGridSource<any> {

  activeFilter: string = '';

  constructor(private service: CodeWrapperService) {
    super();
  }
  
  load(pagedModel: PagedModel) {
    this.loadingSubject.next(true);

    if (!pagedModel?.propertySearches?.find(p => p.propertyName === "inactive")) {
      if (this.activeFilter === "active") {
        pagedModel.propertySearches.push({
          propertyName: 'inactive',
          comparisonType: ComparisonType.Equals,
          value: 'No'
        });
      } else if (this.activeFilter === "inactive") {
        pagedModel.propertySearches.push({
          propertyName: 'inactive',
          comparisonType: ComparisonType.Equals,
          value: 'Yes'
        });
      }
    }

    this.service
      .apiV1CodeCodetypePost('AdjustmentCode', pagedModel)
      .pipe(
        map((response) => {
        
          this.itemCountSubject.next(response.metaData.totalItemCount);

          return response;
        }),
        catchError(() => of({ data: [] })),
        finalize(() => {
          this.loadingSubject.next(false);
        }),
        take(1)
      )
      .subscribe((data) => this.dataSourceSubject.next(data.data));
  }
}
