/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserViewModel } from './userViewModel';
import { PatientViewModel } from './patientViewModel';

export interface ReminderDetailsViewModel {
  id?: string;
  datasetId?: string;
  userId?: string;
  assignedUserId?: string;
  patientId?: string | null;
  accountId?: string | null;
  message?: string | null;
  scheduledDate?: string;
  user?: UserViewModel;
  assignedUser?: UserViewModel;
  patient?: PatientViewModel;
  isDeleted?: boolean;
  deletedBy?: string | null;
  deletedDate?: string | null;
  createdBy?: string | null;
  createdDate?: string;
  updatedBy?: string | null;
  updatedDate?: string | null;
}
