import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subject, catchError, filter, of, take, takeUntil } from 'rxjs';

import { routeAnimations } from './core/animations/route.animations';
import { selectEffectiveTheme } from './core/state/settings/settings.selectors';
import { ConfigurationWrapperService } from '@core/services/service-wrappers/configuration-wrapper.service';
import { authLogin } from '@core/state/auth-state/auth.actions';
import { environment } from '@environment/environment';
import { AuthenticationResult, EventMessage, EventType, IdTokenClaims, InteractionStatus, InteractionType, PopupRequest, PromptValue, RedirectRequest } from '@azure/msal-browser';
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { UserWrapperService } from '@core/services/service-wrappers/user-wrapper.service';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { EligibilityWorkGridSource } from './features/queues/grids/data-sources/eligibility-work-datasources';

type IdTokenClaimsWithPolicyId = IdTokenClaims & {
  acr?: string,
  tfp?: string,
};

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [routeAnimations]
})
export class AppComponent implements OnInit, OnDestroy {
  isIframe = false;
  loginDisplay = false;
  theme$: Observable<string> = this.store.pipe(select(selectEffectiveTheme));
  private readonly _destroying$ = new Subject<void>();

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private store: Store, 
    private configurationService: ConfigurationWrapperService,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private userService: UserWrapperService,
    private router: Router
    ) {
    this.configurationService.apiV1ConfigurationFeatureFeaturenameGet("custom-title").subscribe(f => {
      if (f.featureLevel > 0) {
        document.title = f.featureValue;
      }
    });
  }
  ngOnInit(): void {
    // B2C is this needed
    this.isIframe = window !== window.parent && !window.opener; // Remove this line to use Angular Universal

    // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window
    this.authService.instance.enableAccountStorageEvents(); 
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.ACCOUNT_ADDED || msg.eventType === EventType.ACCOUNT_REMOVED),
        takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {
        if (this.authService.instance.getAllAccounts().length === 0) {
          window.location.pathname = "/";
        } 
      });


    // statuses: Startup, Login, Logout, AcquireToken, SsoSilent, HandleRedirect, None
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe((status: InteractionStatus) => {
        this.checkAndSetActiveAccount();
      })

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS
          || msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
          || msg.eventType === EventType.SSO_SILENT_SUCCESS),
        takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {
        //where the auth data coming from I think
        let payload = result.payload as AuthenticationResult;

        // get current auth id (B2C user id)
        // retrieve user by this auth id if it is changed
        // and dispatch authLogin action with username, name, and database user id
        if ((sessionStorage.getItem('auth-user-id') ?? '') != payload.account.localAccountId) {
          sessionStorage.setItem('auth-user-id', payload.account.localAccountId);
          this.userService.apiV1UserAuthIdGet(payload.uniqueId)
          .pipe(
            take(1),
            catchError(
              err => of(err)
            )
          ).subscribe(x => 
            {
              if (x instanceof HttpErrorResponse) {
                this.router.navigateByUrl('/unauthorized')
              }
              else
                this.store.dispatch(authLogin(
                  {
                    authData: 
                    {
                      email: payload.account.username,
                      firstName: payload?.account?.name ?? '',
                      lastName: '',
                      picture: '',
                      id: x.id,
                      authenticated: true,
                      clientId: ''
                    }
                  }
                ))
            });
          }
          let idtoken = payload.idTokenClaims as IdTokenClaimsWithPolicyId;
          let accessToken = payload.accessToken;
          localStorage.setItem('accessToken', accessToken);

          if (idtoken.acr === environment.b2cPolicies.names.signIn || idtoken.tfp === environment.b2cPolicies.names.signIn) {
              this.authService.instance.setActiveAccount(payload.account);
          }

          return result;
        });
  }

  checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    let activeAccount = this.authService.instance.getActiveAccount();

    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
        let accounts = this.authService.instance.getAllAccounts();
        this.authService.instance.setActiveAccount(accounts[0]);
    }
  }

  login(userFlowRequest?: RedirectRequest | PopupRequest) {
      if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
          if (this.msalGuardConfig.authRequest) {
              this.authService.loginPopup({ ...this.msalGuardConfig.authRequest, ...userFlowRequest } as PopupRequest)
                .subscribe((response: AuthenticationResult) => {
                      this.authService.instance.setActiveAccount(response.account);
                  });
          } else {
              this.authService.loginPopup(userFlowRequest)
                  .subscribe((response: AuthenticationResult) => {
                      this.authService.instance.setActiveAccount(response.account);
                  });
          }
      } else {
          if (this.msalGuardConfig.authRequest) {
              this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest, ...userFlowRequest } as RedirectRequest);
          } else {
              this.authService.loginRedirect(userFlowRequest);
          }
      }
  }

  logout() {
      if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
          this.authService.logoutPopup({
              mainWindowRedirectUri: "/"
          }); 
      } else {
          this.authService.logoutRedirect({account: this.authService.instance.getActiveAccount() } );
      }
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}

