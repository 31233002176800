/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface SearchRequestViewModel {
  term?: string | null;
  fields?: Array<string> | null;
  startDate?: string | null;
  endDate?: string | null;
  readonly hasDateRange?: boolean;
  searchPatients?: boolean;
  searchVisits?: boolean;
  searchBatches?: boolean;
  searchCodes?: boolean;
  searchOther?: boolean;
  pageNum?: number;
  pageSize?: number;
  startsWith?: boolean;
}
