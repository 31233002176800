import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiAutoCompleteService, FieldSelectOption } from '../../../../../projects/components/src/public-api';
import { CodeWrapperService } from '../service-wrappers/code-wrapper.service';
import { FeeScheduleWrapperService } from '../service-wrappers/fee-schedule-wrapper.service';

@Injectable({
  providedIn: 'root'
})
export class FeeScheduleLookupService extends ApiAutoCompleteService {

  constructor(private service: FeeScheduleWrapperService) { super(); }

  mapRow(result) {
    return {
      value: result.id,
      label: result.name,
      toDate: result.effectiveTo,
      fromDate: result.effectiveFrom
    };
  }

  search(value: string): Observable<FieldSelectOption[]> {
    return this.service
      .apiV1FeeScheduleAllPost({
        generalSearch: value,
        pageSize: 9999
      })
      .pipe(map((results) => results.data.map(this.mapRow)));
  }

  load(id: string): Observable<FieldSelectOption> {
    return this.service.apiV1FeeScheduleIdGet(id).pipe(map(this.mapRow));
  }
}
