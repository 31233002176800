import { ErrorHandler, Injectable, Injector, Inject } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { NotificationService } from '../services/notification.service';
import { ErrorService } from '../services/error.service';
import { WindowToken } from '../injection-tokens/window-token';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private injector: Injector) {}

  /**
   * Global Error Handler
   *
   * @param {(Error | HttpErrorResponse)} error
   * @memberof GlobalErrorHandler
   */
  handleError(error: Error | HttpErrorResponse) {
    const errorService = this.injector.get(ErrorService);
    const notifier = this.injector.get(NotificationService);

    let message;
    if (error instanceof HttpErrorResponse) {
      /*
      Moving to start handling errors within individual components. - DW - 2021.08.11
      // Server error
      if (error.status === 404) {
        notifier.error(error.error);
      } else {
        message = errorService.getServerErrorMessage(error);
        notifier.error(message);
      }
      */
    } else {
      // Client Error
      message = errorService.getClientErrorMessage(error);
      notifier.error(message);
    }

    // Always log errors
    console.error(error);
  }
}
