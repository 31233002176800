import { Injectable } from '@angular/core';
import { UserService, PagedModelRequest, UserConfigurationViewModel } from 'data';
import { BaseWrapperService } from './base-wrapper.service';
import { SessionStorageService } from '../session-storage.service';

@Injectable({
  providedIn: 'root'
})
export class UserWrapperService extends BaseWrapperService {
  constructor(private service: UserService, protected sessionStorageService: SessionStorageService) {
    super();
  }

  apiV1UserGet() {
    return this.service.apiV1UserGet(this.getDataSetId());
  }

  apiV1UserPost(pagedModelRequest: PagedModelRequest) {
    return this.service.apiV1UserPost(this.getDataSetId(), pagedModelRequest);
  }

  apiV1UserIdGet(userId: string) {
    return this.service.apiV1UserIdGet(userId, this.getDataSetId());
  }

  apiV1UserAuthIdGet(authId: string) {
    return this.service.apiV1UserAuthAuthidGet(authId, '');
  }

  apiV1UserUpdateConfigIdPost(userId: string, configs: Array<UserConfigurationViewModel>) {
    return this.service.apiV1UserUpdateConfigIdPost(userId, this.getDataSetId(), configs);
  }
}
