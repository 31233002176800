/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PatientEligibilityDetailsViewModel } from './patientEligibilityDetailsViewModel';

export interface PatientEligibilityViewModel {
  id?: string;
  datasetId?: string;
  accountNumber?: string | null;
  lastName?: string | null;
  firstName?: string | null;
  middleName?: string | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  state?: string | null;
  zip?: string | null;
  countryCode?: string | null;
  birthDate?: string;
  sex?: string | null;
  eligibility?: Array<PatientEligibilityDetailsViewModel> | null;
}
