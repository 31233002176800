/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EcsReceiverConfigurationViewModel } from './ecsReceiverConfigurationViewModel';

export interface EcsSubmitterConfigurationViewModel {
  id?: string | null;
  datasetId?: string | null;
  environment?: string | null;
  ecsReceiverConfigurationId?: string | null;
  transactionSetCode?: string | null;
  versionIdentifierCode?: string | null;
  interchangeSenderId?: string | null;
  submitterName?: string | null;
  submitterCode?: string | null;
  contactName?: string | null;
  contactPhoneNumber?: string | null;
  addressLine1?: string | null;
  addressLine2?: string | null;
  city?: string | null;
  stateCode?: string | null;
  zip?: string | null;
  countryCode?: string | null;
  requestMaximum?: number;
  requestRepeatAfterDays?: number;
  additionalData?: string | null;
  receiverConfiguration?: EcsReceiverConfigurationViewModel;
}
