/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { StatusSegmentModel } from './statusSegmentModel';
import { ClaimStatusProviderViewModel } from './claimStatusProviderViewModel';

export interface ClaimStatusFileViewModel {
  id?: string;
  datasetId?: string;
  fileName?: string | null;
  downloadDate?: string;
  infoSourceName?: string | null;
  transactionIdentifier?: string | null;
  receiptDate?: string | null;
  processDate?: string | null;
  receiverName?: string | null;
  receiverIdentifier?: string | null;
  receiverBatch?: string | null;
  totalAcceptedQuantity?: number;
  totalRejectedQuantity?: number;
  totalAcceptedAmount?: number;
  totalRejectedAmount?: number;
  isCleared?: boolean;
  statusSegmentModel?: StatusSegmentModel;
  providerViewModels?: Array<ClaimStatusProviderViewModel> | null;
}
