/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { LocationLinkViewModel } from './locationLinkViewModel';
import { SendingFacilityViewModel } from './sendingFacilityViewModel';
import { LocationDetailsViewModel } from './locationDetailsViewModel';
import { FinancialClassViewModel } from './financialClassViewModel';
import { PatientHoldingIssueViewModel } from './patientHoldingIssueViewModel';
import { CodeViewModel } from './codeViewModel';
import { GuarantorHoldingViewModel } from './guarantorHoldingViewModel';
import { InsuranceHoldingViewModel } from './insuranceHoldingViewModel';
import { ProviderLinkViewModel } from './providerLinkViewModel';
import { ProviderViewModel } from './providerViewModel';
import { HoldingIssue } from './holdingIssue';

export interface PatientHoldingDetailsViewModel {
  id?: string;
  datasetId?: string;
  sendingFacilityId?: string;
  guarantorHoldingId?: string;
  accountNumber?: number;
  externalAccountNumber?: string | null;
  lastName?: string | null;
  firstName?: string | null;
  middleName?: string | null;
  suffix?: string | null;
  birthDate?: string | null;
  sex?: string | null;
  ssn?: string | null;
  medicalRecordNumber?: string | null;
  defaultAdmissionDate?: string | null;
  defaultDischargeDate?: string | null;
  deathDate?: string | null;
  defaultInjuryDate?: string | null;
  defaultInjuryEmploymentCode?: string | null;
  defaultAccidentType?: string | null;
  defaultAutoAccidentState?: string | null;
  printFirstReport?: boolean;
  militaryBranch?: string | null;
  militaryDutyStatus?: string | null;
  maritalStatus?: string | null;
  employmentStudentStatus?: string | null;
  primaryDiagnosisCodeId?: string | null;
  secondaryDiagnosisCodeId?: string | null;
  tertiaryDiagnosisCodeId?: string | null;
  quarternaryDiagnosisCodeId?: string | null;
  primaryInsuranceHoldingId?: string | null;
  secondaryInsuranceHoldingId?: string | null;
  tertiaryInsuranceHoldingId?: string | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  stateCode?: string | null;
  zip?: string | null;
  countryCode?: string | null;
  homePhone?: string | null;
  workPhone?: string | null;
  mobilePhone?: string | null;
  email?: string | null;
  useAlternateMailingAddress?: boolean;
  alternateName?: string | null;
  alternateAddress1?: string | null;
  alternateAddress2?: string | null;
  alternateCity?: string | null;
  alternateStateCode?: string | null;
  alternateZip?: string | null;
  alternateCountryCode?: string | null;
  employerName?: string | null;
  employerAddress1?: string | null;
  employerAddress2?: string | null;
  employerCity?: string | null;
  employerStateCode?: string | null;
  employerZip?: string | null;
  employerCountryCode?: string | null;
  employerPhone?: string | null;
  defaultPlaceOfServiceId?: string | null;
  isDeleted?: boolean;
  deletedBy?: string | null;
  deletedDate?: string | null;
  createdBy?: string | null;
  createdDate?: string;
  updatedBy?: string | null;
  updatedDate?: string | null;
  guarantorHolding?: GuarantorHoldingViewModel;
  primaryInsuranceHolding?: InsuranceHoldingViewModel;
  secondaryInsuranceHolding?: InsuranceHoldingViewModel;
  tertiaryInsuranceHolding?: InsuranceHoldingViewModel;
  primaryProvider?: ProviderViewModel;
  primaryProviderLink?: ProviderLinkViewModel;
  attendingProvider?: ProviderViewModel;
  attendingProviderLink?: ProviderLinkViewModel;
  referringProvider?: ProviderViewModel;
  referringProviderLink?: ProviderLinkViewModel;
  placeOfServiceCode?: CodeViewModel;
  location?: LocationDetailsViewModel;
  locationLink?: LocationLinkViewModel;
  financialClass?: FinancialClassViewModel;
  sendingFacility?: SendingFacilityViewModel;
  patientIssues?: Array<PatientHoldingIssueViewModel> | null;
  issues?: Array<HoldingIssue> | null;
}
