<mat-toolbar color="primary">
  <mat-toolbar-row>
    <div class="site-name">Imagine Software</div>
    <div class="page-name">
      <lib-header></lib-header>
    </div>
    <button mat-icon-button [matMenuTriggerFor]="themePicker">
      <mat-icon> colorize </mat-icon>
    </button>
    <mat-menu #themePicker="matMenu">
      <button mat-menu-item *ngFor="let theme of themes; trackBy: trackByValue" (click)="themeSelect(theme.value)">
        <span>{{ theme.name }}</span>
      </button>
    </mat-menu>
  </mat-toolbar-row>
</mat-toolbar>
