import { formatPercent } from '@angular/common';
import { Injector, LOCALE_ID } from '@angular/core';
import { Validators } from '@angular/forms';
import { BodyClasses, ColumnSizes, CustomValidators, FieldSelectOption, FilterType } from 'components';
import { DateDisplayPipe } from 'projects/components/src/lib/shared/pipes/date-display/date-display.pipe';
import { Report } from '../../models/report';
import { InsuranceTrackingService } from './insurance-tracking.service';
import {
  PhysiciansLookupService,
  InsuranceCarriersLookupService,
  FinancialClassesLookupService,
  AttendingPhysiciansLookupService,
  LocationsLookupService,
  ProcedureCodesLookupService,
  DiagnosisCodesLookupService
} from 'src/app/core/services/lookup';

export const InsuranceTracking: Report = {
  id: 'insurance-tracking',
  label: 'Insurance Tracking Report',
  subText: 'If filters are not selected, then all data is included.',
  searchFormClass: 'insurance-tracking-report',
  textCriteria: {
    'Insurance Carriers': '', 'Financial Classes': '', 'Locations': '', 'Attending Providers': ''
    , 'Procedure Codes': '', 'Diagnosis Codes': '', 'Start Date': '', 'End Date': ''
    , 'Start Post Date': '', 'End Post Date': '', 'Start Billed Date': '', 'End Billed Date': ''
    , 'Paid/Amount Min': 0, 'Paid/Amount Max': 999, 'Paid/Fee Min': 0, 'Paid/Fee Max': 999
  },
  cleanFilterParams: {
    'insuranceCarrierIds': '', 'financialClassIds': '', 'locationIds': '', 'attendingProviderIds': ''
    , 'procedureCodeIds': '', 'diagnosisCodeIds': ''
  },
  getReportTitle: () => {
    return InsuranceTracking.label + ' ' + InsuranceTracking.textCriteria['Start Date'] + ' - ' + InsuranceTracking.textCriteria['End Date'];
  },
  getSearchCriteria: (injector: Injector) => [
    {
      controls: [
        {
          matLabel: 'Insurance Carriers',
          name: 'insuranceCarrierIds',
          apiService: injector.get(InsuranceCarriersLookupService),
          class: 'form-span-6 multi-input',
          type: 'multiSelect',
          placeholder: 'Insurance Carriers',
          selectOptions: (event: []) => {
            let listValue = []
            let listText = []
            event.forEach((x: FieldSelectOption) => {
              listValue.push(x?.value)
              listText.push(x.label)
            })
            InsuranceTracking.textCriteria['Insurance Carriers'] = listText.join(',');
            InsuranceTracking.cleanFilterParams['insuranceCarrierIds'] = listValue
          },
        },
        {
          matLabel: 'Financial Class',
          name: 'financialClassIds',
          apiService: injector.get(FinancialClassesLookupService),
          class: 'form-span-6 multi-input',
          type: 'multiSelect',
          placeholder:'Financial Class',
          selectOptions: (event) => {
            let listValue = []
            let listText = []
            event.forEach((x: FieldSelectOption) => {
              listValue.push(x.value)
              listText.push(x.label)
            })
            InsuranceTracking.textCriteria['Financial Classes'] = listText.join(',');
            InsuranceTracking.cleanFilterParams['financialClassIds'] = listValue
          }
        },
        {
          matLabel: 'Location',
          name: 'locationIds',
          apiService: injector.get(LocationsLookupService),
          type: 'multiSelect',
          class: 'form-span-6 multi-input',
          placeholder: 'Location',
          selectOptions: (event) => {
            let listValue = []
            let listText = []
            event.forEach((x: FieldSelectOption) => {

              listValue.push(x.value)
              listText.push(x.label)
            })
            InsuranceTracking.textCriteria['Locations'] = listText.join(',');
            InsuranceTracking.cleanFilterParams['locationIds'] = listValue
          }
        },
        {
          matLabel: 'Attending Provider',
          name: 'attendingProviderIds',
          apiService: injector.get(AttendingPhysiciansLookupService),
          type: 'multiSelect',
          class: 'form-span-6 multi-input',
          placeholder: 'Attending Provider',
          selectOptions: (event) => {
            let listValue = []
            let listText = []
            event.forEach((x: FieldSelectOption) => {
              listValue.push(x.value)
              listText.push(x.label)
            })
            InsuranceTracking.textCriteria['Attending Providers'] = listText.join(',');
            InsuranceTracking.cleanFilterParams['attendingProviderIds'] = listValue

          }
        },
        {
          matLabel: 'Procedure Code',
          name: 'procedureCodeIds',
          placeholder: 'Procedure Code',
          apiService: injector.get(ProcedureCodesLookupService),
          class: 'form-span-6 multi-input',
          type: 'multiSelect',
          selectOptions: (event) => {
            let listValue = []
            let listText = []
            event.forEach((x: FieldSelectOption) => {
              listValue.push(x.value)
              listText.push(x.label)
            })
            InsuranceTracking.textCriteria['Procedure Codes'] = listText.join(',');
            InsuranceTracking.cleanFilterParams['procedureCodeIds'] = listValue

          }
        },
        {
          matLabel: 'Diagnosis Code',
          name: 'diagnosisCodeIds',
          placeholder: 'Diagnosis Code',
          apiService: injector.get(DiagnosisCodesLookupService),
          class: 'form-span-6 multi-input',
          type: 'multiSelect',
          selectOptions: (event) => {
            let listValue = []
            let listText = []
            event.forEach((x: FieldSelectOption) => {
              listValue.push(x.value)
              listText.push(x.label)
            })
            InsuranceTracking.textCriteria['Diagnosis Codes'] = listText.join(',');
            InsuranceTracking.cleanFilterParams['diagnosisCodeIds'] = listValue

          }
        },
        {
          label: 'Paid/Amount % Min',
          name: 'percentMin',
          class: 'form-span-3',
          type: 'number',
          min: 0,
          max: 999,
          selectionChanged: (event) => {
            InsuranceTracking.textCriteria['Paid/Amount Min'] = event;
          },
          initial: 0,
          validators: [Validators.required, Validators.min(0), Validators.max(999)]

        },
        {
          label: 'Paid/Amount % Max',
          name: 'percentMax',
          class: 'form-span-3',
          type: 'number',
          min: 0,
          max: 999,
          selectionChanged: (event) => {
            InsuranceTracking.textCriteria['Paid/Amount Max'] = event;
          },
          initial: 999,
          validators: [Validators.required, Validators.min(0), Validators.max(999)]
        },
        {
          label: 'Paid/Fee % Min',
          name: 'feeMin',
          class: 'form-span-3',
          type: 'number',
          min: 0,
          max: 999,
          selectionChanged: (event) => {
            InsuranceTracking.textCriteria['Paid/Fee Min'] = event;
          },
          initial: 0,
          validators: [Validators.required, Validators.min(0), Validators.max(999)]
        },
        {
          label: 'Paid/Fee % Max',
          name: 'feeMax',
          class: 'form-span-3',
          type: 'number',
          min: 0,
          max: 999,
          selectionChanged: (event) => {
            InsuranceTracking.textCriteria['Paid/Fee Max'] = event;
          },
          initial: 999,
          validators: [Validators.required, Validators.min(0), Validators.max(999)]
        },
        {
          label: 'Service Date Range',
          name: 'dateRange',
          type: 'dateRange',
          class: 'form-span-6',
          daterangeStartChanged: (event) => {
            InsuranceTracking.textCriteria['Start Date'] = event.value.toLocaleDateString();
          },
          daterangeEndChanged: (event) => {
            InsuranceTracking.textCriteria['End Date'] = event.value.toLocaleDateString();
          }
        },
        {
          label: '',
          name: 'dateRangeStart',
          type: 'noInput',
          class: 'display-none',
        },
        {
          label: '',
          name: 'dateRangeEnd',
          type: 'noInput',
          class: 'display-none',
        },
        {
          label: 'Posted Date Range',
          name: 'postDateRange',
          type: 'dateRange',
          class: 'form-span-6',
          daterangeStartChanged: (event) => {
            InsuranceTracking.textCriteria['Start Post Date'] = event.value.toLocaleDateString();
          },
          daterangeEndChanged: (event) => {
            InsuranceTracking.textCriteria['End Post Date'] = event.value.toLocaleDateString();
          }
        },
        {
          label: '',
          name: 'postDateRangeStart',
          type: 'noInput',
          class: 'display-none',
        },
        {
          label: '',
          name: 'postDateRangeEnd',
          type: 'noInput',
          class: 'display-none',
        },
        {
          label: 'Billed Date Range',
          name: 'billedDateRange',
          type: 'dateRange',
          class: 'form-span-6',
          daterangeStartChanged: (event) => {
            InsuranceTracking.textCriteria['Start Billed Date'] = event.value.toLocaleDateString();
          },
          daterangeEndChanged: (event) => {
            InsuranceTracking.textCriteria['End Billed Date'] = event.value.toLocaleDateString();
          }
        },

        {
          label: '',
          name: 'billedDateRangeStart',
          type: 'noInput',
          class: 'display-none'
        },
        {
          label: '',
          name: 'billedDateRangeEnd',
          type: 'noInput',
          class: 'display-none'
        }
      ]
    }
  ],
  getGridConfiguration: (injector: Injector) => {
    const locale = injector.get(LOCALE_ID);
    const datePipe: DateDisplayPipe = new DateDisplayPipe(locale);
    const percentFormat = new Intl.NumberFormat('en-US', { style: "percent", minimumFractionDigits: 1, maximumFractionDigits: 2 });
    const moneyFormat = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });
    return {
      service: injector.get<any>(InsuranceTrackingService),
      columnDefinitions: [
        {
          id: 'Billed Date',
          displayKey: 'Billed Date',
          headerText: 'Billed Date',
          displayOrder: 0,
          columnSize: ColumnSizes.normal,
          filterable: true,
          sortable: true,
          filterConfig: {
            filterType: FilterType.date,
            filterKey: 'Billed Date'
          },
          formatter: (x) => {
           return datePipe.transform(x)
          }
        },
        {
          id: 'Service Date',
          displayKey: 'Service Date',
          headerText: 'Service Date',
          displayOrder: 1,
          columnSize: ColumnSizes.normal,
          filterable: true,
          sortable: true,
          filterConfig: {
            filterType: FilterType.date,
            filterKey: 'Service Date'
          },
          formatter: (x) => {
           return datePipe.transform(x)
          }
        },
        {
          id: 'Posting Date',
          displayKey: 'Posting Date',
          headerText: 'Posting Date',
          displayOrder: 2,
          columnSize: ColumnSizes.normal,
          filterable: true,
          sortable: true,
          filterConfig: {
            filterType: FilterType.date,
            filterKey: 'Posting Date'
          },
          formatter: (x) => {
            return datePipe.transform(x)
          }
        },
        {
          id: 'Account',
          displayKey: 'Account',
          headerText: 'Account',
          displayOrder: 3,
          columnSize: ColumnSizes.normal,
          filterable: true,
          sortable: true
        },
        {
          id: 'Patient Last',
          displayKey: 'Patient Last',
          headerText: 'Patient Last',
          displayOrder: 4,
          columnSize: ColumnSizes.normal,
          filterable: true,
          sortable: true
        },
        {
          id: 'Patient First',
          displayKey: 'Patient First',
          headerText: 'Patient First',
          displayOrder: 5,
          columnSize: ColumnSizes.normal,
          filterable: true,
          sortable: true
        },
        {
          id: 'Location',
          displayKey: 'Location',
          headerText: 'Location',
          displayOrder: 6,
          columnSize: ColumnSizes.wide,
          filterable: true,
          sortable: true
        },
        {
          id: 'Financial Class',
          displayKey: 'Financial Class',
          headerText: 'Financial Class',
          displayOrder: 7,
          columnSize: ColumnSizes.wide,
          filterable: true,
          sortable: true
        },
        {
          id: 'Type',
          displayKey: 'Type',
          headerText: 'Type',
          displayOrder: 8,
          columnSize: ColumnSizes.extraNarrow,
          filterable: true,
          sortable: true
        },
        {
          id: 'Code',
          displayKey: 'Code',
          headerText: 'Code',
          displayOrder: 9,
          columnSize: ColumnSizes.narrow,
          filterable: true,
          sortable: true
        },
        {
          id: 'Procedure',
          displayKey: 'Procedure',
          headerText: 'Procedure',
          displayOrder: 10,
          columnSize: ColumnSizes.wide,
          filterable: true,
          sortable: true
        },
        {
          id: 'Quantity',
          displayKey: 'Quantity',
          headerText: 'Quantity',
          displayOrder: 11,
          columnSize: ColumnSizes.narrow,
          filterable: true,
          sortable: true
        },
        {
          id: 'Amount',
          displayKey: 'Amount',
          headerText: 'Procedure Amount',
          displayOrder: 12,
          columnSize: ColumnSizes.narrow,
          filterable: true,
          sortable: true,
          bodyClasses: [BodyClasses.alignRight],
          formatter: moneyFormat.format
        },        {
          id: 'Paid',
          displayKey: 'Paid',
          headerText: 'Paid',
          displayOrder: 13,
          columnSize: ColumnSizes.narrow,
          filterable: true,
          sortable: true,
          bodyClasses: [BodyClasses.alignRight],
          formatter: moneyFormat.format
        },
        {
          id: 'Adjusted',
          displayKey: 'Adjusted',
          headerText: 'Adjusted',
          displayOrder: 14,
          columnSize: ColumnSizes.narrow,
          filterable: true,
          sortable: true,
          bodyClasses: [BodyClasses.alignRight],
          formatter: moneyFormat.format
        },
        {
          id: 'Percentage',
          displayKey: 'Percentage',
          headerText: 'Percent',
          displayOrder: 15,
          columnSize: ColumnSizes.narrow,
          filterable: true,
          sortable: true,
          bodyClasses: [BodyClasses.alignRight],
          formatter: percentFormat.format
        },
        {
          id: 'Fee Sched Amount',
          displayKey: 'Fee Sched Amount',
          headerText: 'Fee Sched Amount',
          displayOrder: 16,
          columnSize: ColumnSizes.narrow,
          filterable: true,
          sortable: true,
          bodyClasses: [BodyClasses.alignRight],
          formatter: moneyFormat.format
        },
        {
          id: 'Fee Percentage',
          displayKey: 'Fee Percentage',
          headerText: 'Fee Percent',
          displayOrder: 17,
          columnSize: ColumnSizes.narrow,
          filterable: true,
          sortable: true,
          bodyClasses: [BodyClasses.alignRight],
          formatter: percentFormat.format
        },
        {
          id: 'Insurance',
          displayKey: 'Insurance',
          headerText: 'Insurance',
          displayOrder: 18,
          columnSize: ColumnSizes.narrow,
          filterable: true,
          sortable: true
        }
      ]
    };
  }
};
