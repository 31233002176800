/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EligibilitySourceViewModel } from './eligibilitySourceViewModel';
import { EligibilitySubscriberViewModel } from './eligibilitySubscriberViewModel';
import { EligibilityReceiverViewModel } from './eligibilityReceiverViewModel';

export interface PatientEligibilityDetailsViewModel {
  activeCoverage?: boolean;
  planBeginDate?: string | null;
  planEndDate?: string | null;
  isSubscriber?: boolean;
  isDependent?: boolean;
  lastUpdatedDate?: string;
  insuranceId?: string | null;
  payor?: EligibilitySourceViewModel;
  provider?: EligibilityReceiverViewModel;
  subscriber?: EligibilitySubscriberViewModel;
}
