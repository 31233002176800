/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CodeViewModel } from './codeViewModel';
import { InsuranceCarrierDetailsViewModel } from './insuranceCarrierDetailsViewModel';

export interface InsuranceDetailsViewModel {
  id?: string;
  datasetId?: string;
  patientId?: string;
  insuranceCarrierId?: string | null;
  insuranceCarrier?: InsuranceCarrierDetailsViewModel;
  insuranceTypeId?: string | null;
  insuranceType?: CodeViewModel;
  policyNumber?: string | null;
  groupNumber?: string | null;
  planName?: string | null;
  paperClaimProcedure?: boolean;
  electronicClaimProcedure?: boolean;
  paperClaimCode?: string | null;
  electronicClaimCode?: string | null;
  policyEffectiveFrom?: string | null;
  policyEffectiveTo?: string | null;
  acceptAssignment?: boolean;
  tempInsuranceLinkNumber?: string | null;
  insuredRelation?: string | null;
  insuredLastName?: string | null;
  insuredFirstName?: string | null;
  insuredMiddleName?: string | null;
  insuredSuffix?: string | null;
  insuredBirthDate?: string | null;
  insuredSex?: string | null;
  useGuarantorContactInfo?: boolean;
  insuredAddress1?: string | null;
  insuredAddress2?: string | null;
  insuredCity?: string | null;
  insuredStateCode?: string | null;
  insuredZip?: string | null;
  insuredCountryCode?: string | null;
  insuredPhone?: string | null;
  isDeleted?: boolean;
  deletedBy?: string | null;
  deletedDate?: string | null;
  createdBy?: string | null;
  createdDate?: string;
  updatedBy?: string | null;
  updatedDate?: string | null;
}
