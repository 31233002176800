/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface VisitSearchResultViewModel {
  datasetId?: string;
  patientId?: string;
  visitId?: string;
  accountNumber?: number;
  referenceNumber?: number;
  patientFirstName?: string | null;
  patientMiddleName?: string | null;
  patientLastName?: string | null;
  readonly patientName?: string | null;
  serviceDate?: string;
  procedureMatch?: boolean;
  procedureCode?: string | null;
  procedureDescription?: string | null;
  readonly procedure?: string | null;
  modifierMatch?: boolean;
  modifier1?: string | null;
  modifierDesc1?: string | null;
  modifier2?: string | null;
  modifierDesc2?: string | null;
  modifier3?: string | null;
  modifierDesc3?: string | null;
  modifier4?: string | null;
  modifierDesc4?: string | null;
  readonly modifiers?: string | null;
  checkNumberMatch?: boolean;
  checkNumber?: string | null;
  internalControlNumberMatch?: boolean;
  internalControlNumber?: string | null;
  eobCodeMatch?: boolean;
  eobDetailCode?: string | null;
  eobNameMatch?: boolean;
  readonly eobMatch?: boolean;
  eobDetailName?: string | null;
  readonly eobCode?: string | null;
  remarkCodeMatch?: boolean;
  readonly remarkMatch?: boolean;
  remarkDetailCode?: string | null;
  readonly remark?: string | null;
  remarkNameMatch?: boolean;
  remarkDetailName?: string | null;
  financialClassMatch?: boolean;
  financialClassCode?: string | null;
  financialClass?: string | null;
  readonly financialClassDisplay?: string | null;
  accidentTypeMatch?: boolean;
  accidentTypeCode?: string | null;
  accidentType?: string | null;
  readonly accidentTypeDisplay?: string | null;
  adjustmentCodeMatch?: boolean;
  adjustmentCode?: string | null;
  adjustmentDescMatch?: boolean;
  adjustmentDesc?: string | null;
  readonly adjustment?: string | null;
  patientNameMatch?: boolean;
  procedureResultMatch?: boolean;
  procedureResultData?: string | null;
  diagnosisMatch?: boolean;
  diagnosisCode?: string | null;
  diagnosisName?: string | null;
  readonly diagnosis?: string | null;
  readonly score?: number;
}
