import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { BalanceService } from './api/balance.service';
import { BalanceBillingService } from './api/balanceBilling.service';
import { PaymentPlansService } from './api/paymentPlans.service';
import { PaymentRequestsService } from './api/paymentRequests.service';
import { ProviderService } from './api/provider.service';
import { ReceiptService } from './api/receipt.service';
import { RequestService } from './api/request.service';
import { SearchService } from './api/search.service';
import { TransactionService } from './api/transaction.service';

@NgModule({
  imports: [],
  declarations: [],
  exports: [],
  providers: []
})
export class ApiModule {
  public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [{ provide: Configuration, useFactory: configurationFactory }]
    };
  }

  constructor(@Optional() @SkipSelf() parentModule: ApiModule, @Optional() http: HttpClient) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error(
        'You need to import the HttpClientModule in your AppModule! \n' +
          'See also https://github.com/angular/angular/issues/20575'
      );
    }
  }
}
