import { ColumnDefinition } from '../models';

export class BaseColumnClass {
  protected baseTransform(classes: string[], columnDefinition: ColumnDefinition): string[] {
    if (columnDefinition.columnSize) {
      classes.push(columnDefinition.columnSize);
    }
    return classes;
  }
}
