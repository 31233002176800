<div class="practice-information">
  <div>
    <ng-container>
      <lib-dynamic-form [dynamicFormGroup]="formGroup" [formGroupDefinitions]="formDefinitions"> </lib-dynamic-form>
    </ng-container>
  </div>
  <footer class="practice-information__form-footer">
    <lib-button-loading
      color="primary"
      class="practice-information__button"
      [loading]="saving"
      [invalid]="formGroup.invalid"
      *ngIf="!saved"
      (clickEvent)="save()"
      >Save
    </lib-button-loading>
    <lib-button-loading
      color="primary"
      class="practice-information__button"
      [loading]="gonext"
      [invalid]="formGroup.invalid || !saved"
      *ngIf="saved"
      (clickEvent)="next()"
      >Next
    </lib-button-loading>
  </footer>
</div>
