/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface UnappliedRemittanceViewModel {
  id?: string;
  datasetId?: string;
  remitHoldingId?: string;
  remitClaimHoldingId?: string;
  remitProcedureHoldingId?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  batchId?: string;
  unappliedCause?: string | null;
  createdBy?: string | null;
  createdDate?: string;
  serviceDate?: string;
  accountNumber?: string | null;
  claimAmount?: number;
  payer?: string | null;
  amount?: number;
  checkNumber?: string | null;
  batchNumber?: number;
}
