<ng-container *ngIf="uploadProgress$ | async as uploadProgress">
  <article class="file-summary">
    <mat-icon class="file-summary__icon">insert_drive_file</mat-icon>
    <span class="file-summary__name">{{ file.name }}</span>
    <span *ngIf="!hasError && uploadProgress.progressPercentage" class="file-summary__percentage"
      >{{ uploadProgress.progressPercentage }}%</span
    >
    <mat-icon *ngIf="hasError" class="file-summary__percentage error"> priority_high </mat-icon>
    <mat-progress-bar
      *ngIf="!hasError && uploadProgress.progressPercentage"
      class="file-summary__bar"
      [value]="uploadProgress.progressPercentage"
      color="accent"
    ></mat-progress-bar>
    <span *ngIf="hasError" class="file-summary__bar error"> {{ errorMessage }}</span>
    <mat-icon *ngIf="!uploadProgress.progressPercentage" class="file-summary__remove" (click)="remove()"
      >close</mat-icon
    >
  </article>
</ng-container>
