/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface StatementOnHoldViewModel {
  patientId?: string;
  accountNumber?: number;
  firstName?: string | null;
  lastName?: string | null;
  financialClassId?: string;
  financialClassCode?: string | null;
  financialClassName?: string | null;
  delinquencyCode?: number;
  patientBalance?: number;
  statementHoldReason?: string | null;
  statementHoldDate?: string | null;
}
