import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SecurityService } from './security.service';
import { LoginModule } from './login/login.module';
import { UnauthorizedModule } from './unauthorized/unauthorized.module';
import { LogoutModule } from './logout/logout.module';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  exports: [LoginModule, LogoutModule, UnauthorizedModule],
  providers: [SecurityService]
})
export class SecurityModule { }
