import { PagedModel } from '../models/paged-model';

function filter(dataSource, pagedModel: PagedModel) {
  dataSource = filterText(dataSource, pagedModel);
  dataSource = filterNumberRange(dataSource, pagedModel);
  dataSource = filterDateRange(dataSource, pagedModel);

  return dataSource;
}

function filterText(dataSource, pagedModel: PagedModel) {
  if (pagedModel.propertySearches !== undefined) {
    pagedModel.propertySearches.forEach((filters) => {
      dataSource = dataSource.filter((ds) => {
        return ds[filters.propertyName]?.toString()?.toLowerCase()?.includes(filters.value.toLowerCase());
      });
    });
  }
  return dataSource;
}
function filterNumberRange(dataSource, pagedModel: PagedModel) {
  if (pagedModel.numericRanges !== undefined) {
    pagedModel.numericRanges.forEach((filters) => {
      dataSource = dataSource.filter((ds) => {
        return ds[filters.propertyName] >= filters.rangeStart && ds[filters.propertyName] <= filters.rangeEnd;
      });
    });
  }
  return dataSource;
}

function filterDateRange(dataSource, pagedModel: PagedModel) {
  const dateFormat = new Intl.DateTimeFormat('en-US')
  if (pagedModel.dateRanges !== undefined) {
    pagedModel.dateRanges.forEach((filters) => {
      dataSource = dataSource.filter((ds) => {
        return new Date(ds[filters.propertyName]) >= new Date(filters.rangeStart) && new Date(ds[filters.propertyName]) <= new Date(filters.rangeEnd);
      });
    });
  }
  return dataSource;
}

function sortData(dataSource, pagedModel: PagedModel) {
  if (pagedModel.sortColumn) {
    return dataSource.sort((a, b) => {
      return compare(a[pagedModel.sortColumn], b[pagedModel.sortColumn], pagedModel.sortOrder === 1);
    });
  }

  return dataSource;
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}

function pageData(dataSource: any[], pagedModel: PagedModel) {
  const pageIndex = pagedModel.page;
  const skipCount = pageIndex * pagedModel.pageSize;

  return dataSource.slice(skipCount, skipCount + pagedModel.pageSize);
}

export const StaticCollectionUtilities = {
  filter,
  filterText,
  filterNumberRange,
  /*filterDateRange*/
  sortData,
  compare,
  pageData
};
