/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface FeeScheduleEntryViewModel {
  id?: string;
  datasetId?: string;
  feeScheduleId?: string;
  procedureId?: string;
  modifierId?: string | null;
  procedureCode?: string | null;
  procedureDescription?: string | null;
  effectiveFrom?: string;
  effectiveTo?: string;
  readonly procedureDisplay?: string | null;
  modifierDisplay?: string | null;
  amount?: number;
  createdBy?: string | null;
  createdDate?: string;
  updatedBy?: string | null;
  updatedDate?: string | null;
  isDeleted?: boolean;
  deletedBy?: string | null;
  deletedDate?: string | null;
}
