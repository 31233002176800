import { createAction, props } from '@ngrx/store';
import { UserDetailsViewModel } from 'data';
import { UserState } from './user.state';

const base = '[User] -';

export const loadUser = createAction(`${base} Load User`);
export const loadUserSuccess = createAction(`${base} Load Success`, props<{ user: UserDetailsViewModel }>());
export const loadUserFailure = createAction(`${base} Load Failure`, props<{ error: any }>());

export const updateUser = createAction(`${base} Update User`);
