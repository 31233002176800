import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { VisitDetailsViewModel } from 'data';

@Component({
  selector: 'lib-procedure-totals',
  templateUrl: './procedure-totals.component.html',
  styleUrls: ['./procedure-totals.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProcedureTotalsComponent implements OnInit {
  @Input()
  visit: any;
  // TODO!!!!!!!!!!!!!!!!!!!!!!!!
  //   visit: VisitDetailsViewModel;

  constructor() {}

  ngOnInit(): void { }

  trackById(index: number, item: any) {
    return item.id
  }
}
