import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ShowDialogContent } from '..';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { NotificationService } from '@core/services/notification.service';

@Component({
  selector: 'lib-show-dialog',
  templateUrl: './show-dialog.component.html',
  styleUrls: ['./show-dialog.component.scss']
})
export class ShowDialogComponent implements OnInit {
  defaultModalContent: ShowDialogContent = {
    header: 'Please Confirm',
    body: 'Are you sure you want to continue?',
    subBody: '',
    cancelButtonText: 'Cancel',
    OKButtonText: 'OK',
    cancelButtonVisible: true
  };
  constructor(
    public dialogRef: MatDialogRef<ShowDialogComponent>,
    public notificationService: NotificationService,
    @Inject(MAT_DIALOG_DATA) public data: ShowDialogContent
  ) { }

  saving: Boolean = false;

  ngOnInit(): void { }

  /**
   * Closes dialog
   *
   * @memberof DialogComponent
   */
  ok() {
    if (this.data.observable) {
      if (this.saving === false) {
        this.saving = true;
        let observable: Observable<any> = this.data.observable;
        observable.pipe(take(1)).subscribe((response) => {
            this.dialogRef?.close(true)
          },
          (err) => {
            this.notificationService.error(this.data.errorMessage);
            this.dialogRef.close(false)
          })
      }
    } else {
      this.dialogRef.close(true);
      return true;
    }
  }

  /**
   * Dismisses dialog
   *
   * @memberof DialogComponent
   */
  cancel() {
    this.dialogRef.close(false);
    return false;
  }
}
