import { Component, OnInit, Input, Output, ViewEncapsulation, ChangeDetectionStrategy, ViewChild, EventEmitter, AfterViewInit, HostBinding, OnDestroy } from '@angular/core';
import { GridService } from '../grid.service';
import { ColumnDisplayType, GridConfiguration, PagedModel } from '../models';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { UntypedFormGroup } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { MatTable } from '@angular/material/table';
import { debounceTime, distinctUntilChanged, take } from 'rxjs/operators';

@Component({
  selector: 'lib-report-grid',
  templateUrl: './report-grid.component.html',
  styleUrls: ['./report-grid.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [GridService]
})
export class ReportGridComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() dataSource: Observable<any[]>;
  @Input() count;
  @Input() initialized: boolean;
  @Input() loading: boolean;
  @Input() isModal: boolean;
  @Input() pagedModel: PagedModel;
  @Input() gridConfiguration: GridConfiguration;
  @Input() filterStringParameter: string;
  @Input() enableFilter: boolean = true; 
  @Input() clearFilterOnInitialLoad: boolean = false;
  @Input() hasPaginator: boolean = true;
  @Input() emptyMessage: boolean = false;
  @Input() spinner: boolean = false;
  @Output() pageChanged: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();
  @Output() sortFilterChanged: EventEmitter<PagedModel> = new EventEmitter<PagedModel>();

  @ViewChild('column') column: HTMLElement;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) grid: MatTable<any>;

  @HostBinding('class') gridClass = 'lib-report-grid';
  @HostBinding('class.mat-elevation-z2') get gridElevation() {
    return this.gridConfiguration?.elevation;
  }
  
  get hasFilters(): boolean {
    return this.gridConfiguration?.columnDefinitions?.some((x) => x.filterable === true) || false;
  }

  columnTypes = ColumnDisplayType;
  
  public filterFormGroup: UntypedFormGroup;
  subscriptions: Subscription[] = [];
  constructor(public gridService: GridService, public router: Router, public route: ActivatedRoute) {}

  ngOnInit(): void {
    this.filterFormGroup = new UntypedFormGroup({});
  }
  
  ngAfterViewInit(): void {
    //this gets rid of grid lines when result is 0
    const paginator$ = this.paginator?.page.subscribe(x => this.pageChanged.emit(x));
    const sort$ = this.sort?.sortChange.subscribe(s => {
      const pm = this.gridService.buildPagedModel(null, this.sort, this.gridConfiguration, this.filterFormGroup);
      this.sortFilterChanged.emit(pm)
    });
    const filter$ = this.filterFormGroup.valueChanges
    .pipe(
      debounceTime(750),
      distinctUntilChanged(),
    ).subscribe((f) => 
    {
      // mayvbe why it takes a while to reload datasource results after cancel
      const pm = this.gridService.buildPagedModel(null, this.sort, this.gridConfiguration, this.filterFormGroup);
      this.sortFilterChanged.emit(pm);
    });

    this.subscriptions.push(paginator$, sort$, filter$);
  }

  ngOnDestroy() {
      this.subscriptions.forEach((s) => s?.unsubscribe());
  }

  public camelCaseRemoveSpaces(str: string) {
    return (str.substring(0, 1).toLowerCase() + str.substring(1, str.length)).replace(' ', '');
  }
  trackByIndex(index: number, item: any) {
    return index
  }
  trackById(index: number, item: any) {
    return item.id
  }
}
