import { Injectable } from '@angular/core';
import { AuthUserPostModel, Operation, PagedModelRequest, RoleService, UserService } from 'data';
import { Observable } from 'rxjs';
import { BaseWrapperService } from './base-wrapper.service';
import { SessionStorageService } from '../session-storage.service';

@Injectable({
  providedIn: 'root'
})
export class UserMaintenanceSecurityWrapperService extends BaseWrapperService {
  constructor(
    private roleService: RoleService,
    private userService: UserService,
    protected sessionStorageService: SessionStorageService
  ) {
    super();
  }

  apiV1RolePost(pagedModelRequest?: PagedModelRequest): Observable<any> {
    return this.roleService.apiV1RolePost(this.getDataSetId(), pagedModelRequest);
  }

  apiV1RoleCreateRolePost(authRolePostModel?: any): Observable<any> {
    return this.roleService.apiV1RoleCreateRolePost(this.getDataSetId(), authRolePostModel);
  }

  apiV1RoleIdGet(roleId: string): Observable<any> {
    return this.roleService.apiV1RoleIdGet(roleId, this.getDataSetId());
  }

  apiV1RoleDeleteRoleDelete(roleId?: string): Observable<any> {
    return this.roleService.apiV1RoleDeleteRoleDelete(this.getDataSetId(), roleId);
  }

  apiV1RoleUpdateRolePost(authRolePostModel): Observable<any> {
    return this.roleService.apiV1RoleUpdateRolePost(this.getDataSetId(), authRolePostModel);
  }

  // User APIs
  apiV1UserPost(pagedModelRequest?: PagedModelRequest): Observable<any> {
    return this.userService.apiV1UserPost(this.getDataSetId(), pagedModelRequest);
  }

  apiV1UserCreateUserPost(authRolePostModel?: AuthUserPostModel): Observable<any> {
    return this.userService.apiV1UserCreateUserPost(this.getDataSetId(), authRolePostModel);
  }

  apiV1UserIdGet(userId: string): Observable<any> {
    return this.userService.apiV1UserIdGet(userId, this.getDataSetId());
  }

  apiV1UserUpdateUserPatch(userId?: string, operation?: Array<Operation>): Observable<any> {
    return this.userService.apiV1UserUpdateUserPatch(this.getDataSetId(), userId, operation);
  }

  apiV1UserDeleteUserDelete(userId?: string): Observable<any> {
    return this.userService.apiV1UserDeleteUserDelete(this.getDataSetId(), userId);
  }

  apiV1UserRolesPost(userRolePostModel?: any): Observable<any> {
    return this.userService.apiV1UserRolesPost(this.getDataSetId(), userRolePostModel);
  }

  apiV1UserDatasetsPost(userDatasetPostModel?: any): Observable<any> {
    return this.userService.apiV1UserDatasetsPost(this.getDataSetId(), userDatasetPostModel);
  }
}
