import { Component, Inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'lib-toast',
  template: `
    <div class="notification">
      <mat-icon *ngIf="data?.icon">{{ data?.icon }}</mat-icon> {{ data.message }}
    </div>
  `,
  styles: [
    `
           .notification {
             display: flex;
             align-items: center;
           }
           .notification mat-icon {
             margin-right: 10px;
           }
         `
  ]
})
export class ToastComponent implements OnInit {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data) {}

  ngOnInit(): void {}
}
