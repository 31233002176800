/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export type ComparisonType = 0 | 1 | 2 | 3 | 4 | 5;

export const ComparisonType = {
  NUMBER_0: 0 as ComparisonType,
  NUMBER_1: 1 as ComparisonType,
  NUMBER_2: 2 as ComparisonType,
  NUMBER_3: 3 as ComparisonType,
  NUMBER_4: 4 as ComparisonType,
  NUMBER_5: 5 as ComparisonType
};
