import { Injectable } from '@angular/core';
import {
  AddScheduledJobViewModel,
  AddScheduledReportViewModel,
  Operation,
  PagedModelRequest,
  ScheduledJobService
} from 'data';
import { ScheduledJobDetailsViewModel } from 'projects/data/src/lib/model/scheduledJobDetailsViewModel';
import { ScheduledJobHistoryViewModelPagedModelResponse } from 'projects/data/src/lib/model/scheduledJobHistoryViewModelPagedModelResponse';
import { ScheduledReportExecutionViewModelPagedModelResponse } from 'projects/data/src/lib/model/scheduledReportExecutionViewModelPagedModelResponse';
import { Observable } from 'rxjs';
import { BaseWrapperService } from './base-wrapper.service';
import { SessionStorageService } from '../session-storage.service';
@Injectable({
  providedIn: 'root'
})
export class ScheduledJobWrapperService extends BaseWrapperService {
  constructor(private service: ScheduledJobService, protected sessionStorageService: SessionStorageService) {
    super();
  }

  apiV1ScheduledJobDetailsIdGet(id: string): Observable<any> {
    return this.service.apiV1ScheduledJobDetailsIdGet(id, this.getDataSetId());
  }

  apiV1ScheduledJobAllDetailPost(pagedModelRequest?: PagedModelRequest): Observable<any> {
    return this.service.apiV1ScheduledJobAllDetailPost(this.getDataSetId(), pagedModelRequest);
  }

  apiV1ScheduledJobAddPost(addScheduledJobViewModel?: AddScheduledJobViewModel): Observable<any> {
    return this.service.apiV1ScheduledJobAddPost(this.getDataSetId(), addScheduledJobViewModel);
  }

  apiV1ScheduledJobPatchIdPatch(id: string, operation?: Array<Operation>): Observable<any> {
    return this.service.apiV1ScheduledJobPatchIdPatch(id, this.getDataSetId(), operation);
  }

  apiV1ScheduledJobHistoryPost(
    pagedModelRequest?: PagedModelRequest
  ): Observable<ScheduledJobHistoryViewModelPagedModelResponse> {
    return this.service.apiV1ScheduledJobHistoryPost(this.getDataSetId(), pagedModelRequest);
  }

  apiV1ScheduledJobDownloadIdPost(id: string): Observable<Blob> {
    return this.service.apiV1ScheduledJobDownloadIdPost(id, this.getDataSetId());
  }

  apiV1ScheduledJobDownloadFileIdPost(id: string): Observable<any> {
    return this.service.apiV1ScheduledJobDownloadFileIdPost(id, this.getDataSetId());
  }

  apiV1ScheduledJobAddReportPost(
    addScheduledReportViewModel: AddScheduledReportViewModel
  ): Observable<ScheduledJobDetailsViewModel> {
    return this.service.apiV1ScheduledJobAddReportPost(this.getDataSetId(), addScheduledReportViewModel);
  }

  apiV1ScheduledJobReportHistoryPost(
    pagedModelRequest?: PagedModelRequest
  ): Observable<ScheduledReportExecutionViewModelPagedModelResponse> {
    return this.service.apiV1ScheduledJobReportHistoryPost(this.getDataSetId(), pagedModelRequest);
  }

  apiV1ScheduledJobIdDelete(id: string): Observable<any> {
    return this.service.apiV1ScheduledJobIdDelete(id, this.getDataSetId());
  }
}
