/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface StatusSegmentModel {
  claimStatusCategoryCodeId?: string | null;
  claimStatusCodeId?: string | null;
  entityIdentifierCode?: string | null;
  statusEffectiveDate?: string | null;
  claimStatusActionType?: string | null;
  totalAmount?: number;
  claimStatusCategoryCode2Id?: string | null;
  claimStatusCode2Id?: string | null;
  entityIdentifier2Code?: string | null;
  claimStatusCategoryCode3Id?: string | null;
  claimStatusCode3Id?: string | null;
  entityIdentifier3Code?: string | null;
  freeFormMessageText?: string | null;
  holdFromQueue?: boolean;
}
