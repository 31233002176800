<div class="implementation-start__container">
  <div class="implementation-start__elements">
    <div>
      <img
        src="../../../../assets/peregrine-logo-white_black.svg"
        class="logo"
        alt="Imagine"
        width="475"
        height="300"
      />
    </div>
    <div>
      <lib-button-loading color="primary" class="implementation-start__button" (clickEvent)="next()"
        >Click Here to Get Started
      </lib-button-loading>
    </div>
  </div>
</div>
