import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, switchMap, tap } from 'rxjs/operators';

import { SessionStorageService } from '@core/services/session-storage.service';
import { loadDataSets, loadDataSetsFailure, loadDataSetsSuccess, selectDataSet } from './data-set.actions';

@Injectable()
export class DataSetEffects {
  constructor(private actions$: Actions, private sessionStorageService: SessionStorageService) {}

  setSelectedUserClient = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(selectDataSet),
        tap(({ dataSetId }) => {
          this.sessionStorageService.setItem('selected-data-set-id', dataSetId);
        })
      );
    },
    { dispatch: false }
  );
}
