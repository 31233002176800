/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ARTransactionDetailRemarkHoldingViewModel } from './aRTransactionDetailRemarkHoldingViewModel';
import { ARTransactionDetailEOBHoldingViewModel } from './aRTransactionDetailEOBHoldingViewModel';

export interface ARTransactionDetailsHoldingViewModel {
  id?: string;
  datasetId?: string;
  procedureTransactionId?: string;
  arTransactionHoldingId?: string;
  batchId?: string;
  allowedAmount?: number;
  paymentAmount?: number;
  adjustmentAmount?: number;
  adjustment2Amount?: number;
  interestAdjustmentAmount?: number;
  deductibleAmount?: number;
  copayAmount?: number;
  coinsuranceAmount?: number;
  rebillInsurance?: boolean;
  whoEnteredAllowed?: string | null;
  whoEnteredPayment?: string | null;
  whoEnteredAdjustment?: string | null;
  whoEnteredAdjustment2?: string | null;
  whoEnteredDeductible?: string | null;
  arTransactionDetailEOBHoldings?: Array<ARTransactionDetailEOBHoldingViewModel> | null;
  arTransactionDetailRemarkHoldings?: Array<ARTransactionDetailRemarkHoldingViewModel> | null;
}
