import { Component, Injector, OnInit, Output, EventEmitter, OnDestroy, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Report } from '../../models/report';
import { getReportById } from '../../configs/all-reports';
import { FieldDefinition, FormGroupDefinition, PagedModel } from 'components';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { T } from '@angular/cdk/keycodes';
import { UserLookupService } from '@core/services/lookup/user-lookup.service';
import { ExecutedJobWrapperService } from '@core/services/service-wrappers/executed-job-wrapper.service';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { ScheduledJobWrapperService } from '@core/services/service-wrappers/scheduled-job-wrapper.service';
import { AddScheduledReportViewModel, ComparisonType, PagedModelRequest, PropertySearch, ScheduledJobDetailsViewModel, ScheduledReportExecutionViewModel } from 'data';
import { compare } from 'fast-json-patch';
import { NotificationService } from '@core/services/notification.service';
import { Store } from '@ngrx/store';
import { AppState } from '@core/core.state';
import { ZeroBalance } from '../../configs/financial-analysis/zero-balance';
import { arrayBuffer } from 'stream/consumers';

@Component({
  selector: 'app-report-form',
  templateUrl: './report-form.component.html',
  styleUrls: ['./report-form.component.scss']
})
export class ReportFormComponent implements OnInit, OnDestroy {
  @Output() reportRequest: EventEmitter<void> = new EventEmitter<void>();

  reportId: string;
  reportGroup: string;
  reportName: string;
  report: Report;
  formInitialized = false;
  cleanFilterParams;
  reportCriteriaForm: FormGroupDefinition[];
  reportCriteria = null;
  reportTextCriteria = null;
  jobId = null;
  formGroup = new UntypedFormGroup({});

  isResultsMode = false;

  schedulerForm: FormGroupDefinition[]
  schedulerFormGroup: UntypedFormGroup = null;
  isSchedulerMode: boolean = false;

  data: null;

  subscriptions: Subscription[] = [];
  frequencyChanges$: Observable<any>;

  menuOpen = false;
  updateScheduledReport = false;

  executedScheduledReport: ScheduledReportExecutionViewModel = null;
  frequency: number;
  user;

  getSchedulerFormDefinition(isEdit: boolean, userLookupService: UserLookupService): FormGroupDefinition[] {
    return [
      {
        hideTitle: true,
        controls: [
          {
            label: 'Status',
            name: 'enabled',
            type: 'select',
            class: 'form-span-4',
            options: [{value: true, label: 'Enabled'}, {value: false, label: 'Disabled'}]
          },
          {
            label: 'Scheduled Date',
            name: 'scheduledDate',
            placeholder: 'Date',
            type: 'date',
            class: 'form-span-4',
            validators: Validators.required
          },
          {
            label: 'Scheduled Time',
            name: 'scheduledTime',
            placeholder: 'Time',
            type: 'time',
            class: 'form-span-4',
            validators: Validators.required
          },
          {
            label: 'Frequency',
            name: 'frequency',
            focusId: 'frequency',
            type: 'select',
            class: 'form-span-3',
            options: [{value: 1, label: 'Daily'}, {value: 2, label: 'Weekly'}, {value: 3, label: 'Monthly - same day'}, {value: 4, label: 'Monthly - last calendar day of month'}, {value: 5, label: 'Monthly - last business day of month'}, ],
            validators: Validators.required
          },
          {
            label: 'User',
            name: 'user',
            type: 'select',
            class: 'form-span-4',
            apiService: userLookupService,
            validators: Validators.required
          },
          {
            label: 'Description',
            name: 'description',
            type: 'text',
            class: 'form-span-5',
            validators: Validators.required
          }
        ]
      }
    ];
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private injector: Injector,
    public route: ActivatedRoute,
    private userLookupService: UserLookupService,
    private scheduledJobWrapperService: ScheduledJobWrapperService,
    private notificationService: NotificationService,
    private store: Store<AppState>
  ) {
    const urlsub = this.activatedRoute.url.subscribe((u) => {
      this.isResultsMode = u.find(u => u.path === "results") !== undefined;
    });

    const paramsub = this.activatedRoute.params.subscribe((p) => {
      this.reportId = p.reportId;
      this.reportGroup = p.reportGroup;
      this.reportName = p.reportName;
      this.report = getReportById(this.reportId);
      if (this.report) {
        this.menuOpen = false;
        this.setupForm();
      }
    });

    const qpsub = this.activatedRoute.queryParams.subscribe((qp) => {
      if (qp.reportCriteria) this.reportCriteria = JSON.parse(atob(decodeURIComponent(qp.reportCriteria)));
      if (qp.reportTextCriteria) this.reportTextCriteria = JSON.parse(atob(decodeURIComponent(qp.reportTextCriteria)))
      if (qp.updateScheduledReport) this.updateScheduledReport = true;
      if (qp.executedJobId && qp.scheduledJobId) {
        const executedJobId = atob(decodeURIComponent(qp.executedJobId));
        const scheduledJobId = atob(decodeURIComponent(qp.scheduledJobId));
        const propertySearch: PropertySearch = { propertyName: 'scheduledJobId', comparisonType: ComparisonType.NUMBER_0, value: scheduledJobId}
        const pagedModelRequest: PagedModelRequest =  {
          page: 1,
          pageSize: 1,
          propertySearches: [propertySearch]
        };
        const exsub = this.scheduledJobWrapperService
          .apiV1ScheduledJobReportHistoryPost(pagedModelRequest)
          .subscribe((history) =>
          {
              this.executedScheduledReport = history.data[0];
              this.reportCriteria = JSON.parse(this.executedScheduledReport.parameterJson);
              this.reportTextCriteria = JSON.parse(this.executedScheduledReport.textParameterJson);
              this.loadReportCriteria();

            if (this.updateScheduledReport)
            {
              this.setupScheduleReport();
            }
          });
        this.subscriptions.push(exsub);
      }
    });
    const usersub = this.store.subscribe(s => this.user = s.user);
    this.subscriptions.push(usersub);
    this.subscriptions.push(urlsub);
    this.subscriptions.push(paramsub);
    this.subscriptions.push(qpsub);

    this.schedulerForm = this.getSchedulerFormDefinition(true, userLookupService);
  }

  ngOnInit(): void {
  
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  async setupSchedulerForm() {
    this.schedulerForm = null;
    this.schedulerFormGroup = null;

    const tempReportCriteria = this.getSchedulerFormDefinition(true, this.userLookupService);
    const tempFormGroup = new UntypedFormGroup({});
    await this.delay(50); // delay needed to switch form groups w/o validation holdovers

    tempReportCriteria.forEach((sc) => {
      sc.controls.forEach((control) => {
        if (control.type !== 'empty' && control.type !== 'label') {
          tempFormGroup.addControl(control.name, new UntypedFormControl('', control.validators));
        }
      });
    });

    this.schedulerForm = tempReportCriteria;
    this.schedulerFormGroup = tempFormGroup;

    if (this.updateScheduledReport && this.scheduleReport) {
      this.schedulerFormGroup.get('enabled').setValue(this.executedScheduledReport.enabled);
      if (this.executedScheduledReport?.scheduledDateTime)
      {
        const sd = new Date(this.executedScheduledReport.scheduledDateTime)
        this.schedulerFormGroup.get('scheduledDate').setValue(sd);
        const hrmin = sd.getHours().toString().padStart(2, '0') + ':' + sd.getMinutes().toString().padStart(2, '0');
        this.schedulerFormGroup.get('scheduledTime').setValue(hrmin);
      }

      if (this.executedScheduledReport.reportFrequency) {
        this.schedulerFormGroup.get('frequency').setValue(this.executedScheduledReport.reportFrequency);
        this.frequency = this.executedScheduledReport.reportFrequency;
      }

      this.schedulerFormGroup.get('user').setValue(this.executedScheduledReport.scheduledForId);
      this.schedulerFormGroup.get('description').setValue(this.executedScheduledReport.description);

      this.frequencyChanges$ = this.schedulerFormGroup.get('frequency').valueChanges;
      this.frequencyChanges$.subscribe((x) => {
          if (this.formInitialized) this.frequencyChanged(x);
      });
    }
    else if (this.scheduleReport) {
      this.schedulerFormGroup.get('enabled').setValue(true);
      this.schedulerFormGroup.get('user').setValue(this.user?.id);
    }
  }

  async setupForm() {
    this.isSchedulerMode = false;
    this.reportCriteriaForm = null;
    this.formGroup = null;

    const tempReportCriteriaForm = this.report.getSearchCriteria(this.injector);
    const tempFormGroup = new UntypedFormGroup({});

    await this.delay(50); // delay needed to switch form groups w/o validation holdovers

    tempReportCriteriaForm.forEach((sc) => {
      sc.controls.forEach((control) => {
        if (control.type !== 'empty' && control.type !== 'label') {
            tempFormGroup.addControl(control.name, new UntypedFormControl(control.initial ?? '', control.validators));
        }
      });
    });

    this.reportCriteriaForm = tempReportCriteriaForm;
    this.formGroup = tempFormGroup;
    this.report.formGroup = this.formGroup;

    if (!this.report.init) {
      this.report.originalTextCriteria = { ...this.report.textCriteria };

      this.report.init = true;
    }

    if (this.reportCriteria) {
      this.loadReportCriteria();
    }
    this.formInitialized = true;
  }

  loadReportCriteria() {
    let formDefinition: FieldDefinition;
    Object.keys(this.formGroup?.value).forEach((k) => {
      if (this.reportCriteria[k])
        if (this.reportCriteria[k]?.length) {
          formDefinition = this.reportCriteriaForm[0].controls.find((control) => control.name === k)
          if (k === 'financialClassIds' && this.reportName === 'Archive Accounts' && typeof (this.reportCriteria['financialClassIds']) === 'string') {
            this.reportCriteria[k] = this.reportCriteria[k].split(",").map(item => item.trim());
          }
          if (Array.isArray(this.reportCriteria[k])) {
            formDefinition.initialMulti = this.reportCriteria[k]
          }
        }
        setTimeout(() => {
          const ctrl = this.formGroup.get(k)
          ctrl.setValue(this.reportCriteria[k]);
          if (ctrl.status === "INVALID" && k.toLowerCase().indexOf('date') > -1)
            ctrl.setValue(new Date(this.reportCriteria[k]));
        }, 100);
      });
  }

  @HostListener('window:popstate', ['event'])
  onPopState(event) {
    this.cancel();
  }


  cancel() {
    this.clearFilterParams();
    const routePath = ['reports'];
    if (this.executedScheduledReport != null) routePath.push('scheduled');
    this.router.navigate(routePath);
  }

  clearFilterParams() {
    let formDefinition: FieldDefinition;
    if (!this.reportCriteria) return;
    Object.keys(this.formGroup.value).forEach((k) => {
      if (this.report.cleanFilterParams && this.reportCriteria[k])
        if (this.report.cleanFilterParams[k]) {
          this.report.cleanFilterParams[k] = ''
        }
    })
  }

  generateReport() {
    const routePath = ['reports', 'results', this.reportGroup, this.reportName, this.reportId];
    const reportCriteria = JSON.stringify(this.getCleanReportCriteria());
    const reportTextCriteria = JSON.stringify(this.report.textCriteria);
    this.reportRequest.emit();
    this.router.navigate(routePath, {
      queryParams: {
        reportCriteria: encodeURIComponent(btoa(reportCriteria)),
        reportTextCriteria: encodeURIComponent(btoa(reportTextCriteria))
      }
    });
  }

  updateReport() {
    this.generateReport();
  }

  getCleanReportCriteria(includeSlug = true) {
    let cleanFilterParams = {};
    if (includeSlug) cleanFilterParams = { slug: this.report.id };
    Object.keys(this.formGroup.value).forEach((k) => {
      if (k != 'dateRange' && this.formGroup.value[k] !== '') {
        cleanFilterParams[k] = this.formGroup.value[k];
      }
    });
    
    if (this.report.cleanFilterParams) {
      Object.keys(this.report.cleanFilterParams).forEach((k) => {
        if (k !== '') {
          cleanFilterParams[k] = this.report.cleanFilterParams[k] === "" ? [] : this.report.cleanFilterParams[k]
        }
      })
    }
    return cleanFilterParams;
  }

  setupScheduleReport() {
    this.isSchedulerMode = true;
    this.menuOpen = false;
    this.setupSchedulerForm();
  }

  cancelScheduleReport() {
    if (this.executedScheduledReport != null)
      this.router.navigate(['reports', 'scheduled']);
    else
      this.isSchedulerMode = false;
  }

  scheduleReport() {
    const date = new Date(this.schedulerFormGroup.get('scheduledDate').value);
    const schedTime = this.schedulerFormGroup.get('scheduledTime').value;
    let utcdate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), schedTime.split(':')[0], schedTime.split(':')[1], 0);
    utcdate.setMinutes(utcdate.getMinutes() + utcdate.getTimezoneOffset());
    if (Array.isArray(this.getCleanReportCriteria()['financialClassIds']) && this.reportName === 'Archive Accounts') {
      this.getCleanReportCriteria()['financialClassIds'] = this.getCleanReportCriteria()['financialClassIds'].toString()
    }
    const reportCriteria = JSON.stringify(this.getCleanReportCriteria());
    const reportTextCriteria = JSON.stringify(this.report.textCriteria);
    if (this.executedScheduledReport?.scheduledJobId) {
      const updatedScheduledReport = {
        status: this.schedulerFormGroup.get('enabled').value ? "Scheduled" : "Disabled",
        nextExecution: utcdate.getFullYear().toString() + '/' + (utcdate.getMonth()+1).toString() + '/'
          + utcdate.getDate().toString() + ' ' + utcdate.getHours().toString() + ':' + utcdate.getMinutes().toString() + ' +00:00',
        reportFrequencyType: this.schedulerFormGroup.get('frequency').value,
        scheduledForId: this.schedulerFormGroup.get('user').value,
        description: this.schedulerFormGroup.get('description').value,
        parameterJson: reportCriteria,
        textParameterJson: reportTextCriteria
      }
      this.scheduledJobWrapperService.apiV1ScheduledJobPatchIdPatch(this.executedScheduledReport?.scheduledJobId, compare({}, updatedScheduledReport)).subscribe(
        (x) => {
          this.notificationService.success('Scheduled report updated successfully.');
          if (this.executedScheduledReport) {
            this.cancelScheduleReport();
          }
        },
        (err) => {
          this.notificationService.error('Error updating scheduled report.');
        });
    }
    else
    {
      const scheduledReport: AddScheduledReportViewModel = {
        reportId: this.reportId,
        enabled: this.schedulerFormGroup.get('enabled').value,
        scheduledDateTime: utcdate.getFullYear().toString() + '/' + (utcdate.getMonth()+1).toString() + '/'
        + utcdate.getDate().toString() + ' ' + utcdate.getHours().toString() + ':' + utcdate.getMinutes().toString() + ' +00:00',
        reportFrequency: this.schedulerFormGroup.get('frequency').value,
        userId: this.schedulerFormGroup.get('user').value,
        description: this.schedulerFormGroup.get('description').value,
        parameterJson: reportCriteria,
        textParameterJson: reportTextCriteria
      }

      this.scheduledJobWrapperService.apiV1ScheduledJobAddReportPost(scheduledReport).subscribe(
        (x) => {
          this.notificationService.success('Report scheduled successfully.');
          this.isSchedulerMode = false;
        },
        (err) => {
          this.notificationService.error('Error adding scheduled report.');
        });
    }
  }

  frequencyChanged(freq) {
    let scheduledDate = this.schedulerFormGroup.get('scheduledDate').value;
    if (freq != '') {
      if (!scheduledDate) scheduledDate = new Date();
      this.frequency = freq;
      this.schedulerFormGroup.controls['scheduledDate'].setValue(this.calcStartDate(freq, scheduledDate));
    }
  }

  scheduledDateChanged(sd) {
    if (sd != '' && this.frequency) {
        this.schedulerFormGroup.controls['scheduledDate'].setValue(this.calcStartDate(this.frequency, sd));
    }
  }

  calcStartDate(frequency, baseDate) {
    if (frequency >= 1 && frequency <= 3 || frequency > 5) return baseDate;

    let currMonth = baseDate.getMonth() + 1;
    const currYear = baseDate.getFullYear();

    const ldm =
      currMonth == 1 || currMonth == 3 || currMonth == 5 || currMonth == 7 || currMonth == 8 || currMonth == 10 || currMonth == 12  ? 31
        : (currMonth == 2 ? (currYear % 4 == 0 ? 29 : 28) : 30)

    const lastDayOfMonth = new Date(currMonth.toString() + '/' + ldm.toString() + '/' + currYear.toString());
    if (frequency == 4) {
        return lastDayOfMonth
    }
    else if (frequency == 5) {
        if (lastDayOfMonth.getDay() == 0) return new Date(currMonth.toString() + '/' + (ldm-2).toString() + '/' + currYear.toString());
        else if (lastDayOfMonth.getDay() == 6) return new Date(currMonth.toString() + '/' + (ldm-1).toString() + '/' + currYear.toString());
        else return lastDayOfMonth;
    }
  }

  functionMenuClick() {
    this.menuOpen = !this.menuOpen;
  }
  delay(milliseconds){
    return new Promise(resolve => {
        setTimeout(resolve, milliseconds);
    });
  }

}
