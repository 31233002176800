import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ImplementationComponent } from './implementation.component';
import { ImplementationStartComponent } from './implementation-start/implementation-start.component';
import { NotImplementationGuard } from './guards/not-implementation.guard';
import { PracticeInformationComponent } from './practice-information/practice-information.component';
import { ProvidersComponent } from '../files/providers/providers.component';
import { LocationsComponent } from '../files/locations/locations.component';
import { ModifiersComponent } from '../files/modifiers/modifiers.component';
import { ProceduresComponent } from '../files/procedures/procedures.component';
import { DiagnosisCodesComponent } from '../files/diagnosis-codes/diagnosis-codes.component';
import { AdjustmentCodesComponent } from '../files/adjustment-codes/adjustment-codes.component';
import { PaymentCodesComponent } from '../files/payment-codes/payment-codes.component';
import { FinancialClassComponent } from '../files/financial-class/financial-class.component';
import { FeeSchedulesComponent } from '../files/fee-schedules/fee-schedules.component';
import { InsuranceCarriersComponent } from '../files/insurance-carriers/insurance-carriers.component';
import { EobCodesComponent } from '../files/eob-codes/eob-codes.component';
import { RemarkCodesComponent } from '../files/remark-codes/remark-codes.component';
import { UsersComponent } from '../settings/user-maintenance-and-security/users/users.component';
import { ScheduledJobsComponent } from '../settings/scheduled-jobs/scheduled-jobs.component';
import { ImplementationEndComponent } from './implementation-end/implementation-end.component';

const routes: Routes = [
  {
    path: '',
    component: ImplementationComponent,
    canActivate: [NotImplementationGuard],
    children: [
      {
        path: 'start',
        component: ImplementationStartComponent,
        canActivate: [NotImplementationGuard]
      },
      {
        path: 'practice-information',
        canActivate: [NotImplementationGuard],
        component: PracticeInformationComponent
      },
      {
        path: 'locations',
        component: LocationsComponent,
        canActivate: [NotImplementationGuard],
        data: { 'implementation' : true }
      },
      {
        path: 'providers',
        component: ProvidersComponent,
        canActivate: [NotImplementationGuard],
        data: { 'implementation' : true }
      },
      {
        path: 'modifiers',
        component: ModifiersComponent,
        canActivate: [NotImplementationGuard],
        data: { 'implementation' : true }
      },
      {
        path: 'procedures',
        component: ProceduresComponent,
        canActivate: [NotImplementationGuard],
        data: { 'implementation' : true }
      },
      {
        path: 'diagnoses',
        component: DiagnosisCodesComponent,
        canActivate: [NotImplementationGuard],
        data: { 'implementation' : true }
      },
      {
        path: 'adjustment-codes',
        component: AdjustmentCodesComponent,
        canActivate: [NotImplementationGuard],
        data: { 'implementation' : true }
      },
      {
        path: 'payment-codes',
        component: PaymentCodesComponent,
        canActivate: [NotImplementationGuard],
        data: { 'implementation' : true }
      },
      {
        path: 'financial-classes',
        component: FinancialClassComponent,
        canActivate: [NotImplementationGuard],
        data: { 'implementation' : true }
      },
      {
        path: 'fee-schedules',
        component: FeeSchedulesComponent,
        canActivate: [NotImplementationGuard],
        data: { 'implementation' : true }
      },
      {
        path: 'insurance-carriers',
        component: InsuranceCarriersComponent,
        canActivate: [NotImplementationGuard],
        data: { 'implementation' : true }
      },
      {
        path: 'eob-codes',
        component: EobCodesComponent,
        canActivate: [NotImplementationGuard],
        data: { 'implementation' : true }
      },
      {
        path: 'remark-codes',
        component: RemarkCodesComponent,
        canActivate: [NotImplementationGuard],
        data: { 'implementation' : true }
      },
      {
        path: 'users',
        component: UsersComponent,
        canActivate: [NotImplementationGuard],
        data: { 'implementation' : true }
      },
      {
        path: 'scheduled-jobs',
        component: ScheduledJobsComponent,
        canActivate: [NotImplementationGuard],
        data: { 'implementation' : true }
      },
      {
        path: 'finish',
        component: ImplementationEndComponent,
        canActivate: [NotImplementationGuard],
        data: { 'implementation' : true }
      }

    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ImplementationRoutingModule { 

}
