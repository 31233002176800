<div class="dialog-form assign-datasets-form">
  <div class="dialog-form__close">
    <a mat-dialog-close>
      <mat-icon>close</mat-icon>
    </a>
  </div>
  <header mat-dialog-title class="dialog-form__title">
    <h2>Assign Datasets</h2>
  </header>
  <main mat-dialog-content>
    <div class="dialog-form__container">
      <ul *ngFor="let dataset of dataSets$ | async as datasets; trackBy: trackById">
        <mat-checkbox
          labelPosition="after"
          [value]="dataset.id"
          (change)="updateSelection(dataset, $event, datasets.length)"
        >
          <span class="lib-form-field-checkbox__label">{{ dataset.name }}</span>
        </mat-checkbox>
      </ul>
    </div>
  </main>
  <div mat-dialog-actions class="dialog-form__actions">
    <button class="cancel-button" mat-stroked-button mat-dialog-close>Cancel</button>
    <lib-button-loading color="primary" class="save-button" [loading]="saving" (clickEvent)="save()"
      >Save
    </lib-button-loading>
  </div>
</div>
