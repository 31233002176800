import { Component, Input, TemplateRef } from '@angular/core';

// TODO: ONPUSH

@Component({
  selector: 'lib-detail-layout',
  templateUrl: './detail-layout.component.html',
  styleUrls: ['./detail-layout.component.scss']
})
export class DetailLayoutComponent {
  @Input() detailHeader: TemplateRef<any>;
  @Input() detailBreadcrumbs: TemplateRef<any>;
  @Input() detailMenu: TemplateRef<any>;
  @Input() detailContent: TemplateRef<any>;
}
