import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { SidebarNavConfig } from './navbar-config';
import { SessionStorageService } from '@core/services/session-storage.service';
import { ConfigurationWrapperService } from '@core/services/service-wrappers/configuration-wrapper.service';
import { Store } from '@ngrx/store';
import { AppState } from '@core/core.state';
import { Subscription } from 'rxjs';
import { UserDetailsViewModel } from 'data';
import { Router } from '@angular/router';

// TODO: On push
@Component({
  selector: 'lib-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, OnDestroy {
  @Input()
  mainNavConfig: SidebarNavConfig[];

  @Input()
  footerNavConfig: SidebarNavConfig[];

  @Input()
  userPicture: string;

  @Input() selectedDataSet;

  @Input() availableDataSets;
  @Input() implementationComplete: boolean = true;

  @Output() dataSetSelected: EventEmitter<string> = new EventEmitter<string>();
  @Output() logout: EventEmitter<void> = new EventEmitter<void>();
  @Output() passwordUpdate: EventEmitter<void> = new EventEmitter<void>();

  logoFile = "";
  default_logo_file = "peregrine-logo-white_vert.svg";
  username = "";
  username$: Subscription = null;
  user: UserDetailsViewModel;
  user$ : Subscription = null;

  constructor(
    private sessionStorageService: SessionStorageService,
    private configurationService: ConfigurationWrapperService,
    private store: Store<AppState>,
    private router: Router
  ) {
    const cl = this.sessionStorageService.getItem('custom-logo') ?? '';
    if (cl !== '') this.logoFile = cl;

    this.configurationService.apiV1ConfigurationFeatureFeaturenameGet("custom-logo").subscribe(f => {
      if (f.featureLevel > 0) {
        this.logoFile = f.featureValue;
      }
      else {
        this.logoFile = this.default_logo_file;
      }
    });
  }

  ngOnInit(): void {
      this.username$ = this.store.subscribe(s => this.username = s.user?.fullName ?? "Peregrine User");
      this.user$ = this.store.subscribe(s => this.user = s.user);
      if (this.user?.userPermissions === undefined) {
        window.location.reload();
      }
      this.sessionStorageService.setItem('user-info', this.user);
  }
  
  ngOnDestroy(): void {
    this.username$?.unsubscribe();
    this.user$?.unsubscribe();
  }
  resetPageIndex() {
    sessionStorage.setItem('pageIndex', '0')
  }

  logoutClick() {
    this.logout.emit();
  }

  updatePassword() {
    this.passwordUpdate.emit();
  }

  updateConfigs() {
    this.router.navigateByUrl(`/user-configurations`);
  }

  trackByIndex(index: number, item: any) {
    return index //no unique id or value
  }
}
