/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface AddRVUCodeViewModel {
  datasetId?: string;
  code?: string | null;
  name?: string | null;
  description?: string | null;
  modifierId?: string | null;
  effectiveFrom?: string;
  effectiveTo?: string;
  facility?: number;
  nonFacility?: number;
  work?: number;
  inactive?: boolean;
}
