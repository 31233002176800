/**
 * ImaginePay API
 * This API facilitates partner integration within the platform.
 *
 * The version of the OpenAPI document: 22_12_0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent,
  HttpParameterCodec,
  HttpContext
} from '@angular/common/http';
import { CustomHttpParameterCodec } from '../encoder';
import { Observable } from 'rxjs';

// @ts-ignore
import { ImaginePayApiModelsAuditHistoryAuditPaymentHistoryResponse } from '../model/imaginePayApiModelsAuditHistoryAuditPaymentHistoryResponse';
// @ts-ignore
import { ImaginePayApiModelsMessageResponse } from '../model/imaginePayApiModelsMessageResponse';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  protected basePath = 'https://devapi.imaginepay.com';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  public encoder: HttpParameterCodec;

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }

  // @ts-ignore
  private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
    if (typeof value === 'object' && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }

  private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
    if (value == null) {
      return httpParams;
    }

    if (typeof value === 'object') {
      if (Array.isArray(value)) {
        (value as any[]).forEach((elem) => (httpParams = this.addToHttpParamsRecursive(httpParams, elem, key)));
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(key, (value as Date).toISOString().substr(0, 10));
        } else {
          throw Error('key may not be null if value is Date');
        }
      } else {
        Object.keys(value).forEach(
          (k) => (httpParams = this.addToHttpParamsRecursive(httpParams, value[k], key != null ? `${key}.${k}` : k))
        );
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error('key may not be null if value is not object or array');
    }
    return httpParams;
  }

  /**
   * Get payment history
   * @param authorization access token
   * @param requestOnlinePaymentRequestID
   * @param requestOnlineTransactionID
   * @param requestOnlineAccountNumber
   * @param requestTransactionReferenceNumber
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public searchAuditPaymentHistory(
    authorization: string,
    requestOnlinePaymentRequestID?: number,
    requestOnlineTransactionID?: string,
    requestOnlineAccountNumber?: string,
    requestTransactionReferenceNumber?: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json' | 'text/json' | 'application/xml' | 'text/xml';
      context?: HttpContext;
    }
  ): Observable<ImaginePayApiModelsAuditHistoryAuditPaymentHistoryResponse>;
  public searchAuditPaymentHistory(
    authorization: string,
    requestOnlinePaymentRequestID?: number,
    requestOnlineTransactionID?: string,
    requestOnlineAccountNumber?: string,
    requestTransactionReferenceNumber?: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json' | 'text/json' | 'application/xml' | 'text/xml';
      context?: HttpContext;
    }
  ): Observable<HttpResponse<ImaginePayApiModelsAuditHistoryAuditPaymentHistoryResponse>>;
  public searchAuditPaymentHistory(
    authorization: string,
    requestOnlinePaymentRequestID?: number,
    requestOnlineTransactionID?: string,
    requestOnlineAccountNumber?: string,
    requestTransactionReferenceNumber?: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json' | 'text/json' | 'application/xml' | 'text/xml';
      context?: HttpContext;
    }
  ): Observable<HttpEvent<ImaginePayApiModelsAuditHistoryAuditPaymentHistoryResponse>>;
  public searchAuditPaymentHistory(
    authorization: string,
    requestOnlinePaymentRequestID?: number,
    requestOnlineTransactionID?: string,
    requestOnlineAccountNumber?: string,
    requestTransactionReferenceNumber?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: 'application/json' | 'text/json' | 'application/xml' | 'text/xml';
      context?: HttpContext;
    }
  ): Observable<any> {
    if (authorization === null || authorization === undefined) {
      throw new Error('Required parameter authorization was null or undefined when calling searchAuditPaymentHistory.');
    }

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (requestOnlinePaymentRequestID !== undefined && requestOnlinePaymentRequestID !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>requestOnlinePaymentRequestID,
        'request.onlinePaymentRequestID'
      );
    }
    if (requestOnlineTransactionID !== undefined && requestOnlineTransactionID !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>requestOnlineTransactionID,
        'request.onlineTransactionID'
      );
    }
    if (requestOnlineAccountNumber !== undefined && requestOnlineAccountNumber !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>requestOnlineAccountNumber,
        'request.onlineAccountNumber'
      );
    }
    if (requestTransactionReferenceNumber !== undefined && requestTransactionReferenceNumber !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>requestTransactionReferenceNumber,
        'request.transactionReferenceNumber'
      );
    }

    let localVarHeaders = this.defaultHeaders;
    if (authorization !== undefined && authorization !== null) {
      localVarHeaders = localVarHeaders.set('Authorization', String(authorization));
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json', 'text/json', 'application/xml', 'text/xml'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    return this.httpClient.get<ImaginePayApiModelsAuditHistoryAuditPaymentHistoryResponse>(
      `${this.configuration.basePath}/search/paymenthistory`,
      {
        context: localVarHttpContext,
        params: localVarQueryParameters,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }
}
