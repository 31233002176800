<article class="lib-card">
  <ng-content select="[name=chips]"></ng-content>
  <div class="lib-card__title" *ngIf="!hideTitle">
    <h4>
      <ng-content select="[name=title]"></ng-content>
    </h4>
    <ng-content select="[name=header]"></ng-content>
  </div>
  <ng-content></ng-content>
</article>
