<div class="dialog-form report-form">
  <div class="dialog-form__close">
    <a (click)="cancel()">
      <mat-icon>close</mat-icon>
    </a>
  </div>
  <header mat-dialog-title class="dialog-form__title">
    <h2>Fee Schedule</h2>
  </header>
  <main mat-dialog-content>
    <div class="dialog-form__container">
      <ng-container *ngIf="formInitialized">
        <lib-dynamic-form id="form" [dynamicFormGroup]="formGroup" [formGroupDefinitions]="formDefinitions">
        </lib-dynamic-form>
      </ng-container>
      <mat-error class="error" *ngIf="duplicateName">Fee Schedule name must be unique.</mat-error>
      <div mat-dialog-actions class="buttons" *ngIf="!allowAdd">
        <button class="cancel-button" mat-stroked-button mat-dialog-close>Cancel</button>
        <lib-button-loading
          color="primary"
          class="save-button"
          [loading]="saving"
          [invalid]="formGroup.invalid || this.duplicateName"
          (clickEvent)="save(false)"
        >
          Create
        </lib-button-loading>
      </div>
      <div class="actions">
        <div *ngIf="!viewOnly && allowAdd" class="copy-div">
          <button
            mat-button
            style="color: #18a0fb"
            [ngClass]="commit === true ? 'disabled' : 'populate'"
            [disabled]="commit"
            (click)="populateFees()"
          >
            <mat-icon>add_circle_outline</mat-icon>
            Copy From Existing
          </button>
        </div>
        <button
          class="add-div"
          (click)="addEntry()"
          mat-button
          color="primary"
          [disabled]="!allowAdd"
          *ngIf="!viewOnly"
        >
          <mat-icon>add_circle_outline</mat-icon> Add Fee Entry
        </button>
        <button
          [disabled]="!allowAdd"
          class="upload-div"
          mat-button
          color="primary"
          (click)="uploadFeeSchedule()"
          *ngIf="!viewOnly"
        >
          <mat-icon>file_upload</mat-icon> Upload Fee Schedule
        </button>
      </div>

      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>
        <!--- Note that these columns can be defined in any order.
    The actual rendered columns are set as a property on the row definition" -->
        <!-- Position Column -->
        <!-- Name Column -->
        <ng-container matColumnDef="procedure">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Procedure</th>
          <td mat-cell *matCellDef="let element">{{ element.procedureDisplay }}</td>
        </ng-container>

        <!-- Weight Column -->
        <ng-container matColumnDef="modifier">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Modifier</th>
          <td mat-cell *matCellDef="let element">{{ element.modifierDisplay }}</td>
        </ng-container>
        <!-- Symbol Column -->
        <ng-container matColumnDef="amount">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Amount</th>
          <td mat-cell *matCellDef="let element">{{ element.amount | currency }}</td>
        </ng-container>
        <ng-container matColumnDef="effectiveFrom">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Effective From</th>
          <td mat-cell *matCellDef="let element">{{ element.effectiveFrom | date : 'MM/dd/yyyy' }}</td>
        </ng-container>
        <ng-container matColumnDef="effectiveTo">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Effective To</th>
          <td mat-cell *matCellDef="let element">{{ element.effectiveTo | date : 'MM/dd/yyyy' }}</td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>Actions</th>
          <td mat-cell *matCellDef="let row; let idx = index">
            <button mat-button [matMenuTriggerFor]="menu"><mat-icon>more_vert</mat-icon></button>
            <mat-menu #menu="matMenu">
              <button (click)="editFee(row, true)" mat-menu-item *ngIf="viewOnly" id="view-{{ idx }}">View</button>
              <button (click)="editFee(row, false)" mat-menu-item *ngIf="!viewOnly" id="edit-{{ idx }}">Edit</button>
              <button mat-menu-item (click)="delete(row)" *ngIf="!viewOnly" id="delete-{{ idx }}">Delete</button>
            </mat-menu>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
      <mat-paginator #paginator [pageSize]="25" [pageSizeOptions]="[25, 50, 100, 250, 500]"> </mat-paginator>
    </div>
  </main>
  <div mat-dialog-actions class="buttons" *ngIf="allowAdd">
    <button mat-stroked-button mat-dialog-close *ngIf="viewOnly">Close</button>
    <button class="cancel-edit-button" (click)="cancel()" mat-stroked-button *ngIf="!viewOnly">Cancel</button>
    <lib-button-loading
      *ngIf="!viewOnly"
      color="primary"
      class="save-button"
      [loading]="saving"
      [invalid]="formGroup.invalid || duplicateName === true"
      (clickEvent)="save(true)"
      >Save
    </lib-button-loading>
  </div>
</div>
