import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { State } from './implementation.state';
import { map, switchMap } from 'rxjs/operators';
import * as ImplementationActions from './implementation.actions';
import { ImplementationWrapperService } from '@core/services/service-wrappers/implementation-wrapper.service';

@Injectable()
export class ImplementationEffects {
  constructor(private actions$: Actions, private store: Store<State>, private service: ImplementationWrapperService) {}
}
