// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { getBaseUrl, getImaginePayConfig } from '../app/core/utilities/runtime.utils';

declare const require: any;

export const environment = {
  production: false,
  configEndpoint: 'https://peregrine-config-func.azurewebsites.net/api/GetConfig',
  configEndpointFunctionKey: 'UcJzJs-oxODMDEbf-B6BBLqmdW84PzA35G7bkLzZlY8CAzFuQi7kHA==',
  versions: {
    app: require('../../package.json').version
  },
  stateConfig: {
    storagePrefix: 'VNTG-'
  },
  apiConfig: {
    baseUrl: getBaseUrl(),
    // Temporary Key until Key vault is configurated
    apiKey: 'Vr4#RHRRyKQ%CDXqTF4G',
    scopes: [""],
    uri: "",
  },
  imaginePayConfig: {
    ...getImaginePayConfig()
  },
  msalConfig: {
    auth: {
        clientId: "",
    }
  },
  b2cPolicies: {
    names: {
        signIn: "",
        signUpSignIn: "",
        resetPassword: "",
        editProfile: ""
    },
    authorities: {
        signIn: {
            authority: ""
        },
        signUpSignIn: {
            authority: ""
        },
        resetPassword: {
            authority: ""
        },
        editProfile: {
            authority: ""
        }
    },
    authorityDomain: ""
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
