import { Injector, LOCALE_ID } from '@angular/core';
import { Validators } from '@angular/forms';
import { ColumnSizes, FieldSelectOption, FilterType } from 'components';
import { LocationsLookupService } from '../../../../core/services/lookup';
import { Report } from '../../models/report';
import { BusinessManagementSummaryReportService } from './business-management-summary-report.service';

export const BusinessManagementSummaryReport: Report = {
  id: 'business-management-summary-report',
  label: 'Business Management Summary Report',
  textCriteria: { 'Report Date Type': '', 'Report Date': '', 'Locations': '' },
  cleanFilterParams: {
    'locationIds': ''
  },
  getReportTitle: () => {
    return BusinessManagementSummaryReport.label + ' - ' + BusinessManagementSummaryReport.textCriteria['Report Date']
  },
  getSearchCriteria: (injector: Injector) => [
    {
      title: '',
      controls: [
        {
          label: 'Date Type',
          name: 'reportDateType',
          type: 'select',
          class: 'form-span-6',
          options: [
            {
              label: 'Service Date',
              value: 'S'
            },
            {
              label: 'Posting Date',
              value: 'P'
            }
          ],
          validators: Validators.required,
          selectionChanged: (event) => {
            BusinessManagementSummaryReport.textCriteria['Report Date Type'] = event.source.triggerValue;
          }
        },
        {
          label: 'Date',
          name: 'reportDate',
          class: 'form-span-6',
          type: 'date',
          validators: Validators.required,
          datepickerChanged: (event) => {
            BusinessManagementSummaryReport.textCriteria['Report Date'] = event.value.toLocaleDateString();
          }
        },
        {
          matLabel: 'Location',
          name: 'locationIds',
          apiService: injector.get(LocationsLookupService),
          type: 'multiSelect',
          class: 'form-span-12 multi-input',
          placeholder: 'Location',
          selectOptions: (event) => {
            let listValue = []
            let listText = []
            event.forEach((x: FieldSelectOption) => {

              listValue.push(x.value)
              listText.push(x.label)
            })
            BusinessManagementSummaryReport.textCriteria['Locations'] = listText.join(',');
            BusinessManagementSummaryReport.cleanFilterParams['locationIds'] = listValue
          }
        }
      ]
    }
  ],
  getGridConfiguration: (injector: Injector) => {
    const locale = injector.get(LOCALE_ID);
    const moneyFormat = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });
    const flexibleDecimalFormat = (x: any) => { if (!x || x.trim() === '') return ''; else if (!isNaN(x)) return parseFloat(x).toFixed(2); else return x.trim(); };
    return {
      service: injector.get<any>(BusinessManagementSummaryReportService),
      columnDefinitions: [
        {
          id: 'Accounts Receivable',
          displayKey: 'Accounts Receivable',
          headerText: 'Accounts Receivable',
          displayOrder: 0,
          columnSize: ColumnSizes.normal,
        },
        {
          id: 'Dynamic Column 1',
          displayKey: 'Dynamic Column 1',
          headerText: 'Dynamic Column 1',
          dynamic: true,
          displayOrder: 1,
          columnSize: ColumnSizes.normal
        },
        {
          id: 'Dynamic Column 2',
          displayKey: 'Dynamic Column 2',
          headerText: 'Dynamic Column 2',
          dynamic: true,
          displayOrder: 2,
          columnSize: ColumnSizes.normal
        },
        {
          id: 'Dynamic Column 3',
          displayKey: 'Dynamic Column 3',
          headerText: 'Dynamic Column 3',
          dynamic: true,
          displayOrder: 3,
          columnSize: ColumnSizes.normal
        },
        {
          id: 'Dynamic Column 4',
          displayKey: 'Dynamic Column 4',
          headerText: 'Dynamic Column 4',
          dynamic: true,
          displayOrder: 4,
          columnSize: ColumnSizes.normal
        },
        {
          id: 'Dynamic Column 5',
          displayKey: 'Dynamic Column 5',
          headerText: 'Dynamic Column 5',
          dynamic: true,
          displayOrder: 5,
          columnSize: ColumnSizes.normal
        },
        {
          id: 'Dynamic Column 6',
          displayKey: 'Dynamic Column 6',
          headerText: 'Dynamic Column 6',
          dynamic: true,
          displayOrder: 6,
          columnSize: ColumnSizes.normal
        },
        {
          id: 'Dynamic Column 7',
          displayKey: 'Dynamic Column 7',
          headerText: 'Dynamic Column 7',
          dynamic: true,
          displayOrder: 7,
          columnSize: ColumnSizes.normal
        },
        {
          id: 'Dynamic Column 8',
          displayKey: 'Dynamic Column 8',
          headerText: 'Dynamic Column 8',
          dynamic: true,
          displayOrder: 8,
          columnSize: ColumnSizes.normal
        },
        {
          id: 'Dynamic Column 9',
          displayKey: 'Dynamic Column 9',
          headerText: 'Dynamic Column 9',
          dynamic: true,
          displayOrder: 9,
          columnSize: ColumnSizes.normal
        },
        {
          id: 'Dynamic Column 10',
          displayKey: 'Dynamic Column 10',
          headerText: 'Dynamic Column 10',
          dynamic: true,
          displayOrder: 10,
          columnSize: ColumnSizes.normal
        },
        {
          id: 'Dynamic Column 11',
          displayKey: 'Dynamic Column 11',
          headerText: 'Dynamic Column 11',
          dynamic: true,
          displayOrder: 11,
          columnSize: ColumnSizes.normal
        },
        {
          id: 'Dynamic Column 12',
          displayKey: 'Dynamic Column 12',
          headerText: 'Dynamic Column 12',
          dynamic: true,
          displayOrder: 12,
          columnSize: ColumnSizes.normal
        },
        {
          id: 'Dynamic Column 13',
          displayKey: 'Dynamic Column 13',
          headerText: 'Dynamic Column 13',
          dynamic: true,
          displayOrder: 13,
          columnSize: ColumnSizes.normal
        },
        {
          id: 'Dynamic Column 14',
          displayKey: 'Dynamic Column 14',
          headerText: 'Dynamic Column 14',
          dynamic: true,
          displayOrder: 14,
          columnSize: ColumnSizes.normal
        },
        {
          id: 'Dynamic Column 15',
          displayKey: 'Dynamic Column 15',
          headerText: 'Dynamic Column 15',
          dynamic: true,
          displayOrder: 15,
          columnSize: ColumnSizes.normal
        },
        {
          id: 'Dynamic Column 16',
          displayKey: 'Dynamic Column 16',
          headerText: 'Dynamic Column 16',
          dynamic: true,
          displayOrder: 16,
          columnSize: ColumnSizes.normal
        }
      ]
    };
  }
};
