import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';

export const enum HttpMethod {
  Get = 'GET',
  Post = 'POST',
  Put = 'PUT',
  Patch = 'PATCH',
  Delete = 'DELETE',
  Head = 'HEAD'
}

export type ApiRouteDefinition = HttpInterceptorRouteConfig | string;

export function isHttpInterceptorRouteConfig(def: ApiRouteDefinition): def is HttpInterceptorRouteConfig {
  return typeof def !== 'string';
}

export interface HttpInterceptorConfig {
  allowedList: ApiRouteDefinition[];
}

export interface HttpInterceptorRouteConfig {
  uri?: string;

  uriMatcher?: (uri: string) => boolean;

  //  tokenOptions?: GetTokenSilentlyOptions;

  httpMethod?: HttpMethod | string;

  allowAnonymous?: boolean;
}

export interface AuthConfig {
  authTokenUrl: string;
  authClientId: string;
  authUserName: string;
  authPassword: string;
  authGrantType: string;

  /// Don't know about below
  // domain: string;

  // issuer?: string;

  // redirectUri?: string;

  // skipRedirectCallback?: boolean;

  // leeway?: number;

  //cacheLocation?: CacheLocation;

  //cache?: ICache;

  // useRefreshTokens?: boolean;

  // authorizeTimeoutInSeconds?: number;

  // advancedOptions?: {
  //   defaultScope?: string;
  // };

  // maxAge?: string | number;

  // scope?: string;

  // audience?: string;

  httpInterceptor?: HttpInterceptorConfig;

  // errorPath?: string;

  // organization?: string;

  // invitation?: string;

  // [key: string]: any;
}

@Injectable({ providedIn: 'root' })
export class AuthClientConfig {
  private config?: AuthConfig;

  constructor(@Optional() @Inject(AuthConfigService) config?: AuthConfig) {
    if (config) {
      this.set(config);
    }
  }

  /**
   * Sets configuration to be read by other consumers of the service (see usage notes)
   * @param config The configuration to set
   */
  set(config: AuthConfig): void {
    this.config = config;
  }

  /**
   * Gets the config that has been set by other consumers of the service
   */
  get(): AuthConfig {
    return this.config as AuthConfig;
  }
}

export const AuthConfigService = new InjectionToken<AuthConfig>('imagine-auth-angular.config');


