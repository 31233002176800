import { Injectable } from '@angular/core';
import { RVUCodeService, RVUCodeViewModel, RVUCodeViewModelPagedModelResponse, PagedModelRequest } from 'data';
import { Observable } from 'rxjs';
import { BaseWrapperService } from './base-wrapper.service';
import { SessionStorageService } from '../session-storage.service';

@Injectable({
  providedIn: 'root'
})
export class RvuWrapperService extends BaseWrapperService {
  constructor(private service: RVUCodeService, protected sessionStorageService: SessionStorageService) {
    super();
  }

  apiV1RvucodeAllPost(pagedModelRequest?: PagedModelRequest): Observable<RVUCodeViewModelPagedModelResponse> {
    return this.service.apiV1RvucodeAllPost(this.getDataSetId(), pagedModelRequest);
  }

  apiV1RvucodeIdGet(id: string): Observable<RVUCodeViewModel> {
    return this.service.apiV1RvucodeIdGet(id, this.getDataSetId());
  }

  apiV1RvucodeAddPost(requestBody): Observable<RVUCodeViewModel> {
    return this.service.apiV1RvucodeAddPost(this.getDataSetId(), requestBody);
  }

  apiV1RvucodePatchIdPatch(id: string, operation): Observable<RVUCodeViewModel> {
    return this.service.apiV1RvucodePatchIdPatch(id, this.getDataSetId(), operation);
  }

  apiV1RvucodeIdDelete(id: string): Observable<RVUCodeViewModel> {
    return this.service.apiV1RvucodeIdDelete(id, this.getDataSetId());
  }
}
