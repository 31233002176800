<div>
  <header mat-dialog-title class="dialog-form__title"></header>
  <main>
    <div class="report-form__container">
      <div class="report-form__form {{ report?.searchFormClass }}">
        <div class="report-form__header">
          <h2>{{ reportName === 'Archive Accounts' ? reportName : report?.label }}</h2>
          <h5 *ngIf="report?.subText">{{ report.subText }}</h5>
        </div>
        <ng-container *ngIf="formInitialized">
          <lib-dynamic-form [dynamicFormGroup]="formGroup" [formGroupDefinitions]="reportCriteriaForm">
          </lib-dynamic-form>
        </ng-container>
        <div class="report-form__actions" *ngIf="!isSchedulerMode">
          <button
            class="mat-button--large"
            mat-stroked-button
            id="cancelReportButton"
            (click)="cancel()"
            style="max-height: 44px"
          >
            Cancel
          </button>
          <div style="display: flex; flex-direction: column">
            <div>
              <button
                class="mat-button--large"
                mat-flat-button
                color="primary"
                [disabled]="formGroup?.invalid"
                id="generateReportButton"
                (click)="generateReport()"
                *ngIf="!isResultsMode"
              >
                Run Report
              </button>
              <button
                [disabled]="formGroup?.invalid"
                id="menuButton"
                *ngIf="!isResultsMode"
                mat-flat-button
                color="primary"
                class="report-form__menu-button"
                (click)="functionMenuClick()"
              >
                <mat-icon *ngIf="!menuOpen">arrow_drop_down</mat-icon>
                <mat-icon *ngIf="menuOpen">arrow_drop_up</mat-icon>
              </button>
            </div>
            <div>
              <button
                mat-stroked-button
                class="report-form__schedule-menu-button"
                [disabled]="formGroup?.invalid"
                id="scheduleReportButton"
                *ngIf="!isResultsMode && menuOpen"
                (click)="setupScheduleReport()"
              >
                Schedule Report
              </button>
            </div>
          </div>
          <button
            class="mat-button--large"
            mat-flat-button
            color="primary"
            [disabled]="formGroup?.invalid"
            (click)="updateReport()"
            id="updateReportButton"
            *ngIf="isResultsMode"
          >
            Update Report
          </button>
        </div>
      </div>
      <div *ngIf="isSchedulerMode" class="report-form__form report-form__scheduler-form">
        <div class="report-form__header">
          <h3>Schedule {{ reportName === 'Archive Accounts' ? reportName : ' Report' }}</h3>
        </div>
        <ng-container>
          <lib-dynamic-form [dynamicFormGroup]="schedulerFormGroup" [formGroupDefinitions]="schedulerForm">
          </lib-dynamic-form>
        </ng-container>
        <div class="report-form__actions">
          <button
            class="mat-button--large"
            mat-stroked-button
            id="cancelScheduleReportButton"
            (click)="cancelScheduleReport()"
            style="max-height: 44px"
          >
            Cancel
          </button>
          <button
            class="mat-button--large"
            id="scheduleButton"
            mat-flat-button
            color="primary"
            [disabled]="formGroup.invalid"
            (click)="scheduleReport()"
          >
            Schedule
          </button>
        </div>
      </div>
    </div>
  </main>
</div>
