import { Injector, LOCALE_ID } from '@angular/core';
import { Validators } from '@angular/forms';
import { BodyClasses, ColumnSizes, FieldSelectOption, FilterType } from 'components';
import { DateDisplayPipe } from 'projects/components/src/lib/shared/pipes/date-display/date-display.pipe';
import { Report } from '../../models/report';
import { LocationsLookupService, AttendingPhysiciansLookupService } from '../../../../core/services/lookup';
import { DailyActivitySummaryService } from './daily-activity-summary.service';

export const DailyActivitySummary: Report = {
  id: 'daily-activity-summary',
  label: 'Daily Activity Summary',
  textCriteria: {
    'Start Date': '', 'End Date': '', 'Locations': '', 'Attending Provider': '', 'Report Format': ''
  },
  cleanFilterParams: {
    'locationIds': '', 'attendingProviderIds': ''
  },
  getReportTitle: () => {
    return DailyActivitySummary.label + ' ' + DailyActivitySummary.textCriteria['Start Date'] + ' - ' + DailyActivitySummary.textCriteria['End Date']
      + ' (' + DailyActivitySummary.textCriteria['Report Format'] + ')';
  },
  getSearchCriteria: (injector: Injector) => [
    {
      title: '',
      controls: [
        {
          label: 'Posting Date Range',
          name: 'dateRange',
          type: 'dateRange',
          class: 'form-span-12',
          daterangeStartChanged: (event) => {
            DailyActivitySummary.textCriteria['Start Date'] = event.value.toLocaleDateString();
          },
          daterangeEndChanged: (event) => {
            DailyActivitySummary.textCriteria['End Date'] = event.value.toLocaleDateString();
          }
        },
        {
          label: '',
          name: 'dateRangeStart',
          type: 'noInput',
          class: 'display-none',
          validators: Validators.required
        },
        {
          label: '',
          name: 'dateRangeEnd',
          type: 'noInput',
          class: 'display-none',
          validators: Validators.required
        },
        {
          label: 'Report Format',
          name: 'reportFormat',
          class: 'form-span-12',
          type: 'select',
          options: [
            {
              label: 'Sorted by date showing activity by provider',
              value: '1'
            },
            {
              label: 'Sorted by month showing activity by provider',
              value: '2'
            },
            {
              label: 'Sorted by location of service',
              value: '3'
            }
          ],
          initial: '3',
          validators: Validators.required,
          selectionChanged: (event) => {
            DailyActivitySummary.textCriteria['Report Format'] = event.source.triggerValue;
          }
        },
        {
          matLabel: 'Location',
          name: 'locationIds',
          apiService: injector.get(LocationsLookupService),
          type: 'multiSelect',
          class: 'form-span-12 multi-input',
          placeholder: 'Location',
          selectOptions: (event) => {
            let listValue = []
            let listText = []
            event.forEach((x: FieldSelectOption) => {

              listValue.push(x.value)
              listText.push(x.label)
            })
            DailyActivitySummary.textCriteria['Locations'] = listText.join(',');
            DailyActivitySummary.cleanFilterParams['locationIds'] = listValue
          }
        },
        {
          matLabel: 'Attending Provider',
          name: 'attendingProviderIds',
          apiService: injector.get(AttendingPhysiciansLookupService),
          type: 'multiSelect',
          class: 'form-span-12 multi-input',
          placeholder: 'Attending Provider',
          selectOptions: (event) => {
            let listValue = []
            let listText = []
            event.forEach((x: FieldSelectOption) => {
              listValue.push(x.value)
              listText.push(x.label)
            })
            DailyActivitySummary.textCriteria['Attending Providers'] = listText.join(',');
            DailyActivitySummary.cleanFilterParams['attendingProviderIds'] = listValue

          }
        },
      ]
    }
  ],
  getGridConfiguration: (injector: Injector) => {
    const locale = injector.get(LOCALE_ID);
    const datePipe: DateDisplayPipe = new DateDisplayPipe(locale);
    const moneyFormat = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });
    return {
      service: injector.get<any>(DailyActivitySummaryService),
      columnDefinitions: [
        {
          id: 'Dynamic 0',
          displayKey: 'Dynamic 0',
          headerText: 'Dynamic 0',
          displayOrder: 0,
          dynamic: true,
          columnSize: ColumnSizes.normal,
          filterable: true,
          sortable: true
        },
        {
          id: 'Dynamic 1',
          displayKey: 'Dynamic 1',
          headerText: 'Dynamic 1',
          displayOrder: 1,
          dynamic: true,
          columnSize: ColumnSizes.normal,
          filterable: true,
          sortable: true
        },
        {
          id: 'Dynamic 2',
          displayKey: 'Dynamic 2',
          headerText: 'Dynamic 2',
          displayOrder: 2,
          dynamic: true,
          columnSize: ColumnSizes.normal,
          filterable: true,
          sortable: true
        },
        {
          id: 'Dynamic 3',
          displayKey: 'Dynamic 3',
          headerText: 'Dynamic 3',
          displayOrder: 3,
          dynamic: true,
          columnSize: ColumnSizes.normal,
          filterable: true,
          sortable: true
        },
        {
          id: 'Dynamic 4',
          displayKey: 'Dynamic 4',
          headerText: 'Dynamic 4',
          displayOrder: 4,
          dynamic: true,
          columnSize: ColumnSizes.narrow,
          filterable: true,
          sortable: true
        },
        {
          id: 'Dynamic 5',
          displayKey: 'Dynamic 5',
          headerText: 'Dynamic 5',
          displayOrder: 5,
          dynamic: true,
          columnSize: ColumnSizes.narrow,
          bodyClasses: [BodyClasses.alignRight],
          filterable: true,
          sortable: true
        },
        {
          id: 'Dynamic 6',
          displayKey: 'Dynamic 6',
          headerText: 'Dynamic 6',
          displayOrder: 6,
          dynamic: true,
          columnSize: ColumnSizes.narrow,
          filterable: true,
          sortable: true
        },
        {
          id: 'Dynamic 7',
          displayKey: 'Dynamic 7',
          headerText: 'Dynamic 7',
          displayOrder: 7,
          dynamic: true,
          columnSize: ColumnSizes.narrow,
          filterable: true,
          sortable: true
        }
      ]
    };
  }
};

