/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface CustomQueueResultsViewModel {
  customQueueHoldId?: string;
  recordId?: string;
  visitId?: string;
  patientId?: string;
  accountNumber?: string | null;
  name?: string | null;
  visitNumber?: number;
  serviceDate?: string | null;
  balance?: number | null;
  procedureCount?: number | null;
  insuranceCarrier?: string | null;
  location?: string | null;
  attendingProvider?: string | null;
  referringProvider?: string | null;
  procedure?: string | null;
  placeOfServiceCode?: string | null;
  financialClass?: string | null;
  diagnosisCode?: string | null;
  ruleDetail?: string | null;
  item?: string | null;
}
