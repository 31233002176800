<article class="procedure-details">
  <lib-card class="procedure-card">
    <ng-container name="title">
      <header class="procedure-details__header">
        Procedure {{ procedureNumber }}
        <button
          *ngIf="procedure?.isReversed === false && procedure?.createdBy !== 'Reversal' && !viewOnly"
          mat-button
          [matMenuTriggerFor]="menu"
        >
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="reverseProcedure.emit(procedure.id)">Reverse</button>
        </mat-menu>
        <span
          *ngIf="procedure?.isReversed === true || procedure?.createdBy === 'Reversal' || viewOnly"
          class="empty-menu-space"
        ></span>
        <span class="procedure-notes" [ngClass]="noteColor">{{ procedureNotes }}</span>
      </header>
    </ng-container>
    <div class="procedure-tabs">
      <div [ngClass]="procedureTabActive ? 'active-tab' : 'inactive-tab'" (click)="toggleActiveTab('procedure')">
        {{ procedure?.procedure?.code }}
      </div>
      <div [ngClass]="!procedureTabActive ? 'active-tab' : 'inactive-tab'" (click)="toggleActiveTab('payment')">
        Payment Activity
      </div>
    </div>
    <dl *ngIf="procedure && procedureTabActive" class="data-list">
      <dt>Procedure</dt>
      <dd>{{ procedure?.procedure?.code }}: {{ procedure?.procedure?.description }}</dd>
      <dt>Modifiers</dt>
      <dd>
        {{ procedure?.modifier1?.code }} {{ procedure.modifier2?.code }} {{ procedure.modifier3?.code }}
        {{ procedure?.modifier4?.code }}
      </dd>
      <dt>Diagnosis Codes</dt>
      <dd>
        {{ procedure | diagnosisDescription : diagnoses }}
      </dd>
      <dt>Service Date</dt>
      <dd>
        {{ procedure.serviceFromDate | dateDisplay }}
      </dd>
      <dt>Attending Provider</dt>
      <dd>
        {{ procedure.attendingProvider | physicianDisplay }}
      </dd>

      <dt>Location</dt>
      <dd>
        {{ procedure.location.name }}
      </dd>
      <dt>Amount</dt>
      <dd>
        <span class="text-bold">
          {{ procedure.procedureAmount | currency }}
        </span>
      </dd>
      <dt>Balance</dt>
      <dd>
        {{ procedure.balance | currency }}
      </dd>
      <dt></dt>
      <dd>
        <button class="view-more" mat-button color="primary" (click)="showFields(true)" *ngIf="!showAdditionalFields">
          View More
        </button>
        <button class="view-more" mat-button color="primary" (click)="showFields(false)" *ngIf="showAdditionalFields">
          View Less
        </button>
      </dd>
    </dl>
    <dl *ngIf="procedure && showAdditionalFields && procedureTabActive" class="data-list">
      <dt>Referring Provider</dt>
      <dd>
        {{ procedure?.referringProvider | physicianDisplay }}
      </dd>
      <dt>Supervising Provider</dt>
      <dd>
        {{ procedure?.supervisingProvider | physicianDisplay }}
      </dd>
      <dt>Ordering Provider</dt>
      <dd>
        {{ procedure?.orderingProvider | physicianDisplay }}
      </dd>
      <dt>Primary Provider</dt>
      <dd>
        {{ procedure?.primaryProvider | physicianDisplay }}
      </dd>
      <dt>Place of Service Code</dt>
      <dd>
        {{ procedure?.placeOfServiceCode?.code || '--' }}
      </dd>
      <dt>Quantity</dt>
      <dd>
        {{ procedure?.quantity }}
      </dd>
      <dt>Hold Insurance Billing</dt>
      <dd>
        {{ procedure?.holdInsuranceBilling | trueFalseDisplay }}
      </dd>
      <dt>Hold Patient Billing</dt>
      <dd>
        {{ procedure?.holdPatientBilling | trueFalseDisplay }}
      </dd>
      <dt>Claim Comment</dt>
      <dd>
        {{ procedure?.claimComment || '--' }}
      </dd>
      <dt>Accession #</dt>
      <dd>
        {{ procedure?.accessionNumber || '--' }}
      </dd>
      <dt>Authorization#</dt>
      <dd>
        {{ procedure?.insuranceAuthNumber || '--' }}
      </dd>
      <dt>Post Date</dt>
      <dd>
        {{ procedure?.postDate | dateDisplay }}
      </dd>
      <dt>Charge Group</dt>
      <dd>
        {{ procedure?.chargeGroup || '--' }}
      </dd>
      <dt>NDC</dt>
      <dd>
        {{
          procedure?.nationalDrugCode
            ? formatDrugCode(procedure?.nationalDrugCode?.ndcCode) +
              ', ' +
              procedure?.nationalDrugCode?.ndcQuantity.toFixed(2) +
              ' ' +
              procedure?.nationalDrugCode?.ndcUnit
            : '--'
        }}
      </dd>
    </dl>
    <div *ngIf="procedure && !procedureTabActive && procedure?.arTransactionDetails.length > 0">
      <ng-container>
        <dl class="data-list">
          <dt>Post Date</dt>
          <dd>
            {{ currentPayment?.arTransaction?.postingDate | dateDisplay }}
          </dd>
          <dt>Payment Source</dt>
          <dd>
            {{ getPaymentSource() || '--' }}
          </dd>
          <dt>Batch</dt>
          <dd
            (click)="viewBatch(currentPayment?.arTransaction?.batch)"
            [ngClass]="transactionsAuth !== 'None' ? 'link' : ''"
          >
            {{ currentPayment?.arTransaction?.batch?.batchNumber || '--' }}
          </dd>
          <dt>ICN</dt>
          <dd>
            {{ currentPayment?.arTransaction?.internalControlNumber || '--' }}
          </dd>
          <dt>Check #</dt>
          <dd>
            {{ currentPayment?.arTransaction?.checkNumber || '--' }}
          </dd>
          <dt>Payment Code</dt>
          <dd [title]="currentPayment?.arTransaction?.paymentCode?.name">
            {{ currentPayment?.arTransaction?.paymentCode?.code || '--' }}
          </dd>
          <dt>Adjustment Code</dt>
          <dd [title]="currentPayment?.arTransaction?.adjustmentCode?.name">
            {{ currentPayment?.arTransaction?.adjustmentCode?.code || '--' }}
          </dd>
        </dl>
        <table class="payment-table">
          <tr>
            <th>Allowed</th>
            <td>{{ currentPayment?.allowedAmount | currency }}</td>
          </tr>
          <tr>
            <th>Paid</th>
            <td>{{ currentPayment?.paymentAmount | currency }}</td>
          </tr>
          <tr>
            <th>Adjustment 1</th>
            <td>{{ currentPayment?.adjustmentAmount | currency }}</td>
          </tr>
          <tr>
            <th>Adjustment 2</th>
            <td>{{ currentPayment?.adjustment2Amount | currency }}</td>
          </tr>
          <tr>
            <th>Deductible</th>
            <td>{{ currentPayment?.deductibleAmount | currency }}</td>
          </tr>
          <tr>
            <th>Copay</th>
            <td>{{ currentPayment?.copayAmount | currency }}</td>
          </tr>
          <tr>
            <th>Coinsurance</th>
            <td>{{ currentPayment?.coinsuranceAmount | currency }}</td>
          </tr>
          <tr></tr>
        </table>

        <table class="eob-table">
          <tr>
            <th class="eob-table-type">Type</th>
            <th class="eob-table-description">Description</th>
            <th class="eob-table-amount">EOB Amount</th>
          </tr>
          <tr
            *ngIf="
              currentPayment?.arTransactionDetailEOBCodes.length === 0 &&
              currentPayment?.arTransactionDetailRemarkCodes.length === 0
            "
          >
            <td></td>
            <td>No EOB Codes Found.</td>
            <td></td>
          </tr>
          <tr *ngFor="let eob of currentPayment?.arTransactionDetailEOBCodes">
            <td>EOB</td>
            <td>{{ eob?.eobCode?.code }}: {{ eob?.eobCode?.name }}</td>
            <td>{{ eob?.eobAmount | currency }}</td>
          </tr>
          <tr *ngFor="let remark of currentPayment?.arTransactionDetailRemarkCodes">
            <td>Remark</td>
            <td>{{ remark?.remarkCode }}: {{ remark?.remarkName }}</td>
            <td>--</td>
          </tr>
          <tr></tr>
        </table>
      </ng-container>
      <mat-paginator
        #paginator
        aria-label="datasource"
        [pageSize]="1"
        [hidePageSize]="true"
        [length]="length"
        (page)="handlePageEvent($event)"
        class="paginator"
      >
      </mat-paginator>
      <ng-container>
        <table class="payment-summary-table">
          <tr>
            <th>Post Date</th>
            <th>Check</th>
            <th>Allowed</th>
            <th>Paid</th>
            <th>Adjusted 1</th>
            <th>Adjusted 2</th>
          </tr>
          <tr *ngFor="let transaction of procedure?.arTransactionDetails; trackBy: trackById">
            <td>{{ transaction?.arTransaction?.postingDate | dateDisplay }}</td>
            <td>{{ transaction?.arTransaction?.checkNumber || '--' }}</td>
            <td>{{ transaction?.allowedAmount | currency }}</td>
            <td>{{ transaction?.paymentAmount | currency }}</td>
            <td>{{ transaction?.adjustmentAmount | currency }}</td>
            <td>{{ transaction?.adjustment2Amount | currency }}</td>
          </tr>
        </table>
      </ng-container>
    </div>
    <div *ngIf="procedure && !procedureTabActive && procedure?.arTransactionDetails.length === 0" class="no-payment">
      No Payment Activity Found.
    </div>
  </lib-card>
</article>
