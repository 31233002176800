import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Document360Service } from '../../../../../projects/data/src/public-api';
import { BaseWrapperService } from './base-wrapper.service';
import { SessionStorageService } from '../session-storage.service';

@Injectable({
  providedIn: 'root'
})
export class Doccument360WrapperService extends BaseWrapperService {
  constructor(private service: Document360Service, protected sessionStorageService: SessionStorageService) {
    super();
  }

  apiV1Doc360Launch360Get(): Observable<string> {
    return this.service.apiV1Doc360Launch360Get(this.getDataSetId());
  }
}
