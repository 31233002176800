/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface BillingHistoryViewModel {
  patientId?: string;
  visitId?: string;
  insuranceId?: string;
  submissionDate?: string | null;
  billedAmount?: number;
  insuranceCarrier?: string | null;
  visitNumber?: number;
  icn?: string | null;
  status?: string | null;
  isCorrected?: boolean;
  isRebill?: boolean;
  isVoided?: boolean;
  billingFormat?: string | null;
}
