import { Injectable } from '@angular/core';
import { ApiAutoCompleteService, FieldSelectOption } from 'components/shared';

import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import { CodeWrapperService } from '../service-wrappers/code-wrapper.service';
@Injectable({
  providedIn: 'root'
})
export class CountryCodesLookupService extends ApiAutoCompleteService {
  countryCodes$ = this.service
    .apiV1CodeCodetypePost('CountryCode', { generalSearch: '', pageSize: 500 })
    .pipe(shareReplay(1));

  countryCodes = [];

  constructor(private service: CodeWrapperService) {
    super();
  }

  mapRow(result) {
    return {
      value: result.twoLetterCode,
      label: result.name
    };
  }

  search(value: string): Observable<FieldSelectOption[]> {
    return this.countryCodes$.pipe(
      map((results) => {
        this.countryCodes = results.data;
        return results.data.map(this.mapRow);
      })
    );
  }

  load(id: string): Observable<FieldSelectOption> {
    return this.countryCodes$.pipe(
      map((result: any) => {
        return this.mapRow(result.data.find((x) => x.twoLetterCode === id));
      })
    );
  }
}
