/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RuleCardCriteriaViewModel } from './ruleCardCriteriaViewModel';
import { RuleCardSubtypeViewModel } from './ruleCardSubtypeViewModel';
import { RuleCardTypeViewModel } from './ruleCardTypeViewModel';

export interface RuleCardDetailsViewModel {
  id?: string;
  rowId?: number;
  datasetId?: string;
  ruleCardTypeId?: string;
  ruleCardSubtypeId?: string;
  description?: string | null;
  result?: string | null;
  ruleCardResultId?: string;
  isDeleted?: boolean;
  deletedBy?: string | null;
  deletedDate?: string | null;
  createdBy?: string | null;
  createdDate?: string;
  updatedBy?: string | null;
  updatedDate?: string | null;
  rootId?: string;
  criteriaString?: string | null;
  ruleCardType?: RuleCardTypeViewModel;
  ruleCardSubtype?: RuleCardSubtypeViewModel;
  ruleCardCriteria?: Array<RuleCardCriteriaViewModel> | null;
}
