/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface ARTransactionDetail {
  id?: string;
  rowId?: number;
  datasetId?: string;
  procedureTransactionId?: string;
  arTransactionId?: string;
  allowedAmount?: number;
  paymentAmount?: number;
  adjustmentAmount?: number;
  adjustment2Amount?: number;
  deductibleAmount?: number;
  copayAmount?: number;
  coinsuranceAmount?: number;
  interestAdjustmentAmount?: number;
  rebillInsurance?: boolean;
  whoEnteredAllowed?: string | null;
  whoEnteredPayment?: string | null;
  whoEnteredAdjustment?: string | null;
  whoEnteredAdjustment2?: string | null;
  whoEnteredDeductible?: string | null;
  createdBy?: string | null;
  createdDate?: string;
  updatedBy?: string | null;
  updatedDate?: string | null;
  isDeleted?: boolean;
  deletedBy?: string | null;
  deletedDate?: string | null;
}
