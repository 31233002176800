/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface SearchDocumentResultViewModel {
  id?: string;
  datasetId?: string;
  fileName?: string | null;
  fileType?: string | null;
  documentTypes?: string | null;
  documentTags?: string | null;
  createdDate?: string;
  createdBy?: string | null;
  readonly uploadedDate?: string;
  readonly uploadedBy?: string | null;
  batchId?: string | null;
  patientId?: string | null;
  visitId?: string | null;
}
