import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ImplementationState } from '../../implementation/state/implementation.state';
import { selectImplementation } from '../../implementation/state/implementation.selectors';
import * as ImplementationActions from '../../implementation/state/implementation.actions';
import { Observable, Subscription } from 'rxjs';
import { ImplementationViewModel } from 'projects/data/src/lib/model/implementationViewModel';
import { ImplementationDetailViewModel } from 'projects/data/src/lib/model/implementationDetailViewModel';
import { ActivatedRoute, Router } from '@angular/router';
import { ImplementationWrapperService } from '@core/services/service-wrappers/implementation-wrapper.service';


@Component({
  selector: 'app-implementation-end',
  templateUrl: './implementation-end.component.html',
  styleUrls: ['./implementation-end.component.scss']
})
export class ImplementationEndComponent implements OnInit {

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router, 
    private implementationService: ImplementationWrapperService,
    private store: Store<ImplementationState>) { }

  ngOnInit(): void {
  }

  public isImplementation: boolean = false;
  public gonext: boolean = false;
  public itemCount: number = 0;
  private activatedRouteSub: Subscription;
  private implementation$: Observable<ImplementationViewModel> = this.store.select(selectImplementation);;
  private implementationSub: Subscription;
  private implementation : ImplementationViewModel = null;
  private implementationDetail: ImplementationDetailViewModel = null;
  private STEP_CODE = 'finish'

  implementationSubscribe() {
    this.activatedRouteSub = this.activatedRoute.data.subscribe(data => {
      this.isImplementation = data.implementation;

      if (this.isImplementation) {
        this.implementationSub =
          this.implementation$.subscribe((i) => {
            this.implementation = i;
            this.implementationDetail = this.implementation.details.find(d => d.implementationStepCode == this.STEP_CODE);
        });
      }
    });
    this.store.dispatch(ImplementationActions.updateImplementationStep({ stepCode: this.STEP_CODE}));
  }
  
  implementationUnsubscribe() {
    if (this.isImplementation) {
      this.activatedRouteSub.unsubscribe();
      this.implementationSub.unsubscribe();
    }
  }

  next(): void {
    this.gonext = true;
    this.implementationService
    .apiV1ImplementationCompleteStepCodePost(this.STEP_CODE)
    .subscribe(
      () => { 
        this.gonext = false;  
        window.location.href = '/accounts';
      },
      () => this.gonext = false
    );
  }

}
