/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PatientHoldingIssueFieldViewModel } from './patientHoldingIssueFieldViewModel';

export interface PatientHoldingIssueViewModel {
  id?: string;
  status?: string | null;
  item?: string | null;
  fields?: Array<PatientHoldingIssueFieldViewModel> | null;
}
