/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface UpdateInsuranceHoldingViewModel {
  id?: string;
  datasetId?: string;
  sendingFacilityId?: string;
  insuranceCarrierId?: string | null;
  insuranceCarrierLinkId?: string | null;
  patientHoldingId?: string | null;
  policyNumber?: string | null;
  insuranceSequenceNumber?: number;
  insuranceTypeId?: string | null;
  groupNumber?: string | null;
  planName?: string | null;
  insuredRelation?: string | null;
  insuredLastName?: string | null;
  insuredFirstName?: string | null;
  insuredMiddleName?: string | null;
  insuredSuffix?: string | null;
  insuredBirthDate?: string | null;
  insuredSex?: string | null;
  useGuarantorContactInfo?: boolean;
  insuredAddress1?: string | null;
  insuredAddress2?: string | null;
  insuredCity?: string | null;
  insuredStateCode?: string | null;
  insuredZip?: string | null;
  insuredCountryCode?: string | null;
  insuredPhone?: string | null;
  policyEffectiveFrom?: string | null;
  policyEffectiveTo?: string | null;
  acceptAssignment?: boolean;
  patientInsuranceLevel?: string | null;
  isDeleted?: boolean;
}
