/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ClaimStatusProcedureViewModel } from './claimStatusProcedureViewModel';
import { StatusSegmentModel } from './statusSegmentModel';

export interface ClaimStatusPatientViewModel {
  id?: string;
  datasetId?: string;
  claimStatusProviderResponseId?: string;
  patientId?: string | null;
  visitId?: string | null;
  insuranceId?: string | null;
  patientName?: string | null;
  insuranceMemberNumber?: string | null;
  claimStatusTrackingNumber?: string | null;
  payerClaimControlNumber?: string | null;
  claimIdentifier?: string | null;
  serviceDate?: string | null;
  isCleared?: boolean;
  statusSegmentModel?: StatusSegmentModel;
  procedureViewModels?: Array<ClaimStatusProcedureViewModel> | null;
}
