/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RemitEOBClaimHoldingViewModel } from './remitEOBClaimHoldingViewModel';
import { RemitRemarkClaimHoldingViewModel } from './remitRemarkClaimHoldingViewModel';
import { RemitProcedureHoldingViewModel } from './remitProcedureHoldingViewModel';

export interface RemitClaimHoldingViewModel {
  id?: string;
  datasetId?: string;
  remitHoldingId?: string;
  arTransactionHoldingId?: string | null;
  claimPatientNumber?: string | null;
  claimStatus?: string | null;
  claimAmount?: number;
  claimAmountPaid?: number;
  claimPatientResponsibility?: number;
  claimFilingIndicatorCode?: string | null;
  claimControlNumber?: string | null;
  patientLastName?: string | null;
  patientFirstName?: string | null;
  patientMiddleName?: string | null;
  patientPolicy?: string | null;
  insuredLastName?: string | null;
  insuredFirstName?: string | null;
  insuredMiddleName?: string | null;
  insuredPolicy?: string | null;
  correctedLastName?: string | null;
  correctedFirstName?: string | null;
  correctedMiddleName?: string | null;
  correctedPolicy?: string | null;
  claimStartDate?: string | null;
  claimEndDate?: string | null;
  interestAmount?: number;
  coverageExpirationDate?: string | null;
  claimReceivedDate?: string | null;
  createdBy?: string | null;
  createdDate?: string;
  remitProcedureHoldings?: Array<RemitProcedureHoldingViewModel> | null;
  remitEOBClaimHoldings?: Array<RemitEOBClaimHoldingViewModel> | null;
  remitRemarkClaimHoldings?: Array<RemitRemarkClaimHoldingViewModel> | null;
}
