import { Component, Inject, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatCalendar } from '@angular/material/datepicker';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'lib-frequency-input-dialog',
  templateUrl: './frequency-input-dialog.component.html',
  styleUrls: ['./frequency-input-dialog.component.scss']
})
export class FrequencyInputDialogComponent {
  @ViewChild('frequencyCalendar') frequencyCalendar: MatCalendar<Date>;

  readonly Hourly = 'Hourly';
  readonly Daily = 'Daily';
  readonly Weekly = 'Weekly';
  readonly Monthly = 'Monthly';
  readonly Yearly = 'Yearly';

  formGroup: UntypedFormGroup;
  minDate = new Date();
  constructor(
    public dialogRef: MatDialogRef<FrequencyInputDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    const frequencyDatePre = this.getBaseDate(data?.nextExecution);

    let executionHour = 0;
    let executionMeridiem = data?.executionMeridiem;
    if (data?.executionHour != null && data.executionHour)
    {
      const offset = (new Date()).getUTCHours() - (new Date()).getHours();
      executionHour = data.executionHour  - offset;
      if (executionHour == 0)
      {
        executionHour = 12;
      }
      else if (executionHour < 0)
      {
          executionHour += 12;
          executionMeridiem = executionMeridiem == "AM" ? "PM" : "AM";
      }
    }

    const frequencyDate = new Date(frequencyDatePre);
    const frequencyTime =
      (data?.executionHour != null && data.executionHour != "" && data?.executionMinute != null && data.executionHour != ""  && data?.executionMeridiem)
        ? `${executionHour.toString().padStart(2, '0')}:${data.executionMinute.toString().padStart(2, '0')} ${executionMeridiem}`
        : '12:00 AM';
    const doesRepeat = data?.frequencyType && data.frequencyType !== 'Once' ? true : false;
    const weekDays = data?.weekDays ? data?.weekDays : [];
    const monthDays = data?.monthDays ? data?.monthDays : [];

    setTimeout(() => {
      // This updates the calendar to view the current selected date.
      // Tried implementing the various lifecycle hooks, but they all throw errors due to validation checks.
      this.frequencyCalendar.activeDate = frequencyDate;
    }, 1);

    this.formGroup = new UntypedFormGroup({
      frequencyDate: new UntypedFormControl(frequencyDate, Validators.required),
      frequencyTime: new UntypedFormControl(frequencyTime, Validators.required),
      doesRepeat: new UntypedFormControl(doesRepeat),
      frequencyInterval: new UntypedFormControl(data?.frequencyInterval ?? 1),
      frequencyType: new UntypedFormControl(data?.frequencyType),
      weekDays: new UntypedFormControl(weekDays),
      monthDays: new UntypedFormControl(monthDays)
    });
  }

  getBaseDate(nextExecution) {
    if (nextExecution) {
      if (nextExecution?.toDateString === 'function') {
        return nextExecution?.toDateString();
      }

      return new Date(nextExecution).toDateString();
    }
    return new Date().toDateString();
  }

  setDate(event) {
    this.formGroup.controls.frequencyDate.setValue(event);
  }

  repeatChange(event, toggle) {
    const checked = toggle.checked;
    this.formGroup.controls.doesRepeat.setValue(checked);

    if (checked) {
      this.formGroup.controls.frequencyInterval.setValue(1);
      this.formGroup.controls.frequencyType.setValue(this.Daily);
    } else {
      this.resetInputs('weekDays');
      this.resetInputs('monthDays');
      this.formGroup.controls.frequencyInterval.setValue(0);
      this.formGroup.controls.frequencyType.setValue('');
    }
  }

  cancel() {
    this.dialogRef.close();
  }
  save() {
    this.dialogRef.close(this.formatExportData());
  }

  formatExportData() {
    const formData = this.formGroup.value;
    let frequencyType = '';
    let frequencyInterval = null;
    let executionHour = null;
    let executionMinute = null;
    let executionMeridiem = null;
    let executionTimeZone = null;

    const nextExecution = new Date(`${formData.frequencyDate.toDateString()} ${formData.frequencyTime}`);

    if (formData.doesRepeat !== true) {
      frequencyType = 'Once';
    } else {
      const timeObj = this.getUTCTimeInfo(nextExecution);
      frequencyType = formData.frequencyType;
      executionHour = timeObj.executionHour;
      executionMinute = timeObj.executionMinutes;
      executionMeridiem = timeObj.executionMeridiem;
      frequencyInterval = formData.frequencyInterval;
      executionTimeZone = 'Etc/UTC';
    }

    return {
      nextExecution,
      executionHour,
      executionMinute,
      executionMeridiem,
      executionTimeZone,
      frequencyType,
      frequencyInterval,
      weekDays: frequencyType === this.Weekly ? formData.weekDays : null,
      monthDays: frequencyType === this.Monthly ? formData.monthDays : null
    };
  }
  frequencyChange(event) {
    switch (event.value) {
      case this.Daily:
      case this.Yearly:
        this.resetInputs('monthDays');
        this.resetInputs('weekDays');
        break;
      case this.Weekly:
        this.resetInputs('monthDays');
        this.addValidator('weekDays');
        break;
      case this.Monthly:
        this.addValidator('monthDays');
        this.resetInputs('weekDays');
        break;
    }
  }

  addValidator(input: 'weekDays' | 'monthDays') {
    this.formGroup.get(input).setValidators([Validators.required]);
    this.formGroup.get(input).updateValueAndValidity();
  }
  resetInputs(inputName: 'weekDays' | 'monthDays') {
    const input = this.formGroup.get(inputName);
    input.setValue([]);
    input.setValidators([]);
    input.updateValueAndValidity();
  }

  /**
   * Function serves to out a time into UTC hour, minutes and meridiem
   *
   * @private
   * @param {string} time
   * @memberof FrequencyInputDialogComponent
   */
  private getUTCTimeInfo(timeDate: Date) {
    let executionHour = timeDate?.getUTCHours().toString();
    const executionMinutes = timeDate?.getUTCMinutes().toString();

    let executionMeridiem = 'AM';
    const hour = timeDate.getUTCHours();

    // Apply Hours
    if (hour === 0) {
      executionHour = '12';
    } else if (hour > 12) {
      executionHour = (hour - 12).toString();
    }

    // Apply Meridiem defaults to am
    if (hour === 0 || hour > 12) {
      executionMeridiem = 'PM';
    }
    return {
      executionHour,
      executionMinutes,
      executionMeridiem
    };
  }
  trackByIndex(index: number, item: any) {
    return index
  }
}
