import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImplementationComponent } from './implementation.component';
import { ImplementationRoutingModule } from './implementation-routing.module';
import { SubPageLayoutModule } from 'components/core/sub-page-layout';
import { AutoBreadcrumbModule } from 'src/app/shared/components/auto-breadcrumb/auto-breadcrumb.module';
import { ImplementationStartComponent } from './implementation-start/implementation-start.component';
import { PracticeInformationComponent } from './practice-information/practice-information.component';
import { FormModule, SharedModule } from 'components';
import { MatInputModule } from '@angular/material/input';
import { MaterialModule } from 'components';
import { implementationReducer } from './state/implementation.reducers';
import { FEATURE_NAME as ImplementationFeatureName } from './state/implementation.selectors';
import { ImplementationEffects } from './state/implementation.effects';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { LocationsModule } from '../files/locations/locations.module';
import { ProvidersModule } from '../files/providers/providers.module';
import { ImplementationEndComponent } from './implementation-end/implementation-end.component';

@NgModule({
  declarations: [
    ImplementationComponent,
    ImplementationStartComponent,
    PracticeInformationComponent,
    ImplementationEndComponent
  ],
  imports: [
    CommonModule,
    ImplementationRoutingModule,
    SubPageLayoutModule,
    AutoBreadcrumbModule,
    FormModule,
    SharedModule,
    MatInputModule,
    MaterialModule,
    LocationsModule,
    ProvidersModule,
    StoreModule.forFeature(ImplementationFeatureName, implementationReducer),
    EffectsModule.forFeature([ImplementationEffects]),
  ]
})
export class ImplementationModule { }
