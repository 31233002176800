/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ProcedureTransactionDetailsViewModel } from './procedureTransactionDetailsViewModel';

export interface VisitPaymentLineItemViewModel {
  visitNumber?: number;
  procedureTransaction?: ProcedureTransactionDetailsViewModel;
  procedureAmount?: number;
  paymentAmounts?: { [key: string]: number } | null;
  paymentAmount?: number;
  adjustmentAmount?: number;
  adjustment2Amount?: number;
  balance?: number;
}
