import { Component, Inject, Injector, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroupDefinition, GridComponent, PagedModel } from 'components';
import { compare } from 'fast-json-patch';
import { Observable, of } from 'rxjs';
import { filter, first, map, take } from 'rxjs/operators';
import { LocationsLookupService } from 'src/app/core/services/lookup';
import { PatientLookupService } from 'src/app/core/services/lookup/patient-lookup.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import {
  InsuranceCarrierClaimValueDetailsViewModel,
  InsuranceCarrierClaimValueService
} from '../../../../../../../projects/data/src/public-api';
import { InsuranceCarrierClaimValueWrapperService } from '../../../../../core/services/service-wrappers/insurance-carrier-claim-value-wrapper.service';

@Component({
  selector: 'app-paper-hcfa-dialog',
  templateUrl: './paper-hcfa-dialog.component.html',
  styleUrls: ['./paper-hcfa-dialog.component.scss']
})
export class PaperHcfaDialogComponent implements OnInit {
  @ViewChild(GridComponent) grid: GridComponent;
  formGroup = new UntypedFormGroup({});
  formInitialized = false;
  saving = false;
  hcfaId;
  insuranceCarrierId;
  viewOnly = false;

  formDefinitions: FormGroupDefinition[] = [
    {
      hideTitle: true,
      controls: [
        {
          name: 'x04' //insuredName
        },
        {
          name: 'x07' //insuredAddress
        },
        {
          name: 'x11' //insuredPolicyNumber
        },
        {
          name: 'x11a' //insuredDOB
        },
        {
          name: 'x11b1' //otherClaimId
        },
        {
          name: 'x11b2' //customClaimId
        },
        {
          name: 'x11c' //currentInsurancePlan
        },
        {
          name: 'x09d' //insuredPlanName
        },
        {
          name: 'x10d' //claimCode
        },
        {
          name: 'x121' //patientSignature
        },
        {
          name: 'x13' //authorizedSignature
        },
        {
          name: 'x142' //dateOfIllnessCode
        },
        {
          name: 'x151' //otherDate
        },
        {
          name: 'x17' //referringProvider
        },
        {
          name: 'x17a' //npi
        },
        {
          name: 'x17b' //npiB
        },
        {
          name: 'x19' //additionalClaimText
        },
        {
          name: 'x191' //additionalClaimInfo
        },
        {
          name: 'x222' //originalRefNo
        },
        {
          name: 'x24e' //modifier
        },
        {
          name: 'x24i' //idQual
        },
        {
          name: 'x24j1' //renderingProvider
        },
        {
          name: 'x24j2' //renderingProviderNPI
        },
        {
          name: 'x251' //federalTaxIdNumber
        },
        //{
        //  name: 'x29' //amountPaid
        //},
        {
          name: 'x311' //physicianSignature
        },
        {
          name: 'x321' //facilityLocation
        },
        {
          name: 'x32a' //facilityLocationA
        },
        {
          name: 'x32b' //facilityLocationB
        },
        {
          name: 'x331' //billingProviderNameAndAddress
        },
        //{
        //  name: 'x332' //billingProviderAddress
        //},
        {
          name: 'x33a' //billingProviderA
        },
        {
          name: 'x33b' //billingProviderB
        }
      ]
    }
  ];
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private patientLookupService: PatientLookupService,
    private locationsLookupService: LocationsLookupService,
    public dialogRef: MatDialogRef<PaperHcfaDialogComponent>,
    private notificationService: NotificationService,
    private claimValueService: InsuranceCarrierClaimValueWrapperService
  ) {
    this.hcfaId = data?.hcfaId;
    this.insuranceCarrierId = this.data?.insuranceCarrierId;
    this.viewOnly = this.data?.viewOnly;
    this.setupForm();
  }

  async ngOnInit() {
    this.claimValueService
      .apiV1InsuranceCarrierClaimValueAllPost({})
      .pipe()
      .subscribe((result) => {
        let ctrlField = null;

        this.claimValueService
          .apiV1InsuranceCarrierClaimValueSearchInsurancecarrieridPost(this.insuranceCarrierId, { pageSize: 8000 })
          .pipe()
          .subscribe((result) => {
            let ctrlField = null;

            result.data.forEach((item) => {
              ctrlField = null;
              ctrlField = this.formGroup.get('x' + item.claimField);

              // load stored claim value selection
              if (ctrlField !== null && ctrlField !== undefined && item.value.startsWith('*')) {
                ctrlField.setValue(item.value.replace('*', ''));
              } else if (ctrlField !== null && ctrlField !== undefined) {
                ctrlField.setValue(item.value);
              }
            });
          });
      });
  }

  setupForm() {
    this.formDefinitions.forEach((sc) => {
      sc.controls.forEach((control) => {
        if (control.type !== 'empty' && control.type !== 'label') {
          this.formGroup.addControl(control.name, new UntypedFormControl(control.initial ?? '', control.validators));
        }
      });
    });

    this.formInitialized = true;
  }

  save() {
    let detailString: string = '';
    let saveObservable: Observable<any>;
    let notification;
    let aParmStrings: Array<string> = []

    if (this.formGroup.valid) {
      this.saving = true;
    }

    this.formDefinitions.forEach((sc) => {
      sc.controls.forEach((control) => {
        let asterisk: string = '*';

        // Remove * for "literal" Text Values
        if (
          ['x11b2', 'x142', 'x151', 'x19', 'x24i'].includes(control.name) &&
          !['Suppress Value', 'Policy Num.', 'Group Num.'].includes(this.formGroup.get(control.name)?.value)
        ) {
          asterisk = '';
        }

        if (this.formGroup.get(control.name)?.value != '') {
          aParmStrings.push(control.name.replace('x', '') + ":" + asterisk + this.formGroup.get(control.name).value);
        }

      });
    });

    if (this.insuranceCarrierId) {
      notification = 'Claim Defaults Updated';
      saveObservable = this.claimValueService
        .apiV1InsuranceCarrierClaimValueUpdateAll(this.insuranceCarrierId, aParmStrings)
        .pipe(map((x: any) => x));
    }

    saveObservable
      .pipe(take(1))
      .subscribe(
        (response) => {
          this.dialogRef.close(true);
          this.notificationService.success(notification);
        },
        (err) => this.notificationService.error('Saving Insurance Carrier')
      )
      .add(() => {
        this.saving = false;
      });
  }
}
