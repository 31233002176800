import { Injectable } from '@angular/core';
import { ApiAutoCompleteService, FieldSelectOption } from 'components/shared';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

// import { JobWrapperService } from '../service-wrappers/job-wrapper.service';
import { UserWrapperService } from '../service-wrappers/user-wrapper.service';

@Injectable({
  providedIn: 'root'
})
export class UserLookupService extends ApiAutoCompleteService {
  constructor(private service: UserWrapperService) {
    super();
  }

  mapRow(result) {
    return {
      value: result.id,
      label: `${result.fullName}`
    };
  }

  search(value: string): Observable<FieldSelectOption[]> {
    return this.service
      .apiV1UserPost({
        generalSearch: value,
        pageSize: 9999
      })
      .pipe(map((results) => results.data.map(this.mapRow)));
  }

  load(id: string): Observable<FieldSelectOption> {
    return this.service.apiV1UserIdGet(id).pipe(map(this.mapRow));
  }
}
