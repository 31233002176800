/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CodeViewModel } from './codeViewModel';
import { ProviderLocationViewModel } from './providerLocationViewModel';

export interface LocationDetailsViewModel {
  id?: string;
  datasetId?: string;
  rowId?: number;
  code?: string | null;
  name?: string | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  stateCode?: string | null;
  zip?: string | null;
  countryCode?: string | null;
  phone?: string | null;
  fax?: string | null;
  website?: string | null;
  locationType?: string | null;
  taxId?: string | null;
  npi?: string | null;
  clia?: string | null;
  mammo?: string | null;
  sameMailing?: boolean;
  mailingAddress1?: string | null;
  mailingAddress2?: string | null;
  mailingCity?: string | null;
  mailingStateCode?: string | null;
  mailingZip?: string | null;
  mailingCountryCode?: string | null;
  inactive?: boolean;
  holdBilling?: boolean;
  rvuGpciId?: string | null;
  isDeleted?: boolean;
  deletedBy?: string | null;
  deletedDate?: string | null;
  createdBy?: string | null;
  createdDate?: string;
  updatedBy?: string | null;
  updatedDate?: string | null;
  placeOfServiceCodeIdDefault?: string | null;
  locationProviders?: Array<ProviderLocationViewModel> | null;
  placeOfServiceCodeDefault?: CodeViewModel;
  codeSearch?: string | null;
  nameSearch?: string | null;
  readonly codeSearchIndex?: number;
  readonly nameSearchIndex?: number;
}
