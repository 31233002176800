import { Injectable } from '@angular/core';
import { PagedModelRequest, ProviderService } from 'data';
import { Observable } from 'rxjs';
import { getSelectedDataSetId } from '../../state/data-set/data-set.selectors';
import { BaseWrapperService } from './base-wrapper.service';
import { SessionStorageService } from '../session-storage.service';
@Injectable({
  providedIn: 'root'
})
export class PhysiciansWrapperService extends BaseWrapperService {
  constructor(private service: ProviderService, protected sessionStorageService: SessionStorageService) {
    super();
  }

  apiV1PhysicianIdGet(id: string): Observable<any> {
    return this.service.apiV1ProviderIdGet(id, this.getDataSetId());
  }

  apiV1PhysicianAllPost(pagedModelRequest?: PagedModelRequest): Observable<any> {
    return this.service.apiV1ProviderAllPost(this.getDataSetId(), pagedModelRequest);
  }

  loadByIds(ids: string[]): Observable<any> {
    return this.service.apiV1ProviderIdsPost(this.getDataSetId(), ids);
  }
}
