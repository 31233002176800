<div class="dialog-form add-edit-user-form">
  <div class="dialog-form__close">
    <a mat-dialog-close>
      <mat-icon>close</mat-icon>
    </a>
  </div>
  <header class="dialog-form__title">
    <h2 id="userType">{{ viewOnly === true ? 'View' : userId ? 'Edit' : 'Add New' }} User</h2>
  </header>
  <main mat-dialog-content>
    <div class="dialog-form__container">
      <form class="form" [formGroup]="formGroup">
        <lib-form-field class="email" [parent]="formGroup" label="Email" name="email" type="email"></lib-form-field>
        <div class="name-div">
          <lib-form-field
            class="first-name"
            [parent]="formGroup"
            label="First Name"
            name="firstname"
            type="text"
          ></lib-form-field>
          <lib-form-field
            class="last-name"
            [parent]="formGroup"
            label="Last Name"
            name="lastname"
            type="text"
          ></lib-form-field>
        </div>
        <br *ngIf="isEdit === false" />
        <h4>Datasets:</h4>
        <div>
          <mat-form-field class="dataset-select" appearance="outline">
            <mat-chip-grid #datasetChipList aria-label="Datasets List">
              <mat-chip-row
                *ngFor="let dataset of selectedDatasets; let i = index"
                [selectable]="selectable"
                [removable]="removable"
                (removed)="remove('dataset', dataset)"
                id="selectedDataset{{ i }}"
              >
                {{ dataset.label }}
                <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
              </mat-chip-row>
              <input
                matInput
                #datasetInput
                [formControl]="datasetCtrl"
                [placeholder]="datasetPlaceHolder"
                #datasetMatACTrigger="matAutocompleteTrigger"
                [matAutocomplete]="datasetAuto"
                [matChipInputFor]="datasetChipList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                (matChipInputTokenEnd)="add('dataset', $event)"
                (blur)="onBlur('dataset')"
                (focus)="openAuto('dataset', datasetMatACTrigger)"
              />
            </mat-chip-grid>
            <mat-autocomplete
              #datasetAuto="matAutocomplete"
              (optionSelected)="selected('dataset', $event.option.value)"
              autoActiveFirstOption
            >
              <mat-option *ngFor="let dataset of filteredDatasets | async; let i = index" [value]="dataset">
                <mat-checkbox id="set{{ i }}" color="primary" [checked]="dataset.isChecked"></mat-checkbox>
                {{ dataset.label }}
              </mat-option>
            </mat-autocomplete>
            <button
              mat-icon-button
              matSuffix
              (click)="openAuto('dataset', datasetMatACTrigger)"
              id="dataDropDownButton"
            >
              <mat-icon>expand_more</mat-icon>
            </button>
            <div
              class="error-box"
              *ngIf="datasetCtrl.errors?.leastOne || (isEdit === false && datasetCtrl.pristine === true)"
            >
              <mat-error class="error">Must select at least one dataset</mat-error>
            </div>
          </mat-form-field>
        </div>
        <br *ngIf="isEdit === false" />
        <h4>Roles:</h4>
        <div>
          <mat-form-field class="roles-select" appearance="outline">
            <mat-chip-grid #roleChipList aria-label="Roles List">
              <mat-chip-row
                *ngFor="let role of selectedRoles; let i = index"
                [selectable]="selectable"
                [removable]="removable"
                (removed)="remove('role', role)"
                id="selectedRole{{ i }}"
              >
                {{ role.label }}
                <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
              </mat-chip-row>
              <input
                matInput
                #roleInput
                [formControl]="roleCtrl"
                [placeholder]="rolePlaceHolder"
                #roleMatACTrigger="matAutocompleteTrigger"
                [matAutocomplete]="roleAuto"
                [matChipInputFor]="roleChipList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                (blur)="onBlur('role')"
                (focus)="openAuto('role', roleMatACTrigger)"
                (matChipInputTokenEnd)="add('role', $event)"
              />
            </mat-chip-grid>
            <mat-autocomplete
              #roleAuto="matAutocomplete"
              (optionSelected)="selected('role', $event.option.value)"
              autoActiveFirstOption
            >
              <mat-option *ngFor="let role of filteredRoles | async; let i = index" [value]="role">
                <mat-checkbox color="primary" [checked]="role.isChecked" id="userRole{{ i }}"></mat-checkbox>
                {{ role.label }}
              </mat-option>
            </mat-autocomplete>
            <button mat-icon-button matSuffix (click)="openAuto('role', roleMatACTrigger)" id="roleDropDownButton">
              <mat-icon>expand_more</mat-icon>
            </button>
            <div
              class="error-box"
              *ngIf="roleCtrl.errors?.leastOne || (isEdit === false && roleCtrl.pristine === true)"
            >
              <mat-error class="error">Must select at least one role</mat-error>
            </div>
          </mat-form-field>
        </div>
      </form>
    </div>
  </main>
  <div mat-dialog-actions class="buttons">
    <button *ngIf="viewOnly === true" class="close-button" mat-stroked-button mat-dialog-close>Close</button>
    <button *ngIf="viewOnly === false" class="cancel-button" mat-stroked-button mat-dialog-close>Cancel</button>
    <lib-button-loading
      *ngIf="viewOnly === false"
      color="primary"
      class="save-button"
      [loading]="saving"
      [invalid]="
        formGroup.invalid ||
        roleCtrl.invalid ||
        datasetCtrl.invalid ||
        (isEdit === false && roleCtrl.pristine === true) ||
        (isEdit === false && datasetCtrl.pristine === true)
      "
      (clickEvent)="save()"
      id="saveButton"
      >Save
    </lib-button-loading>
  </div>
</div>
