import { APP_INITIALIZER, ErrorHandler, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { environment } from 'src/environments/environment';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { MsalInterceptor } from '@azure/msal-angular';

import { RouterStateSerializer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { EffectsModule } from '@ngrx/effects';
import { coreReducers, metaReducers, coreEffects } from './core.state';
import { locationProvider, LocationToken } from './injection-tokens/location-token';
import { windowProvider, WindowToken } from './injection-tokens/window-token';
import { StoreModule } from '@ngrx/store';
import { AppInitializerService } from './services/app-initializer.service';
import { HttpBackend, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ServerErrorInterceptor } from './interceptors/server-error.interceptor';
import { GlobalErrorHandler } from './handlers/global-error-handler';
import { RouterModule } from '@angular/router';

import { CoreModule as CoreComponentModule } from 'components/core';
import { SharedModule } from '../shared/shared.module';
import { MaterialModule } from 'components/shared/modules';
import { AuthorizedModule } from './layout/authorized/authorized.module';
import { CustomSerializer } from './state/router/custom-serializer';
import { map } from 'rxjs/operators';
import { AuthInterceptor as ImaginePayInterceptor } from 'imagine-pay-api';
import { AppConfigService } from './services/app-config.service';

export function initConfig(
  handler: HttpBackend,
  appConfigService: AppConfigService,
  appInitService: AppInitializerService
) {
  return () => {
    return appConfigService.initialize(handler).pipe(map(() => appInitService.initialize()));
  };
}

@NgModule({
  declarations: [],
  exports: [],
  imports: [
    CommonModule,
    RouterModule,
    AuthorizedModule,
    MaterialModule,
    CoreComponentModule,
    SharedModule,
    HttpClientModule,
    EffectsModule.forRoot(coreEffects),
    StoreModule.forRoot(coreReducers, { metaReducers }),
    StoreRouterConnectingModule.forRoot(),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production })
  ],
  providers: [
    AppInitializerService,
    { provide: LocationToken, useFactory: locationProvider },
    { provide: WindowToken, useFactory: windowProvider },
    { provide: RouterStateSerializer, useClass: CustomSerializer },
    { provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ServerErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ImaginePayInterceptor, multi: true },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    {
      provide: APP_INITIALIZER,
      useFactory: initConfig,
      deps: [HttpBackend, AppConfigService, AppInitializerService],
      multi: true
    }
  ]
})
export class CoreModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: CoreModule
  ) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import only in AppModule');
    }
  }
}
