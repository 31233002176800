import { AfterViewInit, Component, EventEmitter, Inject, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HttpHeaders, HttpParams } from '@angular/common/http';

export interface UploadDialogData {
  title?: string;
  subTitle?: string;
  maxFiles?: number;
  uploadUrl: string;
  uploadAllLabel?: string;
  allowedExtensions?: string[];
  httpRequestHeaders?: HttpHeaders | { [header: string]: string | string[] };
  httpRequestParams?: HttpParams | { [param: string]: string | string[] };
  checkUploadFile?: boolean;
}

@Component({
  selector: 'lib-upload-modal',
  templateUrl: './upload-modal.component.html',
  styleUrls: ['./upload-modal.component.scss']
})
export class UploadModalComponent implements OnInit, AfterViewInit{
  @ViewChild('fileUploadQueue') public fileUploadQueue: TemplateRef<any>;
  public isDragging = false;
  public disabled = false;
  private responses: Array<any> = [];
  selectionTitle = "Selected File";
  extensions;

  @Input()
  data: UploadDialogData;
  @Output() uploadSuccess = new EventEmitter();

  constructor() {} //public dialogRef: MatDialogRef<UploadModalComponent> // @Inject(MAT_DIALOG_DATA) public data: UploadDialogData

  ngOnInit(): void {
    if (this.data.maxFiles > 1) {
      this.selectionTitle = "Selected Files";
    }
  }

  ngAfterViewInit() {
    if (this.data?.allowedExtensions?.length > 0) {
      this.extensions = this.data.allowedExtensions.map(x => '.' + x).toString();
      var input = document.getElementById('drop-zone');
      input?.setAttribute('accept', this.extensions);
    }
  }

  dragging(isDragging): void {
    this.isDragging = isDragging;
  }

  setIsDisabled(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  uploadFinished(result, i) {
    if (result.event.type === 4 && result.event.ok) {
      // @ts-ignore
      const filesCount = this.fileUploadQueue?.files?.length;
      this.responses[i] = result.event.body;
      //this.responses[i]._fileName = result.file.name;
      if (this.responses.length === filesCount && !this.responses.find((r) => !r)) {
        this.uploadSuccess.emit(this.responses);
        /// UPLOAD FINISHED AND GOOD
        // this.dialogRef.close(this.responses);
      }
    }
  }

  close() {
    //this.dialogRef.close();
  }

  trackByIndex(index: number, item: any) {
    return index
  }
}
