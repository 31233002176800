import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonLoadingComponent } from './button-loading/button-loading.component';
import { MaterialModule } from './modules';
import { PasswordStrengthCheckerComponent } from './password-strength-checker/password-strength-checker.component';

const components = [ButtonLoadingComponent, PasswordStrengthCheckerComponent];

@NgModule({
  declarations: [...components],
  exports: [...components],
  imports: [CommonModule, MaterialModule]
})
export class SharedModule {}
