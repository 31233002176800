import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ImplementationWrapperService } from '@core/services/service-wrappers/implementation-wrapper.service';
import { Store } from '@ngrx/store';
import { ImplementationDetailViewModel } from 'projects/data/src/lib/model/implementationDetailViewModel';
import { ImplementationViewModel } from 'projects/data/src/lib/model/implementationViewModel';
import { Subscription } from 'rxjs';
import { selectImplementation, selectImplementationDetails } from '../state/implementation.selectors';
import { ImplementationState } from '../state/implementation.state';

@Component({
  selector: 'app-implementation-start',
  templateUrl: './implementation-start.component.html',
  styleUrls: ['./implementation-start.component.scss']
})
export class ImplementationStartComponent implements OnInit, OnDestroy {

  constructor(private store: Store<ImplementationState>,
    private implementationService: ImplementationWrapperService,
    private router: Router) { }

  ngOnInit(): void {
    this.subscriptions.push(
      this.implementation$.subscribe((i) => {
        this.implementation = i;
      })
    );
  }

ngOnDestroy() {
  this.subscriptions.forEach((subscription) => subscription.unsubscribe());
}

  private subscriptions: Subscription[] = [];
  public implementationDetails$ = this.store.select(selectImplementationDetails);
  public implementation$ = this.store.select(selectImplementation);
  private implementation : ImplementationViewModel = null;
  private STEP_CODE = "start";

  next() {
    if (this.implementation.status == "NotStarted") {
      this.implementationService
      .apiV1ImplementationCompleteStepCodePost(this.STEP_CODE)
      .subscribe(
        () => { 
          this.router.navigateByUrl('/implementation/practice-information'); 
        },
        () => {}
      );
    }
    else {
      this.router.navigateByUrl('/implementation/practice-information');
    }
  }
}

