import { formatPercent } from '@angular/common';
import { Injector, LOCALE_ID } from '@angular/core';
import { Validators } from '@angular/forms';
import { BodyClasses, ColumnSizes, FilterType } from 'components';
import { DateDisplayPipe } from 'projects/components/src/lib/shared/pipes/date-display/date-display.pipe';
import { Report } from '../../models/report';
import { ReimbursementAnalysisService } from './reimbursement-analysis.service';
import {
  PhysiciansLookupService,
  InsuranceCarriersLookupService,
  FinancialClassesLookupService,
  AttendingPhysiciansLookupService,
  LocationsLookupService,
  ProcedureCodesLookupService,
  PlacesOfServiceCodesLookupService,
  DiagnosisCodesLookupService
} from 'src/app/core/services/lookup';

export const ReimbursementAnalysis: Report = {
  id: 'reimbursement-analysis',
  label: 'Reimbursement Analysis',
  searchFormClass: 'reimbursement-analysis-report',
  getSearchCriteria: null
};
