<div class="dialog-form assign-roles-form">
  <div class="dialog-form__close">
    <a mat-dialog-close>
      <mat-icon>close</mat-icon>
    </a>
  </div>
  <header mat-dialog-title class="dialog-form__title">
    <h2>Assign Roles</h2>
  </header>
  <main mat-dialog-content>
    <div class="dialog-form__container">
      <mat-form-field appearance="outline">
        <mat-label>Assign Roles</mat-label>
        <mat-select [multiple]="true" [(value)]="selectedRoles">
          <mat-option *ngFor="let role of assignRolesOptions; trackBy: trackById" [value]="role">{{
            role.roleName
          }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </main>
  <div mat-dialog-actions class="dialog-form__actions">
    <button class="cancel-button" mat-stroked-button mat-dialog-close>Cancel</button>
    <lib-button-loading color="primary" class="save-button" [loading]="saving" (clickEvent)="save()"
      >Save
    </lib-button-loading>
  </div>
</div>
