import { DatePipe } from '@angular/common';
import { Component, Inject, Injector, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { DialogContent, FormGroupDefinition } from 'components';
import { compare } from 'fast-json-patch';
import { Observable, of, scheduled } from 'rxjs';
import { first, map, take } from 'rxjs/operators';
import { NotificationService } from 'src/app/core/services/notification.service';
import { FeeScheduleWrapperService } from 'src/app/core/services/service-wrappers/fee-schedule-wrapper.service';
import { FeeScheduleEntryViewModel } from '../../../../../../projects/data/src/public-api';
import { DialogService } from '../../../../core/services/dialog.service';
import { ModifiersLookupService, ProcedureCodesLookupService } from '../../../../core/services/lookup';
import { FeeScheduleLookupService } from '../../../../core/services/lookup/fee-schedule-lookup.service';
import { FeeSchedulesEntryDialogComponent } from './fee-schedules-entry-dialog/fee-schedules-entry-dialog.component';
import { PopulateFeeScheduleDialogComponent } from './populate-fee-schedule-dialog/populate-fee-schedule-dialog.component';
import { MatSort } from '@angular/material/sort';
import { UploadComponent } from '../upload/upload.component';

@Component({
  selector: 'app-fee-schedules-dialog',
  templateUrl: './fee-schedules-dialog.component.html',
  styleUrls: ['./fee-schedules-dialog.component.scss'],
  providers: [DatePipe]
})
export class FeeSchedulesDialogComponent implements OnInit {
  @ViewChild(MatTable) table: MatTable<FeeScheduleEntryViewModel>;
  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[] = ['procedure', 'modifier', 'amount', 'effectiveFrom', 'effectiveTo', 'actions'];
  dataSource: any;
  formGroup = new UntypedFormGroup({});
  formInitialized = false;
  name: string;
  saving = false;
  commit = false;
  allow = false;
  sameFeeName = false;
  duplicateName = false;
  entryForm: UntypedFormGroup;
  produreArray = [];
  modifierArray = [];
  entryArray = [];
  entryIdArray = [];
  allowAdd = false;
  createEntries = [];
  updateEntries = [];
  completeArray = [];
  viewOnly = false;

  formDefinitions: FormGroupDefinition[] = [
    {
      hideTitle: true,
      controls: [
        {
          label: 'Name',
          name: 'name',
          focusId: 'name',
          type: 'text',
          class: 'form-span-10',
          validators: Validators.required,
          selectionChanged: (event) => {
            this.nameChange(event)
          }
        },
        {
          label: 'Upload File',
          name: 'uploadFile',
          type: 'empty',
          class: 'form-span-12'
        }
      ]
    }
  ];

  feeScheduleId;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private injector: Injector,
    private notificationService: NotificationService,
    public dialogRef: MatDialogRef<FeeSchedulesDialogComponent>,
    private service: FeeScheduleWrapperService,
    private procedureLookup: ProcedureCodesLookupService,
    private modifierLookup: ModifiersLookupService,
    private fb: UntypedFormBuilder,
    private dialogService: DialogService,
    public dialog: MatDialog,
    public lookup: FeeScheduleLookupService,
    public datePipe: DatePipe
  ) {
    this.feeScheduleId = data?.feeScheduleId;
    this.viewOnly = data?.viewOnly;
  }

  ngOnInit(): void {
    this.setupForm();
    if (this.feeScheduleId && this.feeScheduleId !== '') {
      this.allowAdd = true;
      this.loadGrid(this.feeScheduleId);
    }
  }

  populateFees() {
    const dialog = this.dialog.open(PopulateFeeScheduleDialogComponent, {
      disableClose: true,
      autoFocus: false,
      data: {
        feeScheduleId: this.feeScheduleId
      },
      height: '47%',
      width: '50%'
    });
    dialog.afterClosed().subscribe((result) => {
      if (result) {
        this.notificationService.success('Fee Schedule Has Been Populated');
        this.loadGrid(this.feeScheduleId);
      }
    });
  }

  editFee(row, viewOnly = false) {
    const dialog = this.dialog.open(FeeSchedulesEntryDialogComponent, {
      disableClose: true,
      autoFocus: false,
      data: {
        feeScheduleEntry: row.id,
        feeSchedule: row.feeScheduleId, 
        viewOnly: viewOnly
      },
      height: '500px',
      width: '800px'
    });

    dialog.afterClosed().subscribe((result) => {
      if (result) {
        this.loadGrid(this.feeScheduleId);
      }
    });
  }

  addEntry() {
    const dialog = this.dialog.open(FeeSchedulesEntryDialogComponent, {
      disableClose: true,
      autoFocus: false,
      data: { feeSchedule: this.feeScheduleId },
      height: '500px',
      width: '800px'
    });
    dialog.afterClosed().subscribe((result) => {
      if (result) {
        this.loadGrid(this.feeScheduleId);
      }
    });
  }

  setupForm() {
    this.formDefinitions.forEach((sc) => {
      sc.controls.forEach((control) => {
        if (control.type !== 'empty' && control.type !== 'label') {
          this.formGroup.addControl(control.name, new UntypedFormControl(control.initial ?? '', control.validators));
        }
      });
    });
    this.formInitialized = true;
  }

  //refreshes any update to fee schedule entry for the current fee schedule
  loadGrid(fee: string) {
    this.service
      .apiV1FeeScheduleDetailsIdGet(fee)
      .pipe(first())
      .subscribe((result) => {
        this.name = result.name;
        this.formGroup.patchValue(result);
        this.entryArray = result.feeScheduleEntries
        this.dataSource = new MatTableDataSource(this.entryArray);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;

        this.dataSource.sortingDataAccessor = (item, property) => {
          switch(property) {
            case 'procedure': return item.procedureCode;
            default: return item[property];
          }
        };
      });
  }

  save(closeWindow: boolean) {
    if (this.formGroup.valid) {
      this.saving = true;

      const formData = {
        name: this.formGroup.get('name').value,
      };

      let saveEntry: Observable<any>;

      let saveObservable: Observable<any>;
      let notification;
      if (this.feeScheduleId) {
        notification = 'Fee Schedule Updated';
        saveObservable = this.service.apiV1FeeSchedulePatchIdPatch(this.feeScheduleId, compare({}, formData)).pipe(
          map((x: any) => {
            return x;
          })
        );
      } else {
        notification = 'Fee Schedule Added';
        saveObservable = this.service.apiV1FeeScheduleAddPost(formData).pipe(
          map((x: any) => {
            return x;
          })
        );
      }

      saveObservable.pipe(take(1)).subscribe(
        (response) => {
          this.saving = false;
          this.feeScheduleId = response.id;
          this.allowAdd = true;
          this.notificationService.success('Fee Schedule Saved');
          if (closeWindow === true) {
            this.dialogRef.close(true);
          }
        },
        (err) => this.notificationService.error('Error Saving Fee Schedule')
      );
    }
  }

  nameChange(event) {
    this.lookup.search('').subscribe((result) => {
      result
        .filter((fee) => fee.label !== this.name)
        .forEach((schedule) => {
          if (schedule?.label?.toLowerCase() === event?.toLowerCase()) {
            this.duplicateName = true;
          }
        });
    });
    this.duplicateName = false
  }

  delete(row) {
    const modalContent: DialogContent = {
      header: '',
      body: `Are you sure you want to delete this fee schedule entry?`,
      cancelButtonText: 'Back',
      OKButtonText: 'Confirm'
    };
    this.dialogService.showConfirmDialog(modalContent).subscribe((result) => {
      if (result) {
        this.service
          .apiV1FeeScheduleEntryIdDelete(row.id)
          .pipe(
            map((x: any) => {
              return x;
            }),
            take(1)
          )
          .subscribe((deleteResult) => {
            this.loadGrid(row.feeScheduleId);
          });
      }
    });
  }
  uploadFeeSchedule() {
    this.openFeeScheduleUploadModal();
  }

  openFeeScheduleUploadModal() {
    const dialog = this.dialog.open(UploadComponent, {
      disableClose: true,
      autoFocus: false,
      data: { feeId: this.feeScheduleId }
    });

    dialog.afterClosed().subscribe((result) => {
      this.loadGrid(result)
    });
  }


  cancel() {
    if (this.data.upload === true) {
      this.service
        .apiV1FeeScheduleIdDelete(this.feeScheduleId)
        .pipe(
          map((x: any) => x),
          take(1)
        )
        .subscribe((deleteResult) => {
          this.dialogRef.close();
        });
    } else {
      this.dialogRef.close();
    }
  }
}
