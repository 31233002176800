import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  ViewChild,
  AfterViewInit,
  ViewEncapsulation,
  OnDestroy,
  HostBinding,
  ChangeDetectorRef
} from '@angular/core';
import { BaseGridSource } from './grid-data-source';
import { GridConfiguration, ColumnDisplayType } from './models';
import { UntypedFormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { merge, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap, first } from 'rxjs/operators';
import { GridService } from './grid.service';
import { Router, ActivatedRoute } from '@angular/router';
import { SelectionModel } from '@angular/cdk/collections';

@Component({
  selector: 'lib-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [GridService]
})
export class GridComponent implements OnInit, AfterViewInit, OnDestroy {
  @HostBinding('class') gridClass = 'lib-grid';
  @HostBinding('class.mat-elevation-z2') get gridElevation() {
    return this.gridConfiguration?.elevation;
  }

  privDataset: BaseGridSource<any>;

  @Input()
  get dataSource(): BaseGridSource<any> {
    return this.privDataset;
  }

  set dataSource(e) {
    this.privDataset = e;
    if (this.skipLoadOnInit) {
    } else this.loadData(true);
  }

  @Input()
  gridConfiguration: GridConfiguration;

  @Input()
  filterStringParameter: string;

  @Input()
  enableFilter: boolean = true;

  @Input()
  clearFilterOnInitialLoad: boolean = false;

  @Input() skipLoadOnInit: string;

  @Input() loadAfterInit: boolean = false;

  @Input()
  pageSizeOptions=[25, 50, 100, 250, 500];

  filterColumns = [];

  filterParams = {};

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  get hasFilters(): boolean {
    return this.gridConfiguration?.columnDefinitions?.some((x) => x.filterable === true) || false;
  }

  columnTypes = ColumnDisplayType;
  selection = new SelectionModel(true, []);

  public filterFormGroup: UntypedFormGroup;
  subscriptions: Subscription = new Subscription();
  constructor(
    public gridService: GridService,
    public router: Router,
    public route: ActivatedRoute,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.filterFormGroup = new UntypedFormGroup({});
  }

  ngAfterViewInit() {
    if (this.loadAfterInit) {
      this.loadData();
      this.cdRef.detectChanges();
    }

    this.paginator?.page.subscribe((x) => {
      sessionStorage.setItem('pageSize', x.pageSize.toString());
      sessionStorage.setItem('pageIndex', x.pageIndex.toString());
    });

    const formSub = this.filterFormGroup.valueChanges
      .pipe(
        debounceTime(750),
        distinctUntilChanged(),
        tap(() => {
          if (this.enableFilter) {
            this.paginator.firstPage();
            this.loadData();
          }
        })
      )
      .subscribe();

    if (formSub) {
      this.subscriptions.add(formSub);
    }

    if (this.sort?.sortChange && this.paginator?.page) {
      // Wires paging and sorting to loading';
      const sortPageSub = merge(this.sort?.sortChange, this.paginator?.page)
        .pipe(
          tap(() => {
            this.loadData();
          })
        )
        .subscribe();
      this.subscriptions.add(sortPageSub);
    }

    if (this.filterStringParameter && this.route.queryParams) {
      setTimeout(() => {
        this.subscriptions.add(
          this.route.queryParams.pipe(first()).subscribe((params) => {
            if (params[this.filterStringParameter]) {
              this.filterFormGroup.patchValue(JSON.parse(atob(decodeURIComponent(params[this.filterStringParameter]))));
            }
          })
        );
      }, 0);
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
  /**
   * Dispatches load events
   *
   * @memberof GridComponent
   */
  async loadData(init: boolean = false) {
    this.selection.clear();
    if (this.dataSource) {
      this.dataSource.load(
        this.gridService.buildPagedModel(this.paginator, this.sort, this.gridConfiguration, this.filterFormGroup)
      );
      if (this.filterStringParameter && this.filterFormGroup) {
        const cleanFilterParams = {};
        Object.keys(this.filterFormGroup.value).forEach((k) => {
          if (this.filterFormGroup.value[k] !== '') {
            if (!(init && this.clearFilterOnInitialLoad)) {
              cleanFilterParams[k] = this.filterFormGroup.value[k];
            } else {
              this.filterFormGroup.controls[k].setValue('');
            }
          }
        });
        await this.router.navigate(['./'], {
          relativeTo: this.route,
          queryParams: {
            [this.filterStringParameter]: encodeURIComponent(btoa(JSON.stringify(cleanFilterParams)))
          }
        });
      }
      localStorage.setItem('filterParams', JSON.stringify(this.filterParams));

    }
  }

  /**
   * Event Fired on row click
   *
   * @param {*} rowValue
   * @memberof GridComponent
   */
  rowClick($event, rowValue) {
    if (this.gridConfiguration?.rowClickEvent) {
      this.gridConfiguration?.rowClickEvent(rowValue);
    }
  }

  toggleRow($event, rowValue) {
    if (this.gridConfiguration?.rowExpandable) {
    }
  }

  cellClick(event: MouseEvent, columnKey: string, rowValue: any) {
    const columnDef = this.gridConfiguration.columnDefinitions.find((x) => x.displayKey === columnKey);
    if (columnDef?.cellClickEvent) {
      event.stopPropagation();
      columnDef.cellClickEvent(rowValue);
    }
  }

  cellActionButtonClick(event: MouseEvent, columnKey: string, rowValue: any) {
    const columnDef = this.gridConfiguration.columnDefinitions.find((x) => x.displayKey === columnKey);
    if (columnDef?.cellActionButton?.clickEvent) {
      event.stopPropagation();
      columnDef?.cellActionButton?.clickEvent(rowValue);
    }
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = !!this.dataSource && this.dataSource.dataSourceSubject.value.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.dataSourceSubject.value.forEach((x) => this.selection.select(x));
  }

  toggleCheckbox(row): void {
    this.selection.toggle(row);
  }

  camelCaseRemoveSpaces(element) {
    return element.replace(/\s/g, '').replace(/^\w/, (c) => c.toLowerCase());
  }

  trackByIndex(index: number, item: any) {
    return index;
  }
  trackById(index: number, item: any) {
    return item.id;
  }
}
