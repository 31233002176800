<mat-expansion-panel [expanded]="isExpanded" displayMode="flat" [class.mat-elevation-z0]="true">
  <mat-expansion-panel-header>
    <div class="lib-collapsible-form__title">
      <div class="lib-collapsible-form__step-icon lib-collapsible-form__step-icon__active">
        <div class="lib-collapsible-form__step-count">
          <span>{{step}}</span>
        </div>
      </div>
      {{title}}
    </div>
  </mat-expansion-panel-header>

  <ng-content></ng-content>

</mat-expansion-panel>
