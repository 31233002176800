/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export type JsonValueKind = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7;

export const JsonValueKind = {
  NUMBER_0: 0 as JsonValueKind,
  NUMBER_1: 1 as JsonValueKind,
  NUMBER_2: 2 as JsonValueKind,
  NUMBER_3: 3 as JsonValueKind,
  NUMBER_4: 4 as JsonValueKind,
  NUMBER_5: 5 as JsonValueKind,
  NUMBER_6: 6 as JsonValueKind,
  NUMBER_7: 7 as JsonValueKind
};
