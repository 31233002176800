import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ARTransactionDetail, ARTransactionDetailsViewModel } from 'data';

interface CustomARTransactionDetail extends ARTransactionDetail{
  arTransaction:ARTransactionDetailsViewModel;
}

@Component({
  selector: 'lib-procedure-activity',
  templateUrl: './procedure-activity.component.html',
  styleUrls: ['./procedure-activity.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProcedureActivityComponent implements OnInit {
  @Input()
  transactions: ARTransactionDetail[];

  constructor() {}

  ngOnInit(): void { }

  trackById(index: number, item: any) {
    return item.id
  }
}
