/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface RuleCardCriteriaDataViewModel {
  exList01?: string | null;
  exId01?: string;
  opList01?: string | null;
  opId01?: string;
  xbText01?: string | null;
  xbNum01?: string | null;
  xbMultiList01?: string | null;
  xbMultiIds01?: string | null;
  xbDataType01?: number;
  exDict01?: { [key: string]: string } | null;
  exList02?: string | null;
  exId02?: string;
  opList02?: string | null;
  opId02?: string;
  xbText02?: string | null;
  xbNum02?: string | null;
  xbMultiList02?: string | null;
  xbMultiIds02?: string | null;
  xbDataType02?: number;
}
