<div class="lib-sidebar">
  <section class="logo-container">
    <img src="./assets/{{ logoFile }}" *ngIf="logoFile" class="logo" alt="Imagine" />
  </section>
  <section class="data-set-selector">
    <lib-data-set-selector
      *ngIf="implementationComplete"
      [dataSets]="availableDataSets"
      [selectedDataSet]="selectedDataSet"
      (optionClicked)="dataSetSelected.emit($event)"
    >
    </lib-data-set-selector>
  </section>
  <section *ngIf="!implementationComplete" class="sidebar-navigation__impl-tracker">
    <div class="sidebar-navigation__impl-tracker-logo">
      <img src="./assets/Implementation_logo.svg" class="logo" alt="Implementation" />
    </div>
    <div>Implementation Tracker</div>
  </section>
  <section class="navigation-container">
    <ng-container [ngTemplateOutlet]="navigation" [ngTemplateOutletContext]="{ configs: mainNavConfig }">
    </ng-container>
  </section>
  <section class="avatar-container">
    <ng-container>
      <div [matMenuTriggerFor]="userMenu" title="{{ username }}">
        <!--<img [src]="userPicture" alt="User" class="avatar"/>-->
        <mat-icon class="mat-icon-person">person</mat-icon>
      </div>
    </ng-container>
    <mat-menu #userMenu="matMenu">
      <button mat-menu-item (click)="updatePassword()">Update Password</button>
      <button mat-menu-item (click)="updateConfigs()">User Configurations</button>
      <button mat-menu-item (click)="logoutClick()">Logout</button>
    </mat-menu>
    <!-- <ng-container [ngTemplateOutlet]="navigation" [ngTemplateOutletContext]="{configs:footerNavConfig}">
    </ng-container> -->
  </section>
</div>

<ng-template #navigation let-configs="configs">
  <nav class="sidebar-navigation">
    <ng-container *ngFor="let config of configs; trackBy: trackByIndex">
      <a
        class="h5"
        [routerLink]="config.routerLink"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: config.exactMatchLink }"
        (click)="resetPageIndex()"
      >
        <div class="nav-icon" *ngIf="config.icon">
          <span class="material-icons">
            {{ config.icon }}
          </span>
        </div>
        <div class="h5">{{ config.title }}</div>
      </a>
    </ng-container>
  </nav>
</ng-template>
