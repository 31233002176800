/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AddProcedureTransactionViewModel } from './addProcedureTransactionViewModel';

export interface AddVisitViewModel {
  datasetId?: string;
  patientId?: string;
  financialClassId?: string;
  accidentTypeId?: string | null;
  accidentStateCode?: string | null;
  illnessInjuryDate?: string | null;
  hospitalAdmitDate?: string | null;
  hospitalDischargeDate?: string | null;
  primaryInsuranceId?: string | null;
  secondaryInsuranceId?: string | null;
  tertiaryInsuranceId?: string | null;
  delinquencyCode?: number;
  inCollectionModule?: boolean;
  isScheduledWriteoff?: boolean;
  holdStatus?: string | null;
  patientStatus?: string | null;
  pwkReportTypeCode?: string | null;
  pwkTransmissionCode?: string | null;
  pwkAttachmentNumber?: string | null;
  primaryInsuranceAuthNumber?: string | null;
  secondaryInsuranceAuthNumber?: string | null;
  tertiaryInsuranceAuthNumber?: string | null;
  pendingCommit?: boolean;
  procedureTransactions?: Array<AddProcedureTransactionViewModel> | null;
}
