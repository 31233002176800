import {
  Component,
  ChangeDetectionStrategy,
  Input,
  OnInit,
  OnDestroy,
  Output,
  EventEmitter,
  QueryList,
  ViewChild,
  ElementRef
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormGroup,
  UntypedFormControl,
  ValidationErrors,
  UntypedFormArray
} from '@angular/forms';
import { Observable, of, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, startWith, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { FieldErrorsDefinition, FieldSelectOption, FormIssue } from '../models';
import { ApiAutoCompleteService } from './api-autocomplete.service';
import * as Masks from '../utils/masks';
import {
  MatSelect,
  MatSelectChange,
  MatSelectTrigger,
  MAT_SELECT_TRIGGER,
} from '@angular/material/select';
import { MatAutocomplete, MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { AfterViewInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ShowDialogComponent } from '../../../core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatOption } from '@angular/material/core';
import { InsuranceLookupService } from '../../../../../../../src/app/features/accounts/services/insurance-lookup.service';
import { MatInput } from '@angular/material/input';
import { SearchWrapperService } from '../../../../../../../src/app/core/services/service-wrappers/search-wrapper.service';
import { setTimeout } from 'timers';
import { ChangeDetectorRef } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipGrid, MatChipInputEvent } from '@angular/material/chips';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

@Component({
  selector: 'lib-form-field',
  templateUrl: './field.component.html',
  styleUrls: ['./field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FieldComponent implements AfterViewInit, OnInit, OnDestroy {
  /**
   * Parent form Group
   *
   * @type {FormGroup}
   * @memberof FieldComponent
   */
  @Input() parent: UntypedFormGroup;

  /**
   * Type of input to be displayed
   *
   * @type {('empty'
   *     | 'text'
   *     | 'email'
   *     | 'number'
   *     | 'password'
   *     | 'phone'
   *     | 'date'
   *     | 'dateRange'
   *     | 'select'
   *     | 'radio'
   *     | 'checkbox'
   *     | 'money'
   *     | 'zipCode'
   *     | 'decimal'
   *     | 'autocomplete'
   *     | 'ignore'
   *     | 'label'
   *     | 'birthDate'
   *     | 'allCaps'
   *     | 'time' )}
   * @memberof FieldComponent
   */

  @Input() type:
    | 'empty'
    | 'text'
    | 'email'
    | 'number'
    | 'password'
    | 'phone'
    | 'date'
    | 'dateRange'
    | 'select'
    | 'radio'
    | 'checkbox'
    | 'money'
    | 'decimal'
    | 'autocomplete'
    | 'zipCode'
    | 'ignore'
    | 'label'
    | 'birthDate'
    | 'allCaps'
    | 'toggle'
    | 'multiSelect'  //updated here to add multiselect option
    | 'time' = 'text';
  /**
   * Required: name of input
   *
   * @type {string}
   * @memberof FieldComponent
   */

  @Input() reloadMulti?: boolean

  @Input() clearButton?: boolean;
  showClear?: boolean;

  @Input() name: string;

  /**
   * Label Text
   *
   * @type {string}
   * @memberof FieldComponent
   */
  @Input() label: string;


  /**
   * Multiselect Label Text
   *
   * @type {string}
   * @memberof FieldComponent
   */
  @Input() matLabel: string;

  /**
   * Custom Display for select boxes
   *
   * @type {string}
   * @memberof FieldComponent
   */
  @Input() customSelectedDisplay?: string;

  /**
 * Initial value to set autopopulate in field
 *
 * @type {any}
 * @memberof FieldComponent
 */
  @Input() initial?: any;

  /**
   * Id for element for autofocus
   *
   * @type {string}
   * @memberof FieldComponent
   */
  @Input() focusId?: string;

  /**
   * Id for element for autofocus
   *
   * @type {boolean}
   * @memberof FieldComponent
   */
  @Input() filteredSearch?: boolean;
  /**
   * Id for element for autofocus
   *
   * @type {string}
   * @memberof FieldComponent
   */
  @Input() filterProperty?: string;
  /**
   * Id for element for autofocus
   *
   * @type {any}
   * @memberof FieldComponent
   */
  @Input() filterObject?: any;

  /**
   * Hint text that appears on the bottom of the input
   *
   * @type {string}
   * @memberof FieldComponent
   */
  @Input() hint?: string;

  /**
   * List of Errors to display
   *
   * @type {FieldErrorsDefinition}
   * @memberof FieldComponent
   */
  @Input() errors?: FieldErrorsDefinition = {};

  /**
   * Array of options for radio buttons and select buttons
   *
   * @type {((string | FieldSelectOption)[])}
   * @memberof FieldComponent
   */
  @Input() options?: (string | FieldSelectOption)[] = [];

  /**
   * Empty display Option
   *
   * @type {string}
   * @memberof FieldComponent
   */
  @Input() emptyOption?: string;

  /**
   * Placeholder text
   *
   * @type {string}
   * @memberof FieldComponent
   */
  @Input() placeholder: string;

  /**
   * Custom error message
   *
   * @type {string}
   * @memberof FieldComponent
   */
  @Input() customErrorMessage?: string;

  /**
   * tells component whether or not to send input for select or autocomplete
   *
   * @type {boolean}
   * @memberof FieldComponent
   */

  @Input() getInput?: boolean;

  /**
   * tells component whether or not to send control for select or autocomplete
   *
   * @type {boolean}
   * @memberof FieldComponent
   */
  @Input() getControl?: boolean;

  @Input() initialSelect?: any;
  /**
   * tells component which filter criteria a developer would like to supply search api
   *
   * @type {boolean}
   * @memberof FieldComponent
   */

  _initialMulti: any[] = null;
  @Input() get initialMulti(): any[] {
    return this._initialMulti;
  }
  set initialMulti(val: any[]) {
    this._initialMulti = val;
    if (val != undefined) {
      this.initializeMultiselect();
    }
  }

  /**
   * tells component which filter criteria a developer would like to supply search api
   *
   * @type {any[]}
   * @memberof FieldComponent
   */

  /**
   * Allow multiple selections in select
   *
   * @type {boolean}
   * @memberof FieldComponent
   */
  @Input() multiple?: boolean;

  /**
   * Is Field Read Only
   *
   * @type {boolean}
   * @memberof FieldComponent
   */
  @Input() isReadOnly?: boolean;

  /**
   * Does Field Have Delete Button
   *
   * @type {boolean}
   * @memberof FieldComponent
   */
  @Input() isDelete?: boolean;

  /**
   * Is Field Disabled
   *
   * @type {boolean}
   * @memberof FieldComponent
   */
  @Input() disabled?: boolean;

  /**
 * Is Field Visible
 *
 * @type {boolean}
 * @memberof FieldComponent
 */
  @Input() showIf?: boolean;

  /**
   * Min Number or min date
   *
   * @type {(number | Date)}
   * @memberof FieldComponent
   */

  @Input() min?: number | Date;

  /**
   * Max number or max date
   *
   * @type {(number | Date)}
   * @memberof FieldComponent
   */
  @Input() max?: number | Date;

  /**
 * Max number or max date
 *
 * @type string
 * @memberof FieldComponent
 */
  @Input() minText?: string;

  /**
 * Max number or max date
 *
 * @type string
 * @memberof FieldComponent
 */
  @Input() maxText?: string;

  /**
   * Allowed number of decimal places
   *
   * @type {number}
   * @memberof FieldComponent
   */
  @Input() decimalCount?: number;

  /**
   *
   *
   * @type {FormIssue}
   * @memberof FieldComponent
   */
  @Input() fieldIssues?: FormIssue;

  /**
   *
   *
   * @type {ApiAutoCompleteService}
   * @memberof FieldComponent
   */
  @Input() apiService: ApiAutoCompleteService;

  @Input() observable: Observable<FieldSelectOption[]>;

  @Input() i: number;

  @Input() customRequiredMessage: string;

  @Input() checkedText: string;
  @Input() uncheckedText: string;

  @Input() checked: boolean;
  @Input() stacked: boolean;
  @Input() hidden: boolean;

  @Input() ididx?: string = '';

  @Input() lookup?: Observable<FieldSelectOption[]>;

  /**
   *
   *
   * @type {[]}
   * @memberof FieldComponent
   */
  @Input() array: [];

  /**
   *
   *
   * @type {number}
   * @memberof FieldComponent
   */
  @Input() minimum?: number;

  /**
   * Allow to exclude "Select All" option in multi-select configuration
   *
   * @type {boolean}
   * @memberof FieldComponent
   */
  @Input() excludeSelectAllOption?: boolean;

  /**
   * Allow to exclude "System" option in multi-select configuration
   *
   * @type {boolean}
   * @memberof FieldComponent
   */
  @Input() includeSystemOption?: boolean;

  /**
 * Allow to skip initial, unnecessary load for autocomplete
 *
 * @type {boolean}
 * @memberof FieldComponent
 */
  @Input() autoCompleteSkipInitial?: boolean;

    /**
 * Reload autocomplete options when form is reset
 *
 * @type {boolean}
 * @memberof FieldComponent
 */
    @Input() resetOnClear?: boolean;

  /**
   * Selection field output
   *
   * @type {EventEmitter<any>}
   * @memberof FieldComponent
   */
  @Output() selectionChanged: EventEmitter<any> = new EventEmitter<any>();

  /**
   * Event when user manually select
   *
   * @type {EventEmitter<any>}
   * @memberof FieldComponent
   */
  @Output() userSelectionChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() checkedChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() toggleChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() autoCompleteFilterChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() datepickerChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() daterangeStartChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() daterangeEndChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() rangePickerClosed: EventEmitter<any> = new EventEmitter<any>();
  @Output() diagArray: EventEmitter<any> = new EventEmitter<any>();
  @Output() autoCompleteEmpty: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectInput: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectControl: EventEmitter<any> = new EventEmitter<any>();
  @Output() autoControl: EventEmitter<any> = new EventEmitter<any>();
  @Output() autoComplete: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectOptions: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild(MatSelect) public select: MatSelect;
  @ViewChild('auto') public auto: MatAutocomplete;
  @ViewChild('chip') public chip: MatChipGrid;
  public availableErrors: FieldErrorsDefinition;
  public availableOptions: (string | FieldSelectOption)[] = [];
  public filteredOptions: Observable<any[]>;
  public changeSubscriptions: Subscription;
  patient: boolean;
  allOptions = [];
  allOpt = [];
  autocompleteControl = new UntypedFormControl('');
  multiSelectControl = new UntypedFormControl('');
  hide: boolean = true;
  selectable = true;
  removable = true;
  opened = false;
  option;
  sel = false;
  dateEmpty = true;
  selectedOptions = [];
  @ViewChild('multiSelectInput') multiSelectInput: ElementRef<HTMLInputElement>;//multiselect input field
  @ViewChild('autoMulti') matAutocomplete: MatAutocomplete; //multiselect autocomplete
  @ViewChild('matACTrigger') matACTrigger: MatAutocompleteTrigger; //multi select matACTrigger
  separatorKeysCodes: number[] = [ENTER, COMMA];
  constructor(private dialog: MatDialog, private searchService: SearchWrapperService) {
  }
  ngAfterViewInit() {
    if (this.getControl) {
      this.autoControl.emit(this.autocompleteControl)
    }
    //allows autocomplete field to patch automatically with lib-form component
    if (this.initial) {
      this.auto.optionSelected.emit({ source: this.auto, option: this.auto.options.find((o) => o.value.value === this.initial) })
    }
    if (this.type === 'autocomplete' && this.auto !== undefined) {

      this.autoComplete.emit(this.auto);

    }

    if (this.initialMulti) {
      if (this.initialMulti !== null) {
        this.selectedOptions = this.allOptions.filter((opt) => this.initialMulti.some((o) => o === opt.value))
      }
      else {
        this.selectedOptions = []
      }

    }

  }
  ngOnInit() {
    this.availableErrors = Object.assign(
      {
        min: `Must be greater than ${this.minText ?? this.customErrorMessage}`,
        max: `Must be less than ${this.maxText ?? this.customErrorMessage}`,
        required: `${this.label ?? 'Value'} is <strong>required</strong>`,
        invalid: `${this.label ?? 'Value'} is <strong>invalid</strong>`,
        duplicateName: 'Name already exists',
        phone: 'Phone number is <strong>invalid</strong>',
        email: 'Email address is <strong>invalid</strong>',
        notDecimal: 'Please use a valid decimal',
        notInteger: 'Please use a valid integer',
        ssn: 'SSN must be 9 digits',
        zipPattern: 'Zip code is <strong>invalid</strong>',
        extendedZipPattern: 'Must be Zip + 4',
        invalidDate: 'Date is <strong>invalid</strong>',
        listenAtIp: 'IP Address is <strong>invalid</strong>',
        passwordMismatch: 'Does not match existing password',
        addressProvider: 'Provider address cannot be PO Box',
        locationZip: 'Last 4 of zip is <strong>invalid</strong>',
        greaterDate: `Date cannot come before ${this.customErrorMessage}`,
        PriorDateNotAllowed: 'No past dates are allowed',
        zipLength: 'Zip code must be 9 digits',
        taxonomyPattern: 'Invalid taxonomy',
        taxonomyLength: 'Must be 10 characters',
        listenAtPort: 'Port is <strong>invalid</strong>',
        nonNull: `This code is <strong>required</strong>.`,
        nonNumber: 'Must be numbers only.',
        invalidAcronym: 'This acronym already exists',
        invalidPassword: 'Password is <strong>invalid</strong>',
        noAsterisks: 'Asterisks are not allowed',
        batchPosted: 'This batch has already been posted.',
        customError: `${this.customErrorMessage}`,
        maxlength: `Exceeds character limit for field`,
        exceedsMaxInt: `Exceeds maximum value for field`,
        startsWithSpace: `Name <strong>cannot start with a space</strong>`
      },
      this.errors
    );

    if (this.showIf === false) {
      this.hidden = true;
    }

    if (this.type === 'select' || this.type === 'radio' || this.type === 'autocomplete') {
      this.availableOptions = this.options?.map((opt) =>
        typeof opt === 'string'
          ? {
            value: opt,
            label: opt
          }
          : opt
      );
    }
    if (this.type === 'select') {
      if (this.observable) {
        this.observable.subscribe((items) => {
          this.availableOptions = items;
          const prevValue = this.parent.controls[this.name]?.value;
          // Reset value to retrigger ui
          this.parent.controls[this.name].setValue(prevValue);
        });
      }
      if (this.apiService) {
        this.apiService.search('').subscribe((items) => {
          this.availableOptions = items;
          const prevValue = this.parent.controls[this.name]?.value;
          // Reset value to retrigger ui
          this.parent.controls[this.name].setValue(prevValue);
        });
      }
      if (this.array) {
        this.availableOptions = this.array;
      }

      if (this.lookup) {
        this.lookup.subscribe((items) => {
          this.availableOptions = items;
          const prevValue = this.parent.controls[this.name]?.value;
          // Reset value to retrigger ui
          this.parent.controls[this.name].setValue(prevValue);
        });
      }
    } else if (this.type === 'autocomplete') {
      if (this.apiService) {
        this.autocompleteControl.valueChanges.subscribe((value) => {
          if (value === '') {
            this.autoCompleteEmpty.emit(true);
            if (this.isRequired === true) {
              this.parent.setErrors({ required: true });
            }
          } else {
            this.autoCompleteEmpty.emit(false);
          }
        });
        const loadValue = (x) => {
          this.apiService
            .load(x)
            .pipe(take(1))
            .subscribe((item) => {
              this.selectionChanged.emit(item);
              this.autocompleteControl.setValue(item, { emitEvent: false });
            });
        };
        const loadedFilteredValue = (x) => {
          this.apiService
            .filteredSearch(x, this.filterProperty, this.filterObject)
            .pipe(take(1))
            .subscribe((item) => {
              this.selectionChanged.emit(item);
              this.autocompleteControl.setValue(item, { emitEvent: false });
            });
        };
        const controlValue = this.parent.controls[this.name].value;
        // Applies initial value to the DDL
        if (controlValue && controlValue !== '' && controlValue !== null) {
          loadValue(controlValue);
        }
        // Applies value if changed outside of app
        this.changeSubscriptions = this.parent.controls[this.name].valueChanges.subscribe((x) => {
          if (this.hasValue(x)) {
            loadValue(x);
          } else {
            // Sets autocomplete to empty
            this.autocompleteControl.setValue('', { emitEvent: false });
            if (this.resetOnClear) {
              this.filteredOptions = this.autocompleteControl.valueChanges.pipe(
                startWith(''),
                filter((value) => typeof value === 'string'), // Only filter user input strings
                debounceTime(400),
                distinctUntilChanged(),
                switchMap((value) => this._filter(value))
              );
            }
          }
        });

        if (!this.autoCompleteSkipInitial) {
          this.filteredOptions = this.autocompleteControl.valueChanges.pipe(
            startWith(''),
            filter((value) => typeof value === 'string'), // Only filter user input strings
            debounceTime(400),
            distinctUntilChanged(),
            switchMap((value) => this._filter(value))
          );
        }
      }

      if (this.array) {
        this.changeSubscriptions = this.parent.controls[this.name].valueChanges.subscribe((y) => {
          if (this.hasValue(y)) {
            var item = this.options
              .filter((option) => !!option)
              .map((opt) => (typeof opt === 'string' ? { value: opt, label: opt } : opt))
              .filter((option) => option.value === y)[0];
            this.autocompleteControl.setValue(item, { emitEvent: false });
          } else {
            this.autocompleteControl.setValue('', { emitEvent: false });
          }
        });
        this.options = this.array;
        this.availableOptions = this.array;
        this.filteredOptions = this._filter('');
        const controlValue = this.parent.controls[this.name].value;
        // Applies initial value to the DDL
        if (controlValue && controlValue !== '' && controlValue !== null) {
          var item = this.options
            .filter((option) => !!option)
            .map((opt) => (typeof opt === 'string' ? { value: opt, label: opt } : opt))
            .filter((option) => option.value === controlValue)[0];
          this.autocompleteControl.setValue(item, { emitEvent: false });
        }
      }
    } else if (this.type === 'text' || this.type === 'number') {
      if (this.observable && this.initialSelect) {
        this.observable.subscribe((items: FieldSelectOption[]) => {
          this.parent?.controls[this.name].patchValue(
            items.filter((item: FieldSelectOption) => {
              return item.value === this.initialSelect;
            })[0]?.label
          );
        });
      }
      this.parent?.controls[this.name].valueChanges.subscribe((value) => {
        this.selectionChanged.emit(value);
      });
    }
    else if (this.type === 'multiSelect') {
      //mimic autocomplete behavior for multiSelect field
      if (this.apiService || this.array) {
        this.initializeMultiselect();
      }
      this.filteredOptions = this.multiSelectControl.valueChanges.pipe(
        startWith(''),
        filter((value) => typeof value === 'string'), // Only filter user input strings
        debounceTime(400),
        distinctUntilChanged(),
        switchMap((value) => this._filtered(value))
      );
      this.parent.controls[this.name].statusChanges.subscribe((value) => {
        var isItDisabled = this.parent.controls[this.name].disabled;
        if (isItDisabled) {
          this.selectedOptions = [];
          this.chip.disabled = true;
          this.chip.focus();
        } else {
          this.chip.disabled = false;
        }

      })
    }
    if (this.reloadMulti) {
      this.selected(null)
    }
    else if (this.type === 'allCaps') {
      this.parent?.controls[this.name].valueChanges.subscribe((value) => {
        this.parent.controls[this.name].setValue(value?.toUpperCase(), { emitEvent: false });
      });
    } else if (this.type === 'phone') {
      this.changeSubscriptions = this.parent.controls[this.name].valueChanges.subscribe((value) => {
        this.parent.controls[this.name].setValue(Masks.phone(value), { emitEvent: false });
      });
    } else if (this.type === 'zipCode') {
      this.changeSubscriptions = this.parent.controls[this.name]?.valueChanges?.subscribe((value) => {
        this.parent.controls[this.name].setValue(Masks.zipCode(value), { emitEvent: false });
      });
    }
    else if (this.type === 'dateRange') {
      let x = this.name;
      this.changeSubscriptions = this.parent.controls[this.name + 'Start']?.valueChanges.subscribe((value) => {
        this.dateEmpty = false;
      });
    }
    else if (this.type === 'birthDate') {
      this.changeSubscriptions = this.parent.controls[this.name]?.valueChanges.subscribe((value) => {
        this.parent.controls[this.name].setValue(Masks.birthDate(value), { emitEvent: false });
        let birthDateValue = this.parent.controls[this.name];
        let bDate = birthDateValue.value;
        let regex = new RegExp(/^(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d$/g);
        if (regex.test(bDate)) {
          let birthDate = new Date(bDate);
          if (birthDate > new Date(Date.now())) {
            const dialog = this.dialog.open(ShowDialogComponent, {
              disableClose: true,
              autoFocus: false,
              data: {
                header: 'Please Confirm',
                body: 'You have entered a date of birth in the future. Do you wish to proceed?',
                cancelButtonText: 'Cancel',
                OKButtonText: 'OK'
              }
            });
            dialog.afterClosed().subscribe((result) => {
              if (result === false) {
                birthDateValue.setValue(null);
              }
            });
          }
        }
      });
    } else if (this.type === 'date' && (this.name === 'birthDate' || this.name === 'insuredBirthDate')) {
      let birthDateValue = this.parent.controls[this.name];
      birthDateValue.valueChanges.subscribe((birthDate) => {
        if (birthDate > new Date(Date.now())) {
          const dialog = this.dialog.open(ShowDialogComponent, {
            disableClose: true,
            autoFocus: false,
            data: {
              header: 'Please Confirm',
              body: 'You have entered a date of birth in the future. Do you wish to proceed?',
              cancelButtonText: 'Cancel',
              OKButtonText: 'OK'
            }
          });
          dialog.afterClosed().subscribe((result) => {
            if (result === false) {
              birthDateValue.setValue(null);
            }
          });
        }
      });
    }

    if (this.clearButton === true) {
      this.parent?.controls[this.name].valueChanges.subscribe((value) => {
        if (this.hasValue(value)) {
          this.showClear = true;
        }
        else {
          this.showClear = false;
        }
      });
    }
  }

  autoCompleteUpdate(event) {
    if (event.type === 'blur' && typeof this.autocompleteControl?.value === 'string') {
      this.dropdownChanged(this.autocompleteControl.value);
    }
  }

  dropdownChanged(value: string) {
    var matchedItem;
    if (this.apiService !== undefined) {
      this.apiService.search('').subscribe((result) => {
        result.forEach((item) => {
          if (item.shortLabel?.toString().toUpperCase() === value.toUpperCase() || item.label?.toUpperCase() === value.toUpperCase()) {
            var matchedItem = item;
            // Apply to hidden input
            const selectedValue = matchedItem?.value || matchedItem;
            this.parent.controls[this.name].patchValue(selectedValue, {
              emitEvent: true
            });
            this.parent.patchValue({});
            this.selectionChanged.emit(matchedItem);
            this.userSelectionChanged.emit(matchedItem);
          }
        });
        if (matchedItem === null || matchedItem === undefined) {
          this.autocompleteControl.setValue(null);
        }
      });
    }
  }

  clear() {
    this.parent.controls[this.name].setValue(null);
    if (this.type === 'autocomplete') {
      this.filteredOptions = null;
      this.filteredOptions = this._filter('');
      this.userSelectionChanged.emit(null);
    }
  }

  clearDateRange() {
    this.parent.controls[this.name + 'Start'].setValue(null);
    this.parent.controls[this.name + 'End'].setValue(null);
    this.dateEmpty = true;
  }

  ngOnDestroy() {
    if (this.changeSubscriptions) {
      this.changeSubscriptions.unsubscribe();
    }
  }
  private _filtered(value: string): Observable<FieldSelectOption[]> {
    const filterValue = (value || '').toString().toLowerCase();

    if (this.apiService?.dynamicSearch && filterValue != "") {
      return this.apiService.search(filterValue).pipe(take(1), tap((x) => this.allOptions = x));
    }
    else {
      if (filterValue === '') {
        return of(this.allOptions)
      }
      else {
        return of(this.allOptions.filter(option => option.label.toLowerCase().indexOf(filterValue) >= 0));
      }
    }
  }

  private _filter(value: string): Observable<FieldSelectOption[]> {
    const filterValue = (value || '').toString().toLowerCase();

    if (this.apiService == null && this.options) {
      this.availableOptions = this.options
        .filter((x) => !!x)
        .map((opt) =>
          typeof opt === 'string'
            ? {
              value: opt,
              label: opt
            }
            : opt
        );
    }

    if (this.availableOptions?.length > 0) {
      if (filterValue == '') {
        return of(this.options as FieldSelectOption[]);
      }
      return of(
        (this.availableOptions as FieldSelectOption[]).filter(({ label, code }) => label.toLowerCase().includes(filterValue) || code.toLowerCase().includes(filterValue))
      );
    }

    if (this.apiService) {
      if (this.filteredSearch === false || this.filteredSearch === undefined || this.filteredSearch === null) {
        return this.apiService.search(value);
      } else {
        return this.apiService.filteredSearch(value, this.filterProperty, this.filterObject);
      }
    }

    return of([]);
  }
  public displayProperty(value) {
    if (value?.value !== '' || value !== '') {
      if (value?.code) {
        return value.code + ' - ' + value.label
      } else {
        return value?.label || value;
      }
    } else {
      return '';
    }
  }

  selectSelectionChanged(event: MatSelectChange) {
    this.selectionChanged.emit(event);
  }

  selectUpdate(event, value) {
    this.selectInput.emit(value);
    this.selectControl.emit(this.control);
  }

  datepickerDateChange(event: MatDatepickerInputEvent<Date>) {
    this.datepickerChanged.emit(event);
  }

  autocompleteSelected(event: MatAutocompleteSelectedEvent) {
    const selectedItem = event?.option?.value;
    // Apply to hidden input
    const selectedValue = selectedItem?.value || selectedItem;
    this.parent.controls[this.name].patchValue(selectedValue, {
      // TODO:  Need to figure out how to handle this for value changes
      emitEvent: true
    });
    this.parent.patchValue({});
    this.selectionChanged.emit(selectedItem);
    this.userSelectionChanged.emit(selectedItem);
  }

  checkboxChanged(event: MatCheckboxChange) {
    this.checked = event.checked
    this.checkedChanged.emit(event);
  }

  slideToggleChanged(event: MatSlideToggleChange) {
    this.checked = event.checked;
    this.toggleChanged.emit(event);
  }

  dateRangeChanged(event: MatDatepickerInputEvent<any, any>, startEnd: string) {
    if (this.parent.controls[this.name + 'Start'].value === null) {
      this.dateEmpty = true
    }
    if (event.value) {
      this.dateEmpty = false;
      if (startEnd === 'start') this.daterangeStartChanged.emit(event);
      else this.daterangeEndChanged.emit(event);
    }
  }

  pickerClosed($event) {
    this.rangePickerClosed.emit($event);
  }

  get isRequired(): boolean {
    if (!this.parent?.controls?.[this.name]) {
      return false;
    }
    const controlValidator = this.parent.controls[this.name].validator;
    return typeof controlValidator === 'function' && controlValidator({} as AbstractControl)?.required;
  }

  get currentErrors(): string[] {
    const customErrors: ValidationErrors = {};
    const isDirty = this.parent.controls[this.name]?.dirty;
    if (this.fieldIssues?.missing && !isDirty) {
      customErrors.required = true;
    }
    if (this.fieldIssues?.invalid && !isDirty) {
      customErrors.invalid = true;
    }
    if (Object.keys(customErrors).length > 0) {
      const updatedErrors = {
        ...customErrors,
        ...this.parent?.get(this.name)?.errors
      };
      this.parent?.get(this.name)?.setErrors(updatedErrors, { emitEvent: false });
    }

    let dateRangeErrors = {};
    let dateRangeStatusInvalid = false;
    if (this.type == "dateRange") {
      dateRangeErrors = this.parent?.get(this.name + 'Start')?.errors || this.parent?.get(this.name + 'End')?.errors;
      dateRangeStatusInvalid = this.parent?.get(this.name + 'Start')?.status === 'INVALID' || this.parent?.get(this.name + 'End')?.status === 'INVALID';
    }

    return Object.keys(this.parent?.get(this.name)?.errors || dateRangeErrors || {})
      .filter(() => this.parent?.get(this.name)?.status === 'INVALID' || dateRangeStatusInvalid)
      .map((code) => this.availableErrors[code] || `<strong>${code}</strong> error`);
  }
  get datePickerId(): string | undefined {
    return `picker_${this.name}`;
  }

  get control(): UntypedFormControl {
    return this.parent?.controls?.[this.name] as UntypedFormControl;
  }

  get classes(): string {
    const control = this.control;
    const isDirty = this.parent?.controls?.[this.name]?.dirty;
    return [
      'lib-form-field',
      `lib-form-field-${this.type}`,
      isDirty && control && control.valid && control.value && 'valid'
    ].join(' ');
  }

  get fieldIssuesJSON(): string {
    return JSON.stringify({
      fieldIssues: this.fieldIssues,
      errors: this.parent?.get(this.name)?.errors,
      state: {
        touched: this.parent?.get(this.name)?.touched,
        dirty: this.parent?.get(this.name)?.dirty
      }
    });
  }

  private hasValue(x) {
    return x !== undefined && x !== null && x !== '';
  }
  passwordToggle() {
    this.hide = !this.hide;
  }

  autoCompleteFilterChange(event: Event) {
    this.autoCompleteFilterChanged.emit(event);
  }

  autoCompleteKeyup(event: any) {
    //show all available options on arrow down when value is blank
    if (event.key == 'ArrowDown' && this.autocompleteControl.value === '') {
      this.filteredOptions = null;
      this.filteredOptions = this._filter('');
    }

    if (this.options) {
      this.filteredOptions = null;
      this.filteredOptions = this._filter(this.autocompleteControl.value);
    }
  }

  multipleSelectAll(event: any, fieldType?: string) {

    if (fieldType === 'select') {
      const options: QueryList<MatOption> = this.select.options
      options.forEach((o, i) => {
        if (event.checked) o.select()
        else o.deselect()

      })
    }
    else {
      if (event.checked) {
        this.selectedOptions = this.allOptions
      }
      else {
        this.selectedOptions = []
      }
      if (this.selectedOptions.length > 0) {
        this.placeholder = ''
      }
      else {
        this.placeholder = this.label
      }
      this.selectOptions.emit(this.selectedOptions)
    }

  }

  selectAll(event) {
    if (event.checked) {
      this.selectedOptions = this.allOptions
    }
    else {
      this.selectedOptions = []
    }
    if (this.selectedOptions.length > 0) {
      this.placeholder = ''
    }
    else {
      this.placeholder = this.label
    }
  }

  //removes option when deselected by user
  remove(option: FieldSelectOption): void {
    const index = this.selectedOptions.indexOf(option);
    if (index >= 0) {
      this.selectedOptions = this.selectedOptions.filter(opt => opt.value !== option.value);
    }
    if (this.selectedOptions.length > 0) {
      this.placeholder = ''
    }
    else {
      this.placeholder = this.label
    }
    this.selectOptions.emit(this.selectedOptions)
  }

  //method triggered when user makes selection within drop down
  selected(event: MatAutocompleteSelectedEvent | string, special: string = ""): void {

    let option: MatOption<any>;

    if (special == 'System') {
      option = this.allOptions.find((x: FieldSelectOption) => x.label === 'System')
    }
    else if (typeof (event) !== 'string') {
      option = this.allOptions.find((x: FieldSelectOption) => x.value === event.option.value.value)
    }
    else {
      option = this.allOptions.find((x: FieldSelectOption) => x.value === event)
    }

    if (this.selectedOptions.includes(option)) {
      const index = this.selectedOptions.indexOf(option);
      this.selectedOptions = this.selectedOptions.filter(opt => opt.value !== option.value);
    } else {
      this.selectedOptions.push(option);
    }

    if (this.selectedOptions.length > 0) {
      this.placeholder = ''
    }
    else {
      this.placeholder = this.label
    }

    this.selectOptions.emit(this.selectedOptions)
    this.multiSelectInput.nativeElement.value = '';
    this.multiSelectControl.setValue(null);
    // keep the autocomplete opened after each item is picked.
    if (typeof (event) !== 'string') {
      requestAnimationFrame(() => {
        this.openAuto(this.matACTrigger)
      })
    }
  }

  initializeMultiselect() {
    if (this.apiService?.dynamicSearch && this.initialMulti && Array.isArray(this.initialMulti)) {
      this.apiService.loadByIds(this.initialMulti).subscribe((x) => {
        this.allOptions = [];
        this.selectedOptions = [];

        x.forEach((code: any) => {
          this.allOptions.push({
            label: code.label,
            value: code.value,
            shortLabel: code?.shortLabel ?? code.label
          });
        });

        if (this.includeSystemOption) {
          this.allOptions.push({
            label: 'System',
            value: '00000000-0000-0000-0000-000000000000',
            shortLabel: 'System'
          })
        }

        this.selectedOptions = this.allOptions;
        this.selectOptions.emit(this.selectedOptions);
      })
    }
    else {
      if (this.apiService) {
        this.apiService.search('').subscribe((x) => {
          this.selectedOptions = [];
          this.allOptions = [];

          x.forEach((code: any) => {

            this.allOptions.push({
              label: code.label,
              value: code.value,
              shortLabel: code?.shortLabel ?? code.label
            });

            this.filteredOptions = of(this.allOptions)

          });

          if (this.includeSystemOption) {
            this.allOptions.push({
              label: 'System',
              value: '00000000-0000-0000-0000-000000000000',
              shortLabel: 'System'
            })
          }

          this.filteredOptions = this.multiSelectControl.valueChanges.pipe(
            startWith(''),
            filter((value) => typeof value === 'string'), // Only filter user input strings
            debounceTime(400),
            distinctUntilChanged(),
            switchMap((value) => this._filtered(value))
          );
          if (this.initialMulti && Array.isArray(this.initialMulti)) {
            this.selectedOptions = this.allOptions.filter((opt) => this.initialMulti.some((o) => o === opt.value));
            this.selectOptions.emit(this.selectedOptions);
          }
        });
      }
    }
  }

  //open multiSelect dropdown menu
  openAuto(trigger: MatAutocompleteTrigger) {
    //or issue here
    trigger?.openPanel();

    this.multiSelectInput.nativeElement.focus();
  }

  trackByValue(index: number, item: any) {
    return item?.value;
  }

  trackByIndex(index: number, item: any) {
    return index;
  }
}
