import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShowDialogComponent } from './show-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MaterialModule } from '../../shared/modules';
import { SharedModule } from "../../shared/shared.module";

@NgModule({
    declarations: [ShowDialogComponent],
    exports: [ShowDialogComponent],
    imports: [CommonModule, MatDialogModule, MaterialModule, SharedModule]
})
export class ShowDialogModule {}
