import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ImplementationWrapperService } from '@core/services/service-wrappers/implementation-wrapper.service';
import * as ImplementationActions from '../state/implementation.actions';
import { ImplementationState } from '../state/implementation.state';
import { ImplementationViewModel } from 'data';

@Injectable({
  providedIn: 'root'
})
export class ImplementationGuard  {
  constructor(private service: ImplementationWrapperService, private router: Router, private store: Store<ImplementationState>) {
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree 
  {
      const urlTree : UrlTree = this.router.parseUrl('/implementation');
      let url = route.url;
      let i: ImplementationViewModel;

      return this.service.apiV1ImplementationGet().pipe(
        map(i =>
        {
          this.store.dispatch(ImplementationActions.loadImplementationSuccess({ implementation: i }));
          if (i.status != null && i.status === "Complete") return true; 
          else if (url.toString().startsWith('/implementation')) { return true; }
          else { return urlTree; } 
        }));
  }
}
