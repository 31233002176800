/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface OutgoingStatementViewModel {
  patientId?: string;
  accountNumber?: string | null;
  lastName?: string | null;
  firstName?: string | null;
  locationCode?: string | null;
  statementId?: string | null;
  totalBalance?: number;
  lastStatementDate?: string | null;
}
