/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { VisitPaymentLineItemViewModel } from './visitPaymentLineItemViewModel';
import { FinancialClassViewModel } from './financialClassViewModel';
import { CodeViewModel } from './codeViewModel';
import { InsuranceViewModel } from './insuranceViewModel';
import { PatientViewModel } from './patientViewModel';
import { GuarantorDetailsViewModel } from './guarantorDetailsViewModel';

export interface VisitPaymentDetailsViewModel {
  patient?: PatientViewModel;
  guarantor?: GuarantorDetailsViewModel;
  primaryInsurance?: InsuranceViewModel;
  secondaryInsurance?: InsuranceViewModel;
  tertiaryInsurance?: InsuranceViewModel;
  serviceDate?: string | null;
  transactionDate?: string | null;
  accountNumber?: number;
  procedureAmount?: number;
  paymentAmount?: number;
  adjustmentAmount?: number;
  adjustment2Amount?: number;
  balance?: number;
  lineItems?: Array<VisitPaymentLineItemViewModel> | null;
  id?: string;
  datasetId?: string;
  patientId?: string;
  referenceNumber?: number | null;
  insuranceAuthNumber?: string | null;
  delinquencyCode?: number;
  inCollectionModule?: boolean;
  isScheduledWriteoff?: boolean;
  holdStatus?: string | null;
  claimStatus?: string | null;
  claimStatusDescription?: string | null;
  financialClassId?: string;
  financialClass?: FinancialClassViewModel;
  accidentTypeId?: string | null;
  accidentType?: CodeViewModel;
  insuranceGroup?: string | null;
  createdBy?: string | null;
  createdDate?: string;
  updatedBy?: string | null;
  updatedDate?: string | null;
  pwkReportTypeCode?: string | null;
  pwkTransmissionCode?: string | null;
  pwkAttachmentNumber?: string | null;
  pendingCommit?: boolean;
  hasActivePaymentPlan?: boolean;
  isMerged?: boolean;
}
