import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { DataSet } from './data-set.state';

export const selectDataSet = createAction('[DataSets] Select User', props<{ dataSetId: string }>());
export const loadDataSets = createAction('[DataSets] Load DataSets');
export const loadDataSetsSuccess = createAction(
  '[DataSets] Load Users Clients Success',
  props<{ dataSets: DataSet[] }>()
);
export const loadDataSetsFailure = createAction('[DataSets] Load Users Clients Error', props<{ error: any }>());
