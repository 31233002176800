/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Insurance Carrier view model returned from GetInsuranceCarriers
 */
export interface InsuranceCarrierIntegrationViewModel {
  datasetId?: string;
  carrierCode?: string | null;
  carrierName?: string | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  stateCode?: string | null;
  zip?: string | null;
  countryCode?: string | null;
  phoneNumber?: string | null;
  payerId?: string | null;
  financialClassCode?: string | null;
  financialClassName?: string | null;
}
