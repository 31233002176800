export const pwkReportTypeCodes = [
{
    label: '',
    value: ''
},
{
    label: 'Report Justifying Treatment',
    value: '03'
},
{
    value: '04',
    label: 'Drugs Administered'
},
{
    value: '05',
    label: 'Treatment Diagnosis'
},
{
    value: '06',
    label: 'Initial Assessment'
},
{
    value: '07',
    label: 'Functional Goals'
},
{
    value: '08',
    label: 'Plan of Treatment'
},
{
    value: '09',
    label: 'Progress Report'
},
{
    value: '10',
    label: 'Continued Treatment'
},
{
    value: '11',
    label: 'Chemical Analysis'
},
{
    value: '13',
    label: 'Certified Test Report'
},
{
    value: '15',
    label: 'Justification for Admission'
},
{
    value: '21',
    label: 'Recovery Plan'
},
{
    value: 'A3',
    label: 'Allergies/Sensitivities Document'
},
{
    value: 'A4',
    label: 'Autopsy Report'
},
{
    value: 'AM',
    label: 'Ambulance Certification'
},
{
    value: 'AS',
    label: 'Admission Summary'
},
{
    value: 'B2',
    label: 'Prescription'
},
{
    value: 'B3',
    label: 'Physician Order'
},
{
    value: 'B4',
    label: 'Referral Form'
},
{
    value: 'BR',
    label: 'Benchmark Testing Results'
},
{
    value: 'BS',
    label: 'Baseline'
},
{
    value: 'BT',
    label: 'Blanket Test Results'
},
{
    value: 'CB',
    label: 'Chiropractic Justification'
},
{
    value: 'CK',
    label: 'Consent Form(s)'
},
{
    value: 'CT',
    label: 'Certification'
},
{
    value: 'D2',
    label: 'Drug Profile Document'
},
{
    value: 'DA',
    label: 'Dental Models'
},
{
    value: 'DB',
    label: 'Durable Medical Equipment Prescription'
},
{
    value: 'DG',
    label: 'Diagnostic Report'
},
{
    value: 'DJ',
    label: 'Discharge Monitoring Report'
},
{
    value: 'DS',
    label: 'Discharge Summary'
},
{
    value: 'EB',
    label: 'Explanation of Benefits'
},
{
    value: 'HC',
    label: 'Health Certificate'
},
{
    value: 'HR',
    label: 'Health Clinic Records'
},
{
    value: 'I5',
    label: 'Immunization Record'
},
{
    value: 'IR',
    label: 'State School Immunization Records'
},
{
    value: 'LA',
    label: 'Laboratory Results'
},
{
    value: 'M1',
    label: 'Medical Record Attachment'
},
{
    value: 'MT',
    label: 'Models'
},
{
    value: 'NN',
    label: 'Nursing Notes'
},
{
    value: 'OB',
    label: 'Operative Note'
},
{
    value: 'OC',
    label: 'Oxygen Content Averaging Report'
},
{
    value: 'OD',
    label: 'Orders and Treatments Document'
},
{
    value: 'OE',
    label: 'Objective Physical Examination Document'
},
{
    value: 'OX',
    label: 'Oxygen Therapy Certification'
},
{
    value: 'OZ',
    label: 'Support Data for Claim'
},
{
    value: 'P4',
    label: 'Pathology Report'
},
{
    value: 'P5',
    label: 'Patient Medical History Document'
},
{
    value: 'PE',
    label: 'Parenteral or Enteral Certification'
},
{
    value: 'PN',
    label: 'Physical Therapy Notes'
},
{
    value: 'PO',
    label: 'Prosthetics or Orthotic Certification'
},
{
    value: 'PQ',
    label: 'Paramedical Results'
},
{
    value: 'PY',
    label: 'Physician’s Report'
},
{
    value: 'PZ',
    label: 'Physical Therapy Certification'
},
{
    value: 'RB',
    label: 'Radiology Films'
},
{
    value: 'RR',
    label: 'Radiology Reports'
},
{
    value: 'RT',
    label: 'Report of Tests and Analysis Report'
},
{
    value: 'RX',
    label: 'Renewable Oxygen Content Averaging Report'
},
{
    value: 'SG',
    label: 'Symptoms Document'
},
{
    value: 'V5',
    label: 'Death Notification'
},
{
    value: 'XP',
    label: 'Photographs'
}
];