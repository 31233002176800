/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface EligibilityTransactionTraceResponseViewModel {
  id?: string;
  datasetId?: string;
  eligibilitySubscriberResponseId?: string | null;
  eligibilityDependentResponseId?: string | null;
  transactionTraceReferenceIdSource?: string | null;
  transactionTraceReferenceId?: string | null;
  transactionTraceCompanyIdentifier?: string | null;
  transactionTraceReferenceIdSub?: string | null;
}
