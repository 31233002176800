import { Injectable } from '@angular/core';
import { ApiAutoCompleteService, FieldSelectOption } from 'components/shared';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CodeWrapperService } from '../service-wrappers/code-wrapper.service';

@Injectable({
  providedIn: 'root'
})
export class ModifiersLookupService extends ApiAutoCompleteService {
  constructor(private service: CodeWrapperService) {
    super();
  }

  existingValues: string[] = [];
  bIncludeInactive: boolean = true;

  mapRow(result) {
    return {
      value: result.id,
      label: `${result.code} - ${result.name}`,
      inactive: result.inactive,
      code: result.code,
      shortLabel: result.code
    };
  }

  search(value: string): Observable<FieldSelectOption[]> {
    return this.service
      .apiV1CodeCodetypePost('Modifier', {
        generalSearch: value,
        pageSize: 9999
      })
      .pipe(map((results) => results.data.map(this.mapRow).filter((x: any) => (!x.inactive || this.bIncludeInactive ||this.existingValues.includes(x.value)))));
  }

  load(id: string): Observable<FieldSelectOption> {
    if (id !== '') {
      return this.service.apiV1CodeCodetypeIdGet('Modifier', id).pipe(map(this.mapRow));
    }
  }
}
