import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DecimalFormatterDirective } from './decimal-formatter.directive';
import { MoneyFormatterDirective } from './money-formatter.directive';



@NgModule({
  declarations: [DecimalFormatterDirective, MoneyFormatterDirective],
  exports: [DecimalFormatterDirective, MoneyFormatterDirective],
  imports: [CommonModule]
})
export class DirectivesModule {}
