/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserViewModel } from './userViewModel';
import { JobViewModel } from './jobViewModel';
import { DatasetViewModel } from './datasetViewModel';

export interface ScheduledJobDetailsViewModel {
  id?: string;
  jobId?: string;
  datasetId?: string;
  frequencyType?: string | null;
  nextExecution?: string | null;
  frequencyInterval?: number | null;
  executionHour?: number | null;
  executionMinute?: number | null;
  status?: string | null;
  description?: string | null;
  createdBy?: string | null;
  executionMeridiem?: string | null;
  executionTimezone?: string | null;
  scheduledForId?: string;
  isReport?: boolean;
  parameterJson?: string | null;
  reportFrequencyType?: string | null;
  reportId?: string | null;
  isSysAdminJob?: boolean | null;
  weekDays?: Array<string> | null;
  monthDays?: Array<number> | null;
  months?: Array<string> | null;
  dataset?: DatasetViewModel;
  job?: JobViewModel;
  readonly jobName?: string | null;
  scheduledFor?: UserViewModel;
  readonly scheduledForName?: string | null;
}
