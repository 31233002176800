import { Injectable } from '@angular/core';
import { JobService, PagedModelRequest } from 'data';
import { BaseWrapperService } from './base-wrapper.service';
import { SessionStorageService } from '../session-storage.service';

@Injectable({
  providedIn: 'root'
})
export class JobWrapperService extends BaseWrapperService {
  constructor(private service: JobService, protected sessionStorageService: SessionStorageService) {
    super();
  }

  apiV1JobAllPost(pagedModelRequest: PagedModelRequest) {
    return this.service.apiV1JobAllPost(this.getDataSetId(), pagedModelRequest);
  }
  apiV1JobIdGet(jobId: string) {
    return this.service.apiV1JobIdGet(jobId, this.getDataSetId());
  }
}
