import { Inject, Injectable } from '@angular/core'
import { WindowToken } from '@core/injection-tokens/window-token'

@Injectable({
  providedIn: 'root'
})
export class SessionStorageService {
  constructor(@Inject(WindowToken) public window: Window) {}

  /**
   * Sets session storage
   *
   * @param {string} key
   * @param {*} value
   * @memberof SessionStorageService
   */
  setItem(key: string, value: any) {
    this.window.sessionStorage.setItem(key, JSON.stringify(value));
  }

  /**
   * Retrieves item from session storage
   *
   * @param {string} key
   * @returns
   * @memberof SessionStorageService
   */
  getItem(key: string) {
    return JSON.parse(sessionStorage.getItem(key));
  }

  /**
   * Removes item from local storage
   *
   * @param {string} key
   * @memberof SessionStorageService
   */
  removeItem(key: string) {
    this.window.sessionStorage.removeItem(key);
  }

  /**Tests that sessionStorage exists, can be written to, and read from. */
  testSessionStorage() {
    const testValue = 'testValue';
    const testKey = 'testKey';
    let retrievedValue: string;
    const errorMessage = 'sessionStorage did not return expected value';

    this.setItem(testKey, testValue);
    retrievedValue = this.getItem(testKey);
    this.removeItem(testKey);

    if (retrievedValue !== testValue) {
      throw new Error(errorMessage);
    }
  }

  addPatientId(id: string) {
    if (id !== null && id !== undefined && id !== '') {
      let currentPatients = this.getItem('recent-patients') ?? [];
      let checkIndex = currentPatients.indexOf(id);
      if (checkIndex >= 0) {
        currentPatients.splice(checkIndex, 1);
      }
      currentPatients.unshift(id);
      if (currentPatients.length > 100) {
        currentPatients.pop();
      }
      this.setItem('recent-patients', currentPatients);
    }
  }
}
