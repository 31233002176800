import { AppState } from '../../core.state';
import { UserDetailsViewModel } from 'data';

export interface UserState extends UserDetailsViewModel {
  loading?: boolean;
  error?: any;
}

export const initialUserState: UserState = {
  id: '',
  loading: false,
  error: undefined
};

export interface State extends AppState {
  user: UserState;
}
