import { Injectable } from '@angular/core';
import { ImplementationViewModel } from 'data';
import { Observable } from 'rxjs';
import { BaseWrapperService } from './base-wrapper.service';
import { ImplementationService } from 'data';
import { SessionStorageService } from '../session-storage.service';

@Injectable({
  providedIn: 'root'
})
export class ImplementationWrapperService extends BaseWrapperService {
  constructor(private service: ImplementationService, protected sessionStorageService: SessionStorageService) {
    super();
  }

  datasetId = null;
  apiV1ImplementationGet(): Observable<ImplementationViewModel> {
    return this.service.apiV1ImplementationGet(this.getDataSetId());
  }

  apiV1ImplementationCompleteStepCodePost(stepCode): Observable<ImplementationViewModel> {
    return this.service.apiV1ImplementationCompleteStepCodePost(stepCode, this.getDataSetId());
  }

  apiV1ImplementationPatchPatch(id: string, operation): Observable<ImplementationViewModel> {
    return this.service.apiV1ImplementationPatchPatch(this.getDataSetId(), operation);
  }
}
