import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VersionModule } from './version/version.module';

const modules = [VersionModule];

@NgModule({
  declarations: [],
  imports: [CommonModule, ...modules],
  exports: [...modules]
})
export class ComponentsModule {}
