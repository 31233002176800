/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface EligibilityValidationViewModel {
  id?: string;
  validationType?: string | null;
  yesNoCode?: string | null;
  validRequest?: string | null;
  readonly acceptedRequest?: boolean;
  rejectReasonCode?: string | null;
  rejectReasonDescription?: string | null;
  followupActionCode?: string | null;
  followupActionDescription?: string | null;
}
