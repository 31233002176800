import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterColumns'
})
export class FilterColumnsPipe implements PipeTransform {
  transform(displayColumns: string[]): unknown {
    return displayColumns.map((displayColumn) => {
      return `${displayColumn}-filter`;
    });
  }
}
