import { Pipe, PipeTransform } from '@angular/core';
import { ColumnDefinition } from '../models';
import { BaseColumnClass } from './base-column-class';

@Pipe({
  name: 'bodyColumnClass'
})
export class BodyColumnClassPipe extends BaseColumnClass implements PipeTransform {
  transform(columnDefinition: ColumnDefinition): string[] {
    let bodyClasses = ['lib-body-cell'];

    if (columnDefinition.bodyClasses) {
      bodyClasses = bodyClasses.concat(columnDefinition.bodyClasses);
    }

    if (columnDefinition.cellClickEvent) {
      bodyClasses.push('has-cell-click');
    }

    if (columnDefinition.cellActionButton) {
      bodyClasses.push('has-cell-action-click');
    }

    return this.baseTransform(bodyClasses, columnDefinition);
  }
}
