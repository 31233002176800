/**
 * ImaginePay API
 * This API facilitates partner integration within the platform.
 *
 * The version of the OpenAPI document: 22_12_0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent,
  HttpParameterCodec,
  HttpContext
} from '@angular/common/http';
import { CustomHttpParameterCodec } from '../encoder';
import { Observable } from 'rxjs';

// @ts-ignore
import { ImaginePayApiModelsAuditHistoryAuditTransactionHistoryResponse } from '../model/imaginePayApiModelsAuditHistoryAuditTransactionHistoryResponse';
// @ts-ignore
import { ImaginePayApiModelsMessageResponse } from '../model/imaginePayApiModelsMessageResponse';
// @ts-ignore
import { ImaginePayApiModelsReversePaymentsRefundTransactionRequestWebModel } from '../model/imaginePayApiModelsReversePaymentsRefundTransactionRequestWebModel';
// @ts-ignore
import { ImaginePayApiModelsReversePaymentsRefundTransactionResponseWebModel } from '../model/imaginePayApiModelsReversePaymentsRefundTransactionResponseWebModel';
// @ts-ignore
import { ImaginePayApiModelsReversePaymentsReverseTransactionRequestWebModel } from '../model/imaginePayApiModelsReversePaymentsReverseTransactionRequestWebModel';
// @ts-ignore
import { ImaginePayApiModelsReversePaymentsReverseTransactionResponseWebModel } from '../model/imaginePayApiModelsReversePaymentsReverseTransactionResponseWebModel';
// @ts-ignore
import { ImaginePayApiModelsReversePaymentsVoidTransactionRequestWebModel } from '../model/imaginePayApiModelsReversePaymentsVoidTransactionRequestWebModel';
// @ts-ignore
import { ImaginePayApiModelsReversePaymentsVoidTransactionResponseWebModel } from '../model/imaginePayApiModelsReversePaymentsVoidTransactionResponseWebModel';
// @ts-ignore
import { ImaginePayApiModelsTransactionTransactionRequest } from '../model/imaginePayApiModelsTransactionTransactionRequest';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable({
  providedIn: 'root'
})
export class TransactionService {
  protected basePath = 'https://devapi.imaginepay.com';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  public encoder: HttpParameterCodec;

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }

  // @ts-ignore
  private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
    if (typeof value === 'object' && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }

  private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
    if (value == null) {
      return httpParams;
    }

    if (typeof value === 'object') {
      if (Array.isArray(value)) {
        (value as any[]).forEach((elem) => (httpParams = this.addToHttpParamsRecursive(httpParams, elem, key)));
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(key, (value as Date).toISOString().substr(0, 10));
        } else {
          throw Error('key may not be null if value is Date');
        }
      } else {
        Object.keys(value).forEach(
          (k) => (httpParams = this.addToHttpParamsRecursive(httpParams, value[k], key != null ? `${key}.${k}` : k))
        );
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error('key may not be null if value is not object or array');
    }
    return httpParams;
  }

  /**
   * Get the details of transaction
   * @param transactionReferenceNumber A transaction reference number
   * @param authorization access token
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public transactionDetails(
    transactionReferenceNumber: string,
    authorization: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json' | 'text/json' | 'application/xml' | 'text/xml';
      context?: HttpContext;
    }
  ): Observable<ImaginePayApiModelsAuditHistoryAuditTransactionHistoryResponse>;
  public transactionDetails(
    transactionReferenceNumber: string,
    authorization: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json' | 'text/json' | 'application/xml' | 'text/xml';
      context?: HttpContext;
    }
  ): Observable<HttpResponse<ImaginePayApiModelsAuditHistoryAuditTransactionHistoryResponse>>;
  public transactionDetails(
    transactionReferenceNumber: string,
    authorization: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json' | 'text/json' | 'application/xml' | 'text/xml';
      context?: HttpContext;
    }
  ): Observable<HttpEvent<ImaginePayApiModelsAuditHistoryAuditTransactionHistoryResponse>>;
  public transactionDetails(
    transactionReferenceNumber: string,
    authorization: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: 'application/json' | 'text/json' | 'application/xml' | 'text/xml';
      context?: HttpContext;
    }
  ): Observable<any> {
    if (transactionReferenceNumber === null || transactionReferenceNumber === undefined) {
      throw new Error(
        'Required parameter transactionReferenceNumber was null or undefined when calling transactionDetails.'
      );
    }
    if (authorization === null || authorization === undefined) {
      throw new Error('Required parameter authorization was null or undefined when calling transactionDetails.');
    }

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (transactionReferenceNumber !== undefined && transactionReferenceNumber !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>transactionReferenceNumber,
        'transactionReferenceNumber'
      );
    }

    let localVarHeaders = this.defaultHeaders;
    if (authorization !== undefined && authorization !== null) {
      localVarHeaders = localVarHeaders.set('Authorization', String(authorization));
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json', 'text/json', 'application/xml', 'text/xml'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    return this.httpClient.get<ImaginePayApiModelsAuditHistoryAuditTransactionHistoryResponse>(
      `${this.configuration.basePath}/transaction/details`,
      {
        context: localVarHttpContext,
        params: localVarQueryParameters,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Post a refund for an entire transaction
   * @param authorization access token
   * @param refundTransactionRequestWebModel A request to refund a transaction
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public transactionRefundTransaction(
    authorization: string,
    refundTransactionRequestWebModel: ImaginePayApiModelsReversePaymentsRefundTransactionRequestWebModel,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json' | 'text/json' | 'application/xml' | 'text/xml';
      context?: HttpContext;
    }
  ): Observable<ImaginePayApiModelsReversePaymentsRefundTransactionResponseWebModel>;
  public transactionRefundTransaction(
    authorization: string,
    refundTransactionRequestWebModel: ImaginePayApiModelsReversePaymentsRefundTransactionRequestWebModel,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json' | 'text/json' | 'application/xml' | 'text/xml';
      context?: HttpContext;
    }
  ): Observable<HttpResponse<ImaginePayApiModelsReversePaymentsRefundTransactionResponseWebModel>>;
  public transactionRefundTransaction(
    authorization: string,
    refundTransactionRequestWebModel: ImaginePayApiModelsReversePaymentsRefundTransactionRequestWebModel,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json' | 'text/json' | 'application/xml' | 'text/xml';
      context?: HttpContext;
    }
  ): Observable<HttpEvent<ImaginePayApiModelsReversePaymentsRefundTransactionResponseWebModel>>;
  public transactionRefundTransaction(
    authorization: string,
    refundTransactionRequestWebModel: ImaginePayApiModelsReversePaymentsRefundTransactionRequestWebModel,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: 'application/json' | 'text/json' | 'application/xml' | 'text/xml';
      context?: HttpContext;
    }
  ): Observable<any> {
    if (authorization === null || authorization === undefined) {
      throw new Error(
        'Required parameter authorization was null or undefined when calling transactionRefundTransaction.'
      );
    }
    if (refundTransactionRequestWebModel === null || refundTransactionRequestWebModel === undefined) {
      throw new Error(
        'Required parameter refundTransactionRequestWebModel was null or undefined when calling transactionRefundTransaction.'
      );
    }

    let localVarHeaders = this.defaultHeaders;
    if (authorization !== undefined && authorization !== null) {
      localVarHeaders = localVarHeaders.set('Authorization', String(authorization));
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json', 'text/json', 'application/xml', 'text/xml'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json',
      'text/json',
      'application/xml',
      'text/xml',
      'application/x-www-form-urlencoded'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    return this.httpClient.post<ImaginePayApiModelsReversePaymentsRefundTransactionResponseWebModel>(
      `${this.configuration.basePath}/transaction/refund`,
      refundTransactionRequestWebModel,
      {
        context: localVarHttpContext,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Post to reverse an entire transaction
   * @param authorization access token
   * @param reverseTransactionRequestWebModel A request to reverse transaction
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public transactionReverseTransaction(
    authorization: string,
    reverseTransactionRequestWebModel: ImaginePayApiModelsReversePaymentsReverseTransactionRequestWebModel,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json' | 'text/json' | 'application/xml' | 'text/xml';
      context?: HttpContext;
    }
  ): Observable<ImaginePayApiModelsReversePaymentsReverseTransactionResponseWebModel>;
  public transactionReverseTransaction(
    authorization: string,
    reverseTransactionRequestWebModel: ImaginePayApiModelsReversePaymentsReverseTransactionRequestWebModel,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json' | 'text/json' | 'application/xml' | 'text/xml';
      context?: HttpContext;
    }
  ): Observable<HttpResponse<ImaginePayApiModelsReversePaymentsReverseTransactionResponseWebModel>>;
  public transactionReverseTransaction(
    authorization: string,
    reverseTransactionRequestWebModel: ImaginePayApiModelsReversePaymentsReverseTransactionRequestWebModel,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json' | 'text/json' | 'application/xml' | 'text/xml';
      context?: HttpContext;
    }
  ): Observable<HttpEvent<ImaginePayApiModelsReversePaymentsReverseTransactionResponseWebModel>>;
  public transactionReverseTransaction(
    authorization: string,
    reverseTransactionRequestWebModel: ImaginePayApiModelsReversePaymentsReverseTransactionRequestWebModel,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: 'application/json' | 'text/json' | 'application/xml' | 'text/xml';
      context?: HttpContext;
    }
  ): Observable<any> {
    if (authorization === null || authorization === undefined) {
      throw new Error(
        'Required parameter authorization was null or undefined when calling transactionReverseTransaction.'
      );
    }
    if (reverseTransactionRequestWebModel === null || reverseTransactionRequestWebModel === undefined) {
      throw new Error(
        'Required parameter reverseTransactionRequestWebModel was null or undefined when calling transactionReverseTransaction.'
      );
    }

    let localVarHeaders = this.defaultHeaders;
    if (authorization !== undefined && authorization !== null) {
      localVarHeaders = localVarHeaders.set('Authorization', String(authorization));
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json', 'text/json', 'application/xml', 'text/xml'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json',
      'text/json',
      'application/xml',
      'text/xml',
      'application/x-www-form-urlencoded'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    return this.httpClient.post<ImaginePayApiModelsReversePaymentsReverseTransactionResponseWebModel>(
      `${this.configuration.basePath}/transaction/reverse`,
      reverseTransactionRequestWebModel,
      {
        context: localVarHttpContext,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Process a payment for the matching balance
   * @param authorization access token
   * @param transactionRequest
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public transactionTransaction(
    authorization: string,
    transactionRequest: ImaginePayApiModelsTransactionTransactionRequest,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json' | 'text/json' | 'application/xml' | 'text/xml';
      context?: HttpContext;
    }
  ): Observable<ImaginePayApiModelsAuditHistoryAuditTransactionHistoryResponse>;
  public transactionTransaction(
    authorization: string,
    transactionRequest: ImaginePayApiModelsTransactionTransactionRequest,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json' | 'text/json' | 'application/xml' | 'text/xml';
      context?: HttpContext;
    }
  ): Observable<HttpResponse<ImaginePayApiModelsAuditHistoryAuditTransactionHistoryResponse>>;
  public transactionTransaction(
    authorization: string,
    transactionRequest: ImaginePayApiModelsTransactionTransactionRequest,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json' | 'text/json' | 'application/xml' | 'text/xml';
      context?: HttpContext;
    }
  ): Observable<HttpEvent<ImaginePayApiModelsAuditHistoryAuditTransactionHistoryResponse>>;
  public transactionTransaction(
    authorization: string,
    transactionRequest: ImaginePayApiModelsTransactionTransactionRequest,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: 'application/json' | 'text/json' | 'application/xml' | 'text/xml';
      context?: HttpContext;
    }
  ): Observable<any> {
    if (authorization === null || authorization === undefined) {
      throw new Error('Required parameter authorization was null or undefined when calling transactionTransaction.');
    }
    if (transactionRequest === null || transactionRequest === undefined) {
      throw new Error(
        'Required parameter transactionRequest was null or undefined when calling transactionTransaction.'
      );
    }

    let localVarHeaders = this.defaultHeaders;
    if (authorization !== undefined && authorization !== null) {
      localVarHeaders = localVarHeaders.set('Authorization', String(authorization));
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json', 'text/json', 'application/xml', 'text/xml'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json',
      'text/json',
      'application/xml',
      'text/xml',
      'application/x-www-form-urlencoded'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    return this.httpClient.post<ImaginePayApiModelsAuditHistoryAuditTransactionHistoryResponse>(
      `${this.configuration.basePath}/transaction`,
      transactionRequest,
      {
        context: localVarHttpContext,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Post a void for an entire transaction
   * @param authorization access token
   * @param voidTransactionRequestWebModel A request to void a transaction
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public transactionVoidTransaction(
    authorization: string,
    voidTransactionRequestWebModel: ImaginePayApiModelsReversePaymentsVoidTransactionRequestWebModel,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json' | 'text/json' | 'application/xml' | 'text/xml';
      context?: HttpContext;
    }
  ): Observable<ImaginePayApiModelsReversePaymentsVoidTransactionResponseWebModel>;
  public transactionVoidTransaction(
    authorization: string,
    voidTransactionRequestWebModel: ImaginePayApiModelsReversePaymentsVoidTransactionRequestWebModel,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json' | 'text/json' | 'application/xml' | 'text/xml';
      context?: HttpContext;
    }
  ): Observable<HttpResponse<ImaginePayApiModelsReversePaymentsVoidTransactionResponseWebModel>>;
  public transactionVoidTransaction(
    authorization: string,
    voidTransactionRequestWebModel: ImaginePayApiModelsReversePaymentsVoidTransactionRequestWebModel,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json' | 'text/json' | 'application/xml' | 'text/xml';
      context?: HttpContext;
    }
  ): Observable<HttpEvent<ImaginePayApiModelsReversePaymentsVoidTransactionResponseWebModel>>;
  public transactionVoidTransaction(
    authorization: string,
    voidTransactionRequestWebModel: ImaginePayApiModelsReversePaymentsVoidTransactionRequestWebModel,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: 'application/json' | 'text/json' | 'application/xml' | 'text/xml';
      context?: HttpContext;
    }
  ): Observable<any> {
    if (authorization === null || authorization === undefined) {
      throw new Error(
        'Required parameter authorization was null or undefined when calling transactionVoidTransaction.'
      );
    }
    if (voidTransactionRequestWebModel === null || voidTransactionRequestWebModel === undefined) {
      throw new Error(
        'Required parameter voidTransactionRequestWebModel was null or undefined when calling transactionVoidTransaction.'
      );
    }

    let localVarHeaders = this.defaultHeaders;
    if (authorization !== undefined && authorization !== null) {
      localVarHeaders = localVarHeaders.set('Authorization', String(authorization));
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json', 'text/json', 'application/xml', 'text/xml'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json',
      'text/json',
      'application/xml',
      'text/xml',
      'application/x-www-form-urlencoded'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    return this.httpClient.post<ImaginePayApiModelsReversePaymentsVoidTransactionResponseWebModel>(
      `${this.configuration.basePath}/transaction/void`,
      voidTransactionRequestWebModel,
      {
        context: localVarHttpContext,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }
}
