/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface RuleCardProcedureDetailsViewModel {
  id?: string;
  rowId?: number;
  ruleCardId?: string;
  ordinal?: number;
  procedure?: string | null;
  modifier1?: string | null;
  modifier2?: string | null;
  modifier3?: string | null;
  modifier4?: string | null;
  diagnosis1?: string | null;
  diagnosis2?: string | null;
  diagnosis3?: string | null;
  diagnosis4?: string | null;
  attendingProvider?: string | null;
  referringProvider?: string | null;
  orderingProvider?: string | null;
  primaryProvider?: string | null;
  location?: string | null;
  placeOfServiceCode?: string | null;
  quantity?: string | null;
  insuranceBilling?: string | null;
  patientBilling?: string | null;
  procedureAmount?: string | null;
  claimComment?: string | null;
  textForReload?: string | null;
  isDeleted?: boolean;
  deletedBy?: string | null;
  deletedDate?: string | null;
  createdBy?: string | null;
  createdDate?: string;
  updatedBy?: string | null;
  updatedDate?: string | null;
}
