/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface EobHistoryViewModel {
  patientId?: string | null;
  visitNumber?: number;
  arTransactionDetailHoldingId?: string | null;
  procedureCode?: string | null;
  serviceDate?: string | null;
  insuranceCarrier?: string | null;
  type?: string | null;
  description?: string | null;
  statusDate?: string | null;
  amount?: number;
}
