/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface RemitFileViewModel {
  id?: string;
  datasetId?: string;
  fileName?: string | null;
  fullPathName?: string | null;
  fileProcessedDate?: string;
  fileProcessedSuccessfully?: boolean;
  processingInformation?: string | null;
  note?: string | null;
  issues?: number;
}
