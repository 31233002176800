import { CurrencyPipe } from '@angular/common';
import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currencyDisplay'
})
export class CurrencyDisplayPipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) private locale: string) {}
 currencyPipe = new CurrencyPipe(this.locale);

  transform(value: string, emptyValue = '--'): string {
    if (value === undefined || value === null) {
        return emptyValue;
    }
    return this.currencyPipe.transform(value, 'USD', 'symbol', '1.2-2');
  }
}
