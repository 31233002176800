export interface DialogContent {
  header?: string;
  body?: string;
  subBody?: string;
  cancelButtonText?: string;
  OKButtonText?: string;
  cancelButtonVisible?: boolean;
  observable?: any;
  errorMessage?: string;
}
