import { Injectable } from '@angular/core';
import {
  AddVisitViewModel,
  VisitService,
  Operation,
  PagedModelRequest,
  VisitDetailsViewModel,
  VisitPaymentDetailsViewModel,
  UpdateVisitViewModel,
  MergeVisitViewModel,
  SplitVisitViewModel
} from 'data';
import { Observable } from 'rxjs';
import { BaseWrapperService } from './base-wrapper.service';
import { VisitSearchResultViewModelPagedModelResponse } from 'projects/data/src/lib/model/visitSearchResultViewModelPagedModelResponse';
import { SessionStorageService } from '../session-storage.service';

@Injectable({
  providedIn: 'root'
})
export class VisitWrapperService extends BaseWrapperService {
  constructor(private service: VisitService, protected sessionStorageService: SessionStorageService) {
    super();
  }

  apiV1VisitPatchIdPatch(id: string, operation?: Array<Operation>): Observable<any> {
    return this.service.apiV1VisitPatchIdPatch(id, this.getDataSetId(), operation);
  }

  apiV1VisitUpdateVisitPost(updateVisitViewModel?: UpdateVisitViewModel): Observable<any> {
    return this.service.apiV1VisitUpdateVisitPost(this.getDataSetId(), updateVisitViewModel);
  }

  apiV1VisitAddPost(addVisitViewModel?: AddVisitViewModel) {
    return this.service.apiV1VisitAddPost(this.getDataSetId(), addVisitViewModel);
  }

  apiV1VisitMergeVisitPost(mergeVisitViewModel?: MergeVisitViewModel) {
    return this.service.apiV1VisitMergeVisitPost(this.getDataSetId(), mergeVisitViewModel);
  }

  apiV1VisitAddVisitPost(addVisitViewModel?: AddVisitViewModel) {
    return this.service.apiV1VisitAddVisitPost(this.getDataSetId(), addVisitViewModel);
  }

  apiV1VisitDetailsIdGet(id: string): Observable<VisitDetailsViewModel> {
    return this.service.apiV1VisitDetailsIdGet(id, this.getDataSetId());
  }
  apiV1VisitPaymentDetailsIdGet(id: string): Observable<VisitPaymentDetailsViewModel> {
    return this.service.apiV1VisitPaymentDetailsIdGet(id, this.getDataSetId());
  }
  apiV1VisitAllDetailPost(pagedModelRequest: PagedModelRequest): Observable<any> {
    return this.service.apiV1VisitAllDetailPost(this.getDataSetId(), pagedModelRequest);
  }

  apiV1VisitIdDelete(id: string): Observable<any> {
    return this.service.apiV1VisitIdDelete(id, this.getDataSetId());
  }

  apiV1VisitSearchPost(
    pagedModelRequest?: PagedModelRequest
  ): Observable<VisitSearchResultViewModelPagedModelResponse> {
    return this.service.apiV1VisitSearchPost(this.getDataSetId(), pagedModelRequest);
  }

  apiV1VisitSplitVisitPost(splitVisit: SplitVisitViewModel): Observable<any> {
    return this.service.apiV1VisitSplitVisitPost(this.getDataSetId(), splitVisit);
  }
}
