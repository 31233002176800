<lib-card class="lib-detail-card">
  <ng-container name="chips">
    <div class="grid-chip-list">
      <span *ngFor="let chip of chips; trackBy: trackByValue" class="chip chip-{{ chip.type }}">{{ chip.text }}</span>
    </div>
  </ng-container>
  <ng-container name="title">{{ title }}</ng-container>
  <ng-container name="header">
    <div class="lib-detail-card__button">
      <ng-content select="[name=commands]"></ng-content>
    </div>
    <button *ngIf="showEdit" mat-icon-button class="lib-detail-card__button" (click)="openEdit.emit(id)">
      <mat-icon>edit</mat-icon>
    </button>
    <button *ngIf="showAdd" mat-icon-button class="lib-detail-card__button" (click)="openAdd.emit(id)">
      <mat-icon>add_box</mat-icon>
    </button>
  </ng-container>
  <dl class="lib-detail-card__data">
    <ng-container *ngFor="let field of fields; trackBy: trackByValue">
      <dt
        [ngClass]="{
          previous: field.previous,
          invalid: field.missing
        }"
      >
        {{ field.name }}
      </dt>
      <dd
        [ngClass]="{
          previous: field.previous,
          updated: field.updated,
          invalid: field.invalid
        }"
      >
        {{ field.value }}
      </dd>
    </ng-container>
  </dl>
</lib-card>
