/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HoldingIssue } from './holdingIssue';

export interface ClaimProcedureNDCViewModel {
  id?: string;
  ndcCode?: string | null;
  ndcQuantity?: number;
  ndcDrugName?: string | null;
  ndcAmount?: number;
  ndcQualifier?: string | null;
  ndcUnit?: string | null;
  readonly ndcDisplay?: string | null;
  issues?: Array<HoldingIssue> | null;
}
