import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseWrapperService } from './base-wrapper.service';
import { PagedModelRequest, RvuGpciService, RvuGpciViewModel, RvuGpciViewModelPagedModelResponse } from 'data';
import { SessionStorageService } from '../session-storage.service';

@Injectable({
  providedIn: 'root'
})
export class RvuGpciWrapperService extends BaseWrapperService {
  constructor(private service: RvuGpciService, protected sessionStorageService: SessionStorageService) {
    super();
  }

  apiV1RvugpciAllPost(pagedModelRequest?: PagedModelRequest): Observable<RvuGpciViewModelPagedModelResponse> {
    return this.service.apiV1RvugpciAllPost(this.getDataSetId(), pagedModelRequest);
  }

  apiV1RvugpciIdGet(id: string): Observable<RvuGpciViewModel> {
    return this.service.apiV1RvugpciIdGet(id, this.getDataSetId());
  }

  apiV1RvugpciDetailsIdGet(id: string): Observable<RvuGpciViewModel> {
    return this.service.apiV1RvugpciDetailsIdGet(id, this.getDataSetId());
  }

  apiV1RvugpciAddPost(requestBody): Observable<RvuGpciViewModel> {
    return this.service.apiV1RvugpciAddPost(this.getDataSetId(), requestBody);
  }

  apiV1RvugpciUpdateDetailsPost(requestBody: any) {
    return this.service.apiV1RvugpciUpdateDetailsPost(this.getDataSetId(), requestBody);
  }

  apiV1RvugpciPatchIdPatch(id: string, operation): Observable<RvuGpciViewModel> {
    return this.service.apiV1RvugpciPatchIdPatch(id, this.getDataSetId(), operation);
  }

  apiV1RvugpciIdDelete(id: string): Observable<RvuGpciViewModel> {
    return this.service.apiV1RvugpciIdDelete(id, this.getDataSetId());
  }

  apiV1RvugpciDetailsIdDelete(id: string): Observable<RvuGpciViewModel> {
    return this.service.apiV1RvugpciDetailsIdDelete(id, this.getDataSetId());
  }
}
