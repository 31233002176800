import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router, ActivatedRoute } from '@angular/router';
import { DialogService } from '@core/services/dialog.service';
import { NotificationService } from '@core/services/notification.service';
import { PatientWrapperService } from '@core/services/service-wrappers/patient-wrapper.service';
import { VisitWrapperService } from '@core/services/service-wrappers/visit-wrapper.service';
import { DialogContent } from 'components';
import { ProcedureTransactionDetailsViewModel } from 'data';
import { map, Subscription, take } from 'rxjs';

@Component({
  selector: 'app-split-visit',
  templateUrl: './split-visit.component.html',
  styleUrl: './split-visit.component.scss'
})
export class SplitVisitComponent implements OnInit {
  dataSource: any;
  selection = new SelectionModel(true, []);
  procedureForm: UntypedFormGroup;
  visitForm: UntypedFormGroup;
  procedures: Array<ProcedureTransactionDetailsViewModel> = [];
  procedureArray: UntypedFormArray;
  visit;
  private dateFormatter = new Intl.DateTimeFormat('en-us');
  public formData;
  saving = false;
  visitArray: Array<any> = [];

  displayedColumns: string[] = [
    'select',
    'procedureCode',
    'fromDate',
    'procedureAmount',
    'placeOfService',
    'attending',
    'referring',
    'location'
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<SplitVisitComponent>,
    private formBuilder: UntypedFormBuilder,
    private patientService: PatientWrapperService,
    private dialogService: DialogService,
    private notificationService: NotificationService,
    private service: VisitWrapperService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.visit = data.visit;
  }

  ngOnInit(): void {
    this.procedures = this.visit?.procedureTransactions
    this.dataSource = new MatTableDataSource(this.procedures);

    this.visitForm = this.formBuilder.group({
      procedureArray: new UntypedFormArray([]),
      visitId: new UntypedFormControl('', Validators.required)
    });
    this.setupGridForm();
    this.loadVisits();
  }

  setupGridForm() {
    this.procedures?.forEach((x) => {
      (this.visitForm.get('procedureArray') as UntypedFormArray).push(
        new UntypedFormGroup({
          selected: new UntypedFormControl(false),
          id: new UntypedFormControl(x?.id)
        })
      );
    });
  }

  loadVisits() {
    this.visitArray = [];
    this.patientService
      .apiV1PatientDetailsIdGet(this.visit?.patientId)
      .pipe(
        map((x) => {
          const visits = x.visits.map((data) => {
            return {
              ...data,
              from: this.dateFormatter.format(new Date(data.procedureFromDate))
            };
          });
          return { ...x, visits };
        })
      )
      .subscribe((data) => {
        this.visitArray.push({
          label: "Create New Visit",
          value: "00000000-0000-0000-0000-000000000000"
        });
        data.visits.forEach((visit) => {
          if (visit.id !== this.visit?.id) {
            this.visitArray.push({
              label: `Visit ${visit.referenceNumber} - ${visit.from} - Balance: $${visit.balance.toFixed(2)} `,
              value: visit.id
            });
          }
        });
      });
  }

  toggleCheckbox(row): void {
    this.selection.toggle(row);
  }

  applySplit() {
    if (!this.saving) {
      this.saving = true;
      const modalContent: DialogContent = {
        header: 'Split Visit',
        body: `Are you sure you want to split this visit?`,
        cancelButtonText: 'Cancel',
        OKButtonText: 'Split'
      };
      this.dialogService.showConfirm(modalContent).subscribe((result) => {
        if (result) {
          const selectedItems = [];
          this.selection.selected.forEach(element => {
            selectedItems.push(element);
          });
          const formData = {
            newVisitId: this.visitForm.get('visitId').value,
            oldVisitId: this.data?.visit?.id,
            procedureTransactionIds: selectedItems
          };
          this.service?.apiV1VisitSplitVisitPost(formData)
            .pipe(take(1))
            .subscribe((response) => {
              this.notificationService.notifyNow('Visit Split');
            },
              (err) => this.notificationService.error('Error splitting visit.'));
              this.dialogRef.close();
              this.router
              .navigateByUrl('/', { skipLocationChange: true })
              .then(() =>
                this.router.navigate(['/', 'accounts', 'detail', this.data?.visit?.patientId, 'visit-detail', this.data?.visit?.id]));
              this.saving = false;
        }
      });
    }
  }
}
