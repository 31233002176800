import { Injectable } from '@angular/core';
import { AddPaymentViewModel, ImaginePayRefundViewModel, PaymentService } from 'data';
import { BaseWrapperService } from './base-wrapper.service';
import { SessionStorageService } from '../session-storage.service';
@Injectable({
  providedIn: 'root'
})
export class PaymentWrapperService extends BaseWrapperService {
  constructor(private service: PaymentService, protected sessionStorageService: SessionStorageService) {
    super();
  }

  apiV1PaymentAddPost(addPaymentViewModel: AddPaymentViewModel) {
    return this.service.apiV1PaymentAddPost(this.getDataSetId(), addPaymentViewModel);
  }

  apiV1PaymentConfigPost() {
    return this.service.apiV1PaymentConfigPost(this.getDataSetId());
  }

  apiV1PaymentGetPaymentIdRefnoPost(patientId: string, refno: string) {
    return this.service.apiV1PaymentGetPaymentIdRefnoPost(patientId, refno, this.getDataSetId());
  }

  apiV1PaymentAddRefundPost(refund: ImaginePayRefundViewModel) {
    return this.service.apiV1PaymentAddRefundPost(this.getDataSetId(), refund);
  }
}
