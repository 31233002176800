<div class="reminders">
  <div class="reminders__header" *ngIf="isDialog === false">
    <button
      mat-button
      class="reminders__add-reminder"
      color="primary"
      [disabled]="disableCreateNote"
      (click)="showForm()"
    >
      <mat-icon>add_circle_outline</mat-icon>
      <span>New Reminder</span>
    </button>
  </div>
  <div *ngIf="isDialog === true">
    <div class="dialog-form__close">
      <a mat-dialog-close>
        <mat-icon (click)="close()">close</mat-icon>
      </a>
    </div>
    <h2>Add Reminder</h2>
  </div>

  <div class="reminders__body">
    <ng-container [ngSwitch]="reminderView">
      <ng-container *ngSwitchCase="'list'">
        <app-reminders-list [isLoading]="areRemindersLoading | async" [reminders]="existingReminders | async">
        </app-reminders-list>
      </ng-container>
      <ng-container *ngSwitchCase="'form'">
        <app-reminder-form [patientId]="patientId" (cancelEvent)="hideForm($event)"> </app-reminder-form>
      </ng-container>
    </ng-container>
  </div>
</div>
