import { Injectable } from '@angular/core';
import { CodeService, CodeViewModel, CodeViewModelPagedModelResponse, PagedModelRequest } from 'data';
import { Observable } from 'rxjs';
import { BaseWrapperService } from './base-wrapper.service';
import { SessionStorageService } from '../session-storage.service';
@Injectable({
  providedIn: 'root'
})
export class CodeWrapperService extends BaseWrapperService {
  constructor(private codeService: CodeService, protected sessionStorageService: SessionStorageService) {
    super();
  }
  apiV1CodeCodetypePost(
    codetype: string,
    pagedModelRequest?: PagedModelRequest
  ): Observable<CodeViewModelPagedModelResponse> {
    return this.codeService.apiV1CodeCodetypePost(codetype, this.getDataSetId(), pagedModelRequest);
  }

  apiV1CodeCodetypeIdGet(codetype: string, id: string): Observable<CodeViewModel> {
    return this.codeService.apiV1CodeCodetypeIdGet(codetype, id, this.getDataSetId());
  }

  apiV1CodeCodetypeAddPost(codetype: string, requestBody): Observable<CodeViewModel> {
    return this.codeService.apiV1CodeCodetypeAddPost(codetype, this.getDataSetId(), requestBody);
  }

  apiV1CodeCodetypePatchIdPatch(codetype: string, id: string, operation): Observable<CodeViewModel> {
    return this.codeService.apiV1CodeCodetypePatchIdPatch(codetype, id, this.getDataSetId(), operation);
  }

  apiV1CodeCodetypeDeleteIdDelete(codetype: string, id: string): Observable<CodeViewModel> {
    return this.codeService.apiV1CodeCodetypeDeleteIdDelete(codetype, id, this.getDataSetId());
  }

  apiV1CodeCodetypeGet(codetype: string): Observable<CodeViewModel> {
    return this.codeService.apiV1CodeCodetypeGet(codetype, this.getDataSetId());
  }

  apiV1CodeIdsCodetypePost(codetype: string, ids: string[]): Observable<CodeViewModel[]> {
    return this.codeService.apiV1CodeIdsCodetypePost(codetype, this.getDataSetId(), ids);
  }
}
