import { AuthenticationService } from "data";
import { BaseWrapperService } from "./base-wrapper.service";
import { Injectable } from "@angular/core";
import { SessionStorageService } from "../session-storage.service";

@Injectable({
    providedIn: 'root'
  })
  export class AuthenticationWrapperService extends BaseWrapperService {
    constructor(private service: AuthenticationService, protected sessionStorageService: SessionStorageService) {
      super();
    }

    apiV1AuthenticationAuthorizedClientsIdGet(id: string) {
      return this.service.apiV1AuthenticationAuthorizedClientsIdGet(id);
    }
  }


