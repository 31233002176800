/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SendingFacilityViewModel } from './sendingFacilityViewModel';
import { CodeViewModel } from './codeViewModel';
import { DatasetViewModel } from './datasetViewModel';

export interface PlaceOfServiceCodeLinkDetailsViewModel {
  id?: string;
  datasetId?: string;
  sendingFacilityId?: string;
  placeOfServiceCodeId?: string | null;
  sendingFacilityPlaceOfServiceCode?: string | null;
  sendingFacilityPlaceOfServiceName?: string | null;
  isDeleted?: boolean;
  deletedBy?: string | null;
  deletedDate?: string | null;
  createdBy?: string | null;
  createdDate?: string;
  updatedBy?: string | null;
  updatedDate?: string | null;
  dataset?: DatasetViewModel;
  placeOfServiceCode?: CodeViewModel;
  readonly name?: string | null;
  readonly code?: string | null;
  sendingFacility?: SendingFacilityViewModel;
  readonly sendingFacilityName?: string | null;
}
