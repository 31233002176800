import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  AddNationalDrugCodeViewModel,
  NationalDrugCodeDetailsViewModel,
  NationalDrugCodeService
} from '../../../../../projects/data/src/public-api';
import { BaseWrapperService } from './base-wrapper.service';
import { SessionStorageService } from '../session-storage.service';

@Injectable({
  providedIn: 'root'
})
export class NationalDrugCodeWrapperService extends BaseWrapperService {
  constructor(private service: NationalDrugCodeService, protected sessionStorageService: SessionStorageService) {
    super();
  }

  apiV1NationalDrugCodeDetailsIdGet(id: string): Observable<NationalDrugCodeDetailsViewModel> {
    return this.service.apiV1NationalDrugCodeDetailsIdGet(id, this.getDataSetId());
  }

  apiV1NationalDrugCodeByProcedureIdIdGet(id: string): Observable<NationalDrugCodeDetailsViewModel> {
    return this.service.apiV1NationalDrugCodeByProcedureIdIdGet(id, this.getDataSetId());
  }

  apiV1NationalDrugCodePatchIdPatch(id: string, operation): Observable<NationalDrugCodeDetailsViewModel> {
    return this.service.apiV1NationalDrugCodePatchIdPatch(id, this.getDataSetId(), operation);
  }

  apiV1NationalDrugCodeAddPost(addModel: AddNationalDrugCodeViewModel): Observable<NationalDrugCodeDetailsViewModel> {
    return this.service.apiV1NationalDrugCodeAddPost(this.getDataSetId(), addModel);
  }

  apiV1NationalDrugCodeIdDelete(id: string): Observable<any> {
    return this.service.apiV1NationalDrugCodeIdDelete(id, this.getDataSetId());
  }
}
