import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpRequest, HttpHandler, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';

@Injectable()
export class ServerErrorInterceptor implements HttpInterceptor {
  constructor(private injector: Injector) {}

  /**
   *  Intercepts web requests and ensures errors get plugged into our global error handling
   *
   * @param {HttpRequest<any>} request
   * @param {HttpHandler} next
   * @return {*}  {Observable<HttpEvent<any>>}
   * @memberof ServerErrorInterceptor
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          // refresh token
          this.handleError(error);
          return throwError(error);
        } else {
          this.handleError(error);
          return throwError(error);
        }
      })
    );
  }

  /**
   * Passes http error to global error handler
   *
   * @private
   * @param {*} error
   * @memberof ServerErrorInterceptor
   */
  private handleError(error) {
    const appErrorHandler = this.injector.get(ErrorHandler);
    appErrorHandler.handleError(error);
  }
}
