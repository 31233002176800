import { ReportGroup } from '../../models/report-group';
import { DailyPostingSummary } from './daily-posting-summary';
import { DailyPostingSummaryService } from './daily-posting-summary.service';
import { FinancialClassActivityReport } from './financial-class-activity-report';
import { FinancialClassActivityReportService } from './financial-class-activity-report.service';
import { PracticeAnalysisReport } from './practice-analysis-report';
import { PracticeAnalysisReportService } from './practice-analysis-report.service';
import { BatchSummary } from './batch-summary';
import { BatchSummaryService } from './batch-summary.service';
import { TransactionByTypeDate } from './transactions-by-type-date';
import { TransactionByTypeDateService } from './transactions-by-type-date.service';
import { UnbilledCharges } from './unbilled-charges';
import { UnbilledChargesService } from './unbilled-charges.service';
import { ChargeGroupService } from './charge-group.service';
import { ChargeGroup } from './charge-group';
import { PaymentAnalysisReportService } from './payment-analysis-report.service';
import { PaymentAnalysisReport } from './payment-analysis-report';
import { ChargesWithAccessionNumbersService } from './charges-with-accession-numbers.service';
import { ChargesWithAccessionNumbers } from './charges-with-accession-numbers';
import { ImportedChargesReportService } from './imported-charges-report.service';
import { ImportedChargesReport } from './imported-charges-report';
import { ReportingTypeAnalysisReportService } from './reporting-type-analysis-report.service';
import { ReportingTypeAnalysisReport } from './reporting-type-analysis-report';
import { NewPatientReport } from './new-patient-report';
import { NewPatientReportService } from './new-patient-report.service';
import { SelectedProceduresService } from './selected-procedures.service';
import { SelectedProcedures } from './selected-procedures';
import { ServiceAnalysisReportService } from './service-analysis.service';
import { ServiceAnalysisReport } from './service-analysis';
import { PaymentAllocationReport } from './payment-allocation-report';
import { PaymentAllocationReportService } from './payment-allocation-report.service';
import { UserProductivityReport } from './user-productivity-report';
import { VisitDetailReport } from './visit-detail-report';
import { VisitDetailReportService } from './visit-detail-report.service';
import { RefundsReport } from './refunds-report';
import { RefundsReportService } from './refunds-report.service';


export const TransactionActivityReportGroup: ReportGroup = {
  id: 'transaction-activity',
  label: 'Transaction Activity',
  reports: [BatchSummary, ChargeGroup, DailyPostingSummary, FinancialClassActivityReport, ImportedChargesReport, NewPatientReport, PaymentAllocationReport, PaymentAnalysisReport, PracticeAnalysisReport, ChargesWithAccessionNumbers, 
    RefundsReport, ReportingTypeAnalysisReport, SelectedProcedures, ServiceAnalysisReport, TransactionByTypeDate, UnbilledCharges, UserProductivityReport, VisitDetailReport]
};

export const TransactionActivityServices = [
  FinancialClassActivityReportService,
  NewPatientReportService,
  PaymentAnalysisReportService,
  PracticeAnalysisReportService,
  DailyPostingSummaryService,
  UnbilledChargesService,
  TransactionByTypeDateService,
  BatchSummaryService,
  ChargesWithAccessionNumbersService,
  ImportedChargesReportService,
  ChargeGroupService,
  ReportingTypeAnalysisReportService,
  SelectedProceduresService,
  ServiceAnalysisReportService,
  PaymentAllocationReportService,
  VisitDetailReportService,
  RefundsReportService
];
