import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VisitDetailsComponent } from './visit-details.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { ProcedureDetailsComponent } from './procedure-details/procedure-details.component';
import { StatusModule } from '../status/status.module';
import { PipesModule } from '../pipes';
import { ProcedureActivityComponent } from './procedure-activity/procedure-activity.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSelectModule } from '@angular/material/select';
import { ProcedureTotalsComponent } from './procedure-totals/procedure-totals.component';
import { CardModule } from '../card';
import { VisitInformationComponent } from './visit-information/visit-information.component';
import { MatMenuModule } from '@angular/material/menu';
import { EobHistoryComponent } from './eob-history/eob-history.component';
import { MatPaginatorModule } from '@angular/material/paginator';
@NgModule({
  declarations: [
    VisitDetailsComponent,
    ProcedureDetailsComponent,
    ProcedureActivityComponent,
    ProcedureTotalsComponent,
    VisitInformationComponent,
    EobHistoryComponent
  ],
  exports: [VisitDetailsComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatCardModule,
    StatusModule,
    MatMenuModule,
    PipesModule,
    MatSlideToggleModule,
    CardModule,
    MatButtonModule,
    MatSelectModule,
    MatPaginatorModule
  ]
})
export class VisitDetailsModule {}
