import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProvidersDialogComponent } from './providers-dialog.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { FormModule, SharedModule } from 'components';
import { MatTableModule } from '@angular/material/table';
import { MatMenuModule } from '@angular/material/menu';
import { ProviderLocationsEntryDialogModule } from '../../providerLocations/provider-locations-entry-dialog/provider-locations-entry-dialog.module';
import { ProviderLocationsEntryDialogComponent } from '../../providerLocations/provider-locations-entry-dialog/provider-locations-entry-dialog.component';

@NgModule({
  declarations: [ProvidersDialogComponent, ProviderLocationsEntryDialogComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    FormModule,
    SharedModule,
    MatTableModule,
    MatMenuModule
  ]
})
export class ProvidersDialogModule {}
