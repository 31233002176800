/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Patient Search criteria view model passed from client
 */
export interface PatientSearchCriteriaIntegrationViewModel {
  page?: number;
  pageSize?: number;
  accountNumber?: string | null;
  medicalRecordNumber?: string | null;
  lastName?: string | null;
  firstName?: string | null;
  birthDate?: string | null;
}
