<h3>Provider Location(s)</h3>

<div class="locSelect">
  <lib-form-group class="" stackedTitle="true" hideTitle="true">
    <lib-form-field
      [parent]="entryForm"
      label="Select Location"
      name="locationId"
      type="select"
      multiple="true"
      [array]="inactiveLocationArray"
      class="form-span-12"
    >
    </lib-form-field>
  </lib-form-group>
</div>

<div class="button-div">
  <button class="cancel" mat-stroked-button (click)="cancel()">Cancel</button>

  <button class="save" mat-stroked-button style="color: #ffffff" (click)="save()">Save</button>
</div>
