<article class="split-visit">
  <header mat-dialog-title class="dialog-form__title">
    <h2>Split Visit</h2>
  </header>
  <lib-card class="procedure-table" [hideTitle]="true">
    <form [formGroup]="visitForm">
      <div class="tableContainer">
        <table mat-table class="table mat-elevation-z2" [dataSource]="dataSource" formArrayName="procedureArray">
          <!-- Checkbox Column -->
          <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element" [title]="element.pendingBatchMessage">
              <mat-checkbox
                (change)="toggleCheckbox(element.id)"
                [checked]="selection.isSelected(element.id)"
                [disabled]="element.pendingBatchMessage"
                [title]="element.pendingBatchMessage ?? ''"
              >
              </mat-checkbox>
            </td>
          </ng-container>

          <!-- Procedure Column -->
          <ng-container matColumnDef="procedureCode">
            <th mat-header-cell *matHeaderCellDef>Procedure</th>
            <td mat-cell *matCellDef="let element" id="procedureCode{{ i }}" [title]="element | procedureDescription">
              {{ element?.procedure?.code }}
            </td>
          </ng-container>

          <!-- DOS Column -->
          <ng-container matColumnDef="fromDate">
            <th mat-header-cell *matHeaderCellDef>Service Date</th>
            <td mat-cell *matCellDef="let element" id="fromDate{{ i }}">
              {{ element?.serviceFromDate | dateDisplay }}
            </td>
          </ng-container>

          <!-- Amount -->
          <ng-container matColumnDef="procedureAmount">
            <th mat-header-cell *matHeaderCellDef>Amount</th>
            <td mat-cell *matCellDef="let element" id="procedureAmount{{ i }}">
              ${{ element?.procedureAmount?.toFixed(2) }}
            </td>
          </ng-container>

          <!-- POS -->
          <ng-container matColumnDef="placeOfService">
            <th mat-header-cell *matHeaderCellDef>POS</th>
            <td
              mat-cell
              *matCellDef="let element"
              id="placeOfService{{ i }}"
              [title]="element.placeOfServiceCode?.name"
            >
              {{ element?.placeOfServiceCode?.code }}
            </td>
          </ng-container>

          <!-- Attending Column-->
          <ng-container matColumnDef="attending">
            <th mat-header-cell *matHeaderCellDef>Attending</th>
            <td
              mat-cell
              *matCellDef="let element"
              id="attending{{ i }}"
              [title]="
                element.attendingProvider?.firstName +
                ' ' +
                element.attendingProvider?.lastName +
                ' ' +
                element.attendingProvider?.title +
                ' ' +
                element.attendingProvider?.npi
              "
            >
              {{ element.attendingProvider?.providerNumber }}
            </td>
          </ng-container>

          <!-- Referring Column -->
          <ng-container matColumnDef="referring">
            <th mat-header-cell *matHeaderCellDef>Referring</th>
            <td
              mat-cell
              *matCellDef="let element"
              id="referring{{ i }}"
              [title]="
                element.referringProvider?.firstName +
                ' ' +
                element.referringProvider?.lastName +
                ' ' +
                element.referringProvider?.title +
                ' ' +
                element.referringProvider?.npi
              "
            >
              {{ element.referringProvider?.providerNumber || '--' }}
            </td>
          </ng-container>

          <!-- Location Column -->
          <ng-container matColumnDef="location">
            <th mat-header-cell *matHeaderCellDef>Location</th>
            <td mat-cell *matCellDef="let element" id="location{{ i }}" [title]="element.location?.name">
              {{ element.location?.code }}
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>
      <div class="visit-selection">
        <mat-form-field appearance="outline" class="visit-field">
          <mat-label> Split to Visit </mat-label>
          <mat-select [disabled]="visitArray.length === 0" formControlName="visitId" name="visitId">
            <mat-option *ngFor="let x of visitArray" [value]="x.value">
              {{ x.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </form>
  </lib-card>
  <div mat-dialog-actions class="dialog-form__actions">
    <button class="cancel-button" mat-stroked-button mat-dialog-close id="cancelButton">Cancel</button>
    <lib-button-loading
      #saveButton
      color="primary"
      class="save-button"
      [loading]="saving"
      [invalid]="visitForm.invalid || selection.selected.length < 1"
      (clickEvent)="applySplit()"
      id="saveButton"
    >
      Split Visit
    </lib-button-loading>
  </div>
</article>
