import { createSelector, createFeatureSelector } from '@ngrx/store';

import { RemindersState, RemindersAdapter } from './reminders.state';

export const FEATURE_NAME = 'reminders';
export const selectRemindersState = createFeatureSelector<RemindersState>(FEATURE_NAME);

export const selectRemindersLoading = createSelector(selectRemindersState, (state: RemindersState) => state?.loading);

export const selectRemindersLoaded = createSelector(selectRemindersState, (state: RemindersState) => state?.loaded);

export const { selectIds, selectEntities, selectAll, selectTotal } = RemindersAdapter.getSelectors(
  selectRemindersState
);

export const getSelectedNoteId = createSelector(selectRemindersState, (state) => state?.selectedReminderId);
