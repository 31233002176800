import { Injector, LOCALE_ID } from '@angular/core';
import { Validators } from '@angular/forms';
import { FinancialClassesLookupService, InsuranceCarriersLookupService } from '@core/services/lookup';
import { BodyClasses, ColumnSizes, FilterType, FieldSelectOption } from 'components';
import { lookupService } from 'dns';
import { format } from 'path';
import { DateDisplayPipe } from 'projects/components/src/lib/shared/pipes/date-display/date-display.pipe';
import { Report } from '../../models/report';
import { HeldClaimsRegisterService } from './held-claims-register.service';

export const HeldClaimsRegister: Report = {
  id: 'held-claims',
  label: 'Held Visits Register',
  textCriteria: {'Date Type': '','Start Date': '', 'End Date': '', 'Financial Classes': '', 'Insurance Carriers': '', 'Print Claim Detail': '',
    'Report Format': ''
  },
  cleanFilterParams: {
    'insuranceCarrierIds': '', 'financialClassIds': ''
  },
  getReportTitle: () => {
      return HeldClaimsRegister.label + ' ' + HeldClaimsRegister.textCriteria['Start Date'] + ' - ' + HeldClaimsRegister.textCriteria['End Date'] + ' (' +
        HeldClaimsRegister.textCriteria['Date Type'] + ')';
  },
  getSearchCriteria: (injector: Injector) => [
    {
      controls: [
        {
          label: 'Posting Or Service Or Billed Date',
          name: 'dateRangeType',
          type: 'select',
          class: 'form-span-6',
          options: [
            {
              label: 'Posting Date',
              value: 'P'
            },
            {
              label: 'Service Date',
              value: 'S'
            },
            {
              label: 'Billed Date',
              value: 'B'
            }
          ],
          validators: Validators.required,
          selectionChanged: (event) => {
            HeldClaimsRegister.textCriteria['Date Type'] = event.source.triggerValue;
          }
        },{
          label: 'Date Range',
          name: 'dateRange',
          type: 'dateRange',
          class: 'form-span-6',
          daterangeStartChanged: (event) => {
            HeldClaimsRegister.textCriteria['Start Date'] = event.value.toLocaleDateString();
          },
          daterangeEndChanged: (event) => {
            HeldClaimsRegister.textCriteria['End Date'] = event.value.toLocaleDateString();
          }
        },
        {
          label: '',
          name: 'dateRangeStart',
          type: 'noInput',
          class: 'display-none',
          validators: Validators.required
        },
        {
          label: '',
          name: 'dateRangeEnd',
          type: 'noInput',
          class: 'display-none',
          validators: Validators.required
        },
        {
          matLabel: 'Financial Class',
          name: 'financialClassIds',
          apiService: injector.get(FinancialClassesLookupService),
          class: 'form-span-12 multi-input',
          type: 'multiSelect',
          placeholder: 'Financial Class',
          selectOptions: (event) => {
            let listValue = []
            let listText = []
            event.forEach((x: FieldSelectOption) => {
              listValue.push(x.value)
              listText.push(x.label)
            })
            HeldClaimsRegister.textCriteria['Financial Classes'] = listText.join(',');
            HeldClaimsRegister.cleanFilterParams['financialClassIds'] = listValue
          }
        },
        {
          matLabel: 'Insurance Carrier',
          name: 'insuranceCarrierIds',
          apiService: injector.get(InsuranceCarriersLookupService),
          class: 'form-span-12 multi-input',
          type: 'multiSelect',
          placeholder: 'Insurance Carriers',
          selectOptions: (event: []) => {
            let listValue = []
            let listText = []
            event.forEach((x: FieldSelectOption) => {
              listValue.push(x?.value)
              listText.push(x.label)
            })
            HeldClaimsRegister.textCriteria['Insurance Carriers'] = listText.join(',');
            HeldClaimsRegister.cleanFilterParams['insuranceCarrierIds'] = listValue
          },
        },
        {
          label: 'Print Claim Detail',
          name: 'printClaimDetail',
          class: 'form-span-4',
          type: 'select',
          options: [
            {
              label: 'Yes',
              value: 'Y'
            },
            {
              label: 'No',
              value: 'N'
            }
          ],
          validators: Validators.required,
          selectionChanged: (event) => {
            HeldClaimsRegister.textCriteria['Print Claim Detail'] = event.source.triggerValue;
          }
        },
        {
          label: 'Report Format',
          name: 'reportFormat',
          class: 'form-span-8',
          type: 'select',
          options: [
            {
              label: 'Include all held claims',
              value: '1'
            },
            {
              label: 'Only held claims with payments applied',
              value: '2'
            },
            {
              label: 'Only held claims with a positive balance',
              value: '3'
            }
          ],
          validators: Validators.required,
          selectionChanged: (event) => {
            HeldClaimsRegister.textCriteria['Report Format'] = event.source.triggerValue;
          }
        }
      ]
    }
  ],
  getGridConfiguration: (injector: Injector) => {
    const locale = injector.get(LOCALE_ID);
    const datePipe: DateDisplayPipe = new DateDisplayPipe(locale);
    const moneyFormat = new Intl.NumberFormat('en-us', { style: 'currency', currency: 'USD' });
    return {
      service: injector.get<any>(HeldClaimsRegisterService),
      columnDefinitions: [
        {
          id: 'Account',
          displayKey: 'Account',
          headerText: 'Account',
          displayOrder: 0,
          columnSize: ColumnSizes.normal,
          sortable: true,
          filterable: true
        },
        {
          id: 'Name',
          displayKey: 'Name',
          headerText: 'Name',
          displayOrder: 1,
          columnSize: ColumnSizes.normal,
          sortable: true,
          filterable: true,
        },
        {
          id: 'Visit #',
          displayKey: 'Visit #',
          headerText: 'Visit#',
          displayOrder: 2,
          columnSize: ColumnSizes.extraNarrow,
          sortable: true,
          bodyClasses: [BodyClasses.alignRight],
          filterable: true
        },
        {
          id: 'Billed Date',
          displayKey: 'Billed Date',
          headerText: 'Billed Date',
          displayOrder: 3,
          columnSize: ColumnSizes.normal,
          sortable: true,
          filterable: true
        },
        {
          id: 'DOS',
          displayKey: 'DOS',
          headerText: 'Service Date',
          displayOrder: 4,
          columnSize: ColumnSizes.normal,
          sortable: true,
          filterable: true
        },
        {
          id: 'Posting Date',
          displayKey: 'Posting Date',
          headerText: 'Posting Date',
          displayOrder: 5,
          columnSize: ColumnSizes.normal,
          sortable: true,
          filterable: true
        },
        {
          id: 'Procedure',
          displayKey: 'Procedure',
          headerText: 'Procedure',
          displayOrder: 6,
          columnSize: ColumnSizes.narrow,
          sortable: true,
          filterable: true
        },
        {
          id: 'Proc Desc',
          displayKey: 'Proc Desc',
          headerText: 'Proc Desc',
          displayOrder: 7,
          columnSize: ColumnSizes.wide,
          sortable: true,
          filterable: true
        },
        {
          id: 'Amount',
          displayKey: 'Amount',
          headerText: 'Amount',
          displayOrder: 8,
          columnSize: ColumnSizes.narrow,
          sortable: true,
          filterable: true,
          bodyClasses: [BodyClasses.alignRight],
          formatter: moneyFormat.format
        },
        {
          id: 'Paid',
          displayKey: 'Paid',
          headerText: 'Paid',
          displayOrder: 9,
          columnSize: ColumnSizes.narrow,
          sortable: true,
          filterable: true,
          bodyClasses: [BodyClasses.alignRight],
          formatter: moneyFormat.format
        },
        {
          id: 'Adjustments',
          displayKey: 'Adjustments',
          headerText: 'Adjustments',
          displayOrder: 10,
          columnSize: ColumnSizes.normal,
          sortable: true,
          filterable: true,
          bodyClasses: [BodyClasses.alignRight],
          formatter: moneyFormat.format
        },
        {
          id: 'Balance',
          displayKey: 'Balance',
          headerText: 'Balance',
          displayOrder: 11,
          columnSize: ColumnSizes.narrow,
          sortable: true,
          filterable: true,
          bodyClasses: [BodyClasses.alignRight],
          formatter: moneyFormat.format
        },
        {
          id: 'Attending',
          displayKey: 'Attending',
          headerText: 'Attending',
          displayOrder: 12,
          columnSize: ColumnSizes.normal,
          sortable: true,
          filterable: true
        },
        {
          id: 'Referring',
          displayKey: 'Referring',
          headerText: 'Referring',
          displayOrder: 13,
          columnSize: ColumnSizes.normal,
          sortable: true,
          filterable: true,
        },
        {
          id: 'Diag Code',
          displayKey: 'Diag Code',
          headerText: 'Diag Code',
          displayOrder: 14,
          columnSize: ColumnSizes.narrow,
          sortable: true,
          filterable: true
        }
      ]
    };
  }
};
