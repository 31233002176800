/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ProcedureResultDetailsHoldingViewModel } from './procedureResultDetailsHoldingViewModel';

export interface ProcedureResultHoldingViewModel {
  id?: string;
  datasetId?: string;
  sendingFacilityId?: string | null;
  procedureTransactionHoldingId?: string | null;
  patientId?: string | null;
  patientHoldingId?: string | null;
  visitHoldingId?: string | null;
  visitId?: string | null;
  procedureResultDetailsHoldings?: Array<ProcedureResultDetailsHoldingViewModel> | null;
}
