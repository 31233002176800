import { ReportGroup } from '../models/report-group';
import { FinancialAnalysisReportGroup } from './financial-analysis/financial-analysis';
import { TransactionActivityReportGroup } from './transaction-activity/transaction-activity';
import { InsuranceReportGroup } from './insurance-reports/insurance-reports';
import { RvuReportGroup } from './rvu-reports/rvu-reports';

export const AllReportGroups: ReportGroup[] = [
  FinancialAnalysisReportGroup,
  TransactionActivityReportGroup,
  InsuranceReportGroup,
  RvuReportGroup
];

export const AllReports = [
  ...FinancialAnalysisReportGroup.reports,
  ...TransactionActivityReportGroup.reports,
  ...InsuranceReportGroup.reports,
  ...RvuReportGroup.reports
];

export function getReportById(id: string) {
  return AllReports.find((report) => report.id === id);
}
