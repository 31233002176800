/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface PatientSearchViewModel {
  patientId?: string;
  lastName?: string | null;
  firstName?: string | null;
  middleName?: string | null;
  suffix?: string | null;
  accountNumber?: number;
  readonly accountNumberDisplay?: string | null;
  birthDate?: string | null;
  latestServiceDate?: string | null;
  latestReferenceNumber?: number;
  insuranceCarrierName?: string | null;
  patientBalance?: number;
  insuranceBalance?: number;
  totalBalance?: number;
  patientStatus?: Array<string> | null;
}
