/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface ARTransactionHolding {
  id?: string;
  rowId?: number;
  datasetId?: string;
  batchId?: string;
  patientId?: string;
  visitId?: string;
  paymentCodeId?: string | null;
  adjustmentCodeId?: string | null;
  adjustmentCode2Id?: string | null;
  postingDate?: string | null;
  transactionDate?: string | null;
  allowedAmount?: number;
  paymentAmount?: number;
  adjustmentAmount?: number;
  adjustment2Amount?: number;
  deductibleAmount?: number;
  copayAmount?: number;
  coinsuranceAmount?: number;
  checkNumber?: string | null;
  internalControlNumber?: string | null;
  enteredBy?: string | null;
  postedBy?: string | null;
  insuranceId?: string | null;
  refundId?: string | null;
  denied?: string | null;
  releasedToGuarantor?: boolean;
  billNextInsurance?: string | null;
  fromRemittance?: boolean;
  paymentTypeId?: string | null;
  patientPayment?: boolean;
  crossoverInsuranceId?: string | null;
  isDeleted?: boolean;
  deletedBy?: string | null;
  deletedDate?: string | null;
  createdBy?: string | null;
  createdDate?: string;
  updatedBy?: string | null;
  updatedDate?: string | null;
  unappliedId?: string | null;
  unappliedType?: string | null;
}
