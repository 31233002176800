/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PatientSearchResultViewModel } from './patientSearchResultViewModel';
import { VisitSearchResultViewModel } from './visitSearchResultViewModel';
import { CodeSearchResultViewModel } from './codeSearchResultViewModel';
import { BatchSearchResultViewModel } from './batchSearchResultViewModel';
import { OtherSearchResultViewModel } from './otherSearchResultViewModel';

export interface SearchResultsViewModel {
  patients?: Array<PatientSearchResultViewModel> | null;
  visits?: Array<VisitSearchResultViewModel> | null;
  batches?: Array<BatchSearchResultViewModel> | null;
  codes?: Array<CodeSearchResultViewModel> | null;
  other?: Array<OtherSearchResultViewModel> | null;
  patientCount?: number;
  visitCount?: number;
  batchCount?: number;
  codeCount?: number;
  otherCount?: number;
  pageNum?: number;
  pageSize?: number;
}
