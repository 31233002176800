/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface AddRuleCardCriteriaViewModel {
  id?: string;
  rowId?: number;
  ruleCardId?: string;
  ordinal?: number;
  ruleCardExpressionId?: string;
  operator?: string | null;
  expressionB?: string | null;
}
