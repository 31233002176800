/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ClaimProviderViewModel } from './claimProviderViewModel';
import { HoldingIssue } from './holdingIssue';
import { ClaimLocationViewModel } from './claimLocationViewModel';

export interface ClaimVisitViewModel {
  visitIdentifier?: string | null;
  illnessInjuryDate?: string | null;
  accidentType?: string | null;
  accidentStateCode?: string | null;
  hospitalAdmitDate?: string | null;
  hospitalDischargeDate?: string | null;
  diagnoses?: Array<string> | null;
  delayReason?: string | null;
  frequency?: string | null;
  pwkReportTypeCode?: string | null;
  pwkTransmissionCode?: string | null;
  pwkAttachmentNumber?: string | null;
  primaryInsuranceAuthNumber?: string | null;
  secondaryInsuranceAuthNumber?: string | null;
  tertiaryInsuranceAuthNumber?: string | null;
  releaseOfInformationCode?: string | null;
  patientSignatureSourceCode?: string | null;
  visitBox17AQualifier?: string | null;
  visitBox17AValue?: string | null;
  visitBox9bValue?: string | null;
  visitBox9cValue?: string | null;
  visitAttendingProviderDetails?: ClaimProviderViewModel;
  visitReferringProviderDetails?: ClaimProviderViewModel;
  visitOrderingProviderDetails?: ClaimProviderViewModel;
  visitSupervisingProviderDetails?: ClaimProviderViewModel;
  visitLocationDetails?: ClaimLocationViewModel;
  visitProcedureAmount?: number;
  visitPaymentAmount?: number;
  visitSendClia?: boolean;
  visitSendMammo?: boolean;
  visitDiagnoses?: Array<string> | null;
  visitSendOrdering?: boolean;
  issues?: Array<HoldingIssue> | null;
}
