import { Injectable } from '@angular/core';
import { BaseGridSource, PagedModel } from 'components';
import { of } from 'rxjs';
import { catchError, delay, finalize } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EClaimsDialogService extends BaseGridSource<any> {
  load(pagedModel: PagedModel) {
    this.loadingSubject.next(true);

    const mockAccounts = new Array(5).fill(null).map(() => {
      return {
        id: '00-00',
        loopName: '2000B',
        value: 'Provider Name',
        select: ''
      };
    });

    of(mockAccounts)
      .pipe(
        delay(1000),
        catchError(() => of([])),
        finalize(() => this.loadingSubject.next(false))
      )
      .subscribe((data) => {
        this.itemCountSubject.next(10);
        this.dataSourceSubject.next(data);
      });
  }
}
