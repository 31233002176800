/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ARTransactionDetailEobSimpleViewModel } from './aRTransactionDetailEobSimpleViewModel';
import { CodeViewModel } from './codeViewModel';
import { ARTransactionDetailRemarkViewModel } from './aRTransactionDetailRemarkViewModel';

export interface ARTransactionPostedProcedureViewModel {
  eobCodes?: Array<ARTransactionDetailEobSimpleViewModel> | null;
  remarkCodes?: Array<ARTransactionDetailRemarkViewModel> | null;
  datasetId?: string | null;
  procedureTransactionId?: string;
  serviceDate?: string | null;
  procedureCode?: string | null;
  procedureAmount?: number;
  procedureBalance?: number;
  arTransactionDetailId?: string | null;
  allowedAmount?: number;
  paymentAmount?: number;
  adjustmentAmount?: number;
  adjustment2Amount?: number;
  deductibleAmount?: number;
  coinsuranceAmount?: number;
  copayAmount?: number;
  interestAmount?: number;
  quantity?: number | null;
  totalAdjustment?: number;
  totalEob?: number;
  modifier?: CodeViewModel;
}
