import { authLogin, authLogout } from './auth.actions';
import { createReducer, on, Action } from '@ngrx/store';
import { AuthState, initialState } from './auth.state';

const reducer = createReducer(
  initialState,
  on(authLogin, (state, { authData }) => ({ ...authData })),
  on(authLogout, (state) => ({ ...initialState }))
);

/**
 * Exports reducer for core State to consume.
 *
 * @export
 * @param {(AuthState | undefined)} state
 * @param {Action} action
 * @returns {AuthState}
 */
export function authReducer(state: AuthState | undefined, action: Action): AuthState {
  return reducer(state, action);
}
