/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface HL7UploadViewModel {
  id?: string;
  uploadDate?: string;
  sendingFacilityName?: string | null;
  originalName?: string | null;
  totalPatients?: number;
  totalProcedures?: number;
  content?: string | null;
  status?: string | null;
}
