import { Component, Inject, Injector, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RuleCardCriteriaWrapperService } from '@core/services/service-wrappers/rule-card-criteria-wrapper.service';
import { Store } from '@ngrx/store';
import { FormGroupDefinition } from 'components';
import { compare } from 'fast-json-patch';
import { Observable, of } from 'rxjs';
import { filter, first, map, take } from 'rxjs/operators';
import { NotificationService } from 'src/app/core/services/notification.service';
import { CodeWrapperService } from 'src/app/core/services/service-wrappers/code-wrapper.service';
import { getSelectedDataSetId } from 'src/app/core/state/data-set/data-set.selectors';

@Component({
  selector: 'app-modifiers-dialog',
  templateUrl: './modifiers-dialog.component.html',
  styleUrls: ['./modifiers-dialog.component.scss']
})
export class ModifiersDialogComponent implements OnInit {
  public get dialogRef(): MatDialogRef<ModifiersDialogComponent> {
    return this._dialogRef;
  }
  public set dialogRef(value: MatDialogRef<ModifiersDialogComponent>) {
    this._dialogRef = value;
  }
  formGroup = new UntypedFormGroup({});
  dataSetId$ = this.baseStore.select(getSelectedDataSetId).pipe(filter((cId) => !!cId));
  dataSetId;
  formInitialized = false;
  saving = false;
  CODE_TYPE = 'Modifier';
  modifierId;
  activeModifiers: Array<string> = [];
  viewOnly = false;

  formDefinitions: FormGroupDefinition[];
  getFormDefinitions(isEdit: boolean): FormGroupDefinition[] {
    return [
      {
        hideTitle: true,
        controls: [
          {
            label: 'Code',
            name: 'code',
            isReadOnly: isEdit,
            type: 'allCaps',
            class: 'form-span-10',
            validators: [
              Validators.required,
              Validators.maxLength(2),
              Validators.minLength(2)]
          },
          {
            label: 'Active',
            name: 'active',
            type: 'checkbox',
            class: 'form-span-2',
            initial: true,
            validators: Validators.required
          },

          {
            label: 'Description',
            name: 'name',
            type: 'text',
            class: 'form-span-12',
            validators:  [Validators.required,Validators.maxLength(100)]
          }
        ]
      }
    ];
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private injector: Injector,
    private notificationService: NotificationService,
    private _dialogRef: MatDialogRef<ModifiersDialogComponent>,
    public dropdownService: RuleCardCriteriaWrapperService,
    private service: CodeWrapperService,
    private baseStore: Store<any>
  ) {
    this.dataSetId$.subscribe((dataSetIdFromStore) => {
      this.dataSetId = dataSetIdFromStore;
    });
    this.modifierId = data?.modifierId;
    this.viewOnly = data?.viewOnly;
    const isEdit = this.modifierId && this.modifierId !== '';
    this.formDefinitions = this.getFormDefinitions(isEdit);
  }

  ngOnInit(): void {
    this.setupForm();

    if (this.modifierId && this.modifierId !== '') {
      this.service
        .apiV1CodeCodetypeIdGet(this.CODE_TYPE, this.modifierId)
        .pipe(first())
        .subscribe((result) => {
          this.formGroup.patchValue(result);
          this.formGroup.get('active').patchValue(!result.inactive);
          this.setModCodeArray(result.code)
        });
    } else {
      this.setModCodeArray('')
    }
  }

  setModCodeArray(exceptionMod: string = '') {

    this.dropdownService.apiV1RulecardcriteriaGetdropdownvaluesPost({
      pageSize: 8000, sortColumn: '', sortOrder: 1, page: 1,
      propertySearches: [
        { propertyName: 'Input_TableName', value: 'modifier' },
        { propertyName: 'DatasetId', value: this.dataSetId },
        { propertyName: 'IncludeDescription', value: '0' }
      ]
    })
      .pipe(
         map((value) => this.sortByName(value.data)),
         map((value) => value.map(this.mapRow)
        ))
      .subscribe((data) => {

        data.forEach((e) => {
          if (e.label !== exceptionMod) {
            this.activeModifiers.push(e.label);
          }
        });

      });
  }

  mapRow(value) {
    return {
      value: value.code,
      label: value.name
    };
  }

  sortByName(ruleCardExpressions) {
    return ruleCardExpressions.sort((a, b) => (a.name < b.name ? -1 : 1));
  }

  setupForm() {
    this.formDefinitions.forEach((sc) => {
      sc.controls.forEach((control) => {
        if (control.type !== 'empty' && control.type !== 'label') {
          this.formGroup.addControl(control.name, new UntypedFormControl(control.initial ?? '', control.validators));
        }
      });
    });
    this.formInitialized = true;
  }

  save() {
    if (this.validate() && this.formGroup.valid) {
      this.saving = true;
      const formData = {
        code: this.formGroup.get('code').value,
        name: this.formGroup.get('name').value,
        inactive: this.formGroup.get('active').value === true ? false : true
      };

      let saveObservable: Observable<any>;
      let notification;
      if (this.modifierId) {
        notification = 'Modifier Updated';
        saveObservable = this.service
          .apiV1CodeCodetypePatchIdPatch(this.CODE_TYPE, this.modifierId, compare({}, formData))
          .pipe(map((x: any) => x));
      } else {
        notification = 'Modifier Added';
        saveObservable = this.service.apiV1CodeCodetypeAddPost(this.CODE_TYPE, formData).pipe(map((x: any) => x));
      }

      saveObservable
        .pipe(take(1))
        .subscribe(
          (response) => {
            this.dialogRef.close(true);
            this.notificationService.success(notification);
          },
          (err) => this.notificationService.error('Saving Modifier')
        )
        .add(() => {
          this.saving = false;
        });
    }
  }
  
  validate() {

    let errors = '';

    if (this.activeModifiers.includes(this.formGroup.get('code').value)) {
      errors = this.formGroup.get('code').value + " is already an active modifier in use."
    }

    if (errors !== '') {
      this.notificationService.error(errors)
      return false;
    }
    else { return true; }
    
  }

}
