/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PropertyNumericRange } from './propertyNumericRange';
import { PropertySearch } from './propertySearch';
import { ListSortDirection } from './listSortDirection';
import { PropertyDateRange } from './propertyDateRange';

export interface PagedModelRequest {
  sortOrder?: ListSortDirection;
  sortColumn?: string | null;
  generalSearch?: string | null;
  propertySearches?: Array<PropertySearch> | null;
  numericRanges?: Array<PropertyNumericRange> | null;
  dateRanges?: Array<PropertyDateRange> | null;
  page?: number;
  pageSize?: number;
  startDate?: string;
  endDate?: string;
  propertySearchOr?: boolean;
}
